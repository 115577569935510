import { default as React, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import locale from '@/common/utils/locale';
import * as selectWorkspace from '@/common/api/select-workspace/selectWorkspace';
import * as validator from '@/common/utils/validator';
import { SigninContainer, State } from '@/user/components/auth/signin/SigninContainer';
import * as cookieInterfaces from '@/common/utils/authManagement/cookieInterfaces';
import * as commonWorkspaceModel from '@/common/api/workspace/profile';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import routes from '@/user/constants/routes';
import * as errorHandler from '@/common/utils/errorHandler';
import ModalOuter from '@/user/components/common/ModalOuter';
import { prefix } from '@/common/utils/authManagement/localStorageConstants';
import { parse } from 'query-string';

const styles = (theme: Theme) =>
    createStyles({
        title: {
            marginTop: '100px',
        },
        text: {
            margin: '11px',
            maxWidth: '30%',
            minWidth: '328px',
        },
        button: {
            margin: theme.spacing.unit,
            textTransform: 'none',
            fontSize: '13px',
            fontWeight: 'bold',
        },
        nextButtonDiv: {
            marginTop: '26px',
        },
        nextButton: {
            color: 'white',
            maxWidth: '20%',
            minWidth: '240px',
        },
        signedIn: {
            marginTop: '92px',
            marginBottom: '16px',
        },
        recoverButton: {
            color: '#0d47a1',
            fontWeight: 500,
        },
    });

const useModal = () => {
    const [isOpen, setOpen] = React.useState(false);

    const open = () => setOpen(true);
    const close = () => setOpen(false);

    return { isOpen, open, close };
};

export interface Props extends WithStyles<typeof styles> {}
export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const container = SigninContainer.useContainer();
    const appContainer = UserAppContainer.useContainer();
    const [errors, setErrors] = useState({
        dispId: '',
    });
    const [form, setForm] = useState(selectWorkspace.New(prefix.userApp));
    const modalUI = useModal();
    const history = useHistory();
    const location = useLocation();

    // 「次へ」を押したときの処理
    const handleNext = () => {
        // 入力値の検証
        const msg = validator.Validate<selectWorkspace.Form>(form, selectWorkspace.validations(), selectWorkspace.NewValidation);
        // ワークスペースIDの入力がない場合メッセージを格納
        const dispMessage = msg === null ? '' : msg.workspaceId === null ? '' : msg!.workspaceId.toString();
        setErrors({
            dispId: dispMessage,
        });
        // 入力がない場合はエラーを表示して処理終了
        if (dispMessage !== null && dispMessage !== '') {
            return;
        }
        (async () => {
            try {
                // ワークスペースが存在するか確認
                const exists = await commonWorkspaceModel.showWorkspaceProfile(form.workspaceId);
                if (!exists) {
                    setErrors({
                        dispId: locale.t(locale.keys.error.workspaceNotFound),
                    });
                    return;
                }
                if (msg == null) {
                    container.setValues({
                        ...container.values,
                        workspaceId: exists.id,
                        workspaceDisplayId: exists.displayId,
                        workspaceDisplayName: exists.displayName,
                        workspaceLogoUrl: exists.logoUrl,
                    });
                    // 言語設定
                    locale.set(exists.language);

                    // go to password auth
                    container.setState(State.Login);
                }
            } catch (e) {
                const error = errorHandler.handleApiError(appContainer, e);
                if (error) {
                    setErrors({
                        dispId: error.message, // トーストメッセージに加えてフォームのヘルパーテキストにもエラー内容を表示
                    });
                } else {
                    setErrors({
                        dispId: locale.t(locale.keys.error.unknown),
                    });
                }
            }
        })();
    };

    const selectAlready = (workspace: cookieInterfaces.AlreadySigninWorkspace) => {
        if (workspace.isSaml) {
            // TODO go to saml auth
            container.setState(State.Login);
        }

        if (container.state === State.Login) {
            return;
        }

        (async () => {
            try {
                await appContainer.selectAlready(workspace.displayId, appContainer);

                const parsedQuery = parse(location.search);
                if (parsedQuery.return_to != null && typeof parsedQuery.return_to === 'string') {
                    const returnTo = parsedQuery.return_to;
                    history.push(decodeURIComponent(returnTo));
                } else {
                    history.push(routes.dashboard.index);
                }
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    return (
        <Grid container spacing={24}>
            <Grid item sm />
            <Grid item xs={12} sm={10}>
                <div>
                    <h3 className={classes.title}>{locale.t(locale.keys.selectWorkspace.signin)}</h3>
                    <TextField
                        className={classes.text}
                        error={errors.dispId !== ''}
                        helperText={errors.dispId}
                        id="workspace"
                        label={locale.t(locale.keys.workspaceSetting.workspaceDisplayId.index)}
                        placeholder=""
                        margin="normal"
                        variant="filled"
                        value={form.workspaceId}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
                            setForm({
                                workspaceId: event.target.value,
                                alreadySigninWorkpspaces: form.alreadySigninWorkpspaces,
                                errors: form.errors,
                            })
                        }
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                                handleNext();
                                ev.preventDefault();
                            }
                        }}
                    />
                </div>
                <div className={classes.nextButtonDiv}>
                    <Fab variant="extended" className={classes.nextButton} size="large" color="secondary" onClick={() => handleNext()}>
                        {locale.t(locale.keys.selectWorkspace.next.index)}
                    </Fab>
                </div>

                <div>
                    <Button
                        onClick={() => {
                            modalUI.open();
                        }}
                        className={classes.recoverButton}
                    >
                        {locale.t(locale.keys.selectWorkspace.recovery.button)}
                    </Button>
                </div>

                {form.alreadySigninWorkpspaces.length > 0 && (
                    <>
                        <div className={classes.signedIn}>
                            <span>{locale.t(locale.keys.selectWorkspace.alreadySignin.index)}</span>
                        </div>
                        <div>
                            {form.alreadySigninWorkpspaces.map((it: cookieInterfaces.AlreadySigninWorkspace) => {
                                return (
                                    <Button key={it.displayId} variant="outlined" color="secondary" className={classes.button} onClick={() => selectAlready(it)}>
                                        {`${it.displayName}(${it.displayId})`}
                                    </Button>
                                );
                            })}
                        </div>
                    </>
                )}
            </Grid>
            <Grid item sm />
            <ModalOuter
                title={locale.t(locale.keys.selectWorkspace.recovery.title)}
                modalOpen={modalUI.isOpen}
                modalCloseFunc={modalUI.close}
                headerStyle={{ backgroundColor: 'white', color: 'black' }}
                recoverWsID={true}
            />
        </Grid>
    );
};

export default withStyles(styles)(Component);
