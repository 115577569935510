import * as schema from '@/bundles/schema/typescript/schema';

const mfpUsersSucceedRes = {
    userInfo: {
        pin: '123456',
        id: '303030303030303030303031',
        location: 'FLOOR-4',
        mfpName: 'RICOH 06',
        mfpNumber: '12345',
    },
};

const mfpsSucceedRes = {
    mfps: [
        {
            id: '1',
            deviceName: '22222222222',
            mfpNumber: '12345',
            mfpName: 'RICOH 06',
            mfpLocation: 'FLOOR-4',
            memberCount: 0,
        },
        {
            id: '2',
            deviceName: '3333333333',
            mfpNumber: '12345',
            mfpName: 'RICOH 06',
            mfpLocation: 'FLOOR-4',
            memberCount: 0,
        },
        {
            id: '3',
            deviceName: 'asdfghjkl',
            mfpNumber: '12345',
            mfpName: 'RICOH 06',
            mfpLocation: 'FLOOR-4',
            memberCount: 1,
        },
    ],
    totalCount: 1,
    limit: 5,
    offset: 1,
};

const endpointsResults = {
    endpoints: [
        {
            id: '303030303030303030303031',
            name: 'Endpoint1',
            iconName: 'icon001.png',
            buttonColor: schema.V1ObjectsEndpointsButtonColor.DarkKhaki,
            type: schema.V1ObjectsServiceEnum.Dropbox,
            metadata: {},
            cloudAccount: 'tora@sample.com',
            folderPath: '/abc/xx/de/',
            uploadable: true,
            downloadable: true,
            serviceCompleted: false,
            serviceError: false,
        },
        {
            id: '303030303030303030303032',
            name: 'Endpoint2',
            iconName: 'icon001.png',
            buttonColor: schema.V1ObjectsEndpointsButtonColor.DarkKhaki,
            type: schema.V1ObjectsServiceEnum.Dropbox,
            metadata: {},
            cloudAccount: 'tora@sample.com',
            folderPath: '/abc/xx/de/',
            uploadable: true,
            downloadable: true,
            serviceCompleted: false,
            serviceError: false,
        },
        {
            id: '303030303030303030303033',
            name: 'Endpoint3',
            iconName: 'icon001.png',
            buttonColor: schema.V1ObjectsEndpointsButtonColor.DarkKhaki,
            type: schema.V1ObjectsServiceEnum.Dropbox,
            metadata: {},
            cloudAccount: 'tora@sample.com',
            folderPath: '/abc/xx/de/',
            uploadable: true,
            downloadable: true,
            serviceCompleted: false,
            serviceError: false,
        },
    ],
};

const endpoint = {
    docNameFormat: {
        dateFormat: 'y|M|d',
        fixed: ['A1', 'A2'],
        itemList: 'fixed1|file|date|fixed2',
        separator: '_',
        fileOptional: false,
        lockEdit: false,
        sequenceType: 0,
    },
    wordListData: ['AAA', 'BBB'],
};
const correctUpdateRequest = {
    docNameFormat: endpoint.docNameFormat,
    endpoint: endpointsResults.endpoints[0],
    wordListData: endpoint.wordListData,
    defaultSettings: [
        {
            pdfAttributeData: {
                allowEdit: false,
                allowExtract: false,
                allowPrint: 1,
                authorization: false,
                authorizationPassword: 'WIgNmSmr+spDsBfgu6almjf2zD2RDOwv5XE0ofiY1SU=',
                encrypt: true,
                encryptLevel: 128,
                encryptPassword: 'WIgNmSmr+spDsBfgu6almjf2zD2RDOwv5XE0ofiY1SU=',
            },
            printAttributeData: {
                color: 2,
                copies: 30,
                encrypt: false,
                mediaSizeName: 0,
                mediaTray: 0,
                numberUp: 4,
                presentationDirection: 0,
                punch: 1,
                separatorLine: true,
                sheetCollate: 0,
                sides: 3,
                staple: 1,
            },
            scanAttributeData: {
                aggregation: true,
                autoDensity: false,
                batch: false,
                clippedVertical: false,
                colorType: 3,
                density: 5,
                eraseBorder: false,
                eraseBorderWidthBottom: 60,
                eraseBorderWidthLeft: 70,
                eraseBorderWidthRight: 10,
                eraseBorderWidthSame: 50,
                eraseBorderWidthTop: 90,
                eraseCenter: false,
                eraseCenterWidth: 11,
                eraseSettingSame: false,
                mixedSize: false,
                outputType: 12,
                preset1Horizontal: 324,
                preset1Vertical: 212,
                preset2Horizontal: 435,
                preset2Vertical: 214,
                preset3Horizontal: 166,
                preset3Vertical: 675,
                resolution: 600,
                rotate: false,
                sadf: false,
                scanBothSide: false,
                scanPreview: false,
                scanSpread: false,
                size: 12,
            },
            paramData: {
                folderDepth: 2,
                autoSelect: false,
                iconVisible: true,
                folderTitle1: 'folder1',
                folderTitle2: 'folder2',
                folderTitle3: 'folder3',
                folderTitle4: 'folder4',
                folderFormat: 0,
            },
            mfpInfo: {
                location: 'FLOOR-4',
                mfpId: '303030303030303030303031',
                type: 'QuickScan/4.80',
            },
            isExistDefault: false,
            createdAt: '2019/07/08',
        },
    ],
};
const endpointSucceedRes = {
    endpoints: [
        {
            buttonColor: schema.V1ObjectsEndpointsButtonColor.DarkGray,
            cloudAccount: 'tora@sample.com',
            downloadable: true,
            folderPath: '/abc/xx/de/',
            iconName: 'icon001.png',
            id: '303030303030303030303031',
            metadata: {},
            name: 'Endpoint1',
            type: schema.V1ObjectsServiceEnum.Dropbox,
            uploadable: true,
        },
        {
            buttonColor: schema.V1ObjectsEndpointsButtonColor.DarkGray,
            cloudAccount: 'tora@sample.com',
            downloadable: true,
            folderPath: '/abc/xx/de/',
            iconName: 'icon001.png',
            id: '303030303030303030303032',
            metadata: {},
            name: 'Endpoint2',
            type: schema.V1ObjectsServiceEnum.Dropbox,
            uploadable: true,
        },
    ],
};

const endpointUpdateValidReq = {
    buttonColor: schema.V1ObjectsEndpointsButtonColor.DarkKhaki,
    iconName: 'icon001.png',
    uploadable: true,
    downloadable: true,
    name: 'Endpoint1',
    path: 'xx/xxx/xx',
    serviceCompleted: false,
    serviceError: false,
};
const endpointUpdateValidRes = {
    buttonColor: schema.V1ObjectsEndpointsButtonColor.DarkGray,
    cloudAccount: 'tora@sample.com',
    downloadable: true,
    folderPath: '/abc/xx/de/',
    iconName: 'icon001.png',
    id: '303030303030303030303032',
    metadata: {},
    name: 'Endpoint2',
    type: schema.V1ObjectsServiceEnum.Dropbox,
    uploadable: true,
    xStorage: schema.V1ObjectsServiceEnum.Box,
    path: 'xx/xxx/xx',
    serviceCompleted: false,
    serviceError: false,
};

const deleteResMfpUser = {
    index: {
        mfpUsers: [
            {
                id: '303030303030303030303031',
                location: 'FLOOR-4',
                mfpName: 'RICOH 06',
                mfpNumber: '12345',
                pin: '123456',
                isLock: false,
            },
        ],
    },
};

const resquestNameRule = {
    endpointId: '303030303030303030303031',
    nameRuleObject: {
        dateFormat: 'y|M|d',
        fileOptional: false,
        fixed: [],
        itemList: 'date|file',
        separator: '-',
        wordList: ['abc', 'xyz'],
    },
};

const responseGetNameRule = {
    dateFormat: 'y|M|d',
    endpoint: '303030303030303030303031',
    fileOptional: false,
    fixed: [],
    itemList: 'date|file',
    separator: '-',
    wordList: ['abc', 'xyz'],
    lockEdit: false,
    sequenceType: 1,
    wordList02: ['test', 'test2'],
    wordListName01: 'listname1',
    wordListName02: 'listname2',
};

const responseUpdateNameRule = {
    dateFormat: 'y|M|d',
    endpoint: '303030303030303030303031',
    fileOptional: false,
    fixed: [],
    itemList: 'date|file',
    separator: '-',
    timeFormat: 'Hms',
    wordList: ['abc', 'xyz'],
};

const dataXStorages = {
    cursor: '',
    path: '/',
    xStorageFiles: [
        {
            id: 'id:mAG0-d-knFAAAAAAAAAADA',
            name: 'test',
            path: '/test/',
            writable: true,
        },
    ],
};

const getXStorageRes = {
    xstorages: [
        {
            xstorageId: '234567654',
            authorizationId: 'j@sios.co',
            authorizationDeadline: '2019-06-06T04:48:37.422Z',
            authorizationLink: 'https://sios.com',
            imageLink: 'https://sios.com',
            service: schema.V1ObjectsServiceEnum.Box,
            status: schema.V1ObjectsXStorageStatus.Success,
            share: 0,
        },
    ],
};

const validCreateEndpointRequest = {
    endpoint: {
        buttonColor: schema.V1ObjectsEndpointsButtonColor.Crimson,
        downloadable: true,
        iconName: 'icon012.png',
        name: 'whatever-endpoint',
        path: '/endpoint/path/',
        pathId: 'endpoint-pathId',
        uploadable: false,
        xStorage: '303030303030303030303031',
        share: 0,
    },
    wordListData: ['123123', '123123'],
    docNameFormat: {
        dateFormat: 'y|M|d',
        fixed: ['A1', 'A2'],
        itemList: 'fixed1|file|date|fixed2',
        separator: '_',
        sequenceType: 0,
        lockEdit: false,
    },
};

const validCreateEndpointResponse = {
    id: 'whatever',
    buttonColor: schema.V1ObjectsEndpointsButtonColor.Crimson,
    downloadable: true,
    iconName: 'icon012.png',
    name: 'whatever-endpoint',
    path: '/endpoint/path/',
    pathId: 'endpoint-pathId',
    uploadable: false,
    xStorage: '303030303030303030303031',
    docNameId: 'whatever',
};

const extensionsRes = {
    extensions: [
        {
            id: '303030303030303030303031',
            isStopWhenError: false,
            name: 'extension-001',
            deleteFlag: false,
            workspaceExtensionId: '303030303030303030303031',
            extensionType: schema.ExtensionType.Scan,
        },
        {
            id: '303030303030303030303032',
            isStopWhenError: false,
            name: 'extension-002',
            deleteFlag: false,
            workspaceExtensionId: '303030303030303030303032',
            extensionType: schema.ExtensionType.Scan,
        },
    ],
};

const extensionSettingReq = {
    email: 'genky-test@gmail.com',
    endpointButtonColor: schema.V1ObjectsEndpointsButtonColor.Crimson,
    endpointIcon: 'icon023.png',
    endpointId: '303030303030303030303031',
    endpointName: 'gdyasgoduo',
    name: 'kahsdsahv',
};

const extensionSettingRes = {
    authenCode: 'FYcQX/IrH+u31Wn/b4hgOmaZBOxYcF56mst1D82S6FT5bGeyol36aNECtHTHynWA',
};

const workspaceExtensionList = {
    extensions: [
        {
            id: '303030303030303030303031',
            companyName: 'covas-sios',
            description: 'Whatever111',
            name: 'extension-001',
            version: 'ver.1.0.1',
        },
        {
            id: '303030303030303030303032',
            companyName: 'covas-sios',
            description: 'Whatever222',
            name: 'extension-002',
            version: 'ver.1.0.1',
        },
    ],
};

const getTokenRes = {
    token:
        `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbmRwb2ludElkIjoiMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxIiwic2VjcmV0S2V5Ijoi` +
        `NmE4YTQ0NTJlYjlmNTYzNDkxZDgzYWFiNTY0N2Y2MWNkNmJiOWM5YTg4ZjliNjMwZmE3Zjk1YmY1ZTU0N2Y3NWI2NWU2MDEwZDhmZTA5NDA2NDM3Nj` +
        `QyNDJjZmZiMGZlMjA4ZjFhMzYwMTllMTg1NWE0MGFmNjZkYTZjNTY5MmUiLCJpYXQiOjE1NjY4ODkyNDl9.WqezKxCSdKS1_YCNPPrEV0gkE_yB8qVrPhcl-_Db0t0`,
};

const getExtensionSettingRes = {
    settings: [
        {
            id: '303030303030303030303031',
            endpointId: '303030303030303030303031',
            endpointName: 'gdyasgoduo',
            endpointIcon: 'icon023.png',
            endpointButtonColor: schema.V1ObjectsEndpointsButtonColor.Crimson,
            isDaily: false,
            isWeekly: true,
            isEveryTime: false,
        },
    ],
};

const updateExtensionSettingReq = {
    isDaily: false,
    isWeekly: true,
    isEveryTime: false,
};

const updateExtensionSettingRes = {
    id: '303030303030303030303031',
    endpointId: '303030303030303030303031',
    endpointName: 'gdyasgoduo',
    endpointIcon: 'icon023.png',
    endpointButtonColor: schema.V1ObjectsEndpointsButtonColor.Crimson,
    isDaily: false,
    isWeekly: true,
    isEveryTime: false,
};

const responseGetSpool = {
    data: [
        {
            id: '5d7f09f5164a290118b2f746',
            userName: 'genky',
            email: 'genky@gmail.com',
            avatarUrl: '',
            createdAt: 1568606709060,
            endpointName: 'Box',
            endpointIcon: 'icon002.png',
            endpointBtnColor: schema.V1ObjectsEndpointsButtonColor.Crimson,
            processStatus: schema.ProcessStatus.Stopped,
            processHistory: [
                {
                    type: schema.V1ObjectsSpoolersProcessTypeEnum.Download,
                    result: schema.Result.Success,
                    updatedAt: 1568606713790,
                },
            ],
            owner: 'Genki Test',
            folder: '/',
            files: ['file1.pdf'],
            deviceName: '',
            expiryDate: 1569816313791,
        },
    ],
};

const wordbookResponse = {
    templates: [
        {
            name: 'geni-test-type',
            wordList: ['genki-1', 'genki-2'],
        },
        {
            name: 'geni-test-type1',
            wordList: ['genki-3', 'genki-4'],
        },
        {
            name: 'geni-test-type2',
            wordList: ['genki-5', 'genki-6'],
        },
        {
            name: 'geni-test-type3',
            wordList: ['genki-7', 'genki-8'],
        },
    ],
};

const wordListResponse = {
    templates: {
        name: 'geni-test-type',
        wordList: ['genki-1', 'genki-2'],
    },
};

const validDefaultSettingResponse = {
    docNameFormat: {
        dateFormat: 'y|M|d|Hms',
        fixed: ['123'],
        itemList: 'fixed1',
        separator: '',
        fileOptional: false,
        lockEdit: false,
        sequenceType: 0,
    },
    endpoint: {
        id: '5de72d1d2a00e301e1b8bd23',
        name: '1',
        iconName: 'icon045.png',
        buttonColor: schema.V1ObjectsEndpointsButtonColor.Crimson,
        type: schema.V1ObjectsServiceEnum.Googledrive,
        metadata: {},
        cloudAccount: 'phukhanhnguyen0303@gmail.com',
        folderPath: '/',
        uploadable: false,
        downloadable: true,
        xStorageId: '5de61ea09f9193061c91a089',
        isDisable: false,
        extensionPrintFails: [],
        extensionScanFails: [],
        wsUploadable: false,
        wsDownloadable: true,
        isStorageAvailable: true,
        serviceCompleted: false,
        serviceError: false,
    },
    wordListData: [],
    defaultSettings: [
        {
            pdfAttributeData: {},
            printAttributeData: {
                displayPdf: true,
                displayXls: false,
                displayDoc: false,
                displayPpt: false,
                color: 0,
                copies: 1,
                mediaSizeName: 1,
                mediaTray: 0,
                numberUp: 0,
                presentationDirection: 0,
                punch: 0,
                separatorLine: false,
                sheetCollate: 1,
                sides: 0,
                staple: 0,
            },
            scanAttributeData: {
                aggregation: false,
                autoDensity: false,
                batch: false,
                colorType: 7,
                density: 5,
                eraseBorder: false,
                eraseBorderWidthBottom: 10,
                eraseBorderWidthLeft: 10,
                eraseBorderWidthRight: 10,
                eraseBorderWidthTop: 10,
                eraseCenterWidth: 10,
                eraseBorderWidthSame: 10,
                eraseCenter: false,
                eraseSettingSame: false,
                mixedSize: false,
                outputType: 0,
                preset1Horizontal: 0,
                preset1Vertical: 0,
                preset2Horizontal: 0,
                preset2Vertical: 0,
                preset3Horizontal: 0,
                preset3Vertical: 0,
                resolution: 200,
                rotate: false,
                sadf: false,
                scanPreview: true,
                size: 0,
                scanBothSide: false,
                clippedVertical: true,
                resolutionLock: false,
                colorTypeLock: false,
                omitBlankPage: false,
            },
            mfpInfo: {
                deviceName: 'alreadyExistDeviceName',
                mfpId: '000000000001',
            },
            createdAt: '2019-12-05T03:59:28.662Z',
            isExistDefault: false,
            id: '5de880a069c4340232e82ffe',
            paramData: {
                overwrite: 0,
                scanOverwriteAlert: true,
                folderDepth: 2,
                autoSelect: false,
                iconVisible: true,
                folderTitle1: 'フォルダ1',
                folderTitle2: 'フォルダ2',
                folderTitle3: 'フォルダ3',
                folderTitle4: 'フォルダ4',
                folderFormat: 0,
            },
        },
    ],
};

const testEndpointOrder: schema.V1ObjectsEndpointInfo[] = [
    {
        buttonColor: schema.V1ObjectsEndpointsButtonColor.DarkGray,
        cloudAccount: 'tora@sample.com',
        downloadable: true,
        folderPath: '/abc/xx/de/',
        iconName: 'icon001.png',
        id: '303030303030303030303031',
        metadata: {},
        name: 'Endpoint1',
        type: schema.V1ObjectsServiceEnum.Dropbox,
        uploadable: true,
    },
    {
        buttonColor: schema.V1ObjectsEndpointsButtonColor.DarkGray,
        cloudAccount: 'tora@sample.com',
        downloadable: true,
        folderPath: '/abc/xx/de/',
        iconName: 'icon001.png',
        id: '303030303030303030303032',
        metadata: {},
        name: 'Endpoint2',
        type: schema.V1ObjectsServiceEnum.Dropbox,
        uploadable: true,
    },
    {
        buttonColor: schema.V1ObjectsEndpointsButtonColor.DarkGray,
        cloudAccount: 'tora@sample.com',
        downloadable: true,
        folderPath: '/abc/xx/de/',
        iconName: 'icon001.png',
        id: '303030303030303030303033',
        metadata: {},
        name: 'Endpoint2',
        type: schema.V1ObjectsServiceEnum.Dropbox,
        uploadable: true,
    },
    {
        buttonColor: schema.V1ObjectsEndpointsButtonColor.DarkGray,
        cloudAccount: 'tora@sample.com',
        downloadable: true,
        folderPath: '/abc/xx/de/',
        iconName: 'icon001.png',
        id: '303030303030303030303034',
        metadata: {},
        name: 'Endpoint2',
        type: schema.V1ObjectsServiceEnum.Dropbox,
        uploadable: true,
    },
];

export default {
    mfpUsersSucceedRes,
    mfpsSucceedRes,
    endpointsResults,
    endpoint,
    correctUpdateRequest,
    endpointSucceedRes,
    deleteResMfpUser,
    endpointUpdateValidReq,
    endpointUpdateValidRes,
    resquestNameRule,
    responseGetNameRule,
    responseUpdateNameRule,
    getXStorageRes,
    validCreateEndpointRequest,
    validCreateEndpointResponse,
    dataXStorages,
    extensionsRes,
    extensionSettingReq,
    extensionSettingRes,
    workspaceExtensionList,
    getTokenRes,
    getExtensionSettingRes,
    updateExtensionSettingReq,
    updateExtensionSettingRes,
    responseGetSpool,
    wordbookResponse,
    wordListResponse,
    validDefaultSettingResponse,
    testEndpointOrder,
};
