import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import querystring from 'querystring';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

/**
 * クラウド接続一覧取得
 * @param sub ユーザのID？
 * @param auth 認証情報
 * @param share 共有かどうか 0:非共有、1:共有
 * @param skipDecryption スキップ
 * @param offset
 * @param limit 1回の取得上限
 */
export const getCloudsByUser = async (sub: string, auth: string, share: number, skipDecryption?: boolean, offset?: number, limit?: number): Promise<schema.V1XStoragesIndexResponse> => {
    const query = querystring.stringify({
        offset,
        limit,
        skipDecryption,
        share,
        userSub: encodeURIComponent(sub),
    });

    const response = await axiosFactory.get<schema.V1XStoragesIndexResponse>(`https://${consts.environment.api.hostname}/v1/x-storages?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

/**
 * クラウドストレージ作成
 * @param req 作成リクエスト　v1.6.0で share 項目追加 v1.12.0で scope 追加
 * @param auth 認証情報
 */
export const createUserCloud = async (req: schema.V1XStoragesCreateRequest, auth: string): Promise<schema.V1XStoragesCreateResponse> => {
    const response = await axiosFactory.post<schema.V1XStoragesCreateResponse>(`https://${consts.environment.api.hostname}/v1/x-storages`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const generatorAccessKey = async (req: schema.V1XStoragesAuthCreateRequest, auth: string): Promise<schema.V1XStoragesAuthCreateResponse> => {
    const response = await axiosFactory.post<schema.V1XStoragesAuthCreateResponse>(`https://${consts.environment.api.hostname}/v1/x-storages/access-key`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const generatorDocardAccessKey = async (req: schema.V1XStoragesAuthCreateRequest, auth: string): Promise<schema.V1XStoragesAuthCreateResponse> => {
    const response = await axiosFactory.post<schema.V1XStoragesAuthCreateResponse>(`https://${consts.environment.api.hostname}/v1/x-storages/docard-access-key`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const getAuthorLink = async (service: schema.V1ObjectsServiceEnum, auth: string): Promise<schema.V1XStoragesAuthorLinkIndexResponse> => {
    const query = querystring.stringify({
        service,
    });
    const response = await axiosFactory.get<schema.V1XStoragesAuthorLinkIndexResponse>(`https://${consts.environment.api.hostname}/v1/x-storages/link?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

/**
 * クラウド接続の更新
 * @param userId
 * @param storageId
 * @param req share フラグ追加
 * @param auth
 */
export const updateUserCloud = async (userId: string, storageId: string, req: schema.V1XStoragesUpdateRequest, auth: string): Promise<schema.V1XStoragesUpdateResponse> => {
    const responce = await axiosFactory.put<schema.V1XStoragesUpdateResponse>(`https://${consts.environment.api.hostname}/v1/x-storages/${storageId}`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return responce.data;
};

/**
 * クラウド接続の削除
 * @param storageId 削除するストレージID
 * @param auth 認証情報
 * @param share 個人・共有のフラグ
 */
export const destroyUserCloud = async (storageId: string, auth: string, share: number): Promise<schema.V1XStoragesDestroyResponse> => {
    const response = await axiosFactory.delete<schema.V1XStoragesDestroyResponse>(`https://${consts.environment.api.hostname}/v1/x-storages/${storageId}/shareformat/${share}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
