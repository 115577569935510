import { useState } from 'react';
import * as schema from '@/bundles/schema/typescript/schema';
import { showEndpointById, updateEndpointsById } from '../../../user/models/endpoints/endpoints';
import * as groupModel from '@/common/api/groups';
import { findWorkspaceUser } from '@/common/api/workspace/workspace';
import { getWorkspaceMfp } from '@/common/api/workspace/mfp/mfp';
import userRole from '@/common/constants/userRole';
import locale from '@/common/utils/locale';
import environment from '@/common/constants/environment';

/**
 * @param applicationPermission APIから取得したアプリケーション許可リスト
 * @param tenant 利用中のテナント
 * @description アプリケーション許可リストを表示用のデータに格納
 */
export const mappingApplicationPermission = (applicationPermission: schema.V1ObjectsEndpointsPermissionsApplication[], tenant: schema.Brand): { scan: boolean; fax: boolean } => {
    const result = {
        scan: false,
        fax: false,
    };
    applicationPermission.forEach((permission) => {
        if (tenant === schema.Brand.Otsuka) {
            if (permission.application && permission.application === schema.V1ObjectsClientApp.Quickscan) {
                result.scan = true;
            } else if (permission.application && permission.application === schema.V1ObjectsClientApp.Easyfax) {
                result.fax = true;
            }
        } else {
            if (permission.application && permission.application === schema.V1ObjectsClientApp.Speedoc) {
                result.scan = true;
            } else if (permission.application && permission.application === schema.V1ObjectsClientApp.Smartecofax) {
                result.fax = true;
            }
        }
    });
    return result;
};

export interface MfpPermissionRow {
    id: string;
    deviceName: string;
    memberCount: number;
    mfpLocation: string;
    mfpName: string;
    mfpNumber: string;
    status: schema.V1ObjectsWorkspacemfpStatus;
}

/**
 * @param mfpPermission APIから取得した複合機許可リスト
 * @description 複合機許可リストを表示用のデータに格納
 */
export const mappingMfpPermissionRow = (
    mfpPermission: schema.V1ObjectsEndpointsPermissionsMfp[],
    wsMfpList: schema.V1ObjectsWorkspacemfp[],
): { permissionRow: MfpPermissionRow[]; selected: string[] } => {
    const permissionRow: MfpPermissionRow[] = [];
    const selected: string[] = [];
    wsMfpList.forEach((mfp) => {
        if (mfp.status && mfp.status === schema.V1ObjectsWorkspacemfpStatus.Active) {
            permissionRow.push({
                id: mfp.id,
                deviceName: mfp.deviceName,
                memberCount: mfp.memberCount ? mfp.memberCount : 0, // メンバー数がundefinedのことはない。
                mfpLocation: mfp.mfpLocation,
                mfpName: mfp.mfpName,
                mfpNumber: mfp.mfpNumber,
                status: mfp.status,
            });
        }

        if (mfpPermission.find((permission) => permission.mfp && permission.mfp.id === mfp.id)) {
            selected.push(mfp.id);
        }
    });
    return { permissionRow, selected };
};

export interface UserPermissionRow {
    id: string;
    name: string;
    invitationEmail: string;
    avatarUrl: string;
    role: number;
}

/**
 * @param userPermission APIから取得したユーザー許可リスト
 * @param wsGroupList APIから取得した登録済みのユーザーリスト
 * @description ユーザー許可リストと選択済みのユーザーIDを返却
 */
export const mappingUserPermissionRow = (
    userPermission: schema.V1ObjectsEndpointsPermissionsUser[],
    wsUserList: schema.V1ObjectsWorkspaceuserLarge[],
): { permissionRow: UserPermissionRow[]; selected: string[] } => {
    const permissionRow: UserPermissionRow[] = [];
    const selected: string[] = [];
    wsUserList.forEach((user) => {
        if (userPermission.find((permission) => permission.user && permission.user.id === user.id)) {
            selected.push(user.id);
        }
        if (!user.invitationVerified || !user.active) return;
        permissionRow.push({
            id: user.id,
            name: user.name,
            invitationEmail: user.invitationEmail,
            avatarUrl: user.avatarUrl ? user.avatarUrl : '',
            role: user.role,
        });
    });
    return { permissionRow, selected };
};

export interface GroupPermissionRow {
    id: string;
    name: string;
    avatarUrl: string;
    members: {
        id: string;
    }[];
}

/**
 * @param groupPermission APIから取得した選択済みグループ許可リスト
 * @param wsGroupList APIから取得した登録済みのグループリスト
 * @description グループ許可リストと選択済みのグループIDを返却
 */
export const mappingGroupPermissionRow = (
    groupPermission: schema.V1ObjectsEndpointsPermissionsGroup[],
    wsGroupList: schema.V1WorkspaceGroupsShowResponse[],
): { permissionRow: GroupPermissionRow[]; selected: string[] } => {
    const permissionRow: GroupPermissionRow[] = [];
    const selected: string[] = [];
    wsGroupList.forEach((group) => {
        // Everyoneは非表示
        if (group.groupName === schema.V1ObjectsDefaultGroup.Everyone) return;
        const groupMembers = group.members ? group.members.map((member) => ({ id: member.id })) : [];
        permissionRow.push({
            id: group.id,
            name: group.groupName,
            avatarUrl: group.avatarUrl ? group.avatarUrl : '',
            members: groupMembers,
        });
        if (groupPermission.find((permission) => permission.group && permission.group.id === group.id)) {
            selected.push(group.id);
        }
    });
    return { permissionRow, selected };
};

/**
 * @param rows 複合機許可リスト
 * @param selected 選択済みの複合機ID
 * @param isSelectAll 全選択(true)または全選択解除(false)
 * @description 全選択または全選択解除
 */
export const selectPermittedMfpAll = (rows: MfpPermissionRow[], selected: string[], isSelectAll: boolean): string[] => {
    if (isSelectAll) {
        const newSelected = rows.filter((row) => !selected.includes(row.id)).map((row) => row.id);
        return [...selected, ...newSelected];
    } else {
        return selected.filter((id) => !rows.map((row) => row.id).includes(id));
    }
};

/**
 * @param selected 選択済みの複合機ID
 * @param target 選択する複合機ID
 * @description 複合機の選択
 */
export const selectPermittedMfp = (selected: string[], target: string) => {
    const index = selected.indexOf(target);
    if (index === -1) {
        return [...selected, target];
    }
    return selected.filter((id) => id !== target);
};

/**
 * @param selected 選択済みの複合機ID
 * @param rows 複合機許可リスト
 * @description 全選択されているか判定する
 */
export const isSelectedAllMfp = (selected: string[], rows: MfpPermissionRow[]) => {
    return rows.every((row) => selected.includes(row.id));
};

/**
 * @param rows
 * @param rowPerPage
 * @param offset
 * @description ページング結果
 */
const filterMfpByPage = (rows: MfpPermissionRow[], rowPerPage: number, offset: number): MfpPermissionRow[] => {
    return rows.slice(offset, offset + rowPerPage);
};

/**
 * @param rows
 * @param searchText
 * @description 機械番号、複合機名の部分一致検索
 */
const filterMfpBySearchText = (rows: MfpPermissionRow[], searchText: string): MfpPermissionRow[] => {
    return rows.filter((row) => {
        return row.mfpNumber.includes(searchText) || row.deviceName.includes(searchText);
    });
};
/**
 * @params rows 複合機許可リスト
 * @params searchText 検索文字列
 * @params rowPerPage 1ページあたりの表示件数
 * @params offset オフセット
 * @params shouldPaging ページングする(true)またはページングしない(false)
 * @description 複合機許可リストの表示
 */
export const getMfpPermission = (rows: MfpPermissionRow[], searchText: string, rowPerPage: number, offset: number, shouldPaging: boolean): MfpPermissionRow[] => {
    const searchedRows = filterMfpBySearchText(rows, searchText);
    return shouldPaging ? filterMfpByPage(searchedRows, rowPerPage, offset) : searchedRows;
};

export const roleToString = (role: number): string => {
    switch (role) {
        case userRole.systemAdmin:
            return locale.t(locale.keys.common.role.systemAdmin);
        case userRole.admin:
            return locale.t(locale.keys.common.role.admin);
        case userRole.externalAdmin:
            return locale.t(locale.keys.common.role.externalAdmin);
        case userRole.member:
            return locale.t(locale.keys.common.role.user);
    }
    return '';
};

/**
 * @param rows ユーザー許可リスト
 * @param role 権限（ロール）
 * @description 権限（ロール）によるユーザーの選択
 */
export const selectPermittedUserByRole = (rows: UserPermissionRow[], role: number): string[] => {
    return rows.filter((row) => row.role === role).map((row) => row.id);
};

/**
 * @param rows ユーザー許可リスト
 * @param selected 選択済みのユーザーID
 * @param isSelectAll 全選択(true)または全選択解除(false)
 * @description 全選択または全選択解除
 */
export const selectPermittedUserAll = (rows: UserPermissionRow[], selected: string[], isSelectAll: boolean): string[] => {
    if (isSelectAll) {
        const newSelected = rows.filter((row) => !selected.includes(row.id)).map((row) => row.id);
        return [...selected, ...newSelected];
    } else {
        return selected.filter((id) => !rows.map((row) => row.role !== userRole.admin && row.id).includes(id));
    }
};

/**
 * @param selected 選択済みのユーザーID
 * @param target 選択するユーザーID
 * @description ユーザーの選択
 */
export const selectPermittedUser = (selected: string[], target: string) => {
    const index = selected.indexOf(target);
    if (index === -1) {
        return [...selected, target];
    }
    return selected.filter((id) => id !== target);
};

/**
 * @param selected 選択済みのユーザーID
 * @param rows ユーザー許可リスト
 * @description 全選択されているか判定する
 */
export const isSelectedAllUser = (selected: string[], rows: UserPermissionRow[]) => {
    return rows.every((row) => selected.includes(row.id));
};

/**
 * @param rows
 * @param sortByName
 * @description 名前でソート
 */
const sortUserByName = (rows: UserPermissionRow[], sortByName: schema.V1ObjectsSort): UserPermissionRow[] => {
    const currentRows = [...rows];
    switch (sortByName) {
        case schema.V1ObjectsSort.Asc:
            return currentRows.sort((a, b) => a.name.localeCompare(b.name));
        case schema.V1ObjectsSort.Desc:
            return currentRows.sort((a, b) => b.name.localeCompare(a.name));
        default:
            return currentRows;
    }
};

/**
 * @param rows
 * @param rowPerPage
 * @param offset
 * @description ページング結果
 */
const filterUserByPage = (rows: UserPermissionRow[], rowPerPage: number, offset: number): UserPermissionRow[] => {
    return rows.slice(offset, offset + rowPerPage);
};

/**
 * @param rows
 * @param searchText
 * @description 名前、メールアドレスの部分一致検索
 */
const filterUserBySearchText = (rows: UserPermissionRow[], searchText: string) => {
    return rows.filter((row) => {
        return row.name.toLowerCase().includes(searchText.toLowerCase()) || row.invitationEmail.toLowerCase().includes(searchText.toLowerCase());
    });
};

/**
 * @param rows ユーザー許可リスト
 * @param searchText 検索文字列
 * @param rowPerPage 1ページあたりの表示件数
 * @param offset オフセット
 * @param sortByName ソート順(名前)
 * @params shouldPaging ページングする(true)またはページングしない(false)
 */
export const getUserPermission = (rows: UserPermissionRow[], searchText: string, rowPerPage: number, offset: number, sortByName: schema.V1ObjectsSort, shouldPaging: boolean): UserPermissionRow[] => {
    const searchedRows = filterUserBySearchText(rows, searchText);
    const sortedRows = sortUserByName(searchedRows, sortByName);
    return shouldPaging ? filterUserByPage(sortedRows, rowPerPage, offset) : sortedRows;
};

/**
 * @param rows グループ許可リスト
 * @param selected 選択済みのグループID
 * @param isSelectAll 全選択(true)または全選択解除(false)
 * @description 全選択または全選択解除
 */
export const selectPermittedGroupAll = (rows: GroupPermissionRow[], selected: string[], isSelectAll: boolean): string[] => {
    if (isSelectAll) {
        const newSelected = rows.filter((row) => !selected.includes(row.id)).map((row) => row.id);
        return [...selected, ...newSelected];
    } else {
        return selected.filter((id) => !rows.map((row) => row.id).includes(id));
    }
};

/**
 * @param selected 選択済みのグループID
 * @param target 選択するグループID
 * @description グループの選択
 */
export const selectPermittedGroup = (selected: string[], target: string) => {
    const index = selected.indexOf(target);
    if (index === -1) {
        return [...selected, target];
    }
    return selected.filter((id) => id !== target);
};

/**
 * @param selected 選択済みのグループID
 * @param rows グループ許可リスト
 * @description 全選択されているか判定する
 */
export const isSelectedAllGroup = (selected: string[], rows: GroupPermissionRow[]) => {
    return rows.every((row) => selected.includes(row.id));
};

/**
 * @param rows
 * @param sortByName
 * @description 名前でソート
 */
const sortGroupByName = (rows: GroupPermissionRow[], sortByName: schema.V1ObjectsSort): GroupPermissionRow[] => {
    const currentRows = [...rows];
    switch (sortByName) {
        case schema.V1ObjectsSort.Asc:
            return currentRows.sort((a, b) => a.name.localeCompare(b.name));
        case schema.V1ObjectsSort.Desc:
            return currentRows.sort((a, b) => b.name.localeCompare(a.name));
        default:
            return currentRows;
    }
};

/**
 * @param rows
 * @param rowPerPage
 * @param offset
 * @description ページング結果
 */
const filterGroupByPage = (rows: GroupPermissionRow[], rowPerPage: number, offset: number): GroupPermissionRow[] => {
    return rows.slice(offset, offset + rowPerPage);
};

/**
 * @param rows
 * @param searchText
 * @description グループ名の部分一致検索
 */
const filterGroupBySearchText = (rows: GroupPermissionRow[], searchText: string) => {
    return rows.filter((row) => {
        return row.name.toLowerCase().includes(searchText.toLowerCase());
    });
};

/**
 * @param rows グループ許可リスト
 * @param searchText 検索文字列
 * @param rowPerPage 1ページあたりの表示件数
 * @param offset オフセット
 * @param sortByName ソート順(名前)
 * @params shouldPaging ページングする(true)またはページングしない(false)
 */
export const getGroupPermission = (
    rows: GroupPermissionRow[],
    searchText: string,
    rowPerPage: number,
    offset: number,
    sortByName: schema.V1ObjectsSort,
    shouldPaging: boolean,
): GroupPermissionRow[] => {
    const searchedRows = filterGroupBySearchText(rows, searchText);
    const sortedRows = sortGroupByName(searchedRows, sortByName);
    return shouldPaging ? filterGroupByPage(sortedRows, rowPerPage, offset) : sortedRows;
};

/**
 * @param selectedUserList 選択済みのユーザーID
 * @param selectedGroupList 選択済みのグループID
 * @param groupRow グループ許可リスト(グループに所属するユーザー情報を参照するため)
 * @description 選択済みのユーザーIDリスト（選択したグループに所属するユーザーと選択したユーザーの重複を除いた件数）
 */
export const selectedUserCount = (selectedUserList: string[], selectedGroupList: string[], groupRow: GroupPermissionRow[]) => {
    const selectedUserListFromGroup: string[] = [];
    groupRow.forEach((group) => {
        if (selectedGroupList.includes(group.id)) {
            group.members.forEach((member) => {
                selectedUserListFromGroup.push(member.id);
            });
        }
    });

    const uniqueUserList = selectedUserListFromGroup.concat(selectedUserList).filter((value, index, self) => {
        return self.indexOf(value) === index;
    });

    return uniqueUserList;
};

/**
 * @param permission 利用制限の有効/無効フラグ
 * @param permissionRow オリジナルのデータ
 * @param defaultSelected APIから取得した時点での利用制限の選択状態
 * @description 利用制限の有効/無効に応じて選択状態をリセットする処理
 */
export const togglePermission = (
    permission: { mfpPermission: boolean; userGroupPermission: boolean; applicationPermission: boolean },
    permissionRow: {
        mfpPermission: MfpPermissionRow[];
        userPermission: UserPermissionRow[];
        groupPermission: GroupPermissionRow[];
    },
    defaultSelected: {
        defaultMfpPermission: string[];
        defaultUserPermission: string[];
        defaultGroupPermission: string[];
        defaultApplicationPermission: { scan: boolean; fax: boolean };
    },
) => {
    const result = {
        selectedMfpPermission: [] as string[],
        selectedUserPermission: [] as string[],
        selectedGroupPermission: [] as string[],
        applicationPermission: { scan: false, fax: false },
    };

    result.selectedMfpPermission = defaultSelected.defaultMfpPermission;

    if (permission.userGroupPermission) {
        // 1.11.0の仕様で管理者権限ユーザーは利用制限の対象外とするために、管理者権限ユーザーを選択済みにする
        // TODO:将来的に管理者権限ユーザーを利用制限可能となった場合には、下記の処理の中から管理者権限ユーザーを選択済みにする処理を削除する
        const adminUser = permissionRow.userPermission.filter((user) => user.role === userRole.admin).map((user) => user.id);
        const uniqueUser = adminUser.concat(defaultSelected.defaultUserPermission).filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
        result.selectedUserPermission = uniqueUser;

        result.selectedGroupPermission = defaultSelected.defaultGroupPermission;
    } else {
        result.selectedUserPermission = defaultSelected.defaultUserPermission;
        result.selectedGroupPermission = defaultSelected.defaultGroupPermission;
    }

    result.applicationPermission = defaultSelected.defaultApplicationPermission;

    return result;
};

/**
 * @description 通常業務作成時のリクエストデータの作成
 * TODO:将来的にここにリクエストデータの作成ロジックを集約する。
 */
export const createEndpoint = (
    tenant: schema.Brand,
    allowedApplications?: { scan: boolean; fax: boolean },
    allowedMfps?: string[],
    allowedUsers?: string[],
    allowedGroups?: string[],
    enablePermission?: {
        applicationPermission: boolean;
        userGroupPermission: boolean;
        mfpPermission: boolean;
    },
): {
    allowedApplications: schema.V1ObjectsClientApp[];
    allowedMfps: string[];
    allowedUsers: string[];
    allowedGroups: string[];
    enablePermission: {
        applicationPermission: boolean;
        userGroupPermission: boolean;
        mfpPermission: boolean;
    };
} => {
    const result = {
        allowedApplications: [] as schema.V1ObjectsClientApp[],
        allowedMfps: [] as string[],
        allowedUsers: [] as string[],
        allowedGroups: [] as string[],
        enablePermission: {
            applicationPermission: enablePermission ? enablePermission.applicationPermission : false,
            userGroupPermission: enablePermission ? enablePermission.userGroupPermission : false,
            mfpPermission: enablePermission ? enablePermission.mfpPermission : false,
        },
    };

    // 利用可能アプリケーションの設定
    if (allowedApplications) {
        if (tenant === schema.Brand.Otsuka) {
            if (allowedApplications.scan) {
                result.allowedApplications.push(schema.V1ObjectsClientApp.Quickscan);
            }
            if (allowedApplications.fax) {
                result.allowedApplications.push(schema.V1ObjectsClientApp.Easyfax);
            }
        } else {
            if (allowedApplications.scan) {
                result.allowedApplications.push(schema.V1ObjectsClientApp.Speedoc);
            }
            if (allowedApplications.fax) {
                result.allowedApplications.push(schema.V1ObjectsClientApp.Smartecofax);
            }
        }
    }

    // 利用可能複合機の設定
    if (allowedMfps) {
        result.allowedMfps = allowedMfps;
    }

    // 利用可能ユーザーの設定
    if (allowedUsers) {
        result.allowedUsers = allowedUsers;
    }

    // 利用可能グループの設定
    if (allowedGroups) {
        result.allowedGroups = allowedGroups;
    }

    return result;
};

/**
 * @description 通常業務更新時のリクエストデータの作成
 */
export const updateEndpoint = (
    tenant: schema.Brand,
    allowedApplications?: { scan: boolean; fax: boolean },
    enablePermission?: {
        applicationPermission: boolean;
        userGroupPermission: boolean;
        mfpPermission: boolean;
    },
    allowedMfps?: string[],
    allowedUsers?: string[],
    allowedGroups?: string[],
): {
    applicationPermission: schema.V1ObjectsClientApp[];
    applicationPermissionSetting: boolean;
    mfpPermissionSetting: boolean;
    userPermissionSetting: boolean;
    groupPermission: string[];
    mfpPermission: string[];
    userPermission: string[];
} => {
    const result = {
        applicationPermission: [] as schema.V1ObjectsClientApp[],
        applicationPermissionSetting: false,
        mfpPermissionSetting: false,
        userPermissionSetting: false,
        groupPermission: [] as string[],
        mfpPermission: [] as string[],
        userPermission: [] as string[],
    };

    // 利用制限の更新
    if (enablePermission) {
        result.applicationPermissionSetting = enablePermission.applicationPermission;
        result.mfpPermissionSetting = enablePermission.mfpPermission;
        result.userPermissionSetting = enablePermission.userGroupPermission;

        // 利用可能アプリケーションの設定
        if (enablePermission.applicationPermission && allowedApplications) {
            const applicationPermission = [] as schema.V1ObjectsClientApp[];
            if (tenant === schema.Brand.Otsuka) {
                if (allowedApplications.scan) {
                    applicationPermission.push(schema.V1ObjectsClientApp.Quickscan);
                }
                if (allowedApplications.fax) {
                    applicationPermission.push(schema.V1ObjectsClientApp.Easyfax);
                }
            } else {
                if (allowedApplications.scan) {
                    applicationPermission.push(schema.V1ObjectsClientApp.Speedoc);
                }
                if (allowedApplications.fax) {
                    applicationPermission.push(schema.V1ObjectsClientApp.Smartecofax);
                }
            }
            result.applicationPermission = applicationPermission;
        } else {
            result.applicationPermission = [];
        }

        // 利用可能複合機の設定
        if (enablePermission.mfpPermission && allowedMfps) {
            result.mfpPermission = allowedMfps;
        } else {
            result.mfpPermission = [];
        }

        // 利用可能ユーザー、グループの設定
        if (enablePermission.userGroupPermission) {
            if (allowedUsers) {
                result.userPermission = allowedUsers;
            } else {
                result.userPermission = [];
            }
            if (allowedGroups) {
                result.groupPermission = allowedGroups;
            } else {
                result.groupPermission = [];
            }
        } else {
            result.userPermission = [];
            result.groupPermission = [];
        }
    }

    return result;
};

/**
 * @param isShared 通常業務/転送先か通常業務（共有）か
 * @description
 * 通常業務機能のドメインロジックをまとめたカスタムフック
 */
export const useEndpoint = (isShared: boolean) => {
    const [endpointData, setEndpointData] = useState<schema.V1EndpointsUpdateResponse | null>(null);
    const [enablePermission, setEnablePermission] = useState({
        applicationPermission: false,
        userGroupPermission: false,
        mfpPermission: false,
    });
    const [wsData, setWsData] = useState({
        wsMfpList: [] as schema.V1ObjectsWorkspacemfp[],
        wsUserList: [] as schema.V1ObjectsWorkspaceuserLarge[],
        wsGroupList: [] as schema.V1WorkspaceGroupsShowResponse[],
    });
    const [permissionRows, setPermissionRows] = useState({
        mfpPermission: [] as MfpPermissionRow[],
        userPermission: [] as UserPermissionRow[],
        groupPermission: [] as GroupPermissionRow[],
    });
    const [selectedMfpPermission, setSelectedMfpPermission] = useState<string[]>([]);
    const [selectedUserPermission, setSelectedUserPermission] = useState<string[]>([]);
    const [selectedGroupPermission, setSelectedGroupPermission] = useState<string[]>([]);
    const [applicationPermission, setApplicationPermission] = useState({
        scan: false,
        fax: false,
    });

    /**
     * @description 通常業務のCRUD処理に使用するためのワークスペースデータを取得
     */
    const loadWorkspaceData = async (workspaceId: string, auth: string, role: number, endpointData?: schema.V1EndpointsUpdateResponse | null) => {
        const result = {
            wsMfpList: [] as schema.V1ObjectsWorkspacemfp[],
            wsUserList: [] as schema.V1ObjectsWorkspaceuserLarge[],
            wsGroupList: [] as schema.V1WorkspaceGroupsShowResponse[],
        };

        // 通常業務/転送先と通常業務（共有）で取得するデータが異なる
        if (isShared) {
            // 外部管理者未満の権限を持つ場合はデータを取得しない
            if (role <= userRole.externalAdmin) {
                const mfpList = await getWorkspaceMfp(workspaceId, auth);
                result.wsMfpList = mfpList.mfps;

                const userList = await findWorkspaceUser(workspaceId, auth);
                result.wsUserList = userList.users;
                const groupList = await groupModel.indexGroup(workspaceId, auth);
                result.wsGroupList = groupList.groups;
            }
        } else {
            const mfpList = await getWorkspaceMfp(workspaceId, auth);
            result.wsMfpList = mfpList.mfps;
        }
        setWsData(result);

        // 利用制限を設定済みの場合は、ユーザー、グループ、複合機リストおよび既存の選択状態を返却
        if (endpointData) {
            const resultPermissionRow = {
                mfpPermission: [] as MfpPermissionRow[],
                userPermission: [] as UserPermissionRow[],
                groupPermission: [] as GroupPermissionRow[],
            };

            if (endpointData.endpoint.mfpPermission) {
                const { permissionRow: mfpPermission, selected: selectedMfp } = mappingMfpPermissionRow(endpointData.endpoint.mfpPermission, result.wsMfpList);
                resultPermissionRow.mfpPermission = mfpPermission;
                setSelectedMfpPermission(selectedMfp);
            }
            if (endpointData.endpoint.userPermission) {
                const { permissionRow: userPermission, selected: selectedUser } = mappingUserPermissionRow(endpointData.endpoint.userPermission, result.wsUserList);
                resultPermissionRow.userPermission = userPermission;
                setSelectedUserPermission(selectedUser);
            }
            if (endpointData.endpoint.groupPermission) {
                const { permissionRow: groupPermission, selected: selectedGroup } = mappingGroupPermissionRow(endpointData.endpoint.groupPermission, result.wsGroupList);
                resultPermissionRow.groupPermission = groupPermission;
                setSelectedGroupPermission(selectedGroup);
            }
            if (endpointData.endpoint.applicationPermission) {
                setApplicationPermission(mappingApplicationPermission(endpointData.endpoint.applicationPermission, environment.tenant));
            }

            setPermissionRows(resultPermissionRow);
            setEnablePermission({
                applicationPermission: endpointData.endpoint.applicationPermissionSetting ? true : false,
                userGroupPermission: endpointData.endpoint.userPermissionSetting ? true : false,
                mfpPermission: endpointData.endpoint.mfpPermissionSetting ? true : false,
            });
            // 利用制限を設定済みでない場合は、ユーザー、グループ、複合機リストおよび空の選択状態を返却
        } else {
            setPermissionRows({
                mfpPermission: mappingMfpPermissionRow([], result.wsMfpList).permissionRow,
                userPermission: mappingUserPermissionRow([], result.wsUserList).permissionRow,
                groupPermission: mappingGroupPermissionRow([], result.wsGroupList).permissionRow,
            });
        }

        return;
    };

    /**
     * @param endpointId エンドポイントID
     * @param auth 認証トークン
     * @param endpointsId エンドポイントID
     * @param role 実行するユーザーのロール
     * @description エンドポイントのデータを取得
     */
    const indexEndpointData = async (workspaceId: string, auth: string, endpointsId: string, role: number) => {
        const responseEndpointData = await showEndpointById(endpointsId, auth);
        setEndpointData(responseEndpointData);
        await loadWorkspaceData(workspaceId, auth, role, responseEndpointData);

        return responseEndpointData;
    };

    /**
     * @description エンドポイントのデータを更新
     */
    const updateEndpointData = async (auth: string, endpointsId: string, req: schema.V1EndpointsDetailsUpdateRequest) => {
        const res = await updateEndpointsById(endpointsId, req, auth);
        const updatedEndpointData: schema.V1EndpointsUpdateResponse = {
            ...endpointData!,
            endpoint: {
                ...endpointData!.endpoint,
                applicationPermission: res.applicationPermission,
                groupPermission: res.groupPermission,
                mfpPermission: res.mfpPermission,
                userPermission: res.userPermission,
            },
        };
        setEndpointData(updatedEndpointData);
    };

    /**
     * @param updateEnablePermission アプリケーション、ユーザーグループ、複合機の利用制限の有効/無効
     */
    const handleEnablePermisson = (updateEnablePermission: { applicationPermission: boolean; userGroupPermission: boolean; mfpPermission: boolean }) => {
        setEnablePermission(updateEnablePermission);

        if (endpointData) {
            const defaultMfpPermission = endpointData.endpoint.mfpPermission ? endpointData.endpoint.mfpPermission.map((mfpPermission) => mfpPermission.mfp!.id) : [];
            const defaultUserPermission = endpointData.endpoint.userPermission ? endpointData.endpoint.userPermission.map((userPermission) => userPermission.user!.id) : [];
            const defaultGroupPermission = endpointData.endpoint.groupPermission ? endpointData.endpoint.groupPermission.map((groupPermission) => groupPermission.group!.id!) : [];
            const defaultApplicationPermission = endpointData.endpoint.applicationPermission
                ? mappingApplicationPermission(endpointData.endpoint.applicationPermission, environment.tenant)
                : { scan: false, fax: false };
            const updateSelected = togglePermission(
                { ...updateEnablePermission },
                {
                    mfpPermission: permissionRows.mfpPermission,
                    userPermission: permissionRows.userPermission,
                    groupPermission: permissionRows.groupPermission,
                },
                {
                    defaultMfpPermission: defaultMfpPermission,
                    defaultUserPermission: defaultUserPermission,
                    defaultGroupPermission: defaultGroupPermission,
                    defaultApplicationPermission: defaultApplicationPermission,
                },
            );
            // 利用制限スイッチを切り替える前の状態と比較して、ON/OFFが切り替わっていればステートを更新する。
            if (enablePermission.mfpPermission !== updateEnablePermission.mfpPermission) setSelectedMfpPermission(updateSelected.selectedMfpPermission);
            if (enablePermission.userGroupPermission !== updateEnablePermission.userGroupPermission) setSelectedUserPermission(updateSelected.selectedUserPermission);
            if (enablePermission.userGroupPermission !== updateEnablePermission.userGroupPermission) setSelectedGroupPermission(updateSelected.selectedGroupPermission);
            if (enablePermission.applicationPermission !== updateEnablePermission.applicationPermission) setApplicationPermission(updateSelected.applicationPermission);
        } else {
            const updateSelected = togglePermission(
                { ...updateEnablePermission },
                {
                    mfpPermission: permissionRows.mfpPermission,
                    userPermission: permissionRows.userPermission,
                    groupPermission: permissionRows.groupPermission,
                },
                {
                    defaultMfpPermission: [],
                    defaultUserPermission: [],
                    defaultGroupPermission: [],
                    defaultApplicationPermission: { scan: false, fax: false },
                },
            );
            // 利用制限スイッチを切り替える前の状態と比較して、ON/OFFが切り替わっていればステートを更新する。
            if (enablePermission.mfpPermission !== updateEnablePermission.mfpPermission) setSelectedMfpPermission(updateSelected.selectedMfpPermission);
            if (enablePermission.userGroupPermission !== updateEnablePermission.userGroupPermission) setSelectedUserPermission(updateSelected.selectedUserPermission);
            if (enablePermission.userGroupPermission !== updateEnablePermission.userGroupPermission) setSelectedGroupPermission(updateSelected.selectedGroupPermission);
            if (enablePermission.applicationPermission !== updateEnablePermission.applicationPermission) setApplicationPermission(updateSelected.applicationPermission);
        }
    };

    /**
     * @description 複合機の利用制限
     */
    const handleMfpPermission = (mfpPermission: string[]) => {
        setSelectedMfpPermission(mfpPermission);
    };

    /**
     * @description ユーザーの利用制限
     */
    const handleUserPermission = (userPermission: string[]) => {
        setSelectedUserPermission(userPermission);
    };

    /**
     * @description グループの利用制限
     */
    const handleGroupPermission = (groupPermission: string[]) => {
        setSelectedGroupPermission(groupPermission);
    };

    /**
     * @description アプリケーションの利用制限
     */
    const handleApplicationPermission = (applicationPermission: { scan: boolean; fax: boolean }) => {
        setApplicationPermission(applicationPermission);
    };

    return {
        endpointData,
        enablePermission,
        selectedMfpPermission,
        selectedUserPermission,
        selectedGroupPermission,
        wsData,
        applicationPermission,
        handleEnablePermisson,
        handleMfpPermission,
        handleUserPermission,
        handleGroupPermission,
        handleApplicationPermission,
        indexEndpointData,
        updateEndpointData,
        loadWorkspaceData,
        permissionRows,
    };
};
