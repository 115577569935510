/**
 * 一括登録時にインポートするCSVのフォーマット
 * http://charset.7jp.net/sjis.html
 * https://javascript.programmer-reference.com/js-regexp-sample/
 */
export const csvFormat = {
    // カラム数(最低6個)
    COLUMNS: 6,
    // Eメールのインデックス
    COL_IDX_EMAIL: 0,
    // 名前のインデックス
    COL_IDX_NAME: 1,
    // ヨミガナのインデックス
    COL_IDX_PHONETICNAME: 2,
    // 言語のインデックス
    COL_IDX_LANGUAGE: 3,
    // 権限のインデックス
    COL_IDX_ROLE: 4,
    // パスワードのインデックス
    COL_IDX_PASSWORD: 5,
    // 複合機のログインユーザー名のインデックス
    COL_IDX_DEVICELOGINUSER: 6,
    // PIN
    COL_IDX_PIN: 7,
    // MFP NUMBER
    COL_IDX_MFPNUMBER: 8,
    // 連絡先Eメールアドレス
    COL_IDX_CONTACTEMAIL: 9,
    // グループ
    COL_IDX_GROUP: 10,
    // ヘッダー行
    CSV_HEADER: 2,
    // CSVの最大読み込み数
    CSV_MAX_LINE: 1000,
    // CSVファイル名 テンプレートのファイル名が29バイトなので30バイトまで許容する
    CSVFILENAME_MAX: 30,
    // BOM付き判定
    CSVFORMAT_BOM: /^[\x0x\xef][\x0x\xbb][\x0x\xbf]/,
    // 名前の文字数制限
    NAME_MAX_LENGTH: 80,
    // ニックネームの文字数制限
    PHONETIC_NAME_MAX_LENGTH: 80,
    // 言語 ja or en
    LANGUAGE: ['ja', 'en'],
    // 権限: admin or user or member
    // userとmemberは同じ権限。ユーザーのことをメンバーと呼称していた時の名残。
    USER_ROLE: ['admin', 'user', 'member'],
    // 複合機のログインユーザー
    DEVICELOGINUSER_MAX_LENGTH: 32,
    // 共有ログインユーザー利用可能文字(半角英数,指定した記号)
    // 正規表現チェッカー
    // https://www.softel.co.jp/labs/tools/regex/
    DEVICELOGINUSER: /[a-zA-Z0-9!#$%&'()*+,\-./;<=>?@[\]^_`{|}~\x5c]+/,
    DEVICELOGINUSER_OPTIONAL: /[a-zA-Z0-9!#$%&'()*+,\-./;<=>?@[\]^_`{|}~\x5c]*/,
    // 機械番号区切り文字
    MFPNUMBER_SPLIT: '|',
    // 機械番号
    MFPNUMBER: /(^[A-Za-z0-9]{4}-[0-9]{6}$)/,
    // グループ区切り文字
    GROUP_SPLIT: '|',
};

/**
 * 複合機一括登録のcsv
 */
export const csvFormat4Mfp = {
    // カラム数(最低3個)
    COLUMNS: 3,
    // 機械番号のインデックス
    COL_IDX_MFPNUMBER: 0,
    // 複合機の名前
    COL_IDX_MFPNAME: 1,
    // PIN省略
    COL_IDX_OMITPIN: 2,
    // Eメールのインデックス
    COL_IDX_EMAIL: 3,
    // グループのインデックス
    COL_IDX_GROUP: 4,
    // ヘッダー行
    CSV_HEADER: 2,
    // CSVの最大読み込み数
    CSV_MAX_LINE: 1000,
    // CSVファイル名 テンプレートのファイル名が29バイトなので30バイトまで許容する
    CSVFILENAME_MAX: 30,
    // BOM付き判定
    CSVFORMAT_BOM: /^[\x0x\xef][\x0x\xbb][\x0x\xbf]/,
    // Eメール区切り文字
    EMAIL_SPLIT: '|',
    // グループ名区切り文字
    GROUP_SPLIT: '|',
    // 機械番号
    MFPNUMBER: /(^[A-Za-z0-9]{4}-[0-9]{6}$)/,
    // PIN省略入力想定
    OMITPIN_TEMPLATE: ['true', 'TRUE', 'false', 'FALSE'],
};

/*
    各バージョンごとの最大カラム数
    後方互換性を保つために、下記に定義された列数のCSVがインポートできる
 */
export const csvColumnLimitEachVersion = {
    V1_8_3: 167,
    V1_9_0: 170,
    V1_10_0: 172,
    V1_11_0: 176,
};

/**
 * 通常業務/転送先一括登録のcsv
 */
export const csvFormat4Endpoint = {
    // カラム数(167個)
    COLUMNS: 167,

    //インデックス番号
    // Eメール
    COL_IDX_EMAIL: 0,
    // 通常業務名
    COL_IDX_NAME: 1,
    // アイコン
    COL_IDX_ICON: 2,
    // ラベル色
    COL_IDX_LABELCOLOR: 3,
    // スキャン可否
    COL_IDX_SCAN: 4,
    // プリント可否
    COL_IDX_PRINT: 5,
    // スプール操作完了時の通知
    COL_IDX_FINISHNOTICE: 6,
    // スプールエラー発生時の通知
    COL_IDX_ERRORNOTICE: 7,
    // 接続方法
    COL_IDX_CONNECTION: 8,
    // 認可ID
    COL_IDX_ID: 9,
    // サイト名
    COL_IDX_SITE: 10,
    // ライブラリ名
    COL_IDX_LIBRARY: 11,
    // ディレクトリ名
    COL_IDX_DIRECTORY: 12,
    // ファイル名形式
    COL_IDX_NAMEFORMAT: 13,
    // 年
    COL_IDX_YEAR: 14,
    // 月
    COL_IDX_MONTH: 15,
    // 日
    COL_IDX_DAY: 16,
    // 時分秒
    COL_IDX_TIME: 17,
    // 固定文字
    COL_IDX_FIXED: 18,
    // 任意文字未入力可否
    COL_IDX_OPTIONAL: 19,
    // セパレータ
    COL_IDX_SEPARATOR: 20,
    // 連番
    COL_IDX_SEQUENCE: 21,
    // 表示形式ロック
    COL_IDX_FORMATLOCK: 22,
    // 単語帳1
    COL_IDX_WORD1: 23,
    // 単語1-1
    COL_IDX_WORD1_1: 24,
    // 単語1-2
    COL_IDX_WORD1_2: 25,
    // 単語1-3
    COL_IDX_WORD1_3: 26,
    // 単語1-4
    COL_IDX_WORD1_4: 27,
    // 単語1-5
    COL_IDX_WORD1_5: 28,
    // 単語1-6
    COL_IDX_WORD1_6: 29,
    // 単語1-7
    COL_IDX_WORD1_7: 30,
    // 単語1-8
    COL_IDX_WORD1_8: 31,
    // 単語1-9
    COL_IDX_WORD1_9: 32,
    // 単語1-10
    COL_IDX_WORD1_10: 33,
    // 単語1-11
    COL_IDX_WORD1_11: 34,
    // 単語1-12
    COL_IDX_WORD1_12: 35,
    // 単語1-13
    COL_IDX_WORD1_13: 36,
    // 単語1-14
    COL_IDX_WORD1_14: 37,
    // 単語1-15
    COL_IDX_WORD1_15: 38,
    // 単語1-16
    COL_IDX_WORD1_16: 39,
    // 単語1-17
    COL_IDX_WORD1_17: 40,
    // 単語1-18
    COL_IDX_WORD1_18: 41,
    // 単語1-19
    COL_IDX_WORD1_19: 42,
    // 単語1-20
    COL_IDX_WORD1_20: 43,
    // 単語1-21
    COL_IDX_WORD1_21: 44,
    // 単語1-22
    COL_IDX_WORD1_22: 45,
    // 単語1-23
    COL_IDX_WORD1_23: 46,
    // 単語1-24
    COL_IDX_WORD1_24: 47,
    // 単語1-25
    COL_IDX_WORD1_25: 48,
    // 単語1-26
    COL_IDX_WORD1_26: 49,
    // 単語1-27
    COL_IDX_WORD1_27: 50,
    // 単語1-28
    COL_IDX_WORD1_28: 51,
    // 単語1-29
    COL_IDX_WORD1_29: 52,
    // 単語1-30
    COL_IDX_WORD1_30: 53,
    // 単語1-31
    COL_IDX_WORD1_31: 54,
    // 単語1-32
    COL_IDX_WORD1_32: 55,
    // 単語1-33
    COL_IDX_WORD1_33: 56,
    // 単語1-34
    COL_IDX_WORD1_34: 57,
    // 単語1-35
    COL_IDX_WORD1_35: 58,
    // 単語1-36
    COL_IDX_WORD1_36: 59,
    // 単語1-37
    COL_IDX_WORD1_37: 60,
    // 単語1-38
    COL_IDX_WORD1_38: 61,
    // 単語1-39
    COL_IDX_WORD1_39: 62,
    // 単語1-40
    COL_IDX_WORD1_40: 63,
    // 単語1-41
    COL_IDX_WORD1_41: 64,
    // 単語1-42
    COL_IDX_WORD1_42: 65,
    // 単語1-43
    COL_IDX_WORD1_43: 66,
    // 単語1-44
    COL_IDX_WORD1_44: 67,
    // 単語1-45
    COL_IDX_WORD1_45: 68,
    // 単語帳2
    COL_IDX_WORD2: 69,
    // 単語2-1
    COL_IDX_WORD2_1: 70,
    // 単語2-2
    COL_IDX_WORD2_2: 71,
    // 単語2-3
    COL_IDX_WORD2_3: 72,
    // 単語2-4
    COL_IDX_WORD2_4: 73,
    // 単語2-5
    COL_IDX_WORD2_5: 74,
    // 単語2-6
    COL_IDX_WORD2_6: 75,
    // 単語2-7
    COL_IDX_WORD2_7: 76,
    // 単語2-8
    COL_IDX_WORD2_8: 77,
    // 単語2-9
    COL_IDX_WORD2_9: 78,
    // 単語2-10
    COL_IDX_WORD2_10: 79,
    // 単語2-11
    COL_IDX_WORD2_11: 80,
    // 単語2-12
    COL_IDX_WORD2_12: 81,
    // 単語2-13
    COL_IDX_WORD2_13: 82,
    // 単語2-14
    COL_IDX_WORD2_14: 83,
    // 単語2-15
    COL_IDX_WORD2_15: 84,
    // 単語2-16
    COL_IDX_WORD2_16: 85,
    // 単語2-17
    COL_IDX_WORD2_17: 86,
    // 単語2-18
    COL_IDX_WORD2_18: 87,
    // 単語2-19
    COL_IDX_WORD2_19: 88,
    // 単語2-20
    COL_IDX_WORD2_20: 89,
    // 単語2-21
    COL_IDX_WORD2_21: 90,
    // 単語2-22
    COL_IDX_WORD2_22: 91,
    // 単語2-23
    COL_IDX_WORD2_23: 92,
    // 単語2-24
    COL_IDX_WORD2_24: 93,
    // 単語2-25
    COL_IDX_WORD2_25: 94,
    // 単語2-26
    COL_IDX_WORD2_26: 95,
    // 単語2-27
    COL_IDX_WORD2_27: 96,
    // 単語2-28
    COL_IDX_WORD2_28: 97,
    // 単語2-29
    COL_IDX_WORD2_29: 98,
    // 単語2-30
    COL_IDX_WORD2_30: 99,
    // 単語2-31
    COL_IDX_WORD2_31: 100,
    // 単語2-32
    COL_IDX_WORD2_32: 101,
    // 単語2-33
    COL_IDX_WORD2_33: 102,
    // 単語2-34
    COL_IDX_WORD2_34: 103,
    // 単語2-35
    COL_IDX_WORD2_35: 104,
    // 単語2-36
    COL_IDX_WORD2_36: 105,
    // 単語2-37
    COL_IDX_WORD2_37: 106,
    // 単語2-38
    COL_IDX_WORD2_38: 107,
    // 単語2-39
    COL_IDX_WORD2_39: 108,
    // 単語2-40
    COL_IDX_WORD2_40: 109,
    // 単語2-41
    COL_IDX_WORD2_41: 110,
    // 単語2-42
    COL_IDX_WORD2_42: 111,
    // 単語2-43
    COL_IDX_WORD2_43: 112,
    // 単語2-44
    COL_IDX_WORD2_44: 113,
    // 単語2-45
    COL_IDX_WORD2_45: 114,
    // フォルダ表示階層数
    COL_IDX_DEPTH: 115,
    // フォルダアイコン表示
    COL_IDX_DISPLAY: 116,
    // 自動選択表示
    COL_IDX_AUTO: 117,
    // フォルダ表示形式
    COL_IDX_FORM: 118,
    // 階層タイトル1
    COL_IDX_TITLE1: 119,
    // 階層タイトル2
    COL_IDX_TITLE2: 120,
    // 階層タイトル3
    COL_IDX_TITLE3: 121,
    // 階層タイトル4
    COL_IDX_TITLE4: 122,
    // 通常業務パスワード
    COL_IDX_PASSWORD: 123,
    // プレビュー
    COL_IDX_PREVIEW: 124,
    // 上書き警告
    COL_IDX_WARNING: 125,
    // ファイル名衝突時
    COL_IDX_CONFLICT: 126,
    // 不定形サイズ1縦
    COL_IDX_HEIGHT1: 127,
    // 不定形サイズ1横
    COL_IDX_WIDTH1: 128,
    // 不定形サイズ2縦
    COL_IDX_HEIGHT2: 129,
    // 不定形サイズ2横
    COL_IDX_WIDTH2: 130,
    // 不定形サイズ3縦
    COL_IDX_HEIGHT3: 131,
    // 不定形サイズ3横
    COL_IDX_WIDTH3: 132,
    // ファイル形式
    COL_IDX_FORMAT: 133,
    // 原稿セット方向
    COL_IDX_ORIENTATION: 134,
    // 原稿面
    COL_IDX_DOCUSIDE: 135,
    // 大量原稿
    COL_IDX_SEVENAL: 136,
    // SADF
    COL_IDX_SADF: 137,
    // 読み取りサイズ
    COL_IDX_SIZE: 138,
    // サイズ混載
    COL_IDX_MIX: 139,
    // 集約
    COL_IDX_DOCSUMMARY: 140,
    // 解像度
    COL_IDX_RESOLUTION: 141,
    //解像度ロック
    COL_IDX_RESOLUTIONLOCK: 142,
    // 原稿種類
    COL_IDX_TYPE: 143,
    // 原稿種類ロック
    COL_IDX_TYPELOCK: 144,
    // 濃度
    COL_IDX_GRAYLEVEL: 145,
    // 自動濃度
    COL_IDX_AUTOGRAYLEVEL: 146,
    // 枠消去
    COL_IDX_FRAMEERASE: 147,
    // 同一幅
    COL_IDX_FRAMESAME: 148,
    // 上
    COL_IDX_FRAMEUP: 149,
    // 下
    COL_IDX_FRAMEUNDER: 150,
    // 左
    COL_IDX_FRAMELEFT: 151,
    // 右
    COL_IDX_FRAMERIGHT: 152,
    // センター消去
    COL_IDX_CENTER: 153,
    // 白紙ページ削除
    COL_IDX_BLANK: 154,
    // 印刷対象形式
    COL_IDX_PRINTFORMAT: 155,
    // 印刷条件：カラー
    COL_IDX_COLOR: 156,
    // 部数
    COL_IDX_COPIES: 157,
    // 給紙トレイ
    COL_IDX_TRAY: 158,
    // 印刷面
    COL_IDX_PRINTSIDE: 159,
    // 集約
    COL_IDX_PRINTSUMMARY: 160,
    // 集約方向
    COL_IDX_DIRECTION: 161,
    // 集約仕切り線
    COL_IDX_LINE: 162,
    // 変倍
    COL_IDX_DOUBLING: 163,
    // ソート
    COL_IDX_SORT: 164,
    // ステープル
    COL_IDX_STAPLE: 165,
    // パンチ
    COL_IDX_PUNCH: 166,
    // 日付セパレーター
    COL_IDX_DATESEPARATOR: 167,
    // リスト表示列数
    COL_IDX_NUMBER_OF_LIST_DISPLAY_COLUMNS: 168,
    // 原稿厚さ
    COL_IDX_DOCUMENT_THICKNESS: 169,
    // サイズ混載モード
    COL_IDX_MIXED_SIZE_MODE: 170,
    // 画像切出し
    COL_IDX_DO_CUT_IMAGE: 171,
    // アプリケーション利用制限設定
    COL_IDX_APPLICATION_PERMISSION_SETTINGS: 172,
    // 利用可能アプリケーション
    COL_IDX_PERMITED_APPLICATIONS: 173,
    // 複合機利用制限設定
    COL_IDX_MFP_PERMISSION_SETTINGS: 174,
    // 利用可能複合機
    COL_IDX_PERMITED_MFPS: 175,

    // ヘッダー行
    CSV_HEADER: 2,
    // CSVの最大読み込み数
    CSV_MAX_LINE: 1000,
    // CSVファイル名 テンプレートのファイル名が29バイトなので30バイトまで許容する
    CSVFILENAME_MAX: 30,
    // BOM付き判定
    CSVFORMAT_BOM: /^[\x0x\xef][\x0x\xbb][\x0x\xbf]/,

    //バリデーション
    // Eメールアドレスの文字数制限
    MAIL_MAX_LENGTH: 54,
    // 通常業務名の文字数制限
    NAME_MAX_LENGTH: 32,
    //アイコン
    ICON: [
        'folder',
        'file',
        'notes',
        'blueprint',
        'briefcase',
        'lock',
        'address_book',
        'book',
        'chart',
        'line_graph',
        'bar_graph',
        'printer',
        'fax',
        'hdd',
        'monitor',
        'smartphone',
        'phone',
        'floppy_disk',
        'camera',
        'clock',
        'calendar',
        'envelope',
        'global',
        'home',
        'building',
        'shop',
        'factory',
        'old_man',
        'woman',
        'group',
        'nameplate',
        'namecard',
        'paperclip',
        'tag',
        'star',
        'exclamation_mark',
        'pushpin',
        'bell',
        'lightbulb',
        'wifi',
        'music',
        'heart',
        'dog',
        'cat',
        'cutlery',
        'beer',
        'cup',
        'podium',
        'golf_course',
        'gift',
    ],
    // ラベル色
    LABELCOLOR: [
        'white',
        'light-salmon',
        'hot-pink',
        'yellow',
        'dark-gray',
        'cornflower-blue',
        'crimson',
        'light-green',
        'cyan',
        'medium-orchid',
        'orange-red',
        'lime-green',
        'dark-khaki',
        'dark_khaki',
        'chocolate',
        'dark-cyan',
    ],
    // 接続方法
    CONNECTION: ['box', 'googledrive', 'googleteamdrive', 'dropbox', 'sharepointonline', 'onedrive', 'nonecloud'],
    // 認可ID
    ID_MAX_LENGTH: 256,
    // <半角英数字記号>@<半角英数字記号>の形式
    ID: /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.]+@[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.]+$/,
    //サイト名
    SITE_MAX_LENGTH: 256,
    // ライブラリ名
    LIBRARY_MAX_LENGTH: 256,
    // ディレクトリ名
    DIRECTORY_MAX_LENGTH: 256,
    DIRECTORY: /^[^\\:,;*?"<>|]*$/,
    // ファイル名形式
    NAMEFORMAT: /^(folder[1-9]|folder1[0-9]|folder20|docname|date|user|inputstr[1-9]|inputstr1[0-9]|inputstr20)$/,
    NAMEFORMAT_SPLIT: '%',
    //固定文字
    FIXED_SPLIT: '|',
    FIXED_NEED: /(inputstr)[0-9]+/,
    FIXED_ORDER: 'inputstr',
    FIXED_ORDER_STRICT: 'InputStr',
    FIXED: /^(?!.*(_vti_|\.\.))[^"#%&*,/:;<>?\\{-~]*$/,
    FIXED_START: /^(?!(~ \$|con\.|prn\.|aux\.|nul\.|com[0-9]\.|lpt[0-9]\.|\.|desktop.ini|\s)).*$/,
    // セパレーター
    SEPARATOR: /^[._-]?$/,
    FORMAT_SEPARATOR: /[._-]%/g, // ファイル名形式のチェックに使用する
    // 連番
    SEQUENCE: ['枝番', 'カウントアップ'],
    //単語帳
    WORD_MAX_LENGTH: 32,
    WORD1_DEF: '単語帳1',
    WORD2_DEF: '単語帳2',
    // 単語帳の最大数
    WORDLIST_MAX: 45,
    WORD: /^[^\\:,;*?"<>|/]*$/,
    // フォルダ表示階層数
    DEPTH: /^[1-4]階層$/,
    // フォルダ表示形式
    FORM: ['カラム', 'リスト'],
    FORM_DEF: 'カラム',
    // 階層タイトル
    TITLE_MAX_LENGTH: 32,
    // 通常業務パスワード
    PASSWORD: /^[a-zA-Z0-9!#$%&'"()*+,\-./:;<=>?@[\]^_`{|}~\x5c]*$/,
    PASSWORD_MAX_LENGTH: 32,
    PASSWORD_CIP_MAX_LENGTH: 72,
    // プレビュー表示
    PREVIEW_DEF: 'FALSE',
    // 上書き警告
    WARNING_DEF: 'FALSE',
    // ファイル名衝突時
    CONFLICT: ['上書き', '子番'],
    CONFLICT_DEF: '子番',
    //不定形サイズ
    SIZE_ALLOW: 0,
    SIZE_MIN: 128,
    SIZE_MAX: 999,
    // ファイル形式
    FORMAT: ['TIFF/JPG', 'PDF', 'COMPACT-PDF', 'MULTI-TIFF', 'MULTI-PDF', 'MULTI-COMPACT-PDF'],
    // 原稿セット方向
    ORIENTATION: ['left', 'top'],
    //原稿面
    DOCUMENT: ['片面', '両面(左右ひらき)', '両面(左右開き)', '両面(上下ひらき)', '両面(上下開き)'],
    // 読み取りサイズ
    SIZE: ['Auto', 'A3H', 'B4H', 'A4V', 'A4H', 'B5V', 'B5H', 'User0', 'User1', 'User2', 'CARDH', 'AutoI'],
    //集約
    DOCSUMMARY: ['1', '4'],
    //解像度
    RESOLUTION: /^[2346]00dpi$/,
    // 原稿種類
    TYPE: ['MONO_OCR', 'MONO_TEXT', 'MONO_MIXED', 'MONO_PHOTO', 'GRAYSCALE', 'FULLCOLOR_MIXED', 'FULLCOLOR_PHOTO', 'ACS', 'GRAYSCALE_OCR', 'FULLCOLOR_OCR'],
    //濃度
    LEVEL_MIN: 2,
    LEVEL_MAX: 8,
    //枠消去
    FRAMEERASE: ['枠消去しない', '枠消去(同一幅)', '枠消去(個別指定)', 'センター消去', 'センター・枠消去(同一幅)', 'センター・枠消去(個別指定)'],
    ERASE_DEF: '枠消去しない',
    // 枠消去サイズ
    ERASE_MIN: 2,
    ERASE_MAX: 99,
    ERASESIZE_DEF: '10',
    // 白紙ページ削除
    BLANK_DEF: 'FALSE',
    // 印刷対象形式
    PRINTFORMAT: ['PDF'],
    PRINT_DEF: 'PDF',
    // カラー
    COLOR: ['PRINT_MONO', 'PRINT_COLOR'],
    // 部数
    COPIES_MIN: 1,
    COPIES_MAX: 999,
    // 給紙トレイ
    TRAY: ['TRAY_AUTO', 'TRAY_MANUAL'],
    TRAY_TITLE: '印刷条件：給紙トレイ',
    // 印刷面
    PRINTSIDE: ['片面', '両面(左辺綴じ)', '両面(上辺綴じ)', '週刊誌綴じ(左ひらき)', '週刊誌綴じ(右ひらき)'],
    PRINTSIDE_TITLE: '印刷条件：片面/両面',
    // 集約
    PRINTSUMMARY: ['NONE', 'TWO_IN_ONE', 'FOUR_IN_ONE'],
    PRINTSUMMARY_TITLE: '印刷条件：集約',
    //　集約方向
    DIRECTION: ['Z', 'N'],
    // 変倍
    DOUBLING: ['NONE', 'B5', 'A4', 'B4', 'A3'],
    // ソート
    SORT: ['STACK', 'SORT_NORMAL', 'SORT_ROTATE', 'SORT_SHIFT'],
    SORT_TITLE: '印刷条件：ソート',
    // ステープル
    STAPLE: ['NONE', 'TOP_LEFT', 'TOP_LEFT_SLANT', 'TOP_RIGHT', 'TOP_RIGHT_SLANT', 'DUAL_TOP', 'DUAL_LEFT', 'DUAL_RIGHT', 'SADDLE_STITCH'],
    STAPLE_TITLE: '印刷条件：ステープル',
    // パンチ
    PUNCH: ['NONE', 'LEFT_TWO', 'TOP_TWO', 'RIGHT_TWO'],
    PUNCH_TITLE: '印刷条件：パンチ',
    // 日付セパレーター
    DATE_SEPARATOR: ['.', '-', '_', 'c', 'なし'],
    // リスト表示列数
    NUMBER_OF_LIST_DISPLAY_COLUMNS: ['1', '2', '１', '２'],
    // 原稿厚さ
    DOCUMENT_THICKNESS: ['標準', '薄め', '厚め'],
    // サイズ混載モード
    MIXED_SIZE_MODE: ['同幅', '異幅'],

    // 利用可能アプリケーション区切り文字
    PERMITED_APPLICATIONS_SPLIT: '|',

    // 利用可能複合機区切り文字
    PERMITED_MFPS_SPLIT: '|',
};
