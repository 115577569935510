import { default as React } from 'react';
import { default as icon } from '@/common/img/mfp/index';
import * as locale from '@/common/utils/locale/locale';
import { WithStyles, withStyles, Theme, createStyles, Button } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingTop: '28px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            overflow: 'auto',
            [theme.breakpoints.down('md')]: {
                marginLeft: '0',
                marginRight: '0',
                width: '100%',
                overflow: 'auto',
                paddingTop: '0',
            },
        },
        divList: {
            alignSelf: 'center',
            width: '50%',
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'auto',
            [theme.breakpoints.down('md')]: {
                width: '90%',
            },
        },
        itemImage: {
            width: '24px',
            height: '24px',
            alignSelf: 'center',
            cursor: 'pointer',
            [theme.breakpoints.down('md')]: {
                width: '24px',
                height: '24px',
            },
        },
        item: {
            margin: '14px',
            width: '40px',
            height: '40px',
            padding: '6px',
            [theme.breakpoints.down('md')]: {
                margin: '8px',
                width: '36px',
                height: '36px',
            },
        },
        selectedItem: {
            margin: '14px',
            width: '40px',
            height: '40px',
            cursor: 'pointer',
            backgroundColor: '#ffefbd',
            border: 'solid 2px #ff7300',
            borderRadius: '4px',
            boxSizing: 'border-box',
            padding: '4px',
            [theme.breakpoints.down('md')]: {
                margin: '8px',
                width: '36px',
                height: '36px',
                ackgroundColor: '#ffefbd',
                border: 'solid 2px #ff7300',
                borderRadius: '4px',
                boxSizing: 'border-box',
                padding: '4px',
            },
        },
        divBtn: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        btnClose: {
            color: '#ffffff',
            backgroundColor: '#ff7300',
            borderRadius: theme.spacing.unit * 3,
            width: theme.spacing.unit * 27,
            [theme.breakpoints.down('md')]: {
                color: '#ffffff',
                backgroundColor: '#ff7300',
                borderRadius: theme.spacing.unit * 3,
                width: theme.spacing.unit * 27,
            },
        },
        rootContainer: {
            // overflow: 'scroll',
        },
    });

interface Props extends WithStyles<typeof styles> {
    closeModal: (iconName: string) => void;
    defaultValue?: string;
}

export const Component: React.SFC<Props> = (props: Props) => {
    const { classes } = props;
    const list = [];
    const defaultName = props.defaultValue ? props.defaultValue.split('.')[0] : '';
    const [isSelected, setIsSelected] = React.useState(defaultName);

    const handlePressKeyIcon = (event: any, key: string) => {
        if (event.keyCode === 32 || event.keyCode === 13) {
            setIsSelected(key);
        }
    };

    React.useEffect(() => {
        for (const i in icon.iconMfps) {
            document.getElementById(`img${i}`)!.tabIndex = 0;
        }
    }, []);

    for (const i in icon.iconMfps) {
        list.push(
            <div key={i} id={`img${i}`} className={isSelected === i ? classes.selectedItem : classes.item} onKeyDown={(event) => handlePressKeyIcon(event, i)} tabIndex={0}>
                <img className={props.classes.itemImage} src={icon.iconMfps[i]} alt="img" onClick={() => setIsSelected(i)} />
            </div>,
        );
    }

    return (
        <div className={props.classes.rootContainer}>
            <div className={classes.root}>
                <div className={classes.divList}>{list}</div>
            </div>
            <div className={classes.divBtn}>
                <Button disabled={isSelected ? false : true} onClick={() => props.closeModal(isSelected)} variant="contained" className={classes.btnClose}>
                    {locale.t(locale.keys.endpoint.saveChangeIcon)}
                </Button>
            </div>
        </div>
    );
};

export default withStyles(styles)(Component);
