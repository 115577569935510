import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const getNameRule = async (id: string, auth: string): Promise<schema.V1EndpointsRulesShowResponse> => {
    const response = await axiosFactory.get<schema.V1EndpointsRulesShowResponse>(`https://${consts.environment.api.hostname}/v1/endpoints/${id}/rules`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const updateNameRule = async (id: string, nameRuleObject: schema.V1EndpointsRulesUpdateRequest, auth: string): Promise<schema.V1EndpointsRulesUpdateResponse> => {
    const responce = await axiosFactory.put<schema.V1EndpointsRulesUpdateResponse>(`https://${consts.environment.api.hostname}/v1/endpoints/${id}/rules`, nameRuleObject, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return responce.data;
};
