import { default as mixedSizeModeDiff } from './mixedSizeModeDiff.svg';
import { default as mixedSizeModeSame } from './mixedSizeModeSame.svg';

interface Img {
    [key: string]: string;
}

const mfpSetting: Img = { mixedSizeModeDiff, mixedSizeModeSame };
export default {
    mfpSetting,
};
