import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Grid, FormControlLabel, Checkbox, FormGroup } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
    });

interface Props extends WithStyles<typeof styles> {
    applicationPermission: {
        scan: boolean;
        fax: boolean;
    };
    handleApplicationPermission: (applicationPermission: { scan: boolean; fax: boolean }) => void;
}

const ApplicationCheckList: React.FC<Props> = (props: Props) => {
    const { classes } = props;

    return (
        <Grid container className={classes.root}>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.applicationPermission.scan}
                            onChange={() => {
                                props.handleApplicationPermission({
                                    ...props.applicationPermission,
                                    scan: !props.applicationPermission.scan,
                                });
                            }}
                            name={locale.t(locale.keys.endpointsPermission.applicationPermission.application.scan)}
                        />
                    }
                    label={locale.t(locale.keys.endpointsPermission.applicationPermission.application.scan)}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.applicationPermission.fax}
                            onChange={() => {
                                props.handleApplicationPermission({
                                    ...props.applicationPermission,
                                    fax: !props.applicationPermission.fax,
                                });
                            }}
                            name={locale.t(locale.keys.endpointsPermission.applicationPermission.application.fax)}
                        />
                    }
                    label={locale.t(locale.keys.endpointsPermission.applicationPermission.application.fax)}
                />
            </FormGroup>
        </Grid>
    );
};

export default withStyles(styles)(ApplicationCheckList);
