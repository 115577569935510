import { default as React, useState, useContext, useEffect } from 'react';
import {
    WithStyles,
    withStyles,
    Grid,
    Theme,
    createStyles,
    Button,
    Avatar,
    Switch,
    TextField,
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    Divider,
} from '@material-ui/core';
import { __RouterContext, RouteComponentProps, withRouter, generatePath, Prompt } from 'react-router';
import { Link } from 'react-router-dom';

import * as endpoints from '@/user/models/endpoints/endpoints';
import * as schema from '@/bundles/schema/typescript/schema';
import * as locale from '@/common/utils/locale/locale';
import { default as icon } from '@/common/img/mfp/index';
import { Folder, Delete, ArrowBack, SupervisorAccount } from '@material-ui/icons';
import Icons from '@/user/components/common/Icons';
import ButtonColor from '@/user/components/common/ButtonColor';
import { Variants } from '@/common/components/messages/CommonMessage';
import useTitle from '@/common/components/hooks/useTitle';
import usePreventWindowUnload from '@/common/components/hooks/usePreventWindowUnload';
import routes from '@/user/constants/routes';
// import { ScannerMethod } from '@/user/constants/default-setting';
import Folders from './folders/List';
import { default as UI } from '@/common/constants/ui';
import mockData from '@/user/constants/test-constants/mock-data';
import Confirm from '@/common/components/Confirm/Confirm';
import WarningPopover from '@/user/components/common/WarningPopover';
import { UserAppContainer } from '../UserAppContainer';
import { isLoaded, isLoading, isError, isSaving } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';
import * as webappUtil from '@/common/utils/webappUtil';
import EmailsTemplate from '@/user/components/endpoints/emails-template/EmailsTemplate';
import CopyConfirm from '@/common/components/Confirm/CopyConfirm';
import DialogBase, { DialogBaseProps } from '@/common/components/Confirm/DialogBase';
import shareFormat from '@/common/constants/shareFormat';
import userRole from '@/common/constants/userRole';
import { useEndpoint, updateEndpoint } from '@/common/models/endpoints/useEndpoints';
import PermissionTable from '@/user/components/endpoints/permission-setting/PermissionTable/PermissionTable';
import EnablePermission from '@/user/components/endpoints/permission-setting/EnablePermission';
import environment from '@/common/constants/environment';
import { MultiLineText } from '@/common/components/messages/MultiLineText';

const selectColor = (tenant: string) => {
    switch (tenant) {
        case "ricoh":
            return '#E06666';
        default:
            return '#607d8b';
    }
}


const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing.unit * 5,
            marginBottom: theme.spacing.unit * 6,
            textAlign: 'left',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                marginTop: 0,
                // paddingRight: theme.spacing.unit * 3,
            },
        },
        content: {
            marginRight: theme.spacing.unit * 3,
            [theme.breakpoints.down('md')]: {
                marginRight: 0,
                marginBottom: theme.spacing.unit * 3,
            },
        },
        grid: {
            width: `calc((100% - ${theme.spacing.unit * 9}px) / 3)`,
            marginRight: theme.spacing.unit * 2,
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        action1: {
            marginTop: theme.spacing.unit * 3,
            marginLeft: theme.spacing.unit * 7,
            display: 'flex',
            flexWrap: 'wrap',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                width: '100%',
                marginLeft: theme.spacing.unit * 0,
            },
        },
        action2: {
            marginTop: theme.spacing.unit,
            marginLeft: theme.spacing.unit * 7,
            display: 'flex',
            flexWrap: 'wrap',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                width: '100%',
                marginLeft: theme.spacing.unit * 0,
            },
        },
        endpointName: {
            marginBottom: theme.spacing.unit * 4,
            backgroundColor: '#eeeeee',
            borderRadius: theme.spacing.unit * 0.5,
            border: '0, 0, soild 1px #dddddd, 0',
            '&& p': {
                fontSize: '0.875rem',
                paddingLeft: theme.spacing.unit * 2,
                paddingTop: theme.spacing.unit,
                marginBottom: theme.spacing.unit * 0.5,
                fontWeight: 500,
            },
        },
        mb20: {
            paddingLeft: theme.spacing.unit * 2,
            paddingBottom: theme.spacing.unit,
            fontSize: '1.125rem',
            color: '#333333',
            fontWeight: 500,
            lineHeight: 1.67,
            '&& div': {
                position: 'unset',
            },
        },
        iconContent: {
            fontSize: '0.875rem',
            '&& p': {
                color: '#666666',
            },
        },
        iconButtonBorder: {
            width: theme.spacing.unit * 9.5,
            height: theme.spacing.unit * 9.5,
            border: 'solid 3px #dddddd',
            borderRadius: '50%',
            cursor: 'pointer',
        },
        iconButton: {
            width: theme.spacing.unit * 8,
            height: theme.spacing.unit * 8,
            margin: '3px',
        },
        iconImg: {
            width: theme.spacing.unit * 9.5,
            height: theme.spacing.unit * 9.5,
            borderRadius: '6px',
            border: 'solid 2px #dddddd',
            backgroundColor: 'white',
            '&& img': {
                width: theme.spacing.unit * 5,
                height: theme.spacing.unit * 5,
            },
            cursor: 'pointer',
        },
        cloundContent: {
            marginBottom: theme.spacing.unit * 4,
            '&& p': {
                fontSize: '0.875rem',
                paddingTop: theme.spacing.unit * 0.3,
                marginBottom: theme.spacing.unit * 0.4,
            },
        },
        accFail: {
            // px指定で無理やり上下中央寄せ
            // ToDo: 相対値で中央寄せできるようにしたい
            marginTop: 22,
            marginLeft: 40,
        },
        cloudName: {
            fontSize: '1rem',
            // color: '#333333',
            fontWeight: 'bold',
            '&& p': {
                fontWeight: 'normal',
                fontSize: '14px',
                // color: '#666666',
                marginTop: theme.spacing.unit * 0.15,
            },
            overflowWrap: 'anywhere',
        },
        folder: {
            fontWeight: 500,
            color: '#666666',
            fontSize: '0.875rem',
            '&& a': {
                marginTop: theme.spacing.unit,
                display: 'flex',
            },
            '&& span': {
                fontSize: '0.9375rem',
                color: '#0d47a1',
            },
        },
        folderIcon: {
            width: theme.spacing.unit * 2.625,
            height: theme.spacing.unit * 3.125,
            marginRight: theme.spacing.unit,
            color: '#0d47a1',
        },
        upDownContent: {
            fontWeight: 500,
            marginTop: theme.spacing.unit * 3,
            '&& a': {
                fontSize: '0.9375rem',
                color: '#0d47a1',
            },
        },
        upDown: {
            display: 'flex',
            borderBottom: '1px solid #ccc',
            borderTop: '1px solid #ccc',
            paddingTop: theme.spacing.unit * 3,
            paddingBottom: theme.spacing.unit * 3,
        },
        iconToggle: {
            marginLeft: 'auto',
            marginTop: -theme.spacing.unit * 1.75,
            marginRight: theme.spacing.unit,
            width: theme.spacing.unit * 5,
            height: theme.spacing.unit * 3,
        },
        link: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing.unit,
            '&& a': {
                marginBottom: theme.spacing.unit,
            },
        },
        btnSave: {
            borderRadius: theme.spacing.unit * 4,
            width: theme.spacing.unit * 30,
            display: 'flex',
            fontWeight: 'bold',
            color: '#ffffff',
            '&& span': {
                fontSize: '1rem',
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '240px',
            },
        },
        confirmDelete: {
            marginLeft: theme.spacing.unit * 3,
            [theme.breakpoints.down('md')]: {
                minWidth: theme.spacing.unit * 15,
                marginLeft: theme.spacing.unit * 0,
            },
        },
        deleteBtn: {
            color: theme.palette.grey[700],
            minWidth: theme.spacing.unit * 15,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: theme.spacing.unit * 0.5,
            border: 'solid 1px #cccccc',
            fontWeight: 'bold',
            '&& span': {
                fontSize: '0.8125rem',
            },
            [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing.unit * 4,
            },
        },
        commonModal: {
            backgroundColor: '#fff',
            flexGrow: 1,
            height: '100%',
            padding: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            outline: 'none',
            overflow: 'auto',
        },
        commonModalFolder: {
            backgroundColor: '#fff',
            flexGrow: 1,
            padding: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            outline: 'none',
            [theme.breakpoints.up('lg')]: {
                height: '100%',
                width: '100%',
            },
            overflow: 'auto',
        },
        headerChangeFolder: {
            textAlign: 'center',
        },
        header: {
            width: '100%',
            backgroundColor: selectColor(environment.tenant),
        },
        heading: {
            margin: '0 auto',
            fontSize: theme.spacing.unit * 2,
            fontWeight: 'bold',
        },
        close: {
            fontSize: '28px',
            cursor: 'pointer',
            position: 'absolute',
            left: 0,
        },
        pathEndFolder: {
            overflow: 'hidden',
            whiteSpace: 'pre' /**空白は詰めない */,
            textOverflow: 'ellipsis',
            maxWidth: 300,
        },
        folderDetail: {
            cursor: 'pointer',
        },
        defaultSetting: {
            marginTop: theme.spacing.unit * 3,
        },
        formControlLabel: {
            float: 'left',
            marginTop: '20px',
            marginBottom: '20px',
        },
        checkboxLabel: {
            fontSize: '15px',
        },
        btnSharedCopy: {
            borderRadius: theme.spacing.unit * 4,
            width: theme.spacing.unit * 40,
            display: 'flex',
            fontWeight: 'bold',
            color: '#ffffff',
            '&& span': {
                fontSize: '1rem',
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '240px',
            },
        },
        btnPersonalCopy: {
            borderRadius: theme.spacing.unit * 4,
            marginLeft: theme.spacing.unit,
            width: theme.spacing.unit * 40,
            display: 'flex',
            fontWeight: 'bold',
            color: '#ffffff',
            '&& span': {
                fontSize: '1rem',
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '240px',
            },
        },
    });

type TParams = { id: string };

interface Props extends WithStyles<typeof styles>, RouteComponentProps<TParams> {
    endpoint?: schema.V1ObjectsEndpointInfo;
    skipEffect?: boolean;
    onTitle: () => void;
    onBack: (path: string, pathTitle?: string) => void;
}

const initialDialogObject: DialogBaseProps = {
    callBack: () => {},
    onClose: () => {},
    isOpen: false,
    title: '',
    renderChildren: () => <></>,
    type: 'alert',
};

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const endpointsHooks = useEndpoint(false);

    // get id from url
    const id = props.match.params.id;

    const [ui, setUI] = useState(UI.state.Loading);
    useTitle(locale.t(locale.keys.endpoint.endpointDetail));
    const routerContext = useContext(__RouterContext);
    const appContainer = UserAppContainer.useContainer();
    const [endpointState, changeEndpointState] = useState<schema.V1ObjectsEndpointInfo>();
    const [isEdit, setIsEdit] = React.useState(false);
    const [dialogObject, setDialogObject] = useState<DialogBaseProps>(initialDialogObject);

    usePreventWindowUnload(isEdit);
    if (typeof props.onTitle === 'function') {
        props.onTitle();
    }
    if (typeof props.onBack === 'function' && !isEdit) {
        props.onBack(routes.endpoints.index, locale.t(locale.keys.endpointUserSetting.title));
    }
    if (typeof props.onBack === 'function' && isEdit) {
        props.onBack(routes.endpoints.index, locale.t(locale.keys.endpoint.endpointDetail));
    }
    const [open, setOpen] = React.useState(false);
    const [openBtnColor, setOpenBtnColor] = React.useState(false);
    const [openFolder, setOpenFolder] = React.useState(false);

    const [endpointIcon, setEndpointIcon] = React.useState();
    const [endpoinBtnColor, setEndpointBtnColor] = React.useState();
    const [textFieldState, setTextFieldState] = React.useState('');
    const [pathIdState, setPathIdState] = React.useState();

    useEffect(() => {
        return () => {
            if (typeof props.onBack === 'function') {
                props.onBack('');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        appContainer.updateLoadingState(ui);
        if (isError(ui)) {
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.error),
                variant: Variants.error,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        (async () => {
            try {
                if (props.skipEffect) {
                    changeEndpointState(mockData.endpointsResults.endpoints[0]);
                    setUI(UI.state.Loaded);
                    return;
                }
                if (!id) {
                    setUI(UI.state.Error);
                    appContainer.setLoadingState(false);
                    throw new Error(locale.t(locale.keys.error.notFound));
                }
                const dataRes = await endpointsHooks.indexEndpointData(
                    appContainer.values.signinWorkspaceObject.id!,
                    appContainer.values.authorizationCode,
                    id,
                    appContainer.values.signinWorkspaceUserObject.role,
                );
                const endpointData = dataRes.endpoint;
                if (endpointData) {
                    setUI(UI.state.Loaded);
                    changeEndpointState(endpointData);
                    setEndpointIcon(icon.iconMfps[endpointData.iconName.split('.')[0]]);
                    setEndpointBtnColor(webappUtil.getEnumKey(endpointData.buttonColor, schema.V1ObjectsEndpointsButtonColor));
                    setTextFieldState(endpointData.name);
                    setState({ ...state, checkedUp: endpointData.uploadable, checkeDown: endpointData.downloadable });
                    setPathIdState(endpointData.folderPathId);
                }
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
                setUI(UI.state.Error);
                appContainer.setLoadingState(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBackground = () => {
        const color = endpoinBtnColor;
        return { backgroundColor: color };
    };

    const [state, setState] = useState({
        checkedUp: false,
        checkeDown: false,
    });

    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (name === 'checkedUp') {
            endpointState.uploadable = !endpointState.uploadable;
        }
        if (name === 'checkeDown') {
            endpointState.downloadable = !endpointState.downloadable;
        }
        changeEndpointState(endpointState);
        setState({ ...state, [name]: event.target.checked });
        setIsEdit(true);
    };

    const handleChangeServiceCompleted = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeEndpointState({
            ...endpointState,
            serviceCompleted: !endpointState.serviceCompleted,
        });
        setIsEdit(true);
    };

    const handleChangeServiceError = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeEndpointState({
            ...endpointState,
            serviceError: !endpointState.serviceError,
        });
        setIsEdit(true);
    };

    const saveOnClick = async () => {
        setUI(UI.state.Saving);
        setIsEdit(false);

        const permission = updateEndpoint(
            environment.tenant,
            endpointsHooks.applicationPermission,
            endpointsHooks.enablePermission,
            endpointsHooks.selectedMfpPermission,
            endpointsHooks.selectedUserPermission,
            endpointsHooks.selectedGroupPermission,
        );

        const option: schema.V1EndpointsDetailsUpdateRequest = {
            allowedApplications: permission.applicationPermission,
            applicationPermissionSetting: permission.applicationPermissionSetting,
            allowedMfps: permission.mfpPermission,
            mfpPermissionSetting: permission.mfpPermissionSetting,
            allowedUsers: permission.userPermission,
            userPermissionSetting: permission.userPermissionSetting,
            allowedGroups: permission.groupPermission,
            buttonColor: endpointState.buttonColor,
            downloadable: endpointState.downloadable,
            uploadable: endpointState.uploadable,
            iconName: endpointState.iconName,
            name: endpointState.name,
            path: endpointState.folderPath,
            pathId: pathIdState,
            serviceCompleted: endpointState.serviceCompleted,
            serviceError: endpointState.serviceError,
            mailSetting: endpointState.mailSetting,
        };

        try {
            await endpointsHooks.updateEndpointData(appContainer.values.authorizationCode, id, option);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.updated),
                variant: Variants.success,
            });
            setUI(UI.state.Loaded);
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
            setUI(UI.state.Error);
            appContainer.setLoadingState(false);
        }
    };

    const confirmRemove = (isYes: boolean) => {
        if (!isYes) {
            return;
        }
        removeOnclick();
    };

    const removeOnclick = async () => {
        try {
            const dataRes = await endpoints.deleteEndpoint(endpointState.id, appContainer.values.authorizationCode);
            if (!dataRes) {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.error.default),
                    variant: Variants.error,
                });
            }
            if (dataRes) {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.deleted),
                    variant: Variants.success,
                });
                document.title = locale.t(locale.keys.endpointUserSetting.title);
                if (typeof props.onTitle === 'function' && !isEdit) {
                    props.onTitle();
                }
                // return to endpoint list
                routerContext.history.push(routes.endpoints.index);
            }
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
            setUI(UI.state.Error);
            appContainer.setLoadingState(false);
        }
    };

    /**
     * エンドポイント, 共有エンドポイントを個人にコピー
     */
    const copyEndpoint = async (share: number) => {
        setUI(UI.state.Saving);
        setIsEdit(false);
        let newName: string = endpointState.name;
        if (appContainer.values.signinWorkspaceUserObject.language === schema.Language.Ja) {
            newName = newName + locale.t(locale.keys.endpoint.copyName);
        } else {
            newName = locale.t(locale.keys.endpoint.copyName) + newName;
        }
        const option: schema.V1EndpointsCreateCopyRequest = {
            share,
            buttonColor: endpointState.buttonColor,
            downloadable: endpointState.downloadable,
            uploadable: endpointState.uploadable,
            iconName: endpointState.iconName,
            name: newName,
            path: endpointState.folderPath,
            pathId: pathIdState,
            serviceCompleted: endpointState.serviceCompleted,
            serviceError: endpointState.serviceError,
            mailSetting: endpointState.mailSetting,
        };

        try {
            await endpoints.copyEndpoint(endpointState.id, option, appContainer.values.authorizationCode);
            setUI(UI.state.Loaded);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.copied),
                variant: Variants.success,
            });
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
            setUI(UI.state.Error);
            appContainer.setLoadingState(false);
        }
    };

    /**
     * 変更がある状態で共有にコピー
     */
    const saveAndCopySharedEndpoint = async () => {
        await saveOnClick();
        await copyEndpoint(shareFormat.share);
    };

    /**
     * 変更がある状態で個人にコピー
     */
    const saveAndCopyEndpoint = async () => {
        await saveOnClick();
        await copyEndpoint(shareFormat.personal);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseChooseIcon = (iconName: string) => {
        if (iconName) {
            changeIcon(iconName);
        }
        if (!iconName) {
            handleClose();
        }
    };

    const handleOpenBtnColor = () => {
        setOpenBtnColor(true);
    };

    const handleCloseChooseColor = () => {
        setOpenBtnColor(false);
    };

    const handleCloseBtnColor = (btnColorName: string) => {
        if (btnColorName) {
            changeBtnColor(btnColorName);
        }
        if (!btnColorName) {
            setOpenBtnColor(false);
        }
    };

    const handleOpenFolder = () => {
        setOpenFolder(true);
    };

    const handleCloseFolder = () => {
        appContainer.setLoadingState(false);
        setOpenFolder(false);
    };

    const changeIcon = (iconName: string) => {
        setEndpointIcon(icon.iconMfps[iconName]);
        endpointState.iconName = `${iconName}.png`;
        changeEndpointState(endpointState);
        setIsEdit(true);
        handleClose();
    };

    const changeBtnColor = (btnColorName: string) => {
        setEndpointBtnColor(btnColorName);
        endpointState.buttonColor = webappUtil.getEnumData(btnColorName, schema.V1ObjectsEndpointsButtonColor);
        changeEndpointState(endpointState);
        setIsEdit(true);
        setOpenBtnColor(false);
    };

    const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
        endpointState.name = e.target.value;
        setTextFieldState(e.target.value);
        changeEndpointState(endpointState);
        setIsEdit(true);
    };

    const onBlurEndpointNameInput = () => {
        const newInput = webappUtil.removeEmojiChar(endpointState.name);
        endpointState.name = newInput;
        setTextFieldState(newInput);
        changeEndpointState(endpointState);
    };

    const varidateEndpointName = () => {
        if (!endpointState.name) {
            // エンドポイント名が未入力
            return locale.t(locale.keys.validation.required);
        }
        return '';
    };

    const pathUpdate = (path: string, pathId?: string) => {
        endpointState.folderPath = path;
        if (pathId) endpointState.folderPathId = pathId;
        setPathIdState(pathId);
        changeEndpointState(endpointState);
        setIsEdit(true);
    };

    const mailList = (mailSetting: schema.V1ObjectsEndpointsMailSetting) => {
        endpointState.mailSetting = mailSetting;
        changeEndpointState(endpointState);
        setIsEdit(true);
    };

    const getComponent = (service: schema.V1ObjectsServiceEnum) => {
        if (service && service === schema.V1ObjectsServiceEnum.Email) {
            return (
                <>
                    <AppBar position="static" className={classes.header}>
                        <Toolbar variant="dense">
                            <IconButton color="inherit" className={classes.close} onClick={handleCloseFolder}>
                                <ArrowBack />
                            </IconButton>
                            <Typography color="inherit" className={classes.heading}>
                                {locale.t(locale.keys.endpoint.operationObject)}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <EmailsTemplate mailSetting={endpointState.mailSetting} mailList={mailList} closeModal={handleCloseFolder} />
                </>
            );
        }
        return (
            <>
                <AppBar position="static" className={classes.header}>
                    <Toolbar variant="dense">
                        <IconButton color="inherit" className={classes.close} onClick={handleCloseFolder}>
                            <ArrowBack />
                        </IconButton>
                        <Typography color="inherit" className={classes.heading}>
                            {locale.t(locale.keys.endpoint.operationObject)}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Folders
                    id={endpointState.xStorageId}
                    cloudAcc={endpointState.cloudAccount}
                    endpointType={endpointState.type}
                    path={endpointState.folderPath}
                    closeModal={handleCloseFolder}
                    pathSelect={pathUpdate}
                    pathId={endpointState.folderPathId}
                />
            </>
        );
    };

    const getFolderOrEmail = (service: schema.V1ObjectsServiceEnum) => {
        if (service && service === schema.V1ObjectsServiceEnum.Email) {
            return (
                <>
                    {/* eslint-disable-next-line */}
                    <a href="javascript:void(0)" onClick={handleOpenFolder}>
                        <span>{locale.t(locale.keys.endpoint.operationObject)}</span>
                    </a>
                </>
            );
        }

        const path: string = endpointState.folderPath;
        if (schema.V1ObjectsServiceEnum.Docard === service) {
            const name = path.split('/').filter((item) => item);
            return (
                <>
                    <label>{locale.t(locale.keys.endpoint.operationObject)}</label>
                    {/* eslint-disable-next-line */}
                    <a href="javascript:void(0)" onClick={handleOpenFolder}>
                        <SupervisorAccount className={classes.folderIcon} />
                        <span className={classes.pathEndFolder}>{name}</span>
                    </a>
                </>
            );
        }

        return (
            <>
                <label>{locale.t(locale.keys.endpoint.operationObject)}</label>
                {/* eslint-disable-next-line */}
                <a href="javascript:void(0)" onClick={handleOpenFolder}>
                    <Folder className={classes.folderIcon} />
                    <span className={classes.pathEndFolder}>{path}</span>
                </a>
            </>
        );
    };

    const handlePressKey = (event: any, value: string) => {
        if (event.keyCode === 32 || event.keyCode === 13) {
            switch (value) {
                case 'icon':
                    setOpen(true);
                    break;
                default:
                    setOpenBtnColor(true);
                    break;
            }
        }
    };

    const shouldConfirmSave = () => {
        if (endpointsHooks.enablePermission.mfpPermission && endpointsHooks.selectedMfpPermission.length === 0) return true;
        if (endpointsHooks.enablePermission.applicationPermission && !endpointsHooks.applicationPermission.fax && !endpointsHooks.applicationPermission.scan) return true;

        return false;
    };

    let endpointRender = <></>;
    if (endpointState) {
        endpointRender = (
            <>
                <Grid container className={classes.root}>
                    <Grid className={classes.grid}>
                        <div className={classes.content}>
                            <div className={classes.endpointName}>
                                <p>{locale.t(locale.keys.endpoint.endpointName)}</p>
                                <TextField
                                    className={classes.mb20}
                                    value={textFieldState}
                                    inputProps={{ style: { height: '100%' } }}
                                    onChange={handleChangeTextField}
                                    onBlur={onBlurEndpointNameInput}
                                    fullWidth
                                    error={varidateEndpointName() !== ''}
                                    helperText={varidateEndpointName()}
                                />
                            </div>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                <Grid item xs={6} className={classes.iconContent}>
                                    <p>{locale.t(locale.keys.endpoint.icon)}</p>
                                    <Avatar className={classes.iconImg} onClick={handleOpen} tabIndex={0} onKeyDown={(event: any) => handlePressKey(event, 'icon')}>
                                        <img src={endpointIcon} alt="img" />
                                    </Avatar>
                                </Grid>
                                <Grid item xs={6} className={classes.iconContent}>
                                    <p>{locale.t(locale.keys.endpoint.buttonColor)}</p>
                                    <div className={classes.iconButtonBorder} onClick={handleOpenBtnColor} tabIndex={0} onKeyDown={(event: any) => handlePressKey(event, 'color')}>
                                        <Avatar className={classes.iconButton} style={getBackground()} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid className={classes.grid}>
                        <Grid container className={classes.content} direction="column" justify="flex-start" alignItems="flex-start">
                            <div className={classes.cloundContent}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                                    <div>
                                        <p>{locale.t(locale.keys.endpoint.cloudConnection)}</p>

                                        <Link to={`${generatePath(routes.endpoints.cloud, { id: endpointState.id, num: shareFormat.personal })}`}>
                                            <div className={classes.cloudName}>
                                                <span>{webappUtil.getServiceText(endpointState.type)}</span>
                                                <p>{![schema.V1ObjectsServiceEnum.Nonecloud, schema.V1ObjectsServiceEnum.Email].includes(endpointState.type) && endpointState.cloudAccount}</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className={classes.accFail}>
                                        {!endpointState.isStorageAvailable && (
                                            <WarningPopover
                                                xStorageAccFail={
                                                    endpointState.cloudAccount
                                                        ? endpointState.cloudAccount.length > 30
                                                            ? endpointState.cloudAccount.slice(0, 30) + '...'
                                                            : endpointState.cloudAccount
                                                        : endpointState.cloudAccount
                                                }
                                                isShared={false}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </div>
                            <div className={classes.folder}>{getFolderOrEmail(endpointState.type)}</div>
                        </Grid>
                    </Grid>
                    <Grid className={classes.grid}>
                        <div className={classes.upDownContent}>
                            <div className={classes.upDown}>
                                <span>{locale.t(locale.keys.endpoint.upload)}</span>
                                <div className={classes.iconToggle}>
                                    <Switch
                                        value="checkedUp"
                                        disabled={!endpointState.wsUploadable ? true : false}
                                        onChange={handleChange('checkedUp')}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        checked={state.checkedUp}
                                    />
                                </div>
                            </div>
                            <div className={classes.link}>
                                <Grid container direction="row" justify="flex-start" alignItems="center">
                                    {endpointState.type !== schema.V1ObjectsServiceEnum.Nonecloud ? (
                                        <Link to={`${generatePath(routes.endpoints.filename.index, { id: endpointState.id })}`}>{locale.t(locale.keys.endpoint.fileName)}</Link>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                                {/*
                                スキャン機能/ファクス配信拡張機能のリンク（機能停止中）
                                いつか使用する可能性を考慮して削除せずにコメントアウトで対応する。
                                {appContainer.values.signinWorkspaceObject != null && appContainer.values.signinWorkspaceObject.billingPlan === schema.BillingPlan.Internal && (
                                    <Grid container direction="row" justify="flex-start" alignItems="center">
                                        <Link to={`${generatePath(routes.endpoints.extensions.index, { id: endpointState.id })}?type=${ScannerMethod.Scan}`}>
                                            {locale.t(locale.keys.endpoint.extendedFeatureUp)}
                                        </Link>
                                        {endpointState.isDisable && Object.keys(endpointState.extensionScanFails).length !== 0 && <WarningPopover listScanExFail={endpointState.extensionScanFails} />}
                                    </Grid>
                                )}
                                */}
                            </div>
                            <div className={classes.upDown}>
                                <span>{locale.t(locale.keys.endpoint.download)}</span>
                                <div className={classes.iconToggle}>
                                    <Switch
                                        value="checkeDown"
                                        disabled={!endpointState.wsDownloadable ? true : false}
                                        onChange={handleChange('checkeDown')}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        checked={state.checkeDown}
                                    />
                                </div>
                            </div>
                            {/*
                            プリント機能拡張機能のリンク（機能停止中）
                            いつか使用する可能性を考慮して削除せずにコメントアウトで対応する。
                            <div className={classes.link}>
                                {appContainer.values.signinWorkspaceObject != null && appContainer.values.signinWorkspaceObject.billingPlan === schema.BillingPlan.Internal && (
                                    <Grid container direction="row" justify="flex-start" alignItems="center">
                                        <Link to={`${generatePath(routes.endpoints.extensions.index, { id: endpointState.id })}?type=${ScannerMethod.Print}`}>
                                            {locale.t(locale.keys.endpoint.extendedFeatureDown)}
                                        </Link>
                                        {endpointState.isDisable && Object.keys(endpointState.extensionPrintFails).length !== 0 && (
                                            <WarningPopover listPrintExFail={endpointState.extensionPrintFails} />
                                        )}
                                    </Grid>
                                )}
                            </div>
                            */}
                            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.defaultSetting}>
                                <Link to={`${generatePath(routes.endpoints.scan.index, { id: endpointState.id })}`}> {locale.t(locale.keys.endpoint.defaultSettingForScanApp)} </Link>
                            </Grid>
                            <div className={classes.link}>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                    <FormHelperText>{locale.t(locale.keys.endpoint.email.title)}</FormHelperText>
                                    <FormControlLabel
                                        className={classes.checkboxLabel}
                                        control={<Checkbox checked={endpointState.serviceCompleted} onChange={handleChangeServiceCompleted} />}
                                        label={locale.t(locale.keys.endpoint.email.completed)}
                                    />
                                    <FormControlLabel
                                        className={classes.checkboxLabel}
                                        control={<Checkbox checked={endpointState.serviceError} onChange={handleChangeServiceError} />}
                                        label={locale.t(locale.keys.endpoint.email.error)}
                                    />
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    <Grid container direction="column" style={{ width: '100%' }}>
                        <Grid item style={{ width: '100%' }}>
                            <Typography variant="h4">{locale.t(locale.keys.endpointsPermission.title)}</Typography>
                            <Divider style={{ width: '100%', marginBlock: '4px' }} />
                        </Grid>
                        <Grid item style={{ width: '100%' }}>
                            <EnablePermission
                                isShared={false}
                                handleEnablePermisson={endpointsHooks.handleEnablePermisson}
                                enablePermission={endpointsHooks.enablePermission}
                                applicationPermission={endpointsHooks.applicationPermission}
                                handleApplicationPermission={endpointsHooks.handleApplicationPermission}
                            />
                        </Grid>
                        <Grid item style={{ width: '100%', overflow: 'auto' }}>
                            {(endpointsHooks.enablePermission.userGroupPermission || endpointsHooks.enablePermission.mfpPermission) && (
                                <PermissionTable
                                    isShared={false}
                                    enablePermission={endpointsHooks.enablePermission}
                                    wsData={endpointsHooks.wsData}
                                    mfpPermission={endpointsHooks.permissionRows.mfpPermission}
                                    handleMfpPermission={endpointsHooks.handleMfpPermission}
                                    selectedMfpPermission={endpointsHooks.selectedMfpPermission}
                                    userPermission={endpointsHooks.permissionRows.userPermission}
                                    handleUserPermission={endpointsHooks.handleUserPermission}
                                    selectedUserPermission={endpointsHooks.selectedUserPermission}
                                    groupPermission={endpointsHooks.permissionRows.groupPermission}
                                    handleGroupPermission={endpointsHooks.handleGroupPermission}
                                    selectedGroupPermission={endpointsHooks.selectedGroupPermission}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} alignItems="flex-start" className={classes.action1}>
                        <div>
                            {shouldConfirmSave() ? (
                                <Button
                                    className={classes.btnSave}
                                    fullWidth
                                    variant="contained"
                                    onClick={() => {
                                        setDialogObject({
                                            callBack: () => {
                                                saveOnClick();
                                                setDialogObject(initialDialogObject);
                                            },
                                            onClose: () => setDialogObject(initialDialogObject),
                                            isOpen: true,
                                            title: locale.t(locale.keys.endpoint.confirmSave.title),
                                            type: 'confirm',
                                            renderChildren: () => {
                                                let desc = [] as string[];
                                                let list = [] as string[];

                                                if (endpointsHooks.enablePermission.applicationPermission && !endpointsHooks.applicationPermission.fax && !endpointsHooks.applicationPermission.scan) {
                                                    desc.push(locale.t(locale.keys.endpoint.confirmSave.endpointPermission.desc.application));
                                                    list.push(locale.t(locale.keys.endpoint.confirmSave.endpointPermission.applicationPermissionList));
                                                }

                                                if (endpointsHooks.enablePermission.mfpPermission && endpointsHooks.selectedMfpPermission.length === 0) {
                                                    desc.push(locale.t(locale.keys.endpoint.confirmSave.endpointPermission.desc.mfp));
                                                    list.push(locale.t(locale.keys.endpoint.confirmSave.endpointPermission.mfpPermissionList));
                                                }

                                                return (
                                                    <>
                                                        <MultiLineText
                                                            value={locale.t(locale.keys.endpoint.confirmSave.endpointPermission.desc.base, {
                                                                endpoint: locale.t(locale.keys.common.model.endpoint.individual),
                                                                list: desc.join(locale.t(locale.keys.endpoint.confirmSave.endpointPermission.desc.separator)),
                                                            })}
                                                        />
                                                        <ul style={{ margin: 0 }}>
                                                            {list.map((item, index) => {
                                                                return (
                                                                    <li key={index} style={{ wordBreak: 'break-all' }}>
                                                                        {item}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </>
                                                );
                                            },
                                        });
                                    }}
                                    disabled={varidateEndpointName() !== ''}
                                >
                                    {locale.t(locale.keys.endpoint.button.save)}
                                </Button>
                            ) : (
                                <Button className={classes.btnSave} fullWidth variant="contained" onClick={saveOnClick} disabled={varidateEndpointName() !== ''}>
                                    {locale.t(locale.keys.endpoint.button.save)}
                                </Button>
                            )}
                        </div>
                        <div className={classes.confirmDelete}>
                            <Confirm callBack={confirmRemove} name={textFieldState}>
                                <Button className={classes.deleteBtn}>
                                    <Delete /> {locale.t(locale.keys.endpoint.button.remove)}
                                </Button>
                            </Confirm>
                        </div>
                    </Grid>
                    <Grid item xs={12} alignItems="flex-start" className={classes.action2}>
                        {isEdit ? (
                            <>
                                {appContainer.values.signinWorkspaceUserObject.role === userRole.member ? (
                                    <>
                                        <div>
                                            <Button className={classes.btnSharedCopy} fullWidth variant="contained" disabled={true}>
                                                {locale.t(locale.keys.endpoint.button.sharecopy)}
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            <CopyConfirm callBack={saveAndCopySharedEndpoint} isDisable={varidateEndpointName() !== ''}>
                                                <Button className={classes.btnSharedCopy} fullWidth variant="contained" disabled={!endpointState.isStorageAvailable || varidateEndpointName() !== ''}>
                                                    {locale.t(locale.keys.endpoint.button.sharecopy)}
                                                </Button>
                                            </CopyConfirm>
                                        </div>
                                    </>
                                )}
                                <div>
                                    <CopyConfirm callBack={saveAndCopyEndpoint} isDisable={varidateEndpointName() !== ''}>
                                        <Button className={classes.btnPersonalCopy} fullWidth variant="contained" disabled={!endpointState.isStorageAvailable || varidateEndpointName() !== ''}>
                                            {locale.t(locale.keys.endpoint.button.settingcopy)}
                                        </Button>
                                    </CopyConfirm>
                                </div>
                            </>
                        ) : (
                            <>
                                {appContainer.values.signinWorkspaceUserObject.role === userRole.member ? (
                                    <>
                                        <div>
                                            <Button className={classes.btnSharedCopy} fullWidth variant="contained" onClick={() => copyEndpoint(shareFormat.share)} disabled={true}>
                                                {locale.t(locale.keys.endpoint.button.sharecopy)}
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            <Button
                                                className={classes.btnSharedCopy}
                                                fullWidth
                                                variant="contained"
                                                onClick={() => copyEndpoint(shareFormat.share)}
                                                disabled={!endpointState.isStorageAvailable}
                                            >
                                                {locale.t(locale.keys.endpoint.button.sharecopy)}
                                            </Button>
                                        </div>
                                    </>
                                )}
                                <div>
                                    <Button
                                        className={classes.btnPersonalCopy}
                                        fullWidth
                                        variant="contained"
                                        onClick={() => copyEndpoint(shareFormat.personal)}
                                        disabled={!endpointState.isStorageAvailable}
                                    >
                                        {locale.t(locale.keys.endpoint.button.settingcopy)}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Grid>
                </Grid>
                <div>
                    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open} onClose={handleClose}>
                        <div className={classes.commonModal}>
                            <AppBar position="static" className={classes.header}>
                                <Toolbar variant="dense">
                                    <IconButton color="inherit" className={classes.close} onClick={handleClose}>
                                        <ArrowBack />
                                    </IconButton>
                                    <Typography color="inherit" className={classes.heading}>
                                        {locale.t(locale.keys.endpoint.changeIcon)}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Icons closeModal={handleCloseChooseIcon} defaultValue={endpointState.iconName ? endpointState.iconName : ''} />
                        </div>
                    </Modal>
                </div>
                <div>
                    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={openBtnColor} onClose={handleClose}>
                        <div className={classes.commonModal}>
                            <AppBar position="static" className={classes.header}>
                                <Toolbar variant="dense">
                                    <IconButton color="inherit" className={classes.close} onClick={handleCloseChooseColor}>
                                        <ArrowBack />
                                    </IconButton>
                                    <Typography color="inherit" className={classes.heading}>
                                        {locale.t(locale.keys.endpoint.changeColor)}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <ButtonColor closeModal={handleCloseBtnColor} defaultValue={endpointState.buttonColor ? endpointState.buttonColor : ''} />
                        </div>
                    </Modal>
                </div>
                <Modal open={openFolder} onClose={handleCloseFolder}>
                    <div className={classes.commonModalFolder}>{getComponent(endpointState.type)}</div>
                </Modal>
                <DialogBase
                    callBack={dialogObject.callBack}
                    onClose={dialogObject.onClose}
                    isOpen={dialogObject.isOpen}
                    title={dialogObject.title}
                    type={dialogObject.type}
                    renderChildren={dialogObject.renderChildren}
                />
            </>
        );
    }

    return (
        <>
            {isLoading(ui) && <div data-testid={UI.state.Loading} />}

            {isSaving(ui) && <div data-testid={UI.state.Saving} />}

            {isLoaded(ui) && <div data-testid={UI.state.Loaded}>{endpointRender}</div>}

            {isError(ui) && <div data-testid={UI.state.Error} />}
            <Prompt when={isEdit} message={locale.t(locale.keys.promptMessage)} />
        </>
    );
};

export default withStyles(styles)(withRouter(Component));
