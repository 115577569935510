import { default as React, useEffect } from 'react';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles, AppBar, Tabs, Tab, Typography, Button } from '@material-ui/core';
import { MfpSettingContainer } from './MfpSettingContainer';
import TabOne from './TabOne';
import TabTwo from './TabTwo';
import TabThree from './TabThree';
import TabFour from './TabFour';
import { default as UI } from '@/common/constants/ui';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import * as errorHandler from '@/common/utils/errorHandler';
import { withRouter, RouteComponentProps, Prompt } from 'react-router';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import useTitle from '@/common/components/hooks/useTitle';
import { isLoaded, isLoading, isError } from '@/common/components/hooks/useUI';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: 960,
            paddingBottom: theme.spacing.unit * 3,
            [theme.breakpoints.down('sm')]: {
                marginTop: -theme.spacing.unit * 3,
                width: '100%',
            },
            '&& span': {
                fontWeight: 500,
            },
        },
        header: {
            backgroundColor: '#ffffff',
            borderBottom: `3px solid ${theme.palette.secondary.main}`,
            marginBottom: 30,
            '&& span': {
                fontWeight: 'bold',
            },
        },
        button: {
            minWidth: 196,
            maxWidth: 246,
            height: 44,
            marginTop: 20,
            marginBottom: 30,
        },
        tabs: {
            minHeight: 36,
            color: `${theme.palette.secondary.main}`,
            backgroundColor: 'white',
            textAlign: 'left',
            width: 'auto',
        },
        indicator: {
            display: 'none',
        },
        tabsContainer: {
            minHeight: 36,
        },
        tabSelected: {
            color: '#ffffff',
            backgroundColor: `${theme.palette.secondary.main}`,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
        },
        tabPanel: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing.unit * 2,
            },
        },
    });

type TParams = { id: string; mfpSettingId: string };

interface Props extends WithStyles<typeof styles>, RouteComponentProps<TParams> {
    theme: Theme;
    skipEffect?: boolean;
    onTitle?: () => void;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <Typography component="div" role="tabpanel" id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
            {children}
            {value !== index}
        </Typography>
    );
};

const a11yProps = (index: number) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};

const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const mfpSettingId = props.match.params.mfpSettingId;

    const [value, setValue] = React.useState(0);
    const mfpSettingContainer = MfpSettingContainer.useContainer();
    const appContainer = UserAppContainer.useContainer();
    const matchesBreakPoint = useMediaQuery(props.theme.breakpoints.up('md'));

    let indexTitle = locale.t(locale.keys.mfpDefaultSetting.indexDefault);

    // The title is empty while loading is in progress.
    if (!mfpSettingContainer.title) {
        indexTitle = '';
    }

    if (indexTitle !== mfpSettingContainer.title) {
        indexTitle = locale.t(locale.keys.mfpDefaultSetting.indexInit, { name: mfpSettingContainer.title });
    }

    // Compression title if length is greater than 80.
    // Format is: 20_first_character...locale.keys.mfpDefaultSetting.indexInit.
    if (mfpSettingContainer && mfpSettingContainer.title && indexTitle.length > 80) {
        const compactTitle = [mfpSettingContainer.title.substring(0, 20), '...'].join(' ');
        indexTitle = locale.t(locale.keys.mfpDefaultSetting.indexInit, { name: compactTitle });
    }
    useTitle(indexTitle);
    if (typeof props.onTitle === 'function') {
        props.onTitle();
    }

    useEffect(() => {
        appContainer.updateLoadingState(mfpSettingContainer.ui);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mfpSettingContainer.ui]);

    useEffect(() => {
        void (async () => {
            try {
                mfpSettingContainer.setUI(UI.state.Loading);
                await mfpSettingContainer.getDefaultSetting(mfpSettingId);
                mfpSettingContainer.setUI(UI.state.Loaded);
            } catch (e) {
                mfpSettingContainer.setUI(UI.state.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            {isLoading(mfpSettingContainer.ui) && <div data-testid={UI.state.Loading} />}
            {isError(mfpSettingContainer.ui) && <div data-testid={UI.state.Error}>Error</div>}
            {isLoaded(mfpSettingContainer.ui) && (
                <div className={props.classes.root}>
                    <AppBar position="static" className={classes.header}>
                        <Tabs value={value} onChange={handleChange} classes={{ root: classes.tabsContainer, indicator: classes.indicator }} variant="fullWidth">
                            <Tab label={locale.t(locale.keys.mfpDefaultSetting.tabTitle.tabOne)} {...a11yProps(0)} className={classes.tabs} classes={{ selected: classes.tabSelected }} />
                            <Tab
                                label={matchesBreakPoint ? locale.t(locale.keys.mfpDefaultSetting.tabTitle.tabTwo) : locale.t(locale.keys.mfpDefaultSetting.tabTitle.tabTwoSP)}
                                {...a11yProps(1)}
                                className={classes.tabs}
                                classes={{ selected: classes.tabSelected }}
                            />
                            <Tab
                                label={matchesBreakPoint ? locale.t(locale.keys.mfpDefaultSetting.tabTitle.tabTree) : locale.t(locale.keys.mfpDefaultSetting.tabTitle.tabTreeSP)}
                                {...a11yProps(2)}
                                className={classes.tabs}
                                classes={{ selected: classes.tabSelected }}
                            />
                            <Tab
                                label={matchesBreakPoint ? locale.t(locale.keys.mfpDefaultSetting.tabTitle.tabFour) : locale.t(locale.keys.mfpDefaultSetting.tabTitle.tabFourSP)}
                                {...a11yProps(3)}
                                className={classes.tabs}
                                classes={{ selected: classes.tabSelected }}
                            />
                        </Tabs>
                    </AppBar>
                    <div className={classes.tabPanel}>
                        {value === 0 && (
                            <TabPanel value={value} index={0}>
                                <TabOne />
                            </TabPanel>
                        )}
                        {value === 1 && (
                            <TabPanel value={value} index={1}>
                                <TabTwo />
                            </TabPanel>
                        )}
                        {value === 2 && (
                            <TabPanel value={value} index={2}>
                                <TabThree />
                            </TabPanel>
                        )}
                        {value === 3 && (
                            <TabPanel value={value} index={3}>
                                <TabFour />
                            </TabPanel>
                        )}
                    </div>
                    <Button
                        className={classes.button}
                        fullWidth
                        variant="contained"
                        onClick={() => mfpSettingContainer.handleClickUpdateDefaultSetting(mfpSettingId)}
                        disabled={appContainer.loadingState ? true : false}
                    >
                        {locale.t(locale.keys.action.save)}
                    </Button>
                    <Prompt when={mfpSettingContainer.isEdit} message={locale.t(locale.keys.promptMessage)} />
                </div>
            )}
        </>
    );
};

export default withStyles(styles, { withTheme: true })(withRouter(Component));
