import { default as React } from 'react';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles, Grid, Typography, Button, TextField, Switch, InputAdornment, IconButton } from '@material-ui/core';
import { RemoveCircle, AddCircle, Clear, Visibility, VisibilityOff } from '@material-ui/icons';
import { MfpSettingContainer } from './MfpSettingContainer';
import { default as UI } from '@/common/constants/ui';

const styles = (theme: Theme) =>
    createStyles({
        gridFullWidth: {
            width: '100%',
            paddingRight: 40,
        },
        title: {
            fontSize: 14,
            lineHeight: '20px',
            color: '#666666',
            marginBottom: 15,
        },
        textFieldLayer: {
            width: 120,
            marginRight: 24,
        },
        textFieldHeight: {
            height: 66,
            borderRadius: 4,
            backgroundColor: '#eeeeee',
        },
        textField: {
            width: '100%',
            marginBottom: 15,
        },
        textFieldFormSize: {
            width: '45%',
            marginBottom: 15,
        },
        clearIcon: {
            width: '10%',
            height: 12,
        },
        iconCircle: {
            width: 16,
            height: 16,
            color: `${theme.palette.secondary.main}`,
            cursor: 'pointer',
        },
        iconToggle: {
            marginRight: 30,
        },
        buttonFirst: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: 6,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            height: 48,
            padding: 0,
            minWidth: 90,
            margin: 0,
            marginBottom: 30,
            textTransform: 'none',
            fontSize: 14,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
        },
        buttonMiddle: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: 0,
            height: 48,
            padding: 0,
            margin: 0,
            marginBottom: 30,
            textTransform: 'none',
            fontSize: 14,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
        },
        buttonLast: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: 6,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: 48,
            padding: 0,
            minWidth: 90,
            margin: 0,
            marginBottom: 30,
            textTransform: 'none',
            fontSize: 14,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
        },
        buttonSelected: {
            backgroundColor: `${theme.palette.secondary.main}`,
            color: '#ffffff',
        },
        description: {
            marginTop: theme.spacing.unit,
            fontSize: '14px',
            fontWeight: 500,
            color: '#333333',
            textAlign: 'left',
        },
        btnUpDown: {
            marginLeft: -24,
            '&:hover': {
                backgroundColor: '#ffffff',
                '@media (hover: none)': {
                    backgroundColor: '#ffffff',
                },
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    skipEffect?: boolean;
}

const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const mfpSettingContainer = MfpSettingContainer.useContainer();

    const container = Object.assign(mfpSettingContainer);
    const localeKeys = Object.assign(locale.keys.mfpDefaultSetting);
    const listFolder = [];
    for (let i = 1; i <= 4; i += 1) {
        listFolder.push(
            <Grid key={i} item xs={12} className={classes.gridFullWidth}>
                <TextField
                    className={classes.textField}
                    label={locale.t(localeKeys.labelFolder[`folder${i}`])}
                    variant="filled"
                    InputProps={{ disableUnderline: true, className: classes.textFieldHeight }}
                    value={container.defaultSetting.paramData ? container.defaultSetting.paramData[`folderTitle${i}`] : undefined}
                    onChange={container[`handleChangeFolder${i}`]}
                    onBlur={container[`onBlurFolder${i}`]}
                />
            </Grid>,
        );
    }
    const listPreset = [];
    for (let i = 1; i <= 3; i += 1) {
        listPreset.push(
            <Grid key={i} item container direction="column" justify="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                    <Typography className={classes.title}>{locale.t(localeKeys.title[`size${i}`])}</Typography>
                </Grid>
                <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center" className={classes.gridFullWidth}>
                    <TextField
                        className={classes.textFieldFormSize}
                        label={locale.t(localeKeys.labelFolder.width)}
                        variant="filled"
                        InputProps={{ disableUnderline: true, className: classes.textFieldHeight, inputProps: { maxLength: 3 } }}
                        value={container.defaultSetting.scanAttributeData[`preset${i}Horizontal`]}
                        onChange={container[`handleChangeWidth${i}`]}
                        onBlur={mfpSettingContainer.handleResetMinValue}
                    />
                    <Clear className={classes.clearIcon} />
                    <TextField
                        className={classes.textFieldFormSize}
                        label={locale.t(localeKeys.labelFolder.height)}
                        variant="filled"
                        InputProps={{ disableUnderline: true, className: classes.textFieldHeight, inputProps: { maxLength: 3 } }}
                        value={container.defaultSetting.scanAttributeData[`preset${i}Vertical`]}
                        onChange={container[`handleChangeHeight${i}`]}
                        onBlur={mfpSettingContainer.handleResetMinValue}
                    />
                </Grid>
            </Grid>,
        );
    }
    const folderFormat = [];
    for (let i = 0; i <= 1; i += 1) {
        folderFormat.push(
            <Button
                key={i}
                className={i === 0 ? classes.buttonFirst : i === 1 ? classes.buttonLast : classes.buttonMiddle}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.paramData && mfpSettingContainer.defaultSetting.paramData.folderFormat === i ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChooseDisplayForm(i)}
            >
                {i === 0 && locale.t(locale.keys.mfpDefaultSetting.switchButton.column)}
                {i === 1 && locale.t(locale.keys.mfpDefaultSetting.switchButton.list)}
            </Button>,
        );
    }

    // 表示形式：カラム選択時に選択不可
    const isDisplayColumnsDisabled = mfpSettingContainer.defaultSetting.paramData ? mfpSettingContainer.defaultSetting.paramData.folderFormat === 0 : false;
    const isDisplayColumnsSelected = (columns: number) => {
        return mfpSettingContainer.defaultSetting.paramData!.numberOfListDisplayColumns === columns;
    };
    const displayColumns = (
        <>
            <Button
                className={classes.buttonFirst}
                variant="contained"
                classes={{ root: isDisplayColumnsSelected(1) ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickDisplayColumns(1)}
                disabled={isDisplayColumnsDisabled && !isDisplayColumnsSelected(1)}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.displayColumns1)}
            </Button>
            <Button
                className={classes.buttonLast}
                variant="contained"
                classes={{ root: isDisplayColumnsSelected(2) ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickDisplayColumns(2)}
                disabled={isDisplayColumnsDisabled && !isDisplayColumnsSelected(2)}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.displayColumns2)}
            </Button>
        </>
    );

    const listOverwrite = [];
    for (let i = 1; i >= 0; i -= 1) {
        listOverwrite.push(
            <Grid item xs={6} key={i}>
                <Button
                    fullWidth
                    className={i === 1 ? classes.buttonFirst : i === 0 ? classes.buttonLast : classes.buttonMiddle}
                    variant="contained"
                    disabled={mfpSettingContainer.disabledOverwrite(i)}
                    classes={{ root: mfpSettingContainer.defaultSetting.paramData && mfpSettingContainer.defaultSetting.paramData.overwrite === i ? classes.buttonSelected : undefined }}
                    onClick={() => mfpSettingContainer.handleClickChooseOverwrite(i)}
                >
                    {i === 1 && locale.t(locale.keys.mfpDefaultSetting.switchButton.overwriteFileName)}
                    {i === 0 && locale.t(locale.keys.mfpDefaultSetting.switchButton.saveChildNumber)}
                </Button>
            </Grid>,
        );
    }

    return (
        <>
            {mfpSettingContainer.ui === UI.state.Error && <div data-testid={UI.state.Error}>Error</div>}
            {(mfpSettingContainer.ui === UI.state.Loaded || mfpSettingContainer.ui === UI.state.Loading || props.skipEffect) && (
                <Grid container direction="row" justify="center" alignItems="flex-start">
                    <Grid item md={6} container direction="column" justify="flex-start" alignItems="flex-start">
                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.selectFolder)}</Typography>
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center">
                            <IconButton
                                disableRipple
                                disableTouchRipple
                                className={classes.btnUpDown}
                                tabIndex={-1}
                                disabled={
                                    mfpSettingContainer.defaultSetting.paramData &&
                                    mfpSettingContainer.defaultSetting.paramData.folderFormat &&
                                    mfpSettingContainer.defaultSetting.paramData.folderFormat === 1
                                        ? true
                                        : false
                                }
                                onClick={mfpSettingContainer.handleClickRemoveALayer}
                            >
                                <RemoveCircle className={classes.iconCircle} />
                            </IconButton>
                            <TextField
                                className={classes.textFieldLayer}
                                label={locale.t(locale.keys.mfpDefaultSetting.labelFolder.noLayer)}
                                variant="filled"
                                InputProps={{ disableUnderline: true, className: classes.textFieldHeight }}
                                value={mfpSettingContainer.defaultSetting.paramData ? mfpSettingContainer.defaultSetting.paramData.folderDepth : undefined}
                                onChange={mfpSettingContainer.handleChangeNoLayer}
                                onBlur={mfpSettingContainer.handleResetMinValue}
                                disabled={
                                    mfpSettingContainer.defaultSetting.paramData &&
                                    mfpSettingContainer.defaultSetting.paramData.folderFormat &&
                                    mfpSettingContainer.defaultSetting.paramData.folderFormat === 1
                                        ? true
                                        : false
                                }
                            />
                            <IconButton
                                disableRipple
                                disableTouchRipple
                                className={classes.btnUpDown}
                                tabIndex={-1}
                                disabled={
                                    mfpSettingContainer.defaultSetting.paramData &&
                                    mfpSettingContainer.defaultSetting.paramData.folderFormat &&
                                    mfpSettingContainer.defaultSetting.paramData.folderFormat === 1
                                        ? true
                                        : false
                                }
                                onClick={mfpSettingContainer.handleClickAddALayer}
                            >
                                <AddCircle className={classes.iconCircle} />
                            </IconButton>
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center">
                            <span>{locale.t(locale.keys.mfpDefaultSetting.labelSwitch.iconFolder)}</span>
                            <Switch
                                className={classes.iconToggle}
                                onChange={mfpSettingContainer.handleSwitchIconFolder}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                checked={mfpSettingContainer.defaultSetting.paramData ? mfpSettingContainer.defaultSetting.paramData.iconVisible : undefined}
                            />
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center" wrap="nowrap" style={{ marginTop: -15 }}>
                            <span>{locale.t(locale.keys.mfpDefaultSetting.labelSwitch.autoSelect)}</span>
                            <Switch
                                className={classes.iconToggle}
                                onChange={mfpSettingContainer.handleSwitchAutoSelect}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                checked={mfpSettingContainer.defaultSetting.paramData ? mfpSettingContainer.defaultSetting.paramData.autoSelect : undefined}
                                disabled={
                                    mfpSettingContainer.defaultSetting.paramData &&
                                    mfpSettingContainer.defaultSetting.paramData.folderFormat &&
                                    mfpSettingContainer.defaultSetting.paramData.folderFormat === 1
                                        ? true
                                        : false
                                }
                            />
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid item sm={6} xs={12}>
                                <Typography align="left" className={classes.title}>
                                    {locale.t(locale.keys.mfpDefaultSetting.title.displayForm)}
                                </Typography>
                                <Grid container alignItems="flex-start">
                                    {folderFormat}
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography align="left" className={classes.title}>
                                    {locale.t(locale.keys.mfpDefaultSetting.title.displayColumns)}
                                </Typography>
                                <Grid container alignItems="flex-start">
                                    {displayColumns}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.titleFolder)}</Typography>
                        </Grid>
                        {listFolder}
                    </Grid>

                    <Grid item md={6} container direction="column" justify="flex-start" alignItems="flex-start">
                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.normalOperation)}</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.gridFullWidth}>
                            <TextField
                                className={classes.textField}
                                type={mfpSettingContainer.showPassword ? 'text' : 'password'}
                                label={locale.t(locale.keys.mfpDefaultSetting.labelFolder.password)}
                                variant="filled"
                                InputProps={{
                                    className: classes.textFieldHeight,
                                    disableUnderline: true,
                                    inputProps: { maxLength: 32 },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="Toggle password visibility" onClick={() => mfpSettingContainer.setShowPassword(!mfpSettingContainer.showPassword)}>
                                                {mfpSettingContainer.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                value={mfpSettingContainer.endpoint && mfpSettingContainer.endpoint.endpointPassword ? mfpSettingContainer.endpoint.endpointPassword : ''}
                                onChange={mfpSettingContainer.handleChangePassword}
                                onBlur={mfpSettingContainer.onBlurEndpointPwd}
                            />
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: 15 }}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.scanOperation)}</Typography>
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center" style={{ marginTop: -15 }}>
                            <span>{locale.t(locale.keys.mfpDefaultSetting.labelSwitch.scanPreview)}</span>
                            <Switch
                                className={classes.iconToggle}
                                onChange={mfpSettingContainer.handleSwitchScanPreview}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                checked={mfpSettingContainer.defaultSetting.scanAttributeData.scanPreview}
                                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.outputType === 9 || mfpSettingContainer.defaultSetting.scanAttributeData.outputType === 10}
                            />
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center" style={{ marginTop: -15 }}>
                            <span>{locale.t(locale.keys.mfpDefaultSetting.labelSwitch.overwriteWarningScanning)}</span>
                            <Switch
                                className={classes.iconToggle}
                                onChange={mfpSettingContainer.handleSwitchOverwrite}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                disabled={mfpSettingContainer.disabledOverwriteAlert()}
                                checked={mfpSettingContainer.defaultSetting.paramData ? mfpSettingContainer.defaultSetting.paramData.scanOverwriteAlert : undefined}
                            />
                        </Grid>
                        <Grid item xs={12} container className={classes.gridFullWidth}>
                            {listOverwrite}
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 15 }}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.formSize)}</Typography>
                        </Grid>
                        {listPreset}

                        <Grid item xs={11} container direction="column" justify="flex-start" alignItems="flex-start" className={classes.description}>
                            <Grid item>{locale.t(locale.keys.mfpDefaultSetting.description.tabOne1)}</Grid>
                            <Grid item>{locale.t(locale.keys.mfpDefaultSetting.description.tabOne2)}</Grid>
                            <Grid item>{locale.t(locale.keys.mfpDefaultSetting.description.tabOne3)}</Grid>
                            <Grid item>{locale.t(locale.keys.mfpDefaultSetting.description.tabOne4)}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default withStyles(styles)(Component);
