import { default as consts } from '@/common/constants';
import { axiosFactory } from '@/common/utils/axiosFactory';
import * as schema from "@/schema/typescript/schema";

/**
 * @description 通常業務の通知購読停止リクエスト
 */
export const unsubscribeEndpointNotification = async (code: string) => {
    const response = await axiosFactory.post<schema.V1EndpointsUnsubscribeCreateResponse>(`https://${consts.environment.api.hostname}/v1/endpoints/unsubscribe/${code}`);
    return response.data;
};
