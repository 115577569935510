import { default as React, useState } from 'react';
import { Fab, Card, CardContent, createStyles, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, Typography, Link, TextField, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import useTitle from '@/common/components/hooks/useTitle';
import ImageFileSelector from '@/common/components/ImageFileSelector';
import * as userProfile from '@/user/models/user-profile/userProfile';
import * as errorHandler from '@/common/utils/errorHandler';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as schema from '@/bundles/schema/typescript/schema';
import { Variants } from '@/common/components/messages/CommonMessage';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import * as logger from '@/common/utils/logger';
import * as uploader from '@/common/utils/uploader';
import * as validator from '@/common/utils/validator';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import LanguageSelect from '@/common/components/LanguageSelect';
import Error from '@/common/components/state/Error';
import Loading from '@/common/components/state/Loading';
import Saving from '@/common/components/state/Saving';
import { MultiLineText } from '@/common/components/messages/MultiLineText';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing.unit * 3,
            },
        },
        avatar: {
            border: `2px solid ${theme.palette.secondary.main}`,
            height: 160,
            width: 160,
        },
        button: {
            '&& span': {
                fontSize: '1.25rem',
                fontWeight: 'bold',
                [theme.breakpoints.up('md')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('md')]: {
                width: 'auto',
            },
            color: 'white',
            minWidth: '240px',
            margin: theme.spacing.unit,
        },
        singleCard: {
            [theme.breakpoints.down('sm')]: {
                boxShadow: 'initial',
            },
        },
        imageFileSelectorCardContent: {
            textAlign: 'center',
            paddingBottom: 0,
            height: '160px',
        },
        selectLanguage: {
            marginTop: '17px',
        },
        link: {
            cursor: 'pointer',
            color: theme.palette.secondary.main,
            display: 'block',
            fontWeight: 'bold',
            textAlign: 'left',
        },
        text: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        mb20: {
            marginBottom: 20,
        },
        mb30: {
            marginBottom: 30,
        },
        mb40: {
            marginBottom: 40,
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    ui?: UI;
    onTitle?: () => void;
    onDesc?: (desc?: string) => void;
}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;

    const ui = useUI(props.ui);

    useTitle(locale.t(locale.keys.pageTitle.userProfile.index));
    const appContainer = UserAppContainer.useContainer();
    const [form, setForm] = useState(userProfile.New(appContainer.values));
    const [avatarDataUri, setAvatarDataUri] = useState('');

    if (typeof props.onDesc === 'function') {
        props.onDesc(locale.t(locale.keys.profileAcount.description));
    }

    React.useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    React.useEffect(() => {
        if (typeof props.onTitle === 'function') {
            props.onTitle();
        }

        (async () => {
            if (form.init) {
                return;
            }
            try {
                const user = await userProfile.findUserProfile(appContainer.values.signinWorkspaceObject.id!, appContainer.values.signinWorkspaceUserObject.id!, appContainer.values.authorizationCode);
                logger.debug(user);
                logger.debug(user.invitationEmail);
                logger.debug(user.avatarUrl);
                let emailNotification;
                if (user.emailNotification != null) {
                    emailNotification = user.emailNotification!;
                } else {
                    emailNotification = {
                        serviceError: false,
                        sharingEvent: false,
                        supportMessage: false,
                    };
                }
                setForm({
                    ...form,
                    init: true,
                    email: user.invitationEmail,
                    name: user.name,
                    phoneticName: user.phoneticName,
                    avatarUrl: user.avatarUrl,
                    active: user.active,
                    role: user.role,
                    deviceLoginUser: user.deviceLoginUser,
                    language: user.language || appContainer.values.signinWorkspaceObject.language || '',
                    notifications: emailNotification,
                    contactEmail: user.contactEmail,
                });
                ui.update(UI.Loaded);
            } catch (e) {
                ui.update(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChangeAvatarDataUri = (v: string) => {
        setAvatarDataUri(v);
    };
    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            name: e.target.value,
        });
    };
    const handleValidateName = () => {
        if (!form.init) {
            return '';
        }
        const msg = validator.Validate<userProfile.Form>(form, userProfile.validations(), userProfile.NewValidation);
        let dispMessage = '';
        if (msg && msg.name) {
            dispMessage = msg.name.toString();
        }
        return dispMessage;
    };
    const handleValidatePhoneticName = () => {
        if (!form.init) {
            return '';
        }
        const msg = validator.Validate<userProfile.Form>(form, userProfile.validations(), userProfile.NewValidation);
        let dispMessage = '';
        if (msg && msg.phoneticName) {
            dispMessage = msg.phoneticName.toString();
        }
        return dispMessage;
    };

    const handleValidateDeviceLoginUser = () => {
        if (!form.init) {
            return '';
        }

        if (!form.deviceLoginUser) {
            return '';
        }
        const msg = validator.Validate<userProfile.Form>(form, userProfile.validations(), userProfile.NewValidation);
        let dispMessage = '';
        if (msg && msg.deviceLoginUser) {
            dispMessage = msg.deviceLoginUser.toString();
        }
        return dispMessage;
    };

    const handleChangePhoneticName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            phoneticName: e.target.value,
        });
    };

    const handleChangeDeviceLoginUser = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            deviceLoginUser: e.target.value,
        });
    };

    const handleChangeServiceError = (e: React.ChangeEvent<HTMLInputElement>) => {
        logger.debug('handleChangeServiceError');
        setForm({
            ...form,
            notifications: {
                ...form.notifications,
                serviceError: !form.notifications.serviceError,
            },
        });
    };
    const handleChangeTimeLine = (e: React.ChangeEvent<HTMLInputElement>) => {
        logger.debug('handleChangeTimeline');
        setForm({
            ...form,
            notifications: {
                ...form.notifications,
                sharingEvent: !form.notifications.sharingEvent,
            },
        });
    };
    const handleChangeCloud = (e: React.ChangeEvent<HTMLInputElement>) => {
        logger.debug('handleChangeCloud');
        setForm({
            ...form,
            notifications: {
                ...form.notifications,
                supportMessage: !form.notifications.supportMessage,
            },
        });
    };
    const handleChangeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
        logger.debug('handleChangeLanguage');
        setForm({
            ...form,
            language: e.target.value === locale.Language.English ? schema.Language.En : schema.Language.Ja,
        });
    };
    const handleValidateLanguage = () => {
        if (!form.init) {
            return '';
        }
        const msg = validator.Validate<userProfile.Form>(form, userProfile.validations(), userProfile.NewValidation);
        let dispMessage = '';
        if (msg && msg.language) {
            dispMessage = msg.language.toString();
        }
        return dispMessage;
    };
    // 連絡先Eメールアドレス
    const handleChangeContactEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            contactEmail: e.target.value,
        });
    };
    const handleValidateContactEmail = () => {
        const msg = validator.Validate<userProfile.Form>(form, userProfile.validations(), userProfile.NewValidation);
        let dispMessage = '';
        if (msg && msg.contactEmail) {
            dispMessage = msg.contactEmail.toString();
        }
        return dispMessage;
    };
    const handleButton = () => {
        return validator.Validate<userProfile.Form>(form, userProfile.validations(), userProfile.NewValidation) !== null;
    };
    const handleUpdate = () => {
        ui.update(UI.Saving);
        (async () => {
            try {
                // req: schema.V1WorkspaceuserUpdateRequest, userId: string, auth: string
                let uploadedAvatarUrl: string | undefined = undefined;
                if (avatarDataUri !== '') {
                    const resp = await uploader.Upload(`${appContainer.values.signinWorkspaceUserObject.id!}.png`, schema.BlobType.UserAvatar, appContainer.values.authorizationCode, avatarDataUri);
                    uploadedAvatarUrl = resp.publicUrl;
                }
                const req: schema.V1WorkspaceuserUpdateRequest = {
                    active: form.active,
                    avatarUrl: uploadedAvatarUrl,
                    language: form.language,
                    name: form.name,
                    phoneticName: form.phoneticName,
                    emailNotification: form.notifications,
                    role: form.role,
                    deviceLoginUser: form.deviceLoginUser,
                    pin: form.pin,
                    contactEmail: form.contactEmail,
                };
                const res = await userProfile.updateUserProfile(req, appContainer.values.signinWorkspaceUserObject.id!, appContainer.values.authorizationCode);
                appContainer.setValues({
                    ...appContainer.values,
                    signinWorkspaceUserObject: res.user,
                });
                setForm({
                    ...form,
                    init: true,
                    email: res.user.invitationEmail,
                    name: res.user.name,
                    phoneticName: res.user.phoneticName,
                    avatarUrl: res.user.avatarUrl,
                    active: res.user.active,
                    role: res.user.role,
                    language: res.user.language || '',
                    deviceLoginUser: res.user.deviceLoginUser,
                    contactEmail: res.user.contactEmail,
                });
                logger.debug(res);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.updated),
                    variant: Variants.success,
                });
                // 言語設定
                locale.set(res.user.language, appContainer.values.signinWorkspaceObject.language || '');
                ui.update(UI.Loaded);
            } catch (e) {
                ui.update(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    return (
        <div className={classes.root}>
            {ui.current === UI.Loading && (
                <div data-testid={UI.Loading}>
                    <Loading />
                </div>
            )}
            {ui.current === UI.Loaded && (
                <div data-testid={UI.Loaded}>
                    <Grid container spacing={24}>
                        <Grid item xs={12} md={8}>
                            <Card className={classes.singleCard}>
                                <CardContent>
                                    {/* 属性は仮のものです */}
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} sm={5}>
                                            <Typography align="left" color="textSecondary">
                                                {locale.t(locale.keys.profileAcount.avatar.index)}
                                            </Typography>
                                            <CardContent className={classes.imageFileSelectorCardContent}>
                                                <div>
                                                    <ImageFileSelector
                                                        seed={form.email}
                                                        uploadWidth={512}
                                                        uploadHeight={512}
                                                        mobileWidth={160}
                                                        mobileHeight={160}
                                                        pcWidth={160}
                                                        pcHeight={160}
                                                        defaultUrl={form.avatarUrl}
                                                        logoDataUri={avatarDataUri}
                                                        isAvatar={true}
                                                        onLoaded={(datauri) => {
                                                            handleChangeAvatarDataUri(datauri);
                                                        }}
                                                        dependAppContainer={UserAppContainer.useContainer}
                                                        editable={true}
                                                    />
                                                </div>
                                            </CardContent>
                                        </Grid>
                                        <Grid item xs={12} sm={7} style={{ paddingBottom: 0 }}>
                                            <CardContent style={{ paddingBottom: 0 }}>
                                                <TextField
                                                    value={form.email}
                                                    disabled={true}
                                                    className={classes.mb20}
                                                    inputProps={{ style: { height: '100%' } }}
                                                    fullWidth
                                                    label={`${locale.t(locale.keys.common.email)}`}
                                                />
                                                <Grid container>
                                                    <Link className={`${classes.link} ${classes.mb20}`} data-testid="editPWButton" onClick={() => props.history.push('change/password')}>
                                                        {`${locale.t(locale.keys.profileAcount.passwordChange.index)}`}
                                                    </Link>
                                                </Grid>
                                                <TextField
                                                    value={form.name}
                                                    className={`${classes.text}`}
                                                    margin="normal"
                                                    variant="filled"
                                                    label={`${locale.t(locale.keys.common.name)}`}
                                                    inputProps={{ style: { height: '100%' } }}
                                                    onChange={handleChangeName}
                                                    error={handleValidateName() !== ''}
                                                    helperText={handleValidateName()}
                                                />
                                                <TextField
                                                    value={form.phoneticName}
                                                    className={`${classes.text}`}
                                                    margin="normal"
                                                    variant="filled"
                                                    label={`${locale.t(locale.keys.common.phonetic)}`}
                                                    inputProps={{ style: { height: '100%' } }}
                                                    onChange={handleChangePhoneticName}
                                                    error={handleValidatePhoneticName() !== ''}
                                                    helperText={handleValidatePhoneticName()}
                                                />
                                                <TextField
                                                    value={form.deviceLoginUser}
                                                    className={`${classes.text}`}
                                                    margin="normal"
                                                    variant="filled"
                                                    label={`${locale.t(locale.keys.common.deviceLoginUser)}`}
                                                    inputProps={{ style: { height: '100%' } }}
                                                    onChange={handleChangeDeviceLoginUser}
                                                    error={handleValidateDeviceLoginUser() !== ''}
                                                    helperText={handleValidateDeviceLoginUser()}
                                                />

                                                <div className={classes.selectLanguage}>
                                                    <LanguageSelect
                                                        disabled={false}
                                                        value={form.language === schema.Language.Ja ? locale.Language.Japanese : locale.Language.English}
                                                        label={locale.t(locale.keys.newWorkspaceSetting.workspaceLanguage)}
                                                        handleChange={handleChangeLanguage}
                                                        handleValidate={handleValidateLanguage}
                                                    />
                                                </div>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    label={locale.t(locale.keys.common.contactEmail)}
                                                    value={form.contactEmail}
                                                    inputProps={{ style: { height: '100%' } }}
                                                    onChange={handleChangeContactEmail}
                                                    error={handleValidateContactEmail() !== ''}
                                                    helperText={handleValidateContactEmail()}
                                                />
                                                <MultiLineText value={locale.t(locale.keys.common.contactEmailInfo)} align="left" variant="caption" color="textSecondary" />
                                                <div style={{ display: 'none' }}>
                                                    <FormControl fullWidth>
                                                        <FormHelperText>{`${locale.t(locale.keys.profileAcount.notification.index)}`}</FormHelperText>
                                                        <FormControlLabel
                                                            control={<Checkbox checked={form.notifications.supportMessage} data-testid="noticeByEmail" onChange={handleChangeCloud} />}
                                                            label={`${locale.t(locale.keys.profileAcount.notification.cloud)}`}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={form.notifications.serviceError} onChange={handleChangeServiceError} />}
                                                            label={`${locale.t(locale.keys.profileAcount.notification.serviceError)}`}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={form.notifications.sharingEvent} data-testid="noticeByEmail" onChange={handleChangeTimeLine} />}
                                                            label={`${locale.t(locale.keys.profileAcount.notification.timeline)}`}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </CardContent>
                                        </Grid>
                                        <Grid item xs={12} style={{ width: '100%' }}>
                                            <Fab variant="extended" className={classes.button} size="large" color="secondary" disabled={handleButton()} onClick={(e) => handleUpdate()}>
                                                {locale.t(locale.keys.profileAcount.button.save)}
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            )}

            {ui.current === UI.Saving && (
                <div data-testid={UI.Saving}>
                    <Saving />
                </div>
            )}

            {ui.current === UI.Error && (
                <div data-testid={UI.Error}>
                    <Error />
                </div>
            )}
        </div>
    );
};

export default withRouter(withStyles(styles)(Component));
