import React, { FC, useState } from 'react';
import { Checkbox, Grid, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, WithStyles, createStyles, withStyles } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import { styles as baseTableStyles } from '@/common/components/Table/style';
import * as schema from '@/bundles/schema/typescript/schema';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { Search, UnfoldMore } from '@material-ui/icons';
import {
    getGroupPermission,
    selectPermittedGroup,
    selectPermittedGroupAll,
    UserPermissionRow,
    isSelectedAllGroup,
    selectedUserCount,
    GroupPermissionRow,
} from '@/common/models/endpoints/useEndpoints';
import { CovasGroupAvatar } from '@/common/components/CovasAvatar';

const styles = () =>
    createStyles({
        ...baseTableStyles(),
        root: {
            width: '100%',
            gap: '16px',
        },
        searchRoot: {
            width: '100%',
            display: 'flex',
            overflowX: 'auto',
        },
        searchIconButton: {
            marginBlock: 'auto',
            marginInline: '10px',
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: '8px',
        },
        table: {
            width: '100%',
            padding: 0,
        },
    });

interface Props extends WithStyles<typeof styles> {
    groupPermission: GroupPermissionRow[];
    selectedGroupPermission: string[];
    handleGroupPermission: (groupPermission: string[]) => void;
    userPermission: UserPermissionRow[];
    selectedUserPermission: string[];
}

const GroupPermissionTable: FC<Props> = (props: Props) => {
    const defaultRows = props.groupPermission;
    const [rows, setRows] = useState<GroupPermissionRow[]>(getGroupPermission(defaultRows, '', 5, 0, schema.V1ObjectsSort.None, true));
    const [sortByName, setSortByName] = useState<schema.V1ObjectsSort>(schema.V1ObjectsSort.None);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [filteredCount, setFilteredCount] = useState(getGroupPermission(defaultRows, '', 0, 0, schema.V1ObjectsSort.None, false).length);

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, updatedPage: number) => {
        setPage(updatedPage);
        setRows(getGroupPermission(defaultRows, searchText, rowsPerPage, rowsPerPage * updatedPage, sortByName, true));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const updatedRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(updatedRowsPerPage);
        setRows(getGroupPermission(defaultRows, searchText, updatedRowsPerPage, updatedRowsPerPage * page, sortByName, true));
    };

    const handleChangeSearchText = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchText(event.target.value);
        setRows(getGroupPermission(defaultRows, event.target.value, rowsPerPage, 0, sortByName, true));
        setFilteredCount(getGroupPermission(defaultRows, event.target.value, 0, 0, sortByName, false).length);
    };

    const handleChangeSortByName = () => {
        let updateSort = schema.V1ObjectsSort.None;
        switch (sortByName) {
            case schema.V1ObjectsSort.None:
                updateSort = schema.V1ObjectsSort.Asc;
                break;
            case schema.V1ObjectsSort.Asc:
                updateSort = schema.V1ObjectsSort.Desc;
                break;
            case schema.V1ObjectsSort.Desc:
                updateSort = schema.V1ObjectsSort.None;
                break;
        }
        setSortByName(updateSort);
        setRows(getGroupPermission(defaultRows, searchText, rowsPerPage, rowsPerPage * page, updateSort, true));
    };

    const countSelectedUser = () => {
        // 選択済みのユーザーのうち、アクティブなユーザーの数をカウント
        // 停止中のユーザーがアクティブになった場合は、以前の許可状態を保持する仕様である。
        // アクティブの時に許可したユーザーが停止中になったとしても、許可状態は保持される。
        const uniqueCount = selectedUserCount(props.selectedUserPermission, props.selectedGroupPermission, props.groupPermission);
        return uniqueCount.filter((id) => props.userPermission.map((user) => user.id).includes(id)).length.toString();
    };

    const getEmptyRowCount = () => {
        return rowsPerPage - Math.min(rowsPerPage, rows.length);
    };

    return (
        <Grid container direction="column">
            <Grid item>
                <Typography>{locale.t(locale.keys.endpointsPermission.userGroupPermission.description)}</Typography>
            </Grid>
            <Grid item>
                <TextField
                    placeholder={locale.t(locale.keys.endpointsPermission.userGroupPermission.groupTable.searchGroup)}
                    variant="outlined"
                    margin={'dense'}
                    fullWidth
                    style={{ marginBlock: '4px' }}
                    InputLabelProps={{ style: { fontSize: 12, transform: 'translate(14px, 10px) scale(1)' } }}
                    InputProps={{ style: { fontSize: 14, height: 36 } }}
                    value={searchText}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChangeSearchText(e)}
                />
            </Grid>
            <Grid item>
                <Typography>
                    {locale.t(locale.keys.endpointsPermission.userGroupPermission.selectedNumber, {
                        n: props.selectedGroupPermission.length.toString(),
                        i: countSelectedUser(),
                    })}
                </Typography>
            </Grid>
            <Grid item>
                <Table className={props.classes.table}>
                    <TableHead>
                        <TableRow className={props.classes.headerFont}>
                            <TableCell padding="none" className={props.classes.tableCell} style={{ width: 40 }}>
                                <Checkbox
                                    color="secondary"
                                    checked={rows.length !== 0 && isSelectedAllGroup(props.selectedGroupPermission, getGroupPermission(defaultRows, searchText, 0, 0, sortByName, false))}
                                    onChange={() => {
                                        const targetRows = getGroupPermission(defaultRows, searchText, 0, 0, sortByName, false);
                                        const updateSelected = selectPermittedGroupAll(targetRows, props.selectedGroupPermission, !isSelectedAllGroup(props.selectedGroupPermission, targetRows));
                                        props.handleGroupPermission(updateSelected);
                                    }}
                                />
                            </TableCell>
                            <TableCell className={props.classes.tableCell} style={{ width: 60 }} />
                            <TableCell padding="none" className={props.classes.tableCell}>
                                <Grid className={props.classes.gridInsideHeaderCell}>
                                    <p className={props.classes.gridInsideHeaderCellText}>{locale.t(locale.keys.endpointsPermission.userGroupPermission.groupTable.cell.name)}</p>
                                    <Search style={{ marginBlock: 'auto' }} />
                                    <IconButton onClick={() => handleChangeSortByName()}>
                                        <UnfoldMore />
                                    </IconButton>
                                </Grid>
                            </TableCell>
                            <TableCell padding="none" className={props.classes.tableCell}>
                                {locale.t(locale.keys.endpointsPermission.userGroupPermission.groupTable.cell.memberCount)}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                            return (
                                <TableRow key={row.id} tabIndex={-1} selected={false} data-testid="row" className={index % 2 ? props.classes.evenRow : props.classes.oddRow}>
                                    <TableCell padding="none" className={props.classes.tableCell} style={{ width: 40 }}>
                                        <Checkbox
                                            color="secondary"
                                            checked={props.selectedGroupPermission.includes(row.id)}
                                            onChange={() => {
                                                props.handleGroupPermission(selectPermittedGroup(props.selectedGroupPermission, row.id));
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell className={props.classes.tableCell} style={{ width: 60 }}>
                                        <CovasGroupAvatar alt="Workspace" size={40} src={row.avatarUrl} />
                                    </TableCell>
                                    <TableCell padding="none" className={props.classes.tableCell}>
                                        <Grid container direction="column" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                            {row.name}
                                        </Grid>
                                    </TableCell>
                                    <TableCell padding="none" className={props.classes.tableCell}>
                                        {row.members.length}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {getEmptyRowCount() > 0 && (
                            <TableRow style={{ height: 49 * getEmptyRowCount() }}>
                                <TableCell colSpan={100} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredCount}
                    labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                    labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                        <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
                    )}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': locale.t(locale.keys.table.previousPage),
                    }}
                    nextIconButtonProps={{
                        'aria-label': locale.t(locale.keys.table.nextPage),
                    }}
                    onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => handleChangePage(event, p)}
                    onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChangeRowsPerPage(event)}
                    data-testid="pagenation-root"
                />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(GroupPermissionTable);
