import * as React from 'react';

import { Theme, createStyles, WithStyles, Button, withStyles, Grid } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';

import { context } from '@/user/components/mfp/Context';
import locale from '@/common/utils/locale';
import Confirm from '@/common/components/Confirm/Confirm';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

const styles = (theme: Theme) =>
    createStyles({
        deleteBtn: {
            alignItems: 'center',
            color: theme.palette.grey[700],
            display: 'flex',
            fontSize: '0.75rem',
            '&& svg': {
                marginRight: theme.spacing.unit * 1,
            },
        },
        root: {
            height: 46,
        },
    });

const Consumer = context.Consumer;

interface RemoveButtonProp {
    textMessageDelete?: string;
    index: number;
    theme: Theme;
    disabled?: boolean;
}

interface Props extends WithStyles<typeof styles>, RemoveButtonProp {}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const matchesMd = useMediaQuery(props.theme.breakpoints.up('md'));

    return (
        <Consumer>
            {(app) =>
                app &&
                (props.disabled ? (
                    <Grid className={classes.root} />
                ) : (
                    <Grid className={classes.root}>
                        <Confirm value={props.index.toString()} name={props.textMessageDelete} callBack={app.confirmRemove}>
                            <Button className={classes.deleteBtn}>
                                <Delete />
                                {matchesMd ? locale.t(locale.keys.mfp.deletePC) : locale.t(locale.keys.mfp.delete)}
                            </Button>
                        </Confirm>
                    </Grid>
                ))
            }
        </Consumer>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
