import { useState } from 'react';

export const useMail = () => {
    const [mail, setMail] = useState('');

    const mailInput = (event: any) => {
        setMail(event.target.value);
    };

    return { mail, mailInput };
};

export const useValue = () => {
    const [values, setValues] = useState({
        cloud: '',
        name: '',
    });

    const handleChange = (event: any) => {
        setValues((oldValues) => ({
            ...oldValues,
            [event.target.name]: event.target.value,
        }));
    };

    return { values, handleChange };
};
