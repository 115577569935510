import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import querystring from 'querystring';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

/**
 * ストレージのフォルダ取得
 * @param id
 * @param path
 * @param auth
 * @param cursor
 * @param filter
 */
export const getListFolder = async (id: string, path: string, auth: string, cursor?: string, filter?: string): Promise<schema.V1XStoragesFilesShowResponse> => {
    const query = querystring.stringify({
        path,
        cursor,
        filter,
    });

    const response = await axiosFactory.get<schema.V1XStoragesFilesShowResponse>(`https://${consts.environment.api.hostname}/v1/x-storages/${id}/files?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
