import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import locale from '@/common/utils/locale';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '24px 0',
            margin: '0 24px',
        },
        action: {
            marginRight: theme.spacing.unit * 2,
            // borderTop: '1px solid #ccc',
            '&& button': {
                textTransform: 'unset',
            },
        },
        yesBtn: {
            color: '#ff7300',
            border: 'none',
        },
    });

interface Props extends WithStyles<typeof styles> {
    message: string;
    open: boolean;
    handleClose: () => void;
}

const Confirm: React.FC<Props> = (props: Props) => {
    return (
        <div>
            <Dialog open={props.open} onClose={props.handleClose}>
                <DialogContent className={props.classes.content}>
                    <DialogContentText>{props.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className={props.classes.action}>
                        <Button className={props.classes.yesBtn} onClick={props.handleClose} variant="outlined">
                            {locale.t(locale.keys.confirmDeleted.button.yes)}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withStyles(styles)(Confirm);
