import { Theme, createStyles } from '@material-ui/core';
import ui from '@/common/constants/ui';

export const styles = (theme: Theme) =>
    createStyles({
        header: {
            height: ui.headerHeight,
            justifyContent: 'center',
            position: 'fixed',
        },
        descheader: {
            height: ui.headerDescHeight,
            justifyContent: 'center',
            position: 'fixed',
        },
        menuButton: {
            position: 'absolute',
            right: 0,
        },
        arrowBackButton: {
            position: 'absolute',
            left: 0,
        },
        heading: {
            margin: 0,
        },
        link: {
            color: '#fff',
        },
        popper: {
            opacity: 1,
        },
        lightTooltip: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.bodyText.main,
            boxShadow: theme.shadows[1],
            fontSize: 12,
        },
        tooltipButton: {
            color: theme.palette.common.white,
            padding: 0,
            marginInline: '4px',
        },
    });

export default styles;
