import * as React from 'react';
import { Route, Redirect, RouteProps, useHistory, useLocation } from 'react-router';
import logger from '@/common/utils/logger';
import routes from '@/user/constants/routes';
import { parse } from 'query-string';
import * as cookies from '@/common/utils/browser/cookies';
import { keys as cookieKeys } from '@/common/utils/authManagement/cookieConstants';
import { resetAgentCookie, isAgent } from '@/common/utils/authManagement/agentSignIn';
import * as UserAppContainer from '@/user/components/UserAppContainer';
import * as login from '@/user/models/auth/login/Login';

interface Props extends RouteProps {}

export const Component: React.FC<Props> = (props) => {
    const { component, path, exact, children } = props;
    const history = useHistory();
    const location = useLocation();
    const loggedin = () => {
        // /auth以下のパス（トークンなしでアクセスできる画面）の場合はログインチェックを行わない
        if (history.location.pathname.startsWith(routes.auth.index)) {
            return true;
        } else {
            const state = UserAppContainer.initStateWithCache();
            if (cookies.get<string>(cookieKeys.authorizationCode) === null) {
                // 認証トークンが期限切れでCookieに存在しない場合はログイン画面に遷移
                return false;
            } else if (!(state.signinWorkspaceObject != null && state.signinWorkspaceObject.displayId !== '')) {
                // キャッシュが存在しない場合は強制ログアウト
                cookies.remove(cookieKeys.authorizationCode);
                return false;
            } else if (!login.roleCheck(state.signinWorkspaceObject, state.signinWorkspaceUserObject)) {
                // メンバーサイトに入れないロールの場合は強制ログアウト
                // 外部管理者はメンバーサイトにログインできない仕様である
                cookies.remove(cookieKeys.authorizationCode);
                return false;
            } else {
                return true;
            }
        }
    };
    const getRedirect = () => {
        const parsedQuery = parse(window.location.search);
        if (parsedQuery.redirectFrom != null && typeof parsedQuery.redirectFrom === 'string') {
            logger.debug(parsedQuery);
            let queryString = '';
            Object.keys(parsedQuery)
                .filter((key) => {
                    return key !== 'redirectFrom';
                })
                .forEach((key) => {
                    const raw = parsedQuery[key];
                    if (raw) {
                        const value =
                            typeof raw === 'string'
                                ? (raw as string).split('.').join('%2E')
                                : (raw as string[])
                                      .toString()
                                      .split('.')
                                      .join('%2E');
                        queryString += `&${key}=${value}`;
                    }
                });
            return `${(parsedQuery.redirectFrom as string).split('.').join('%2E')}${queryString}`;
        }
        return '';
    };
    if (getRedirect() !== '') {
        return <Redirect to={getRedirect()} />;
    }
    if (!loggedin()) {
        // 代理ログインのセッション切れ（errorHandlerで401と403エラーでCookieが削除された時）の場合は、代理ログイン元に戻る
        if (isAgent()) {
            // isAgentがtrueの場合はresetAgentCookie関数でエラーは絶対に発生しない
            // 代理ログインのセッション切れの時に、代理ログイン用のCookieを削除せずに/auth以下の画面に遷移すると無限ループが発生するので注意
            resetAgentCookie();
            return <></>;
        }
        /**
         * return_toのクエリパラメータを付けたままサインインをするとreturn_toのパスにサインインできる。
         * 下記の2コンポーネントで使用される
         * - src/user/components/auth/signin/password-auth/List.tsx パスワードでのサインイン画面
         * - src/user/components/auth/signin/select-workspace/Select.tsx ワークスペース選択画面におけるサインイン済みWS選択機能
         */
        const returnTo = encodeURIComponent(location.pathname + location.search);
        return <Redirect to={`${routes.auth.login.index}?return_to=${returnTo}`} />;
    }
    return (
        <Route exact={exact} path={path} component={component}>
            {children}
        </Route>
    );
};

export default Component;
