import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, ListItemText, Select, MenuItem } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import { NewEndpointContainer } from './NewEndpointContainer';
import * as webappUtil from '@/common/utils/webappUtil';

const styles = (theme: Theme) =>
    createStyles({
        select: {
            '&& svg': {
                marginTop: '-10px',
            },
        },
        label: {
            fontSize: '0.875rem',
            margin: 0,
            padding: `${theme.spacing.unit}px ${theme.spacing.unit * 1.5}px 0`,
            color: '#666666',
        },
        box: {
            backgroundColor: '#eee',
            borderTopLeftTadius: 4,
            borderTopRightRadius: 4,
            marginBottom: theme.spacing.unit * 3,
            width: '100%',
            '&&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.13)',
            },
            '&& div': {
                paddingLeft: 4,
                paddingRight: 4,
                fontSize: '1.125rem',
            },
        },
    });

export interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const newEndpointContainer = NewEndpointContainer.useContainer();
    const [xstorageList, setXstorageList] = React.useState();

    const handleChange = (event: any) => {
        // 選択しているクラウド接続を変更したら、パスとパスIDをクリアする
        // でないと、存在しないバスを指定してエラーが発生する
        if (newEndpointContainer.activeStorage.xstorageId !== event.target.value) {
            newEndpointContainer.getPath('', '');
        }
        newEndpointContainer.storageList.xstorages.forEach((element: any) => {
            if (element.xstorageId === event.target.value) {
                newEndpointContainer.setActiveStorage(element);
                newEndpointContainer.setFeature({
                    isUploadable: element.isUploadable!,
                    isDownloadable: element.isDownloadable!,
                });
            }
        });
    };

    React.useEffect(() => {
        const list = [];
        for (let i = 0; i < newEndpointContainer.storageList.xstorages.length; i += 1) {
            const item = newEndpointContainer.storageList.xstorages[i];
            list.push(
                <MenuItem key={i} value={item.xstorageId}>
                    <ListItemText
                        primary={webappUtil.getServiceText(item.service)}
                        secondary={![schema.V1ObjectsServiceEnum.Nonecloud, schema.V1ObjectsServiceEnum.Email].includes(item.service) && item.authorizationId}
                    />
                </MenuItem>,
            );
        }
        setXstorageList(list);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className={classes.box}>
                <p className={classes.label}>{locale.t(locale.keys.createEnpoint.cloudConnect)}</p>
                <Select className={classes.select} value={newEndpointContainer.activeStorage.xstorageId} fullWidth onChange={handleChange}>
                    {xstorageList}
                </Select>
            </div>
        </>
    );
};

export default withStyles(styles)(Component);
