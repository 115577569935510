import { default as React, useEffect } from 'react';
import locale from '@/common/utils/locale';
import { Theme, WithStyles, Card, CardContent, Typography, CardActions, Button, createStyles, withStyles, IconButton, Collapse, Grid } from '@material-ui/core';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { Edit, Delete, ExpandMore } from '@material-ui/icons';
import dummyList from './dummyList';
import { ScanContainer } from './ScanContainer';
import { RouteComponentProps, withRouter, generatePath } from 'react-router';
import * as queryString from 'query-string';
import Confirm from '@/common/components/Confirm/Confirm';
import { default as UI } from '@/common/constants/ui';
import { UserAppContainer } from '../../UserAppContainer';
import { isLoaded, isLoading, isError } from '@/common/components/hooks/useUI';
import clsx from 'clsx';
import * as errorHandler from '@/common/utils/errorHandler';
import routes from '@/user/constants/routes';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexWrap: 'wrap',
            position: 'relative',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
        },
        card: {
            marginBottom: theme.spacing.unit * 2,
            marginLeft: theme.spacing.unit * 2,
            width: `calc((100% - ${theme.spacing.unit * 10}px) / 4)`,
            [theme.breakpoints.down('md')]: {
                width: 'calc(100% - 32px)',
            },
        },
        titleDiv: {
            height: '100px',
            backgroundColor: '#ffffff',
            padding: '7px 16px 0px 16px',
            marginBottom: 30,
            '&& h4': {
                textAlign: 'left',
                height: '21px',
                fontSize: '1rem',
                fontWeight: 'bold',
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: '1.31',
                letterSpacing: 'normal',
                color: '#333333',
            },
        },
        ul: {
            padding: 0,
            listStyle: 'none',
            marginBottom: theme.spacing.unit * 2,
            textAlign: 'left',
            '&& li': {
                marginBottom: theme.spacing.unit * 2,
            },
            '&& li:last-of-type': {
                marginBottom: 0,
            },
        },
        ulTitle: {
            paddingLeft: 0,
            listStyle: 'none',
            marginBottom: theme.spacing.unit * 2,
            textAlign: 'left',
            '&& li': {
                marginBottom: theme.spacing.unit * 2,
            },
            '&& li:last-of-type': {
                marginBottom: 0,
            },
        },
        contentTextHeader: {
            wordWrap: 'break-word',
            minHeight: '38px',
            fontSize: '0.9375rem',
            fontWeight: 500,
            margin: 0,
            lineHeight: '1.66',
            color: '#333333',
            maxHeight: '48px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
        },
        title: {
            fontSize: '0.875rem',
            height: '0.875rem',
            marginBottom: theme.spacing.unit,
            fontWeight: 500,
            color: '#666666',
        },
        contentText: {
            fontSize: '0.9375rem',
            fontWeight: 500,
            margin: 0,
            lineHeight: '1.67',
            // whiteSpace: 'nowrap',
            color: '#333333',
        },
        contentText2: {
            fontSize: '0.9375rem',
            fontWeight: 500,
            margin: 0,
            lineHeight: '1.67',
            // whiteSpace: 'nowrap',
            color: '#333333',
            height: '20vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
        },
        action: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        deleteButton: {
            fontWeight: 'bold',
            color: '#666666',
            fontSize: '0.8125rem',
        },
        updateButton: {
            backgroundColor: '#ff7300',
            color: '#ffffff',
            borderRadius: 6,
            paddingRight: 15,
            '&:hover': {
                backgroundColor: '#ff7300',
                color: '#ffffff',
            },
        },
        iconButton: {
            height: '17px',
        },
        expand: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        divider: {
            margin: '0 16px',
        },
    });
type TParams = { id: string };

interface Props extends WithStyles<typeof styles>, RouteComponentProps<TParams> {
    theme: Theme;
    skipEffect?: boolean;
    onLoadingState?: (flag: boolean) => void;
}
export const Component: React.SFC<Props> = (props) => {
    const matchesLg = useMediaQuery(props.theme.breakpoints.up('lg'));
    // get id from url
    const id = props.match.params.id;
    // get type of link (upload /download)
    const query = queryString.parse(props.location.search);
    let type = '';
    if (query && query.type && typeof query.type === 'string') {
        type = query.type;
    }
    const list = [];
    const scanContainer = ScanContainer.useContainer();
    const appContainer = UserAppContainer.useContainer();

    React.useEffect(() => {
        appContainer.updateLoadingState(scanContainer.ui);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanContainer.ui]);

    // effect using for get list data
    // empty array to avoid call after update
    React.useEffect(() => {
        if (props.skipEffect) {
            scanContainer.loadDummyData(dummyList);
            scanContainer.setUI(UI.state.Loaded);
            return;
        }
        void (async () => {
            try {
                scanContainer.setUI(UI.state.Loading);
                await scanContainer.getListDefaultSetting(id);
                scanContainer.setUI(UI.state.Loaded);
            } catch (e) {
                scanContainer.setUI(UI.state.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (scanContainer.goToDefaultSetting) {
            props.history.push(generatePath(routes.endpoints.scan.edit, { id, mfpSettingId: scanContainer.idSelected }));
            scanContainer.setGoToDefaultSetting(false);
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanContainer.goToDefaultSetting]);

    const [expanded, setExpanded] = React.useState<string[]>([]);

    const handleExpandClick = (i: string) => {
        let count = 0;
        let existFlag = false;
        const coppyArr = [...expanded];
        for (const j in coppyArr) {
            if (coppyArr[j] === i) {
                coppyArr.splice(count, 1);
                existFlag = true;
            }
            count += 1;
        }
        if (!existFlag) {
            setExpanded([...expanded, i]);
        } else {
            setExpanded([...coppyArr]);
        }
    };

    const checkExistData = (i: string) => {
        for (const j in expanded) {
            if (expanded[j] === i) {
                return true;
            }
        }
        return false;
    };

    if (scanContainer.defaultSettingList && scanContainer.defaultSettingList.defaultSettings) {
        for (const i in scanContainer.defaultSettingList.defaultSettings) {
            const data = scanContainer.defaultSettingList.defaultSettings[i];
            list.push(
                matchesLg ? (
                    <Card key={i} data-testid="cards" className={props.classes.card}>
                        {i !== '0' ? (
                            <div className={props.classes.titleDiv}>
                                <Typography variant="h5" component="h4" className={props.classes.contentTextHeader}>
                                    {locale.t(locale.keys.endpointScan.label.scanSettingName, { name: data.mfpInfo.deviceName })}
                                </Typography>
                                <ul className={props.classes.ulTitle}>
                                    <li>
                                        <p className={props.classes.title}>{locale.t(locale.keys.endpointScan.label.userAgent)}</p>
                                        <p className={props.classes.contentTextHeader}>{data.mfpInfo.type}</p>
                                    </li>
                                </ul>
                            </div>
                        ) : (
                            <div className={props.classes.titleDiv}>
                                <Typography variant="h5" component="h4">
                                    {locale.t(locale.keys.endpointScan.label.initScanSetting)}
                                </Typography>
                                <Typography gutterBottom variant="caption" align="left">
                                    {locale.t(locale.keys.endpointScan.label.indexDescription)}
                                </Typography>
                            </div>
                        )}
                        <CardContent>
                            <ul className={props.classes.ul}>
                                <li>
                                    <p className={props.classes.title}>{locale.t(locale.keys.endpointScan.label.saveDefault)}</p>
                                    <p className={props.classes.contentText}>{scanContainer.dateConvert(data.createdAt)}</p>
                                </li>
                                <li>
                                    <p className={props.classes.title}>{locale.t(locale.keys.endpointScan.label.content)}</p>
                                    <p className={props.classes.contentText2}>{scanContainer.dataDefaultSetting(data, type)}</p>
                                </li>
                            </ul>
                        </CardContent>
                        <CardActions className={props.classes.action}>
                            <Grid container direction="row" justify={i !== '0' ? 'space-between' : 'center'} alignItems="center">
                                <Button className={props.classes.updateButton} size="small" color="primary" onClick={() => scanContainer.handleClickGoToDefaultSetting(data.id)}>
                                    <Edit className={props.classes.iconButton} />
                                    {locale.t(locale.keys.endpointScan.button.update)}
                                </Button>
                                {i !== '0' && (
                                    <Confirm name={locale.t(locale.keys.endpointScan.label.scanSettingName, { name: data.mfpInfo.deviceName })} value={data.id} callBack={scanContainer.confirmRemove}>
                                        <Button className={props.classes.deleteButton} size="small" color="primary">
                                            <Delete className={props.classes.iconButton} />
                                            {locale.t(locale.keys.endpointScan.button.delete)}
                                        </Button>
                                    </Confirm>
                                )}
                            </Grid>
                        </CardActions>
                    </Card>
                ) : (
                    <Card key={i} data-testid="cards" className={props.classes.card}>
                        <Grid container className={props.classes.titleDiv}>
                            {i !== '0' ? (
                                <Grid container item xs={10}>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom variant="h5" component="h4" className={props.classes.contentTextHeader}>
                                            {locale.t(locale.keys.endpointScan.label.scanSettingName, { name: data.mfpInfo.deviceName })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ul className={props.classes.ulTitle}>
                                            <li>
                                                <p className={props.classes.title}>{locale.t(locale.keys.endpointScan.label.userAgent)}</p>
                                                <p className={props.classes.contentTextHeader}>{data.mfpInfo.type}</p>
                                            </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container item xs={10}>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom variant="h5" component="h4">
                                            {locale.t(locale.keys.endpointScan.label.initScanSetting)}
                                        </Typography>
                                        <Typography gutterBottom variant="caption" align="left">
                                            {locale.t(locale.keys.endpointScan.label.indexDescription)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={2}>
                                <IconButton
                                    className={clsx(props.classes.expand, {
                                        [props.classes.expandOpen]: checkExistData(i),
                                    })}
                                    onClick={() => {
                                        handleExpandClick(i);
                                    }}
                                    aria-expanded={checkExistData(i)}
                                    aria-label="Show more"
                                >
                                    <ExpandMore />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Collapse in={checkExistData(i)} timeout="auto" unmountOnExit>
                            <CardContent>
                                <ul className={props.classes.ul}>
                                    <li>
                                        <p className={props.classes.title}>{locale.t(locale.keys.endpointScan.label.saveDefault)}</p>
                                        <p className={props.classes.contentText}>{scanContainer.dateConvert(data.createdAt)}</p>
                                    </li>
                                    <li>
                                        <p className={props.classes.title}>{locale.t(locale.keys.endpointScan.label.content)}</p>
                                        <p className={props.classes.contentText2}>{scanContainer.dataDefaultSetting(data, type)}</p>
                                    </li>
                                </ul>
                            </CardContent>
                            <CardActions className={props.classes.action}>
                                <Grid container direction="row" justify={i !== '0' ? 'space-between' : 'center'} alignItems="center">
                                    <Button className={props.classes.updateButton} size="small" color="primary" onClick={() => scanContainer.handleClickGoToDefaultSetting(data.id)}>
                                        <Edit className={props.classes.iconButton} />
                                        {locale.t(locale.keys.endpointScan.button.update)}
                                    </Button>
                                    {i !== '0' && (
                                        <Confirm
                                            name={locale.t(locale.keys.endpointScan.label.scanSettingName, { name: data.mfpInfo.deviceName })}
                                            value={data.id}
                                            callBack={scanContainer.confirmRemove}
                                        >
                                            <Button className={props.classes.deleteButton} data-testid="deleteButton" size="small" color="primary">
                                                <Delete className={props.classes.iconButton} />
                                                {locale.t(locale.keys.endpointScan.button.delete)}
                                            </Button>
                                        </Confirm>
                                    )}
                                </Grid>
                            </CardActions>
                        </Collapse>
                    </Card>
                ),
            );
        }
    }
    return (
        <>
            {isLoading(scanContainer.ui) && <div data-testid={UI.state.Loading} />}
            {isError(scanContainer.ui) && <div data-testid={UI.state.Error} />}
            {isLoaded(scanContainer.ui) && <div className={props.classes.content}>{list}</div>}
        </>
    );
};

export default withStyles(styles, { withTheme: true })(withRouter(Component));
