import locale from '@/common/utils/locale';

export const FOLDER_SIZE = 20;

export const WORDBOOK_SIZE = 45;

export const dateFormatLabel = [
    locale.t(locale.keys.fileName.date.index),
    locale.t(locale.keys.fileName.date1.index),
    locale.t(locale.keys.fileName.date2.index),
    locale.t(locale.keys.fileName.date3.index),
    locale.t(locale.keys.fileName.date4.index),
    locale.t(locale.keys.fileName.date5.index),
    locale.t(locale.keys.fileName.date6.index),
    locale.t(locale.keys.fileName.date7.index),
];

export enum DateFormatValue {
    Date = 'date',
    Date1 = 'y|M|d|Hms',
    Date2 = 'y|M|d',
    Date3 = 'y|M|Hms',
    Date4 = 'y|d|Hms',
    Date5 = 'y|M',
    Date6 = 'y|d',
    Date7 = 'y|Hms',
    Date8 = 'y',
    Date9 = 'M|d|Hms',
    Date10 = 'M|d',
    Date11 = 'M|Hms',
    Date12 = 'M',
    Date13 = 'd|Hms',
    Date14 = 'd',
    Date15 = 'Hms',
}

export const separate = {
    DOT: '.',
    DASH: '-',
    UNDERSCORE: '_',
};

export const dateSeparate = {
    // 本来はNONEは空文字が正しい値だが、MUIのSelectコンポーネントの仕様で空文字にすると選択されたMenuItemコンポーネントが表示されないため、"none"で埋める
    NONE: 'none',
    DOT: '.',
    HYPHEN: '-',
    UNDERSCORE: '_',
    C: 'c',
};

export const DEFAULT_DATE_SEPARATOR = dateSeparate.C;

export enum DateSeparateFormatValue {
    Date1 = 'YYYY年MM月DD日HH時mm分ss秒',
    Date2 = 'YYYY年MM月DD日',
    Date3 = 'YYYY年MM月HH時mm分ss秒',
    Date4 = 'YYYY年DD日HH時mm分ss秒',
    Date5 = 'YYYY年MM月',
    Date6 = 'YYYY年DD日',
    Date7 = 'YYYY年HH時mm分ss秒',
    Date8 = 'YYYY年',
    Date9 = 'MM月DD日HH時mm分ss秒',
    Date10 = 'MM月DD日',
    Date11 = 'MM月HH時mm分ss秒',
    Date12 = 'MM月',
    Date13 = 'DD日HH時mm分ss秒',
    Date14 = 'DD日',
    Date15 = 'HH時mm分ss秒',
}

export const sequenceType = {
    COUNT: 1,
    PUT: 0,
};

export const nameTag = {
    DATE: 'date',
    TIME: 'time',
    USER: 'user',
    FILE: 'file',
    FOLDER: 'folder',
    FIXED: 'fixed',
};

export const listBrightBtnColor = ['Yellow', 'White', 'Cyan', 'LightSalmon', 'LightGreen'];

export const forbiddenWords = [
    'con.',
    'prn.',
    'aux.',
    'nul.',
    'com0.',
    'com1.',
    'com2.',
    'com3.',
    'com4.',
    'com5.',
    'com6.',
    'com7.',
    'com8.',
    'com9.',
    'lpt0.',
    'lpt1.',
    'lpt2.',
    'lpt3.',
    'lpt4.',
    'lpt5.',
    'lpt6.',
    'lpt7.',
    'lpt8.',
    'lpt9.',
    '.',
    ' ',
    '　',
];
