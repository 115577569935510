import { default as React } from 'react';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { FileNameContainer } from './FileNameContainer';
import FileName from './FileName';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: -theme.spacing.unit,
            [theme.breakpoints.down('md')]: {
                marginLeft: theme.spacing.unit * 2,
                marginRight: theme.spacing.unit * 2,
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    onTitle?: () => void;
}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.fileName.index));
    if (typeof props.onTitle === 'function') {
        props.onTitle();
    }

    return (
        <div className={props.classes.root}>
            <FileNameContainer.Provider>
                <FileName />
            </FileNameContainer.Provider>
        </div>
    );
};

export default withStyles(styles)(Component);
