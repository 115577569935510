import * as validator from '@/common/utils/validator';
import locale from '@/common/utils/locale';

export interface Form {
    emails: string[];
    // limit: number;
    Rows: Row[];
    validateInit: {
        emails: boolean;
    };
    errors: validator.ValidationOutput;
}

export const New = (): Form => ({
    emails: [],
    // limit: 7,
    Rows: [],
    validateInit: {
        emails: false,
    },
    // Initialize error holder.
    errors: NewValidation(),
});

export interface Row {
    id: number;
    userId: string;
    email: string;
    invitationUser: string;
    invitationDate: string;
    limit: string;
    resend: string;
}

export const validations: validator.Constraints = {
    emails: {
        presence: {
            allowEmpty: false,
            // 定数化
            message: locale.t(locale.keys.validation.required),
        },
    },
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        emails: null,
    };
};

export const emailValidations: validator.Constraints = {
    email: {
        email: {
            message: locale.t(locale.keys.validation.email),
        },
        length: {
            maximum: 55,
            message: locale.t(locale.keys.validation.tooLong, { num: 55 }),
        },
    },
};
export const NewEmailValidation = (): validator.ValidationOutput => {
    return {
        email: null,
    };
};
