import { default as React, useEffect, useState } from 'react';
import { Theme, createStyles, WithStyles, withStyles, Fab, Card, CardActions, CardContent, Button, Typography, IconButton, Collapse } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Add, Delete } from '@material-ui/icons';
import moment from 'moment';
import clsx from 'clsx';

import { default as UI } from '@/common/constants/ui';
import useTitle from '@/common/components/hooks/useTitle';
import ModalOuter from '@/user/components/common/ModalOuter';
import * as schema from '@/bundles/schema/typescript/schema';

import { useModal } from './useUI';
import * as models from '@/user/models/cloud-connection/cloud-connection';
import AuthorizationPopup from './AuthorizationPopup';
import locale from '@/common/utils/locale';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { Variants } from '@/common/components/messages/CommonMessage';
import dummyCloud, { Cloud } from './dummyCloud';
import { identifyUser } from '@/user/models/auth';
import Confirm from '@/common/components/Confirm/Confirm';
import { default as xstorages } from '@/common/img/x-storages/index';
import { UserAppContainer } from '../UserAppContainer';
import { isLoaded, isLoading, isError, isSaving } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';
import * as webappUtil from '@/common/utils/webappUtil';
import { DateFormat } from '@/common/constants/dateFormat';
import { PAGINATION_LIMIT_CLOUD } from '@/common/constants/pagination';
import shareFormat from '@/common/constants/shareFormat';
import ui from '@/common/constants/ui';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexWrap: 'wrap',
            paddingTop: theme.spacing.unit * 6,
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                paddingTop: '12px',
                paddingBottom: theme.spacing.unit * 6,
                overflow: 'auto',
            },
        },
        card: {
            marginRight: theme.spacing.unit * 3,
            marginBottom: theme.spacing.unit * 3,
            width: `calc((100% - ${theme.spacing.unit * 9 + 1}px) / 3)`,
            [theme.breakpoints.down('sm')]: {
                marginRight: 0,
                width: '87vw',
                alignSelf: 'center',
            },
        },
        spCard: {
            display: 'flex',
            height: '50px',
        },
        media: {
            height: 140,
        },
        icon: {
            height: '32px',
            width: '32px',
            marginRight: theme.spacing.unit * 3,
            float: 'left',
        },
        storageName: {
            paddingTop: theme.spacing.unit * 1,
            float: 'left',
        },
        heading: {
            fontSize: '16px',
            fontWeight: 'bold',
            [theme.breakpoints.up('md')]: {
                height: '50px',
                paddingBottom: 0,
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: '15px',
                paddingBottom: '19px',
                paddingLeft: '16px',
            },
            textAlign: 'left',
            display: 'flex',
        },
        ul: {
            padding: 0,
            listStyle: 'none',
            marginBottom: theme.spacing.unit * 2,
            textAlign: 'left',
            '&& li': {
                marginBottom: theme.spacing.unit * 2,
            },
            '&& li:last-of-type': {
                marginBottom: 0,
            },
        },
        title: {
            fontSize: '0.875rem',
            marginBottom: theme.spacing.unit,
            color: '#666666',
        },
        contentText: {
            fontSize: '1.125rem',
            margin: 0,
            lineHeight: '1.67',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minHeight: '30px',
        },
        buttonCase: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: `0 ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
            fontSize: '0.8125rem',
            height: '58px',
        },
        buttonFrame: {
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: 4,
            margin: 0,
            padding: '6px 8px 4px 8px',
            width: '90px',
            height: '36px',
        },
        button: {
            borderRadius: 50,
            position: 'absolute',
            top: -28,
            right: theme.spacing.unit * 2, // 16px
            '&& span': {
                fontSize: '1rem',
            },
            [theme.breakpoints.down(ui.breakPoints_1380)]: {
                display: 'none',
            },
            height: '44px',
            minWidth: '283px',
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing.unit * 3,
            right: theme.spacing.unit * 3,
            [theme.breakpoints.up(ui.breakPoints_1380)]: {
                display: 'none',
            },
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandPC: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            textAlign: 'right',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        divIcon: {
            marginTop: theme.spacing.unit * 1.5,
            marginLeft: theme.spacing.unit * 1.5,
        },
        cardcontent: {
            paddingBottom: 0,
        },
    });

interface Props extends WithStyles<typeof styles> {
    theme: Theme;
    skipEffect?: boolean;
    onTitle?: () => void;
    onDesc: (desc?: string) => void;
}

export const Component: React.SFC<Props> = (props) => {
    const [ui, setUI] = useState(UI.state.Loading);
    const matchesLg = useMediaQuery(props.theme.breakpoints.up('md'));
    const defaultVal: string[] = [];
    const [expanded, setExpanded] = React.useState(defaultVal);
    const [pagination, setPagination] = React.useState<schema.V1ObjectsPagination>();
    const [isItemFull, setIsItemFull] = useState(false);
    const appContainer = UserAppContainer.useContainer();
    // i18n対象
    useTitle(locale.t(locale.keys.modalTitle.cloudStorage.index));

    if (typeof props.onTitle === 'function') {
        props.onTitle();
    }
    if (typeof props.onDesc === 'function') {
        props.onDesc(locale.t(locale.keys.cloudConnection.cloudConnectionDesc));
    }

    useEffect(() => {
        appContainer.updateLoadingState(ui);
        if (isError(ui)) {
            appContainer.setOnBackError(true);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.error),
                variant: Variants.error,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);
    useEffect(() => {
        return () => {
            appContainer.setOnBackError(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle window popup
    useEffect(() => {
        if (window.location.search.indexOf('?') !== 0) {
            return;
        }
        if (window.opener === null) {
            window.opener = {};
        }
        window.opener.authorize = '';
        window.opener.authorize = window.location.search;
        window.close();
    });

    // listの中にはendpointsListから生成したdomを入れる
    const list = [];

    // user_sub
    const sub = identifyUser()!.sub;
    // location
    const lang = window.localStorage.getItem('lang');
    let dateFormat = DateFormat.fullDateWithSlash;
    if (lang === 'ja') {
        dateFormat = DateFormat.fullJpDate;
    }
    const modalUI = useModal();

    // get list cloud connection from API
    const [cloudList, changeCloudList] = React.useState();
    // effect using for get list data
    // empty array to avoid call after update
    useEffect(() => {
        if (props.skipEffect) {
            changeCloudList(dummyCloud);
            setUI(UI.state.Loaded);
            return;
        }
        if (props.skipEffect) {
            changeCloudList(dummyCloud);
            return;
        }
        if (cloudList) {
            return;
        }
        (async () => {
            try {
                const cloudList = await models.getCloudsByUser(sub, appContainer.values.authorizationCode, 0, true, 0, PAGINATION_LIMIT_CLOUD);
                if (cloudList.xstorages) {
                    changeCloudList(cloudList.xstorages);
                    setPagination(cloudList.pagination);
                    setUI(UI.state.Loaded);
                }
                if ((cloudList.pagination !== undefined && cloudList.pagination.offset === cloudList.pagination.totalCount) || cloudList.xstorages.length === 0) {
                    setIsItemFull(true);
                }
            } catch (e) {
                setUI(UI.state.Error);
                appContainer.setLoadingState(false);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const confirmRemove = (isYes: boolean, value?: string) => {
        if (!isYes) {
            return;
        }
        if (typeof value === 'string') {
            deleteList(value);
        }
    };

    // 削除ボタン押下時の挙動
    const deleteList = (id: string) => {
        (async () => {
            try {
                const currentId = id;
                const response = await models.destroyUserCloud(currentId, appContainer.values.authorizationCode, shareFormat.personal);
                if (!response.xstorageId) {
                    appContainer.updateMessage({
                        autoHideDuration: 3000,
                        isOpen: true,
                        message: `${locale.t(locale.keys.error.default)}`,
                        variant: Variants.error,
                    });
                    return;
                }
                // stateListを直接編集してsetState(changeList)走らせても反応しないので
                // object.assingでオブジェクトをコピー
                const cloudArray: Cloud[] = Object.values(cloudList);
                const filtered = cloudArray.filter((cloud) => {
                    return cloud.xstorageId !== currentId;
                });
                const copyObj = Object.assign({}, filtered);
                changeCloudList(copyObj);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.deleted),
                    variant: Variants.success,
                });
                if (pagination && pagination.offset !== undefined) {
                    setPagination({
                        ...pagination,
                        offset: pagination.offset - 1,
                    });
                }
            } catch (e) {
                setUI(UI.state.Error);
                appContainer.setLoadingState(false);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    const updateStateObj = (storageId: string, dueDate: string, status: string, authorizationId: string) => {
        const copyObj = Object.assign({}, cloudList);
        for (const i in copyObj) {
            if (copyObj[i].xstorageId === storageId) {
                copyObj[i].authorizationDeadline = dueDate;
                copyObj[i].status = status;
                copyObj[i].authorizationId = authorizationId;
            }
        }
        changeCloudList(copyObj);
        appContainer.updateMessage({
            autoHideDuration: 3000,
            isOpen: true,
            message: locale.t(locale.keys.action.updated),
            variant: Variants.success,
        });
    };

    const addList = (object: schema.V1XStoragesUpdateResponse) => {
        const objArray = Object.values(cloudList);
        objArray.unshift({
            authorizationDeadline: object.authorizationDeadline,
            authorizationId: object.authorizationId,
            status: object.status,
            authorizationLink: object.authorizationLink,
            xstorageId: object.xstorageId,
            service: object.service,
            imageLink: object.imageLink,
        });
        const newList = Object.assign({}, objArray);
        changeCloudList(newList);
        appContainer.updateMessage({
            autoHideDuration: 3000,
            isOpen: true,
            message: locale.t(locale.keys.action.created),
            variant: Variants.success,
        });
    };
    const dateConvert = (date: string, isAuthor: string) => {
        if (!isAuthor || isAuthor === schema.V1ObjectsXStorageStatus.NotAuthored) {
            return locale.t(locale.keys.cloudConnection.unAuthor);
        }
        if (schema.V1ObjectsXStorageStatus.Expiried === isAuthor) {
            return locale.t(locale.keys.cloudConnection.expireDay);
        }
        if (moment(date).isValid()) {
            return moment(date).format(dateFormat);
        }
        return locale.t(locale.keys.cloudConnection.dueDate);
    };

    const handleExpandClick = (i: string) => {
        let count = 0;
        let existFlag = false;
        const coppyArr = [...expanded];
        for (const j in coppyArr) {
            if (coppyArr[j] === i) {
                coppyArr.splice(count, 1);
                existFlag = true;
            }
            count += 1;
        }
        if (!existFlag) {
            setExpanded([...expanded, i]);
        } else {
            setExpanded([...coppyArr]);
        }
    };
    const checkExistData = (i: string) => {
        for (const j in expanded) {
            if (expanded[j] === i) {
                return true;
            }
        }
        return false;
    };

    // get data with scrolling
    const fetchMoreListItems = () => {
        if (isItemFull) {
            return;
        }
        if (!cloudList || cloudList.length === 0 || appContainer.loadingState) {
            setIsFetching(false);
            return;
        }

        setTimeout(async () => {
            try {
                appContainer.setLoadingState(true);
                let offset = cloudList.length;
                if (pagination !== undefined) {
                    offset = Number(pagination.offset);
                }
                const data = await models.getCloudsByUser(sub, appContainer.values.authorizationCode, 0, true, offset, PAGINATION_LIMIT_CLOUD);
                if (!data || data.xstorages.length === 0) {
                    setIsFetching(false);
                    setIsItemFull(true);
                    appContainer.setLoadingState(false);
                    return;
                }
                if (data.pagination !== undefined && data.pagination.totalCount === data.pagination.offset) {
                    setIsItemFull(true);
                }
                setPagination(data.pagination);
                const objArray: schema.V1ObjectsXStorage[] = Object.values(cloudList);
                for (const i in data.xstorages) {
                    const xstorage = data.xstorages[i];
                    const isDuplicate = objArray.find((item) => item.xstorageId === xstorage.xstorageId);
                    if (isDuplicate) {
                        continue;
                    }
                    objArray.push(xstorage);
                }
                const newList = Object.assign({}, objArray);
                changeCloudList(newList);
                appContainer.setLoadingState(false);
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
                appContainer.setLoadingState(false);
            }
            setIsFetching(false);
        }, 2000);
    };
    const { isFetching, setIsFetching } = appContainer.useInfiniteScroll(isItemFull, fetchMoreListItems);

    const getCloudImage = (type: string) => {
        if (type === schema.V1ObjectsServiceEnum.Dropbox) return <img src={xstorages.xstorages.dropbox} className={props.classes.icon} alt="img" />;
        if (type === schema.V1ObjectsServiceEnum.Googledrive) return <img src={xstorages.xstorages.googledrive} className={props.classes.icon} alt="img" />;
        if (type === schema.V1ObjectsServiceEnum.Googleteamdrive) return <img src={xstorages.xstorages.teamdrive} className={props.classes.icon} alt="img" />;
        if (type === schema.V1ObjectsServiceEnum.Box) return <img src={xstorages.xstorages.box} className={props.classes.icon} alt="img" />;
        if (type === schema.V1ObjectsServiceEnum.Onedrive) return <img src={xstorages.xstorages.onedrive} className={props.classes.icon} alt="img" />;
        if (type === schema.V1ObjectsServiceEnum.Sharepointonline) return <img src={xstorages.xstorages.sharepointonline} className={props.classes.icon} alt="img" />;
        if (type === schema.V1ObjectsServiceEnum.Email) return <></>;
        if (type === schema.V1ObjectsServiceEnum.Nonecloud) return <></>;
        if (type === schema.V1ObjectsServiceEnum.Docard) return <img src={xstorages.xstorages.docard} className={props.classes.icon} alt="img" />;
        if (type === schema.V1ObjectsServiceEnum.Docab) return <img src={xstorages.xstorages.docab} className={props.classes.icon} alt="img" />;
    };
    // listにdomを入れる
    for (const i in cloudList) {
        if ([schema.V1ObjectsServiceEnum.Nonecloud, schema.V1ObjectsServiceEnum.Email].includes(cloudList[i].service)) {
            continue;
        }
        list.push(
            matchesLg ? (
                <div key={i} className={props.classes.card}>
                    <Card data-testid="cards">
                        <CardContent className={props.classes.cardcontent}>
                            <Typography gutterBottom variant="h5" component="h4" className={props.classes.heading}>
                                <div>{getCloudImage(cloudList[i].service)}</div>
                                <div className={props.classes.storageName}>{webappUtil.getServiceText(cloudList[i].service)}</div>
                            </Typography>
                        </CardContent>
                        <CardContent>
                            <ul className={props.classes.ul}>
                                <li>
                                    <p className={props.classes.title}>{locale.t(locale.keys.cloudConnection.label.authorizationId)}</p>
                                    <p className={props.classes.contentText}>
                                        {cloudList[i].service !== schema.V1ObjectsServiceEnum.Nonecloud && cloudList[i].service !== schema.V1ObjectsServiceEnum.Email && cloudList[i].authorizationId}
                                    </p>
                                </li>
                                <li>
                                    <p className={props.classes.title}>{locale.t(locale.keys.cloudConnection.label.approvalPeriod)}</p>
                                    <p className={props.classes.contentText}>{dateConvert(cloudList[i].authorizationDeadline, cloudList[i].status)}</p>
                                </li>
                            </ul>
                        </CardContent>
                        <CardActions className={props.classes.buttonCase}>
                            {!cloudList[i].is2L && (
                                <>
                                    <AuthorizationPopup
                                        disable={cloudList[i].service === schema.V1ObjectsServiceEnum.Nonecloud || cloudList[i].service === schema.V1ObjectsServiceEnum.Email}
                                        classes={props.classes.buttonFrame}
                                        window={window}
                                        callBack={updateStateObj}
                                        id={cloudList[i].xstorageId}
                                        url={cloudList[i].authorizationLink}
                                    />
                                    <Confirm name={`${webappUtil.getServiceText(cloudList[i].service)} ${cloudList[i].authorizationId}`} value={cloudList[i].xstorageId} callBack={confirmRemove}>
                                        <Button size="small" color="primary">
                                            <Delete />
                                            {locale.t(locale.keys.cloudConnection.button.delete)}
                                        </Button>
                                    </Confirm>
                                </>
                            )}
                        </CardActions>
                    </Card>
                </div>
            ) : (
                <Card className={props.classes.card} key={i} data-testid="cards">
                    <div className={props.classes.divIcon}>{getCloudImage(cloudList[i].service)}</div>
                    <CardActions className={props.classes.spCard}>
                        <Typography gutterBottom variant="h5" component="h4" className={props.classes.heading}>
                            {webappUtil.getServiceText(cloudList[i].service)}
                        </Typography>
                        <IconButton
                            className={clsx(props.classes.expand, {
                                [props.classes.expandOpen]: checkExistData(i),
                            })}
                            onClick={() => {
                                handleExpandClick(i);
                            }}
                            aria-expanded={checkExistData(i)}
                            aria-label="Show more"
                        >
                            <ExpandMore />
                        </IconButton>
                    </CardActions>
                    <Collapse in={checkExistData(i)} timeout="auto" unmountOnExit>
                        <CardContent>
                            <ul className={props.classes.ul}>
                                <li>
                                    <p className={props.classes.title}>{locale.t(locale.keys.cloudConnection.label.authorizationId)}</p>
                                    <p className={props.classes.contentText}>
                                        {cloudList[i].service !== schema.V1ObjectsServiceEnum.Nonecloud && cloudList[i].service !== schema.V1ObjectsServiceEnum.Email && cloudList[i].authorizationId}
                                    </p>
                                </li>
                                <li>
                                    <p className={props.classes.title}>{locale.t(locale.keys.cloudConnection.label.approvalPeriod)}</p>
                                    <p className={props.classes.contentText}>{dateConvert(cloudList[i].authorizationDeadline, cloudList[i].status)}</p>
                                </li>
                            </ul>
                        </CardContent>
                        {!cloudList[i].is2L && (
                            <CardActions className={props.classes.buttonCase}>
                                <AuthorizationPopup
                                    disable={cloudList[i].service === schema.V1ObjectsServiceEnum.Nonecloud || cloudList[i].service === schema.V1ObjectsServiceEnum.Email}
                                    classes={props.classes.buttonFrame}
                                    window={window}
                                    callBack={updateStateObj}
                                    id={cloudList[i].xstorageId}
                                    url={cloudList[i].authorizationLink}
                                />
                                <Confirm name={`${webappUtil.getServiceText(cloudList[i].service)} ${cloudList[i].authorizationId}`} value={cloudList[i].xstorageId} callBack={confirmRemove}>
                                    <Button size="small" color="primary">
                                        <Delete />
                                        {locale.t(locale.keys.cloudConnection.button.delete)}
                                    </Button>
                                </Confirm>
                            </CardActions>
                        )}
                    </Collapse>
                </Card>
            ),
        );
    }

    return (
        <div>
            {isLoading(ui) && <div data-testid={UI.state.Loading} />}

            {isSaving(ui) && <div data-testid={UI.state.Saving} />}

            {isLoaded(ui) && (
                <div>
                    <div className={props.classes.content} data-testid={UI.state.Loaded}>
                        {list}
                        <Button data-testid="modalOpenPC" variant="contained" onClick={modalUI.open} className={props.classes.button}>
                            <Add />
                            {locale.t(locale.keys.cloudConnection.button.addNew)}
                        </Button>
                        <Fab data-testid="modalOpenSP" className={props.classes.fab} aria-label="Add" onClick={modalUI.open}>
                            <Add />
                        </Fab>
                        <ModalOuter
                            title={locale.t(locale.keys.cloudConnection.modal.title)}
                            window={window}
                            modalOpen={modalUI.isOpen}
                            modalCloseFunc={modalUI.close}
                            admitCloud={addList}
                            data-testid="modal"
                        />
                    </div>
                    {isFetching && !isItemFull && locale.t(locale.keys.spool.loading.index)}
                </div>
            )}

            {isError(ui) && <div data-testid={UI.state.Error} />}
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
