import { default as box } from './box.png';
import { default as dropbox } from './dropbox.png';
import { default as googledrive } from './googledrive.png';
import { default as teamdrive } from './teamdrive.png';
import { default as onedrive } from './onedrive.png';
import { default as sharepointonline } from './sharepointonline.png';
import { default as docard } from './docard.png';
import { default as docab } from './docab.png';

interface Img {
    [key: string]: string;
}

const xstorages: Img = {
    box,
    dropbox,
    googledrive,
    teamdrive,
    onedrive,
    sharepointonline,
    docard,
    docab,
};
export default {
    xstorages,
};
