import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import querystring from 'querystring';
import * as locale from '@/common/utils/locale/locale';
import userRole from '@/common/constants/userRole';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const updateEndpointsById = async (id: string, endpoints: schema.V1EndpointsDetailsUpdateRequest, auth: string): Promise<schema.V1EndpointsDetailsUpdateResponse> => {
    const responce = await axiosFactory.put<schema.V1EndpointsDetailsUpdateResponse>(`https://${consts.environment.api.hostname}/v1/endpoints/${id}/details`, endpoints, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return responce.data;
};

export const deleteEndpoint = async (id: string, auth: string): Promise<schema.V1EndpointsDestroyResponse> => {
    const response = await axiosFactory.delete<schema.V1EndpointsDestroyResponse>(`https://${consts.environment.api.hostname}/v1/endpoints/${id}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

/**
 * endpoint一覧取得
 */
export const getEndpoints = async (uploadable: boolean, downloadable: boolean, auth: string, share: number, offset?: number, limit?: number): Promise<schema.V1EndpointsIndexResponse> => {
    const query = querystring.stringify({
        share,
        offset,
        limit,
    });

    const response = await axiosFactory.get<schema.V1EndpointsIndexResponse>(`https://${consts.environment.api.hostname}/v1/endpoints?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

/**
 * エンドポイント詳細取得
 */
export const showEndpointById = async (id: string, auth: string): Promise<schema.V1EndpointsUpdateResponse> => {
    const response = await axiosFactory.get<schema.V1EndpointsUpdateResponse>(`https://${consts.environment.api.hostname}/v1/endpoints/${id}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

/**
 * endpoint新規作成リクエスト
 */
export const createEndpoint = async (req: schema.V1EndpointsCreateRequest, auth: string): Promise<schema.V1EndpointsCreateResponse> => {
    const response = await axiosFactory.post<schema.V1EndpointsCreateResponse>(`https://${consts.environment.api.hostname}/v1/endpoints`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const getAllDefaultSetting = async (id: string, auth: string): Promise<schema.V1EndpointsUpdateResponse> => {
    const response = await axiosFactory.get<schema.V1EndpointsUpdateResponse>(`https://${consts.environment.api.hostname}/v1/endpoints/${id}/default-settings`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

/**
 * 通常業務/転送先のコピー不可判定
 * @param endpoint
 * @returns true: コピー不可, false: コピー可
 */
export const disableCopy = (endpoint: schema.V1ObjectsEndpointInfo) => {
    if (endpoint.isStorageAvailable) return false;
    return true;
};

/**
 * 通常業務(共有)のコピー不可判定
 * @param endpoint
 * @param user
 * @returns true: コピー不可, false: コピー可
 */
export const disableShareCopy = (endpoint: schema.V1ObjectsEndpointInfo, user: schema.V1ObjectsWorkspaceuserLarge) => {
    if (endpoint.isStorageAvailable && user.role !== userRole.member) return false;
    return true;
};

/**
 * 通常業務のコピー処理
 * @param endpoint
 * @param user
 * @returns コピーする通常業務のパラメータ
 */
export const copyEndpointObject = (endpoint: schema.V1ObjectsEndpointInfo, user: schema.V1ObjectsWorkspaceuserLarge): schema.V1EndpointsCreateCopyRequest => {
    // 名前処理（「コピー」付与）
    let newName: string = endpoint.name;
    if (user.language === schema.Language.Ja) {
        newName = newName + locale.t(locale.keys.endpoint.copyName);
    } else {
        newName = locale.t(locale.keys.endpoint.copyName) + newName;
    }

    // バリデーションおよびコピーするパラメータの設定
    let option: schema.V1EndpointsCreateCopyRequest | null = null;
    if (endpoint.share === undefined || endpoint.share === null) {
        throw new Error();
    }
    if (endpoint.folderPath === undefined || endpoint.folderPath === null) {
        throw new Error();
    }
    if (endpoint.serviceCompleted === undefined || endpoint.serviceCompleted === null) {
        throw new Error();
    }
    if (endpoint.serviceError === undefined || endpoint.serviceError === null) {
        throw new Error();
    }
    option = {
        share: endpoint.share,
        buttonColor: endpoint.buttonColor,
        downloadable: endpoint.downloadable,
        uploadable: endpoint.uploadable,
        iconName: endpoint.iconName,
        name: newName,
        path: endpoint.folderPath,
        pathId: endpoint.folderPathId,
        serviceCompleted: endpoint.serviceCompleted,
        serviceError: endpoint.serviceError,
        mailSetting: endpoint.mailSetting,
    };
    return option;
};

/**
 * エンドポイントのコピー
 * @param id コピーするエンドポイントのid
 * @param endpoints コピーするエンドポイントの設定
 * @param auth
 */
export const copyEndpoint = async (id: string, endpoints: schema.V1EndpointsCreateCopyRequest, auth: string): Promise<schema.V1EndpointsCreateResponse> => {
    const responce = await axiosFactory.put<schema.V1EndpointsCreateResponse>(`https://${consts.environment.api.hostname}/v1/endpoints/${id}/copy`, endpoints, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return responce.data;
};

/**
 * エンドポイントのクラウドストレージ変更
 * @param id
 * @param request
 * @param auth
 * @returns
 */
export const changeCloudSetting = async (id: string, request: schema.V1EndpointsCloudSettingUpdateRequest, auth: string): Promise<boolean> => {
    const responce = await axiosFactory.put<boolean>(`https://${consts.environment.api.hostname}/v1/endpoints/${id}/details/cloud-setting`, request, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return responce.data;
};
