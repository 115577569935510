import { default as React, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import logger from '@/common/utils/logger';
import * as workspaceuser from '@/user/models/workspaceuser';
import routes from '@/user/constants/routes';
import { VerifyContainer } from './VerifyContainer';
import signup from './signup';
import Error from '@/common/components/state/Error';
import Loading from '@/common/components/state/Loading';
import * as errorHandler from '@/common/utils/errorHandler';
import * as locale from '@/common/utils/locale/locale';
import { UserAppContainer } from '@/user/components/UserAppContainer';

const styles = (theme: Theme) =>
    createStyles({
        link: {
            color: '#0d47a1',
        },

        button: {
            '&& span': {
                fontSize: '1.25rem',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('lg')]: {
                width: 'auto',
            },
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    ui?: UI;
    skipEffect?: boolean;
}

enum State {
    Verify = 'verify',
    VerifyError = 'verifyError',
    MemberSignup = 'memberSignup',
}

export const Component: React.FC<Props> = (props) => {
    const vc = VerifyContainer.useContainer();
    const appContainer = UserAppContainer.useContainer();
    const ui = useUI(props.ui);
    const [state, setState] = useState(State.Verify);

    useEffect(() => {
        if (props.skipEffect || ui.current !== UI.Loading) {
            return;
        }
        logger.debug(`Verify useEffect Loading`);

        // メンバー招待
        (async () => {
            try {
                const req = workspaceuser.parseWorkspaceuserInviteVerifyQueryString(window.location.search);
                const result = await workspaceuser.workspaceuserInviteVerify(req);
                logger.debug('workspaceuserInviteVerify result', result);

                if (!result.success) {
                    setState(State.VerifyError);
                    ui.update(UI.Error);
                    return;
                }

                if (result.user && result.user.invitationVerified) {
                    props.history.push(routes.auth.login.index);
                    return;
                }

                vc.values.invitedUser = result.invitedUser;
                vc.values.user = result.user;
                vc.values.workspace = result.workspace;
                vc.values.token = req.token;
                vc.setValues(vc.values);

                ui.update(UI.Loaded);
                setState(State.MemberSignup);
            } catch (e) {
                ui.update(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    }, [props.skipEffect, props.location, props.history, ui, vc, appContainer]);

    return (
        <>
            {ui.current === UI.Loading && (
                <div data-testid={UI.Loading}>
                    <Loading />
                </div>
            )}

            {ui.current === UI.Loaded && (
                <div data-testid={UI.Loaded}>
                    {state === State.MemberSignup && (
                        <>
                            <signup.List />
                        </>
                    )}
                </div>
            )}

            {ui.current === UI.Error && (
                <>
                    {state === State.VerifyError ? (
                        <h2>{locale.t(locale.keys.error.signupVerifyError)}</h2>
                    ) : (
                        <div data-testid={UI.Error}>
                            <Error />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default withStyles(styles)(withRouter(Component));
