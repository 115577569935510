import React, { FC, useState } from 'react';
import {
    Checkbox,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
    Typography,
    WithStyles,
    createStyles,
    withStyles,
} from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import { styles as baseTableStyles } from '@/common/components/Table/style';
import * as schema from '@/bundles/schema/typescript/schema';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { Search, UnfoldMore, ArrowDropDown } from '@material-ui/icons';
import {
    getUserPermission,
    selectPermittedUser,
    selectPermittedUserAll,
    UserPermissionRow,
    isSelectedAllUser,
    selectedUserCount,
    GroupPermissionRow,
    roleToString,
    selectPermittedUserByRole,
} from '@/common/models/endpoints/useEndpoints';
import { CovasAvatar } from '@/common/components/CovasAvatar';
import userRole from '@/common/constants/userRole';

const styles = () =>
    createStyles({
        ...baseTableStyles(),
        root: {
            width: '100%',
            gap: '16px',
        },
        searchRoot: {
            width: '100%',
            display: 'flex',
            overflowX: 'auto',
        },
        searchIconButton: {
            marginBlock: 'auto',
            marginInline: '10px',
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: '8px',
        },
        table: {
            width: '100%',
            padding: 0,
        },
    });

interface Props extends WithStyles<typeof styles> {
    groupPermission: GroupPermissionRow[];
    selectedGroupPermission: string[];
    userPermission: UserPermissionRow[];
    selectedUserPermission: string[];
    handleUserPermission: (userPermission: string[]) => void;
}

const UserPermissionTable: FC<Props> = (props: Props) => {
    const defaultRows = props.userPermission;
    const [rows, setRows] = useState<UserPermissionRow[]>(getUserPermission(defaultRows, '', 5, 0, schema.V1ObjectsSort.None, true));
    const [sortByName, setSortByName] = useState<schema.V1ObjectsSort>(schema.V1ObjectsSort.None);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [roleMenuAnchor, setRoleMenuAnchor] = useState<null | HTMLElement>(null);
    const isRoleMenuOepn = Boolean(roleMenuAnchor);
    const [filteredCount, setFilteredCount] = useState(getUserPermission(defaultRows, '', 0, 0, schema.V1ObjectsSort.None, false).length);

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, updatedPage: number) => {
        setPage(updatedPage);
        setRows(getUserPermission(defaultRows, searchText, rowsPerPage, rowsPerPage * updatedPage, sortByName, true));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const updatedRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(updatedRowsPerPage);
        setRows(getUserPermission(defaultRows, searchText, updatedRowsPerPage, updatedRowsPerPage * page, sortByName, true));
    };

    const handleChangeSearchText = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchText(event.target.value);
        setRows(getUserPermission(defaultRows, event.target.value, rowsPerPage, 0, sortByName, true));
        setFilteredCount(getUserPermission(defaultRows, event.target.value, 0, 0, sortByName, false).length);
    };

    const handleChangeSortByName = () => {
        let updateSort = schema.V1ObjectsSort.None;
        switch (sortByName) {
            case schema.V1ObjectsSort.None:
                updateSort = schema.V1ObjectsSort.Asc;
                break;
            case schema.V1ObjectsSort.Asc:
                updateSort = schema.V1ObjectsSort.Desc;
                break;
            case schema.V1ObjectsSort.Desc:
                updateSort = schema.V1ObjectsSort.None;
                break;
        }
        setSortByName(updateSort);
        setRows(getUserPermission(defaultRows, searchText, rowsPerPage, rowsPerPage * page, updateSort, true));
    };

    const handleSelectByRole = (role: number) => {
        const targetRows = getUserPermission(defaultRows, searchText, 0, 0, sortByName, false);
        // 選択したロールに該当するユーザーを選択済みにする
        const filteredUserByRole = selectPermittedUserByRole(targetRows, role);
        // 現状管理者権限は強制選択なので管理者ユーザーを抽出する
        const filteredUserByAdmin = selectPermittedUserByRole(targetRows, userRole.admin);
        // 選択したロールに加え、管理者ユーザーを選択済みにする
        const filteredUserByRoleWithAdmin = filteredUserByRole.concat(filteredUserByAdmin);
        const uniqueSelected = filteredUserByRoleWithAdmin.filter((value, index, self) => self.indexOf(value) === index);
        props.handleUserPermission(uniqueSelected);
    };

    const handleRoleMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (isRoleMenuOepn) {
            setRoleMenuAnchor(null);
        } else {
            setRoleMenuAnchor(e.currentTarget);
        }
    };

    const getEmptyRowCount = () => {
        return rowsPerPage - Math.min(rowsPerPage, rows.length);
    };

    const countSelectedUser = () => {
        // 選択済みのユーザーのうち、アクティブなユーザーの数をカウント
        // 停止中のユーザーがアクティブになった場合は、以前の許可状態を保持する仕様である。
        // アクティブの時に許可したユーザーが停止中になったとしても、許可状態は保持される。
        const uniqueCount = selectedUserCount(props.selectedUserPermission, props.selectedGroupPermission, props.groupPermission);
        return uniqueCount.filter((id) => props.userPermission.map((user) => user.id).includes(id)).length.toString();
    };

    const countSelected = () => {
        // 選択済みのユーザーのうち、アクティブなユーザーの数をカウント
        // 停止中のユーザーがアクティブになった場合は、以前の許可状態を保持する仕様である。
        // アクティブの時に許可したユーザーが停止中になったとしても、許可状態は保持される。
        return props.selectedUserPermission.filter((id) => props.userPermission.map((user) => user.id).includes(id)).length.toString();
    };

    return (
        <Grid container direction="column">
            <Grid item>
                <Typography>{locale.t(locale.keys.endpointsPermission.userGroupPermission.description)}</Typography>
            </Grid>
            <Grid item>
                <TextField
                    placeholder={locale.t(locale.keys.endpointsPermission.userGroupPermission.userTable.searchUser)}
                    variant="outlined"
                    margin={'dense'}
                    fullWidth
                    style={{ marginBlock: '4px' }}
                    InputLabelProps={{ style: { fontSize: 12, transform: 'translate(14px, 10px) scale(1)' } }}
                    InputProps={{ style: { fontSize: 14, height: 36 } }}
                    value={searchText}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChangeSearchText(e)}
                />
            </Grid>
            <Grid item>
                <Typography>
                    {locale.t(locale.keys.endpointsPermission.userGroupPermission.selectedNumber, {
                        n: countSelected(),
                        i: countSelectedUser(),
                    })}
                </Typography>
            </Grid>
            <Grid item>
                <Table className={props.classes.table}>
                    <TableHead>
                        <TableRow className={props.classes.headerFont}>
                            <TableCell padding="none" className={props.classes.tableCell} style={{ width: 40 }}>
                                <Checkbox
                                    color="secondary"
                                    checked={rows.length !== 0 && isSelectedAllUser(props.selectedUserPermission, getUserPermission(defaultRows, searchText, 0, 0, sortByName, false))}
                                    onChange={() => {
                                        const targetRows = getUserPermission(defaultRows, searchText, 0, 0, sortByName, false);
                                        const updateSelected = selectPermittedUserAll(targetRows, props.selectedUserPermission, !isSelectedAllUser(props.selectedUserPermission, targetRows));
                                        props.handleUserPermission(updateSelected);
                                    }}
                                />
                            </TableCell>
                            <TableCell className={props.classes.tableCell} style={{ width: 60 }} />
                            <TableCell padding="none" className={props.classes.tableCell}>
                                <Grid className={props.classes.gridInsideHeaderCell}>
                                    <p className={props.classes.gridInsideHeaderCellText}>{locale.t(locale.keys.endpointsPermission.userGroupPermission.userTable.cell.name)}</p>
                                    <Search style={{ marginBlock: 'auto' }} />
                                    <IconButton onClick={() => handleChangeSortByName()}>
                                        <UnfoldMore />
                                    </IconButton>
                                </Grid>
                            </TableCell>
                            <TableCell padding="none" className={props.classes.tableCell}>
                                <Tooltip title={locale.t(locale.keys.endpointsPermission.userGroupPermission.userTable.cell.role)} placement="bottom-end" data-testid="role-menu-tooltip">
                                    <TableSortLabel active IconComponent={ArrowDropDown} onClick={handleRoleMenu}>
                                        {locale.t(locale.keys.endpointsPermission.userGroupPermission.userTable.cell.role)}
                                    </TableSortLabel>
                                </Tooltip>
                                <Menu open={isRoleMenuOepn} anchorEl={roleMenuAnchor} onClose={handleRoleMenu}>
                                    {/**keyには存在しないroleを渡す。（keyが重複するとレンダリングにバグが発生する。） */}
                                    <MenuItem
                                        key={10000}
                                        onClick={(e) => {
                                            const targetRows = getUserPermission(defaultRows, searchText, 0, 0, sortByName, false);
                                            const updateSelected = selectPermittedUserAll(targetRows, props.selectedUserPermission, true);
                                            props.handleUserPermission(updateSelected);
                                            handleRoleMenu(e);
                                        }}
                                    >
                                        {locale.t(locale.keys.endpointsPermission.userGroupPermission.userTable.popupMenu.role.all)}
                                    </MenuItem>
                                    {[userRole.admin, userRole.member].map((status) => {
                                        return (
                                            <MenuItem
                                                key={status}
                                                value={status}
                                                onClick={(e) => {
                                                    handleSelectByRole(status);
                                                    handleRoleMenu(e);
                                                }}
                                            >
                                                {roleToString(status)}
                                            </MenuItem>
                                        );
                                    })}
                                </Menu>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                            return (
                                <TableRow key={row.id} tabIndex={-1} selected={false} data-testid="row" className={index % 2 ? props.classes.evenRow : props.classes.oddRow}>
                                    <TableCell padding="none" className={props.classes.tableCell}>
                                        <Checkbox
                                            color="secondary"
                                            // 1.11.0の仕様で管理者権限ユーザーは利用制限の対象外とするために、管理者権限ユーザーを選択済みにする
                                            // TODO:将来的に管理者権限ユーザーを利用制限可能となった場合には、下記の処理を削除する
                                            disabled={row.role === userRole.admin}
                                            checked={props.selectedUserPermission.includes(row.id)}
                                            onChange={() => {
                                                props.handleUserPermission(selectPermittedUser(props.selectedUserPermission, row.id));
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell className={props.classes.tableCell} style={{ width: 60 }}>
                                        <CovasAvatar alt="Workspace" size={40} seed={row.invitationEmail} src={row.avatarUrl} />
                                    </TableCell>
                                    <TableCell padding="none" className={props.classes.tableCell}>
                                        <Grid container direction="column">
                                            <Grid item style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                                {row.name}
                                            </Grid>
                                            <Grid item style={{ color: 'gray' }}>
                                                {row.invitationEmail}
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell padding="none" className={props.classes.tableCell}>
                                        {roleToString(row.role)}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {getEmptyRowCount() > 0 && (
                            <TableRow style={{ height: 49 * getEmptyRowCount() }}>
                                <TableCell colSpan={100} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredCount}
                    labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                    labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                        <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
                    )}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': locale.t(locale.keys.table.previousPage),
                    }}
                    nextIconButtonProps={{
                        'aria-label': locale.t(locale.keys.table.nextPage),
                    }}
                    onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => handleChangePage(event, p)}
                    onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChangeRowsPerPage(event)}
                    data-testid="pagenation-root"
                />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(UserPermissionTable);
