import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import querystring from 'querystring';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const getSpoolList = async (auth: string, queryType: string, offset?: string, limit?: string): Promise<schema.V1SpoolersIndexResponse> => {
    const query = querystring.stringify({
        queryType,
        offset,
        limit,
        source: schema.V1ObjectsSpoolersSourceEnum.Browser,
    });

    const response = await axiosFactory.get<schema.V1SpoolersIndexResponse>(`https://${consts.environment.api.hostname}/v1/spoolers?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const destroySpoolObject = async (auth: string, id: string): Promise<schema.V1SpoolersDestroyResponse> => {
    const response = await axiosFactory.delete<schema.V1SpoolersDestroyResponse>(`https://${consts.environment.api.hostname}/v1/spoolers/${id}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const retrySpoolObject = async (auth: string, id: string): Promise<schema.V1SpoolersDestroyResponse> => {
    const response = await axiosFactory.post<schema.V1SpoolersDestroyResponse>(
        `https://${consts.environment.api.hostname}/v1/spoolers/${id}`,
        {},
        {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        },
    );
    return response.data;
};

export const downloadSpoolObjectFailed = async (auth: string, id: string): Promise<schema.V1SpoolersDownloadShowResponse> => {
    const response = await axiosFactory.get<schema.V1SpoolersDownloadShowResponse>(`https://${consts.environment.api.hostname}/v1/spoolers/${id}/download`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
