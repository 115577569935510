import { default as React, useState, useEffect } from 'react';
import { WithStyles, Theme, createStyles, withStyles, Grid, Paper, Typography, Button } from '@material-ui/core';
import locale from '@/common/utils/locale';
import { default as UI } from '@/common/constants/ui';
import { isLoaded, isLoading, isError } from '@/common/components/hooks/useUI';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as errorHandler from '@/common/utils/errorHandler';
import * as schema from '@/bundles/schema/typescript/schema';
import { getWordbookList } from '@/user/models/wordbooks/wordbooks';
import LoadingState from '@/common/components/ui-state/LoadingState';
import { getEnumData } from '@/common/utils/webappUtil';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing.unit * 14,
            paddingTop: theme.spacing.unit * 4,
            paddingRight: theme.spacing.unit * 10,
            display: 'flex',
            flexWrap: 'wrap',
            position: 'relative',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                paddingTop: theme.spacing.unit * 3,
                paddingRight: theme.spacing.unit * 2,
                paddingLeft: theme.spacing.unit * 2,
            },
        },
        paper: {
            width: `calc((100% - ${theme.spacing.unit * 12 + 1}px) / 3)`,
            marginRight: theme.spacing.unit * 4,
            marginBottom: theme.spacing.unit * 3.5,
            textAlign: 'left',
            color: theme.palette.text.secondary,
            padding: theme.spacing.unit * 2,
            [theme.breakpoints.down('md')]: {
                marginRight: 0,
                width: '100%',
            },
        },
        subject: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#333333',
        },
        body: {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.71,
            color: '#333333',
            height: '80px',
            overflowY: 'auto',
        },
    });

interface Props extends WithStyles<typeof styles> {
    closeModal: () => void;
    wordbooks: (works: string) => void;
}

export const Component: React.SFC<Props> = (props: Props) => {
    const { classes } = props;
    const list = [];
    const [wordbookList, setWordbookList] = useState<schema.V1ObjectsWordbook[]>([]);
    const appContainer = UserAppContainer.useContainer();
    const [ui, setUI] = useState(UI.state.Loaded);

    useEffect(() => {
        appContainer.updateLoadingState(ui);
        if (isError(ui)) {
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.error),
                variant: Variants.error,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        void (async () => {
            try {
                setUI(UI.state.Loading);
                let brand = schema.Brand.System;
                if (appContainer.values.signinWorkspaceObject.brand) {
                    brand = getEnumData(appContainer.values.signinWorkspaceObject.brand, schema.Brand);
                }
                const dataRes = await getWordbookList(appContainer.values.authorizationCode, brand);
                if (!dataRes || !dataRes.templates) {
                    setUI(UI.state.Error);
                    appContainer.updateMessage({
                        autoHideDuration: 3000,
                        isOpen: true,
                        message: locale.t(locale.keys.error.notFound),
                        variant: Variants.error,
                    });
                    return;
                }
                setWordbookList(dataRes.templates);
                setUI(UI.state.Loaded);
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const replaceOnclick = (i: string) => {
        const index = Number(i);
        if (typeof props.wordbooks === 'function') {
            const wordbook = wordbookList[index].wordList.join(',');
            props.wordbooks(wordbook);
        }
        props.closeModal();
    };

    for (const i in wordbookList) {
        const wordbook = wordbookList[i];
        const content = wordbook.wordList.join(', ');
        const no = Number(i) + 1;
        list.push(
            <Paper key={i} className={classes.paper}>
                <Grid container direction="column" justify="space-between" alignItems="flex-start" spacing={16}>
                    <Grid item xs>
                        <Typography className={classes.subject}>
                            {no < 10 ? `0${no} ` : `${no} `}
                            {wordbook.name}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <div className={classes.body}>{content}</div>
                    </Grid>
                    <Grid item xs>
                        <Button onClick={() => replaceOnclick(i)}>{locale.t(locale.keys.fileName.wordbook.button)}</Button>
                    </Grid>
                </Grid>
            </Paper>,
        );
    }

    return (
        <>
            {isLoading(ui) && <div data-testid={UI.state.Loading} /> && <LoadingState />}
            {isError(ui) && <div data-testid={UI.state.Error} />}
            {isLoaded(ui) && <Grid className={classes.root}>{list}</Grid>}
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
