// @ts-nocheck
import { default as React, useEffect, useState } from 'react';
import { WithStyles, Theme, createStyles, withStyles, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Radio } from '@material-ui/core';

import { KeyboardArrowRight, Folder, LastPage } from '@material-ui/icons';
import locale from '@/common/utils/locale';
import * as errLocale from '@/common/utils/locale/error-locale';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as xStorages from '@/user/models/x-storages/files/files';
import { default as UI } from '@/common/constants/ui';
import * as schema from '@/bundles/schema/typescript/schema';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import { isLoaded, isLoading, isError } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';
import LoadingState from '@/common/components/ui-state/LoadingState';
import * as webappUtil from '@/common/utils/webappUtil';
import { join } from 'path';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        header: {
            width: '100%',
        },
        pathContent: {
            marginTop: theme.spacing.unit * 1,
            marginBottom: theme.spacing.unit * 1,
            // height: theme.spacing.unit * 10,
            display: 'flex',
            overflowX: 'auto',
        },
        path: {
            display: 'flex',
            cursor: 'pointer',
            '&& span': {
                marginRight: theme.spacing.unit,
                color: 'orange',
            },
            '&& svg': {
                marginRight: theme.spacing.unit,
            },
        },
        body: {
            paddingTop: 0,
            width: '100%',
            maxHeight: theme.spacing.unit * 25,
            overflow: 'auto',
        },
        content: {
            paddingLeft: 0,
            '&& div': {
                marginRight: 0,
            },
            width: '100%',
            listStyleType: 'circle',
        },
        folderIcon: {
            width: theme.spacing.unit * 2.625,
            height: theme.spacing.unit * 3.125,
            marginRight: theme.spacing.unit,
            '&& span': {
                fontSize: '0.9375rem',
                color: '#0d47a1',
            },
        },
        radioChecked: {
            '&& span': {
                padding: 0,
                marginRight: theme.spacing.unit,
            },
        },
        cloudName: {
            fontSize: '1rem',
            color: '#333333',
            fontWeight: 'bold',
            marginTop: theme.spacing.unit * 3,
            '&& p': {
                fontWeight: 'normal',
                fontSize: '14px',
                color: '#666666',
                marginTop: theme.spacing.unit * 0.15,
            },
        },
        siteName: {
            fontSize: '1rem',
            color: '#333333',
            fontWeight: 'bold',
            marginTop: theme.spacing.unit * 3,
            '&& p': {
                fontWeight: 'normal',
                fontSize: '14px',
                color: theme.palette.error.light,
            },
            '&& span': {
                fontWeight: 'normal',
                fontSize: '14px',
                color: '#666666',
            },
        },
        nextPage: {
            marginLeft: 'auto',
            marginTop: theme.spacing.unit * 2,
        },
        load: {
            width: '100%',
            textAlign: 'center',
        },
        imgStyle: {
            width: '30px',
            height: '30px',
            border: 'solid 1px black',
            borderRadius: '50%',
        },
        imgStyleSelected: {
            width: '30px',
            height: '30px',
            backgroundColor: 'orange',
            border: 'solid 1px black',
            borderRadius: '50%',
        },
        folderStyle: {
            float: 'left',
        },
        pathFolder: {
            overflow: 'hidden',
            whiteSpace: 'pre' /**空白は詰めない */,
            textOverflow: 'ellipsis',
            maxWidth: 70,
        },
    });

interface Props extends WithStyles<typeof styles> {
    id: string;
    endpointType: schema.V1ObjectsServiceEnum;
    cloudAcc?: string;
    path?: string;
    pathSelect: (pth: string, pthId?: string) => void;
    pathId?: string;
}

export const selectQuery = (service: schema.V1ObjectsServiceEnum, pathName?: string, pathId?: string) => {
    let queryPathName = '/';

    if (pathName) {
        queryPathName = pathName;
    }
    switch (service) {
        case schema.V1ObjectsServiceEnum.Googleteamdrive:
        case schema.V1ObjectsServiceEnum.Googledrive:
            // Googleドライブの時は必ずpathIdが入る
            // 「/」を含むフォルダ名を受け取った時、階層構造と判定してしまうから。
            return { queryPathName: queryPathName, queryPathId: pathId! };

        default:
            return { queryPathName: queryPathName, queryPathId: '' };
    }
};

type CloudPathArray = {
    id: string[] | null;
    path: string[];
};

export const Component: React.SFC<Props> = (props: Props) => {
    const { classes } = props;
    const list = [];
    const listFol = [];
    const [folderList, setFolderList] = useState<schema.V1ObjectsXstorageFile[]>();
    const [cursorState, setCursorState] = useState();
    const [ui, setUI] = useState(UI.state.Loading);
    const [isSelected, setIsSelected] = useState('-1');
    const [cloudPathArr, setCloudPathArr] = useState<CloudPathArray>({
        id: null,
        path: ['/'],
    });
    const appContainer = UserAppContainer.useContainer();

    useEffect(() => {
        appContainer.updateLoadingState(ui);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        const { queryPathName, queryPathId } = selectQuery(props.endpointType, props.path, props.pathId);

        void (async () => {
            // GoogleDriveの場合のみ第２引数（pathId）を使用する
            await getFolderList(queryPathName, queryPathId);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * ルート「/」リクエスト以外で「/」が返ってきた場合はtrue
     * @param prePath リクエストパス
     * @param responsePath レスポンスパス
     * @returns
     */
    const checkNotFoundPath = (prePath: string, responsePath: string): boolean => {
        switch (props.endpointType) {
            case schema.V1ObjectsServiceEnum.Googledrive:
                if (!(prePath === '/' || prePath === 'root') && responsePath === '/') {
                    return true;
                }
                break;
            default:
                if (prePath !== '/' && responsePath === '/') {
                    return true;
                }
                break;
        }
        return false;
    };
    const getFolderList = async (path: string, pathId?: string, cursor?: string) => {
        setUI(UI.state.Loading);
        try {
            let pathCall = path;
            if (props.endpointType !== schema.V1ObjectsServiceEnum.Dropbox && props.endpointType !== schema.V1ObjectsServiceEnum.Docab && pathId) {
                pathCall = pathId;
            }
            const dataRes = await xStorages.getListFolder(props.id, pathCall, appContainer.values.authorizationCode, cursor);
            // 通常業務インポートで存在しないディレクトリが指定されている場合、指定したパスと違うパスが返却される
            if (checkNotFoundPath(pathCall, dataRes.path)) {
                path = dataRes.path;
            }
            switch (props.endpointType) {
                case schema.V1ObjectsServiceEnum.Googleteamdrive:
                case schema.V1ObjectsServiceEnum.Googledrive:
                    if (!dataRes.pathArray) throw new Error('Internal Server Error');
                    // Googleドライブの場合、フォルダ構造を配列で扱うためにidとpathの配列でデータが返される
                    // Googleドライブのフォルダ名ではフォルダの区切り文字であるスラッシュやエスケープ文字であるバックスラッシュを含むことができるので、フォルダパスのテキストからフォルダ構造は判断できない。この対策のために、Googleドライブ系だけ処理を分けている。
                    // https://sios-mfp.backlog.jp/view/COVAS-1679
                    setCloudPathArr({
                        id: ['/'].concat(dataRes.pathArray.map((v) => v.id)),
                        path: ['/'].concat(dataRes.pathArray.map((v) => v.name)),
                    });
                    break;
                default:
                    setCloudPathArr({
                        id: null,
                        path: ['/'].concat(dataRes.path.split('/').filter((path) => path)),
                    });
            }
            if (typeof props.pathSelect === 'function') {
                if (props.endpointType === schema.V1ObjectsServiceEnum.Googleteamdrive && path === '/') props.pathSelect('', pathId);
                else if (props.endpointType === schema.V1ObjectsServiceEnum.Googledrive && path === '/') props.pathSelect(path, 'root');
                else if (props.endpointType === schema.V1ObjectsServiceEnum.Docab && path === '/') props.pathSelect('', pathId);
                else props.pathSelect(path, pathId);
            }
            setFolderList(dataRes.xStorageFiles);
            setCursorState(dataRes.cursor);
            if (checkNotFoundPath(pathCall, dataRes.path)) {
                for (const i in folderList) {
                    listFol.push(
                        <ListItem key={i} button className={classes.content} onKeyDown={(event) => handleSelectPressKey(event, i)}>
                            <ListItemIcon className={classes.radioChecked} tabIndex={-1}>
                                <Radio checked={isSelected === i} name={i} tabIndex={-1} value={folderList[i].path} onChange={() => handleSelectClick(folderList[i].path, folderList[i].id, i)} />
                            </ListItemIcon>
                            <ListItemIcon>
                                <Folder className={props.classes.folderStyle} onClick={() => selectOnClick(folderList[i].path, folderList[i].id)} />
                            </ListItemIcon>
                            <ListItemText primary={folderList[i].name} onClick={() => selectOnClick(folderList[i].path, folderList[i].id)} />
                        </ListItem>,
                    );
                }
                setUI(UI.state.Error);

                appContainer.updateMessage({
                    isOpen: true,
                    message: errLocale.translate(errLocale.keys.E08180).message,
                    variant: Variants.error,
                });
            } else {
                setUI(UI.state.Loaded);
            }
        } catch (e) {
            setUI(UI.state.Error);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const converPathString = (dataList: string[]): string => {
        let path: string = '';
        for (const i in dataList) {
            path = join('/', path, dataList[i]);
        }
        return join(path, '/');
    };

    const selectOnClick = async (path: string, pthId: string) => {
        const addPath = path.split('/');
        addPath[0] = '/';
        if (addPath[addPath.length - 1] && addPath[addPath.length - 1] === '') {
            addPath.pop();
        }
        await getFolderList(path, pthId);
        const divTarget: HTMLDivElement = document.getElementById(`pathId`) as HTMLDivElement;
        if (divTarget) {
            divTarget.scrollTo(divTarget.scrollWidth, 0);
        }
        setIsSelected('-1');
    };

    const handleSelectPressKey = (event: any, index: string) => {
        if (event.keyCode === 13 || event.keyCode === 32) {
            handleSelectClick(folderList![Number(index)].path, folderList![Number(index)].id, index);
            return;
        }
    };

    // 階層ごとのフォルダのリンクをクリックしたときの関数
    const linkOnClick = async (index: string) => {
        if (index === '' || isLoading(ui)) {
            return;
        }
        switch (props.endpointType) {
            case schema.V1ObjectsServiceEnum.Nonecloud:
                return;
            case schema.V1ObjectsServiceEnum.Googleteamdrive:
            case schema.V1ObjectsServiceEnum.Googledrive: {
                const i = Number(index) + 1;

                const pathArr = [...cloudPathArr.path];
                pathArr.splice(i, cloudPathArr.path.length - i);

                const pathIdArr = [...cloudPathArr.id!];
                pathIdArr.splice(i, cloudPathArr.id!.length - i);

                await getFolderList(converPathString(pathArr), cloudPathArr.id![i - 1]);
                return;
            }
            default: {
                const i = Number(index) + 1;
                const pathArr = [...cloudPathArr.path];
                pathArr.splice(i, pathArr.length - i);
                await getFolderList(converPathString(pathArr), undefined);
                return;
            }
        }
    };

    const nextOnClick = async () => {
        await getFolderList(converPathString(cloudPathArr.path), undefined, cursorState);
    };

    for (const i in folderList) {
        listFol.push(
            <ListItem key={i} button className={classes.content} onKeyDown={(event) => handleSelectPressKey(event, i)}>
                <ListItemIcon className={classes.radioChecked} tabIndex={-1}>
                    <Radio checked={isSelected === i} name={i} tabIndex={-1} value={folderList[i].path} onChange={() => handleSelectClick(folderList[i].path, folderList[i].id, i)} />
                </ListItemIcon>
                <ListItemIcon>
                    <Folder className={props.classes.folderStyle} onClick={() => selectOnClick(folderList[i].path, folderList[i].id)} />
                </ListItemIcon>
                <ListItemText primary={folderList[i].name} onClick={() => selectOnClick(folderList[i].path, folderList[i].id)} />
            </ListItem>,
        );
    }

    const handleSelectPress = (event: any, index: string) => {
        if (event.keyCode === 13 || event.keyCode === 32) {
            linkOnClick(index);
            setIsSelected('-1');
            return;
        }
    };

    for (const i in cloudPathArr.path) {
        if (!cloudPathArr.path[i]) {
            continue;
        }

        list.push(
            <Link className={classes.path} key={i} onClick={() => linkOnClick(i)}>
                {i === '0' ? (
                    <div tabIndex={0} onKeyDown={(event: any) => handleSelectPress(event, i)}>
                        {cloudPathArr.path[i]}
                    </div>
                ) : (
                    <>
                        <KeyboardArrowRight />
                        <span className={classes.pathFolder} tabIndex={0} onKeyDown={(event: any) => handleSelectPress(event, i)}>
                            {cloudPathArr.path[i]}
                        </span>
                    </>
                )}
            </Link>,
        );
    }

    const handleSelectClick = (path: string, pthId: string, i: string) => {
        switch (props.endpointType) {
            case schema.V1ObjectsServiceEnum.Googleteamdrive:
            case schema.V1ObjectsServiceEnum.Googledrive:
                let selectedPath = '';
                folderList!.forEach((folder) => {
                    if (folder.id === pthId) {
                        selectedPath = folder.name;
                    }
                });
                if (isSelected !== '-1') {
                    cloudPathArr.id!.pop();
                    cloudPathArr.path.pop();
                    setCloudPathArr({
                        id: [...cloudPathArr.id!, pthId],
                        path: [...cloudPathArr.path, selectedPath],
                    });
                } else {
                    setCloudPathArr({
                        id: [...cloudPathArr.id!, pthId],
                        path: [...cloudPathArr.path, selectedPath],
                    });
                }
                break;
            default:
                const addPath = path.split('/');
                addPath[0] = '/';
                if (addPath[addPath.length - 1] && addPath[addPath.length - 1] === '') {
                    addPath.pop();
                }
                if (isSelected === i) {
                    setIsSelected('-1');
                    addPath.pop();
                }
                setCloudPathArr({
                    id: null,
                    path: addPath,
                });
        }

        if (typeof props.pathSelect === 'function') {
            if (props.endpointType === schema.V1ObjectsServiceEnum.Googleteamdrive && path === '/') props.pathSelect('', pthId);
            else if (props.endpointType === schema.V1ObjectsServiceEnum.Docab && path === '/') props.pathSelect('', pthId);
            else props.pathSelect(path, pthId);
        }
        setIsSelected(i);
    };

    return (
        <div className={classes.root}>
            <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                <div className={classes.header}>
                    <div className={classes.cloudName}>
                        <p>{locale.t(locale.keys.endpoint.cloudConnection)}</p>
                        {webappUtil.getServiceText(props.endpointType)}
                        <p>{props.endpointType !== schema.V1ObjectsServiceEnum.Nonecloud && props.cloudAcc}</p>
                    </div>
                    {props.endpointType === schema.V1ObjectsServiceEnum.Sharepointonline && (
                        <>
                            <div className={classes.siteName}>
                                <span>{locale.t(locale.keys.createEnpoint.site)}</span>
                                {cloudPathArr.path && cloudPathArr.path.length >= 1 ? cloudPathArr.path[1] : ''}
                                <p>{locale.t(locale.keys.createEnpoint.m365SiteRequired)}</p>
                            </div>

                            <div className={classes.siteName}>
                                <span>{locale.t(locale.keys.createEnpoint.library)}</span>
                                {cloudPathArr.path && cloudPathArr.path.length >= 2 ? cloudPathArr.path[2] : ''}
                                <p>{locale.t(locale.keys.createEnpoint.m365LibRequired)}</p>
                            </div>
                        </>
                    )}
                    <div id="pathId" className={classes.pathContent}>
                        {list}
                    </div>
                </div>
                {isLoading(ui) && <div className={classes.load} data-testid={UI.state.Loading} /> && <LoadingState isModal={true} />}

                {isLoaded(ui) && (
                    <div className={classes.load} data-testid={UI.state.Loaded}>
                        <List className={classes.body} tabIndex={-1}>
                            {listFol.length === 0 ? (
                                <Grid container justify="center" alignItems="flex-start">
                                    {locale.t(locale.keys.endpoint.empty)}
                                </Grid>
                            ) : (
                                <></>
                            )}
                            {listFol}
                        </List>
                        {cursorState === '' || cursorState === undefined ? (
                            <></>
                        ) : (
                            <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                                <LastPage className={classes.nextPage} onClick={nextOnClick} />
                            </Grid>
                        )}
                    </div>
                )}
                {isError(ui) && (
                    // エラー時もリスト更新を行う。
                    <div className={classes.load} data-testid={UI.state.Error}>
                        <List className={classes.body} tabIndex={-1}>
                            {listFol.length === 0 ? (
                                <Grid container justify="center" alignItems="flex-start">
                                    {locale.t(locale.keys.endpoint.empty)}
                                </Grid>
                            ) : (
                                <></>
                            )}
                            {listFol}
                        </List>
                        {cursorState === '' || cursorState === undefined ? (
                            <></>
                        ) : (
                            <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                                <LastPage className={classes.nextPage} onClick={nextOnClick} />
                            </Grid>
                        )}
                    </div>
                )}
            </Grid>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
