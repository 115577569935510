import * as React from 'react';

import { Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';

import { context } from '@/user/components/mfp/Context';
import * as locale from '@/common/utils/locale/locale';

const Consumer = context.Consumer;

const Component: React.FC = () => (
    <Consumer>
        {(app) =>
            app && (
                <Grid container justify="flex-end">
                    <Grid item>
                        <Fab
                            data-testid="modalOpenPC"
                            variant="extended"
                            aria-label="Add"
                            size="small"
                            onClick={() => {
                                app.openMFPListModal();
                                app.getMfps();
                                app.isOpen = true;
                            }}
                        >
                            <Add />
                            {locale.t(locale.keys.mfp.addShareDevice)}
                        </Fab>
                    </Grid>
                </Grid>
            )
        }
    </Consumer>
);

export default Component;
