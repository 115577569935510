import { default as React, useEffect, useState } from 'react';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles, Button, Grid, Typography } from '@material-ui/core';
import * as schema from '@/bundles/schema/typescript/schema';
import StepMail from '@/user/components/common/endpoints/step-mail/StepMail';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: theme.spacing.unit * 40,
            marginTop: theme.spacing.unit * 5,
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('md')]: {
                margin: theme.spacing.unit * 2,
                width: 'auto',
                minWidth: theme.spacing.unit * 36,
            },
        },
        email: {
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#333333',
        },
        button: {
            minWidth: 196,
            maxWidth: 246,
            height: 44,
            margin: `0 auto`,
        },
    });

interface Props extends WithStyles<typeof styles> {
    ui?: UI;
    skipEffect?: boolean;
    closeModal: () => void;
    mailList: (setting: schema.V1ObjectsEndpointsMailSetting) => void;
    mailSetting: schema.V1ObjectsEndpointsMailSetting;
}

export const Component: React.SFC<Props> = (props: Props) => {
    const ui = useUI(props.ui);
    const [mailSetting, setMailSetting] = useState<schema.V1ObjectsEndpointsMailSetting>(props.mailSetting);
    const [buttonState, setButtonState] = useState<boolean>(false);

    useEffect(() => {
        if (props.skipEffect) {
            return;
        }
        ui.update(UI.Loaded);
    }, [props.skipEffect, ui]);

    const saveOnClick = () => {
        if (typeof props.mailList === 'function') {
            props.mailList(mailSetting);
            props.closeModal();
        }
    };

    const mailList = (setting: schema.V1ObjectsEndpointsMailSetting) => {
        let state = true;
        if (mailSetting.address.length === 0) {
            state = false;
        }
        setButtonState(state);
        setMailSetting(setting);
    };

    return (
        <>
            {ui.current === UI.Loading && <div data-testid={UI.Loading}>Loading</div>}

            {ui.current === UI.Loaded && (
                <div data-testid={UI.Loaded}>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid className={props.classes.root}>
                            <p>{locale.t(locale.keys.endpoint.cloudConnection)}</p>
                            <Typography className={props.classes.email}>{locale.t(locale.keys.cloudConnection.service.email)}</Typography>
                            <StepMail mailSetting={props.mailSetting} mailList={mailList} />
                            <Button className={props.classes.button} variant="contained" onClick={saveOnClick} disabled={!buttonState} fullWidth>
                                {locale.t(locale.keys.endpoint.button.save)}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            )}

            {ui.current === UI.Error && <div data-testid={UI.Error}>Error</div>}
        </>
    );
};

export default withStyles(styles)(Component);
