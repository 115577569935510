export interface Cloud {
    xstorageId: string;
    authorizationId: string;
    authorizationDeadline: string;
    authorizationLink: string;
    imageLink: string;
    status: string;
    share: number;
}

// apiから受け取る想定のデータのダミー
export const dummyCloud: Cloud[] = [
    {
        xstorageId: '3001',
        authorizationId: 'abc@gmailcom',
        authorizationDeadline: '2018-01-01',
        authorizationLink: '',
        imageLink: '',
        status: '',
        share: 0,
    },
    {
        xstorageId: '3002',
        authorizationId: 'def@gmailcom',
        authorizationDeadline: '2020-01-01',
        authorizationLink: '',
        imageLink: '',
        status: '',
        share: 0,
    },
    {
        xstorageId: '3003',
        authorizationId: 'sample@gmailcom',
        authorizationDeadline: '2019-01-01',
        authorizationLink: '',
        imageLink: '',
        status: '',
        share: 0,
    },
];

export default dummyCloud;
