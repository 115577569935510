import { useState } from 'react';
import { createContainer } from 'unstated-next';

export enum State {
    SkipSignin = 'skipSignin',
    SelectWorkSpace = 'selectWorkspace',
    Login = 'login',
}

interface Props {
    workspaceId: string;
    workspaceDisplayId: string;
    workspaceDisplayName: string;
    workspaceLogoUrl: string;
    userEmail: string;
}

const NewValues = (): Props => ({
    workspaceId: '',
    workspaceDisplayId: '',
    workspaceDisplayName: '',
    workspaceLogoUrl: '',
    userEmail: '',
});

const useSigninContainer = () => {
    const [state, setState] = useState(State.SelectWorkSpace);
    const [values, setValues] = useState(NewValues());

    return {
        state,
        setState,
        values,
        setValues,
    };
};
export const SigninContainer = createContainer(useSigninContainer);
