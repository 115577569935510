// @ts-nocheck
import * as React from 'react';
import { Route, Switch, RouteComponentProps, withRouter } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { Modal, Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';

import ModalHeader from '@/common/components/ModalHeader';
import { default as userRoutes } from '@/user/constants/routes';
import mfp from '@/user/components/mfp';
import cloudConnection from '@/user/components/cloud-connection';
import sharedCloudConnection from '@/user/components/shared-cloud-connection';
import * as schema from '@/bundles/schema/typescript/schema';
import passwordAuth from '@/user/components/auth/signin/password-auth';
import selectWorkspace from '@/user/components/auth/signin/select-workspace';

const pcModalHeight: number = 667;
const pcModalWidth: number = 400;

const styles = (theme: Theme) =>
    createStyles({
        commonModal: {
            backgroundColor: '#fff',
            flexGrow: 1,
            height: '100%',
            padding: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            outline: 'none',
            [theme.breakpoints.up('md')]: {
                borderRadius: 10,
                top: '45%',
                left: '50%',
                height: 'auto',
                maxHeight: pcModalHeight,
                width: pcModalWidth,
                transform: 'translate(-50%, -50%)',
            },
        },
        content: {
            padding: theme.spacing.unit * 3,
            wordBreak: 'break-all',
        },
    });

interface IWindow extends Window {
    authorize?: string;
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    modalOpen: boolean;
    modalCloseFunc: () => void;
    admit?: (propId: string, propName: string) => void;
    admitCloud?: (object: schema.V1XStoragesUpdateResponse) => void;
    title?: string;
    window?: IWindow;
    value?: string;
    headerStyle?: any;
    recoverWsID?: boolean;
}
/**
 * モーダルビューの追加
 * @param props
 */
export const Component: React.FC<Props> = (props: Props) => {
    return (
        <Modal data-testid="modal" open={props.modalOpen} onClose={props.modalCloseFunc}>
            <div className={props.classes.commonModal}>
                <ModalHeader title={props.title} modalCloseFunc={props.modalCloseFunc} headerStyle={props.headerStyle} />
                <div className={props.classes.content}>
                    <BrowserRouter>
                        <Switch>
                            {/** メンバーサイトの複合機画面で複合機の追加を押したときのダイアログ */}
                            <Route path={userRoutes.mfp.index} component={mfp.Modal.List} />
                            {/** メンバーサイトの共有クラウドストレージ画面でクラウドストレージ認可の追加を押したときのダイアログ */}
                            <Route path={userRoutes.sharedCloudConnection.index}>
                                <sharedCloudConnection.Modal.List onClose={props.modalCloseFunc} window={props.window} admit={props.admitCloud} />
                            </Route>
                            {/** メンバーサイトのクラウドストレージ画面でクラウドストレージ認可の追加を押したときのダイアログ */}
                            <Route path={userRoutes.cloudConnection.index}>
                                <cloudConnection.Modal.List onClose={props.modalCloseFunc} window={props.window} admit={props.admitCloud} />
                            </Route>
                            {props.recoverWsID ? (
                                <Route path={userRoutes.auth.login.index}>
                                    {/** メンバーサイトのログイン画面の「ワークスペースIDをお忘れの方はこちら」を押したときのダイアログ */}
                                    <selectWorkspace.Modal.List value={props.value} onClose={props.modalCloseFunc} admit={props.admit} />
                                </Route>
                            ) : (
                                <Route path={userRoutes.auth.login.index}>
                                    {/** メンバーサイトのログイン画面の「パスワードをお忘れの方はこちら」を押したときのダイアログ */}
                                    <passwordAuth.Modal.List value={props.value} onClose={props.modalCloseFunc} admit={props.admit} />
                                </Route>
                            )}
                        </Switch>
                    </BrowserRouter>
                </div>
            </div>
        </Modal>
    );
};

export default withStyles(styles, { withTheme: true })(withRouter(Component));
