import { default as React } from 'react';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { ScanContainer } from './ScanContainer';
import ScanComponent from './ScanComponent';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: -theme.spacing.unit * 4,
        },
    });

interface Props extends WithStyles<typeof styles> {
    onTitle?: () => void;
    onDesc: (desc: string) => void;
}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.pageTitle.endpoint.scan));
    if (typeof props.onTitle === 'function') {
        props.onTitle();
    }

    if (typeof props.onDesc === 'function') {
        props.onDesc(locale.t(locale.keys.pageTitle.endpoint.scanDesc));
    }

    return (
        <div className={props.classes.root}>
            <ScanContainer.Provider>
                <ScanComponent />
            </ScanContainer.Provider>
        </div>
    );
};

export default withStyles(styles)(Component);
