import { default as React, useContext } from 'react';
import { Theme, createStyles, withStyles, WithStyles, Typography, Divider, Grid } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import { __RouterContext } from 'react-router';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import environment from '@/common/constants/environment';

const selectColor = (tenant: string) => {
    switch (tenant) {
        case "ricoh":
            return '#E06666';
        default:
            return '#607d8b';
    }
}


const styles = (theme: Theme) =>
    createStyles({
        title: {
            height: '44px',
            fontSize: '20px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: '1.3',
            letterSpacing: 'normal',
            color: '#333333',
            textAlign: 'left',
        },
        subtitle: {
            height: '22px',
            fontSize: '15px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: '1.3',
            letterSpacing: 'normal',
            color: '#333333',
            textAlign: 'left',
        },
        desc: {
            width: '100%',
            textAlign: 'left',
            margin: '0 auto',
            fontSize: '15px',
            fontWeight: 'bold',
        },
        link: {
            width: '2.125rem',
            height: '2.125rem',
            float: 'left',
            cursor: 'pointer',
            '&& svg': {
                height: '20px',
                paddingTop: '5px',
                color: selectColor(environment.tenant),
            },
        },
        fabGrid: {
            marginRight: 40,
            flexGrow: 1,
            textAlign: 'right',
            display: 'flex',
            height: 'auto',
        },
        marginGrid: {
            height: '100%',
        },
        fab: {
            height: 0,
        },
    });

interface Props extends WithStyles<typeof styles> {
    theme: Theme;
    title: string;
    pageDescription?: string;
    isBack?: string;
    backTitle?: string;
    onTitle?: () => void;
    isAdminApp: boolean;
    fabWidth?: number;
}

export const Component: React.SFC<Props> = (props: Props) => {
    const { classes } = props;
    const appContainer = props.isAdminApp ? AdminAppContainer.useContainer() : UserAppContainer.useContainer();
    const routerContext = useContext(__RouterContext);
    const handleClick = () => {
        if (props.backTitle) {
            document.title = props.backTitle;
        }
        if (typeof props.onTitle === 'function') {
            props.onTitle();
        }
    };
    const matchesBreakPoint = useMediaQuery(props.theme.breakpoints.up('lg'), {
        defaultMatches: false,
    });
    if (typeof props.onTitle === 'function') {
        props.onTitle();
    }
    const render = () => {
        if (matchesBreakPoint) {
            return (
                <>
                    {props.isBack && !appContainer.onBackError && (
                        <Link className={classes.link} onClick={handleClick} to={props.isBack}>
                            <ArrowBackIos />
                        </Link>
                    )}
                    {!props.isBack && appContainer.onBackError && (
                        <div className={classes.link} onClick={() => routerContext.history.goBack()}>
                            <ArrowBackIos />
                        </div>
                    )}
                    {!props.isBack && <></>}
                    <Typography className={classes.title}>{props.title}</Typography>
                    <Grid item container direction="row" wrap="nowrap">
                        <Grid item direction="column">
                            {props.pageDescription &&
                                props.pageDescription.split('\n').map((value, index) => {
                                    return (
                                        <Typography className={classes.desc} key={index} variant="caption" color="inherit">
                                            {value}
                                        </Typography>
                                    );
                                })}
                        </Grid>
                        {/*
                            画面縮小時にFAB UIとpageDescriptionが被らないようにするための余白。
                            FAB UIを配置する画面はFAB UIの幅分だけ余白をとることでUIが被るのを防ぐことができる。
                        */}
                        {props.fabWidth && <Grid style={{ width: `${props.fabWidth}px` }} />}
                    </Grid>

                    <Divider />
                </>
            );
        }
        return null;
    };

    return <>{render()}</>;
};
export default withStyles(styles, { withTheme: true })(Component);
