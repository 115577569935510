import signin from './signin';
import recover from './recover';
import logout from './logout';
import verify from './verify';

export default {
    signin,
    logout,
    recover,
    verify,
};
