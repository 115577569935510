export default {
    "promptMessage": "promptMessage",
    "action": {
        "add": "action.add",
        "added": "action.added",
        "back": "action.back",
        "cancel": "action.cancel",
        "cancelled": "action.cancelled",
        "change": "action.change",
        "close": "action.close",
        "collectiveInvited": "action.collectiveInvited",
        "complete": "action.complete",
        "copied": "action.copied",
        "copy": "action.copy",
        "create": "action.create",
        "created": "action.created",
        "creating": "action.creating",
        "delete": "action.delete",
        "deleted": "action.deleted",
        "deleting": "action.deleting",
        "download": "action.download",
        "dragAndDrop": "action.dragAndDrop",
        "edit": "action.edit",
        "edited": "action.edited",
        "editing": "action.editing",
        "error": "action.error",
        "failedToDelete": "action.failedToDelete",
        "failedToInvite": "action.failedToInvite",
        "failedToSave": "action.failedToSave",
        "failedToSend": "action.failedToSend",
        "invalidEmailInvite": "action.invalidEmailInvite",
        "invitationReSend": "action.invitationReSend",
        "invitationSend": "action.invitationSend",
        "invitationSendFailure": "action.invitationSendFailure",
        "invited": "action.invited",
        "load": "action.load",
        "loaded": "action.loaded",
        "loading": "action.loading",
        "next": "action.next",
        "ok": "action.ok",
        "or": "action.or",
        "register": "action.register",
        "request": "action.request",
        "requestTimeout": "action.requestTimeout",
        "resistered": "action.resistered",
        "save": "action.save",
        "saveChangeEmail": "action.saveChangeEmail",
        "saved": "action.saved",
        "saving": "action.saving",
        "select": "action.select",
        "selectFile": "action.selectFile",
        "selectImage": "action.selectImage",
        "selected": "action.selected",
        "send": "action.send",
        "sending": "action.sending",
        "sent": "action.sent",
        "skip": "action.skip",
        "skipped": "action.skipped",
        "tryAgain": "action.tryAgain",
        "update": "action.update",
        "updated": "action.updated",
        "updating": "action.updating",
        "upload": "action.upload",
        "uploaded": "action.uploaded",
        "uploading": "action.uploading"
    },
    "adminCloudDasboard": {
        "apiKey": "adminCloudDasboard.apiKey",
        "invalid": "adminCloudDasboard.invalid",
        "label2L": "adminCloudDasboard.label2L",
        "label3L": "adminCloudDasboard.label3L",
        "operation": "adminCloudDasboard.operation",
        "serviceConnect": "adminCloudDasboard.serviceConnect",
        "serviceName": "adminCloudDasboard.serviceName",
        "title": "adminCloudDasboard.title"
    },
    "adminCloudDetail": {
        "btnCopy": "adminCloudDetail.btnCopy",
        "btnSave": "adminCloudDetail.btnSave",
        "btnSelect": "adminCloudDetail.btnSelect",
        "downloadable": "adminCloudDetail.downloadable",
        "jsonSetting": "adminCloudDetail.jsonSetting",
        "label2L": "adminCloudDetail.label2L",
        "label3L": "adminCloudDetail.label3L",
        "note": "adminCloudDetail.note",
        "oauthLabel": "adminCloudDetail.oauthLabel",
        "siosCovasAccId": "adminCloudDetail.siosCovasAccId",
        "subTitle": "adminCloudDetail.subTitle",
        "title": "adminCloudDetail.title",
        "uploadable": "adminCloudDetail.uploadable",
        "warning": "adminCloudDetail.warning"
    },
    "adminDashboard": {
        "announce": {
            "index": "adminDashboard.announce.index"
        },
        "collectFile": {
            "index": "adminDashboard.collectFile.index"
        },
        "createEndpoint": {
            "index": "adminDashboard.createEndpoint.index"
        },
        "delete": {
            "index": "adminDashboard.delete.index"
        },
        "deliverFile": {
            "index": "adminDashboard.deliverFile.index"
        },
        "edit": {
            "index": "adminDashboard.edit.index"
        },
        "hidden": {
            "index": "adminDashboard.hidden.index"
        },
        "index": "adminDashboard.index",
        "menu": {
            "index": "adminDashboard.menu.index",
            "pickup": {
                "index": "adminDashboard.menu.pickup.index"
            },
            "pinned": {
                "index": "adminDashboard.menu.pinned.index"
            },
            "setting": {
                "cloudStorage": {
                    "index": "adminDashboard.menu.setting.cloudStorage.index"
                },
                "endpoint": {
                    "index": "adminDashboard.menu.setting.endpoint.index"
                },
                "index": "adminDashboard.menu.setting.index",
                "profileAccount": {
                    "index": "adminDashboard.menu.setting.profileAccount.index"
                },
                "shareDevice": {
                    "index": "adminDashboard.menu.setting.shareDevice.index"
                }
            },
            "signinOther": {
                "index": "adminDashboard.menu.signinOther.index"
            },
            "signout": {
                "index": "adminDashboard.menu.signout.index"
            },
            "spool": {
                "index": "adminDashboard.menu.spool.index"
            }
        },
        "pin": {
            "index": "adminDashboard.pin.index"
        },
        "pullToRefresh": {
            "index": "adminDashboard.pullToRefresh.index"
        }
    },
    "agentSignInHeader": {
        "info": "agentSignInHeader.info",
        "link": "agentSignInHeader.link"
    },
    "analytics": {
        "index": "analytics.index",
        "save": "analytics.save",
        "usageLog": {
            "downloadUsageLog": "analytics.usageLog.downloadUsageLog",
            "modal": {
                "button": {
                    "cancel": "analytics.usageLog.modal.button.cancel",
                    "download": "analytics.usageLog.modal.button.download"
                },
                "desc": "analytics.usageLog.modal.desc",
                "selectedDuration": {
                    "helperMessage": "analytics.usageLog.modal.selectedDuration.helperMessage",
                    "title": "analytics.usageLog.modal.selectedDuration.title"
                },
                "title": "analytics.usageLog.modal.title"
            },
            "subTitle": "analytics.usageLog.subTitle",
            "usageLogCsvName": "analytics.usageLog.usageLogCsvName"
        },
        "usageReport": {
            "enable": "analytics.usageReport.enable",
            "table": {
                "cell": {
                    "name": "analytics.usageReport.table.cell.name"
                },
                "desc": "analytics.usageReport.table.desc",
                "search": "analytics.usageReport.table.search",
                "selected": "analytics.usageReport.table.selected"
            }
        }
    },
    "applicationList": {
        "action": {
            "canceled": "applicationList.action.canceled",
            "created": "applicationList.action.created",
            "deleted": "applicationList.action.deleted",
            "reverted": "applicationList.action.reverted",
            "saved": "applicationList.action.saved"
        },
        "applicationDetails": {
            "applicationCancel": {
                "attention1": "applicationList.applicationDetails.applicationCancel.attention1",
                "attention2": "applicationList.applicationDetails.applicationCancel.attention2",
                "button1": "applicationList.applicationDetails.applicationCancel.button1",
                "button2": "applicationList.applicationDetails.applicationCancel.button2",
                "cancel": "applicationList.applicationDetails.applicationCancel.cancel",
                "index": "applicationList.applicationDetails.applicationCancel.index"
            },
            "applicationDelete": {
                "attention": "applicationList.applicationDetails.applicationDelete.attention",
                "button": "applicationList.applicationDetails.applicationDelete.button",
                "index": "applicationList.applicationDetails.applicationDelete.index"
            },
            "basicInformation": {
                "basicInfo": {
                    "cancelDate": "applicationList.applicationDetails.basicInformation.basicInfo.cancelDate",
                    "deleteDate": "applicationList.applicationDetails.basicInformation.basicInfo.deleteDate",
                    "entryDate": "applicationList.applicationDetails.basicInformation.basicInfo.entryDate",
                    "entryNumber": "applicationList.applicationDetails.basicInformation.basicInfo.entryNumber",
                    "entryState": "applicationList.applicationDetails.basicInformation.basicInfo.entryState",
                    "title": "applicationList.applicationDetails.basicInformation.basicInfo.title",
                    "workspace": "applicationList.applicationDetails.basicInformation.basicInfo.workspace",
                    "workspaceStatus": "applicationList.applicationDetails.basicInformation.basicInfo.workspaceStatus"
                },
                "category": {
                    "new": "applicationList.applicationDetails.basicInformation.category.new",
                    "title": "applicationList.applicationDetails.basicInformation.category.title",
                    "try": "applicationList.applicationDetails.basicInformation.category.try"
                },
                "cluod": {
                    "box": "applicationList.applicationDetails.basicInformation.cluod.box",
                    "dropbox": "applicationList.applicationDetails.basicInformation.cluod.dropbox",
                    "googleDrive": "applicationList.applicationDetails.basicInformation.cluod.googleDrive",
                    "googleShareDrive": "applicationList.applicationDetails.basicInformation.cluod.googleShareDrive",
                    "oneDrive": "applicationList.applicationDetails.basicInformation.cluod.oneDrive",
                    "sharePoint": "applicationList.applicationDetails.basicInformation.cluod.sharePoint",
                    "title": "applicationList.applicationDetails.basicInformation.cluod.title"
                },
                "product": {
                    "fax": "applicationList.applicationDetails.basicInformation.product.fax",
                    "scan": "applicationList.applicationDetails.basicInformation.product.scan",
                    "title": "applicationList.applicationDetails.basicInformation.product.title"
                }
            },
            "customerInfomation": {
                "companyName": "applicationList.applicationDetails.customerInfomation.companyName",
                "departmentName": "applicationList.applicationDetails.customerInfomation.departmentName",
                "email": "applicationList.applicationDetails.customerInfomation.email",
                "firstName": "applicationList.applicationDetails.customerInfomation.firstName",
                "lastName": "applicationList.applicationDetails.customerInfomation.lastName",
                "phoneNumber": "applicationList.applicationDetails.customerInfomation.phoneNumber"
            },
            "index": "applicationList.applicationDetails.index",
            "managerInformation": {
                "engineerInfo": {
                    "email": "applicationList.applicationDetails.managerInformation.engineerInfo.email",
                    "firstName": "applicationList.applicationDetails.managerInformation.engineerInfo.firstName",
                    "lastName": "applicationList.applicationDetails.managerInformation.engineerInfo.lastName",
                    "title": "applicationList.applicationDetails.managerInformation.engineerInfo.title"
                },
                "salesInfo": {
                    "departmentName": "applicationList.applicationDetails.managerInformation.salesInfo.departmentName",
                    "email": "applicationList.applicationDetails.managerInformation.salesInfo.email",
                    "firstName": "applicationList.applicationDetails.managerInformation.salesInfo.firstName",
                    "lastName": "applicationList.applicationDetails.managerInformation.salesInfo.lastName",
                    "phoneNumber": "applicationList.applicationDetails.managerInformation.salesInfo.phoneNumber",
                    "title": "applicationList.applicationDetails.managerInformation.salesInfo.title"
                }
            },
            "placeholder": "applicationList.applicationDetails.placeholder",
            "saveButton": "applicationList.applicationDetails.saveButton",
            "tab1": "applicationList.applicationDetails.tab1",
            "tab2": "applicationList.applicationDetails.tab2",
            "tab3": "applicationList.applicationDetails.tab3",
            "workspaceOperation": {
                "create": "applicationList.applicationDetails.workspaceOperation.create",
                "detail": "applicationList.applicationDetails.workspaceOperation.detail"
            },
            "workspaceState": {
                "active": "applicationList.applicationDetails.workspaceState.active",
                "delete": "applicationList.applicationDetails.workspaceState.delete",
                "none": "applicationList.applicationDetails.workspaceState.none",
                "stopped": "applicationList.applicationDetails.workspaceState.stopped",
                "waitSignup": "applicationList.applicationDetails.workspaceState.waitSignup"
            }
        },
        "csvButton": "applicationList.csvButton",
        "index": "applicationList.index",
        "search": {
            "button": "applicationList.search.button",
            "company": "applicationList.search.company",
            "date": {
                "default": "applicationList.search.date.default",
                "tab4": "applicationList.search.date.tab4",
                "tab5": "applicationList.search.date.tab5"
            },
            "entryNumber": "applicationList.search.entryNumber",
            "mail": "applicationList.search.mail",
            "manager": "applicationList.search.manager"
        },
        "tab": {
            "company": "applicationList.tab.company",
            "entryCategory": "applicationList.tab.entryCategory",
            "entryNumber": "applicationList.tab.entryNumber",
            "mail": "applicationList.tab.mail",
            "manager": "applicationList.tab.manager"
        },
        "tab1": {
            "date": "applicationList.tab1.date",
            "index": "applicationList.tab1.index"
        },
        "tab2": {
            "index": "applicationList.tab2.index"
        },
        "tab3": {
            "index": "applicationList.tab3.index"
        },
        "tab4": {
            "date": "applicationList.tab4.date",
            "index": "applicationList.tab4.index"
        },
        "tab5": {
            "date": "applicationList.tab5.date",
            "index": "applicationList.tab5.index"
        },
        "validation": {
            "incorrectFormat": "applicationList.validation.incorrectFormat",
            "inputLimit": "applicationList.validation.inputLimit",
            "requiredSometing": "applicationList.validation.requiredSometing",
            "tooLong": "applicationList.validation.tooLong"
        }
    },
    "cloudConnection": {
        "button": {
            "addNew": "cloudConnection.button.addNew",
            "connectAuthorize": "cloudConnection.button.connectAuthorize",
            "delete": "cloudConnection.button.delete"
        },
        "cloudConnectionDesc": "cloudConnection.cloudConnectionDesc",
        "dueDate": "cloudConnection.dueDate",
        "expireDay": "cloudConnection.expireDay",
        "label": {
            "approvalPeriod": "cloudConnection.label.approvalPeriod",
            "authorizationId": "cloudConnection.label.authorizationId"
        },
        "modal": {
            "button": {
                "authorize": "cloudConnection.modal.button.authorize"
            },
            "docabTitle": "cloudConnection.modal.docabTitle",
            "docardTitle": "cloudConnection.modal.docardTitle",
            "label": {
                "service": "cloudConnection.modal.label.service"
            },
            "title": "cloudConnection.modal.title"
        },
        "service": {
            "docab": "cloudConnection.service.docab",
            "docard": "cloudConnection.service.docard",
            "email": "cloudConnection.service.email",
            "google": "cloudConnection.service.google",
            "googleTeam": "cloudConnection.service.googleTeam",
            "noneCloud": "cloudConnection.service.noneCloud",
            "oneDrive": "cloudConnection.service.oneDrive",
            "sharePointOnline": "cloudConnection.service.sharePointOnline"
        },
        "unAuthor": "cloudConnection.unAuthor"
    },
    "cloudConnectionAuth": {
        "component": {
            "auth": "cloudConnectionAuth.component.auth",
            "cancel": "cloudConnectionAuth.component.cancel",
            "close": "cloudConnectionAuth.component.close"
        },
        "message": {
            "cloudConnection": "cloudConnectionAuth.message.cloudConnection",
            "error": {
                "expired": "cloudConnectionAuth.message.error.expired",
                "forbidden": "cloudConnectionAuth.message.error.forbidden",
                "invalidCloudStorage": "cloudConnectionAuth.message.error.invalidCloudStorage",
                "invalidDomain": "cloudConnectionAuth.message.error.invalidDomain",
                "unknown": "cloudConnectionAuth.message.error.unknown"
            },
            "success": "cloudConnectionAuth.message.success"
        },
        "title": {
            "cloudConnection": "cloudConnectionAuth.title.cloudConnection",
            "error": "cloudConnectionAuth.title.error",
            "forbidden": "cloudConnectionAuth.title.forbidden",
            "success": "cloudConnectionAuth.title.success"
        }
    },
    "common": {
        "brand": "common.brand",
        "contactEmail": "common.contactEmail",
        "contactEmailInfo": "common.contactEmailInfo",
        "covas": "common.covas",
        "covasManagementConsole": "common.covasManagementConsole",
        "covasManagementSite": "common.covasManagementSite",
        "deviceLoginUser": "common.deviceLoginUser",
        "email": "common.email",
        "language": {
            "english": "common.language.english",
            "japanese": "common.language.japanese"
        },
        "model": {
            "endpoint": {
                "individual": "common.model.endpoint.individual",
                "shared": "common.model.endpoint.shared"
            },
            "group": "common.model.group",
            "user": "common.model.user"
        },
        "name": "common.name",
        "password": "common.password",
        "passwordConfirm": "common.passwordConfirm",
        "phonetic": "common.phonetic",
        "plan": {
            "enterprise": "common.plan.enterprise",
            "free": "common.plan.free",
            "index": "common.plan.index",
            "internal": "common.plan.internal",
            "standard": "common.plan.standard"
        },
        "policy": "common.policy",
        "policyURL": "common.policyURL",
        "role": {
            "admin": "common.role.admin",
            "externalAdmin": "common.role.externalAdmin",
            "systemAdmin": "common.role.systemAdmin",
            "user": "common.role.user"
        },
        "tos": "common.tos",
        "tosURL": "common.tosURL"
    },
    "confirmCopy": {
        "areYouSaveAndCopy": "confirmCopy.areYouSaveAndCopy",
        "button": {
            "no": "confirmCopy.button.no",
            "yes": "confirmCopy.button.yes"
        }
    },
    "confirmDeleted": {
        "areYouDelete": "confirmDeleted.areYouDelete",
        "areYouDeleteNoName": "confirmDeleted.areYouDeleteNoName",
        "button": {
            "no": "confirmDeleted.button.no",
            "yes": "confirmDeleted.button.yes"
        }
    },
    "confirmSaved": {
        "button": {
            "no": "confirmSaved.button.no",
            "yes": "confirmSaved.button.yes"
        },
        "withName": "confirmSaved.withName",
        "withoutName": "confirmSaved.withoutName"
    },
    "createEnpoint": {
        "btnCreate": "createEnpoint.btnCreate",
        "buttonColor": "createEnpoint.buttonColor",
        "cloudConnect": "createEnpoint.cloudConnect",
        "display": "createEnpoint.display",
        "download": "createEnpoint.download",
        "endpointName": "createEnpoint.endpointName",
        "icon": "createEnpoint.icon",
        "library": "createEnpoint.library",
        "m365LibRequired": "createEnpoint.m365LibRequired",
        "m365LibRequiredDialog": "createEnpoint.m365LibRequiredDialog",
        "m365RequiredDialog": "createEnpoint.m365RequiredDialog",
        "m365SiteRequired": "createEnpoint.m365SiteRequired",
        "m365SiteRequiredDialog": "createEnpoint.m365SiteRequiredDialog",
        "site": "createEnpoint.site",
        "stepFiveLabel": "createEnpoint.stepFiveLabel",
        "stepFourLabel": "createEnpoint.stepFourLabel",
        "stepOneLabel": "createEnpoint.stepOneLabel",
        "stepThreeLabel": "createEnpoint.stepThreeLabel",
        "stepTwoLabel": "createEnpoint.stepTwoLabel",
        "title": "createEnpoint.title",
        "totalIconLabel": "createEnpoint.totalIconLabel",
        "upload": "createEnpoint.upload"
    },
    "createSharedEndpoint": {
        "btnCreate": "createSharedEndpoint.btnCreate",
        "endpointName": "createSharedEndpoint.endpointName",
        "stepFiveLabel": "createSharedEndpoint.stepFiveLabel",
        "title": "createSharedEndpoint.title",
        "upload": "createSharedEndpoint.upload"
    },
    "debug": {
        "hello": "debug.hello",
        "mixed": "debug.mixed",
        "plural": {
            "one": "debug.plural.one",
            "two": "debug.plural.two",
            "index": "debug.plural"
        }
    },
    "endpoint": {
        "businessCard": {
            "all": "endpoint.businessCard.all",
            "other": "endpoint.businessCard.other"
        },
        "button": {
            "remove": "endpoint.button.remove",
            "save": "endpoint.button.save",
            "saveEdit": "endpoint.button.saveEdit",
            "selectFolder": "endpoint.button.selectFolder",
            "settingcopy": "endpoint.button.settingcopy",
            "sharecopy": "endpoint.button.sharecopy"
        },
        "buttonColor": "endpoint.buttonColor",
        "changeColor": "endpoint.changeColor",
        "changeFolder": "endpoint.changeFolder",
        "changeIcon": "endpoint.changeIcon",
        "cloudConnection": "endpoint.cloudConnection",
        "confirmSave": {
            "endpointPermission": {
                "applicationPermissionList": "endpoint.confirmSave.endpointPermission.applicationPermissionList",
                "desc": {
                    "application": "endpoint.confirmSave.endpointPermission.desc.application",
                    "base": "endpoint.confirmSave.endpointPermission.desc.base",
                    "mfp": "endpoint.confirmSave.endpointPermission.desc.mfp",
                    "separator": "endpoint.confirmSave.endpointPermission.desc.separator"
                },
                "mfpPermissionList": "endpoint.confirmSave.endpointPermission.mfpPermissionList"
            },
            "title": "endpoint.confirmSave.title"
        },
        "copyName": "endpoint.copyName",
        "defaultSettingForPrint": "endpoint.defaultSettingForPrint",
        "defaultSettingForScanApp": "endpoint.defaultSettingForScanApp",
        "download": "endpoint.download",
        "email": {
            "completed": "endpoint.email.completed",
            "error": "endpoint.email.error",
            "title": "endpoint.email.title"
        },
        "emailXStorage": {
            "address": "endpoint.emailXStorage.address",
            "defaultSubject": "endpoint.emailXStorage.defaultSubject",
            "defaultTemplate": "endpoint.emailXStorage.defaultTemplate",
            "fileMaxSize": "endpoint.emailXStorage.fileMaxSize",
            "fileMaxSizeLabel": "endpoint.emailXStorage.fileMaxSizeLabel",
            "fileMaxSizeNotice": "endpoint.emailXStorage.fileMaxSizeNotice",
            "subject": "endpoint.emailXStorage.subject",
            "template": "endpoint.emailXStorage.template"
        },
        "empty": "endpoint.empty",
        "endpointDetail": "endpoint.endpointDetail",
        "endpointName": "endpoint.endpointName",
        "error": {
            "notFoundXstorage": "endpoint.error.notFoundXstorage"
        },
        "extendedFeatureDown": "endpoint.extendedFeatureDown",
        "extendedFeatureUp": "endpoint.extendedFeatureUp",
        "extensions": {
            "button": {
                "addMoreExtened": "endpoint.extensions.button.addMoreExtened",
                "remove": "endpoint.extensions.button.remove",
                "toSetting": "endpoint.extensions.button.toSetting"
            },
            "displayAndCloudConnect": "endpoint.extensions.displayAndCloudConnect",
            "downLoadDocument": "endpoint.extensions.downLoadDocument",
            "modal": {
                "button": {
                    "add": "endpoint.extensions.modal.button.add",
                    "detail": "endpoint.extensions.modal.button.detail"
                }
            },
            "printDownload": "endpoint.extensions.printDownload",
            "saveDocument": "endpoint.extensions.saveDocument",
            "stopWhenError": "endpoint.extensions.stopWhenError"
        },
        "fileName": "endpoint.fileName",
        "folder": "endpoint.folder",
        "icon": "endpoint.icon",
        "operationObject": "endpoint.operationObject",
        "operationTarget": "endpoint.operationTarget",
        "saveChangeColor": "endpoint.saveChangeColor",
        "saveChangeIcon": "endpoint.saveChangeIcon",
        "upload": "endpoint.upload"
    },
    "endpointScan": {
        "button": {
            "delete": "endpointScan.button.delete",
            "switchButton": "endpointScan.button.switchButton",
            "update": "endpointScan.button.update"
        },
        "label": {
            "content": "endpointScan.label.content",
            "indexDescription": "endpointScan.label.indexDescription",
            "initScanSetting": "endpointScan.label.initScanSetting",
            "saveDefault": "endpointScan.label.saveDefault",
            "scanSettingName": "endpointScan.label.scanSettingName",
            "userAgent": "endpointScan.label.userAgent"
        }
    },
    "endpointSharedSetting": {
        "addButton": "endpointSharedSetting.addButton",
        "description": "endpointSharedSetting.description",
        "title": "endpointSharedSetting.title"
    },
    "endpointUserSetting": {
        "addButton": "endpointUserSetting.addButton",
        "description": "endpointUserSetting.description",
        "title": "endpointUserSetting.title"
    },
    "endpointsPermission": {
        "applicationPermission": {
            "application": {
                "fax": "endpointsPermission.applicationPermission.application.fax",
                "scan": "endpointsPermission.applicationPermission.application.scan"
            },
            "permissionSwitch": "endpointsPermission.applicationPermission.permissionSwitch"
        },
        "mfpPermission": {
            "permissionSwitch": "endpointsPermission.mfpPermission.permissionSwitch",
            "selectedNumber": "endpointsPermission.mfpPermission.selectedNumber",
            "table": {
                "cell": {
                    "deviceName": "endpointsPermission.mfpPermission.table.cell.deviceName",
                    "mfpName": "endpointsPermission.mfpPermission.table.cell.mfpName",
                    "mfpNumber": "endpointsPermission.mfpPermission.table.cell.mfpNumber"
                },
                "search": "endpointsPermission.mfpPermission.table.search"
            }
        },
        "sharedTitle": "endpointsPermission.sharedTitle",
        "tab": {
            "groupPermission": "endpointsPermission.tab.groupPermission",
            "mfpPermission": "endpointsPermission.tab.mfpPermission",
            "userPermission": "endpointsPermission.tab.userPermission"
        },
        "title": "endpointsPermission.title",
        "userGroupPermission": {
            "description": "endpointsPermission.userGroupPermission.description",
            "groupTable": {
                "cell": {
                    "memberCount": "endpointsPermission.userGroupPermission.groupTable.cell.memberCount",
                    "name": "endpointsPermission.userGroupPermission.groupTable.cell.name"
                },
                "searchGroup": "endpointsPermission.userGroupPermission.groupTable.searchGroup"
            },
            "permissionSwitch": "endpointsPermission.userGroupPermission.permissionSwitch",
            "selectedNumber": "endpointsPermission.userGroupPermission.selectedNumber",
            "userTable": {
                "cell": {
                    "name": "endpointsPermission.userGroupPermission.userTable.cell.name",
                    "role": "endpointsPermission.userGroupPermission.userTable.cell.role"
                },
                "popupMenu": {
                    "role": {
                        "all": "endpointsPermission.userGroupPermission.userTable.popupMenu.role.all"
                    }
                },
                "searchUser": "endpointsPermission.userGroupPermission.userTable.searchUser"
            }
        }
    },
    "error": {
        "accountLock": "error.accountLock",
        "accountSuspend": "error.accountSuspend",
        "aiAuthFailure": "error.aiAuthFailure",
        "default": "error.default",
        "emailAlreadyInUse": "error.emailAlreadyInUse",
        "endpointsExError": "error.endpointsExError",
        "extensionFailure": "error.extensionFailure",
        "fileLengthFailure": "error.fileLengthFailure",
        "getListFailed": "error.getListFailed",
        "inconsistentSetting": "error.inconsistentSetting",
        "invalidEmail": "error.invalidEmail",
        "invalidJsonFile": "error.invalidJsonFile",
        "invalidStorageToken": "error.invalidStorageToken",
        "invalidToken": "error.invalidToken",
        "loginFailure": "error.loginFailure",
        "loginRoleFailure": "error.loginRoleFailure",
        "mfpInactive": "error.mfpInactive",
        "mfpNoAlreadyInUse": "error.mfpNoAlreadyInUse",
        "mfpRegisterFailed": "error.mfpRegisterFailed",
        "networkFailure": "error.networkFailure",
        "networkUnstable": "error.networkUnstable",
        "noAvailableExtension": "error.noAvailableExtension",
        "noDataAvailable": "error.noDataAvailable",
        "notExtensionFound": "error.notExtensionFound",
        "notFound": "error.notFound",
        "rootFolderNotFound": "error.rootFolderNotFound",
        "signupVerifyError": "error.signupVerifyError",
        "spoolerUpdatedPleaseReload": "error.spoolerUpdatedPleaseReload",
        "storageNotFound": "error.storageNotFound",
        "tooManyAccess": "error.tooManyAccess",
        "unknown": "error.unknown",
        "updateCloudSetting": "error.updateCloudSetting",
        "updateFailed": "error.updateFailed",
        "updateRegisteredStorage": "error.updateRegisteredStorage",
        "uploadFailure": "error.uploadFailure",
        "userRegisterFailed": "error.userRegisterFailed",
        "weakPassword": "error.weakPassword",
        "workspaceIdAlreadyInUse": "error.workspaceIdAlreadyInUse",
        "workspaceNotFound": "error.workspaceNotFound",
        "xstorageFailConnect": "error.xstorageFailConnect"
    },
    "extension": {
        "active": "extension.active",
        "button": {
            "addMoreExtended": "extension.button.addMoreExtended"
        },
        "category": "extension.category",
        "detailInfo": "extension.detailInfo",
        "extensionFunction": "extension.extensionFunction",
        "numberOfUsers": {
            "from100Users": "extension.numberOfUsers.from100Users",
            "from5Users": "extension.numberOfUsers.from5Users",
            "notSpecified": "extension.numberOfUsers.notSpecified",
            "textNumberOf": "extension.numberOfUsers.textNumberOf",
            "under5Users": "extension.numberOfUsers.under5Users"
        },
        "searchByKey": "extension.searchByKey"
    },
    "fileName": {
        "anyCharater": {
            "index": "fileName.anyCharater.index"
        },
        "anyText": {
            "index": "fileName.anyText.index"
        },
        "checkboxLabel": {
            "index": "fileName.checkboxLabel.index",
            "shareIndex": "fileName.checkboxLabel.shareIndex"
        },
        "clearWordList": "fileName.clearWordList",
        "date": {
            "index": "fileName.date.index"
        },
        "date1": {
            "index": "fileName.date1.index"
        },
        "date10": {
            "index": "fileName.date10.index"
        },
        "date11": {
            "index": "fileName.date11.index"
        },
        "date12": {
            "index": "fileName.date12.index"
        },
        "date13": {
            "index": "fileName.date13.index"
        },
        "date14": {
            "index": "fileName.date14.index"
        },
        "date15": {
            "index": "fileName.date15.index"
        },
        "date2": {
            "index": "fileName.date2.index"
        },
        "date3": {
            "index": "fileName.date3.index"
        },
        "date4": {
            "index": "fileName.date4.index"
        },
        "date5": {
            "index": "fileName.date5.index"
        },
        "date6": {
            "index": "fileName.date6.index"
        },
        "date7": {
            "index": "fileName.date7.index"
        },
        "date8": {
            "index": "fileName.date8.index"
        },
        "date9": {
            "index": "fileName.date9.index"
        },
        "dateSeparator": {
            "c": "fileName.dateSeparator.c",
            "dot": "fileName.dateSeparator.dot",
            "hyphen": "fileName.dateSeparator.hyphen",
            "none": "fileName.dateSeparator.none",
            "underscore": "fileName.dateSeparator.underscore"
        },
        "day": {
            "index": "fileName.day.index"
        },
        "example": {
            "index": "fileName.example.index"
        },
        "extension": {
            "index": "fileName.extension.index"
        },
        "fileNameErrorMessage": {
            "index": "fileName.fileNameErrorMessage.index"
        },
        "folder": {
            "index": "fileName.folder.index"
        },
        "folder0": {
            "index": "fileName.folder0.index"
        },
        "folder1": {
            "index": "fileName.folder1.index"
        },
        "folder2": {
            "index": "fileName.folder2.index"
        },
        "folder3": {
            "index": "fileName.folder3.index"
        },
        "folder4": {
            "index": "fileName.folder4.index"
        },
        "folder5": {
            "index": "fileName.folder5.index"
        },
        "folderLabel": {
            "index": "fileName.folderLabel.index"
        },
        "index": "fileName.index",
        "month": {
            "index": "fileName.month.index"
        },
        "none": {
            "index": "fileName.none.index"
        },
        "rule": {
            "index": "fileName.rule.index"
        },
        "saveChanges": {
            "index": "fileName.saveChanges.index"
        },
        "selectedConditionsErrorMessage": {
            "index": "fileName.selectedConditionsErrorMessage.index"
        },
        "separatorDot": {
            "index": "fileName.separatorDot.index"
        },
        "separatorHyphen": {
            "index": "fileName.separatorHyphen.index"
        },
        "separatorTitle": "fileName.separatorTitle",
        "separatorUnderscore": {
            "index": "fileName.separatorUnderscore.index"
        },
        "severalPagesCount": {
            "index": "fileName.severalPagesCount.index"
        },
        "severalPagesPut": {
            "index": "fileName.severalPagesPut.index"
        },
        "severalPagesTitle": "fileName.severalPagesTitle",
        "switchBlock": {
            "index": "fileName.switchBlock.index"
        },
        "time": {
            "index": "fileName.time.index"
        },
        "user": {
            "index": "fileName.user.index"
        },
        "wordbook": {
            "button": "fileName.wordbook.button",
            "prefix": "fileName.wordbook.prefix",
            "title": "fileName.wordbook.title"
        },
        "wordbookTemplate": {
            "index": "fileName.wordbookTemplate.index"
        },
        "workBook": {
            "index": "fileName.workBook.index",
            "noName": "fileName.workBook.noName",
            "unavailable": "fileName.workBook.unavailable"
        },
        "year": {
            "index": "fileName.year.index"
        }
    },
    "forbidden": {
        "sub1": "forbidden.sub1",
        "sub2": "forbidden.sub2",
        "title": "forbidden.title"
    },
    "invitationNewWorkspace": {
        "index": "invitationNewWorkspace.index",
        "tab1": {
            "index": "invitationNewWorkspace.tab1.index",
            "memberCount": "invitationNewWorkspace.tab1.memberCount",
            "operation": "invitationNewWorkspace.tab1.operation",
            "state": {
                "active": "invitationNewWorkspace.tab1.state.active",
                "inactive": "invitationNewWorkspace.tab1.state.inactive",
                "index": "invitationNewWorkspace.tab1.state.index"
            },
            "subWorkspace": {
                "disable": "invitationNewWorkspace.tab1.subWorkspace.disable",
                "enable": "invitationNewWorkspace.tab1.subWorkspace.enable",
                "index": "invitationNewWorkspace.tab1.subWorkspace.index"
            },
            "workspaceId": "invitationNewWorkspace.tab1.workspaceId",
            "workspaceName": "invitationNewWorkspace.tab1.workspaceName"
        },
        "tab2": {
            "contactEmail": "invitationNewWorkspace.tab2.contactEmail",
            "corporateOrganization": "invitationNewWorkspace.tab2.corporateOrganization",
            "customerNumber": "invitationNewWorkspace.tab2.customerNumber",
            "edit": "invitationNewWorkspace.tab2.edit",
            "index": "invitationNewWorkspace.tab2.index",
            "plan": "invitationNewWorkspace.tab2.plan",
            "tel": "invitationNewWorkspace.tab2.tel",
            "workspaceId": "invitationNewWorkspace.tab2.workspaceId"
        },
        "tab3": {
            "contactEmailAddress": {
                "index": "invitationNewWorkspace.tab3.contactEmailAddress.index"
            },
            "emailAddress": "invitationNewWorkspace.tab3.emailAddress",
            "externalAdmin": "invitationNewWorkspace.tab3.externalAdmin",
            "index": "invitationNewWorkspace.tab3.index",
            "invidationDate": {
                "index": "invitationNewWorkspace.tab3.invidationDate.index"
            },
            "invidationTime": {
                "index": "invitationNewWorkspace.tab3.invidationTime.index"
            },
            "invidationUser": {
                "index": "invitationNewWorkspace.tab3.invidationUser.index"
            },
            "invitation": {
                "index": "invitationNewWorkspace.tab3.invitation.index"
            },
            "invitationLimit": {
                "day": {
                    "one": "invitationNewWorkspace.tab3.invitationLimit.day.one",
                    "two": "invitationNewWorkspace.tab3.invitationLimit.day.two",
                    "index": "invitationNewWorkspace.tab3.invitationLimit.day"
                },
                "expired": "invitationNewWorkspace.tab3.invitationLimit.expired",
                "hour": {
                    "one": "invitationNewWorkspace.tab3.invitationLimit.hour.one",
                    "two": "invitationNewWorkspace.tab3.invitationLimit.hour.two",
                    "index": "invitationNewWorkspace.tab3.invitationLimit.hour"
                },
                "index": "invitationNewWorkspace.tab3.invitationLimit.index",
                "minute": {
                    "one": "invitationNewWorkspace.tab3.invitationLimit.minute.one",
                    "two": "invitationNewWorkspace.tab3.invitationLimit.minute.two",
                    "index": "invitationNewWorkspace.tab3.invitationLimit.minute"
                },
                "remain": "invitationNewWorkspace.tab3.invitationLimit.remain"
            },
            "invitationStatus": "invitationNewWorkspace.tab3.invitationStatus",
            "invitationUnit": {
                "day": {
                    "one": "invitationNewWorkspace.tab3.invitationUnit.day.one",
                    "two": "invitationNewWorkspace.tab3.invitationUnit.day.two",
                    "index": "invitationNewWorkspace.tab3.invitationUnit.day"
                },
                "infinity": {
                    "one": "invitationNewWorkspace.tab3.invitationUnit.infinity.one",
                    "two": "invitationNewWorkspace.tab3.invitationUnit.infinity.two",
                    "index": "invitationNewWorkspace.tab3.invitationUnit.infinity"
                },
                "month": {
                    "one": "invitationNewWorkspace.tab3.invitationUnit.month.one",
                    "two": "invitationNewWorkspace.tab3.invitationUnit.month.two",
                    "index": "invitationNewWorkspace.tab3.invitationUnit.month"
                },
                "week": {
                    "one": "invitationNewWorkspace.tab3.invitationUnit.week.one",
                    "two": "invitationNewWorkspace.tab3.invitationUnit.week.two",
                    "index": "invitationNewWorkspace.tab3.invitationUnit.week"
                }
            },
            "mailStatus": {
                "deliverd": "invitationNewWorkspace.tab3.mailStatus.deliverd",
                "dropped": "invitationNewWorkspace.tab3.mailStatus.dropped",
                "failed": "invitationNewWorkspace.tab3.mailStatus.failed",
                "index": "invitationNewWorkspace.tab3.mailStatus.index",
                "open": "invitationNewWorkspace.tab3.mailStatus.open",
                "processed": "invitationNewWorkspace.tab3.mailStatus.processed",
                "unsent": "invitationNewWorkspace.tab3.mailStatus.unsent"
            },
            "operation": "invitationNewWorkspace.tab3.operation",
            "period": {
                "index": "invitationNewWorkspace.tab3.period.index"
            },
            "sendAction": "invitationNewWorkspace.tab3.sendAction",
            "unSignup": {
                "index": "invitationNewWorkspace.tab3.unSignup.index"
            },
            "workspace": {
                "index": "invitationNewWorkspace.tab3.workspace.index"
            },
            "workspaceDisplayName": {
                "index": "invitationNewWorkspace.tab3.workspaceDisplayName.index"
            },
            "workspaceId": {
                "index": "invitationNewWorkspace.tab3.workspaceId.index"
            }
        }
    },
    "invitationNewWorkspaceMail": {
        "cc": "invitationNewWorkspaceMail.cc",
        "email": {
            "intro": "invitationNewWorkspaceMail.email.intro",
            "subject": "invitationNewWorkspaceMail.email.subject"
        },
        "index": "invitationNewWorkspaceMail.index",
        "intro": "invitationNewWorkspaceMail.intro",
        "invitationLimit": {
            "index": "invitationNewWorkspaceMail.invitationLimit.index"
        },
        "invitationUnit": {
            "day": {
                "one": "invitationNewWorkspaceMail.invitationUnit.day.one",
                "two": "invitationNewWorkspaceMail.invitationUnit.day.two",
                "index": "invitationNewWorkspaceMail.invitationUnit.day"
            },
            "infinity": {
                "one": "invitationNewWorkspaceMail.invitationUnit.infinity.one",
                "two": "invitationNewWorkspaceMail.invitationUnit.infinity.two",
                "index": "invitationNewWorkspaceMail.invitationUnit.infinity"
            },
            "month": {
                "one": "invitationNewWorkspaceMail.invitationUnit.month.one",
                "two": "invitationNewWorkspaceMail.invitationUnit.month.two",
                "index": "invitationNewWorkspaceMail.invitationUnit.month"
            },
            "week": {
                "one": "invitationNewWorkspaceMail.invitationUnit.week.one",
                "two": "invitationNewWorkspaceMail.invitationUnit.week.two",
                "index": "invitationNewWorkspaceMail.invitationUnit.week"
            }
        },
        "send": "invitationNewWorkspaceMail.send",
        "to": "invitationNewWorkspaceMail.to"
    },
    "mailSetting": {
        "aISecretSealTitle": {
            "index": "mailSetting.aISecretSealTitle.index"
        },
        "email": {
            "index": "mailSetting.email.index"
        },
        "endpoint": {
            "index": "mailSetting.endpoint.index"
        },
        "index": "mailSetting.index",
        "radio": {
            "index": "mailSetting.radio.index",
            "radioBox1": "mailSetting.radio.radioBox1",
            "radioBox2": "mailSetting.radio.radioBox2",
            "radioBox3": "mailSetting.radio.radioBox3"
        },
        "siosCovas": {
            "index": "mailSetting.siosCovas.index"
        }
    },
    "manageShareDevice": {
        "accountStatus": {
            "index": "manageShareDevice.accountStatus.index"
        },
        "action": {
            "index": "manageShareDevice.action.index"
        },
        "applyToRegisterButton": {
            "index": "manageShareDevice.applyToRegisterButton.index"
        },
        "back": {
            "index": "manageShareDevice.back.index"
        },
        "collective": {
            "csvtitle": "manageShareDevice.collective.csvtitle",
            "desc1": "manageShareDevice.collective.desc1",
            "desc2": "manageShareDevice.collective.desc2",
            "desc3": "manageShareDevice.collective.desc3",
            "description": "manageShareDevice.collective.description",
            "noneFile": "manageShareDevice.collective.noneFile",
            "please": "manageShareDevice.collective.please",
            "process": "manageShareDevice.collective.process",
            "register": "manageShareDevice.collective.register",
            "select": "manageShareDevice.collective.select",
            "template": "manageShareDevice.collective.template",
            "title": "manageShareDevice.collective.title",
            "upload": "manageShareDevice.collective.upload"
        },
        "deadline": {
            "index": "manageShareDevice.deadline.index"
        },
        "deadlineTitle": {
            "index": "manageShareDevice.deadlineTitle.index"
        },
        "deadlineValue": {
            "index": "manageShareDevice.deadlineValue.index",
            "infinity": "manageShareDevice.deadlineValue.infinity"
        },
        "deleteDevice": {
            "index": "manageShareDevice.deleteDevice.index"
        },
        "deviceName": {
            "index": "manageShareDevice.deviceName.index"
        },
        "deviceStatus": {
            "index": "manageShareDevice.deviceStatus.index"
        },
        "edit": "manageShareDevice.edit",
        "email": {
            "index": "manageShareDevice.email.index"
        },
        "emailTitle": {
            "index": "manageShareDevice.emailTitle.index",
            "placeholder": "manageShareDevice.emailTitle.placeholder"
        },
        "everyoneCheck": {
            "index": "manageShareDevice.everyoneCheck.index"
        },
        "fullname": {
            "index": "manageShareDevice.fullname.index"
        },
        "index": "manageShareDevice.index",
        "memberCount": {
            "index": "manageShareDevice.memberCount.index"
        },
        "mfpName": {
            "index": "manageShareDevice.mfpName.index"
        },
        "mfpNumber": {
            "index": "manageShareDevice.mfpNumber.index"
        },
        "new": "manageShareDevice.new",
        "newDeviceTableTitle": {
            "index": "manageShareDevice.newDeviceTableTitle.index"
        },
        "noMembers": {
            "index": "manageShareDevice.noMembers.index"
        },
        "noRecord": {
            "index": "manageShareDevice.noRecord.index"
        },
        "noResult": {
            "index": "manageShareDevice.noResult.index"
        },
        "omitPin": {
            "index": "manageShareDevice.omitPin.index"
        },
        "phoneticName": {
            "index": "manageShareDevice.phoneticName.index"
        },
        "registerEveryonePin": {
            "index": "manageShareDevice.registerEveryonePin.index"
        },
        "registerMember": {
            "index": "manageShareDevice.registerMember.index"
        },
        "registerNewDevice": {
            "index": "manageShareDevice.registerNewDevice.index"
        },
        "registeredDatetime": {
            "index": "manageShareDevice.registeredDatetime.index"
        },
        "registeredPerson": {
            "index": "manageShareDevice.registeredPerson.index"
        },
        "registrationPin": {
            "index": "manageShareDevice.registrationPin.index"
        },
        "remainDays": {
            "index": "manageShareDevice.remainDays.index"
        },
        "resumeDevice": {
            "index": "manageShareDevice.resumeDevice.index"
        },
        "role": {
            "admin": "manageShareDevice.role.admin",
            "externalAdmin": "manageShareDevice.role.externalAdmin",
            "index": "manageShareDevice.role.index",
            "member": "manageShareDevice.role.member",
            "systemAdmin": "manageShareDevice.role.systemAdmin"
        },
        "saveChanges": {
            "index": "manageShareDevice.saveChanges.index"
        },
        "search": {
            "tips": "manageShareDevice.search.tips"
        },
        "selectTable": {
            "group": "manageShareDevice.selectTable.group",
            "user": "manageShareDevice.selectTable.user"
        },
        "status": {
            "active": "manageShareDevice.status.active",
            "suspended": "manageShareDevice.status.suspended"
        },
        "suspendDevice": {
            "index": "manageShareDevice.suspendDevice.index"
        },
        "table": {
            "displayedRowsArgs": "manageShareDevice.table.displayedRowsArgs",
            "rowsPerPage": "manageShareDevice.table.rowsPerPage"
        }
    },
    "memberAuth": {
        "index": "memberAuth.index",
        "passwordReset": "memberAuth.passwordReset",
        "recovery": {
            "content": "memberAuth.recovery.content",
            "tips": "memberAuth.recovery.tips",
            "title": "memberAuth.recovery.title"
        },
        "signin": "memberAuth.signin",
        "signinButton": "memberAuth.signinButton"
    },
    "memberAuthManagement": {
        "allowDomain": {
            "index": "memberAuthManagement.allowDomain.index"
        },
        "client": {
            "index": "memberAuthManagement.client.index"
        },
        "createDate": {
            "index": "memberAuthManagement.createDate.index"
        },
        "detail": {
            "index": "memberAuthManagement.detail.index"
        },
        "index": "memberAuthManagement.index",
        "link": {
            "createAuthOrder": "memberAuthManagement.link.createAuthOrder"
        },
        "orderConfirmation": {
            "abstract": "memberAuthManagement.orderConfirmation.abstract",
            "body": {
                "allowedDomain": "memberAuthManagement.orderConfirmation.body.allowedDomain",
                "footer": "memberAuthManagement.orderConfirmation.body.footer"
            },
            "index": "memberAuthManagement.orderConfirmation.index",
            "label": {
                "cc": "memberAuthManagement.orderConfirmation.label.cc",
                "mailBody": "memberAuthManagement.orderConfirmation.label.mailBody",
                "mailTitle": "memberAuthManagement.orderConfirmation.label.mailTitle",
                "to": "memberAuthManagement.orderConfirmation.label.to",
                "toAuth": "memberAuthManagement.orderConfirmation.label.toAuth"
            },
            "tips": {
                "to": "memberAuthManagement.orderConfirmation.tips.to"
            }
        },
        "orderCreate": {
            "button": {
                "back": "memberAuthManagement.orderCreate.button.back",
                "next": "memberAuthManagement.orderCreate.button.next",
                "preview": "memberAuthManagement.orderCreate.button.preview",
                "send": "memberAuthManagement.orderCreate.button.send"
            },
            "index": "memberAuthManagement.orderCreate.index",
            "stepOne": {
                "index": "memberAuthManagement.orderCreate.stepOne.index",
                "label": {
                    "allowedDomain": "memberAuthManagement.orderCreate.stepOne.label.allowedDomain",
                    "cloudConnection": "memberAuthManagement.orderCreate.stepOne.label.cloudConnection"
                }
            },
            "stepThree": {
                "expired": {
                    "day": "memberAuthManagement.orderCreate.stepThree.expired.day"
                },
                "index": "memberAuthManagement.orderCreate.stepThree.index",
                "label": {
                    "cc": "memberAuthManagement.orderCreate.stepThree.label.cc"
                },
                "placeholder": {
                    "expire": "memberAuthManagement.orderCreate.stepThree.placeholder.expire",
                    "mailBody": "memberAuthManagement.orderCreate.stepThree.placeholder.mailBody",
                    "mailTitle": "memberAuthManagement.orderCreate.stepThree.placeholder.mailTitle"
                },
                "subHeader": {
                    "body": "memberAuthManagement.orderCreate.stepThree.subHeader.body"
                },
                "tips": {
                    "mailBody": "memberAuthManagement.orderCreate.stepThree.tips.mailBody"
                }
            },
            "stepTwo": {
                "groupTable": {
                    "header": {
                        "groupName": "memberAuthManagement.orderCreate.stepTwo.groupTable.header.groupName",
                        "memberCount": "memberAuthManagement.orderCreate.stepTwo.groupTable.header.memberCount"
                    }
                },
                "index": "memberAuthManagement.orderCreate.stepTwo.index",
                "tab": {
                    "groupTable": "memberAuthManagement.orderCreate.stepTwo.tab.groupTable",
                    "userTable": "memberAuthManagement.orderCreate.stepTwo.tab.userTable"
                },
                "table": {
                    "haeder": {
                        "accountStatus": "memberAuthManagement.orderCreate.stepTwo.table.haeder.accountStatus",
                        "authStatus": "memberAuthManagement.orderCreate.stepTwo.table.haeder.authStatus",
                        "mail": "memberAuthManagement.orderCreate.stepTwo.table.haeder.mail",
                        "role": "memberAuthManagement.orderCreate.stepTwo.table.haeder.role"
                    },
                    "popupMenu": {
                        "account": {
                            "active": "memberAuthManagement.orderCreate.stepTwo.table.popupMenu.account.active",
                            "all": "memberAuthManagement.orderCreate.stepTwo.table.popupMenu.account.all",
                            "inActive": "memberAuthManagement.orderCreate.stepTwo.table.popupMenu.account.inActive"
                        },
                        "auth": {
                            "all": "memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.all",
                            "authed": "memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.authed",
                            "still": "memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.still"
                        },
                        "role": {
                            "all": "memberAuthManagement.orderCreate.stepTwo.table.popupMenu.role.all"
                        }
                    }
                },
                "tips": {
                    "sendSum": "memberAuthManagement.orderCreate.stepTwo.tips.sendSum"
                }
            },
            "validation": {
                "domain": "memberAuthManagement.orderCreate.validation.domain"
            }
        },
        "search": {
            "tips": "memberAuthManagement.search.tips"
        },
        "service": {
            "index": "memberAuthManagement.service.index"
        },
        "totalMember": {
            "index": "memberAuthManagement.totalMember.index"
        }
    },
    "memberAuthManagementDetail": {
        "action": {
            "adminLogin": "memberAuthManagementDetail.action.adminLogin",
            "sendGroupEmail": "memberAuthManagementDetail.action.sendGroupEmail"
        },
        "header": {
            "admin": "memberAuthManagementDetail.header.admin",
            "authStatus": "memberAuthManagementDetail.header.authStatus",
            "email": "memberAuthManagementDetail.header.email",
            "emailStatus": "memberAuthManagementDetail.header.emailStatus",
            "memberName": "memberAuthManagementDetail.header.memberName",
            "sendEmail": "memberAuthManagementDetail.header.sendEmail"
        },
        "index": "memberAuthManagementDetail.index",
        "requestInformation": {
            "allowedDomain": "memberAuthManagementDetail.requestInformation.allowedDomain",
            "clientName": "memberAuthManagementDetail.requestInformation.clientName",
            "cloud": "memberAuthManagementDetail.requestInformation.cloud",
            "requestedDate": "memberAuthManagementDetail.requestInformation.requestedDate"
        }
    },
    "memberDetail": {
        "dangerZone": {
            "emailConfirm": "memberDetail.dangerZone.emailConfirm",
            "index": "memberDetail.dangerZone.index",
            "removeAccount": "memberDetail.dangerZone.removeAccount",
            "warning": "memberDetail.dangerZone.warning"
        },
        "index": "memberDetail.index",
        "memberControl": {
            "agentSignIn": "memberDetail.memberControl.agentSignIn",
            "clooudSetting": "memberDetail.memberControl.clooudSetting",
            "deviceSetting": "memberDetail.memberControl.deviceSetting",
            "endpointSetting": "memberDetail.memberControl.endpointSetting",
            "resetPassword": "memberDetail.memberControl.resetPassword",
            "resumeAccount": "memberDetail.memberControl.resumeAccount",
            "suspendAccount": "memberDetail.memberControl.suspendAccount"
        },
        "memberSetting": {
            "admin": "memberDetail.memberSetting.admin",
            "avatarImage": "memberDetail.memberSetting.avatarImage",
            "index": "memberDetail.memberSetting.index",
            "member": "memberDetail.memberSetting.member",
            "role": "memberDetail.memberSetting.role",
            "save": "memberDetail.memberSetting.save",
            "userAdmin": "memberDetail.memberSetting.userAdmin"
        }
    },
    "memberInvitation": {
        "collectiveInvite": {
            "button": "memberInvitation.collectiveInvite.button",
            "csvfileformat": "memberInvitation.collectiveInvite.csvfileformat",
            "csvtemplate": "memberInvitation.collectiveInvite.csvtemplate",
            "csvtitle": "memberInvitation.collectiveInvite.csvtitle",
            "csvupload": "memberInvitation.collectiveInvite.csvupload",
            "descriptionTitle": "memberInvitation.collectiveInvite.descriptionTitle",
            "descthree": "memberInvitation.collectiveInvite.descthree",
            "desctwo": "memberInvitation.collectiveInvite.desctwo",
            "download": "memberInvitation.collectiveInvite.download",
            "enableNotice": {
                "checkbox": "memberInvitation.collectiveInvite.enableNotice.checkbox",
                "configmDialog": {
                    "message": "memberInvitation.collectiveInvite.enableNotice.configmDialog.message",
                    "title": "memberInvitation.collectiveInvite.enableNotice.configmDialog.title"
                },
                "description": "memberInvitation.collectiveInvite.enableNotice.description"
            },
            "exportedCsvtemplate": "memberInvitation.collectiveInvite.exportedCsvtemplate",
            "index": "memberInvitation.collectiveInvite.index",
            "noneFile": "memberInvitation.collectiveInvite.noneFile",
            "process": "memberInvitation.collectiveInvite.process",
            "uploadbutton": "memberInvitation.collectiveInvite.uploadbutton"
        },
        "index": "memberInvitation.index",
        "invidationDate": {
            "index": "memberInvitation.invidationDate.index"
        },
        "invidationTime": {
            "index": "memberInvitation.invidationTime.index"
        },
        "invidationUser": {
            "index": "memberInvitation.invidationUser.index"
        },
        "invitation": {
            "index": "memberInvitation.invitation.index"
        },
        "invitationLimit": {
            "day": {
                "one": "memberInvitation.invitationLimit.day.one",
                "two": "memberInvitation.invitationLimit.day.two",
                "index": "memberInvitation.invitationLimit.day"
            },
            "expired": "memberInvitation.invitationLimit.expired",
            "hour": {
                "one": "memberInvitation.invitationLimit.hour.one",
                "two": "memberInvitation.invitationLimit.hour.two",
                "index": "memberInvitation.invitationLimit.hour"
            },
            "index": "memberInvitation.invitationLimit.index",
            "minute": {
                "one": "memberInvitation.invitationLimit.minute.one",
                "two": "memberInvitation.invitationLimit.minute.two",
                "index": "memberInvitation.invitationLimit.minute"
            },
            "remain": "memberInvitation.invitationLimit.remain"
        },
        "invitationStatus": "memberInvitation.invitationStatus",
        "invitationUnit": {
            "day": {
                "one": "memberInvitation.invitationUnit.day.one",
                "two": "memberInvitation.invitationUnit.day.two",
                "index": "memberInvitation.invitationUnit.day"
            },
            "infinity": {
                "one": "memberInvitation.invitationUnit.infinity.one",
                "two": "memberInvitation.invitationUnit.infinity.two",
                "index": "memberInvitation.invitationUnit.infinity"
            },
            "month": {
                "one": "memberInvitation.invitationUnit.month.one",
                "two": "memberInvitation.invitationUnit.month.two",
                "index": "memberInvitation.invitationUnit.month"
            },
            "week": {
                "one": "memberInvitation.invitationUnit.week.one",
                "two": "memberInvitation.invitationUnit.week.two",
                "index": "memberInvitation.invitationUnit.week"
            }
        },
        "memberEmailAddress": {
            "helperText": {
                "duplicateError": "memberInvitation.memberEmailAddress.helperText.duplicateError"
            },
            "index": "memberInvitation.memberEmailAddress.index"
        },
        "operation": "memberInvitation.operation",
        "period": {
            "index": "memberInvitation.period.index"
        },
        "reInvitation": {
            "index": "memberInvitation.reInvitation.index"
        },
        "unSignup": {
            "index": "memberInvitation.unSignup.index"
        }
    },
    "memberManagement": {
        "accountState": {
            "index": "memberManagement.accountState.index"
        },
        "active": {
            "index": "memberManagement.active.index"
        },
        "addGroupMember": "memberManagement.addGroupMember",
        "addGroupMemberfromGroup": "memberManagement.addGroupMemberfromGroup",
        "deleteGroupMember": "memberManagement.deleteGroupMember",
        "deleteWorkspaceMember": "memberManagement.deleteWorkspaceMember",
        "groupList": {
            "groupList": {
                "create": "memberManagement.groupList.groupList.create",
                "group": {
                    "count": "memberManagement.groupList.groupList.group.count"
                },
                "menu": {
                    "delete": "memberManagement.groupList.groupList.menu.delete",
                    "settings": "memberManagement.groupList.groupList.menu.settings"
                },
                "search": {
                    "placeholder": "memberManagement.groupList.groupList.search.placeholder"
                },
                "title": "memberManagement.groupList.groupList.title"
            },
            "wsGroupList": {
                "title": "memberManagement.groupList.wsGroupList.title",
                "updateWsGroup": "memberManagement.groupList.wsGroupList.updateWsGroup"
            }
        },
        "inactive": {
            "index": "memberManagement.inactive.index"
        },
        "index": "memberManagement.index",
        "link": {
            "collective": "memberManagement.link.collective",
            "invite": "memberManagement.link.invite"
        },
        "modal": {
            "addGroup": {
                "groupAvatar": {
                    "title": "memberManagement.modal.addGroup.groupAvatar.title"
                },
                "groupName": {
                    "title": "memberManagement.modal.addGroup.groupName.title",
                    "validate": {
                        "duplicationGroupName": "memberManagement.modal.addGroup.groupName.validate.duplicationGroupName",
                        "invalidCharacter": "memberManagement.modal.addGroup.groupName.validate.invalidCharacter",
                        "limitGroupName": "memberManagement.modal.addGroup.groupName.validate.limitGroupName",
                        "reservedGroupName": "memberManagement.modal.addGroup.groupName.validate.reservedGroupName",
                        "undefinedGroupName": "memberManagement.modal.addGroup.groupName.validate.undefinedGroupName"
                    }
                },
                "title": "memberManagement.modal.addGroup.title"
            },
            "addGroupMember": {
                "addMember": {
                    "title": "memberManagement.modal.addGroupMember.addMember.title"
                },
                "selectGroup": {
                    "placeholder": "memberManagement.modal.addGroupMember.selectGroup.placeholder",
                    "searchGroup": "memberManagement.modal.addGroupMember.selectGroup.searchGroup",
                    "title": "memberManagement.modal.addGroupMember.selectGroup.title",
                    "validate": {
                        "selectedGroup": "memberManagement.modal.addGroupMember.selectGroup.validate.selectedGroup"
                    }
                },
                "title": "memberManagement.modal.addGroupMember.title",
                "titleOpenbyGroup": "memberManagement.modal.addGroupMember.titleOpenbyGroup"
            },
            "deleteGroup": {
                "description": "memberManagement.modal.deleteGroup.description",
                "title": "memberManagement.modal.deleteGroup.title"
            },
            "deleteGroupMember": {
                "deleteMember": {
                    "title": "memberManagement.modal.deleteGroupMember.deleteMember.title"
                },
                "description": "memberManagement.modal.deleteGroupMember.description",
                "title": "memberManagement.modal.deleteGroupMember.title"
            },
            "deleteWorkspaceMember": {
                "deleteMember": {
                    "title": "memberManagement.modal.deleteWorkspaceMember.deleteMember.title"
                },
                "description": "memberManagement.modal.deleteWorkspaceMember.description",
                "title": "memberManagement.modal.deleteWorkspaceMember.title"
            },
            "settingGroup": {
                "groupAvatar": {
                    "title": "memberManagement.modal.settingGroup.groupAvatar.title"
                },
                "groupName": {
                    "title": "memberManagement.modal.settingGroup.groupName.title"
                },
                "title": "memberManagement.modal.settingGroup.title"
            }
        },
        "operation": "memberManagement.operation",
        "role": {
            "index": "memberManagement.role.index"
        },
        "search": "memberManagement.search",
        "searchFromGroupMember": "memberManagement.searchFromGroupMember",
        "selectedMenerNum": "memberManagement.selectedMenerNum"
    },
    "memberSignup": {
        "agreement": {
            "message": "memberSignup.agreement.message"
        },
        "avatar": "memberSignup.avatar",
        "index": "memberSignup.index",
        "invited": "memberSignup.invited",
        "join": "memberSignup.join",
        "language": "memberSignup.language",
        "next": "memberSignup.next",
        "workspace": "memberSignup.workspace"
    },
    "mfp": {
        "addShareDevice": "mfp.addShareDevice",
        "delete": "mfp.delete",
        "deletePC": "mfp.deletePC",
        "locked": "mfp.locked",
        "machineNumber": "mfp.machineNumber",
        "model": "mfp.model",
        "save": "mfp.save",
        "tips": {
            "invalidDeleteMfpByRegistrationGroup": "mfp.tips.invalidDeleteMfpByRegistrationGroup"
        },
        "unlock": "mfp.unlock"
    },
    "mfpDefaultSetting": {
        "checkBox": {
            "deleting": "mfpDefaultSetting.checkBox.deleting",
            "doCutImage": "mfpDefaultSetting.checkBox.doCutImage",
            "specifyingTheSameWidth": "mfpDefaultSetting.checkBox.specifyingTheSameWidth"
        },
        "description": {
            "tabFour1": "mfpDefaultSetting.description.tabFour1",
            "tabFour2": "mfpDefaultSetting.description.tabFour2",
            "tabOne1": "mfpDefaultSetting.description.tabOne1",
            "tabOne2": "mfpDefaultSetting.description.tabOne2",
            "tabOne3": "mfpDefaultSetting.description.tabOne3",
            "tabOne4": "mfpDefaultSetting.description.tabOne4"
        },
        "indexDefault": "mfpDefaultSetting.indexDefault",
        "indexInit": "mfpDefaultSetting.indexInit",
        "labelFolder": {
            "copies": "mfpDefaultSetting.labelFolder.copies",
            "folder1": "mfpDefaultSetting.labelFolder.folder1",
            "folder2": "mfpDefaultSetting.labelFolder.folder2",
            "folder3": "mfpDefaultSetting.labelFolder.folder3",
            "folder4": "mfpDefaultSetting.labelFolder.folder4",
            "height": "mfpDefaultSetting.labelFolder.height",
            "noLayer": "mfpDefaultSetting.labelFolder.noLayer",
            "password": "mfpDefaultSetting.labelFolder.password",
            "width": "mfpDefaultSetting.labelFolder.width"
        },
        "labelSwitch": {
            "autoSelect": "mfpDefaultSetting.labelSwitch.autoSelect",
            "displayDividerLine": "mfpDefaultSetting.labelSwitch.displayDividerLine",
            "grayLevelAutomatically": "mfpDefaultSetting.labelSwitch.grayLevelAutomatically",
            "iconFolder": "mfpDefaultSetting.labelSwitch.iconFolder",
            "lockChange": "mfpDefaultSetting.labelSwitch.lockChange",
            "overwriteWarningScanning": "mfpDefaultSetting.labelSwitch.overwriteWarningScanning",
            "scanPreview": "mfpDefaultSetting.labelSwitch.scanPreview"
        },
        "message": {
            "aggregationOutputType": {
                "content2": "mfpDefaultSetting.message.aggregationOutputType.content2",
                "multiContent3": "mfpDefaultSetting.message.aggregationOutputType.multiContent3",
                "multiPdfContent1": "mfpDefaultSetting.message.aggregationOutputType.multiPdfContent1",
                "singleContent3": "mfpDefaultSetting.message.aggregationOutputType.singleContent3",
                "singlePdfContent1": "mfpDefaultSetting.message.aggregationOutputType.singlePdfContent1",
                "tiffContent1": "mfpDefaultSetting.message.aggregationOutputType.tiffContent1",
                "tiffJpegContent1": "mfpDefaultSetting.message.aggregationOutputType.tiffJpegContent1"
            },
            "business": {
                "content1": "mfpDefaultSetting.message.business.content1",
                "content2": "mfpDefaultSetting.message.business.content2",
                "sizeContent3": "mfpDefaultSetting.message.business.sizeContent3",
                "splitPaperContent3": "mfpDefaultSetting.message.business.splitPaperContent3"
            },
            "doCutImage": {
                "aggregation": "mfpDefaultSetting.message.doCutImage.aggregation",
                "batch": "mfpDefaultSetting.message.doCutImage.batch",
                "colorType": "mfpDefaultSetting.message.doCutImage.colorType",
                "content1": "mfpDefaultSetting.message.doCutImage.content1",
                "indefinite": "mfpDefaultSetting.message.doCutImage.indefinite",
                "outputType": "mfpDefaultSetting.message.doCutImage.outputType",
                "paperSide": "mfpDefaultSetting.message.doCutImage.paperSide",
                "resolution": "mfpDefaultSetting.message.doCutImage.resolution",
                "size": "mfpDefaultSetting.message.doCutImage.size"
            },
            "error": "mfpDefaultSetting.message.error",
            "lock": {
                "base": "mfpDefaultSetting.message.lock.base",
                "comma": "mfpDefaultSetting.message.lock.comma"
            },
            "outputTypeToColorType": {
                "content1": "mfpDefaultSetting.message.outputTypeToColorType.content1",
                "content2": "mfpDefaultSetting.message.outputTypeToColorType.content2",
                "content3": "mfpDefaultSetting.message.outputTypeToColorType.content3"
            },
            "previewToClearlightPDF": {
                "content1": "mfpDefaultSetting.message.previewToClearlightPDF.content1",
                "content2": "mfpDefaultSetting.message.previewToClearlightPDF.content2",
                "content3": "mfpDefaultSetting.message.previewToClearlightPDF.content3"
            },
            "questionConfirm": "mfpDefaultSetting.message.questionConfirm",
            "title": "mfpDefaultSetting.message.title",
            "type": {
                "blackAndWhiteType": "mfpDefaultSetting.message.type.blackAndWhiteType",
                "fullColor": "mfpDefaultSetting.message.type.fullColor",
                "grayScale": "mfpDefaultSetting.message.type.grayScale",
                "multiPage": "mfpDefaultSetting.message.type.multiPage",
                "singlePage": "mfpDefaultSetting.message.type.singlePage"
            },
            "undefinedOutputType": {
                "content2": "mfpDefaultSetting.message.undefinedOutputType.content2",
                "multiContent1": "mfpDefaultSetting.message.undefinedOutputType.multiContent1",
                "multiContent3": "mfpDefaultSetting.message.undefinedOutputType.multiContent3",
                "singleContent1": "mfpDefaultSetting.message.undefinedOutputType.singleContent1",
                "singleContent3": "mfpDefaultSetting.message.undefinedOutputType.singleContent3"
            }
        },
        "radioButton": {
            "automaticColorSelection": "mfpDefaultSetting.radioButton.automaticColorSelection",
            "blackWhiteCharacters": "mfpDefaultSetting.radioButton.blackWhiteCharacters",
            "blackWhiteCharactersCharts": "mfpDefaultSetting.radioButton.blackWhiteCharactersCharts",
            "blackWhitePhoto": "mfpDefaultSetting.radioButton.blackWhitePhoto",
            "blackWhiteTextPhoto": "mfpDefaultSetting.radioButton.blackWhiteTextPhoto",
            "center2": "mfpDefaultSetting.radioButton.center2",
            "clearlightPdf": "mfpDefaultSetting.radioButton.clearlightPdf",
            "confirmCompactPdf": {
                "multi": "mfpDefaultSetting.radioButton.confirmCompactPdf.multi",
                "single": "mfpDefaultSetting.radioButton.confirmCompactPdf.single"
            },
            "confirmPdf": {
                "multi": "mfpDefaultSetting.radioButton.confirmPdf.multi",
                "single": "mfpDefaultSetting.radioButton.confirmPdf.single"
            },
            "confirmTiff": {
                "multi": "mfpDefaultSetting.radioButton.confirmTiff.multi",
                "single": "mfpDefaultSetting.radioButton.confirmTiff.single"
            },
            "doNotErase": "mfpDefaultSetting.radioButton.doNotErase",
            "eraseCenter": "mfpDefaultSetting.radioButton.eraseCenter",
            "frameCenterErase": "mfpDefaultSetting.radioButton.frameCenterErase",
            "frameErase": "mfpDefaultSetting.radioButton.frameErase",
            "fullColorOcr": "mfpDefaultSetting.radioButton.fullColorOcr",
            "fullColorPhotoPaper": "mfpDefaultSetting.radioButton.fullColorPhotoPaper",
            "fullColorTextPhoto": "mfpDefaultSetting.radioButton.fullColorTextPhoto",
            "grayscale": "mfpDefaultSetting.radioButton.grayscale",
            "grayscaleOcr": "mfpDefaultSetting.radioButton.grayscaleOcr",
            "left2": "mfpDefaultSetting.radioButton.left2",
            "magazineBindingLeftOpen": "mfpDefaultSetting.radioButton.magazineBindingLeftOpen",
            "magazineBindingRightOpen": "mfpDefaultSetting.radioButton.magazineBindingRightOpen",
            "none": "mfpDefaultSetting.radioButton.none",
            "oneside": "mfpDefaultSetting.radioButton.oneside",
            "pdf": "mfpDefaultSetting.radioButton.pdf",
            "right2": "mfpDefaultSetting.radioButton.right2",
            "tiff": "mfpDefaultSetting.radioButton.tiff",
            "tiffJpeg": "mfpDefaultSetting.radioButton.tiffJpeg",
            "top2": "mfpDefaultSetting.radioButton.top2",
            "twoSidesUpperSideBinding": "mfpDefaultSetting.radioButton.twoSidesUpperSideBinding",
            "twosidesLeftSideBinding": "mfpDefaultSetting.radioButton.twosidesLeftSideBinding",
            "upperLeft": "mfpDefaultSetting.radioButton.upperLeft",
            "upperLeftDiagonal": "mfpDefaultSetting.radioButton.upperLeftDiagonal",
            "upperRight": "mfpDefaultSetting.radioButton.upperRight",
            "upperRightDiagonal": "mfpDefaultSetting.radioButton.upperRightDiagonal"
        },
        "switchButton": {
            "a3": "mfpDefaultSetting.switchButton.a3",
            "a4": "mfpDefaultSetting.switchButton.a4",
            "autoTraySelect": "mfpDefaultSetting.switchButton.autoTraySelect",
            "b4": "mfpDefaultSetting.switchButton.b4",
            "b5": "mfpDefaultSetting.switchButton.b5",
            "blackWhite": "mfpDefaultSetting.switchButton.blackWhite",
            "businessCard": "mfpDefaultSetting.switchButton.businessCard",
            "bypassTray": "mfpDefaultSetting.switchButton.bypassTray",
            "color": "mfpDefaultSetting.switchButton.color",
            "column": "mfpDefaultSetting.switchButton.column",
            "confirmA3": {
                "horizontal": "mfpDefaultSetting.switchButton.confirmA3.horizontal"
            },
            "confirmA4": {
                "horizontal": "mfpDefaultSetting.switchButton.confirmA4.horizontal",
                "vertical": "mfpDefaultSetting.switchButton.confirmA4.vertical"
            },
            "confirmB4": {
                "horizontal": "mfpDefaultSetting.switchButton.confirmB4.horizontal"
            },
            "confirmB5": {
                "horizontal": "mfpDefaultSetting.switchButton.confirmB5.horizontal",
                "vertical": "mfpDefaultSetting.switchButton.confirmB5.vertical"
            },
            "displayColumns1": "mfpDefaultSetting.switchButton.displayColumns1",
            "displayColumns2": "mfpDefaultSetting.switchButton.displayColumns2",
            "dpi": "mfpDefaultSetting.switchButton.dpi",
            "fourSheetToOnesheet": "mfpDefaultSetting.switchButton.fourSheetToOnesheet",
            "left2": "mfpDefaultSetting.switchButton.left2",
            "list": "mfpDefaultSetting.switchButton.list",
            "mixedSize": "mfpDefaultSetting.switchButton.mixedSize",
            "mixedSizeDiff": "mfpDefaultSetting.switchButton.mixedSizeDiff",
            "mixedSizeSame": "mfpDefaultSetting.switchButton.mixedSizeSame",
            "nDirection": "mfpDefaultSetting.switchButton.nDirection",
            "no": "mfpDefaultSetting.switchButton.no",
            "noSummary": "mfpDefaultSetting.switchButton.noSummary",
            "none": "mfpDefaultSetting.switchButton.none",
            "normal": "mfpDefaultSetting.switchButton.normal",
            "oneSide": "mfpDefaultSetting.switchButton.oneSide",
            "overwriteFileName": "mfpDefaultSetting.switchButton.overwriteFileName",
            "right2": "mfpDefaultSetting.switchButton.right2",
            "rotate90DegLeft": "mfpDefaultSetting.switchButton.rotate90DegLeft",
            "rotatingSort": "mfpDefaultSetting.switchButton.rotatingSort",
            "sadf": "mfpDefaultSetting.switchButton.sadf",
            "sameSize": "mfpDefaultSetting.switchButton.sameSize",
            "saveChildNumber": "mfpDefaultSetting.switchButton.saveChildNumber",
            "severalPagesDocs": "mfpDefaultSetting.switchButton.severalPagesDocs",
            "shiftSort": "mfpDefaultSetting.switchButton.shiftSort",
            "sort": "mfpDefaultSetting.switchButton.sort",
            "stack": "mfpDefaultSetting.switchButton.stack",
            "summarizeEach4sheets": "mfpDefaultSetting.switchButton.summarizeEach4sheets",
            "textOrientation": "mfpDefaultSetting.switchButton.textOrientation",
            "thick": "mfpDefaultSetting.switchButton.thick",
            "thin": "mfpDefaultSetting.switchButton.thin",
            "top2": "mfpDefaultSetting.switchButton.top2",
            "twoSheetToOneSheet": "mfpDefaultSetting.switchButton.twoSheetToOneSheet",
            "twoSideUpDown": "mfpDefaultSetting.switchButton.twoSideUpDown",
            "twosideRightleft": "mfpDefaultSetting.switchButton.twosideRightleft",
            "zDirection": "mfpDefaultSetting.switchButton.zDirection"
        },
        "tabTitle": {
            "tabFour": "mfpDefaultSetting.tabTitle.tabFour",
            "tabFourSP": "mfpDefaultSetting.tabTitle.tabFourSP",
            "tabOne": "mfpDefaultSetting.tabTitle.tabOne",
            "tabTree": "mfpDefaultSetting.tabTitle.tabTree",
            "tabTreeSP": "mfpDefaultSetting.tabTitle.tabTreeSP",
            "tabTwo": "mfpDefaultSetting.tabTitle.tabTwo",
            "tabTwoSP": "mfpDefaultSetting.tabTitle.tabTwoSP"
        },
        "title": {
            "automaticDetection": "mfpDefaultSetting.title.automaticDetection",
            "center": "mfpDefaultSetting.title.center",
            "colorBlackWhite": "mfpDefaultSetting.title.colorBlackWhite",
            "displayColumns": "mfpDefaultSetting.title.displayColumns",
            "displayForm": "mfpDefaultSetting.title.displayForm",
            "documentThickness": "mfpDefaultSetting.title.documentThickness",
            "documentType": "mfpDefaultSetting.title.documentType",
            "formSize": "mfpDefaultSetting.title.formSize",
            "formatFile": "mfpDefaultSetting.title.formatFile",
            "grayLevel": "mfpDefaultSetting.title.grayLevel",
            "left": "mfpDefaultSetting.title.left",
            "mixedSizeMode": "mfpDefaultSetting.title.mixedSizeMode",
            "multi": "mfpDefaultSetting.title.multi",
            "normalOperation": "mfpDefaultSetting.title.normalOperation",
            "oneSideTwosides": "mfpDefaultSetting.title.oneSideTwosides",
            "onlySelectPdf": "mfpDefaultSetting.title.onlySelectPdf",
            "paperFeedTray": "mfpDefaultSetting.title.paperFeedTray",
            "paperOrientation": "mfpDefaultSetting.title.paperOrientation",
            "paperSide": "mfpDefaultSetting.title.paperSide",
            "paperSize": "mfpDefaultSetting.title.paperSize",
            "printObjectFormat": "mfpDefaultSetting.title.printObjectFormat",
            "punch": "mfpDefaultSetting.title.punch",
            "removingShadowsFromScannedDocuments": "mfpDefaultSetting.title.removingShadowsFromScannedDocuments",
            "resolution": "mfpDefaultSetting.title.resolution",
            "right": "mfpDefaultSetting.title.right",
            "scanOperation": "mfpDefaultSetting.title.scanOperation",
            "selectFolder": "mfpDefaultSetting.title.selectFolder",
            "single": "mfpDefaultSetting.title.single",
            "size1": "mfpDefaultSetting.title.size1",
            "size2": "mfpDefaultSetting.title.size2",
            "size3": "mfpDefaultSetting.title.size3",
            "sortMethod": "mfpDefaultSetting.title.sortMethod",
            "specifyingPaperSize": "mfpDefaultSetting.title.specifyingPaperSize",
            "splitPaperFeeding": "mfpDefaultSetting.title.splitPaperFeeding",
            "staple": "mfpDefaultSetting.title.staple",
            "summary": "mfpDefaultSetting.title.summary",
            "theRangeOfASetOfData": "mfpDefaultSetting.title.theRangeOfASetOfData",
            "titleFolder": "mfpDefaultSetting.title.titleFolder",
            "undefined": "mfpDefaultSetting.title.undefined",
            "under": "mfpDefaultSetting.title.under",
            "up": "mfpDefaultSetting.title.up"
        },
        "valueFolder": {
            "folder1": "mfpDefaultSetting.valueFolder.folder1",
            "folder2": "mfpDefaultSetting.valueFolder.folder2",
            "folder3": "mfpDefaultSetting.valueFolder.folder3",
            "folder4": "mfpDefaultSetting.valueFolder.folder4"
        }
    },
    "migrationEndpoint": {
        "csvtitle": "migrationEndpoint.csvtitle",
        "desc1": "migrationEndpoint.desc1",
        "desc2": "migrationEndpoint.desc2",
        "description": "migrationEndpoint.description",
        "description1": "migrationEndpoint.description1",
        "description2": "migrationEndpoint.description2",
        "descriptionLink": "migrationEndpoint.descriptionLink",
        "import": "migrationEndpoint.import",
        "index": "migrationEndpoint.index",
        "migration": {
            "desc": "migrationEndpoint.migration.desc",
            "mode1": "migrationEndpoint.migration.mode1",
            "mode2": "migrationEndpoint.migration.mode2"
        },
        "noneFile": "migrationEndpoint.noneFile",
        "select": "migrationEndpoint.select",
        "title": "migrationEndpoint.title"
    },
    "modalLabel": {
        "mfp": {
            "device": "modalLabel.mfp.device",
            "machineNumber": "modalLabel.mfp.machineNumber",
            "model": "modalLabel.mfp.model",
            "pin": "modalLabel.mfp.pin",
            "registerFailed": "modalLabel.mfp.registerFailed",
            "registerNewDevice": "modalLabel.mfp.registerNewDevice"
        }
    },
    "modalTitle": {
        "cloudStorage": {
            "index": "modalTitle.cloudStorage.index"
        },
        "mfp": {
            "index": "modalTitle.mfp.index"
        }
    },
    "newSharedArticleCreate": {
        "index": "newSharedArticleCreate.index",
        "wizard1": {
            "index": "newSharedArticleCreate.wizard1.index",
            "next": {
                "index": "newSharedArticleCreate.wizard1.next.index"
            },
            "shareAll": {
                "index": "newSharedArticleCreate.wizard1.shareAll.index"
            },
            "sharingDeadline": {
                "index": "newSharedArticleCreate.wizard1.sharingDeadline.index"
            },
            "text": {
                "index": "newSharedArticleCreate.wizard1.text.index"
            },
            "titleOrUrl": {
                "index": "newSharedArticleCreate.wizard1.titleOrUrl.index"
            }
        },
        "wizard2": {
            "back": {
                "index": "newSharedArticleCreate.wizard2.back.index"
            },
            "index": "newSharedArticleCreate.wizard2.index",
            "next": {
                "index": "newSharedArticleCreate.wizard2.next.index"
            }
        },
        "wizard3": {
            "back": {
                "index": "newSharedArticleCreate.wizard3.back.index"
            },
            "index": "newSharedArticleCreate.wizard3.index",
            "share": {
                "index": "newSharedArticleCreate.wizard3.share.index"
            }
        }
    },
    "newWorkspaceSetting": {
        "adminSetting": "newWorkspaceSetting.adminSetting",
        "agreement": {
            "message": "newWorkspaceSetting.agreement.message"
        },
        "avatar": "newWorkspaceSetting.avatar",
        "index": "newWorkspaceSetting.index",
        "logo": "newWorkspaceSetting.logo",
        "organizationSetting": "newWorkspaceSetting.organizationSetting",
        "passwordSetting": "newWorkspaceSetting.passwordSetting",
        "userLanguage": "newWorkspaceSetting.userLanguage",
        "workspace": "newWorkspaceSetting.workspace",
        "workspaceDisplayName": "newWorkspaceSetting.workspaceDisplayName",
        "workspaceId": "newWorkspaceSetting.workspaceId",
        "workspaceLanguage": "newWorkspaceSetting.workspaceLanguage",
        "workspaceSetting": "newWorkspaceSetting.workspaceSetting"
    },
    "organizationInfo": {
        "address1": "organizationInfo.address1",
        "address2": "organizationInfo.address2",
        "country": "organizationInfo.country",
        "customerId": "organizationInfo.customerId",
        "index": "organizationInfo.index",
        "name": "organizationInfo.name",
        "phoneNumber": "organizationInfo.phoneNumber",
        "zipCode": "organizationInfo.zipCode"
    },
    "pageTitle": {
        "admin": {
            "allMenu": "pageTitle.admin.allMenu",
            "authManagement": "pageTitle.admin.authManagement",
            "cloudConnect": "pageTitle.admin.cloudConnect",
            "entryList": "pageTitle.admin.entryList",
            "extension": "pageTitle.admin.extension",
            "memberInvite": "pageTitle.admin.memberInvite",
            "memberManagement": "pageTitle.admin.memberManagement",
            "memberSite": "pageTitle.admin.memberSite",
            "menu": "pageTitle.admin.menu",
            "menuEntry": "pageTitle.admin.menuEntry",
            "registerDevice": "pageTitle.admin.registerDevice",
            "registrationEndpoint": "pageTitle.admin.registrationEndpoint",
            "shareDevice": "pageTitle.admin.shareDevice",
            "subWorkspaceInvite": "pageTitle.admin.subWorkspaceInvite",
            "subWorkspaceManagement": "pageTitle.admin.subWorkspaceManagement",
            "subWorkspaceMfps": "pageTitle.admin.subWorkspaceMfps",
            "toApp": "pageTitle.admin.toApp",
            "workspaceSetting": "pageTitle.admin.workspaceSetting"
        },
        "adminHeaderDescription": {
            "memberCollectiveInvite": "pageTitle.adminHeaderDescription.memberCollectiveInvite",
            "memberManagement": "pageTitle.adminHeaderDescription.memberManagement",
            "registrationEndpoint": "pageTitle.adminHeaderDescription.registrationEndpoint",
            "subWorkspaceInvite": "pageTitle.adminHeaderDescription.subWorkspaceInvite"
        },
        "announcement": {
            "destination": {
                "allmember": "pageTitle.announcement.destination.allmember",
                "index": "pageTitle.announcement.destination.index",
                "resend": "pageTitle.announcement.destination.resend",
                "system_root": "pageTitle.announcement.destination.system_root",
                "workspace": "pageTitle.announcement.destination.workspace"
            },
            "index": "pageTitle.announcement.index",
            "mailtext": "pageTitle.announcement.mailtext",
            "mailtitle": "pageTitle.announcement.mailtitle",
            "mailtitlePlaceholder": "pageTitle.announcement.mailtitlePlaceholder",
            "send": "pageTitle.announcement.send",
            "senderror": "pageTitle.announcement.senderror",
            "sendresult": {
                "failure": "pageTitle.announcement.sendresult.failure",
                "success": "pageTitle.announcement.sendresult.success"
            }
        },
        "auth": {
            "login": {
                "index": "pageTitle.auth.login.index",
                "verify": {
                    "index": "pageTitle.auth.login.verify.index"
                }
            }
        },
        "cloudStorage": {
            "index": "pageTitle.cloudStorage.index"
        },
        "dashboard": {
            "adminHeader": "pageTitle.dashboard.adminHeader",
            "adminIndex": "pageTitle.dashboard.adminIndex",
            "userHeader": "pageTitle.dashboard.userHeader",
            "userIndex": "pageTitle.dashboard.userIndex"
        },
        "endpoint": {
            "extension": "pageTitle.endpoint.extension",
            "extensionPrint": "pageTitle.endpoint.extensionPrint",
            "folder": "pageTitle.endpoint.folder",
            "index": "pageTitle.endpoint.index",
            "modalExtension": "pageTitle.endpoint.modalExtension",
            "operationTarget": "pageTitle.endpoint.operationTarget",
            "scan": "pageTitle.endpoint.scan",
            "scanDesc": "pageTitle.endpoint.scanDesc"
        },
        "manual": {
            "admin": {
                "index": "pageTitle.manual.admin.index",
                "url": "pageTitle.manual.admin.url"
            },
            "app": {
                "index": "pageTitle.manual.app.index",
                "url": "pageTitle.manual.app.url"
            }
        },
        "mfp": {
            "collective": "pageTitle.mfp.collective",
            "index": "pageTitle.mfp.index",
            "popupMfp": {
                "back": "pageTitle.mfp.popupMfp.back",
                "next": "pageTitle.mfp.popupMfp.next",
                "popoverMessage": "pageTitle.mfp.popupMfp.popoverMessage",
                "popupTitle": "pageTitle.mfp.popupMfp.popupTitle",
                "slide1": "pageTitle.mfp.popupMfp.slide1",
                "slide2": "pageTitle.mfp.popupMfp.slide2",
                "slide3": "pageTitle.mfp.popupMfp.slide3"
            }
        },
        "parts": {
            "index": "pageTitle.parts.index"
        },
        "privacyPolicy": {
            "index": "pageTitle.privacyPolicy.index"
        },
        "registrationEndpoint": {
            "index": "pageTitle.registrationEndpoint.index"
        },
        "setting": {
            "index": "pageTitle.setting.index"
        },
        "sharedCloudStorage": {
            "index": "pageTitle.sharedCloudStorage.index"
        },
        "sharedDevice": {
            "index": "pageTitle.sharedDevice.index"
        },
        "sharedEndpoint": {
            "extension": "pageTitle.sharedEndpoint.extension",
            "index": "pageTitle.sharedEndpoint.index"
        },
        "signInAnother": {
            "index": "pageTitle.signInAnother.index"
        },
        "signOut": {
            "index": "pageTitle.signOut.index"
        },
        "signOutExample": {
            "index": "pageTitle.signOutExample.index"
        },
        "spool": {
            "index": "pageTitle.spool.index"
        },
        "supporturl": {
            "common": {
                "index": "pageTitle.supporturl.common.index",
                "url": "pageTitle.supporturl.common.url"
            }
        },
        "tos": {
            "index": "pageTitle.tos.index"
        },
        "userProfile": {
            "index": "pageTitle.userProfile.index"
        }
    },
    "paramData": {
        "autoSelect": {
            "true": "paramData.autoSelect.true"
        },
        "folderDepth": {
            "type1": "paramData.folderDepth.type1",
            "type2": "paramData.folderDepth.type2",
            "type3": "paramData.folderDepth.type3",
            "type4": "paramData.folderDepth.type4"
        },
        "folderFormat": {
            "type0": "paramData.folderFormat.type0",
            "type1": "paramData.folderFormat.type1"
        },
        "folderTitle1": "paramData.folderTitle1",
        "folderTitle2": "paramData.folderTitle2",
        "folderTitle3": "paramData.folderTitle3",
        "folderTitle4": "paramData.folderTitle4",
        "iconVisible": {
            "true": "paramData.iconVisible.true"
        },
        "numberOfListDisplayColumns": {
            "type1": "paramData.numberOfListDisplayColumns.type1",
            "type2": "paramData.numberOfListDisplayColumns.type2"
        },
        "overwrite": {
            "type1": "paramData.overwrite.type1"
        },
        "scanOverwriteAlert": {
            "true": "paramData.scanOverwriteAlert.true"
        }
    },
    "passwordChange": {
        "change": {
            "index": "passwordChange.change.index"
        },
        "index": "passwordChange.index"
    },
    "passwordRecovery": {
        "change": "passwordRecovery.change",
        "content": "passwordRecovery.content"
    },
    "passwordReset": {
        "content": {
            "index": "passwordReset.content.index"
        },
        "index": "passwordReset.index"
    },
    "passwordSetting": {
        "content": {
            "index": "passwordSetting.content.index"
        },
        "index": "passwordSetting.index"
    },
    "pdfAttributeData": {
        "allowEdit": {
            "true": "pdfAttributeData.allowEdit.true"
        },
        "allowExtract": {
            "true": "pdfAttributeData.allowExtract.true"
        },
        "allowPrint": {
            "type0": "pdfAttributeData.allowPrint.type0",
            "type1": "pdfAttributeData.allowPrint.type1",
            "type2": "pdfAttributeData.allowPrint.type2"
        },
        "authorization": {
            "false": "pdfAttributeData.authorization.false",
            "true": "pdfAttributeData.authorization.true"
        },
        "encrypt": {
            "false": "pdfAttributeData.encrypt.false",
            "true": "pdfAttributeData.encrypt.true"
        },
        "encryptLevel": {
            "type128": "pdfAttributeData.encryptLevel.type128"
        }
    },
    "popoverWarning": {
        "hasBeenRemoved": "popoverWarning.hasBeenRemoved",
        "xstoragesAccountFail": "popoverWarning.xstoragesAccountFail"
    },
    "printAttributeData": {
        "color": {
            "type0": "printAttributeData.color.type0",
            "type1": "printAttributeData.color.type1",
            "type2": "printAttributeData.color.type2",
            "type3": "printAttributeData.color.type3",
            "type4": "printAttributeData.color.type4"
        },
        "copies": "printAttributeData.copies",
        "displayDoc": {
            "true": "printAttributeData.displayDoc.true"
        },
        "displayPdf": {
            "true": "printAttributeData.displayPdf.true"
        },
        "displayPpt": {
            "true": "printAttributeData.displayPpt.true"
        },
        "displayXls": {
            "true": "printAttributeData.displayXls.true"
        },
        "encrypt": {
            "false": "printAttributeData.encrypt.false",
            "true": "printAttributeData.encrypt.true"
        },
        "mediaSizeName": {
            "type1": "printAttributeData.mediaSizeName.type1",
            "type2": "printAttributeData.mediaSizeName.type2",
            "type3": "printAttributeData.mediaSizeName.type3",
            "type4": "printAttributeData.mediaSizeName.type4"
        },
        "mediaTray": {
            "type0": "printAttributeData.mediaTray.type0",
            "type1": "printAttributeData.mediaTray.type1"
        },
        "numberUp": "printAttributeData.numberUp",
        "presentationDirection": {
            "type0": "printAttributeData.presentationDirection.type0",
            "type1": "printAttributeData.presentationDirection.type1"
        },
        "punch": {
            "type1": "printAttributeData.punch.type1",
            "type2": "printAttributeData.punch.type2",
            "type3": "printAttributeData.punch.type3"
        },
        "separatorLine": {
            "true": "printAttributeData.separatorLine.true"
        },
        "sheetCollate": {
            "type0": "printAttributeData.sheetCollate.type0",
            "type1": "printAttributeData.sheetCollate.type1",
            "type2": "printAttributeData.sheetCollate.type2",
            "type3": "printAttributeData.sheetCollate.type3"
        },
        "sides": {
            "type0": "printAttributeData.sides.type0",
            "type1": "printAttributeData.sides.type1",
            "type2": "printAttributeData.sides.type2",
            "type3": "printAttributeData.sides.type3",
            "type4": "printAttributeData.sides.type4"
        },
        "staple": {
            "type1": "printAttributeData.staple.type1",
            "type2": "printAttributeData.staple.type2",
            "type3": "printAttributeData.staple.type3",
            "type4": "printAttributeData.staple.type4",
            "type5": "printAttributeData.staple.type5",
            "type6": "printAttributeData.staple.type6",
            "type7": "printAttributeData.staple.type7",
            "type8": "printAttributeData.staple.type8"
        }
    },
    "profileAcount": {
        "avatar": {
            "index": "profileAcount.avatar.index"
        },
        "button": {
            "save": "profileAcount.button.save"
        },
        "description": "profileAcount.description",
        "index": "profileAcount.index",
        "name": {
            "index": "profileAcount.name.index"
        },
        "notification": {
            "cloud": "profileAcount.notification.cloud",
            "index": "profileAcount.notification.index",
            "serviceError": "profileAcount.notification.serviceError",
            "timeline": "profileAcount.notification.timeline"
        },
        "passwordChange": {
            "index": "profileAcount.passwordChange.index"
        },
        "phonetic": {
            "index": "profileAcount.phonetic.index"
        }
    },
    "registrationEndpoint": {
        "create": {
            "emphasis": "registrationEndpoint.create.emphasis",
            "text": "registrationEndpoint.create.text"
        },
        "createAndRecreateDescription": {
            "body": "registrationEndpoint.createAndRecreateDescription.body",
            "end": "registrationEndpoint.createAndRecreateDescription.end",
            "link": "registrationEndpoint.createAndRecreateDescription.link"
        },
        "csvtitle": "registrationEndpoint.csvtitle",
        "desc1": "registrationEndpoint.desc1",
        "desc2": "registrationEndpoint.desc2",
        "description": "registrationEndpoint.description",
        "description1": "registrationEndpoint.description1",
        "description2": "registrationEndpoint.description2",
        "descriptionLink": "registrationEndpoint.descriptionLink",
        "descriptionUrl": "registrationEndpoint.descriptionUrl",
        "export": "registrationEndpoint.export",
        "index": "registrationEndpoint.index",
        "message": {
            "aboutRecreateAndCreate": {
                "desc1": "registrationEndpoint.message.aboutRecreateAndCreate.desc1",
                "desc2": "registrationEndpoint.message.aboutRecreateAndCreate.desc2",
                "desc3": "registrationEndpoint.message.aboutRecreateAndCreate.desc3",
                "desc4": "registrationEndpoint.message.aboutRecreateAndCreate.desc4",
                "desc5": "registrationEndpoint.message.aboutRecreateAndCreate.desc5",
                "desc6": "registrationEndpoint.message.aboutRecreateAndCreate.desc6",
                "desc7": "registrationEndpoint.message.aboutRecreateAndCreate.desc7",
                "title": "registrationEndpoint.message.aboutRecreateAndCreate.title"
            },
            "createNotification": {
                "desc1": "registrationEndpoint.message.createNotification.desc1",
                "desc2": "registrationEndpoint.message.createNotification.desc2",
                "desc3": "registrationEndpoint.message.createNotification.desc3",
                "title": "registrationEndpoint.message.createNotification.title"
            },
            "recreateNotification": {
                "desc1": "registrationEndpoint.message.recreateNotification.desc1",
                "desc2": "registrationEndpoint.message.recreateNotification.desc2",
                "desc3": "registrationEndpoint.message.recreateNotification.desc3",
                "title": "registrationEndpoint.message.recreateNotification.title"
            }
        },
        "noneFile": "registrationEndpoint.noneFile",
        "please": "registrationEndpoint.please",
        "process": "registrationEndpoint.process",
        "register": {
            "emphasis": "registrationEndpoint.register.emphasis",
            "text": "registrationEndpoint.register.text"
        },
        "select": "registrationEndpoint.select",
        "template": "registrationEndpoint.template",
        "title": "registrationEndpoint.title",
        "upload": "registrationEndpoint.upload"
    },
    "scanAttributeData": {
        "aggregation": {
            "true": "scanAttributeData.aggregation.true"
        },
        "autoDensity": {
            "true": "scanAttributeData.autoDensity.true"
        },
        "batch": {
            "true": "scanAttributeData.batch.true"
        },
        "clippedVertical": {
            "true": "scanAttributeData.clippedVertical.true"
        },
        "colorType": {
            "type0": "scanAttributeData.colorType.type0",
            "type1": "scanAttributeData.colorType.type1",
            "type2": "scanAttributeData.colorType.type2",
            "type3": "scanAttributeData.colorType.type3",
            "type4": "scanAttributeData.colorType.type4",
            "type5": "scanAttributeData.colorType.type5",
            "type6": "scanAttributeData.colorType.type6",
            "type7": "scanAttributeData.colorType.type7",
            "type8": "scanAttributeData.colorType.type8",
            "type9": "scanAttributeData.colorType.type9"
        },
        "colorTypeLock": {
            "true": "scanAttributeData.colorTypeLock.true"
        },
        "density": "scanAttributeData.density",
        "doCutImage": {
            "false": "scanAttributeData.doCutImage.false",
            "true": "scanAttributeData.doCutImage.true"
        },
        "documentThickness": {
            "type0": "scanAttributeData.documentThickness.type0",
            "type1": "scanAttributeData.documentThickness.type1",
            "type2": "scanAttributeData.documentThickness.type2"
        },
        "eraseBorder": {
            "true": "scanAttributeData.eraseBorder.true"
        },
        "eraseBorderWidthBottom": "scanAttributeData.eraseBorderWidthBottom",
        "eraseBorderWidthLeft": "scanAttributeData.eraseBorderWidthLeft",
        "eraseBorderWidthRight": "scanAttributeData.eraseBorderWidthRight",
        "eraseBorderWidthSame": "scanAttributeData.eraseBorderWidthSame",
        "eraseBorderWidthTop": "scanAttributeData.eraseBorderWidthTop",
        "eraseCenter": {
            "true": "scanAttributeData.eraseCenter.true"
        },
        "eraseCenterWidth": "scanAttributeData.eraseCenterWidth",
        "eraseSettingSame": {
            "true": "scanAttributeData.eraseSettingSame.true"
        },
        "mixedSize": {
            "true": "scanAttributeData.mixedSize.true"
        },
        "mixedSizeMode": {
            "type0": "scanAttributeData.mixedSizeMode.type0",
            "type1": "scanAttributeData.mixedSizeMode.type1"
        },
        "omitBlankPage": {
            "true": "scanAttributeData.omitBlankPage.true"
        },
        "outputType": {
            "type0": "scanAttributeData.outputType.type0",
            "type1": "scanAttributeData.outputType.type1",
            "type10": "scanAttributeData.outputType.type10",
            "type11": "scanAttributeData.outputType.type11",
            "type12": "scanAttributeData.outputType.type12",
            "type13": "scanAttributeData.outputType.type13",
            "type14": "scanAttributeData.outputType.type14",
            "type15": "scanAttributeData.outputType.type15",
            "type16": "scanAttributeData.outputType.type16",
            "type18": "scanAttributeData.outputType.type18",
            "type19": "scanAttributeData.outputType.type19",
            "type2": "scanAttributeData.outputType.type2",
            "type3": "scanAttributeData.outputType.type3",
            "type4": "scanAttributeData.outputType.type4",
            "type5": "scanAttributeData.outputType.type5",
            "type6": "scanAttributeData.outputType.type6",
            "type7": "scanAttributeData.outputType.type7",
            "type8": "scanAttributeData.outputType.type8",
            "type9": "scanAttributeData.outputType.type9"
        },
        "preset1Horizontal": "scanAttributeData.preset1Horizontal",
        "preset1Vertical": "scanAttributeData.preset1Vertical",
        "preset2Horizontal": "scanAttributeData.preset2Horizontal",
        "preset2Vertical": "scanAttributeData.preset2Vertical",
        "preset3Horizontal": "scanAttributeData.preset3Horizontal",
        "preset3Vertical": "scanAttributeData.preset3Vertical",
        "resolution": "scanAttributeData.resolution",
        "resolutionLock": {
            "true": "scanAttributeData.resolutionLock.true"
        },
        "rotate": {
            "true": "scanAttributeData.rotate.true"
        },
        "sadf": {
            "true": "scanAttributeData.sadf.true"
        },
        "scanBothSide": {
            "true": "scanAttributeData.scanBothSide.true"
        },
        "scanPreview": {
            "true": "scanAttributeData.scanPreview.true"
        },
        "scanSpread": {
            "true": "scanAttributeData.scanSpread.true"
        },
        "size": {
            "type0": "scanAttributeData.size.type0",
            "type1": "scanAttributeData.size.type1",
            "type10": "scanAttributeData.size.type10",
            "type11": "scanAttributeData.size.type11",
            "type12": "scanAttributeData.size.type12",
            "type13": "scanAttributeData.size.type13",
            "type14": "scanAttributeData.size.type14",
            "type15": "scanAttributeData.size.type15",
            "type2": "scanAttributeData.size.type2",
            "type3": "scanAttributeData.size.type3",
            "type4": "scanAttributeData.size.type4",
            "type5": "scanAttributeData.size.type5",
            "type6": "scanAttributeData.size.type6",
            "type7": "scanAttributeData.size.type7",
            "type8": "scanAttributeData.size.type8",
            "type9": "scanAttributeData.size.type9"
        }
    },
    "selectWorkspace": {
        "alreadySignin": {
            "index": "selectWorkspace.alreadySignin.index"
        },
        "index": "selectWorkspace.index",
        "next": {
            "index": "selectWorkspace.next.index"
        },
        "recovery": {
            "button": "selectWorkspace.recovery.button",
            "placeholder": "selectWorkspace.recovery.placeholder",
            "tips": "selectWorkspace.recovery.tips",
            "title": "selectWorkspace.recovery.title"
        },
        "signin": "selectWorkspace.signin",
        "workspaceId": {
            "index": "selectWorkspace.workspaceId.index"
        }
    },
    "sharedArticleCreateWizard": {
        "index": "sharedArticleCreateWizard.index",
        "wizard1": {
            "deadline": {
                "index": "sharedArticleCreateWizard.wizard1.deadline.index"
            },
            "next": {
                "index": "sharedArticleCreateWizard.wizard1.next.index"
            },
            "text": {
                "index": "sharedArticleCreateWizard.wizard1.text.index"
            },
            "titleOrUrl": {
                "index": "sharedArticleCreateWizard.wizard1.titleOrUrl.index"
            }
        },
        "wizard2": {
            "back": {
                "index": "sharedArticleCreateWizard.wizard2.back.index"
            },
            "index": "sharedArticleCreateWizard.wizard2.index",
            "next": {
                "index": "sharedArticleCreateWizard.wizard2.next.index"
            }
        },
        "wizard3": {
            "back": {
                "index": "sharedArticleCreateWizard.wizard3.back.index"
            },
            "index": "sharedArticleCreateWizard.wizard3.index",
            "next": {
                "index": "sharedArticleCreateWizard.wizard3.next.index"
            }
        },
        "wizard4": {
            "back": {
                "index": "sharedArticleCreateWizard.wizard4.back.index"
            },
            "index": "sharedArticleCreateWizard.wizard4.index",
            "share": {
                "index": "sharedArticleCreateWizard.wizard4.share.index"
            }
        }
    },
    "sharedArticleEditAdmin": {
        "deadline": {
            "index": "sharedArticleEditAdmin.deadline.index"
        },
        "index": "sharedArticleEditAdmin.index",
        "shareAll": {
            "index": "sharedArticleEditAdmin.shareAll.index"
        },
        "shareEndpoint": {
            "index": "sharedArticleEditAdmin.shareEndpoint.index"
        },
        "target": {
            "index": "sharedArticleEditAdmin.target.index"
        },
        "text": {
            "index": "sharedArticleEditAdmin.text.index"
        },
        "titleOrUrl": {
            "index": "sharedArticleEditAdmin.titleOrUrl.index"
        }
    },
    "sharedArticleEditMember": {
        "deadline": {
            "index": "sharedArticleEditMember.deadline.index"
        },
        "index": "sharedArticleEditMember.index",
        "shareEndpoint": {
            "index": "sharedArticleEditMember.shareEndpoint.index"
        },
        "shareMember": {
            "index": "sharedArticleEditMember.shareMember.index"
        },
        "target": {
            "index": "sharedArticleEditMember.target.index"
        },
        "text": {
            "index": "sharedArticleEditMember.text.index"
        },
        "titleOrUrl": {
            "index": "sharedArticleEditMember.titleOrUrl.index"
        }
    },
    "sharedEndpoint": {
        "extendedFeatureUp": "sharedEndpoint.extendedFeatureUp",
        "extensions": {
            "displayAndCloudConnect": "sharedEndpoint.extensions.displayAndCloudConnect"
        },
        "sharedEndpointDetail": "sharedEndpoint.sharedEndpointDetail",
        "sharedEndpointName": "sharedEndpoint.sharedEndpointName",
        "upload": "sharedEndpoint.upload"
    },
    "spool": {
        "button": {
            "addSpool": "spool.button.addSpool",
            "delete": "spool.button.delete",
            "download": "spool.button.download",
            "retry": "spool.button.retry"
        },
        "completed": {
            "index": "spool.completed.index"
        },
        "dateFormat": {
            "brief": "spool.dateFormat.brief",
            "index": "spool.dateFormat.index"
        },
        "download": {
            "index": "spool.download.index"
        },
        "downloaded": {
            "index": "spool.downloaded.index"
        },
        "downloading": {
            "index": "spool.downloading.index"
        },
        "error": {
            "download": "spool.error.download",
            "extension": "spool.error.extension",
            "upload": "spool.error.upload"
        },
        "extension": {
            "index": "spool.extension.index"
        },
        "index": "spool.index",
        "loading": {
            "index": "spool.loading.index"
        },
        "stopped": {
            "index": "spool.stopped.index"
        },
        "tabTitle": {
            "tabFour": "spool.tabTitle.tabFour",
            "tabOne": "spool.tabTitle.tabOne",
            "tabThree": "spool.tabTitle.tabThree",
            "tabTwo": "spool.tabTitle.tabTwo"
        },
        "title": {
            "device": "spool.title.device",
            "download": "spool.title.download",
            "error": "spool.title.error",
            "expiration": "spool.title.expiration",
            "file": "spool.title.file",
            "folder": "spool.title.folder",
            "owner": "spool.title.owner",
            "upload": "spool.title.upload"
        },
        "upload": {
            "index": "spool.upload.index"
        },
        "uploaded": {
            "index": "spool.uploaded.index"
        },
        "uploading": {
            "index": "spool.uploading.index"
        }
    },
    "subWorkspaceDetail": {
        "dangerPanel": {
            "index": "subWorkspaceDetail.dangerPanel.index",
            "removeWorkspace": "subWorkspaceDetail.dangerPanel.removeWorkspace",
            "warning": "subWorkspaceDetail.dangerPanel.warning",
            "workspaceIdConfirm": "subWorkspaceDetail.dangerPanel.workspaceIdConfirm"
        },
        "index": "subWorkspaceDetail.index",
        "settingPanel": {
            "contactEmail": {
                "index": "subWorkspaceDetail.settingPanel.contactEmail.index"
            },
            "enableSubWorkspace": {
                "index": "subWorkspaceDetail.settingPanel.enableSubWorkspace.index"
            },
            "language": {
                "index": "subWorkspaceDetail.settingPanel.language.index"
            },
            "logo": {
                "index": "subWorkspaceDetail.settingPanel.logo.index"
            },
            "save": "subWorkspaceDetail.settingPanel.save",
            "workspaceDisplayName": {
                "index": "subWorkspaceDetail.settingPanel.workspaceDisplayName.index"
            },
            "workspaceId": {
                "index": "subWorkspaceDetail.settingPanel.workspaceId.index"
            }
        },
        "signinPanel": {
            "active": {
                "index": "subWorkspaceDetail.signinPanel.active.index"
            },
            "inactive": {
                "index": "subWorkspaceDetail.signinPanel.inactive.index"
            },
            "instantActivate": {
                "failuerUser": "subWorkspaceDetail.signinPanel.instantActivate.failuerUser",
                "failureWorkspace": "subWorkspaceDetail.signinPanel.instantActivate.failureWorkspace",
                "index": "subWorkspaceDetail.signinPanel.instantActivate.index",
                "success": "subWorkspaceDetail.signinPanel.instantActivate.success"
            },
            "sendSignupEmailExplanation": {
                "index": "subWorkspaceDetail.signinPanel.sendSignupEmailExplanation.index"
            },
            "signin": {
                "index": "subWorkspaceDetail.signinPanel.signin.index"
            },
            "signupExternalAdmin": {
                "index": "subWorkspaceDetail.signinPanel.signupExternalAdmin.index"
            }
        }
    },
    "subWorkspaceManagement": {
        "index": "subWorkspaceManagement.index",
        "memberCount": "subWorkspaceManagement.memberCount",
        "operation": "subWorkspaceManagement.operation",
        "state": {
            "active": "subWorkspaceManagement.state.active",
            "inactive": "subWorkspaceManagement.state.inactive",
            "index": "subWorkspaceManagement.state.index"
        },
        "subWorkspace": {
            "disable": "subWorkspaceManagement.subWorkspace.disable",
            "enable": "subWorkspaceManagement.subWorkspace.enable",
            "index": "subWorkspaceManagement.subWorkspace.index"
        },
        "workspaceId": "subWorkspaceManagement.workspaceId",
        "workspaceName": "subWorkspaceManagement.workspaceName"
    },
    "subWorkspaceMfpManagement": {
        "detail": "subWorkspaceMfpManagement.detail",
        "displayId": "subWorkspaceMfpManagement.displayId",
        "index": "subWorkspaceMfpManagement.index",
        "mfpNumber": "subWorkspaceMfpManagement.mfpNumber",
        "mfpType": "subWorkspaceMfpManagement.mfpType",
        "organizationName": "subWorkspaceMfpManagement.organizationName"
    },
    "table": {
        "displayedRowsArgs": "table.displayedRowsArgs",
        "nextPage": "table.nextPage",
        "previousPage": "table.previousPage",
        "rowsPerPage": "table.rowsPerPage"
    },
    "toasts": {
        "code": {
            "errorCode": "toasts.code.errorCode"
        }
    },
    "unsubscribe": {
        "error": {
            "cause": {
                "unknown": "unsubscribe.error.cause.unknown"
            },
            "description": "unsubscribe.error.description",
            "manualLink": "unsubscribe.error.manualLink",
            "subTitle": "unsubscribe.error.subTitle"
        },
        "index": "unsubscribe.index",
        "success": {
            "description": "unsubscribe.success.description",
            "description2": "unsubscribe.success.description2",
            "subTitle": "unsubscribe.success.subTitle",
            "type": {
                "complete": "unsubscribe.success.type.complete",
                "error": "unsubscribe.success.type.error",
                "index": "unsubscribe.success.type.index"
            }
        }
    },
    "userDashboard": {
        "announce": {
            "index": "userDashboard.announce.index"
        },
        "collectFile": {
            "index": "userDashboard.collectFile.index"
        },
        "deliverFile": {
            "index": "userDashboard.deliverFile.index"
        },
        "index": "userDashboard.index",
        "menu": {
            "backToWorkspace": {
                "index": "userDashboard.menu.backToWorkspace.index"
            },
            "index": "userDashboard.menu.index",
            "invitaitonNewWorkspace": {
                "index": "userDashboard.menu.invitaitonNewWorkspace.index"
            },
            "invitationMember": {
                "index": "userDashboard.menu.invitationMember.index"
            },
            "manageShareDevice": {
                "index": "userDashboard.menu.manageShareDevice.index"
            },
            "manageSubWorkspace": {
                "index": "userDashboard.menu.manageSubWorkspace.index"
            },
            "memberManagement": {
                "index": "userDashboard.menu.memberManagement.index"
            },
            "signinOtherConsole": {
                "index": "userDashboard.menu.signinOtherConsole.index"
            },
            "signoutFrom": {
                "index": "userDashboard.menu.signoutFrom.index"
            },
            "workspaceSetting": {
                "index": "userDashboard.menu.workspaceSetting.index"
            }
        },
        "pullToRefresh": {
            "index": "userDashboard.pullToRefresh.index"
        },
        "shareToSubWorkspace": {
            "index": "userDashboard.shareToSubWorkspace.index"
        },
        "shareToSubWorkspacePanel": {
            "deadline": {
                "index": "userDashboard.shareToSubWorkspacePanel.deadline.index"
            },
            "display": {
                "index": "userDashboard.shareToSubWorkspacePanel.display.index"
            },
            "index": "userDashboard.shareToSubWorkspacePanel.index",
            "kind": {
                "announce": {
                    "index": "userDashboard.shareToSubWorkspacePanel.kind.announce.index"
                },
                "index": "userDashboard.shareToSubWorkspacePanel.kind.index"
            },
            "opreation": {
                "index": "userDashboard.shareToSubWorkspacePanel.opreation.index"
            }
        },
        "shareToWorkspace": {
            "index": "userDashboard.shareToWorkspace.index"
        },
        "shareToWorkspacePanel": {
            "deadline": {
                "index": "userDashboard.shareToWorkspacePanel.deadline.index"
            },
            "display": {
                "index": "userDashboard.shareToWorkspacePanel.display.index"
            },
            "index": "userDashboard.shareToWorkspacePanel.index",
            "kind": {
                "announce": {
                    "index": "userDashboard.shareToWorkspacePanel.kind.announce.index"
                },
                "index": "userDashboard.shareToWorkspacePanel.kind.index"
            },
            "opreation": {
                "index": "userDashboard.shareToWorkspacePanel.opreation.index"
            }
        },
        "supportPanel": {
            "contact": {
                "index": "userDashboard.supportPanel.contact.index"
            },
            "contactAnswerArticle": {
                "index": "userDashboard.supportPanel.contactAnswerArticle.index"
            },
            "deadline": {
                "index": "userDashboard.supportPanel.deadline.index"
            },
            "display": {
                "index": "userDashboard.supportPanel.display.index"
            },
            "index": "userDashboard.supportPanel.index",
            "kind": {
                "index": "userDashboard.supportPanel.kind.index"
            },
            "provider": {
                "index": "userDashboard.supportPanel.provider.index"
            }
        }
    },
    "validation": {
        "alreadyUses": "validation.alreadyUses",
        "cc": {
            "invalidDuplication": "validation.cc.invalidDuplication",
            "tooMany": "validation.cc.tooMany"
        },
        "csvemptyerror": "validation.csvemptyerror",
        "csvimporteoferror": "validation.csvimporteoferror",
        "csvimporterror": "validation.csvimporterror",
        "csvimporterrorBase": "validation.csvimporterrorBase",
        "csvmincolumnerror": "validation.csvmincolumnerror",
        "deviceLoginUser": "validation.deviceLoginUser",
        "email": "validation.email",
        "emailorEveryone": "validation.emailorEveryone",
        "incorrectFormat": "validation.incorrectFormat",
        "inputLimit": "validation.inputLimit",
        "invalidCharacterCode": "validation.invalidCharacterCode",
        "itemlistLimit": "validation.itemlistLimit",
        "just": "validation.just",
        "language": "validation.language",
        "memberEmail": "validation.memberEmail",
        "mfpNo": "validation.mfpNo",
        "mfpNumber": "validation.mfpNumber",
        "noUses": "validation.noUses",
        "notEnough": "validation.notEnough",
        "numeric": "validation.numeric",
        "outOfRange": "validation.outOfRange",
        "passwordPolicy": "validation.passwordPolicy",
        "pinPolicy": "validation.pinPolicy",
        "required": "validation.required",
        "requiredSometing": "validation.requiredSometing",
        "role": "validation.role",
        "tooLong": "validation.tooLong",
        "tooLongByte": "validation.tooLongByte",
        "tooLongInt": "validation.tooLongInt",
        "tooShort": "validation.tooShort",
        "wordlist": "validation.wordlist",
        "workspaceIdFormat": "validation.workspaceIdFormat"
    },
    "workspaceSetting": {
        "extensions": {
            "index": "workspaceSetting.extensions.index"
        },
        "image": "workspaceSetting.image",
        "index": "workspaceSetting.index",
        "language": {
            "index": "workspaceSetting.language.index"
        },
        "logo": {
            "index": "workspaceSetting.logo.index"
        },
        "memberAll": "workspaceSetting.memberAll",
        "passwordReset": {
            "index": "workspaceSetting.passwordReset.index"
        },
        "saml": {
            "index": "workspaceSetting.saml.index"
        },
        "setting": "workspaceSetting.setting",
        "workspaceAuthTitle": {
            "index": "workspaceSetting.workspaceAuthTitle.index"
        },
        "workspaceDisplayId": {
            "index": "workspaceSetting.workspaceDisplayId.index"
        },
        "workspaceDisplayName": {
            "index": "workspaceSetting.workspaceDisplayName.index"
        }
    }
}