import * as schema from '@/bundles/schema/typescript/schema';

// apiから受け取る想定のデータのダミー
export const dummyList: schema.V1EndpointsUpdateResponse = {
    defaultSettings: [
        {
            pdfAttributeData: {
                allowEdit: true,
                allowExtract: true,
                allowPrint: 0,
                authorization: true,
                authorizationPassword: 'MbWWnZ3GFDIz6EVP5oD6Lw==',
                encrypt: true,
                encryptLevel: 128,
                encryptPassword: 'MbWWnZ3GFDIz6EVP5oD6Lw==',
            },
            printAttributeData: {
                color: 1,
                copies: 20,
                encrypt: true,
                mediaSizeName: 4,
                mediaTray: 1,
                numberUp: 2,
                presentationDirection: 1,
                punch: 2,
                separatorLine: true,
                sheetCollate: 3,
                sides: 4,
                staple: 8,
            },
            scanAttributeData: {
                aggregation: true,
                autoDensity: true,
                batch: true,
                clippedVertical: true,
                colorType: 7,
                density: 8,
                eraseBorder: true,
                eraseBorderWidthBottom: 90,
                eraseBorderWidthLeft: 80,
                eraseBorderWidthRight: 70,
                eraseBorderWidthSame: 90,
                eraseBorderWidthTop: 99,
                eraseCenter: true,
                eraseCenterWidth: 12,
                eraseSettingSame: true,
                mixedSize: true,
                outputType: 18,
                preset1Horizontal: 243,
                preset1Vertical: 221,
                preset2Horizontal: 254,
                preset2Vertical: 224,
                preset3Horizontal: 666,
                preset3Vertical: 981,
                resolution: 200,
                rotate: true,
                sadf: true,
                scanBothSide: true,
                scanSpread: true,
                scanPreview: true,
                size: 14,
            },
            mfpInfo: {
                deviceName: 'FLOOR-4',
                mfpId: '303030303030303030303031',
                type: 'QuickScan/4.80',
            },
            isExistDefault: false,
            createdAt: '2019/07/08',
        },
        {
            pdfAttributeData: {
                allowEdit: true,
                allowExtract: true,
                allowPrint: 0,
                authorization: true,
                authorizationPassword: 'MbWWnZ3GFDIz6EVP5oD6Lw==',
                encrypt: false,
                encryptLevel: 128,
                encryptPassword: 'MbWWnZ3GFDIz6EVP5oD6Lw==',
            },
            printAttributeData: {
                color: 1,
                copies: 20,
                encrypt: true,
                mediaSizeName: 4,
                mediaTray: 1,
                numberUp: 2,
                presentationDirection: 1,
                punch: 2,
                separatorLine: false,
                sheetCollate: 3,
                sides: 4,
                staple: 8,
            },
            scanAttributeData: {
                aggregation: false,
                autoDensity: true,
                batch: true,
                // clippedVertical: true,
                // colorType: 7,
                // density: 8,
                // eraseBorder: true,
                // eraseBorderWidthBottom: 90,
                // eraseBorderWidthLeft: 80,
                // eraseBorderWidthRight: 70,
                // eraseBorderWidthSame: 90,
                // eraseBorderWidthTop: 99,
                // eraseCenter: true,
                // eraseCenterWidth: 12,
                // eraseSettingSame: true,
                // mixedSize: true,
                // outputType: 18,
                // preset1Horizontal: 128,
                // preset1Vertical: 221,
                // preset2Horizontal: 254,
                // preset2Vertical: 254,
                // preset3Horizontal: 666,
                // preset3Vertical: 431,
                // resolution: 200,
                // rotate: true,
                // sadf: true,
                // scanBothSide: true,
                // scanSpread: true,
                // scanPreview: true,
                // size: 14,
            },
            mfpInfo: {
                deviceName: 'FLOOR-5',
                mfpId: '303030303030303030303032',
                type: 'QuickScan/5.80',
            },
            isExistDefault: true,
            createdAt: '2019/07/09',
        },
        {
            pdfAttributeData: {
                allowEdit: true,
                allowExtract: true,
                allowPrint: 0,
                authorization: true,
                authorizationPassword: 'MbWWnZ3GFDIz6EVP5oD6Lw==',
                encrypt: false,
                encryptLevel: 128,
                encryptPassword: 'MbWWnZ3GFDIz6EVP5oD6Lw==',
            },
            printAttributeData: {
                color: 1,
                copies: 20,
                encrypt: true,
                mediaSizeName: 4,
                mediaTray: 1,
                numberUp: 2,
                presentationDirection: 1,
                punch: 2,
                separatorLine: false,
                sheetCollate: 3,
                sides: 4,
                staple: 8,
            },
            scanAttributeData: {
                aggregation: false,
                autoDensity: true,
                batch: true,
                clippedVertical: true,
                colorType: 7,
                density: 8,
                eraseBorder: true,
                eraseBorderWidthBottom: 90,
                eraseBorderWidthLeft: 80,
                eraseBorderWidthRight: 70,
                eraseBorderWidthSame: 90,
                eraseBorderWidthTop: 99,
                eraseCenter: true,
                eraseCenterWidth: 12,
                eraseSettingSame: true,
                mixedSize: true,
                outputType: 18,
                preset1Horizontal: 129,
                preset1Vertical: 221,
                preset2Horizontal: 465,
                preset2Vertical: 224,
                preset3Horizontal: 666,
                preset3Vertical: 465,
                resolution: 200,
                rotate: true,
                sadf: true,
                scanBothSide: true,
                scanSpread: true,
                scanPreview: true,
                size: 14,
            },
            mfpInfo: {
                deviceName: 'FLOOR-6',
                mfpId: '303030303030303030303033',
                type: 'Easy Scan',
            },
            isExistDefault: false,
            createdAt: '2019/07/10',
        },
        {
            pdfAttributeData: {
                allowEdit: true,
                allowExtract: true,
                allowPrint: 0,
                authorization: true,
                authorizationPassword: 'MbWWnZ3GFDIz6EVP5oD6Lw==',
                encrypt: false,
                encryptLevel: 128,
                encryptPassword: 'MbWWnZ3GFDIz6EVP5oD6Lw==',
            },
            printAttributeData: {
                color: 1,
                copies: 20,
                encrypt: true,
                mediaSizeName: 4,
                mediaTray: 1,
                numberUp: 2,
                presentationDirection: 1,
                punch: 2,
                separatorLine: false,
                sheetCollate: 3,
                sides: 4,
                staple: 8,
            },
            scanAttributeData: {
                aggregation: false,
                autoDensity: true,
                batch: true,
                clippedVertical: true,
                colorType: 7,
                density: 8,
                eraseBorder: true,
                eraseBorderWidthBottom: 90,
                eraseBorderWidthLeft: 80,
                eraseBorderWidthRight: 70,
                eraseBorderWidthSame: 90,
                eraseBorderWidthTop: 99,
                eraseCenter: true,
                eraseCenterWidth: 12,
                eraseSettingSame: true,
                mixedSize: true,
                outputType: 18,
                preset1Horizontal: 233,
                preset1Vertical: 221,
                preset2Horizontal: 224,
                preset2Vertical: 224,
                preset3Horizontal: 666,
                preset3Vertical: 454,
                resolution: 200,
                rotate: true,
                sadf: true,
                scanBothSide: true,
                scanSpread: true,
                scanPreview: true,
                size: 14,
            },
            mfpInfo: {
                deviceName: 'FLOOR-7',
                mfpId: '303030303030303030303034',
                type: 'QuickScan/4.80',
            },
            isExistDefault: false,
        },
    ],
    docNameFormat: {
        dateFormat: 'y|M|d',
        fixed: ['A1', 'A2'],
        itemList: 'fixed1|file|date|fixed2',
        separator: '_',
        sequenceType: 1,
        lockEdit: false,
    },
    endpoint: {
        id: '303030303030303030303031',
        name: 'Endpoint1',
        iconName: 'icon001.png',
        buttonColor: schema.V1ObjectsEndpointsButtonColor.DarkKhaki,
        type: schema.V1ObjectsServiceEnum.Dropbox,
        metadata: {},
        cloudAccount: 'tora@sample.com',
        folderPath: '/abc/xx/de/',
        uploadable: true,
        downloadable: true,
        serviceCompleted: false,
        serviceError: false,
    },
    wordListData: ['AAA', 'BBB'],
    wordListData02: ['CCC', 'DDD'],
    wordListName01: '単語帳1',
    wordListName02: '単語帳2',
};

export default dummyList;
