import { default as React, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router';
import { Theme, Grid, InputAdornment, IconButton, TextField, Typography, Button, Checkbox, createStyles, withStyles, WithStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import * as schema from '@/bundles/schema/typescript/schema';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';
import logger from '@/common/utils/logger';
import locale from '@/common/utils/locale';
import { Variants } from '@/common/components/messages/CommonMessage';
import TermsMessage from '@/common/components/auth/common/TermsMessage';
import * as workspaceuser from '@/user/models/workspaceuser';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import routes from '@/user/constants/routes';
import { SignupContainer } from './SignupContainer';
import { VerifyContainer } from '../VerifyContainer';
import { LogoHeaderContainer } from '@/common/components/headers/LogoHeaderContainer';
import Error from '@/common/components/state/Error';
import Loading from '@/common/components/state/Loading';
import Saving from '@/common/components/state/Saving';
import LanguageSelect from '@/common/components/LanguageSelect';
import * as model from '@/user/models/auth/signup/Signup';
import ImageFileSelector from '@/common/components/ImageFileSelector';
import * as uploader from '@/common/utils/uploader';
import { MultiLineText } from '@/common/components/messages/MultiLineText';
import { parse } from 'query-string';
import { inviteType } from '@/common/constants/invite-type';

// 管理者が代理でサインアップしているかどうか取得
const parseSignupRequest = (given: string): schema.Type => {
    const got = parse(given);
    if (!got) {
        // @ts-ignore
        throw new Error('invalid');
    }
    if ('type' in got && got.type && typeof got.type === 'string' && got.type === inviteType.adminSignup) {
        return schema.Type.AdminSignup;
    } else {
        return schema.Type.MemberSignup;
    }
};

const styles = (theme: Theme) =>
    createStyles({
        heading: {
            margin: `0 auto ${theme.spacing.unit * 3}px`,
            wordWrap: 'break-word',
        },
        formControl: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        mb20: {
            marginBottom: 20,
        },
        link: {
            color: '#0d47a1',
        },
        button: {
            '&& span': {
                fontSize: '1.25rem',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('lg')]: {
                width: 'auto',
                minWidth: 240,
            },
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    ui?: UI;
    skipEffect?: boolean;
}

export const Component: React.FC<Props> = (props) => {
    const appContainer = UserAppContainer.useContainer();
    const vc = VerifyContainer.useContainer();
    const container = SignupContainer.useContainer();
    const lhc = LogoHeaderContainer.useContainer();
    const { classes } = props;
    const ui = useUI(props.ui);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        if (props.skipEffect || ui.current !== UI.Loading) {
            return;
        }
        logger.debug(`Signup useEffect Loading`);

        // TODO: SAML設定している 時はIdPの認証画面へ。 IdPからアバター、名前、ヨミガナが渡されてきたら、初期値として画面に埋める。
        const verify = vc.values;
        if (!verify.user || !verify.workspace) {
            ui.update(UI.Error);
            return;
        }

        // 言語設定
        locale.set(verify.user.language, verify.workspace.language || '');

        ui.update(UI.Loaded);
        container.setValues({
            ...container.values,
            name: verify.user.name,
            phoneticName: verify.user.phoneticName,
            mail: verify.user.invitationEmail,
            userLang: verify.user.language,
            adminName: verify.invitedUser ? verify.invitedUser : '',
            workspaceName: verify.workspace.displayName,
            displayId: verify.workspace.displayId ? verify.workspace.displayId : '',
            token: vc.values.token,
            active: verify.user.active,
            role: verify.user.role,
            enableSubWorkspace: !!verify.workspace.enableSubWorkspace,
            logoUrl: verify.workspace.logoUrl ? verify.workspace.logoUrl : '',
            workspaceId: verify.workspace.id!,
            avatarUrl: verify.user.avatarUrl,
            deviceLoginUser: verify.user.deviceLoginUser,
            pin: verify.user.pin,
            contactEmail: verify.user.contactEmail,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO: SAML判定
    const isSAML = (): boolean => {
        return false;
    };

    const handleNext = () => {
        logger.debug('handleNext');
        if (isSAML()) {
            // TODO: ワークスペースにSAML設定している時はそのままサインイン。
        }
        lhc.setBackFunc(() => {
            container.setScreenState(model.MemberSignupState.MemberSignup);
        });
        container.setScreenState(model.MemberSignupState.PasswordSetting);
    };

    const handleSignup = () => {
        logger.debug('handleSignup');
        ui.update(UI.Saving);
        lhc.setBackFunc();

        (async () => {
            try {
                const type = parseSignupRequest(window.location.search);
                const req = workspaceuser.parseMemberSignupRequest(container.values);
                if (type) req.type = type;
                const result = await workspaceuser.memberSignup(req);
                logger.debug('memberSignup result', result);

                let userObj = result.user;
                // アバター更新
                if (container.avatarDataUri) {
                    const avatarRes = await uploader.Upload(`${result.user.id}.png`, schema.BlobType.UserAvatar, result.id, container.avatarDataUri);
                    const workspaceuserUpdateRequest: schema.V1WorkspaceuserUpdateRequest = {
                        active: result.user.active,
                        avatarUrl: avatarRes.publicUrl,
                        language: result.user.language,
                        name: result.user.name,
                        phoneticName: result.user.phoneticName,
                        role: result.user.role,
                        deviceLoginUser: result.user.deviceLoginUser,
                        pin: result.user.pin,
                        contactEmail: result.user.contactEmail,
                    };
                    const updatedUser = await workspaceuser.updateUser(result.user.id, result.id, workspaceuserUpdateRequest);
                    userObj = updatedUser.user;
                }

                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.saved),
                    variant: Variants.success,
                });
                appContainer.onSignin(appContainer, result.id, result.workspace, userObj);
                ui.update(UI.Loaded);
                setLoggedIn(true);
            } catch (e) {
                lhc.setBackFunc(() => {
                    container.setScreenState(model.MemberSignupState.MemberSignup);
                });
                ui.update(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    return (
        <>
            {loggedIn ? (
                <Redirect to={routes.dashboard.index} />
            ) : (
                <>
                    {ui.current === UI.Loading && (
                        <div data-testid={UI.Loading}>
                            <Loading />
                        </div>
                    )}

                    {ui.current === UI.Loaded && (
                        <div data-testid={UI.Loaded}>
                            <Grid container spacing={32}>
                                {container.screenState === model.MemberSignupState.MemberSignup && (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography className={classes.heading} variant="h3" color="inherit">
                                                {locale.t(locale.keys.memberSignup.workspace, {
                                                    workspaceName: container.values.workspaceName,
                                                    workspaceId: container.values.displayId,
                                                })}
                                            </Typography>
                                            <Typography style={{ wordWrap: 'break-word' }}>{locale.t(locale.keys.memberSignup.invited, { adminName: container.values.adminName })}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <Typography align="left" color="textSecondary">
                                                {locale.t(locale.keys.newWorkspaceSetting.avatar)}
                                            </Typography>
                                            <ImageFileSelector
                                                seed={container.values.mail}
                                                uploadWidth={512}
                                                uploadHeight={512}
                                                mobileWidth={160}
                                                mobileHeight={160}
                                                pcWidth={160}
                                                pcHeight={160}
                                                defaultUrl={container.values.avatarUrl}
                                                logoDataUri={container.avatarDataUri}
                                                isAvatar={true}
                                                onLoaded={(datauri) => {
                                                    container.handleChangeAvatarDataUri(datauri);
                                                }}
                                                dependAppContainer={UserAppContainer.useContainer}
                                                editable={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={7}>
                                            <TextField
                                                className={classes.mb20}
                                                fullWidth
                                                label={locale.t(locale.keys.common.email)}
                                                margin="normal"
                                                value={container.values.mail}
                                                inputProps={{
                                                    readOnly: true,
                                                    style: { height: '100%' },
                                                }}
                                            />
                                            <TextField
                                                className={classes.mb20}
                                                fullWidth
                                                variant="filled"
                                                label={locale.t(locale.keys.common.name)}
                                                value={container.values.name}
                                                inputProps={{ style: { height: '100%' } }}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangeName(event.target.value)}
                                                error={container.handleValidateName() !== ''}
                                                helperText={container.handleValidateName()}
                                            />
                                            <TextField
                                                className={classes.mb20}
                                                fullWidth
                                                variant="filled"
                                                label={locale.t(locale.keys.common.phonetic)}
                                                value={container.values.phoneticName}
                                                inputProps={{ style: { height: '100%' } }}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
                                                    container.handleChangePhoneticName(event.target.value)
                                                }
                                                error={container.handleValidatePhoneticName() !== ''}
                                                helperText={container.handleValidatePhoneticName()}
                                            />
                                            <TextField
                                                className={classes.mb20}
                                                fullWidth
                                                variant="filled"
                                                label={locale.t(locale.keys.common.deviceLoginUser)}
                                                value={container.values.deviceLoginUser}
                                                inputProps={{ style: { height: '100%' } }}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
                                                    container.handleChangeDeviceLoginUser(event.target.value)
                                                }
                                                error={container.handleValidateDeviceLoginUser() !== ''}
                                                helperText={container.handleValidateDeviceLoginUser()}
                                            />
                                            <TextField
                                                className={classes.mb20}
                                                fullWidth
                                                variant="filled"
                                                label={locale.t(locale.keys.modalLabel.mfp.pin)}
                                                value={container.values.pin}
                                                inputProps={{ style: { height: '100%' } }}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangePin(event.target.value)}
                                                error={container.handleValidatePin() !== ''}
                                                helperText={container.handleValidatePin()}
                                            />

                                            <LanguageSelect
                                                disabled={false}
                                                value={container.values.userLang}
                                                label={locale.t(locale.keys.memberSignup.language)}
                                                handleChange={(event) => container.handleChangeUserLang(event.currentTarget.value)}
                                                handleValidate={() => container.handleValidateUserLang()}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                label={locale.t(locale.keys.common.contactEmail)}
                                                value={container.values.contactEmail}
                                                inputProps={{ style: { height: '100%' } }}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
                                                    container.handleChangeContactEmail(event.target.value)
                                                }
                                                error={container.handleValidateContactEmail() !== ''}
                                                helperText={container.handleValidateContactEmail()}
                                            />
                                            <MultiLineText value={locale.t(locale.keys.common.contactEmailInfo)} align="left" color="textSecondary" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div>
                                                <Checkbox
                                                    checked={container.values.terms}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => container.handleChangeTerms(event.currentTarget.checked)}
                                                />
                                                <TermsMessage />
                                            </div>

                                            <Button className={classes.button} fullWidth variant="contained" disabled={container.buttonDisabled()} onClick={() => handleNext()}>
                                                {locale.t(locale.keys.memberSignup.next)}
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                                {container.screenState === model.MemberSignupState.PasswordSetting && (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography className={classes.heading} variant="h3" color="inherit">
                                                {locale.t(locale.keys.memberSignup.workspace, {
                                                    workspaceName: container.values.workspaceName,
                                                    workspaceId: container.values.displayId,
                                                })}
                                            </Typography>
                                            <Typography style={{ wordWrap: 'break-word' }}>{locale.t(locale.keys.memberSignup.invited, { adminName: container.values.adminName })}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                className={classes.mb20}
                                                type={container.values.showPassword ? 'text' : 'password'}
                                                fullWidth
                                                variant="filled"
                                                label={locale.t(locale.keys.common.password)}
                                                value={container.values.password}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangePassword(event.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="Toggle password visibility" onClick={() => container.handleClickShowPassword()}>
                                                                {container.values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: { style: { height: '100%' } },
                                                }}
                                                error={container.handleValidatePassword() !== ''}
                                                helperText={container.handleValidatePassword()}
                                            />
                                            <TextField
                                                className={classes.mb20}
                                                type={container.values.showPasswordConfirm ? 'text' : 'password'}
                                                fullWidth
                                                variant="filled"
                                                label={locale.t(locale.keys.common.passwordConfirm)}
                                                value={container.values.passwordConfirm}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
                                                    container.handleChangePasswordConfirm(event.target.value)
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="Toggle verify visibility" onClick={() => container.handleClickShowPasswordConfirm()}>
                                                                {container.values.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: { style: { height: '100%' } },
                                                }}
                                                error={container.handleValidatePasswordConfirm() !== ''}
                                                helperText={container.handleValidatePasswordConfirm()}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button className={classes.button} fullWidth variant="contained" disabled={container.savePasswordButtonDisabled()} onClick={() => handleSignup()}>
                                                {locale.t(locale.keys.memberSignup.join)}
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </div>
                    )}

                    {ui.current === UI.Saving && (
                        <div data-testid={UI.Saving}>
                            <Saving />
                        </div>
                    )}

                    {ui.current === UI.Error && (
                        <div data-testid={UI.Error}>
                            <Error />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default withStyles(styles)(withRouter(Component));
