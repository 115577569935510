import { default as React, useEffect } from 'react';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles, AppBar, Tabs, Tab, Typography, Grid, Avatar, Button } from '@material-ui/core';
import { SpoolContainer } from './SpoolContainer';
import { default as UI } from '@/common/constants/ui';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import * as errorHandler from '@/common/utils/errorHandler';
import * as schema from '@/bundles/schema/typescript/schema';
import { Variants } from '@/common/components/messages/CommonMessage';
import { Info, CheckCircle } from '@material-ui/icons';
import { default as imgs } from '@/common/img/mfp/index';
import * as webappUtil from '@/common/utils/webappUtil';
import Confirm from '@/common/components/Confirm/Confirm';
import moment from 'moment';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: -theme.spacing.unit,
            paddingBottom: theme.spacing.unit * 3,
            '&& span': {
                fontWeight: 500,
            },
        },
        buttonAdd: {
            borderRadius: 50,
            position: 'absolute',
            top: -50,
            right: 16,
            '&& span': {
                fontSize: 16,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
            height: 44,
        },
        fab: {
            position: 'fixed',
            bottom: 24,
            right: 24,
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        header: {
            display: 'table',
            width: 'auto',
            borderBottom: `3px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            marginBottom: 16,
            '&& span': {
                fontWeight: 'bold',
            },
            '&& button': {
                textTransform: 'unset',
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        tabs: {
            minHeight: 36,
            color: `${theme.palette.secondary.main}`,
            backgroundColor: 'white',
            textAlign: 'left',
            width: '25%',
        },
        dateTime: {
            fontSize: 12,
            lineHeight: '16px',
            color: '#666666',
            marginBottom: 10,
        },
        indicator: {
            display: 'none',
        },
        tabsContainer: {
            minHeight: 36,
        },
        tabSelected: {
            color: '#ffffff',
            backgroundColor: `${theme.palette.secondary.main}`,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
        },
        commonContainer: {
            padding: 16,
            marginBottom: 16,
            borderRadius: 4,
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
            width: `calc((100% - ${16 * 2}px) / 3)`,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        middleContainer: {
            padding: 16,
            marginBottom: 16,
            marginLeft: 16,
            marginRight: 16,
            borderRadius: 4,
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
            width: `calc((100% - ${16 * 2 + 1}px) / 3)`,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginLeft: 'unset',
                marginRight: 'unset',
            },
        },
        endpointAvatar: {
            color: '#fff',
            borderRadius: 0,
            width: 40,
            height: 40,
            border: 'solid 1px #dddddd',
        },
        imgStyle: {
            width: 'inherit',
            height: 'inherit',
            padding: 5,
        },
        endpointInfo: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: 16,
            lineHeight: '20px',
            color: '#444444',
            width: '100%',
            overflow: 'hidden',
        },
        endpointName: {
            whiteSpace: 'nowrap',
            overflowX: 'auto',
            overflowY: 'hidden',
            maxWidth: '100%',
            height: 28,
            paddingRight: 4,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
            '@supports (-ms-ime-align:auto)': {
                height: '42px',
            },
            scrollbarWidth: 'thin',
        },
        status: {
            whiteSpace: 'nowrap',
            overflowX: 'auto',
            overflowY: 'hidden',
            maxWidth: '100%',
            fontSize: 12,
            lineHeight: '16px',
            color: '#333333',
            marginBottom: 19,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
            // スクロールバー全体
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            // スクロールバーの軌道
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            // スクロールバーのスクロールハンドル
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
            '@supports (-ms-ime-align:auto)': {
                height: '42px',
            },
            scrollbarWidth: 'thin',
        },
        successIcon: {
            width: 14,
            height: 14,
            color: '#43a047',
            marginRight: 5,
            marginBottom: -2,
        },
        errorIcon: {
            width: 14,
            height: 14,
            color: '#c91227',
            marginRight: 5,
            marginBottom: -2,
        },
        errorTitle: {
            fontSize: 14,
            lineHeight: '20px',
            color: '#666666',
        },
        errorContent: {
            fontSize: 14,
            lineHeight: '20px',
            color: '#444444',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '100%',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
        },
        jobItem: {
            fontSize: 13,
            lineHeight: '20px',
            color: '#666666',
            textAlign: 'left',
        },
        itemTitle: {
            fontSize: 14,
            lineHeight: '20px',
            color: '#666666',
        },
        itemContent: {
            fontSize: 15,
            lineHeight: '26px',
            height: '34px',
            color: '#333333',
            marginBottom: 19,
            overflowX: 'auto',
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            paddingRight: 4,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
                maxHeight: '5px',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
            '@supports (-ms-ime-align:auto)': {
                height: '42px',
            },
            scrollbarWidth: 'thin',
        },
        errorContainer: {
            height: 50,
            width: '100%',
            overflow: 'auto',
            fontSize: 15,
            lineHeight: '26px',
            color: '#333333',
            marginBottom: 19,
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
        },
        fileContainer: {
            height: 30,
            overflow: 'auto',
            width: '100%',
            whiteSpace: 'nowrap',
            fontSize: 15,
            lineHeight: '26px',
            color: '#333333',
            marginBottom: 19,
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
            '@supports (-ms-ime-align:auto)': {
                height: '45px',
            },
            scrollbarWidth: 'thin',
        },
        fileName: {
            textAlign: 'left',
            maxWidth: '100%',
            fontSize: 15,
            [theme.breakpoints.down('md')]: {
                maxWidth: '100%',
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
            '&:last-child': {
                '@supports (-ms-ime-align:auto)': {
                    marginBottom: 8,
                },
            },
        },
        textButton: {
            padding: '6px 0',
        },
        emptySpoolerNote: {
            textAlign: 'left',
            fontSize: 16,
        },
        itemContainer: {
            overflow: 'hidden',
            width: '100%',
        },
    });

interface Props extends WithStyles<typeof styles> {
    theme: Theme;
    skipEffect?: boolean;
}

const a11yProps = (index: number) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};

const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const spoolContainer = SpoolContainer.useContainer();
    const appContainer = UserAppContainer.useContainer();

    const fetchMoreListItems = () => {
        if (!isFetching) {
            return;
        }
        if (!spoolContainer.spoolList || spoolContainer.spoolList.length === 0 || spoolContainer.isItemFull || spoolContainer.ui === UI.state.Loading) {
            setIsFetching(false);
            return;
        }
        spoolContainer.setUI(UI.state.Loading);
        setTimeout(async () => {
            try {
                await spoolContainer.getSpoolList(spoolContainer.tabIndex);
            } catch (e) {
                spoolContainer.setUI(UI.state.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
            spoolContainer.setUI(UI.state.Loaded);
            setIsFetching(false);
        }, 2000);
    };

    const { isFetching, setIsFetching } = appContainer.useInfiniteScroll(false, fetchMoreListItems);

    useEffect(() => {
        appContainer.updateLoadingState(spoolContainer.ui);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spoolContainer.ui]);

    useEffect(() => {
        void (async () => {
            try {
                await spoolContainer.getSpoolList(spoolContainer.tabIndex, true);
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        return () => {
            spoolContainer.setSpoolList([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTypeText = (processHistory: schema.V1ObjectsSpoolersProcessHistory[] | undefined) => {
        if (processHistory && processHistory[0] && processHistory[0].type === schema.V1ObjectsSpoolersProcessTypeEnum.Upload) {
            return locale.t(locale.keys.spool.title.upload);
        }
        return locale.t(locale.keys.spool.title.download);
    };

    const getStatusErrorText = (processHistory: schema.V1ObjectsSpoolersProcessHistory[] | undefined) => {
        if (processHistory === undefined) {
            return '';
        }
        for (const i in processHistory) {
            if (processHistory[i].result === schema.Result.Error && processHistory[i].type === schema.V1ObjectsSpoolersProcessTypeEnum.Upload) {
                return locale.t(locale.keys.spool.error.upload);
            }
            if (processHistory[i].result === schema.Result.Error && processHistory[i].type === schema.V1ObjectsSpoolersProcessTypeEnum.Download) {
                return locale.t(locale.keys.spool.error.download);
            }
            if (processHistory[i].result === schema.Result.Error && processHistory[i].type === schema.V1ObjectsSpoolersProcessTypeEnum.Extension) {
                return locale.t(locale.keys.spool.error.extension);
            }
        }
        return '';
    };

    const getStatusRunningText = (processHistory: schema.V1ObjectsSpoolersProcessHistory[] | undefined) => {
        if (processHistory && processHistory[0] && processHistory[0].type === schema.V1ObjectsSpoolersProcessTypeEnum.Upload) {
            return locale.t(locale.keys.spool.uploading.index);
        }
        return locale.t(locale.keys.spool.downloading.index);
    };

    const getStatusCompletedText = (processHistory: schema.V1ObjectsSpoolersProcessHistory[] | undefined) => {
        if (processHistory && processHistory[0] && processHistory[0].type === schema.V1ObjectsSpoolersProcessTypeEnum.Upload) {
            return locale.t(locale.keys.spool.uploaded.index);
        }
        return locale.t(locale.keys.spool.downloaded.index);
    };

    const getFiles = (files: string[]) => {
        const fileList = [];
        for (const i in files) {
            fileList.push(
                <Typography key={i} className={classes.fileName}>
                    {files[i]}
                </Typography>,
            );
        }
        return fileList;
    };

    const getSpoolProcessHistory = (processHistory: schema.V1ObjectsSpoolersProcessHistory[] | undefined) => {
        const spoolProcessHistoryList = [];
        if (processHistory === undefined) {
            return;
        }
        for (const i in processHistory) {
            if (processHistory[i] && processHistory[i].type === schema.V1ObjectsSpoolersProcessTypeEnum.Extension) {
                spoolProcessHistoryList.push(
                    <Typography key={i} className={classes.jobItem}>
                        {processHistory[i].result === schema.Result.Error ? <Info className={classes.errorIcon} /> : <CheckCircle className={classes.successIcon} />}
                        <span>{moment(processHistory[i].updatedAt).format(locale.t(locale.keys.spool.dateFormat.brief))}</span>&nbsp;
                        <span>{locale.t(locale.keys.spool.extension.index)}</span>&nbsp;&nbsp;
                        <span>{processHistory[i].name}</span>
                        {processHistory[i].result === schema.Result.Success && <span>ok</span>}
                    </Typography>,
                );
            } else {
                spoolProcessHistoryList.push(
                    <Typography key={i} className={classes.jobItem}>
                        {processHistory[i].result === schema.Result.Error ? <Info className={classes.errorIcon} /> : <CheckCircle className={classes.successIcon} />}
                        <span>{moment(processHistory[i].updatedAt).format(locale.t(locale.keys.spool.dateFormat.brief))}</span>&nbsp;
                        {processHistory[i].type === schema.V1ObjectsSpoolersProcessTypeEnum.Upload ? (
                            <span>{locale.t(locale.keys.spool.upload.index)}</span>
                        ) : (
                            <span>{locale.t(locale.keys.spool.download.index)}</span>
                        )}
                    </Typography>,
                );
            }
        }
        if (spoolProcessHistoryList.length > 0) {
            return (
                <Grid item xs={12} container direction="column" justify="flex-start" alignItems="flex-start">
                    {spoolProcessHistoryList}
                </Grid>
            );
        }
    };

    const getErrorContent = (processHistory: schema.V1ObjectsSpoolersProcessHistory[] | undefined) => {
        const errorContentList = [];
        if (processHistory === undefined) {
            return;
        }
        for (const i in processHistory) {
            if (schema.V1ObjectsSpoolersProcessTypeEnum.Extension === processHistory[i].type && processHistory[i].result === schema.Result.Error) {
                errorContentList.push(
                    <Typography key={i} className={classes.errorContent}>
                        <span>{processHistory[i].note}</span>
                    </Typography>,
                );
            }
        }
        if (errorContentList.length > 0) {
            return (
                <Grid item xs={12} container direction="column" justify="flex-start" alignItems="flex-start">
                    <Typography className={classes.errorTitle}>{locale.t(locale.keys.spool.title.error)}</Typography>
                    {errorContentList}
                </Grid>
            );
        }
    };

    const downloadAble = (spoolList: schema.V1ObjectsSpoolersIndex | undefined) => {
        if (!spoolList) {
            return false;
        }
        const processHistoryList = spoolList.processHistory;
        if (!processHistoryList) return false;
        for (const i in processHistoryList) {
            if (spoolList.processStatus === schema.ProcessStatus.Stopped && processHistoryList[i].type === schema.V1ObjectsSpoolersProcessTypeEnum.Upload) {
                return false;
            }
        }
        return true;
    };

    const list = [];
    for (const i in spoolContainer.spoolList) {
        list.push(
            <Grid key={i} item container className={Number(i) % 3 === 1 ? classes.middleContainer : classes.commonContainer}>
                <Grid item xs={12} container>
                    <Typography className={classes.dateTime}>{moment(spoolContainer.spoolList[i].createdAt).format(locale.t(locale.keys.spool.dateFormat.index))}</Typography>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={2} container>
                        <Avatar
                            className={classes.endpointAvatar}
                            style={{ backgroundColor: webappUtil.getEnumKey(spoolContainer.spoolList[i].endpointBtnColor, schema.V1ObjectsEndpointsButtonColor) }}
                        >
                            <img alt="img" src={imgs.iconMfps[`${spoolContainer.spoolList[i].endpointIcon.split('.')[0]}`]} className={classes.imgStyle} />
                        </Avatar>
                    </Grid>
                    <Grid item xs={10} container direction="column" justify="flex-start" alignItems="flex-start">
                        <div className={classes.endpointInfo}>
                            <Typography className={classes.endpointName}>
                                {spoolContainer.spoolList[i].endpointName}
                                {getTypeText(spoolContainer.spoolList[i].processHistory)}
                            </Typography>
                        </div>
                        <Typography className={classes.status}>
                            {spoolContainer.spoolList[i].processStatus === schema.ProcessStatus.Stopped ? (
                                <>
                                    <Info className={classes.errorIcon} />
                                    <span>{locale.t(locale.keys.spool.stopped.index)}</span>
                                    <span>{getStatusErrorText(spoolContainer.spoolList[i].processHistory)}</span>
                                </>
                            ) : spoolContainer.spoolList[i].processStatus === schema.ProcessStatus.Completed ? (
                                <>
                                    <span>{locale.t(locale.keys.spool.completed.index)}</span>
                                    <span>{getStatusCompletedText(spoolContainer.spoolList[i].processHistory)}</span>
                                </>
                            ) : (
                                <span>{getStatusRunningText(spoolContainer.spoolList[i].processHistory)}</span>
                            )}
                        </Typography>
                    </Grid>
                    {spoolContainer.spoolList[i].processStatus === schema.ProcessStatus.Stopped && (
                        <>
                            <div className={classes.errorContainer}>{getSpoolProcessHistory(spoolContainer.spoolList[i].processHistory)}</div>
                            <div className={classes.errorContainer}>{getErrorContent(spoolContainer.spoolList[i].processHistory)}</div>
                        </>
                    )}
                </Grid>
                <Grid item xs={12} container direction="column" justify="flex-start" alignItems="flex-start" className={classes.itemContainer}>
                    <Typography className={classes.itemTitle}>{locale.t(locale.keys.spool.title.folder)}</Typography>
                    <Typography className={classes.itemContent}>{spoolContainer.spoolList[i].folder}</Typography>
                </Grid>
                <Grid item xs={12} container direction="column" justify="flex-start" alignItems="flex-start" className={classes.itemContainer}>
                    <Typography className={classes.itemTitle}>{locale.t(locale.keys.spool.title.file)}</Typography>
                    <div className={classes.fileContainer}>{getFiles(spoolContainer.spoolList[i].files)}</div>
                </Grid>
                <Grid item xs={12} container direction="column" justify="flex-start" alignItems="flex-start" className={classes.itemContainer}>
                    <Typography className={classes.itemTitle}>{locale.t(locale.keys.spool.title.device)}</Typography>
                    <Typography className={classes.itemContent}>{spoolContainer.spoolList[i].deviceName}</Typography>
                </Grid>
                <Grid item xs={12} container direction="column" justify="flex-start" alignItems="flex-start" className={classes.itemContainer}>
                    <Typography className={classes.itemTitle}>{locale.t(locale.keys.spool.title.expiration)}</Typography>
                    <Typography className={classes.itemContent}>{moment(spoolContainer.spoolList[i].expiryDate).format(locale.t(locale.keys.spool.dateFormat.index))}</Typography>
                </Grid>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-start">
                    <Confirm
                        isDisable={spoolContainer.tabIndex !== 1 || spoolContainer.spoolList[i].processStatus !== schema.ProcessStatus.Stopped}
                        value={spoolContainer.spoolList[i].id}
                        callBack={spoolContainer.confirmRemove}
                    >
                        <Button variant="contained" disabled={spoolContainer.tabIndex !== 1 || spoolContainer.spoolList[i].processStatus !== schema.ProcessStatus.Stopped}>
                            {locale.t(locale.keys.spool.button.delete)}
                        </Button>
                    </Confirm>
                    <Button
                        onClick={() => spoolContainer.handleClickDownload(i)}
                        disabled={spoolContainer.tabIndex !== 1 || downloadAble(spoolContainer.spoolList[i])}
                        classes={{ text: classes.textButton }}
                    >
                        {locale.t(locale.keys.spool.button.download)}
                    </Button>
                    {/* リトライボタン */}
                    <Button
                        onClick={() => spoolContainer.handleClickRetry(i)}
                        disabled={spoolContainer.tabIndex !== 1 || downloadAble(spoolContainer.spoolList[i])}
                        classes={{ text: classes.textButton }}
                    >
                        {locale.t(locale.keys.spool.button.retry)}
                    </Button>
                </Grid>
            </Grid>,
        );
    }

    return (
        <>
            {spoolContainer.ui === UI.state.Error && <div data-testid={UI.state.Error}>Error</div>}
            {(spoolContainer.ui === UI.state.Loaded || spoolContainer.ui === UI.state.Loading || props.skipEffect) && (
                <div className={props.classes.root}>
                    <AppBar position="static" className={classes.header}>
                        <Tabs
                            value={spoolContainer.tabIndex}
                            onChange={spoolContainer.handleChangeTabIndex}
                            classes={{ root: classes.tabsContainer, indicator: classes.indicator }}
                            variant="fullWidth"
                        >
                            <Tab
                                disabled={spoolContainer.ui === UI.state.Loading}
                                label={locale.t(locale.keys.spool.tabTitle.tabOne)}
                                {...a11yProps(0)}
                                className={classes.tabs}
                                classes={{ selected: classes.tabSelected }}
                            />
                            <Tab
                                disabled={spoolContainer.ui === UI.state.Loading}
                                label={locale.t(locale.keys.spool.tabTitle.tabTwo)}
                                {...a11yProps(1)}
                                className={classes.tabs}
                                classes={{ selected: classes.tabSelected }}
                            />
                            <Tab
                                disabled={spoolContainer.ui === UI.state.Loading}
                                label={locale.t(locale.keys.spool.tabTitle.tabThree)}
                                {...a11yProps(2)}
                                className={classes.tabs}
                                classes={{ selected: classes.tabSelected }}
                            />
                            <Tab
                                disabled={spoolContainer.ui === UI.state.Loading}
                                label={locale.t(locale.keys.spool.tabTitle.tabFour)}
                                {...a11yProps(3)}
                                className={classes.tabs}
                                classes={{ selected: classes.tabSelected }}
                            />
                        </Tabs>
                    </AppBar>
                    <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                        {(spoolContainer.ui === UI.state.Loaded || isFetching) && list}
                        {spoolContainer.ui === UI.state.Loaded && list.length === 0 && <div className={classes.emptySpoolerNote}>{locale.t(locale.keys.error.noDataAvailable)}</div>}
                        {/* <Button data-testid="modalOpenPC" variant="contained" className={classes.buttonAdd}>
                            <Add />
                            {locale.t(locale.keys.spool.button.addSpool)}
                        </Button>
                        <Fab data-testid="modalOpenSP" className={classes.fab} aria-label="Add">
                            <Add />
                        </Fab> */}
                    </Grid>
                    <Grid item xs={12}>
                        {isFetching && !spoolContainer.isItemFull && locale.t(locale.keys.spool.loading.index)}
                    </Grid>
                </div>
            )}
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
