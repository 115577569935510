import * as React from 'react';
import { Typography } from '@material-ui/core';
import locale from '@/common/utils/locale';
import { MfpSettingContainer } from './MfpSettingContainer';
import DialogBase from '@/common/components/Confirm/DialogBase';

interface Props {
    callBack: (value?: string) => void;
}

const DialogConfirmChanges: React.FC<Props> = (props: Props) => {
    const mfpSettingContainer = MfpSettingContainer.useContainer();

    const renderChildren = () => {
        return (
            <>
                {mfpSettingContainer.dialogObject.renderChildren ? (
                    mfpSettingContainer.dialogObject.renderChildren()
                ) : (
                    <>
                        {mfpSettingContainer.dialogObject.message1 && <Typography> {mfpSettingContainer.dialogObject.message1} </Typography>}
                        {mfpSettingContainer.dialogObject.message2 && <Typography> {mfpSettingContainer.dialogObject.message2} </Typography>}
                        {mfpSettingContainer.dialogObject.message3 && <Typography> {mfpSettingContainer.dialogObject.message3} </Typography>}
                        {mfpSettingContainer.dialogObject.message4 && <Typography> {mfpSettingContainer.dialogObject.message4} </Typography>}
                        <Typography>{mfpSettingContainer.dialogObject.lastMessage}</Typography>
                    </>
                )}
            </>
        );
    };

    return (
        <DialogBase
            callBack={props.callBack}
            onClose={mfpSettingContainer.handleCloseDialog}
            renderChildren={renderChildren}
            isOpen={mfpSettingContainer.dialogObject.isOpenDialog}
            title={mfpSettingContainer.dialogObject.title ? mfpSettingContainer.dialogObject.title : locale.t(locale.keys.mfpDefaultSetting.message.title)}
            type={mfpSettingContainer.dialogObject.type ? mfpSettingContainer.dialogObject.type : 'confirm'}
        />
    );
};

export default DialogConfirmChanges;
