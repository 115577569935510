import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Select, MenuItem } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import { context } from '@/user/components/mfp/Context';

const styles = (theme: Theme) =>
    createStyles({
        select: {
            '&& svg': {
                marginTop: '-10px',
            },
        },
        label: {
            fontSize: '0.875rem',
            margin: 0,
            padding: `${theme.spacing.unit}px ${theme.spacing.unit * 1.5}px 0`,
            color: '#666666',
        },
        box: {
            marginTop: 24,
            backgroundColor: '#eee',
            borderTopLeftTadius: 4,
            borderTopRightRadius: 4,
            marginBottom: theme.spacing.unit * 3,
            width: '100%',
            '&&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.13)',
            },
            '&& div': {
                paddingLeft: 4,
                paddingRight: 4,
                fontSize: '1.125rem',
            },
        },
        name: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'pre' /**空白は詰めない */,
            maxWidth: '20vw',
            [theme.breakpoints.down('md')]: {
                maxWidth: '60vw',
            },
        },
    });

const Consumer = context.Consumer;

export interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    return (
        <Consumer>
            {(app) =>
                app && (
                    <>
                        <div className={classes.box}>
                            <p className={classes.label}>{locale.t(locale.keys.modalLabel.mfp.device)}</p>
                            <Select className={classes.select} value={app.activePrinter.id} fullWidth onChange={app.handleChange}>
                                {app.printerList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        <div className={classes.name}>{item.name}</div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </>
                )
            }
        </Consumer>
    );
};

export default withStyles(styles)(Component);
