import { default as React } from 'react';
import { default as UI } from '@/common/constants/ui';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles, Grid, Typography, Button, TextField, Switch, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox, IconButton } from '@material-ui/core';
import { RemoveCircle, AddCircle } from '@material-ui/icons';
import { MfpSettingContainer } from './MfpSettingContainer';

const styles = (theme: Theme) =>
    createStyles({
        gridFullWidth: {
            width: '100%',
            paddingRight: 40,
        },
        title: {
            fontSize: 14,
            lineHeight: '20px',
            color: '#666666',
            marginBottom: 15,
        },
        textFieldCopy: {
            width: 120,
            marginLeft: 20,
            marginRight: 20,
            marginBottom: 30,
        },
        textFieldHeight: {
            height: 66,
            borderRadius: 4,
            backgroundColor: '#eeeeee',
        },
        iconCircle: {
            width: 16,
            height: 16,
            color: `${theme.palette.secondary.main}`,
            cursor: 'pointer',
        },
        iconToggle: {
            marginRight: 30,
        },
        buttonFirst: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: 6,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            height: 44,
            margin: 0,
            marginBottom: 30,
            textTransform: 'none',
            fontSize: 14,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
        },
        buttonMiddle: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: 0,
            height: 44,
            margin: 0,
            marginBottom: 30,
            textTransform: 'none',
            fontSize: 14,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
        },
        buttonLast: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: 6,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: 44,
            margin: 0,
            marginBottom: 30,
            textTransform: 'none',
            fontSize: 14,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
        },
        buttonSelected: {
            backgroundColor: `${theme.palette.secondary.main}`,
            color: '#ffffff',
        },
        formLabel: {
            textAlign: 'left',
            height: 37,
        },
        description: {
            textAlign: 'left',
            marginBottom: 20,
        },
        btnUpDown: {
            width: 16,
            height: 16,
            marginBottom: 30,
            '&:hover': {
                backgroundColor: '#ffffff',
                '@media (hover: none)': {
                    backgroundColor: '#ffffff',
                },
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    skipEffect?: boolean;
}

const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const mfpSettingContainer = MfpSettingContainer.useContainer();

    const printFormat = (
        <>
            <Grid item xs={6} container direction="column" justify="flex-start" alignItems="flex-start">
                <FormControlLabel control={<Checkbox checked={true} />} label="PDF" />
                <FormControlLabel control={<Checkbox checked={false} disabled={true} />} label="Excel" />
            </Grid>
            <Grid item xs={6} container direction="column" justify="flex-start" alignItems="flex-start">
                <FormControlLabel control={<Checkbox checked={false} disabled={true} />} label="Word" />
                <FormControlLabel control={<Checkbox checked={false} disabled={true} />} label="PowerPoint" />
            </Grid>
        </>
    );

    const color = (
        <>
            <Button
                className={classes.buttonFirst}
                style={{ minWidth: 90 }}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.printAttributeData.color === 1 ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChooseColor(1)}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.color)}
            </Button>
            <Button
                className={classes.buttonLast}
                style={{ minWidth: 90 }}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.printAttributeData.color === 0 ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChooseColor(0)}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.blackWhite)}
            </Button>
        </>
    );

    const mediaTray = [];
    for (let i = 0; i <= 1; i += 1) {
        mediaTray.push(
            <Button
                key={i}
                className={i === 0 ? classes.buttonFirst : i === 1 ? classes.buttonLast : classes.buttonMiddle}
                style={{ minWidth: 130 }}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.printAttributeData.mediaTray === i ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChooseTray(i)}
                disabled={i === 1 ? mfpSettingContainer.disabledTray1() : undefined}
            >
                {i === 0 && locale.t(locale.keys.mfpDefaultSetting.switchButton.autoTraySelect)}
                {i === 1 && locale.t(locale.keys.mfpDefaultSetting.switchButton.bypassTray)}
            </Button>,
        );
    }
    const sides = (
        <>
            <Grid item xs={6} container direction="column" justify="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup
                            value={mfpSettingContainer.defaultSetting.printAttributeData.sides ? mfpSettingContainer.defaultSetting.printAttributeData.sides.toString() : '0'}
                            onChange={mfpSettingContainer.handleChangeTypeSide}
                            name={'sides'}
                        >
                            <FormControlLabel className={classes.formLabel} value="0" control={<Radio />} label={locale.t(locale.keys.mfpDefaultSetting.radioButton.oneside)} />
                            <FormControlLabel
                                className={classes.formLabel}
                                value="1"
                                control={<Radio />}
                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.twosidesLeftSideBinding)}
                                disabled={mfpSettingContainer.disabledSides1()}
                            />
                            <FormControlLabel
                                className={classes.formLabel}
                                value="3"
                                control={<Radio />}
                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.magazineBindingLeftOpen)}
                                disabled={mfpSettingContainer.disabledSides34()}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={6} container direction="column" justify="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup
                            value={mfpSettingContainer.defaultSetting.printAttributeData.sides ? mfpSettingContainer.defaultSetting.printAttributeData.sides.toString() : '0'}
                            onChange={mfpSettingContainer.handleChangeTypeSide}
                            name={'sides'}
                        >
                            <FormControlLabel
                                className={classes.formLabel}
                                value="2"
                                control={<Radio />}
                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.twoSidesUpperSideBinding)}
                                disabled={mfpSettingContainer.disabledSides2()}
                            />
                            <FormControlLabel
                                className={classes.formLabel}
                                value="4"
                                control={<Radio />}
                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.magazineBindingRightOpen)}
                                disabled={mfpSettingContainer.disabledSides34()}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );

    const numberUp = [];
    const numberUpValueList = [0, 2, 4];
    for (let i = 0; i <= 2; i += 1) {
        numberUp.push(
            <Button
                key={i}
                className={i === 0 ? classes.buttonFirst : i === 2 ? classes.buttonLast : classes.buttonMiddle}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.printAttributeData.numberUp === numberUpValueList[i] ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChooseSummarySheet(numberUpValueList[i])}
                disabled={i !== 0 ? mfpSettingContainer.disabledSummarySheet() : undefined}
            >
                {i === 0 && locale.t(locale.keys.mfpDefaultSetting.switchButton.none)}
                {i === 1 && locale.t(locale.keys.mfpDefaultSetting.switchButton.twoSheetToOneSheet)}
                {i === 2 && locale.t(locale.keys.mfpDefaultSetting.switchButton.fourSheetToOnesheet)}
            </Button>,
        );
    }
    const presentationDirection = [];
    for (let i = 0; i <= 1; i += 1) {
        presentationDirection.push(
            <Button
                key={i}
                className={i === 0 ? classes.buttonFirst : i === 1 ? classes.buttonLast : classes.buttonMiddle}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.printAttributeData.presentationDirection === i ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChooseDirection(i)}
                disabled={mfpSettingContainer.defaultSetting.printAttributeData.numberUp === 0}
            >
                {i === 0 && locale.t(locale.keys.mfpDefaultSetting.switchButton.zDirection)}
                {i === 1 && locale.t(locale.keys.mfpDefaultSetting.switchButton.nDirection)}
            </Button>,
        );
    }
    const mediaSizeName = [];
    mediaSizeName.push(
        <Button
            key={0}
            className={classes.buttonFirst}
            variant="contained"
            classes={{ root: mfpSettingContainer.defaultSetting.printAttributeData.mediaSizeName === 0 ? classes.buttonSelected : undefined }}
            onClick={() => mfpSettingContainer.handleClickChooseSpecifyingPaperSize(0)}
        >
            {locale.t(locale.keys.mfpDefaultSetting.switchButton.none)}
        </Button>,
    );
    for (let i = 1; i <= 4; i += 1) {
        mediaSizeName.push(
            <Button
                key={i}
                className={i === 0 ? classes.buttonFirst : i === 4 ? classes.buttonLast : classes.buttonMiddle}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.printAttributeData.mediaSizeName === 5 - i ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChooseSpecifyingPaperSize(5 - i)}
            >
                {i === 1 && locale.t(locale.keys.mfpDefaultSetting.switchButton.a3)}
                {i === 2 && locale.t(locale.keys.mfpDefaultSetting.switchButton.b4)}
                {i === 3 && locale.t(locale.keys.mfpDefaultSetting.switchButton.a4)}
                {i === 4 && locale.t(locale.keys.mfpDefaultSetting.switchButton.b5)}
            </Button>,
        );
    }
    const sheetCollate = [];
    for (let i = 0; i <= 3; i += 1) {
        sheetCollate.push(
            <Button
                key={i}
                className={i === 0 ? classes.buttonFirst : i === 3 ? classes.buttonLast : classes.buttonMiddle}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.printAttributeData.sheetCollate === i ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChooseSort(i)}
                disabled={i === 0 ? mfpSettingContainer.disabledSort0() : i === 2 ? mfpSettingContainer.disabledSort2() : undefined}
            >
                {i === 0 && locale.t(locale.keys.mfpDefaultSetting.switchButton.stack)}
                {i === 1 && locale.t(locale.keys.mfpDefaultSetting.switchButton.sort)}
                {i === 2 && locale.t(locale.keys.mfpDefaultSetting.switchButton.rotatingSort)}
                {i === 3 && locale.t(locale.keys.mfpDefaultSetting.switchButton.shiftSort)}
            </Button>,
        );
    }

    const punch = (
        <>
            <Button
                className={classes.buttonFirst}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.printAttributeData.punch === 0 ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChoosePunch(0)}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.none)}
            </Button>
            <Button
                className={classes.buttonMiddle}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.printAttributeData.punch === 2 ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChoosePunch(2)}
                disabled={mfpSettingContainer.disabledPunch1()}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.top2)}
            </Button>
            <Button
                className={classes.buttonMiddle}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.printAttributeData.punch === 1 ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChoosePunch(1)}
                disabled={mfpSettingContainer.disabledPunch2()}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.left2)}
            </Button>
            <Button
                className={classes.buttonLast}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.printAttributeData.punch === 3 ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChoosePunch(3)}
                disabled={mfpSettingContainer.disabledPunch3()}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.right2)}
            </Button>
        </>
    );

    return (
        <>
            {mfpSettingContainer.ui === UI.state.Error && <div data-testid={UI.state.Error}>Error</div>}
            {(mfpSettingContainer.ui === UI.state.Loaded || mfpSettingContainer.ui === UI.state.Loading || props.skipEffect) && (
                <Grid container direction="row" justify="center" alignItems="flex-start">
                    <Grid item md={6} container direction="column" justify="flex-start" alignItems="flex-start">
                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.printObjectFormat)}</Typography>
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {printFormat}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.description}>{locale.t(locale.keys.mfpDefaultSetting.title.onlySelectPdf)}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.colorBlackWhite)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {color}
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center">
                            <IconButton disableRipple disableTouchRipple className={classes.btnUpDown} tabIndex={-1} onClick={mfpSettingContainer.handleClickRemoveACopy}>
                                <RemoveCircle className={classes.iconCircle} />
                            </IconButton>
                            <TextField
                                className={classes.textFieldCopy}
                                label={locale.t(locale.keys.mfpDefaultSetting.labelFolder.copies)}
                                variant="filled"
                                InputProps={{ disableUnderline: true, className: classes.textFieldHeight }}
                                value={mfpSettingContainer.defaultSetting.printAttributeData.copies}
                                onChange={mfpSettingContainer.handleChangeNoCopy}
                                onBlur={mfpSettingContainer.handleResetMinValue}
                            />
                            <IconButton disableRipple disableTouchRipple className={classes.btnUpDown} tabIndex={-1} onClick={mfpSettingContainer.handleClickAddACopy}>
                                <AddCircle className={classes.iconCircle} />
                            </IconButton>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.paperFeedTray)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {mediaTray}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.oneSideTwosides)}</Typography>
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-end">
                            {sides}
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: 20 }}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.summary)}</Typography>
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {numberUp}
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start" style={{ marginTop: -10 }}>
                            {presentationDirection}
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center" style={{ marginTop: -30 }}>
                            <span>{locale.t(locale.keys.mfpDefaultSetting.labelSwitch.displayDividerLine)}</span>
                            <Switch
                                className={classes.iconToggle}
                                onChange={mfpSettingContainer.handleSwitchDisplayDividerLine}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                checked={mfpSettingContainer.defaultSetting.printAttributeData.separatorLine}
                                disabled={mfpSettingContainer.defaultSetting.printAttributeData.numberUp === 0}
                            />
                        </Grid>
                    </Grid>

                    <Grid item md={6} container direction="column" justify="flex-start" alignItems="flex-start">
                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.specifyingPaperSize)}</Typography>
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {mediaSizeName}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.sortMethod)}</Typography>
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {sheetCollate}
                        </Grid>

                        <Grid item xs={12} style={{ marginBottom: -5 }}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.staple)}</Typography>
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-end">
                            <Grid item xs={5} container direction="column" justify="flex-start" alignItems="flex-start">
                                <Grid item xs={12}>
                                    <FormControl>
                                        <RadioGroup
                                            value={mfpSettingContainer.defaultSetting.printAttributeData.staple ? mfpSettingContainer.defaultSetting.printAttributeData.staple.toString() : '0'}
                                            onChange={mfpSettingContainer.handleChangeTypeStaple}
                                            name={'staple'}
                                        >
                                            <FormControlLabel className={classes.formLabel} value="0" control={<Radio />} label={locale.t(locale.keys.mfpDefaultSetting.radioButton.none)} />
                                            <FormControlLabel
                                                className={classes.formLabel}
                                                value="5"
                                                control={<Radio />}
                                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.top2)}
                                                disabled={mfpSettingContainer.disabledStaple5()}
                                            />
                                            <FormControlLabel
                                                className={classes.formLabel}
                                                value="6"
                                                control={<Radio />}
                                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.left2)}
                                                disabled={mfpSettingContainer.disabledStaple6()}
                                            />
                                            <FormControlLabel
                                                className={classes.formLabel}
                                                value="1"
                                                control={<Radio />}
                                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.upperLeft)}
                                                disabled={mfpSettingContainer.disabledStaple1()}
                                            />
                                            <FormControlLabel
                                                className={classes.formLabel}
                                                value="3"
                                                control={<Radio />}
                                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.upperRight)}
                                                disabled={mfpSettingContainer.disabledStaple3()}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} container direction="column" justify="flex-start" alignItems="flex-start">
                                <Grid item xs={12}>
                                    <FormControl>
                                        <RadioGroup
                                            value={mfpSettingContainer.defaultSetting.printAttributeData.staple ? mfpSettingContainer.defaultSetting.printAttributeData.staple.toString() : '0'}
                                            onChange={mfpSettingContainer.handleChangeTypeStaple}
                                            name={'staple'}
                                        >
                                            <FormControlLabel
                                                className={classes.formLabel}
                                                value="8"
                                                control={<Radio />}
                                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.center2)}
                                                disabled={mfpSettingContainer.disabledStaple8()}
                                            />
                                            <FormControlLabel
                                                className={classes.formLabel}
                                                value="7"
                                                control={<Radio />}
                                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.right2)}
                                                disabled={mfpSettingContainer.disabledStaple7()}
                                            />
                                            <FormControlLabel
                                                className={classes.formLabel}
                                                value="2"
                                                control={<Radio />}
                                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.upperLeftDiagonal)}
                                                disabled={mfpSettingContainer.disabledStaple2()}
                                            />
                                            <FormControlLabel
                                                className={classes.formLabel}
                                                value="4"
                                                control={<Radio />}
                                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.upperRightDiagonal)}
                                                disabled={mfpSettingContainer.disabledStaple4()}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: 20 }}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.punch)}</Typography>
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {punch}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.description}>{locale.t(locale.keys.mfpDefaultSetting.description.tabFour1)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.description}>{locale.t(locale.keys.mfpDefaultSetting.description.tabFour2)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default withStyles(styles)(Component);
