import auth from './auth';
import changePassword from './change-password';
import cloudConnection from './cloud-connection';
import dashboard from './dashboard';
import endpoints from './endpoints';
import mfp from './mfp';
import userProfile from './user-profile';
import spool from './spool';
import sharedCloudConnection from './shared-cloud-connection';
import sharedendpoints from './shared-endpoints';

export default {
    auth,
    changePassword,
    cloudConnection,
    dashboard,
    endpoints,
    mfp,
    userProfile,
    spool,
    sharedCloudConnection,
    sharedendpoints,
};
