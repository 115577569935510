import { default as React } from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { MfpSettingContainer } from './MfpSettingContainer';
import MfpSetting from './MfpSetting';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 'auto',
        },
    });

interface Props extends WithStyles<typeof styles> {
    onTitle?: () => void;
}

export const Component: React.FC<Props> = (props) => {
    return (
        <div className={props.classes.root}>
            <MfpSettingContainer.Provider>
                <MfpSetting onTitle={props.onTitle} />
            </MfpSettingContainer.Provider>
        </div>
    );
};

export default withStyles(styles)(Component);
