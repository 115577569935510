import React, { FC, useState } from 'react';
import { Checkbox, Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, WithStyles, createStyles, withStyles } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import { styles as baseTableStyles } from '@/common/components/Table/style';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { Search } from '@material-ui/icons';
import { getMfpPermission, selectPermittedMfp, selectPermittedMfpAll, MfpPermissionRow, isSelectedAllMfp } from '@/common/models/endpoints/useEndpoints';

const styles = () =>
    createStyles({
        ...baseTableStyles(),
        root: {
            width: '100%',
            gap: '16px',
        },
        searchRoot: {
            width: '100%',
            display: 'flex',
            overflowX: 'auto',
        },
        searchIconButton: {
            marginBlock: 'auto',
            marginInline: '10px',
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: '8px',
        },
        table: {
            width: '100%',
            padding: 0,
        },

        checkCell: {
            width: 48,
            maxWidth: 48,
            margin: 4,
            wordBreak: 'break-all',
        },
        mfpNumberCell: {
            // 複合機名
            width: 'auto',
            margin: 4,
        },
        deviceNameCell: {
            // 複合機名
            width: 'auto',
            margin: 4,
        },
        mfpNameCell: {
            // 機種
            width: 'auto',
            margin: 4,
        },
    });

interface Props extends WithStyles<typeof styles> {
    mfpPermission: MfpPermissionRow[];
    selectedMfpPermission: string[];
    handleMfpPermission: (mfpPermission: string[]) => void;
}

const MfpPermissionTable: FC<Props> = (props: Props) => {
    const defaultRows = props.mfpPermission;
    const [rows, setRows] = useState<MfpPermissionRow[]>(getMfpPermission(defaultRows, '', 5, 0, true));
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [filteredCount, setFilteredCount] = useState(getMfpPermission(defaultRows, '', 0, 0, false).length);

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, updatedPage: number) => {
        setPage(updatedPage);
        setRows(getMfpPermission(defaultRows, searchText, rowsPerPage, rowsPerPage * updatedPage, true));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const updatedRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(updatedRowsPerPage);
        setRows(getMfpPermission(defaultRows, searchText, updatedRowsPerPage, updatedRowsPerPage * page, true));
    };

    const handleChangeSearchText = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchText(event.target.value);
        setRows(getMfpPermission(defaultRows, event.target.value, rowsPerPage, 0, true));
        setFilteredCount(getMfpPermission(defaultRows, event.target.value, 0, 0, false).length);
    };

    const getEmptyRowCount = () => {
        return rowsPerPage - Math.min(rowsPerPage, rows.length);
    };

    // 選択済みの複合機の数をカウント
    const countSelected = () => {
        // 選択済みの複合機のうち、アクティブな複合機の数をカウント
        // 停止中の複合機がアクティブになった場合は、以前の許可状態を保持する仕様である。
        // アクティブの時に許可した複合機が停止中になったとしても、許可状態は保持される。
        return props.selectedMfpPermission.filter((id) => props.mfpPermission.map((mfp) => mfp.id).includes(id)).length.toString();
    };

    return (
        <Grid container direction="column">
            <Grid item>
                <TextField
                    placeholder={locale.t(locale.keys.endpointsPermission.mfpPermission.table.search)}
                    variant="outlined"
                    margin={'dense'}
                    fullWidth
                    style={{ marginBlock: '4px' }}
                    InputLabelProps={{ style: { fontSize: 12, transform: 'translate(14px, 10px) scale(1)' } }}
                    InputProps={{ style: { fontSize: 14, height: 36 } }}
                    value={searchText}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChangeSearchText(e)}
                />
            </Grid>
            <Grid item>
                <Typography>
                    {locale.t(locale.keys.endpointsPermission.mfpPermission.selectedNumber, {
                        n: countSelected(),
                    })}
                </Typography>
            </Grid>
            <Grid item>
                <Table className={props.classes.table}>
                    <TableHead>
                        <TableRow className={props.classes.headerFont}>
                            <TableCell padding="none" className={props.classes.checkCell}>
                                <Checkbox
                                    color="secondary"
                                    checked={rows.length !== 0 && isSelectedAllMfp(props.selectedMfpPermission, getMfpPermission(defaultRows, searchText, 0, 0, false))}
                                    onChange={() => {
                                        // 検索された複合機すべてをチェック
                                        const targetRows = getMfpPermission(defaultRows, searchText, 0, 0, false);
                                        const updateSelected = selectPermittedMfpAll(targetRows, props.selectedMfpPermission, !isSelectedAllMfp(props.selectedMfpPermission, targetRows));
                                        props.handleMfpPermission(updateSelected);
                                    }}
                                />
                            </TableCell>
                            <TableCell padding="none" className={props.classes.mfpNumberCell}>
                                <Grid className={props.classes.gridInsideHeaderCell}>
                                    <p className={props.classes.gridInsideHeaderCellText}>{locale.t(locale.keys.endpointsPermission.mfpPermission.table.cell.mfpNumber)}</p>
                                    <Search style={{ marginBlock: 'auto' }} />
                                </Grid>
                            </TableCell>
                            <TableCell padding="none" className={props.classes.deviceNameCell}>
                                <Grid className={props.classes.gridInsideHeaderCell}>
                                    <p className={props.classes.gridInsideHeaderCellText}>{locale.t(locale.keys.endpointsPermission.mfpPermission.table.cell.deviceName)}</p>
                                    <Search style={{ marginBlock: 'auto' }} />
                                </Grid>
                            </TableCell>
                            <TableCell className={props.classes.mfpNameCell}>{locale.t(locale.keys.endpointsPermission.mfpPermission.table.cell.mfpName)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                            return (
                                <TableRow key={row.id} tabIndex={-1} selected={false} data-testid="row" className={index % 2 ? props.classes.evenRow : props.classes.oddRow}>
                                    <TableCell padding="none" className={props.classes.checkCell}>
                                        <Checkbox
                                            color="secondary"
                                            checked={props.selectedMfpPermission.includes(row.id)}
                                            onChange={() => {
                                                props.handleMfpPermission(selectPermittedMfp(props.selectedMfpPermission, row.id));
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell padding="none" className={props.classes.mfpNumberCell}>
                                        {row.mfpNumber}
                                    </TableCell>
                                    <TableCell padding="none" className={props.classes.deviceNameCell} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                        {row.deviceName}
                                    </TableCell>
                                    <TableCell padding="none" className={props.classes.mfpNameCell}>
                                        {row.mfpName}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {getEmptyRowCount() > 0 && (
                            <TableRow style={{ height: 49 * getEmptyRowCount() }}>
                                <TableCell colSpan={100} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredCount}
                    labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                    labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                        <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
                    )}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': locale.t(locale.keys.table.previousPage),
                    }}
                    nextIconButtonProps={{
                        'aria-label': locale.t(locale.keys.table.nextPage),
                    }}
                    onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => handleChangePage(event, p)}
                    onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChangeRowsPerPage(event)}
                    data-testid="pagenation-root"
                />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(MfpPermissionTable);
