import { default as React } from 'react';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { SpoolContainer } from './SpoolContainer';
import Spool from './Spool';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing.unit * 2,
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing.unit,
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    onTitle?: () => void;
}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.spool.index));
    if (typeof props.onTitle === 'function') {
        props.onTitle();
    }

    return (
        <div className={props.classes.root}>
            <SpoolContainer.Provider>
                <Spool />
            </SpoolContainer.Provider>
        </div>
    );
};

export default withStyles(styles)(Component);
