import * as locale from '@/common/utils/locale/locale';
import * as validator from '@/common/utils/validator';

export interface Pages {
    [code: string]: {
        title?: string;
        modalTitle?: string;
        type: string;
    };
}

export const pageData: Pages = {
    login: {
        type: 'logo',
    },
    dashboard: {
        title: locale.t(locale.keys.pageTitle.dashboard.userIndex),
        type: 'dashboard',
    },
    mfplist: {
        title: locale.t(locale.keys.pageTitle.mfp.index),
        modalTitle: locale.t(locale.keys.modalTitle.mfp.index),
        type: 'normal',
    },
    parts: {
        title: locale.t(locale.keys.pageTitle.parts.index),
        type: 'normal',
    },
    userProfile: {
        title: locale.t(locale.keys.pageTitle.userProfile.index),
        type: 'normal',
    },
};

export const pinValidations = (): validator.Constraints => {
    return {
        pin: {
            pinPolicy: {
                allow: false,
                message: locale.t(locale.keys.validation.pinPolicy),
            },
        },
    };
};

export const NewPinValidation = (): validator.ValidationOutput => {
    return {
        pin: null,
    };
};
