import * as React from 'react';
import { TextField, Theme, createStyles, WithStyles, withStyles, Typography, Avatar, Grid, Button } from '@material-ui/core';
import { default as icon } from '@/common/img/mfp/index';
import * as schema from '@/bundles/schema/typescript/schema';
import { Check } from '@material-ui/icons';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { NewEndpointContainer } from './NewEndpointContainer';
import * as locale from '@/common/utils/locale/locale';
import { listBrightBtnColor } from '@/user/constants/endpoint-setting';

const sizePerPageIcons = 20;

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        endpointNameContainer: {
            width: '100%',
        },
        md20: {
            marginRight: '20px',
        },
        textField: {
            backgroundColor: theme.palette.grey[200],
            borderBottom: `1px solid ${theme.palette.grey[400]}`,
            marginBottom: theme.spacing.unit * 3,
            width: theme.spacing.unit * 36,
            '&& input': {
                marginLeft: theme.spacing.unit * 1,
            },
            '&& input::-webkit-inner-spin-button': {
                appearance: 'none',
                margin: 0,
            },
            '&& input::-webkit-outer-spin-button': {
                appearance: 'none',
                margin: 0,
            },
            '&& label': {
                fontSize: '0.875rem',
                transform: 'none',
                top: theme.spacing.unit * 1,
                left: theme.spacing.unit * 1,
            },
            '&& div': {
                marginTop: theme.spacing.unit * 3,
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        typography: {
            marginBottom: theme.spacing.unit,
            marginTop: theme.spacing.unit * 3,
        },
        iconContainer: {
            paddingTop: '13px',
            display: 'flex',
            flexWrap: 'wrap',
        },
        iconButton: {
            marginRight: theme.spacing.unit * 1,
            marginBottom: theme.spacing.unit * 1,
            width: theme.spacing.unit * 6,
            height: theme.spacing.unit * 6,
            borderRadius: '50%',
            border: 'solid 1px #dddddd',
            color: 'transparent',
            cursor: 'pointer',
        },
        iconButtonFirefox: {
            marginRight: theme.spacing.unit * 1,
            marginBottom: theme.spacing.unit * 1,
            width: theme.spacing.unit * 6,
            height: theme.spacing.unit * 6,
            borderRadius: '50%',
            border: 'solid 1px #dddddd',
            color: 'transparent',
            cursor: 'pointer',
            '&:focus-within': {
                border: 'solid 1px black',
            },
        },
        btnColorContainer: {
            marginTop: theme.spacing.unit * 3,
            marginBottom: theme.spacing.unit * 3,
            display: 'flex',
            flexWrap: 'wrap',
            position: 'relative',
        },

        imgContainer: {
            width: theme.spacing.unit * 6.25,
            height: theme.spacing.unit * 6.25,
            borderRadius: '4px',
            margin: '3px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        imgSeleted: {
            border: 'solid 2px #ff7300',
            backgroundColor: '#ffefbd',
        },
        imgIcon: {
            width: theme.spacing.unit * 3.75,
            height: theme.spacing.unit * 3.75,
            cursor: 'pointer',
        },
        containedCustom: {
            borderColor: theme.palette.secondary.main,
            border: 'solid',
            borderWidth: '1px',
            boxShadow: 'none',
            width: theme.spacing.unit * 4.5,
            height: theme.spacing.unit * 4.5,
            color: theme.palette.secondary.main,
            minWidth: theme.spacing.unit * 4.5,
            '&:active': {
                backgroundColor: theme.palette.secondary.light,
            },
            '&:disabled': {
                borderColor: theme.palette.grey[400],
                color: theme.palette.grey[400],
            },
        },
        paginateBoard: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
    });

export interface Props extends WithStyles<typeof styles> {
    storages?: string;
    theme: Theme;
    isShared: boolean;
}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const newEndpointContainer = NewEndpointContainer.useContainer();
    const [selectedPage, setPage] = React.useState(0);
    const [startIndex, setStart] = React.useState(0);
    const [endIndex, setEnd] = React.useState(sizePerPageIcons);
    const matchesSm = useMediaQuery(props.theme.breakpoints.down('sm'));
    const listIcons = [];
    const totalIcons: number = Object.keys(icon.iconMfps).length;
    const sizePage = ~~(totalIcons / sizePerPageIcons);
    const arrPage = [];
    const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;

    for (let i = 0; i <= sizePage; i += 1) {
        arrPage.push(i);
    }
    const listBtnColor = [];
    const btnColorList = JSON.parse(JSON.stringify(schema.V1ObjectsEndpointsButtonColor));

    const handleIconSelected = (key: string) => {
        newEndpointContainer.setEndpointIcon(key);
    };

    const handleBtnSelected = (key: schema.V1ObjectsEndpointsButtonColor) => {
        newEndpointContainer.setEndpointBtnColor(key);
    };

    const handlePressKeyIcon = (event: any, key: string) => {
        if (event.keyCode === 32 || event.keyCode === 13) {
            handleIconSelected(key);
        }
    };

    const handlePressKeyButton = (event: any, key: schema.V1ObjectsEndpointsButtonColor) => {
        if (event.keyCode === 32 || event.keyCode === 13) {
            newEndpointContainer.setEndpointBtnColor(key);
        }
    };

    for (const i in icon.iconMfps) {
        if (matchesSm) break;
        let iconClass = classes.imgContainer;
        if (newEndpointContainer.endpointIcon && newEndpointContainer.endpointIcon === i) {
            iconClass = `${classes.imgContainer} ${classes.imgSeleted}`;
        }
        listIcons.push(
            <div key={i} className={iconClass} id={`img${i}`} onKeyDown={(event) => handlePressKeyIcon(event, i)} tabIndex={0}>
                <img className={classes.imgIcon} src={icon.iconMfps[i]} alt="img" onClick={() => handleIconSelected(i)} />
            </div>,
        );
    }

    for (let index = startIndex; index < endIndex; index += 1) {
        if (!matchesSm) break;
        const i = Object.keys(icon.iconMfps)[index];
        let iconClass = classes.imgContainer;
        if (newEndpointContainer.endpointIcon && newEndpointContainer.endpointIcon === i) {
            iconClass = `${classes.imgContainer} ${classes.imgSeleted}`;
        }
        listIcons.push(
            <div key={i} className={iconClass} id={`img${i}`} onKeyDown={(event) => handlePressKeyIcon(event, i)} tabIndex={0}>
                <img className={classes.imgIcon} src={icon.iconMfps[i]} alt="img" onClick={() => handleIconSelected(i)} />
            </div>,
        );
    }

    for (const item in btnColorList) {
        let styleBtn = { backgroundColor: item, color: 'transparent' };
        if (newEndpointContainer.endpointBtnColor && newEndpointContainer.endpointBtnColor === btnColorList[item] && !listBrightBtnColor.includes(item)) {
            styleBtn = { backgroundColor: item, color: 'white' };
        }
        if (newEndpointContainer.endpointBtnColor && newEndpointContainer.endpointBtnColor === btnColorList[item] && listBrightBtnColor.includes(item)) {
            styleBtn = { backgroundColor: item, color: 'black' };
        }
        listBtnColor.push(
            <Avatar
                key={item}
                id={`avatar${item}`}
                className={isFirefox ? `${classes.iconButtonFirefox}` : `${classes.iconButton}`}
                style={styleBtn}
                onClick={() => handleBtnSelected(btnColorList[item])}
                onKeyDown={(event) => handlePressKeyButton(event, btnColorList[item])}
                tabIndex={0}
            >
                <Check />
            </Avatar>,
        );
    }

    const changePage = (index: number) => {
        const start = index * sizePerPageIcons;
        let end = start + sizePerPageIcons;
        if (end > Object.keys(icon.iconMfps).length) end = Object.keys(icon.iconMfps).length;
        setPage(index);
        setStart(start);
        setEnd(end);
    };

    return (
        <>
            <Grid container className={classes.root}>
                <Grid item sm={12} md={8}>
                    <div className={classes.endpointNameContainer}>
                        <p>{locale.t(locale.keys.createEnpoint.stepOneLabel)}</p>
                        <TextField
                            value={newEndpointContainer.endpointName}
                            className={`${classes.textField}`}
                            fullWidth
                            label={props.isShared ? locale.t(locale.keys.createSharedEndpoint.endpointName) : locale.t(locale.keys.createEnpoint.endpointName)}
                            inputProps={{ style: { height: '100%' } }}
                            onChange={(evt) => {
                                newEndpointContainer.addEnpointName(evt.target.value);
                            }}
                            onBlur={newEndpointContainer.onBlurEndpointNameInput}
                        />
                    </div>
                    {matchesSm ? (
                        // pagination for responsive
                        <div className={classes.endpointNameContainer}>
                            <Typography className={classes.typography}>{locale.t(locale.keys.createEnpoint.icon)}</Typography>
                            <div className={classes.iconContainer}>{listIcons}</div>
                            <div className={classes.paginateBoard}>
                                <Typography className={classes.md20}>{locale.t(locale.keys.createEnpoint.totalIconLabel, { number: totalIcons })}</Typography>
                                {arrPage.map((item, i) => (
                                    <Button className={classes.containedCustom} disabled={selectedPage === item} key={i} onClick={() => changePage(item)}>
                                        {item + 1}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className={classes.endpointNameContainer}>
                            <Typography className={classes.typography}>{locale.t(locale.keys.createEnpoint.icon)}</Typography>
                            <div className={classes.iconContainer}>{listIcons}</div>
                        </div>
                    )}
                    <div className={classes.endpointNameContainer}>
                        <Typography className={classes.typography}>{locale.t(locale.keys.createEnpoint.buttonColor)}</Typography>
                        <div className={classes.btnColorContainer}>{listBtnColor}</div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
