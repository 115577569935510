import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import * as signup from '@/user/models/auth/signup/Signup';
import { parse } from 'query-string';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

// location.search からワークスペースメンバ招待検証APIのRequestを作成する
export const parseWorkspaceuserInviteVerifyQueryString = (given: string): schema.V1WorkspaceuserInviteVerifyCreateRequest => {
    const got = parse(given);
    if (!got) {
        throw new Error('invalid');
    }
    if (!got.workspace || typeof got.workspace !== 'string') {
        throw new Error('given workspace is invalid');
    }
    if (!got.email || typeof got.email !== 'string') {
        throw new Error('given email is invalid');
    }
    if (!got.token || typeof got.token !== 'string') {
        throw new Error('given token is invalid');
    }

    const req: schema.V1WorkspaceuserInviteVerifyCreateRequest = {
        workspaceId: got.workspace as string,
        email: got.email as string,
        token: got.token as string,
    };
    return req;
};

// ワークスペースメンバ招待検証API
export const workspaceuserInviteVerify = async (req: schema.V1WorkspaceuserInviteVerifyCreateRequest): Promise<schema.V1WorkspaceuserInviteVerifyCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceuserInviteVerifyCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaceusers/invite/verify`, req);
    return response.data;
};

export const parseMemberSignupRequest = (form: signup.Form): schema.V1WorkspaceuserInviteVerifyUpdateRequest => {
    const user: schema.V1WorkspaceuserUpdateRequest = {
        active: form.active,
        language: form.userLang as schema.Language,
        name: form.name,
        phoneticName: form.phoneticName,
        role: form.role,
        avatarUrl: form.avatarUrl,
        deviceLoginUser: form.deviceLoginUser,
        pin: form.pin,
        contactEmail: form.contactEmail,
    };

    const req: schema.V1WorkspaceuserInviteVerifyUpdateRequest = {
        user,
        displayId: form.workspaceId,
        email: form.mail,
        password: form.password,
        token: form.token,
    };
    return req;
};

// メンバサインアップ用ユーザー更新API https://${consts.environment.api.hostname}/v1/workspaceusers/invite/verify
export const memberSignup = async (req: schema.V1WorkspaceuserInviteVerifyUpdateRequest): Promise<schema.V1WorkspaceuserInviteVerifyUpdateResponse> => {
    const responce = await axiosFactory.put<schema.V1WorkspaceuserInviteVerifyUpdateResponse>(`https://${consts.environment.api.hostname}/v1/workspaceusers/invite/verify`, req);
    return responce.data;
};

export const parseUpdateMemberRequest = (user: schema.V1ObjectsWorkspaceuserLarge): schema.V1WorkspaceuserUpdateRequest => {
    const req: schema.V1WorkspaceuserUpdateRequest = {
        active: user.active,
        language: user.language,
        name: user.name,
        phoneticName: user.phoneticName,
        role: user.role,
        deviceLoginUser: user.deviceLoginUser,
        pin: user.pin,
        contactEmail: user.contactEmail,
    };
    return req;
};

// ユーザー更新
export const updateUser = async (id: string, auth: string, req: schema.V1WorkspaceuserUpdateRequest): Promise<schema.V1WorkspaceuserUpdateResponse> => {
    const responce = await axiosFactory.put<schema.V1WorkspaceuserUpdateResponse>(`https://${consts.environment.api.hostname}/v1/workspaceusers/${id}`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return responce.data;
};

// ワークスペースユーザー単体取得API
export const showWorkspaceUser = async (workspaceId: string, workspaceUserId: string, auth: string): Promise<schema.V1WorkspaceuserShowResponse> => {
    const response = await axiosFactory.get<schema.V1WorkspaceuserShowResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/workspaceusers/${workspaceUserId}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
