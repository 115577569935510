import React, { useState } from 'react';
import { createContainer } from 'unstated-next';
import { default as UI } from '@/common/constants/ui';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import * as defaultSettingsModel from '@/user/models/endpoints/default-settings/default-settings';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as errorHandler from '@/common/utils/errorHandler';
import { getStringByteCount, removeEmojiChar } from '@/common/utils/webappUtil';
import { regexEndpointPw } from '@/common/constants/dateFormat';
import { Typography } from '@material-ui/core';

export const validationSettingLock = (defaultSetting: schema.V1ObjectsEndpointDefaultSetting) => {
    const message = [];
    const comma = locale.t(locale.keys.mfpDefaultSetting.message.lock.comma);

    if (defaultSetting.scanAttributeData.resolutionLock && defaultSetting.scanAttributeData.resolution !== 300) {
        message.push(locale.t(locale.keys.mfpDefaultSetting.title.resolution));
    }

    if (
        defaultSetting.scanAttributeData.colorTypeLock &&
        defaultSetting.scanAttributeData.colorType !== undefined &&
        defaultSetting.scanAttributeData.colorType !== null &&
        ![4, 5, 6, 8, 9].includes(defaultSetting.scanAttributeData.colorType)
    ) {
        if (message.length > 0) message.push(comma);
        message.push(locale.t(locale.keys.mfpDefaultSetting.title.documentType));
    }

    if (message.length > 0) message.push(locale.t(locale.keys.mfpDefaultSetting.message.lock.base));

    return message.join('');
};

/**
 * 読み取りサイズ（UIにおける原稿サイズ(定型)と不定形）変更時のメッセージおよび設定の出力
 * @param changedSize 変更後の読み取りサイズ
 * @param CurrentDefaultSetting 変更前のスキャン/プリント設定
 */
export const changePaperSize = (changedSize: number, CurrentDefaultSetting: schema.V1ObjectsEndpointDefaultSetting) => {
    if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 14].includes(changedSize)) {
        CurrentDefaultSetting = {
            ...CurrentDefaultSetting,
            scanAttributeData: {
                ...CurrentDefaultSetting.scanAttributeData,
                mixedSize: false,
            },
        };
    }

    if (
        changedSize === 14 &&
        CurrentDefaultSetting.scanAttributeData.size &&
        [1, 2, 3, 4, 5, 6].includes(CurrentDefaultSetting.scanAttributeData.size) &&
        (CurrentDefaultSetting.scanAttributeData.batch || CurrentDefaultSetting.scanAttributeData.sadf) &&
        CurrentDefaultSetting.scanAttributeData.aggregation
    ) {
        return {
            defaultSetting: {
                ...CurrentDefaultSetting,
                scanAttributeData: {
                    ...CurrentDefaultSetting.scanAttributeData,
                    size: 14,
                    batch: false,
                    sadf: false,
                    aggregation: false,
                },
            },
            isOpenDialog: true,
            message1:
                locale.t(locale.keys.mfpDefaultSetting.message.business.content1) +
                locale.t(locale.keys.mfpDefaultSetting.message.business.content2) +
                locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
            message2: locale.t(locale.keys.mfpDefaultSetting.message.business.splitPaperContent3),
            message3: locale.t(locale.keys.mfpDefaultSetting.message.business.sizeContent3),
            message4: undefined,
            lastMessage: undefined,
        };
    }

    if (
        changedSize === 14 &&
        CurrentDefaultSetting.scanAttributeData.size &&
        [1, 2, 3, 4, 5, 6].includes(CurrentDefaultSetting.scanAttributeData.size) &&
        (!CurrentDefaultSetting.scanAttributeData.batch && !CurrentDefaultSetting.scanAttributeData.sadf) &&
        CurrentDefaultSetting.scanAttributeData.aggregation
    ) {
        return {
            defaultSetting: {
                ...CurrentDefaultSetting,
                scanAttributeData: {
                    ...CurrentDefaultSetting.scanAttributeData,
                    size: 14,
                    aggregation: false,
                },
            },
            isOpenDialog: true,
            message1:
                locale.t(locale.keys.mfpDefaultSetting.message.business.content1) +
                locale.t(locale.keys.mfpDefaultSetting.message.business.content2) +
                locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
            message2: locale.t(locale.keys.mfpDefaultSetting.message.business.sizeContent3),
            message3: undefined,
            message4: undefined,
            lastMessage: undefined,
        };
    }

    if (changedSize === 14 && typeof CurrentDefaultSetting.scanAttributeData.size === 'number' && (CurrentDefaultSetting.scanAttributeData.batch || CurrentDefaultSetting.scanAttributeData.sadf)) {
        return {
            defaultSetting: {
                ...CurrentDefaultSetting,
                scanAttributeData: {
                    ...CurrentDefaultSetting.scanAttributeData,
                    size: 14,
                    batch: false,
                    sadf: false,
                },
            },
            isOpenDialog: true,
            message1:
                locale.t(locale.keys.mfpDefaultSetting.message.business.content1) +
                locale.t(locale.keys.mfpDefaultSetting.message.business.content2) +
                locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
            message2: locale.t(locale.keys.mfpDefaultSetting.message.business.splitPaperContent3),
            message3: undefined,
            message4: undefined,
            lastMessage: undefined,
        };
    }

    if (
        (changedSize === 7 || changedSize === 8 || changedSize === 9 || changedSize === 15) &&
        CurrentDefaultSetting.scanAttributeData.outputType &&
        [9, 10].includes(CurrentDefaultSetting.scanAttributeData.outputType)
    ) {
        return {
            defaultSetting: {
                ...CurrentDefaultSetting,
                scanAttributeData: {
                    ...CurrentDefaultSetting.scanAttributeData,
                    size: changedSize,
                    outputType: CurrentDefaultSetting.scanAttributeData.outputType === 9 ? 1 : 3,
                },
            },
            isOpenDialog: true,
            message1:
                (CurrentDefaultSetting.scanAttributeData.outputType === 9
                    ? locale.t(locale.keys.mfpDefaultSetting.message.undefinedOutputType.singleContent1)
                    : locale.t(locale.keys.mfpDefaultSetting.message.undefinedOutputType.multiContent1)) +
                locale.t(locale.keys.mfpDefaultSetting.message.undefinedOutputType.content2) +
                (CurrentDefaultSetting.scanAttributeData.outputType === 9
                    ? locale.t(locale.keys.mfpDefaultSetting.message.undefinedOutputType.singleContent3)
                    : locale.t(locale.keys.mfpDefaultSetting.message.undefinedOutputType.multiContent3)) +
                locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
            message2: undefined,
            message3: undefined,
            message4: undefined,
            lastMessage: undefined,
        };
    }

    if ((changedSize === 14 || changedSize === 7 || changedSize === 8 || changedSize === 9) && CurrentDefaultSetting.scanAttributeData.aggregation) {
        return {
            defaultSetting: {
                ...CurrentDefaultSetting,
                scanAttributeData: {
                    ...CurrentDefaultSetting.scanAttributeData,
                    size: changedSize,
                    aggregation: false,
                },
            },
            isOpenDialog: false,
            message1: undefined,
            message2: undefined,
            message3: undefined,
            message4: undefined,
            lastMessage: undefined,
        };
    }
    return {
        defaultSetting: {
            ...CurrentDefaultSetting,
            scanAttributeData: {
                ...CurrentDefaultSetting.scanAttributeData,
                size: changedSize,
            },
        },
        isOpenDialog: false,
        message1: undefined,
        message2: undefined,
        message3: undefined,
        message4: undefined,
        lastMessage: undefined,
    };
};

export const changeByEnabledDoCutImage = (defaultSetting: schema.V1ObjectsEndpointDefaultSetting) => {
    let scanAttributeData = { ...defaultSetting.scanAttributeData };
    let message = [];

    // 原稿面
    if (defaultSetting.scanAttributeData.clippedVertical && defaultSetting.scanAttributeData.scanBothSide) {
        message.push(
            locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.paperSide, {
                before: locale.t(locale.keys.mfpDefaultSetting.switchButton.twosideRightleft),
                after: locale.t(locale.keys.mfpDefaultSetting.switchButton.oneSide),
            }),
        );
        scanAttributeData = { ...scanAttributeData, clippedVertical: true, scanBothSide: false };
    } else if (!defaultSetting.scanAttributeData.clippedVertical && defaultSetting.scanAttributeData.scanBothSide) {
        message.push(
            locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.paperSide, {
                before: locale.t(locale.keys.mfpDefaultSetting.switchButton.twoSideUpDown),
                after: locale.t(locale.keys.mfpDefaultSetting.switchButton.oneSide),
            }),
        );
        scanAttributeData = { ...scanAttributeData, clippedVertical: true, scanBothSide: false };
    }

    // 分割大量給紙
    if (defaultSetting.scanAttributeData.sadf) {
        message.push(
            locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.batch, {
                before: locale.t(locale.keys.mfpDefaultSetting.switchButton.sadf),
                after: locale.t(locale.keys.mfpDefaultSetting.switchButton.no),
            }),
        );
        scanAttributeData = { ...scanAttributeData, sadf: false };
    }
    if (defaultSetting.scanAttributeData.batch) {
        message.push(
            locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.batch, {
                before: locale.t(locale.keys.mfpDefaultSetting.switchButton.severalPagesDocs),
                after: locale.t(locale.keys.mfpDefaultSetting.switchButton.no),
            }),
        );
        scanAttributeData = { ...scanAttributeData, batch: false };
    }

    // サイズ
    // 切出し有効時、サイズはA3ヨコに設定される
    // Quickスキャンに動作を合わせるため
    // https://sios-mfp.backlog.jp/view/COVAS-2218#comment-213116278
    scanAttributeData = { ...scanAttributeData, size: 1 };
    switch (defaultSetting.scanAttributeData.size) {
        case 0:
            if (defaultSetting.scanAttributeData.mixedSize) {
                message.push(
                    locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.size, {
                        before: locale.t(locale.keys.mfpDefaultSetting.switchButton.mixedSize),
                        after: locale.t(locale.keys.mfpDefaultSetting.switchButton.confirmA3.horizontal),
                    }),
                );
            } else {
                message.push(
                    locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.size, {
                        before: locale.t(locale.keys.mfpDefaultSetting.switchButton.sameSize),
                        after: locale.t(locale.keys.mfpDefaultSetting.switchButton.confirmA3.horizontal),
                    }),
                );
            }
            break;
        case 15:
            if (defaultSetting.scanAttributeData.mixedSize) {
                message.push(
                    locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.size, {
                        before: locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.indefinite) + ':' + locale.t(locale.keys.mfpDefaultSetting.switchButton.mixedSize),
                        after: locale.t(locale.keys.mfpDefaultSetting.switchButton.confirmA3.horizontal),
                    }),
                );
            } else {
                message.push(
                    locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.size, {
                        before: locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.indefinite) + ':' + locale.t(locale.keys.mfpDefaultSetting.switchButton.sameSize),
                        after: locale.t(locale.keys.mfpDefaultSetting.switchButton.confirmA3.horizontal),
                    }),
                );
            }
            break;
    }

    // 集約
    if (defaultSetting.scanAttributeData.aggregation) {
        message.push(
            locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.aggregation, {
                before: locale.t(locale.keys.mfpDefaultSetting.switchButton.summarizeEach4sheets),
                after: locale.t(locale.keys.mfpDefaultSetting.switchButton.noSummary),
            }),
        );
        scanAttributeData = { ...scanAttributeData, aggregation: false };
    }

    // 解像度
    switch (defaultSetting.scanAttributeData.resolution) {
        case 200:
            message.push(
                locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.resolution, {
                    before: '200' + locale.t(locale.keys.mfpDefaultSetting.switchButton.dpi),
                    after: '300' + locale.t(locale.keys.mfpDefaultSetting.switchButton.dpi),
                }),
            );
            scanAttributeData = { ...scanAttributeData, resolution: 300 };
            break;
        case 400:
            message.push(
                locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.resolution, {
                    before: '400' + locale.t(locale.keys.mfpDefaultSetting.switchButton.dpi),
                    after: '300' + locale.t(locale.keys.mfpDefaultSetting.switchButton.dpi),
                }),
            );
            scanAttributeData = { ...scanAttributeData, resolution: 300 };
            break;
        case 600:
            message.push(
                locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.resolution, {
                    before: '600' + locale.t(locale.keys.mfpDefaultSetting.switchButton.dpi),
                    after: '300' + locale.t(locale.keys.mfpDefaultSetting.switchButton.dpi),
                }),
            );
            scanAttributeData = { ...scanAttributeData, resolution: 300 };
            break;
    }

    // 原稿種類
    switch (defaultSetting.scanAttributeData.colorType) {
        case 0:
            message.push(
                locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.colorType, {
                    before: locale.t(locale.keys.mfpDefaultSetting.radioButton.blackWhiteCharacters),
                    after: locale.t(locale.keys.mfpDefaultSetting.radioButton.fullColorTextPhoto),
                }),
            );
            scanAttributeData = { ...scanAttributeData, colorType: 5 };
            break;
        case 1:
            message.push(
                locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.colorType, {
                    before: locale.t(locale.keys.mfpDefaultSetting.radioButton.blackWhiteCharactersCharts),
                    after: locale.t(locale.keys.mfpDefaultSetting.radioButton.fullColorTextPhoto),
                }),
            );
            scanAttributeData = { ...scanAttributeData, colorType: 5 };
            break;
        case 2:
            message.push(
                locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.colorType, {
                    before: locale.t(locale.keys.mfpDefaultSetting.radioButton.blackWhiteTextPhoto),
                    after: locale.t(locale.keys.mfpDefaultSetting.radioButton.fullColorTextPhoto),
                }),
            );
            scanAttributeData = { ...scanAttributeData, colorType: 5 };
            break;
        case 3:
            message.push(
                locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.colorType, {
                    before: locale.t(locale.keys.mfpDefaultSetting.radioButton.blackWhitePhoto),
                    after: locale.t(locale.keys.mfpDefaultSetting.radioButton.fullColorTextPhoto),
                }),
            );
            scanAttributeData = { ...scanAttributeData, colorType: 5 };
            break;
        case 7:
            message.push(
                locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.colorType, {
                    before: locale.t(locale.keys.mfpDefaultSetting.radioButton.automaticColorSelection),
                    after: locale.t(locale.keys.mfpDefaultSetting.radioButton.fullColorTextPhoto),
                }),
            );
            scanAttributeData = { ...scanAttributeData, colorType: 5 };
            break;
    }

    // 保存形式
    switch (defaultSetting.scanAttributeData.outputType) {
        case 2:
            message.push(
                locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.outputType, {
                    before: locale.t(locale.keys.mfpDefaultSetting.radioButton.confirmTiff.multi),
                    after: locale.t(locale.keys.mfpDefaultSetting.radioButton.confirmPdf.single),
                }),
            );
            scanAttributeData = { ...scanAttributeData, outputType: 1 };
            break;
        case 9:
            message.push(
                locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.outputType, {
                    before: locale.t(locale.keys.mfpDefaultSetting.radioButton.confirmCompactPdf.single),
                    after: locale.t(locale.keys.mfpDefaultSetting.radioButton.confirmPdf.single),
                }),
            );
            scanAttributeData = { ...scanAttributeData, outputType: 1 };
            break;
        case 10:
            message.push(
                locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.outputType, {
                    before: locale.t(locale.keys.mfpDefaultSetting.radioButton.confirmCompactPdf.multi),
                    after: locale.t(locale.keys.mfpDefaultSetting.radioButton.confirmPdf.single),
                }),
            );
            scanAttributeData = { ...scanAttributeData, outputType: 1 };
            break;
    }

    // 画像切出し有効時、「outputType: 1（シングルPDF）」と「mixedSize: false（自動検知）」はパラメータに関わらず設定変更
    return { defaultSetting: { ...defaultSetting, scanAttributeData: { ...scanAttributeData, mixedSize: false, outputType: 1 } }, message };
};

export const changeByDisabledDoCutImage = (defaultSetting: schema.V1ObjectsEndpointDefaultSetting) => {
    return {
        ...defaultSetting,
        scanAttributeData: {
            ...defaultSetting.scanAttributeData,
            size: 0,
            mixedSize: false,
        },
    };
};

const dataDefault: schema.V1ObjectsEndpointDefaultSetting = {
    pdfAttributeData: {},
    printAttributeData: {
        color: 0,
        copies: 1, // 1 ~ 999
        mediaSizeName: 0, // 0 ~ 4
        mediaTray: 0, // 0 ~ 1
        numberUp: 0, // [0, 2, 4]
        presentationDirection: 0, // 0 ~ 1
        punch: 0, // 0 ~ 3
        separatorLine: false,
        sheetCollate: 1, // 0 ~ 3
        sides: 0, // 0 ~ 4
        staple: 0, // 0 ~ 8
        displayPdf: true,
        displayXls: false,
        displayDoc: false,
        displayPpt: false,
    },
    scanAttributeData: {
        aggregation: false,
        autoDensity: true,
        batch: false,
        colorType: 7, // 0 ~ 7
        density: 5, // 2 ~ 8
        eraseBorder: false,
        eraseBorderWidthBottom: 10, // 2 ~ 99
        eraseBorderWidthLeft: 10, // 2 ~ 99
        eraseBorderWidthRight: 10, // 2 ~ 99
        eraseBorderWidthTop: 10, // 2 ~ 99
        eraseCenterWidth: 10, // 2 ~ 99
        eraseBorderWidthSame: 10, // 2 ~ 99
        eraseCenter: false,
        eraseSettingSame: false,
        mixedSize: false,
        outputType: 3, // [0, 1, 2, 3, 9, 10]
        preset1Horizontal: 0, // 128 ~ 999
        preset1Vertical: 0, // 128 ~ 999
        preset2Horizontal: 0, // 128 ~ 999
        preset2Vertical: 0, // 128 ~ 999
        preset3Horizontal: 0, // 128 ~ 999
        preset3Vertical: 0, // 128 ~ 999
        resolution: 200, // [200, 300, 400, 600]
        rotate: false,
        sadf: false,
        scanPreview: true,
        size: 0,
        scanBothSide: false,
        clippedVertical: true,
        resolutionLock: false,
        orgResolutionLock: false,
        colorTypeLock: false,
        orgColorTypeLock: false,
        omitBlankPage: false,
    },
    paramData: {
        folderDepth: 2,
        autoSelect: false,
        iconVisible: true,
        folderTitle1: locale.t(locale.keys.mfpDefaultSetting.valueFolder.folder1),
        folderTitle2: locale.t(locale.keys.mfpDefaultSetting.valueFolder.folder2),
        folderTitle3: locale.t(locale.keys.mfpDefaultSetting.valueFolder.folder3),
        folderTitle4: locale.t(locale.keys.mfpDefaultSetting.valueFolder.folder4),
        folderFormat: 0,
        overwrite: 0,
        scanOverwriteAlert: true,
    },
    mfpInfo: {
        deviceName: '',
        mfpId: '',
        type: '',
    },
};

interface DialogObjectInfo {
    isOpenDialog: boolean;
    message1?: string;
    message2?: string;
    message3?: string;
    message4?: string;
    lastMessage?: string;
    renderChildren?: () => JSX.Element;
    title?: string;
    type?: 'alert';
    functionHandleConfirmChanges: () => void;
}

const initDialogObject = {
    isOpenDialog: false,
    lastMessage: '',
    functionHandleConfirmChanges: () => {},
};

const minEraseBorderWidth = 2;
const minPreset = 128;
const folderDepthMax = 4;
const presetSizeMax = 999;
const eraseSizeMax = 99;
const copyMax = 999;
const maxLengthFolderTitle = 32;

const useMfpSettingContainer = () => {
    const [ui, setUI] = useState(UI.state.Loaded);
    const [isEdit, setIsEdit] = useState(false);
    const appContainer = UserAppContainer.useContainer();
    const [defaultSetting, setDefaultSetting] = useState<schema.V1ObjectsEndpointDefaultSetting>(dataDefault);
    const [endpoint, setEndpoint] = useState();
    const [eraseBorderCenter, setEraseBorderCenter] = useState('1');
    const [showPassword, setShowPassword] = useState(false);
    const [title, setTitle] = useState('');
    const [dialogObject, setDialogObject] = useState<DialogObjectInfo>(initDialogObject);

    const handleCloseDialog = () => {
        setDialogObject(initDialogObject);
    };

    const handleConfirmChangePreviewToClearLight = (value: string) => {
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, outputType: Number(value), scanPreview: false } });
        setDialogObject(initDialogObject);
        setIsEdit(true);
    };

    const mapingDataDefault = (defaultSetting: schema.V1ObjectsEndpointDefaultSetting) => {
        if (!defaultSetting) {
            return;
        }
        const mapingData: schema.V1ObjectsEndpointDefaultSetting = {
            pdfAttributeData: Object.assign({}, dataDefault.pdfAttributeData, defaultSetting.pdfAttributeData),
            printAttributeData: Object.assign({}, dataDefault.printAttributeData, defaultSetting.printAttributeData),
            scanAttributeData: Object.assign({}, dataDefault.scanAttributeData, defaultSetting.scanAttributeData),
            mfpInfo: Object.assign({}, dataDefault.mfpInfo, defaultSetting.mfpInfo),
            paramData: Object.assign({}, dataDefault.paramData, defaultSetting.paramData),
        };
        setDefaultSetting(mapingData);
        let eraseBorder = false;
        let eraseCenter = false;
        let txtTitle = locale.t(locale.keys.mfpDefaultSetting.indexDefault);
        if (defaultSetting.mfpInfo && defaultSetting.mfpInfo.mfpId) {
            txtTitle = defaultSetting.mfpInfo.deviceName || '';
        }
        setTitle(txtTitle);
        if (mapingData.scanAttributeData.eraseBorder) {
            eraseBorder = mapingData.scanAttributeData.eraseBorder;
        }
        if (mapingData.scanAttributeData.eraseCenter) {
            eraseCenter = mapingData.scanAttributeData.eraseCenter;
        }
        handleInitEraseBorderCenter(eraseBorder, eraseCenter);
    };

    const getDefaultSetting = async (id: string) => {
        try {
            const response = await defaultSettingsModel.showDefaultSetting(id, appContainer.values.authorizationCode);
            if (!response) {
                setUI(UI.state.Loaded);
                appContainer.setLoadingState(false);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.error.notFound),
                    variant: Variants.error,
                });
                return;
            }
            mapingDataDefault(response.defaultSettings);
            setEndpoint(response.endpoint);
        } catch (e) {
            setUI(UI.state.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const handleInitEraseBorderCenter = (eraseBorder: boolean, eraseCenter: boolean) => {
        if (eraseBorder && eraseCenter) {
            setEraseBorderCenter('4');
        }
        if (!eraseBorder && eraseCenter) {
            setEraseBorderCenter('2');
        }
        if (eraseBorder && !eraseCenter) {
            setEraseBorderCenter('3');
        }
        if (!eraseBorder && !eraseCenter) {
            setEraseBorderCenter('1');
        }
    };

    const handleClickUpdateDefaultSetting = async (mfpSettingId: string) => {
        try {
            setUI(UI.state.Loading);
            setIsEdit(false);
            const defaultSettingObject: schema.V1EndpointsDefaultSettingsUpdateRequest = {
                endpointPassword: endpoint.endpointPassword || '',
                isExistDefault: true,
                pdfAttributeData: Object.assign({}, defaultSetting.pdfAttributeData),
                printAttributeData: Object.assign({}, defaultSetting.printAttributeData),
                scanAttributeData: Object.assign({}, defaultSetting.scanAttributeData),
                paramData: Object.assign({}, defaultSetting.paramData),
            };

            if (defaultSettingObject.paramData.folderDepth === 0) {
                defaultSettingObject.paramData.folderDepth = 1;
            }
            if (defaultSettingObject.printAttributeData.copies === 0) {
                defaultSettingObject.printAttributeData.copies = 1;
            }

            if (defaultSettingObject.scanAttributeData.eraseBorderWidthBottom === 0 || defaultSettingObject.scanAttributeData.eraseBorderWidthBottom === 1) {
                defaultSettingObject.scanAttributeData.eraseBorderWidthBottom = minEraseBorderWidth;
            }
            if (defaultSettingObject.scanAttributeData.eraseBorderWidthLeft === 0 || defaultSettingObject.scanAttributeData.eraseBorderWidthLeft === 1) {
                defaultSettingObject.scanAttributeData.eraseBorderWidthLeft = minEraseBorderWidth;
            }
            if (defaultSettingObject.scanAttributeData.eraseBorderWidthRight === 0 || defaultSettingObject.scanAttributeData.eraseBorderWidthRight === 1) {
                defaultSettingObject.scanAttributeData.eraseBorderWidthRight = minEraseBorderWidth;
            }
            if (defaultSettingObject.scanAttributeData.eraseBorderWidthTop === 0 || defaultSettingObject.scanAttributeData.eraseBorderWidthTop === 1) {
                defaultSettingObject.scanAttributeData.eraseBorderWidthTop = minEraseBorderWidth;
            }
            if (defaultSettingObject.scanAttributeData.eraseCenterWidth === 0 || defaultSettingObject.scanAttributeData.eraseCenterWidth === 1) {
                defaultSettingObject.scanAttributeData.eraseCenterWidth = minEraseBorderWidth;
            }
            if (defaultSettingObject.scanAttributeData.eraseBorderWidthSame === 0 || defaultSettingObject.scanAttributeData.eraseBorderWidthSame === 1) {
                defaultSettingObject.scanAttributeData.eraseBorderWidthSame = minEraseBorderWidth;
            }

            if (
                typeof defaultSettingObject.scanAttributeData.preset1Horizontal === 'number' &&
                defaultSetting.scanAttributeData.preset1Horizontal !== 0 &&
                defaultSettingObject.scanAttributeData.preset1Horizontal < minPreset
            ) {
                defaultSettingObject.scanAttributeData.preset1Horizontal = minPreset;
            }
            if (
                typeof defaultSettingObject.scanAttributeData.preset1Vertical === 'number' &&
                defaultSetting.scanAttributeData.preset1Vertical !== 0 &&
                defaultSettingObject.scanAttributeData.preset1Vertical < minPreset
            ) {
                defaultSettingObject.scanAttributeData.preset1Vertical = minPreset;
            }
            if (
                typeof defaultSettingObject.scanAttributeData.preset2Horizontal === 'number' &&
                defaultSetting.scanAttributeData.preset2Horizontal !== 0 &&
                defaultSettingObject.scanAttributeData.preset2Horizontal < minPreset
            ) {
                defaultSettingObject.scanAttributeData.preset2Horizontal = minPreset;
            }
            if (
                typeof defaultSettingObject.scanAttributeData.preset2Vertical === 'number' &&
                defaultSetting.scanAttributeData.preset2Vertical !== 0 &&
                defaultSettingObject.scanAttributeData.preset2Vertical < minPreset
            ) {
                defaultSettingObject.scanAttributeData.preset2Vertical = minPreset;
            }
            if (
                typeof defaultSettingObject.scanAttributeData.preset3Horizontal === 'number' &&
                defaultSetting.scanAttributeData.preset3Horizontal !== 0 &&
                defaultSettingObject.scanAttributeData.preset3Horizontal < minPreset
            ) {
                defaultSettingObject.scanAttributeData.preset3Horizontal = minPreset;
            }
            if (
                typeof defaultSettingObject.scanAttributeData.preset3Vertical === 'number' &&
                defaultSetting.scanAttributeData.preset3Vertical !== 0 &&
                defaultSettingObject.scanAttributeData.preset3Vertical < minPreset
            ) {
                defaultSettingObject.scanAttributeData.preset3Vertical = minPreset;
            }

            const response = await defaultSettingsModel.updateDefaultSetting(mfpSettingId, defaultSettingObject, appContainer.values.authorizationCode);
            if (!response) {
                setUI(UI.state.Loaded);
                appContainer.setLoadingState(false);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.error.notFound),
                    variant: Variants.error,
                });
                return;
            }
            setUI(UI.state.Loaded);
            appContainer.setLoadingState(false);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.updated),
                variant: Variants.success,
            });
        } catch (e) {
            setUI(UI.state.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const handleResetMinValue = () => {
        if (defaultSetting.paramData && defaultSetting.paramData.folderDepth === 0) {
            setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, folderDepth: 1 } });
        }
        if (defaultSetting.printAttributeData.copies === 0) {
            setDefaultSetting({ ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, copies: 1 } });
        }

        if (defaultSetting.scanAttributeData.eraseBorderWidthBottom === 0 || defaultSetting.scanAttributeData.eraseBorderWidthBottom === 1) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthBottom: minEraseBorderWidth } });
        }
        if (defaultSetting.scanAttributeData.eraseBorderWidthLeft === 0 || defaultSetting.scanAttributeData.eraseBorderWidthLeft === 1) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthLeft: minEraseBorderWidth } });
        }
        if (defaultSetting.scanAttributeData.eraseBorderWidthRight === 0 || defaultSetting.scanAttributeData.eraseBorderWidthRight === 1) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthRight: minEraseBorderWidth } });
        }
        if (defaultSetting.scanAttributeData.eraseBorderWidthTop === 0 || defaultSetting.scanAttributeData.eraseBorderWidthTop === 1) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthTop: minEraseBorderWidth } });
        }
        if (defaultSetting.scanAttributeData.eraseCenterWidth === 0 || defaultSetting.scanAttributeData.eraseCenterWidth === 1) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseCenterWidth: minEraseBorderWidth } });
        }
        if (defaultSetting.scanAttributeData.eraseBorderWidthSame === 0 || defaultSetting.scanAttributeData.eraseBorderWidthSame === 1) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthSame: minEraseBorderWidth } });
        }

        if (
            typeof defaultSetting.scanAttributeData.preset1Horizontal === 'number' &&
            defaultSetting.scanAttributeData.preset1Horizontal !== 0 &&
            defaultSetting.scanAttributeData.preset1Horizontal < minPreset
        ) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset1Horizontal: 0 } });
        }
        if (
            typeof defaultSetting.scanAttributeData.preset1Vertical === 'number' &&
            defaultSetting.scanAttributeData.preset1Vertical !== 0 &&
            defaultSetting.scanAttributeData.preset1Vertical < minPreset
        ) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset1Vertical: 0 } });
        }
        if (
            typeof defaultSetting.scanAttributeData.preset2Horizontal === 'number' &&
            defaultSetting.scanAttributeData.preset2Horizontal !== 0 &&
            defaultSetting.scanAttributeData.preset2Horizontal < minPreset
        ) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset2Horizontal: 0 } });
        }
        if (
            typeof defaultSetting.scanAttributeData.preset2Vertical === 'number' &&
            defaultSetting.scanAttributeData.preset2Vertical !== 0 &&
            defaultSetting.scanAttributeData.preset2Vertical < minPreset
        ) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset2Vertical: 0 } });
        }
        if (
            typeof defaultSetting.scanAttributeData.preset3Horizontal === 'number' &&
            defaultSetting.scanAttributeData.preset3Horizontal !== 0 &&
            defaultSetting.scanAttributeData.preset3Horizontal < minPreset
        ) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset3Horizontal: 0 } });
        }
        if (
            typeof defaultSetting.scanAttributeData.preset3Vertical === 'number' &&
            defaultSetting.scanAttributeData.preset3Vertical !== 0 &&
            defaultSetting.scanAttributeData.preset3Vertical < minPreset
        ) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset3Vertical: 0 } });
        }
    };

    // handle for tab one

    const handleChangeNoLayer = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            return;
        }
        let folderDepth = Number(event.target.value);
        if (folderDepth > folderDepthMax) {
            folderDepth = folderDepthMax;
        }
        if (folderDepth < 0) {
            folderDepth = 0;
        }
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, folderDepth } });
        setIsEdit(true);
    };

    const handleClickAddALayer = () => {
        if (defaultSetting.paramData && defaultSetting.paramData.folderDepth && defaultSetting.paramData.folderDepth < folderDepthMax) {
            setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, folderDepth: defaultSetting.paramData.folderDepth + 1 } });
            setIsEdit(true);
        }
    };

    const handleClickRemoveALayer = () => {
        if (defaultSetting.paramData && defaultSetting.paramData.folderDepth && defaultSetting.paramData.folderDepth > 1) {
            setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, folderDepth: defaultSetting.paramData.folderDepth - 1 } });
            setIsEdit(true);
        }
    };

    const handleChangeFolder1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (getStringByteCount(event.target.value) > maxLengthFolderTitle) {
            return;
        }
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, folderTitle1: event.target.value } });
        setIsEdit(true);
    };

    const onBlurFolder1 = () => {
        if (!defaultSetting || !defaultSetting.paramData || !defaultSetting.paramData.folderTitle1) {
            return;
        }
        const newInput = removeEmojiChar(defaultSetting.paramData.folderTitle1);
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, folderTitle1: newInput } });
    };

    const handleChangeFolder2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (getStringByteCount(event.target.value) > maxLengthFolderTitle) {
            return;
        }
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, folderTitle2: event.target.value } });
        setIsEdit(true);
    };

    const onBlurFolder2 = () => {
        if (!defaultSetting || !defaultSetting.paramData || !defaultSetting.paramData.folderTitle2) {
            return;
        }
        const newInput = removeEmojiChar(defaultSetting.paramData.folderTitle2);
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, folderTitle2: newInput } });
    };

    const handleChangeFolder3 = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (getStringByteCount(event.target.value) > maxLengthFolderTitle) {
            return;
        }
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, folderTitle3: event.target.value } });
        setIsEdit(true);
    };

    const onBlurFolder3 = () => {
        if (!defaultSetting || !defaultSetting.paramData || !defaultSetting.paramData.folderTitle3) {
            return;
        }
        const newInput = removeEmojiChar(defaultSetting.paramData.folderTitle3);
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, folderTitle3: newInput } });
    };

    const handleChangeFolder4 = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (getStringByteCount(event.target.value) > maxLengthFolderTitle) {
            return;
        }
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, folderTitle4: event.target.value } });
        setIsEdit(true);
    };

    const onBlurFolder4 = () => {
        if (!defaultSetting || !defaultSetting.paramData || !defaultSetting.paramData.folderTitle4) {
            return;
        }
        const newInput = removeEmojiChar(defaultSetting.paramData.folderTitle4);
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, folderTitle4: newInput } });
    };

    // const splitCharacterIfOutOfMaximum = (data: string) => {
    //     for (let i = data.length; i > 0; i -= 1) {
    //         const tmpStr = data.substring(0, i);
    //         if (getStringByteCount(tmpStr) <= maxLengthFolderTitle) {
    //             return tmpStr;
    //         }
    //     }
    //     return '';
    // };

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        // validate half width
        if (event.target.value && !event.target.value.match(regexEndpointPw)) {
            return;
        }

        setEndpoint({
            ...endpoint,
            endpointPassword: event.target.value,
        });
        setIsEdit(true);
    };

    const onBlurEndpointPwd = () => {
        const newInput = removeEmojiChar(endpoint.endpointPassword);
        setEndpoint({
            ...endpoint,
            endpointPassword: newInput,
        });
    };

    const handleChangeWidth1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            return;
        }
        let sizeForm = Number(event.target.value);
        if (sizeForm > presetSizeMax) {
            sizeForm = presetSizeMax;
        }
        if (sizeForm < 0) {
            sizeForm = 0;
        }
        if (sizeForm === 0 && defaultSetting.scanAttributeData.size === 7) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset1Horizontal: 0, size: 0, mixedSize: false } });
            setIsEdit(true);
            return;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset1Horizontal: sizeForm } });
        setIsEdit(true);
    };

    const handleChangeWidth2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            return;
        }
        let sizeForm = Number(event.target.value);
        if (sizeForm > presetSizeMax) {
            sizeForm = presetSizeMax;
        }
        if (sizeForm < 0) {
            sizeForm = 0;
        }
        if (sizeForm === 0 && defaultSetting.scanAttributeData.size === 8) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset2Horizontal: 0, size: 0, mixedSize: false } });
            setIsEdit(true);
            return;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset2Horizontal: sizeForm } });
        setIsEdit(true);
    };

    const handleChangeWidth3 = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            return;
        }
        let sizeForm = Number(event.target.value);
        if (sizeForm > presetSizeMax) {
            sizeForm = presetSizeMax;
        }
        if (sizeForm < 0) {
            sizeForm = 0;
        }
        if (sizeForm === 0 && defaultSetting.scanAttributeData.size === 9) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset3Horizontal: 0, size: 0, mixedSize: false } });
            setIsEdit(true);
            return;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset3Horizontal: sizeForm } });
        setIsEdit(true);
    };

    const handleChangeHeight1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            return;
        }
        let sizeForm = Number(event.target.value);
        if (sizeForm > presetSizeMax) {
            sizeForm = presetSizeMax;
        }
        if (sizeForm < 0) {
            sizeForm = 0;
        }
        if (sizeForm === 0 && defaultSetting.scanAttributeData.size === 7) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset1Vertical: 0, size: 0, mixedSize: false } });
            setIsEdit(true);
            return;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset1Vertical: sizeForm } });
        setIsEdit(true);
    };

    const handleChangeHeight2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            return;
        }
        let sizeForm = Number(event.target.value);
        if (sizeForm > presetSizeMax) {
            sizeForm = presetSizeMax;
        }
        if (sizeForm < 0) {
            sizeForm = 0;
        }
        if (sizeForm === 0 && defaultSetting.scanAttributeData.size === 8) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset2Vertical: 0, size: 0, mixedSize: false } });
            setIsEdit(true);
            return;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset2Vertical: sizeForm } });
        setIsEdit(true);
    };

    const handleChangeHeight3 = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            return;
        }
        let sizeForm = Number(event.target.value);
        if (sizeForm > presetSizeMax) {
            sizeForm = presetSizeMax;
        }
        if (sizeForm < 0) {
            sizeForm = 0;
        }
        if (sizeForm === 0 && defaultSetting.scanAttributeData.size === 9) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset3Vertical: 0, size: 0, mixedSize: false } });
            setIsEdit(true);
            return;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, preset3Vertical: sizeForm } });
        setIsEdit(true);
    };

    const handleSwitchIconFolder = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, iconVisible: event.target.checked } });
        setIsEdit(true);
    };

    const handleSwitchAutoSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, autoSelect: event.target.checked } });
        setIsEdit(true);
    };

    const handleSwitchScanPreview = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, scanPreview: event.target.checked } });
        setIsEdit(true);
    };

    const handleClickChooseDisplayForm = (displayForm: number) => {
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, folderFormat: displayForm } });
        setIsEdit(true);
    };

    const handleClickDisplayColumns = (displayColumns: number) => {
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, numberOfListDisplayColumns: displayColumns } });
        setIsEdit(true);
    };

    const handleClickChooseOverwrite = (over: number) => {
        setDefaultSetting({ ...defaultSetting, paramData: { ...defaultSetting.paramData, overwrite: over } });
        setIsEdit(true);
    };

    const handleSwitchOverwrite = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultSetting({
            ...defaultSetting,
            paramData: {
                ...defaultSetting.paramData,
                scanOverwriteAlert: event.target.checked,
            },
        });
        setIsEdit(true);
    };

    // handle for tab two

    const handleChangeTypeFormatFile = (event: React.ChangeEvent<{}>, value: string) => {
        if (value === '2' && defaultSetting.scanAttributeData.colorType && [4, 5, 6, 7].includes(defaultSetting.scanAttributeData.colorType)) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, outputType: Number(value), colorType: 0 } }); // black and white: characters
            setIsEdit(true);
            return;
        }
        if ((value === '9' || value === '10') && typeof defaultSetting.scanAttributeData.colorType === 'number' && [0, 1, 2, 3].includes(defaultSetting.scanAttributeData.colorType)) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, outputType: Number(value), colorType: 7 } }); // default
            setIsEdit(true);
            return;
        }
        if (defaultSetting.scanAttributeData.scanPreview && (value === '9' || value === '10')) {
            setDialogObject({
                isOpenDialog: true,
                message1:
                    locale.t(locale.keys.mfpDefaultSetting.message.previewToClearlightPDF.content1) +
                    locale.t(locale.keys.mfpDefaultSetting.message.previewToClearlightPDF.content2) +
                    locale.t(locale.keys.mfpDefaultSetting.message.previewToClearlightPDF.content3) +
                    locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
                message2: undefined,
                message3: undefined,
                lastMessage: undefined,
                functionHandleConfirmChanges: () => handleConfirmChangePreviewToClearLight(value),
            });
            return;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, outputType: Number(value) } });
        setIsEdit(true);
    };

    const handleClickChoosePaperOrientation = (rotate: boolean) => {
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, rotate } });
        setIsEdit(true);
    };

    const handleClickChoosePaperSide = (scanBothSide: boolean, clippedVertical?: boolean) => {
        if (typeof clippedVertical === 'boolean') {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, scanBothSide, clippedVertical } });
            setIsEdit(true);
            return;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, scanBothSide } });
        setIsEdit(true);
    };

    const handleClickChooseSplitPaperFeeding = (batch: boolean, sadf: boolean) => {
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, batch, sadf } });
        setIsEdit(true);
        return;
    };

    /**
     * @param mixedSize: サイズ混載
     * @param isIndefinite: 不定形の自動検知を選択するか否か
     */
    const handleClickChooseAutoDetection = (mixedSize: boolean, isIndefinite?: boolean) => {
        if (isIndefinite) {
            const result = changePaperSize(15, defaultSetting);
            if (result.isOpenDialog) {
                setDialogObject({
                    isOpenDialog: true,
                    message1: result.message1,
                    message2: result.message2,
                    message3: result.message3,
                    message4: result.message4,
                    lastMessage: result.lastMessage,
                    functionHandleConfirmChanges: () => {
                        setDialogObject(initDialogObject);
                        setDefaultSetting({ ...result.defaultSetting, scanAttributeData: { ...result.defaultSetting.scanAttributeData, mixedSize, size: 15 } });
                        setIsEdit(true);
                    },
                });
            } else {
                setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, mixedSize, size: 15, aggregation: false } });
                setIsEdit(true);
            }
            return;
        } else {
            if (defaultSetting.scanAttributeData.aggregation) {
                setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, mixedSize, size: 0, aggregation: false } });
                setIsEdit(true);
                return;
            }
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, mixedSize, size: 0 } });
            setIsEdit(true);
            return;
        }
    };

    const handleClickChooseMixedSizeMode = (mixedSizeMode: number) => {
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, mixedSizeMode } });
        setIsEdit(true);
    };

    const handleClickChoosePaperSize = (size: number) => {
        const result = changePaperSize(size, defaultSetting);

        if (result.isOpenDialog) {
            setDialogObject({
                isOpenDialog: true,
                message1: result.message1,
                message2: result.message2,
                message3: result.message3,
                message4: result.message4,
                lastMessage: result.lastMessage,
                functionHandleConfirmChanges: () => {
                    setDefaultSetting(result.defaultSetting);
                    setDialogObject(initDialogObject);
                    setIsEdit(true);
                },
            });
            return;
        }

        setDefaultSetting(result.defaultSetting);
        setIsEdit(true);
    };

    const handleConfirmChangeAggregation = () => {
        const defaultSettingObjecTmp = { ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, aggregation: true } };
        if (defaultSetting.scanAttributeData.outputType === 0 || defaultSetting.scanAttributeData.outputType === 9) {
            defaultSettingObjecTmp.scanAttributeData.outputType = 1;
        }
        if (defaultSetting.scanAttributeData.outputType === 2 || defaultSetting.scanAttributeData.outputType === 10) {
            defaultSettingObjecTmp.scanAttributeData.outputType = 3;
        }
        setDefaultSetting(defaultSettingObjecTmp);
        setDialogObject(initDialogObject);
        setIsEdit(true);
    };

    const handleClickChooseSummary = (aggregation: boolean) => {
        if (aggregation && defaultSetting.scanAttributeData.outputType === 0) {
            setDialogObject({
                isOpenDialog: true,
                message1:
                    locale.t(locale.keys.mfpDefaultSetting.message.aggregationOutputType.tiffJpegContent1) +
                    locale.t(locale.keys.mfpDefaultSetting.message.aggregationOutputType.content2) +
                    locale.t(locale.keys.mfpDefaultSetting.message.aggregationOutputType.singleContent3) +
                    locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
                message2: undefined,
                message3: undefined,
                lastMessage: undefined,
                functionHandleConfirmChanges: handleConfirmChangeAggregation,
            });
            return;
        }
        if (aggregation && defaultSetting.scanAttributeData.outputType === 2) {
            setDialogObject({
                isOpenDialog: true,
                message1:
                    locale.t(locale.keys.mfpDefaultSetting.message.aggregationOutputType.tiffContent1) +
                    locale.t(locale.keys.mfpDefaultSetting.message.aggregationOutputType.content2) +
                    locale.t(locale.keys.mfpDefaultSetting.message.aggregationOutputType.multiContent3) +
                    locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
                message2: undefined,
                message3: undefined,
                lastMessage: undefined,
                functionHandleConfirmChanges: handleConfirmChangeAggregation,
            });
            return;
        }
        if (aggregation && defaultSetting.scanAttributeData.outputType === 9) {
            setDialogObject({
                isOpenDialog: true,
                message1:
                    locale.t(locale.keys.mfpDefaultSetting.message.aggregationOutputType.singlePdfContent1) +
                    locale.t(locale.keys.mfpDefaultSetting.message.aggregationOutputType.content2) +
                    locale.t(locale.keys.mfpDefaultSetting.message.aggregationOutputType.singleContent3) +
                    locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
                message2: undefined,
                message3: undefined,
                lastMessage: undefined,
                functionHandleConfirmChanges: handleConfirmChangeAggregation,
            });
            return;
        }
        if (aggregation && defaultSetting.scanAttributeData.outputType === 10) {
            setDialogObject({
                isOpenDialog: true,
                message1:
                    locale.t(locale.keys.mfpDefaultSetting.message.aggregationOutputType.multiPdfContent1) +
                    locale.t(locale.keys.mfpDefaultSetting.message.aggregationOutputType.content2) +
                    locale.t(locale.keys.mfpDefaultSetting.message.aggregationOutputType.multiContent3) +
                    locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
                message2: undefined,
                message3: undefined,
                lastMessage: undefined,
                functionHandleConfirmChanges: handleConfirmChangeAggregation,
            });
            return;
        }
        const defaultSettingObjecTmp = { ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, aggregation } };
        if (aggregation && defaultSetting.scanAttributeData.size === 14) {
            defaultSettingObjecTmp.scanAttributeData.size = 0;
        }
        setDefaultSetting(defaultSettingObjecTmp);
        setIsEdit(true);
    };

    const handleClickDocumentThickness = (documentThickness: number) => {
        const defaultSettingObjecTmp = { ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, documentThickness } };
        setDefaultSetting(defaultSettingObjecTmp);
        setIsEdit(true);
    };

    // handle for tab three

    const handleClickChooseResolution = (resolution: number) => {
        let reso = 200;
        if ([200, 300, 400, 600].includes(resolution)) {
            reso = resolution;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, resolution: reso } });
        setIsEdit(true);
    };

    const handleClickChooseGrayLevel = (grayLv: number) => {
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, density: grayLv } });
        setIsEdit(true);
    };

    const handleConfirmOutputTypeToColorType = (outputType: number, colorType: number) => {
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, outputType, colorType } });
        setDialogObject(initDialogObject);
        setIsEdit(true);
    };

    const handleChangeTypeDocument = (event: React.ChangeEvent<{}>, value: string) => {
        const num = Number(value);
        if (defaultSetting.scanAttributeData.outputType === 9 && num === 7) {
            setDialogObject({
                isOpenDialog: true,
                message1:
                    locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content1, {
                        outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.clearlightPdf),
                        pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.singlePage),
                    }) +
                    locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content2, { colorType: locale.t(locale.keys.mfpDefaultSetting.radioButton.automaticColorSelection) }) +
                    locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content3, {
                        pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.singlePage),
                        outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.pdf),
                    }) +
                    locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
                message2: undefined,
                message3: undefined,
                lastMessage: undefined,
                functionHandleConfirmChanges: () => handleConfirmOutputTypeToColorType(1, num),
            });
            return true;
        }
        if ([0, 1, 2, 3].includes(num) && defaultSetting.scanAttributeData.outputType === 9) {
            setDialogObject({
                isOpenDialog: true,
                message1:
                    locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content1, {
                        outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.clearlightPdf),
                        pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.singlePage),
                    }) +
                    locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content2, { colorType: locale.t(locale.keys.mfpDefaultSetting.message.type.blackAndWhiteType) }) +
                    locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content3, {
                        pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.singlePage),
                        outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.pdf),
                    }) +
                    locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
                message2: undefined,
                message3: undefined,
                lastMessage: undefined,
                functionHandleConfirmChanges: () => handleConfirmOutputTypeToColorType(1, num),
            });
            return true;
        }
        if (num === 7 && defaultSetting.scanAttributeData.outputType === 10) {
            setDialogObject({
                isOpenDialog: true,
                message1:
                    locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content1, {
                        outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.clearlightPdf),
                        pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.multiPage),
                    }) +
                    locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content2, { colorType: locale.t(locale.keys.mfpDefaultSetting.radioButton.automaticColorSelection) }) +
                    locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content3, {
                        pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.multiPage),
                        outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.pdf),
                    }) +
                    locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
                message2: undefined,
                message3: undefined,
                lastMessage: undefined,
                functionHandleConfirmChanges: () => handleConfirmOutputTypeToColorType(3, num),
            });
            return true;
        }
        if ([0, 1, 2, 3].includes(num) && defaultSetting.scanAttributeData.outputType === 10) {
            setDialogObject({
                isOpenDialog: true,
                message1:
                    locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content1, {
                        outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.clearlightPdf),
                        pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.multiPage),
                    }) +
                    locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content2, { colorType: locale.t(locale.keys.mfpDefaultSetting.message.type.blackAndWhiteType) }) +
                    locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content3, {
                        pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.multiPage),
                        outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.pdf),
                    }) +
                    locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
                message2: undefined,
                message3: undefined,
                lastMessage: undefined,
                functionHandleConfirmChanges: () => handleConfirmOutputTypeToColorType(3, num),
            });
            return true;
        }
        // ファイルの保存形式がマルチTIFFの場合は白黒以外は選択できない
        if (defaultSetting.scanAttributeData.outputType === 2) {
            switch (num) {
                case 4:
                case 8:
                    setDialogObject({
                        isOpenDialog: true,
                        message1:
                            locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content1, {
                                outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.tiff),
                                pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.multiPage),
                            }) +
                            locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content2, { colorType: locale.t(locale.keys.mfpDefaultSetting.message.type.grayScale) }) +
                            locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content3, {
                                pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.multiPage),
                                outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.pdf),
                            }) +
                            locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
                        message2: undefined,
                        message3: undefined,
                        lastMessage: undefined,
                        functionHandleConfirmChanges: () => handleConfirmOutputTypeToColorType(3, num),
                    });
                    return true;
                case 5:
                case 6:
                case 9:
                    setDialogObject({
                        isOpenDialog: true,
                        message1:
                            locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content1, {
                                outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.tiff),
                                pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.multiPage),
                            }) +
                            locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content2, { colorType: locale.t(locale.keys.mfpDefaultSetting.message.type.fullColor) }) +
                            locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content3, {
                                pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.multiPage),
                                outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.pdf),
                            }) +
                            locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
                        message2: undefined,
                        message3: undefined,
                        lastMessage: undefined,
                        functionHandleConfirmChanges: () => handleConfirmOutputTypeToColorType(3, num),
                    });
                    return true;
                case 7:
                    setDialogObject({
                        isOpenDialog: true,
                        message1:
                            locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content1, {
                                outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.tiff),
                                pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.multiPage),
                            }) +
                            locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content2, {
                                colorType: locale.t(locale.keys.mfpDefaultSetting.radioButton.automaticColorSelection),
                            }) +
                            locale.t(locale.keys.mfpDefaultSetting.message.outputTypeToColorType.content3, {
                                pageType: locale.t(locale.keys.mfpDefaultSetting.message.type.multiPage),
                                outputType: locale.t(locale.keys.mfpDefaultSetting.radioButton.pdf),
                            }) +
                            locale.t(locale.keys.mfpDefaultSetting.message.questionConfirm),
                        message2: undefined,
                        message3: undefined,
                        lastMessage: undefined,
                        functionHandleConfirmChanges: () => handleConfirmOutputTypeToColorType(3, num),
                    });
                    return true;
            }
        }

        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, colorType: num } });
        setIsEdit(true);
    };

    const handleChangeTypeErase = (event: React.ChangeEvent<{}>, value: string) => {
        if (value === '1') {
            setEraseBorderCenter(value);
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorder: false, eraseCenter: false } });
            setIsEdit(true);
        }
        if (value === '2') {
            setEraseBorderCenter(value);
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorder: false, eraseCenter: true } });
            setIsEdit(true);
        }
        if (value === '3') {
            setEraseBorderCenter(value);
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorder: true, eraseCenter: false } });
            setIsEdit(true);
        }
        if (value === '4') {
            setEraseBorderCenter(value);
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorder: true, eraseCenter: true } });
            setIsEdit(true);
        }
    };

    const handleSwitchLockChangeDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, colorTypeLock: event.target.checked } });
        setIsEdit(true);
    };

    const handleSwitchLockChangeResolution = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, resolutionLock: event.target.checked } });
        setIsEdit(true);
    };

    const handleSwitchAutoGrayLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, autoDensity: event.target.checked } });
        setIsEdit(true);
    };

    const handleChangeSpecifyingTheSameWidth = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseSettingSame: event.target.checked } });
        setIsEdit(true);
    };

    const handleChangeOmitBlankPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, omitBlankPage: event.target.checked } });
        setIsEdit(true);
    };

    const handleConfirmDoCutImage = (fixedDefaultSetting: schema.V1ObjectsEndpointDefaultSetting) => {
        setDefaultSetting({
            ...fixedDefaultSetting,
            scanAttributeData: {
                ...fixedDefaultSetting.scanAttributeData,
                doCutImage: true,
            },
        });
        setDialogObject(initDialogObject);
        setIsEdit(true);
    };

    const handleChangeDoCutImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const resultObject = changeByEnabledDoCutImage(defaultSetting);
            const changeLockedMessage = validationSettingLock(defaultSetting);
            if (changeLockedMessage) {
                setDialogObject({
                    isOpenDialog: true,
                    message1: changeLockedMessage,
                    title: locale.t(locale.keys.mfpDefaultSetting.message.error),
                    type: 'alert',
                    functionHandleConfirmChanges: () => {},
                });
            } else if (resultObject.message.length > 0) {
                const renderChildren = () => {
                    return (
                        <>
                            <Typography>{locale.t(locale.keys.mfpDefaultSetting.message.doCutImage.content1)}</Typography>
                            <ul style={{ margin: 0, padding: 0 }}>
                                {resultObject.message.map((message, index) => (
                                    <li style={{ listStyle: 'none', textIndent: '-1.25rem', paddingLeft: '1.25rem' }} key={index}>
                                        {message}
                                    </li>
                                ))}
                            </ul>
                        </>
                    );
                };
                setDialogObject({
                    isOpenDialog: true,
                    renderChildren,
                    functionHandleConfirmChanges: () => handleConfirmDoCutImage(resultObject.defaultSetting),
                });
            } else {
                handleConfirmDoCutImage(resultObject.defaultSetting);
                setIsEdit(true);
            }
        } else {
            const fixedDefaultSetting = changeByDisabledDoCutImage(defaultSetting);
            setDefaultSetting({
                ...fixedDefaultSetting,
                scanAttributeData: {
                    ...fixedDefaultSetting.scanAttributeData,
                    doCutImage: event.target.checked,
                },
            });
            setIsEdit(true);
        }
    };

    const handleChangeEraseBorderWithTop = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            return;
        }
        let width = Number(event.target.value);
        if (width > eraseSizeMax) {
            width = eraseSizeMax;
        }
        if (defaultSetting.scanAttributeData.eraseSettingSame) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthSame: width } });
            setIsEdit(true);
            return;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthTop: width } });
        setIsEdit(true);
    };

    const handleChangeEraseBorderWithBottom = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            return;
        }
        let width = Number(event.target.value);
        if (width > eraseSizeMax) {
            width = eraseSizeMax;
        }
        if (defaultSetting.scanAttributeData.eraseSettingSame) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthSame: width } });
            setIsEdit(true);
            return;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthBottom: width } });
        setIsEdit(true);
    };

    const handleChangeEraseBorderWithLeft = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            return;
        }
        let width = Number(event.target.value);
        if (width > eraseSizeMax) {
            width = eraseSizeMax;
        }
        if (defaultSetting.scanAttributeData.eraseSettingSame) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthSame: width } });
            setIsEdit(true);
            return;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthLeft: width } });
        setIsEdit(true);
    };

    const handleChangeEraseBorderWithRight = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            return;
        }
        let width = Number(event.target.value);
        if (width > eraseSizeMax) {
            width = eraseSizeMax;
        }
        if (defaultSetting.scanAttributeData.eraseSettingSame) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthSame: width, eraseBorderWidthRight: width } });
            setIsEdit(true);
            return;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthRight: width } });
        setIsEdit(true);
    };

    const handleChangeEraseCenterWidth = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            return;
        }
        let width = Number(event.target.value);
        if (width > eraseSizeMax) {
            width = eraseSizeMax;
        }
        setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseCenterWidth: width } });
        setIsEdit(true);
    };

    const handleClickAddEraseBorderWithSame = () => {
        if (!defaultSetting.scanAttributeData.eraseSettingSame) {
            return;
        }
        if (defaultSetting.scanAttributeData.eraseBorderWidthSame && defaultSetting.scanAttributeData.eraseBorderWidthSame < eraseSizeMax) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthSame: defaultSetting.scanAttributeData.eraseBorderWidthSame + 1 } });
            setIsEdit(true);
        }
    };

    const handleClickRemoveEraseBorderWithSame = () => {
        if (!defaultSetting.scanAttributeData.eraseSettingSame) {
            return;
        }
        if (defaultSetting.scanAttributeData.eraseBorderWidthSame && defaultSetting.scanAttributeData.eraseBorderWidthSame > 2) {
            setDefaultSetting({ ...defaultSetting, scanAttributeData: { ...defaultSetting.scanAttributeData, eraseBorderWidthSame: defaultSetting.scanAttributeData.eraseBorderWidthSame - 1 } });
            setIsEdit(true);
        }
    };

    // handle for tab four

    const handleChangeNoCopy = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            return;
        }
        let copies = Number(event.target.value);
        if (copies > copyMax) {
            copies = copyMax;
        }
        if (copies < 0) {
            copies = 0;
        }
        setDefaultSetting({ ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, copies } });
        setIsEdit(true);
    };

    const handleClickAddACopy = () => {
        if (defaultSetting.printAttributeData.copies && defaultSetting.printAttributeData.copies < copyMax) {
            setDefaultSetting({ ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, copies: defaultSetting.printAttributeData.copies + 1 } });
            setIsEdit(true);
        }
    };

    const handleClickRemoveACopy = () => {
        if (defaultSetting.printAttributeData.copies && defaultSetting.printAttributeData.copies > 1) {
            setDefaultSetting({ ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, copies: defaultSetting.printAttributeData.copies - 1 } });
            setIsEdit(true);
        }
    };

    const handleClickChooseColor = (color: number) => {
        setDefaultSetting({ ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, color } });
        setIsEdit(true);
    };

    const handleClickChooseTray = (mediaTray: number) => {
        const defaultSettingObjecTmp = { ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, mediaTray } };
        if (mediaTray === 1 && defaultSetting.printAttributeData.sides && defaultSetting.printAttributeData.sides > 0) {
            defaultSettingObjecTmp.printAttributeData.sides = 0;
        }
        if (mediaTray === 1 && defaultSetting.printAttributeData.sheetCollate === 2) {
            defaultSettingObjecTmp.printAttributeData.sheetCollate = 1;
        }
        if (mediaTray === 1 && defaultSetting.printAttributeData.staple && defaultSetting.printAttributeData.staple > 0) {
            defaultSettingObjecTmp.printAttributeData.staple = 0;
        }
        if (mediaTray === 1 && defaultSetting.printAttributeData.punch && defaultSetting.printAttributeData.punch > 0) {
            defaultSettingObjecTmp.printAttributeData.punch = 0;
        }
        setDefaultSetting(defaultSettingObjecTmp);
        setIsEdit(true);
    };

    const handleChangeTypeSide = (event: React.ChangeEvent<{}>, value: string) => {
        const defaultSettingObjecTmp = { ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, sides: Number(value) } };
        if (value > '0' && defaultSetting.printAttributeData.mediaTray === 1) {
            defaultSettingObjecTmp.printAttributeData.mediaTray = 0;
        }

        if (value === '1' && defaultSetting.printAttributeData.staple && [5, 7, 3, 4].includes(defaultSetting.printAttributeData.staple)) {
            defaultSettingObjecTmp.printAttributeData.staple = 0;
        }
        if (value === '1' && defaultSetting.printAttributeData.punch && [2, 3].includes(defaultSetting.printAttributeData.punch)) {
            defaultSettingObjecTmp.printAttributeData.punch = 0;
        }

        if (value === '2' && defaultSetting.printAttributeData.staple && [6, 7].includes(defaultSetting.printAttributeData.staple)) {
            defaultSettingObjecTmp.printAttributeData.staple = 0;
        }
        if (value === '2' && defaultSetting.printAttributeData.punch && [1, 3].includes(defaultSetting.printAttributeData.punch)) {
            defaultSettingObjecTmp.printAttributeData.punch = 0;
        }

        if ((value === '3' || value === '4') && defaultSetting.printAttributeData.sheetCollate === 0) {
            defaultSettingObjecTmp.printAttributeData.sheetCollate = 1;
        }
        if ((value === '3' || value === '4') && defaultSetting.printAttributeData.numberUp && defaultSetting.printAttributeData.numberUp > 0) {
            defaultSettingObjecTmp.printAttributeData.numberUp = 0;
        }
        if ((value === '3' || value === '4') && defaultSetting.printAttributeData.staple && [1, 2, 3, 4, 5, 6, 7].includes(defaultSetting.printAttributeData.staple)) {
            defaultSettingObjecTmp.printAttributeData.staple = 0;
        }
        if ((value === '3' || value === '4') && defaultSetting.printAttributeData.punch && [1, 2, 3].includes(defaultSetting.printAttributeData.punch)) {
            defaultSettingObjecTmp.printAttributeData.punch = 0;
        }
        setDefaultSetting(defaultSettingObjecTmp);
        setIsEdit(true);
    };

    const handleChangeTypeStaple = (event: React.ChangeEvent<{}>, value: string) => {
        const defaultSettingObjecTmp = { ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, staple: Number(value) } };

        if (value > '0' && defaultSetting.printAttributeData.mediaTray === 1) {
            defaultSettingObjecTmp.printAttributeData.mediaTray = 0;
        }
        if (value > '0' && typeof defaultSetting.printAttributeData.sheetCollate === 'number' && [0, 2].includes(defaultSetting.printAttributeData.sheetCollate)) {
            defaultSettingObjecTmp.printAttributeData.sheetCollate = 1;
        }

        if (value === '1' && defaultSetting.printAttributeData.sides && [3, 4].includes(defaultSetting.printAttributeData.sides)) {
            defaultSettingObjecTmp.printAttributeData.sides = 0;
        }
        if (value === '1' && defaultSetting.printAttributeData.punch === 3) {
            defaultSettingObjecTmp.printAttributeData.punch = 0;
        }

        if (value === '2' && defaultSetting.printAttributeData.sides && [3, 4].includes(defaultSetting.printAttributeData.sides)) {
            defaultSettingObjecTmp.printAttributeData.sides = 0;
        }
        if (value === '2' && defaultSetting.printAttributeData.punch === 3) {
            defaultSettingObjecTmp.printAttributeData.punch = 0;
        }

        if (value === '3' && defaultSetting.printAttributeData.sides && [1, 3, 4].includes(defaultSetting.printAttributeData.sides)) {
            defaultSettingObjecTmp.printAttributeData.sides = 0;
        }
        if (value === '3' && defaultSetting.printAttributeData.punch && [1, 2, 3].includes(defaultSetting.printAttributeData.punch)) {
            defaultSettingObjecTmp.printAttributeData.punch = 0;
        }

        if (value === '4' && defaultSetting.printAttributeData.sides && [1, 3, 4].includes(defaultSetting.printAttributeData.sides)) {
            defaultSettingObjecTmp.printAttributeData.sides = 0;
        }
        if (value === '4' && defaultSetting.printAttributeData.punch === 1) {
            defaultSettingObjecTmp.printAttributeData.punch = 0;
        }

        if (value === '5' && defaultSetting.printAttributeData.sides && [1, 3, 4].includes(defaultSetting.printAttributeData.sides)) {
            defaultSettingObjecTmp.printAttributeData.sides = 0;
        }
        if (value === '5' && defaultSetting.printAttributeData.punch && [1, 3].includes(defaultSetting.printAttributeData.punch)) {
            defaultSettingObjecTmp.printAttributeData.punch = 0;
        }

        if (value === '6' && defaultSetting.printAttributeData.sides && [2, 3, 4].includes(defaultSetting.printAttributeData.sides)) {
            defaultSettingObjecTmp.printAttributeData.sides = 0;
        }
        if (value === '6' && defaultSetting.printAttributeData.punch && [2, 3].includes(defaultSetting.printAttributeData.punch)) {
            defaultSettingObjecTmp.printAttributeData.punch = 0;
        }

        if (value === '7' && defaultSetting.printAttributeData.sides && [1, 2, 3, 4].includes(defaultSetting.printAttributeData.sides)) {
            defaultSettingObjecTmp.printAttributeData.sides = 0;
        }
        if (value === '7' && defaultSetting.printAttributeData.punch && [1, 2].includes(defaultSetting.printAttributeData.punch)) {
            defaultSettingObjecTmp.printAttributeData.punch = 0;
        }

        if (value === '8' && defaultSetting.printAttributeData.punch && [1, 2, 3].includes(defaultSetting.printAttributeData.punch)) {
            defaultSettingObjecTmp.printAttributeData.punch = 0;
        }
        setDefaultSetting(defaultSettingObjecTmp);
        setIsEdit(true);
    };

    const handleClickChooseSummarySheet = (numberUp: number) => {
        const defaultSettingObjecTmp = { ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, numberUp } };
        if (numberUp > 0 && defaultSetting.printAttributeData.sides && [3, 4].includes(defaultSetting.printAttributeData.sides)) {
            defaultSettingObjecTmp.printAttributeData.sides = 0;
        }
        setDefaultSetting(defaultSettingObjecTmp);
        setIsEdit(true);
    };

    const handleClickChooseDirection = (presentationDirection: number) => {
        setDefaultSetting({ ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, presentationDirection } });
        setIsEdit(true);
    };

    const handleSwitchDisplayDividerLine = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultSetting({ ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, separatorLine: event.target.checked } });
        setIsEdit(true);
    };

    const handleClickChooseSpecifyingPaperSize = (mediaSizeName: number) => {
        setDefaultSetting({ ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, mediaSizeName } });
        setIsEdit(true);
    };

    const handleClickChooseSort = (sheetCollate: number) => {
        const defaultSettingObjecTmp = { ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, sheetCollate } };
        if ((sheetCollate === 0 || sheetCollate === 2) && defaultSetting.printAttributeData.staple && defaultSetting.printAttributeData.staple > 0) {
            defaultSettingObjecTmp.printAttributeData.staple = 0;
        }
        if (sheetCollate === 0 && defaultSetting.printAttributeData.sides && [3, 4].includes(defaultSetting.printAttributeData.sides)) {
            defaultSettingObjecTmp.printAttributeData.sides = 0;
        }
        if (sheetCollate === 2 && defaultSetting.printAttributeData.mediaTray === 1) {
            defaultSettingObjecTmp.printAttributeData.mediaTray = 0;
        }
        if (sheetCollate === 2 && defaultSetting.printAttributeData.punch && [1, 2, 3].includes(defaultSetting.printAttributeData.punch)) {
            defaultSettingObjecTmp.printAttributeData.punch = 0;
        }
        setDefaultSetting(defaultSettingObjecTmp);
        setIsEdit(true);
    };

    const handleClickChoosePunch = (punch: number) => {
        const defaultSettingObjecTmp = { ...defaultSetting, printAttributeData: { ...defaultSetting.printAttributeData, punch } };
        if (punch > 0 && defaultSetting.printAttributeData.mediaTray === 1) {
            defaultSettingObjecTmp.printAttributeData.mediaTray = 0;
        }
        if (punch > 0 && defaultSetting.printAttributeData.sheetCollate === 2) {
            defaultSettingObjecTmp.printAttributeData.sheetCollate = 1;
        }

        if (punch === 2 && defaultSetting.printAttributeData.sides && [1, 3, 4].includes(defaultSetting.printAttributeData.sides)) {
            defaultSettingObjecTmp.printAttributeData.sides = 0;
        }
        if (punch === 2 && defaultSetting.printAttributeData.staple && [8, 6, 7, 3].includes(defaultSetting.printAttributeData.staple)) {
            defaultSettingObjecTmp.printAttributeData.staple = 0;
        }

        if (punch === 1 && defaultSetting.printAttributeData.sides && [2, 3, 4].includes(defaultSetting.printAttributeData.sides)) {
            defaultSettingObjecTmp.printAttributeData.sides = 0;
        }
        if (punch === 1 && defaultSetting.printAttributeData.staple && [5, 8, 7, 3, 4].includes(defaultSetting.printAttributeData.staple)) {
            defaultSettingObjecTmp.printAttributeData.staple = 0;
        }

        if (punch === 3 && defaultSetting.printAttributeData.sides && [1, 2, 3, 4].includes(defaultSetting.printAttributeData.sides)) {
            defaultSettingObjecTmp.printAttributeData.sides = 0;
        }
        if (punch === 3 && defaultSetting.printAttributeData.staple && [5, 8, 6, 1, 2, 3].includes(defaultSetting.printAttributeData.staple)) {
            defaultSettingObjecTmp.printAttributeData.staple = 0;
        }
        setDefaultSetting(defaultSettingObjecTmp);
        setIsEdit(true);
    };

    // condition

    const disabledTray1 = () => {
        if (defaultSetting.printAttributeData.sides && defaultSetting.printAttributeData.sides > 0) return true;
        if (defaultSetting.printAttributeData.sheetCollate === 2) return true;
        if (defaultSetting.printAttributeData.staple && defaultSetting.printAttributeData.staple > 0) return true;
        if (defaultSetting.printAttributeData.punch && defaultSetting.printAttributeData.punch > 0) return true;
        return false;
    };

    const disabledSides1 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.staple && [5, 7, 3, 4].includes(defaultSetting.printAttributeData.staple)) return true;
        if (defaultSetting.printAttributeData.punch && [2, 3].includes(defaultSetting.printAttributeData.punch)) return true;
        return false;
    };

    const disabledSides2 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.staple && [6, 7].includes(defaultSetting.printAttributeData.staple)) return true;
        if (defaultSetting.printAttributeData.punch && [1, 3].includes(defaultSetting.printAttributeData.punch)) return true;
        return false;
    };

    const disabledSides34 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.numberUp && defaultSetting.printAttributeData.numberUp > 0) return true;
        if (defaultSetting.printAttributeData.sheetCollate === 0) return true;
        if (defaultSetting.printAttributeData.staple && [1, 2, 3, 4, 5, 6, 7].includes(defaultSetting.printAttributeData.staple)) return true;
        if (defaultSetting.printAttributeData.punch && [1, 2, 3].includes(defaultSetting.printAttributeData.punch)) return true;
        return false;
    };

    const disabledSummarySheet = () => {
        if (defaultSetting.printAttributeData.sides && [3, 4].includes(defaultSetting.printAttributeData.sides)) return true;
        return false;
    };

    const disabledSort0 = () => {
        if (defaultSetting.printAttributeData.sides && [3, 4].includes(defaultSetting.printAttributeData.sides)) return true;
        if (defaultSetting.printAttributeData.staple && defaultSetting.printAttributeData.staple > 0) return true;
        return false;
    };

    const disabledSort2 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.staple && defaultSetting.printAttributeData.staple > 0) return true;
        if (defaultSetting.printAttributeData.punch && [1, 2, 3].includes(defaultSetting.printAttributeData.punch)) return true;
        return false;
    };

    const disabledStaple1 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.sides && [3, 4].includes(defaultSetting.printAttributeData.sides)) return true;
        if (typeof defaultSetting.printAttributeData.sheetCollate === 'number' && [0, 2].includes(defaultSetting.printAttributeData.sheetCollate)) return true;
        if (defaultSetting.printAttributeData.punch && defaultSetting.printAttributeData.punch === 3) return true;
        return false;
    };

    const disabledStaple2 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.sides && [3, 4].includes(defaultSetting.printAttributeData.sides)) return true;
        if (typeof defaultSetting.printAttributeData.sheetCollate === 'number' && [0, 2].includes(defaultSetting.printAttributeData.sheetCollate)) return true;
        if (defaultSetting.printAttributeData.punch && defaultSetting.printAttributeData.punch === 3) return true;
        return false;
    };

    const disabledStaple3 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.sides && [1, 3, 4].includes(defaultSetting.printAttributeData.sides)) return true;
        if (typeof defaultSetting.printAttributeData.sheetCollate === 'number' && [0, 2].includes(defaultSetting.printAttributeData.sheetCollate)) return true;
        if (defaultSetting.printAttributeData.punch && [1, 2, 3].includes(defaultSetting.printAttributeData.punch)) return true;
        return false;
    };

    const disabledStaple4 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.sides && [1, 3, 4].includes(defaultSetting.printAttributeData.sides)) return true;
        if (typeof defaultSetting.printAttributeData.sheetCollate === 'number' && [0, 2].includes(defaultSetting.printAttributeData.sheetCollate)) return true;
        if (defaultSetting.printAttributeData.punch === 1) return true;
        return false;
    };

    const disabledStaple5 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.sides && [1, 3, 4].includes(defaultSetting.printAttributeData.sides)) return true;
        if (typeof defaultSetting.printAttributeData.sheetCollate === 'number' && [0, 2].includes(defaultSetting.printAttributeData.sheetCollate)) return true;
        if (defaultSetting.printAttributeData.punch && [1, 3].includes(defaultSetting.printAttributeData.punch)) return true;
        return false;
    };

    const disabledStaple6 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.sides && [2, 3, 4].includes(defaultSetting.printAttributeData.sides)) return true;
        if (typeof defaultSetting.printAttributeData.sheetCollate === 'number' && [0, 2].includes(defaultSetting.printAttributeData.sheetCollate)) return true;
        if (defaultSetting.printAttributeData.punch && [2, 3].includes(defaultSetting.printAttributeData.punch)) return true;
        return false;
    };

    const disabledStaple7 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.sides && [1, 2, 3, 4].includes(defaultSetting.printAttributeData.sides)) return true;
        if (typeof defaultSetting.printAttributeData.sheetCollate === 'number' && [0, 2].includes(defaultSetting.printAttributeData.sheetCollate)) return true;
        if (defaultSetting.printAttributeData.punch && [1, 2].includes(defaultSetting.printAttributeData.punch)) return true;
        return false;
    };

    const disabledStaple8 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (typeof defaultSetting.printAttributeData.sheetCollate === 'number' && [0, 2].includes(defaultSetting.printAttributeData.sheetCollate)) return true;
        if (defaultSetting.printAttributeData.punch && [1, 2, 3].includes(defaultSetting.printAttributeData.punch)) return true;
        return false;
    };

    const disabledPunch1 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.sides && [1, 3, 4].includes(defaultSetting.printAttributeData.sides)) return true;
        if (defaultSetting.printAttributeData.sheetCollate === 2) return true;
        if (defaultSetting.printAttributeData.staple && [8, 6, 7, 3].includes(defaultSetting.printAttributeData.staple)) return true;
        return false;
    };

    const disabledPunch2 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.sides && [2, 3, 4].includes(defaultSetting.printAttributeData.sides)) return true;
        if (defaultSetting.printAttributeData.sheetCollate === 2) return true;
        if (defaultSetting.printAttributeData.staple && [5, 8, 7, 3, 4].includes(defaultSetting.printAttributeData.staple)) return true;
        return false;
    };

    const disabledPunch3 = () => {
        if (defaultSetting.printAttributeData.mediaTray === 1) return true;
        if (defaultSetting.printAttributeData.sides && [1, 2, 3, 4].includes(defaultSetting.printAttributeData.sides)) return true;
        if (defaultSetting.printAttributeData.sheetCollate === 2) return true;
        if (defaultSetting.printAttributeData.staple && [5, 8, 6, 1, 2, 3].includes(defaultSetting.printAttributeData.staple)) return true;
        return false;
    };

    const disabledOutputType0 = () => {
        if (defaultSetting.scanAttributeData.aggregation) return true;
        return false;
    };

    // マルチTIFFのボタン制御
    const disabledOutputType2 = () => {
        if (defaultSetting.scanAttributeData.aggregation) return true;
        // 白黒以外はdiabled
        if (defaultSetting.scanAttributeData.colorType && [4, 5, 6, 7, 8, 9].includes(defaultSetting.scanAttributeData.colorType)) return true;
        return false;
    };

    // マルチ/シングルクリアライトPDFのボタン制御
    const disabledOutputType910 = () => {
        if (defaultSetting.scanAttributeData.aggregation) return true;
        if (typeof defaultSetting.scanAttributeData.colorType === 'number' && [0, 1, 2, 3, 7].includes(defaultSetting.scanAttributeData.colorType)) return true;
        // 不定形の場合はdisabled
        if (typeof defaultSetting.scanAttributeData.size === 'number' && [7, 8, 9, 15].includes(defaultSetting.scanAttributeData.size)) return true;
        return false;
    };

    const disabledSplitPaperFeeding = () => {
        if (defaultSetting.scanAttributeData.size === 14) return true;
        return false;
    };

    const disabledAggregation1 = () => {
        const size = defaultSetting.scanAttributeData.size;
        if (size === 0 || size === 14 || size === 7 || size === 8 || size === 9 || size === 15) return true;
        return false;
    };

    const disabledOverwrite = (value: number) => {
        if (value === 1 && endpoint && endpoint.type !== schema.V1ObjectsServiceEnum.Dropbox) {
            return true;
        }
        return false;
    };

    const disabledOverwriteAlert = () => {
        if (defaultSetting.paramData && !defaultSetting.paramData.overwrite) {
            return true;
        }
        return false;
    };

    return {
        ui,
        setUI,
        defaultSetting,
        getDefaultSetting,
        handleClickUpdateDefaultSetting,
        handleResetMinValue,
        dialogObject,
        handleCloseDialog,
        title,
        handleConfirmChangePreviewToClearLight,
        // tab one
        endpoint,
        handleChangeNoLayer,
        handleClickAddALayer,
        handleClickRemoveALayer,
        handleChangeFolder1,
        handleChangeFolder2,
        handleChangeFolder3,
        handleChangeFolder4,
        handleChangePassword,
        handleChangeWidth1,
        handleChangeWidth2,
        handleChangeWidth3,
        handleChangeHeight1,
        handleChangeHeight2,
        handleChangeHeight3,
        handleSwitchIconFolder,
        handleSwitchAutoSelect,
        handleSwitchScanPreview,
        handleClickChooseDisplayForm,
        handleClickDisplayColumns,
        isEdit,
        setIsEdit,
        handleClickChooseOverwrite,
        handleSwitchOverwrite,
        showPassword,
        setShowPassword,
        // tab two
        handleChangeTypeFormatFile,
        handleClickChoosePaperOrientation,
        handleClickChoosePaperSide,
        handleClickChooseSplitPaperFeeding,
        handleClickChooseAutoDetection,
        handleClickChooseMixedSizeMode,
        handleClickChoosePaperSize,
        handleClickChooseSummary,
        handleClickDocumentThickness,
        // tab three
        eraseBorderCenter,
        handleClickChooseResolution,
        handleClickChooseGrayLevel,
        handleChangeTypeDocument,
        handleChangeTypeErase,
        handleSwitchLockChangeDocument,
        handleSwitchLockChangeResolution,
        handleSwitchAutoGrayLevel,
        handleChangeSpecifyingTheSameWidth,
        handleChangeEraseBorderWithTop,
        handleChangeEraseBorderWithBottom,
        handleChangeEraseBorderWithLeft,
        handleChangeEraseBorderWithRight,
        handleChangeEraseCenterWidth,
        handleClickAddEraseBorderWithSame,
        handleClickRemoveEraseBorderWithSame,
        handleChangeOmitBlankPage,
        handleChangeDoCutImage,
        // tab four
        handleChangeNoCopy,
        handleClickAddACopy,
        handleClickRemoveACopy,
        handleClickChooseColor,
        handleClickChooseTray,
        handleChangeTypeSide,
        handleChangeTypeStaple,
        handleClickChooseSummarySheet,
        handleClickChooseDirection,
        handleSwitchDisplayDividerLine,
        handleClickChooseSpecifyingPaperSize,
        handleClickChooseSort,
        handleClickChoosePunch,
        // condition tab four
        disabledTray1,
        disabledSides1,
        disabledSides2,
        disabledSides34,
        disabledSummarySheet,
        disabledSort0,
        disabledSort2,
        disabledStaple1,
        disabledStaple2,
        disabledStaple3,
        disabledStaple4,
        disabledStaple5,
        disabledStaple6,
        disabledStaple7,
        disabledStaple8,
        disabledPunch1,
        disabledPunch2,
        disabledPunch3,
        // condition rest tab
        disabledOutputType0,
        disabledOutputType2,
        disabledOutputType910,
        disabledSplitPaperFeeding,
        disabledOverwrite,
        disabledAggregation1,
        disabledOverwriteAlert,
        onBlurFolder1,
        onBlurFolder2,
        onBlurFolder3,
        onBlurFolder4,
        onBlurEndpointPwd,
    };
};
export const MfpSettingContainer = createContainer(useMfpSettingContainer);
