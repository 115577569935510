import { default as React, useState, useEffect } from 'react';
import { WithStyles, Theme, createStyles, withStyles, Paper, Chip, Grid, TextField, InputAdornment } from '@material-ui/core';
import { default as ChipInput } from 'material-ui-chip-input';
import * as validator from '@/common/utils/validator';
import * as IStepMail from '@/user/components/common/endpoints/step-mail/IStepMail';
import locale from '@/common/utils/locale';
import * as webappUtil from '@/common/utils/webappUtil';
import * as schema from '@/bundles/schema/typescript/schema';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            maxWidth: theme.spacing.unit * 40,
            marginTop: theme.spacing.unit * 3,
            marginBottom: theme.spacing.unit * 4,
        },
        content: {
            display: 'flex',
            border: '2px solid #bbbbbb',
            justifyContent: 'left',
            flexWrap: 'wrap',
            minHeight: theme.spacing.unit * 17,
            padding: 10,
            overflowX: 'auto',
            borderRadius: 4,
            maxWidth: theme.spacing.unit * 40,
            marginBottom: theme.spacing.unit * 4,
            marginTop: theme.spacing.unit * 0.5,
        },
        subject: {
            display: 'flex',
            border: '2px solid #bbbbbb',
            justifyContent: 'left',
            flexWrap: 'wrap',
            padding: 10,
            overflowX: 'auto',
            borderRadius: 4,
            width: '100%',
            fontSize: 16,
            fontWeight: 500,
            color: '#333333',
            maxWidth: theme.spacing.unit * 40,
            marginTop: theme.spacing.unit * 0.5,
            marginBottom: theme.spacing.unit * 4,
        },
        contentBody: {
            display: 'flex',
            border: '2px solid #bbbbbb',
            justifyContent: 'left',
            flexWrap: 'wrap',
            minHeight: theme.spacing.unit * 17,
            padding: 10,
            overflowX: 'auto',
            borderRadius: 4,
            width: '100%',
            fontSize: 15,
            fontWeight: 500,
            lineHeight: 1.73,
            color: '#333333',
            maxWidth: theme.spacing.unit * 40,
            marginTop: theme.spacing.unit * 0.5,
            marginBottom: theme.spacing.unit * 4,
        },
        fileMaxSizeTextField: {
            width: 'auto',
            marginTop: theme.spacing.unit * 0.5,
            marginBottom: 5,
        },
        fileMaxSizeInput: {
            height: '100%',
            borderRadius: 4,
            backgroundColor: theme.palette.grey[200],
        },
        notice: {
            fontSize: 12,
            color: theme.palette.error.light,
        },
    });

interface Props extends WithStyles<typeof styles> {
    mailList: (setting: schema.V1ObjectsEndpointsMailSetting) => void;
    mailSetting?: schema.V1ObjectsEndpointsMailSetting;
}

export const Component: React.SFC<Props> = (props: Props) => {
    const { classes, mailList } = props;
    const [form, setForm] = useState(IStepMail.New());
    const [mailSetting, setMailSetting] = useState(
        props.mailSetting || {
            address: [],
            template: locale.t(locale.keys.endpoint.emailXStorage.defaultTemplate),
            subject: locale.t(locale.keys.endpoint.emailXStorage.defaultSubject),
            fileMaxSize: 10,
        },
    );
    const [errors, setErrors] = useState({
        emails: '',
    });

    const onChangeSubject = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (webappUtil.getStringByteCount(event.target.value) > 60) return;
        setMailSetting({ ...mailSetting, subject: event.target.value });
    };

    const onBlurSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMailSetting({ ...mailSetting, subject: webappUtil.removeEmojiChar(event.target.value) });
    };

    const onChangeTemplate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMailSetting({ ...mailSetting, template: event.target.value });
    };

    const onChangeSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.value.match(/^[0-9]*$/gm)) return;
        setMailSetting({ ...mailSetting, fileMaxSize: Number(event.target.value) });
    };

    const onBlurSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(event.target.value) > 20) {
            setMailSetting({ ...mailSetting, fileMaxSize: 20 });
        }
        if (event.target.value === '') {
            setMailSetting({ ...mailSetting, fileMaxSize: 10 });
        }
        if (Number(event.target.value) < 1) {
            setMailSetting({ ...mailSetting, fileMaxSize: 1 });
        }
    };

    useEffect(() => {
        if (props.mailSetting && props.mailSetting.address.length > 0) {
            setForm({ ...form, emails: form.emails.concat(props.mailSetting.address) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let address = form.emails;
        if (Boolean(errors.emails)) {
            address = [];
        }

        if (typeof mailList === 'function') {
            mailSetting.address = address;
            mailList(mailSetting);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.emails, mailSetting.subject, mailSetting.template, mailSetting.fileMaxSize]);

    const handleAdd = (chip: string) => {
        form.validateInit.emails = true;
        const inputArray = chip.split(',').filter((elem, index, self) => self.indexOf(elem) === index && elem.length !== 0 && !form.emails.includes(elem));
        form.emails = form.emails.concat(inputArray);
        setForm(form);
        validate();
    };

    const handleDelete = (data: string) => {
        form.validateInit.emails = true;
        const filteredEmails = form.emails.filter((it) => data !== it);
        form.emails = filteredEmails;
        setForm(form);
        validate();
    };

    const validate = () => {
        const msg = validator.Validate<IStepMail.Form>(form, IStepMail.validations, IStepMail.NewValidation);
        const emailMsg = form.emails
            .map((value) => {
                const attr = { email: value };
                return validator.Validate<{ email: string }>(attr, IStepMail.emailValidations, IStepMail.NewEmailValidation);
            })
            .find((msg) => {
                return msg && msg.email;
            });
        const emails = msg && msg.emails ? msg.emails.toString() : '';
        const emailsFormat = emailMsg && emailMsg.email ? emailMsg.email.toString() : '';
        setErrors({
            emails: !form.validateInit.emails ? '' : `${emails}${emailsFormat}`,
        });
    };

    const errorChipColor = (key: number) => {
        const attr = { email: form.emails[key] };
        const msg = validator.Validate<{ email: string }>(attr, IStepMail.emailValidations, IStepMail.NewEmailValidation);
        return msg && msg.email ? msg.email.toString() : '';
    };

    const customChip = (args: any, key: number) => (
        <Chip key={key} className={args.className} style={{ backgroundColor: errorChipColor(key) ? '#FF9999' : undefined }} onDelete={args.handleDelete} label={args.text} />
    );

    return (
        <Grid container direction="column" className={classes.root}>
            <div>{locale.t(locale.keys.endpoint.emailXStorage.address)}</div>
            <Grid>
                <Paper className={classes.content}>
                    <ChipInput
                        chipRenderer={customChip}
                        fullWidth={true}
                        fullWidthInput={true}
                        disableUnderline={true}
                        error={errors.emails !== ''}
                        value={form.emails}
                        onAdd={(chip: string) => handleAdd(chip)}
                        onDelete={(chip: string) => handleDelete(chip)}
                        helperText={errors.emails}
                    />
                </Paper>
            </Grid>
            <div>{locale.t(locale.keys.endpoint.emailXStorage.subject)}</div>
            <TextField
                className={classes.subject}
                value={mailSetting.subject}
                onChange={onChangeSubject}
                onBlur={onBlurSubject}
                InputProps={{
                    disableUnderline: true,
                }}
            />
            <div>{locale.t(locale.keys.endpoint.emailXStorage.template)}</div>
            <Grid>
                <textarea className={classes.contentBody} id="noter-text-area" name="textarea" rows={5} value={mailSetting.template} onChange={onChangeTemplate} />
            </Grid>
            <div>{locale.t(locale.keys.endpoint.emailXStorage.fileMaxSize)}</div>
            <TextField
                className={classes.fileMaxSizeTextField}
                label={locale.t(locale.keys.endpoint.emailXStorage.fileMaxSizeLabel)}
                variant="filled"
                value={mailSetting.fileMaxSize}
                onChange={onChangeSize}
                onBlur={onBlurSize}
                InputProps={{
                    disableUnderline: true,
                    className: classes.fileMaxSizeInput,
                    endAdornment: <InputAdornment position="end">MB</InputAdornment>,
                    inputProps: {
                        style: { textAlign: 'center' },
                        maxLength: 2,
                    },
                }}
            />
            <div className={classes.notice}>{locale.t(locale.keys.endpoint.emailXStorage.fileMaxSizeNotice)}</div>
        </Grid>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
