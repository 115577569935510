import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import querystring from 'querystring';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const getWordbookList = async (auth: string, brand: schema.Brand = schema.Brand.System): Promise<schema.V1WordbooksIndexResponse> => {
    const query = querystring.stringify({
        brand,
    });

    const response = await axiosFactory.get<schema.V1WordbooksIndexResponse>(`https://${consts.environment.api.hostname}/v1/wordbooks?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const getWordbook = async (auth: string, brand: schema.Brand = schema.Brand.System, industryType: string = '一般業務'): Promise<schema.V1WordbooksShowResponse> => {
    const query = querystring.stringify({
        brand,
    });

    const response = await axiosFactory.get<schema.V1WordbooksShowResponse>(`https://${consts.environment.api.hostname}/v1/wordbooks/${industryType}?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
