import * as React from 'react';

import {
    Theme,
    createStyles,
    WithStyles,
    withStyles,
    Card,
    CardContent,
    CardActions,
    Button,
    TextField,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Grid,
    ClickAwayListener,
    Tooltip,
    IconButton,
    Typography,
} from '@material-ui/core';
import { ExpandMore, InfoOutlined } from '@material-ui/icons';
import { context } from '@/user/components/mfp/Context';
import RemoveButton from '@/user/components/mfp/RemoveButton';
import locale from '@/common/utils/locale';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { Prompt } from 'react-router';
import { MFP, IMFP } from '@/user/models/mfps/shareDevice';

const styles = (theme: Theme) =>
    createStyles({
        select: {
            paddingTop: theme.spacing.unit * 2,
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing.unit * 0,
                flexDirection: 'column',
            },
        },
        content: {
            width: theme.spacing.unit * 43,
            height: theme.spacing.unit * 33,
            marginRight: theme.spacing.unit * 3,
            marginBottom: theme.spacing.unit * 3,
        },
        card: {
            height: 256,
            backgroundColor: '#fff !important',
            border: '1px solid #ccc',
            flexWrap: 'wrap',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        buttonCase: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: `0 ${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
        },
        active: {
            padding: theme.spacing.unit * 2,
            paddingLeft: theme.spacing.unit * 3,
            height: theme.spacing.unit * 24,
            [theme.breakpoints.down('sm')]: {
                height: 'auto',
            },
        },
        textRoot: {
            display: 'block',
            fontWeight: 'bold',
            paddingLeft: 0,
            paddingRight: 0,
            textAlign: 'left',
            wordBreak: 'break-all',
            height: '50px',
            [theme.breakpoints.down('md')]: {
                width: '85%',
            },
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
            fontSize: '1rem',
            marginBottom: theme.spacing.unit * 3,
            color: '#333333',
            '&& span': {
                color: '#333333',
                display: 'block',
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        txtBox: {
            textAlign: 'left',
            marginBottom: theme.spacing.unit * 3,
            fontSize: '18px',
            fontWeight: 500,
            color: '#333333',
            '&& p': {
                margin: 0,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '15px',
            },
        },
        contentBtn: {
            display: 'flex',
            alignItems: 'right',
            marginLeft: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: 'auto',
                height: 'auto',
                marginRight: 'auto',
            },
        },
        title: {
            color: '#616161',
            fontSize: '14px',
            fontWeight: 500,
        },
        pinContainer: {
            textAlign: 'left',
            backgroundColor: theme.palette.grey[200],
            fontWeight: 500,
            width: theme.spacing.unit * 17.5,
            '&& input': {
                textAlign: 'left',
                paddingTop: theme.spacing.unit,
                paddingLeft: theme.spacing.unit,
            },
            '&& input::-webkit-inner-spin-button': {
                appearance: 'none',
                margin: 0,
            },
            '&& input::-webkit-outer-spin-button': {
                appearance: 'none',
                margin: 0,
            },
            '&& label': {
                fontSize: '0.875rem',
                transform: 'none',
                top: theme.spacing.unit,
                left: theme.spacing.unit,
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing.unit * 2,
                marginTop: theme.spacing.unit * 2,
            },
        },
        btnSavePin: {
            textAlign: 'left',
            marginTop: theme.spacing.unit * 1,
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing.unit * 2,
            },
            '&& p': {
                fontSize: '14px',
                fontWeight: 'bold',
            },
        },
        btnSave: {
            backgroundColor: '#F06E00',
            color: 'white',
            visibility: 'visible',
        },
        listItems: {
            paddingTop: theme.spacing.unit * 1,
            position: 'relative',
            display: 'flex',
            flexWrap: 'wrap',
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing.unit * 3,
                flexDirection: 'column',
            },
        },
        expansionPanel: {
            width: 'auto',
            height: 'auto',
            marginLeft: theme.spacing.unit * 2,
            marginRight: theme.spacing.unit * 2,
            border: '1px solid #ccc',
        },
        popper: {
            opacity: 1,
        },
        lightTooltip: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.bodyText.main,
            boxShadow: theme.shadows[1],
            fontSize: 12,
        },
        tooltipButton: {
            // color: theme.palette.common.white,
            padding: 0,
            marginInline: '4px',
        },
    });

interface Props extends WithStyles<typeof styles> {
    theme: Theme;
}

const Consumer = context.Consumer;

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const matchesMd = useMediaQuery(props.theme.breakpoints.up('md'));
    const maxLength = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.toString().slice(0, 6);
    };
    const [deleteButtonDescOpenList, setDeleteButtonDescOpenList] = React.useState<string[]>([]); // idの配列を格納して、複数のtooltipの表示状態を管理

    const getList = (printer: MFP, index: number, app: IMFP) => {
        if (matchesMd) {
            return (
                <div key={index} className={classes.content}>
                    <Card data-testid="PC" className={classes.card}>
                        <CardContent className={classes.active}>
                            <div className={classes.textRoot}>{printer.name}</div>
                            <div className={classes.txtBox}>
                                <p className={classes.title}>{locale.t(locale.keys.mfp.model)}</p>
                                <p>{printer.model}</p>
                            </div>
                            <div className={classes.txtBox}>
                                <p className={classes.title}>{locale.t(locale.keys.mfp.machineNumber)}</p>
                                <p>{printer.number}</p>
                            </div>
                        </CardContent>
                        <CardActions>
                            <div className={classes.contentBtn}>
                                <div className={classes.buttonCase}>
                                    {printer.groups && printer.groups.length !== 0 ? (
                                        <ClickAwayListener
                                            onClickAway={() => {
                                                const newDeleteButtonDescOpenList = deleteButtonDescOpenList.filter((id) => id !== printer.id);
                                                setDeleteButtonDescOpenList(newDeleteButtonDescOpenList);
                                            }}
                                        >
                                            <Tooltip
                                                title={
                                                    <>
                                                        <Typography key={index} variant="caption" color="inherit">
                                                            {locale.t(locale.keys.mfp.tips.invalidDeleteMfpByRegistrationGroup)}
                                                        </Typography>
                                                    </>
                                                }
                                                onClose={() => {
                                                    const newDeleteButtonDescOpenList = deleteButtonDescOpenList.filter((id) => id !== printer.id);
                                                    setDeleteButtonDescOpenList(newDeleteButtonDescOpenList);
                                                }}
                                                open={deleteButtonDescOpenList.includes(printer.id)}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                classes={{ tooltip: props.classes.lightTooltip, popper: props.classes.popper }}
                                            >
                                                <IconButton
                                                    onClick={() => {
                                                        setDeleteButtonDescOpenList([...deleteButtonDescOpenList, printer.id]);
                                                    }}
                                                    className={props.classes.tooltipButton}
                                                >
                                                    <InfoOutlined />
                                                </IconButton>
                                            </Tooltip>
                                        </ClickAwayListener>
                                    ) : (
                                        <RemoveButton textMessageDelete={printer.name} index={index} disabled={printer.groups && printer.groups.length !== 0} />
                                    )}
                                </div>
                            </div>
                        </CardActions>
                    </Card>
                </div>
            );
        }
        return (
            <ExpansionPanel className={classes.expansionPanel} key={index} expanded={app.expanded === printer.id} onChange={app.handleChangeExpanded(printer.id)}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />} aria-controls={`panel${index}bh-content`} id={`panel${index}bh-header`}>
                    <div className={classes.textRoot}>{printer.name}</div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                        <div className={classes.txtBox}>
                            <p className={classes.title}>{locale.t(locale.keys.mfp.model)}</p>
                            <p>{printer.model}</p>
                        </div>
                        <div className={classes.txtBox}>
                            <p className={classes.title}>{locale.t(locale.keys.mfp.machineNumber)}</p>
                            <p>{printer.number}</p>
                        </div>
                        <div className={classes.contentBtn}>
                            <div className={classes.buttonCase}>
                                {printer.groups && printer.groups.length !== 0 ? (
                                    <ClickAwayListener
                                        onClickAway={() => {
                                            const newDeleteButtonDescOpenList = deleteButtonDescOpenList.filter((id) => id !== printer.id);
                                            setDeleteButtonDescOpenList(newDeleteButtonDescOpenList);
                                        }}
                                    >
                                        <Tooltip
                                            title={
                                                <>
                                                    <Typography key={index} variant="caption" color="inherit">
                                                        {locale.t(locale.keys.mfp.tips.invalidDeleteMfpByRegistrationGroup)}
                                                    </Typography>
                                                </>
                                            }
                                            onClose={() => {
                                                const newDeleteButtonDescOpenList = deleteButtonDescOpenList.filter((id) => id !== printer.id);
                                                setDeleteButtonDescOpenList(newDeleteButtonDescOpenList);
                                            }}
                                            open={deleteButtonDescOpenList.includes(printer.id)}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            classes={{ tooltip: props.classes.lightTooltip, popper: props.classes.popper }}
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    setDeleteButtonDescOpenList([...deleteButtonDescOpenList, printer.id]);
                                                }}
                                                className={props.classes.tooltipButton}
                                            >
                                                <InfoOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    </ClickAwayListener>
                                ) : (
                                    <RemoveButton textMessageDelete={printer.name} index={index} disabled={printer.groups && printer.groups.length !== 0} />
                                )}
                            </div>
                        </div>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    return (
        <Consumer>
            {(app) =>
                app && (
                    <div className={classes.select}>
                        <div>
                            <div className={classes.pinContainer}>
                                {!app.isLocked ? (
                                    <TextField
                                        inputProps={{ maxLength: 6 }}
                                        value={app.defaultPinValue}
                                        label={locale.t(locale.keys.modalLabel.mfp.pin)}
                                        onInput={maxLength}
                                        onChange={app.changePin}
                                        error={app.pinError ? true : false}
                                        helperText={app.pinError}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                            {!app.isLocked ? (
                                <div className={classes.btnSavePin}>
                                    <Button variant="contained" disabled={app.pinFormDisable ? true : false} size={'large'} className={classes.btnSave} hidden={false} onClick={app.handleSaveClick}>
                                        {locale.t(locale.keys.mfp.save)}
                                    </Button>
                                </div>
                            ) : (
                                <div className={classes.btnSavePin}>
                                    <p>{locale.t(locale.keys.mfp.locked)}</p>
                                    <Button variant="contained" size={'large'} className={classes.btnSave} onClick={app.handleUnlockDevices}>
                                        {locale.t(locale.keys.mfp.unlock)}
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className={classes.listItems}>{app.mfpList && app.mfpList.map((printer, index) => getList(printer, index, app))}</div>
                        <Prompt when={app.isEdit} message={locale.t(locale.keys.promptMessage)} />
                    </div>
                )
            }
        </Consumer>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
