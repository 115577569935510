import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { indexXStorage } from '@/user/models/x-storages/x-storages';
import moment from 'moment';
import * as auth from '@/user/models/auth';
import * as schema from '@/bundles/schema/typescript/schema';
import locale from '@/common/utils/locale';
import { createEndpoint } from '@/user/models/endpoints/endpoints';
import { State as UI } from '@/common/components/hooks/useUI';
import {
    nameTag,
    DateFormatValue,
    forbiddenWords,
    FOLDER_SIZE,
    WORDBOOK_SIZE,
    separate,
    sequenceType,
    dateSeparate,
    DEFAULT_DATE_SEPARATOR,
    DateSeparateFormatValue,
} from '@/user/constants/endpoint-setting';
import { UserAppContainer } from '../../UserAppContainer';
import * as errorHandler from '@/common/utils/errorHandler';
import { DateFormat } from '@/common/constants/dateFormat';
import { getEnumData, isNotGarble, removeEmojiChar, getStringByteCount } from '@/common/utils/webappUtil';
import { default as icon } from '@/common/img/mfp/index';
import shareFormat from '@/common/constants/shareFormat';
import { getWordbook } from '@/user/models/wordbooks/wordbooks';
import { validateNameRuleChip, validateFileName } from './namerule/validateNameRule';
import { useEndpoint, createEndpoint as createEndpointFormat } from '@/common/models/endpoints/useEndpoints';
import environment from '@/common/constants/environment';

const storagesDefault = {
    xstorages: [
        {
            xstorageId: '',
            authorizationId: '',
            authorizationDeadline: '',
            authorizationLink: '',
            imageLink: '',
            service: schema.V1ObjectsServiceEnum.Box,
            status: schema.V1ObjectsXStorageStatus.Success,
        },
    ],
};

const getDateFormat = (dateFormat: string) => {
    switch (dateFormat) {
        case DateFormatValue.Date2: {
            return DateFormat.fullDateWithHyphen;
        }
        case DateFormatValue.Date3: {
            return DateFormat.fullYearMonthTime;
        }
        case DateFormatValue.Date4: {
            return DateFormat.fullYearDayTime;
        }
        case DateFormatValue.Date5: {
            return DateFormat.fullYearMonthWithHyphen;
        }
        case DateFormatValue.Date6: {
            return DateFormat.fullYearDateWithHyphen;
        }
        case DateFormatValue.Date7: {
            return DateFormat.fullYearTime;
        }
        case DateFormatValue.Date8: {
            return DateFormat.fullYear;
        }
        case DateFormatValue.Date9: {
            return DateFormat.fullMonthDayTime;
        }
        case DateFormatValue.Date10: {
            return DateFormat.fullMonthDateWithHyphen;
        }
        case DateFormatValue.Date11: {
            return DateFormat.fullMonthTime;
        }
        case DateFormatValue.Date12: {
            return DateFormat.fullMonth;
        }
        case DateFormatValue.Date13: {
            return DateFormat.fullDayTime;
        }
        case DateFormatValue.Date14: {
            return DateFormat.fullDate;
        }
        case DateFormatValue.Date15: {
            return DateFormat.fullTime;
        }
        default: {
            return DateFormat.fullDateTimeWithHyphen;
        }
    }
};

export const SeparateDateFormat = (dateFormat: DateFormatValue) => {
    switch (dateFormat) {
        case DateFormatValue.Date1:
            return DateSeparateFormatValue.Date1;
        case DateFormatValue.Date2:
            return DateSeparateFormatValue.Date2;
        case DateFormatValue.Date3:
            return DateSeparateFormatValue.Date3;
        case DateFormatValue.Date4:
            return DateSeparateFormatValue.Date4;
        case DateFormatValue.Date5:
            return DateSeparateFormatValue.Date5;
        case DateFormatValue.Date6:
            return DateSeparateFormatValue.Date6;
        case DateFormatValue.Date7:
            return DateSeparateFormatValue.Date7;
        case DateFormatValue.Date8:
            return DateSeparateFormatValue.Date8;
        case DateFormatValue.Date9:
            return DateSeparateFormatValue.Date9;
        case DateFormatValue.Date10:
            return DateSeparateFormatValue.Date10;
        case DateFormatValue.Date11:
            return DateSeparateFormatValue.Date11;
        case DateFormatValue.Date12:
            return DateSeparateFormatValue.Date12;
        case DateFormatValue.Date13:
            return DateSeparateFormatValue.Date13;
        case DateFormatValue.Date14:
            return DateSeparateFormatValue.Date14;
        case DateFormatValue.Date15:
            return DateSeparateFormatValue.Date15;
        default:
            return DateSeparateFormatValue.Date1;
    }
};

export const fileNameDateExample = (dateSeparatorSelected: string, dateFormat: DateFormatValue, now: moment.Moment) => {
    let tempDate = now.format(getDateFormat(dateFormat));
    switch (dateSeparatorSelected) {
        case dateSeparate.NONE:
            tempDate = tempDate.replace(/-/g, '');
            tempDate = tempDate.replace(' ', '');
            tempDate = tempDate.replace(/:/g, '');
            break;
        case dateSeparate.DOT:
        case dateSeparate.HYPHEN:
        case dateSeparate.UNDERSCORE:
            tempDate = tempDate.replace(/-/g, dateSeparatorSelected);
            tempDate = tempDate.replace(' ', dateSeparatorSelected);
            tempDate = tempDate.replace(/:/g, '');
            break;
        case dateSeparate.C:
            tempDate = now.format(SeparateDateFormat(dateFormat));
            break;
    }
    return tempDate;
};

// unstaged-nextの仕様上、引数はinitialStateという名前でしか渡せない。
// 本来はisSharedのような命名にしたい。
/**
 * @param initialState isShared 通常業務共有画面か否か
 * @returns
 */
const useNewEndpointContainer = (initialState: string = '') => {
    const isShared = initialState === 'shared';
    const regexFolder = new RegExp(`^${locale.t(locale.keys.fileName.folderLabel.index)}([1-9]|1[0-9]|20)$`);
    const regexSpecialCharacter = new RegExp(/^(?!.*\.{2})[^"#%&*,/:;<>?\\{-~]*$/);
    const regexSpecialCharacterForMultipleInput = new RegExp(/^(?!.*\.{2})[^"#%&*/:;<>?\\{-~]*$/);
    const sub = auth.identifyUser()!.sub;
    const [storageList, setStorageList] = useState(storagesDefault);
    const [activeStorage, setActiveStorage] = useState();
    const [endpointName, setEndpointName] = useState('');
    const [endpointIcon, setEndpointIcon] = useState(Object.keys(icon.iconMfps)[0]);
    const [endpointBtnColor, setEndpointBtnColor] = useState(schema.V1ObjectsEndpointsButtonColor.White);
    const [pathName, setPathName] = useState('');
    const [path, setPath] = useState<string>();
    const [tmpPathName, setTmpPathName] = useState('');
    const [idPath, setIdPath] = useState('');
    const [upDownFeature, setFeature] = useState({
        isUploadable: false,
        isDownloadable: false,
    });
    const [itemList, setItemList] = useState([locale.t(locale.keys.fileName.anyCharater.index), locale.t(locale.keys.fileName.date.index)]);
    const [dateFormat, setDateFormat] = useState(DateFormatValue.Date1);
    const [dateFlag, setDateFlag] = useState(true);
    const [userFlag, setUserFlag] = useState(false);
    const [anyCharaterFlag, setAnyCharaterFlag] = useState(true);
    const [folderFlag, setFolderFlag] = useState(false);
    const [separatorSelected, setSeparatorSelected] = useState(separate.DOT);
    const [dateSeparatorSelected, setDateSeparatorSelected] = useState<string>(DEFAULT_DATE_SEPARATOR);
    const [canEmpty, setCanEmpty] = useState(true);
    const appContainer = UserAppContainer.useContainer();
    const [wordListName01, setWordListName01] = useState('単語帳1');
    const [wordListName02, setWordListName02] = useState('単語帳2');
    const [wordList, setWordList] = useState<string[]>([]);
    const [wordList2, setWordList2] = useState<string[]>([]);
    const [isFolderFull, setFolderFullFlag] = useState(false);
    const [ui, setUI] = useState(UI.Loading);
    // 名付けルール定義のエラーメッセージ出し分けのために使用
    const [fileNameErrorMessage, setFileNameErrorMessage] = useState('');
    const [fileNameLimitErrorMessege, setfileNameLimitErrorMessege] = useState('');
    const [errors, setErrors] = useState({
        wordlist: '',
        wordlist2: '',
    });
    const [errorsName, setErrorsName] = useState({
        wordlistname: '',
        wordlistname2: '',
    });
    const itemFolder: number[] = [];
    const [folderNumber, setFolderNumber] = useState(itemFolder);
    const [itemSelected, setItemSelected] = useState(-1);
    const [isYearSelected, setIsYearSelected] = useState(true);
    const [isMonthSelected, setIsMonthSelected] = useState(true);
    const [isDaySelected, setIsDaySelected] = useState(true);
    const [isHourSelected, setIsHourSelected] = useState(true);
    const [open, setOpen] = useState(false);

    const [isLock, setIsLock] = useState(false);

    const [sequenceTypeSelected, setSequenceTypeSelected] = useState(sequenceType.PUT);

    const [emails, setEmails] = useState<string[]>([]);
    const [bodyMail, setBodyMail] = useState(locale.t(locale.keys.endpoint.emailXStorage.defaultTemplate));
    const [subject, setSubject] = useState(locale.t(locale.keys.endpoint.emailXStorage.defaultSubject));
    const [fileMaxSize, setFileMaxSize] = useState(10);

    const endpoints = useEndpoint(isShared);

    const loadInitialData = async () => {
        try {
            setUI(UI.Loading);
            await getStoragesList();
            await getWordList();
            await endpoints.loadWorkspaceData(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode, appContainer.values.signinWorkspaceUserObject.role);
            setUI(UI.Loaded);
        } catch (e) {
            setUI(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const getWordList = async () => {
        try {
            let brand = schema.Brand.System;
            if (appContainer.values.signinWorkspaceObject.brand) {
                brand = getEnumData(appContainer.values.signinWorkspaceObject.brand, schema.Brand);
            }
            const dataRes = await getWordbook(appContainer.values.authorizationCode, brand);
            setWordList(dataRes.templates.wordList);
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const getStoragesList = async () => {
        try {
            let data = {} as schema.V1XStoragesIndexResponse;

            if (isShared) {
                data = await indexXStorage(true, appContainer.values.authorizationCode, shareFormat.share, sub, appContainer.values.signinWorkspaceObject.id);
            } else {
                data = await indexXStorage(true, appContainer.values.authorizationCode, shareFormat.personal, sub, appContainer.values.signinWorkspaceObject.id);
            }
            const noneCloudExists = data.xstorages.find((item) => item.service === schema.V1ObjectsServiceEnum.Nonecloud);
            if (!noneCloudExists) {
                if (isShared) {
                    const item: schema.V1ObjectsXStorage = {
                        xstorageId: schema.V1ObjectsServiceEnum.Nonecloud,
                        authorizationDeadline: '',
                        authorizationLink: '',
                        service: schema.V1ObjectsServiceEnum.Nonecloud,
                        status: schema.V1ObjectsXStorageStatus.Success,
                        isDownloadable: false,
                        isUploadable: true,
                        is2L: false,
                    };
                    data.xstorages.push(item);
                } else {
                    const item: schema.V1ObjectsXStorage = {
                        xstorageId: schema.V1ObjectsServiceEnum.Nonecloud,
                        authorizationDeadline: '',
                        authorizationLink: '',
                        service: schema.V1ObjectsServiceEnum.Nonecloud,
                        status: schema.V1ObjectsXStorageStatus.Success,
                        isDownloadable: false,
                        isUploadable: true,
                        is2L: false,
                        share: shareFormat.personal, // 共有フラグ
                    };
                    data.xstorages.push(item);
                }
            }
            setStorageList(JSON.parse(JSON.stringify(data)));
            setActiveStorage(data.xstorages[0]);
            setFeature({
                isUploadable: data.xstorages[0].isUploadable!,
                isDownloadable: data.xstorages[0].isDownloadable!,
            });
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const getPath = (path: string, idPath?: string) => {
        setPathName(path);
        setPath(path);
        setIdPath(idPath || '');
    };

    const handleSwitch = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeature({ ...upDownFeature, [name]: event.target.checked });
    };
    const removeUnnecessarySpace = (chip: string) => {
        return chip.replace(/  +/g, ' ');
    };

    const isWordInvalid = (item: string) => {
        for (let index = 0; index < forbiddenWords.length; index += 1) {
            const char = forbiddenWords[index];
            if (item.toLowerCase().indexOf(char) === 0) return true;
        }
        return false;
    };

    // 単語帳1の名称変更
    const handleChangeWordListName01 = (wordListName: string) => {
        // 未入力
        if (!wordListName) {
            setErrorsName({
                wordlistname: locale.t(locale.keys.fileName.workBook.noName),
                wordlistname2: errorsName.wordlistname2,
            });
            // 32バイト以上
        } else if (getStringByteCount(wordListName) > 32) {
            setErrorsName({
                wordlistname: locale.t(locale.keys.validation.tooLongByte, { num: 32 }),
                wordlistname2: errorsName.wordlistname2,
            });
            // SJISで表示できない文字をチェック
        } else if (!isNotGarble(wordListName)) {
            setErrorsName({
                wordlistname: locale.t(locale.keys.fileName.workBook.unavailable),
                wordlistname2: errorsName.wordlistname2,
            });
        } else {
            setErrorsName({
                wordlistname: '',
                wordlistname2: errorsName.wordlistname2,
            });
        }
        setWordListName01(wordListName);
    };

    // 単語帳2の名称変更
    const handleChangeWordListName02 = (wordListName: string) => {
        // 未入力
        if (!wordListName) {
            setErrorsName({
                wordlistname: errorsName.wordlistname,
                wordlistname2: locale.t(locale.keys.fileName.workBook.noName),
            });
            // 32バイト以上
        } else if (getStringByteCount(wordListName) > 32) {
            setErrorsName({
                wordlistname: errorsName.wordlistname,
                wordlistname2: locale.t(locale.keys.validation.tooLongByte, { num: 32 }),
            });
            // SJISで表示できない文字をチェック
        } else if (!isNotGarble(wordListName)) {
            setErrorsName({
                wordlistname: errorsName.wordlistname,
                wordlistname2: locale.t(locale.keys.fileName.workBook.unavailable),
            });
        } else {
            setErrorsName({
                wordlistname: errorsName.wordlistname,
                wordlistname2: '',
            });
        }
        setWordListName02(wordListName);
    };

    const handleAddItem = (chip: string) => {
        const { itemList: validatedItemList, errorMessege } = validateNameRuleChip(chip, itemList);
        setItemList(validatedItemList);
        setfileNameLimitErrorMessege(errorMessege);
    };

    const handleDeleteItem = (data: string, index: number) => {
        const dataList = [...itemList];
        const folderList = [...folderNumber];
        const dataListFiltered = dataList.filter((it: string) => data !== it);
        if (data === locale.t(locale.keys.fileName.date.index)) {
            setDateFlag(false);
            setDateFormat(DateFormatValue.Date1);
        }
        if (data === locale.t(locale.keys.fileName.user.index)) {
            setUserFlag(false);
        }
        if (data === locale.t(locale.keys.fileName.anyCharater.index)) {
            setAnyCharaterFlag(false);
        }
        if (data.includes(locale.t(locale.keys.fileName.folderLabel.index))) {
            const numberValue = +data.split(locale.t(locale.keys.fileName.folderLabel.index))[1];
            const index = folderList.indexOf(numberValue);
            folderList.splice(index, 1);
        }
        // 固定文字の場合
        let fixedItemNum = 0;
        for (let i = 0; i < dataListFiltered.length; i++) {
            if (
                !regexFolder.test(dataListFiltered[i]) &&
                dataListFiltered[i] !== locale.t(locale.keys.fileName.anyCharater.index) &&
                dataListFiltered[i] !== locale.t(locale.keys.fileName.user.index) &&
                dataListFiltered[i] !== locale.t(locale.keys.fileName.time.index) &&
                dataListFiltered[i] !== locale.t(locale.keys.fileName.date.index)
            ) {
                fixedItemNum++;
            }
        }

        let selectedItem = itemSelected;
        if (selectedItem === index) {
            selectedItem = -1;
        }
        if (selectedItem > index) {
            selectedItem -= 1;
        }

        // 固定文字が20以下だった場合にエラーメッセージを消去
        // 基本的にアクションがあった場合は削除する方向
        if (fixedItemNum <= FOLDER_SIZE) {
            setfileNameLimitErrorMessege('');
        }
        setFolderNumber(folderList);
        setItemSelected(selectedItem);

        while (0 < dataListFiltered.length && isWordInvalid(dataListFiltered[0])) {
            dataListFiltered.shift();
        }
        setItemList(dataListFiltered);
    };

    // 固定文字が上限の20個を超えたときのエラーメッセージを削除する
    const handleDeleteFileNameLimitErrorMessege = () => {
        setfileNameLimitErrorMessege('');
    };

    const handleChangeSeparatorSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSeparatorSelected(event.target.value);
    };

    const handleChangeDateSeparatorSelected = (event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => {
        setDateSeparatorSelected(event.target.value);
    };

    const handleClickDateButton = () => {
        const dataList = [...itemList];
        dataList.push(locale.t(locale.keys.fileName.date.index));
        setDateFormat(DateFormatValue.Date1);
        setItemList(dataList);
        setDateFlag(true);
        setIsYearSelected(true);
        setIsMonthSelected(true);
        setIsDaySelected(true);
        setIsHourSelected(true);
    };

    const handleClickYearButton = () => {
        const value = !isYearSelected;
        const format = dateFormat;
        let newFormat = '';
        if (isYearSelected) {
            newFormat = format.replace('y|', '');
        }
        if (!isYearSelected) {
            newFormat = `y|${format}`;
        }
        setDateFormat(getEnumData(newFormat, DateFormatValue));
        setIsYearSelected(value);
    };

    const handleClickMonthButton = () => {
        const value = !isMonthSelected;
        const format = dateFormat;
        let newFormat = '';
        if (isMonthSelected && format.includes('y')) {
            newFormat = format.replace('|M', '');
        }
        if (isMonthSelected && !format.includes('y')) {
            newFormat = format.replace('M|', '');
        }
        if (!isMonthSelected && !format.includes('y')) {
            newFormat = `M|${format}`;
        }
        if (!isMonthSelected && format === 'y') {
            newFormat = `${format}|M`;
        }
        if (!isMonthSelected && format.includes('y') && format !== 'y') {
            newFormat = format.replace('y|', 'y|M|');
        }
        newFormat = newFormat.replace('||', '|');
        setIsMonthSelected(value);
        setDateFormat(getEnumData(newFormat, DateFormatValue));
    };

    const handleClickDayButton = () => {
        const value = !isDaySelected;
        const format = dateFormat;
        let newFormat = '';
        if (isDaySelected && (format.includes('y') || format.includes('M'))) {
            newFormat = format.replace('|d', '');
        }
        if (isDaySelected && format === 'd|Hms') {
            newFormat = format.replace('d|', '');
        }
        if (!isDaySelected && !format.includes('Hms')) {
            newFormat = `${format}|d`;
        }
        if (!isDaySelected && format === 'Hms') {
            newFormat = `d|${format}`;
        }
        if (!isDaySelected && format.includes('Hms') && format !== 'Hms') {
            newFormat = format.replace('|Hms', '|d|Hms');
        }
        newFormat = newFormat.replace('||', '|');
        setIsDaySelected(value);
        setDateFormat(getEnumData(newFormat, DateFormatValue));
    };

    const handleClickTimeButton = () => {
        const value = !isHourSelected;
        const format = dateFormat;
        let newFormat = '';
        if (isHourSelected) {
            newFormat = format.replace('|Hms', '');
        }
        if (!isHourSelected) {
            newFormat = `${format}|Hms`;
        }
        setDateFormat(getEnumData(newFormat, DateFormatValue));
        setIsHourSelected(value);
    };

    const handleClickUserButton = () => {
        const dataList = [...itemList];
        dataList.push(locale.t(locale.keys.fileName.user.index));
        setItemList(dataList);
        setUserFlag(true);
    };

    const handleClickAnyCharaterButton = () => {
        const dataList = [...itemList];
        dataList.push(locale.t(locale.keys.fileName.anyCharater.index));
        setItemList(dataList);
        setAnyCharaterFlag(true);
    };

    const handleClickFolderButton = (folderNo: number) => {
        const dataList = [...itemList];
        const folderList = [...folderNumber];
        if (folderNo < 5) {
            dataList.push(locale.t(locale.keys.fileName.folder.index, { number: folderNo }));
            folderList.push(folderNo);
            setItemList(dataList);
            setFolderFlag(true);
            setFolderNumber(folderList);
            return;
        }
        switch (itemSelected < 0) {
            case true:
                for (let i = 5; i <= FOLDER_SIZE; i += 1) {
                    if (!folderList.includes(i)) {
                        dataList.push(locale.t(locale.keys.fileName.folder.index, { number: i }));
                        folderList.push(i);
                        setItemList(dataList);
                        setFolderFlag(true);
                        setFolderNumber(folderList);
                        return;
                    }
                }
                break;
            case false:
                let numberValue = +dataList[itemSelected].split(locale.t(locale.keys.fileName.folderLabel.index))[1];
                const index = folderList.indexOf(numberValue);
                while (folderList.includes(numberValue)) {
                    numberValue += 1;
                }
                if (numberValue > 20) {
                    return;
                }
                folderList.splice(index, 1, numberValue);
                dataList[itemSelected] = locale.t(locale.keys.fileName.folder.index, { number: numberValue });
                setItemList(dataList);
                setFolderFlag(true);
                setFolderNumber(folderList);
                return;
        }
    };

    const handleDisableFolder = (folderNo: number) => {
        const folderList = [...folderNumber];
        const full5: number[] = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
        const full = full5.every((number) => folderList.includes(number));
        if (folderNo < 5 && folderList.includes(folderNo)) {
            return true;
        }
        if (folderNo >= 5 && (folderList.length >= FOLDER_SIZE || full)) {
            return true;
        }
        return false;
    };

    const handleChangeCanEmpty = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCanEmpty(event.target.checked);
    };

    const handleAddWord = (chip: string, isWordbook: boolean = false) => {
        let dataList = [...wordList];
        if (isWordbook) {
            dataList = [];
        }
        if (!isNotGarble(chip)) {
            return;
        }
        if (dataList.length < WORDBOOK_SIZE && regexSpecialCharacter.test(chip.trim()) && !dataList.includes(removeUnnecessarySpace(chip.trim()))) {
            dataList.push(removeUnnecessarySpace(chip.trim()));
        }
        let chipList: string[] = [];
        if (chip.includes(',')) {
            chipList = [...chip.split(',')];
        }
        for (const i in chipList) {
            let isPass = false;
            if (dataList.length < WORDBOOK_SIZE && regexSpecialCharacterForMultipleInput.test(removeUnnecessarySpace(chipList[i].trim())) && chipList[i].trim() !== '') {
                isPass = true;
            }
            if (isPass && !dataList.includes(removeUnnecessarySpace(chipList[i].trim()))) {
                dataList.push(removeUnnecessarySpace(chipList[i].trim()));
            }
        }
        if (WORDBOOK_SIZE <= dataList.length) {
            setErrors({
                wordlist: locale.t(locale.keys.validation.wordlist),
                wordlist2: errors.wordlist2,
            });
        }
        setWordList(dataList);
    };

    const handleAddWord2 = (chip: string, isWordbook: boolean = false) => {
        let dataList = [...wordList2];
        if (isWordbook) {
            dataList = [];
        }
        if (!isNotGarble(chip)) {
            return;
        }
        if (dataList.length < WORDBOOK_SIZE && regexSpecialCharacter.test(chip.trim()) && !dataList.includes(removeUnnecessarySpace(chip.trim()))) {
            dataList.push(removeUnnecessarySpace(chip.trim()));
        }
        let chipList: string[] = [];
        if (chip.includes(',')) {
            chipList = [...chip.split(',')];
        }
        for (const i in chipList) {
            let isPass = false;
            if (dataList.length < WORDBOOK_SIZE && regexSpecialCharacterForMultipleInput.test(removeUnnecessarySpace(chipList[i].trim())) && chipList[i].trim() !== '') {
                isPass = true;
            }
            if (isPass && !dataList.includes(removeUnnecessarySpace(chipList[i].trim()))) {
                dataList.push(removeUnnecessarySpace(chipList[i].trim()));
            }
        }
        if (WORDBOOK_SIZE <= dataList.length) {
            setErrors({
                wordlist: errors.wordlist,
                wordlist2: locale.t(locale.keys.validation.wordlist),
            });
        }
        setWordList2(dataList);
    };

    const handleDeleteWord = (data: string) => {
        setErrors({
            wordlist: '',
            wordlist2: errors.wordlist2,
        });
        const dataList = [...wordList];
        setWordList(dataList.filter((it: string) => data !== it));
    };

    const handleDeleteWord2 = (data: string) => {
        setErrors({
            wordlist: errors.wordlist,
            wordlist2: '',
        });
        const dataList = [...wordList2];
        setWordList2(dataList.filter((it: string) => data !== it));
    };

    const handleChangeSequenceTypeSelected = (event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => {
        setSequenceTypeSelected(+event.target.value);
    };

    const handleChangeSwitch = () => {
        const check = !isLock;
        setIsLock(check);
    };

    const isDisabled = (step: number) => {
        switch (step) {
            case 0: {
                if (!activeStorage) return true;
                if (endpointName && endpointIcon && endpointBtnColor) return false;
                return true;
            }
            case 1: {
                if (activeStorage) return false;
                return true;
            }
            case 2: {
                if (path && activeStorage.service === schema.V1ObjectsServiceEnum.Docard) return false;
                if (appContainer.loadingState) return true;
                // let pathArray = path.split('/');
                // pathArray = pathArray.filter((item: string) => item !== '');
                // if ((!pathArray || pathArray.length < 2) && activeStorage.service === schema.V1ObjectsServiceEnum.Sharepointonline) {
                //     return true;
                // }
                if (activeStorage.service === schema.V1ObjectsServiceEnum.Email && emails.length !== 0 && bodyMail) {
                    return false;
                }
                if (path && activeStorage.service !== schema.V1ObjectsServiceEnum.Email) {
                    return false;
                }
                if (path) return false;
                return true;
            }
            case 3: {
                // falseなら通常業務作成ボタン活性化
                let error = false;
                setFileNameErrorMessage('');

                if (sequenceTypeSelected === sequenceType.COUNT && !anyCharaterFlag) {
                    setFileNameErrorMessage(locale.t(locale.keys.fileName.selectedConditionsErrorMessage.index));
                    error = true;
                }

                if (!(errorsName.wordlistname === '') || !(errorsName.wordlistname2 === '')) error = true;
                // ファイル名が不正だったら非活性
                if (!validateFileName(getFileNameExample())) {
                    setFileNameErrorMessage(locale.t(locale.keys.fileName.fileNameErrorMessage.index));
                    error = true;
                }

                if (!itemList.length) {
                    setFileNameErrorMessage(locale.t(locale.keys.validation.required));
                    error = true;
                }

                return error;
            }
            case 4: {
                // 利用制限設定は非活性化条件なし
                return false;
            }
            default:
                return true;
        }
    };

    const m365ErrorMessage = (step: number) => {
        if (activeStorage.service === schema.V1ObjectsServiceEnum.Sharepointonline && step === 2) {
            if (!path) return locale.t(locale.keys.createEnpoint.m365SiteRequiredDialog);
            let pathArray = path.split('/');
            pathArray = pathArray.filter((item: string) => item !== '');
            if (pathArray.length < 1) {
                return locale.t(locale.keys.createEnpoint.m365SiteRequiredDialog);
            }
            if (pathArray.length < 2) {
                return locale.t(locale.keys.createEnpoint.m365LibRequiredDialog);
            }
        }
        return '';
    };

    const handleCreate = async () => {
        let nameRule: string = '';
        const fixedList: string[] = [];
        for (let index = 0; index < itemList.length; index += 1) {
            if (itemList[index] === locale.t(locale.keys.fileName.date.index) && !nameRule.includes(nameTag.DATE)) {
                nameRule += `|${nameTag.DATE}`;
            }
            if (itemList[index] === locale.t(locale.keys.fileName.time.index) && !nameRule.includes(nameTag.DATE)) {
                nameRule += `|${nameTag.DATE}`;
            }
            if (itemList[index] === locale.t(locale.keys.fileName.user.index)) {
                nameRule += `|${nameTag.USER}`;
            }
            if (itemList[index] === locale.t(locale.keys.fileName.anyCharater.index)) {
                nameRule += `|${nameTag.FILE}`;
            }
            if (regexFolder.test(itemList[index])) {
                const folderId = itemList[index].replace(locale.t(locale.keys.fileName.folderLabel.index), '');
                nameRule += `|folder${folderId}`;
            }
            if (
                !regexFolder.test(itemList[index]) &&
                itemList[index] !== locale.t(locale.keys.fileName.anyCharater.index) &&
                itemList[index] !== locale.t(locale.keys.fileName.user.index) &&
                itemList[index] !== locale.t(locale.keys.fileName.time.index) &&
                itemList[index] !== locale.t(locale.keys.fileName.date.index)
            ) {
                fixedList.push(itemList[index]);
                nameRule += `|${nameTag.FIXED}${fixedList.length}`;
            }
        }
        nameRule = nameRule.substr(1);
        let separator = separatorSelected;
        if (separator === 'none') {
            separator = '';
        }

        let dateSeparator = dateSeparatorSelected;
        if (dateSeparator === 'none') {
            dateSeparator = '';
        }

        let folderPath = path || '/';
        const docName = {
            dateFormat,
            separator,
            dateSeparator,
            fixed: fixedList,
            itemList: nameRule,
            fileOptional: canEmpty,
            sequenceType: sequenceTypeSelected,
            lockEdit: isLock,
            wordListName01: wordListName01 ? wordListName01 : '単語帳1',
            wordListName02: wordListName02 ? wordListName02 : '単語帳2',
        };

        let workData = wordList;
        let workData2 = wordList2;
        if (activeStorage && schema.V1ObjectsServiceEnum.Nonecloud === activeStorage.service) {
            folderPath = '/';
            workData = [];
            workData2 = [];
            docName.fixed = [];
            docName.itemList = '';
            docName.separator = '';
            docName.fileOptional = false;
            docName.sequenceType = 0;
        }
        if (activeStorage && schema.V1ObjectsServiceEnum.Docard === activeStorage.service) {
            workData = [];
            workData2 = [];
            docName.fixed = [];
            docName.itemList = '';
            docName.separator = '';
            docName.fileOptional = false;
        }

        const permission = createEndpointFormat(
            environment.tenant,
            endpoints.applicationPermission,
            endpoints.selectedMfpPermission,
            endpoints.selectedUserPermission,
            endpoints.selectedGroupPermission,
            endpoints.enablePermission,
        );

        try {
            setUI(UI.Loading);
            const endpointReq: schema.V1EndpointsCreateRequest = {
                allowedApplications: permission.allowedApplications,
                allowedMfps: permission.allowedMfps,
                allowedGroups: permission.allowedGroups,
                allowedUsers: permission.allowedUsers,
                endpoint: {
                    path: folderPath,
                    pathId: idPath,
                    buttonColor: endpointBtnColor,
                    downloadable: upDownFeature.isDownloadable,
                    iconName: `${endpointIcon}.png`,
                    name: endpointName,
                    uploadable: upDownFeature.isUploadable,
                    xStorage: activeStorage.xstorageId,
                    share: isShared ? shareFormat.share : shareFormat.personal,
                    applicationPermissionSetting: permission.enablePermission.applicationPermission,
                    groupPermissionSetting: permission.enablePermission.userGroupPermission,
                    userPermissionSetting: permission.enablePermission.userGroupPermission,
                    mfpPermissionSetting: permission.enablePermission.mfpPermission,
                },
                wordListData: workData,
                wordListData02: workData2,
                docNameFormat: docName,
            };
            if (activeStorage && schema.V1ObjectsServiceEnum.Email === activeStorage.service) {
                endpointReq.endpoint.address = emails;
                endpointReq.endpoint.template = bodyMail;
                endpointReq.endpoint.subject = subject;
                endpointReq.endpoint.fileMaxSize = fileMaxSize;
            }
            await createEndpoint(endpointReq, appContainer.values.authorizationCode);
            setUI(UI.Loaded);
        } catch (e) {
            setUI(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
            return;
        }
    };

    const handleClickChip = (key: number, value: string) => {
        const selectedItem = itemSelected;
        const numberValue = +value.split(locale.t(locale.keys.fileName.folderLabel.index))[1];
        if (numberValue < 5 || !value.includes(locale.t(locale.keys.fileName.folderLabel.index))) {
            return;
        }
        if (selectedItem === key) {
            setItemSelected(-1);
            return;
        }
        if (selectedItem !== key) {
            setItemSelected(key);
            return;
        }
    };

    const getFileNameExample = () => {
        let exampleText = '';
        if (!itemList || !Array.isArray(itemList) || itemList.length === 0) {
            return exampleText;
        }
        let separator = separatorSelected;
        if (separator === 'none') {
            separator = '';
        }
        for (let index = 0; index < itemList.length; index += 1) {
            if (itemList[index] === locale.t(locale.keys.fileName.date.index)) {
                let tempDate = fileNameDateExample(dateSeparatorSelected, dateFormat, moment());
                exampleText += `${separator}${tempDate}`;
            }
            if (itemList[index] === locale.t(locale.keys.fileName.user.index)) {
                exampleText += `${separator}${locale.t(locale.keys.fileName.user.index)}`;
            }
            if (itemList[index] === locale.t(locale.keys.fileName.anyCharater.index)) {
                exampleText += `${separator}${locale.t(locale.keys.fileName.anyText.index)}`;
            }
            if (regexFolder.test(itemList[index])) {
                exampleText += `${separator}${itemList[index].replace(' ', '')}`;
            }
            if (
                !regexFolder.test(itemList[index]) &&
                itemList[index] !== locale.t(locale.keys.fileName.anyCharater.index) &&
                itemList[index] !== locale.t(locale.keys.fileName.user.index) &&
                itemList[index] !== locale.t(locale.keys.fileName.date.index)
            ) {
                exampleText += `${separator}${itemList[index]}`;
            }
        }
        exampleText += `.pdf`;
        if (separator) {
            exampleText = exampleText.substr(1);
        }

        return exampleText;
    };

    const handleInputDateButton = () => {
        switch (dateFormat) {
            case DateFormatValue.Date2:
                return locale.t(locale.keys.fileName.date2.index);
            case DateFormatValue.Date3:
                return locale.t(locale.keys.fileName.date3.index);
            case DateFormatValue.Date4:
                return locale.t(locale.keys.fileName.date4.index);
            case DateFormatValue.Date5:
                return locale.t(locale.keys.fileName.date5.index);
            case DateFormatValue.Date6:
                return locale.t(locale.keys.fileName.date6.index);
            case DateFormatValue.Date7:
                return locale.t(locale.keys.fileName.date7.index);
            case DateFormatValue.Date8:
                return locale.t(locale.keys.fileName.date8.index);
            case DateFormatValue.Date9:
                return locale.t(locale.keys.fileName.date9.index);
            case DateFormatValue.Date10:
                return locale.t(locale.keys.fileName.date10.index);
            case DateFormatValue.Date11:
                return locale.t(locale.keys.fileName.date11.index);
            case DateFormatValue.Date12:
                return locale.t(locale.keys.fileName.date12.index);
            case DateFormatValue.Date13:
                return locale.t(locale.keys.fileName.date13.index);
            case DateFormatValue.Date14:
                return locale.t(locale.keys.fileName.date14.index);
            case DateFormatValue.Date15:
                return locale.t(locale.keys.fileName.date15.index);
            default:
                return locale.t(locale.keys.fileName.date1.index);
        }
    };

    const isButtonDisable = (name: string) => {
        switch (name) {
            case 'year':
                if (!dateFlag) return true;
                if (!isMonthSelected && !isDaySelected && !isHourSelected) return true;
                return false;
            case 'month':
                if (!dateFlag) return true;
                if (!isYearSelected && !isDaySelected && !isHourSelected) return true;
                return false;
            case 'day':
                if (!dateFlag) return true;
                if (!isYearSelected && !isMonthSelected && !isHourSelected) return true;
                return false;
            case 'hour':
                if (!dateFlag) return true;
                if (!isYearSelected && !isMonthSelected && !isDaySelected) return true;
                return false;
            default:
                return false;
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const replaceWordbook = (words: string) => {
        handleAddWord(words, true);
    };
    const clearWordList = () => {
        setWordList([]);
    };
    const clearWordList2 = () => {
        setWordList2([]);
    };

    const mailList = (mailSetting: schema.V1ObjectsEndpointsMailSetting) => {
        if (mailSetting.address) {
            setEmails(mailSetting.address);
        }
        if (mailSetting.template) {
            setBodyMail(mailSetting.template);
        }
        if (mailSetting.subject) {
            setSubject(mailSetting.subject);
        }
        if (mailSetting.fileMaxSize) {
            setFileMaxSize(mailSetting.fileMaxSize);
        }
    };

    const addEnpointName = (name: string) => {
        setEndpointName(name);
    };

    const onBlurEndpointNameInput = () => {
        const newInput = removeEmojiChar(endpointName);
        setEndpointName(newInput);
    };

    const isDateSelected = () => {
        if (itemList.includes(locale.t(locale.keys.fileName.date.index))) {
            return true;
        }
        return false;
    };

    return {
        ui,
        setUI,
        regexFolder,
        storageList,
        getStoragesList,
        activeStorage,
        setActiveStorage,
        endpointName,
        setEndpointName,
        endpointIcon,
        setEndpointIcon,
        endpointBtnColor,
        setEndpointBtnColor,
        handleSwitch,
        getPath,
        path,
        setPath,
        pathName,
        setPathName,
        itemList,
        handleChangeWordListName01,
        handleChangeWordListName02,
        wordListName01,
        wordListName02,
        handleAddItem,
        handleDeleteItem,
        handleDeleteFileNameLimitErrorMessege,
        separatorSelected,
        dateSeparatorSelected,
        handleChangeSeparatorSelected,
        handleChangeDateSeparatorSelected,
        dateFlag,
        handleClickDateButton,
        handleClickTimeButton,
        userFlag,
        handleClickUserButton,
        anyCharaterFlag,
        handleClickAnyCharaterButton,
        folderFlag,
        handleClickFolderButton,
        canEmpty,
        handleChangeCanEmpty,
        wordList,
        wordList2,
        handleAddWord,
        handleAddWord2,
        handleDeleteWord,
        handleDeleteWord2,
        dateFormat,
        setItemList,
        setFolderFlag,
        handleCreate,
        isDisabled,
        setFolderFullFlag,
        isFolderFull,
        upDownFeature,
        setFeature,
        getFileNameExample,
        errors,
        errorsName,
        tmpPathName,
        setTmpPathName,
        handleInputDateButton,
        folderNumber,
        setFolderNumber,
        isYearSelected,
        setIsYearSelected,
        isMonthSelected,
        setIsMonthSelected,
        isDaySelected,
        setIsDaySelected,
        itemSelected,
        setItemSelected,
        handleClickYearButton,
        handleClickMonthButton,
        handleClickDayButton,
        handleDisableFolder,
        handleClickChip,
        isHourSelected,
        setIsHourSelected,
        isButtonDisable,
        isLock,
        setIsLock,
        sequenceTypeSelected,
        setSequenceTypeSelected,
        handleChangeSequenceTypeSelected,
        handleChangeSwitch,
        handleOpen,
        handleClose,
        open,
        replaceWordbook,
        clearWordList,
        clearWordList2,
        addEnpointName,
        mailList,
        onBlurEndpointNameInput,
        getWordList,
        m365ErrorMessage,
        fileNameErrorMessage,
        fileNameLimitErrorMessege,
        isDateSelected,
        loadInitialData,
        endpoints,
        idPath,
    };
};
export const NewEndpointContainer = createContainer(useNewEndpointContainer);
