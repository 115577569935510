export default {
    "E00000": {
        "code": "E00000.code",
        "errorId": "E00000.errorId",
        "message": "E00000.message",
        "statusCode": "E00000.statusCode"
    },
    "E00001": {
        "code": "E00001.code",
        "errorId": "E00001.errorId",
        "message": "E00001.message",
        "statusCode": "E00001.statusCode"
    },
    "E00002": {
        "code": "E00002.code",
        "errorId": "E00002.errorId",
        "message": "E00002.message",
        "statusCode": "E00002.statusCode"
    },
    "E00003": {
        "code": "E00003.code",
        "errorId": "E00003.errorId",
        "message": "E00003.message",
        "statusCode": "E00003.statusCode"
    },
    "E01000": {
        "code": "E01000.code",
        "errorId": "E01000.errorId",
        "message": "E01000.message",
        "statusCode": "E01000.statusCode"
    },
    "E01001": {
        "code": "E01001.code",
        "errorId": "E01001.errorId",
        "message": "E01001.message",
        "statusCode": "E01001.statusCode"
    },
    "E01002": {
        "code": "E01002.code",
        "errorId": "E01002.errorId",
        "message": "E01002.message",
        "statusCode": "E01002.statusCode"
    },
    "E01003": {
        "code": "E01003.code",
        "errorId": "E01003.errorId",
        "message": "E01003.message",
        "statusCode": "E01003.statusCode"
    },
    "E01004": {
        "code": "E01004.code",
        "errorId": "E01004.errorId",
        "message": "E01004.message",
        "statusCode": "E01004.statusCode"
    },
    "E01005": {
        "code": "E01005.code",
        "errorId": "E01005.errorId",
        "message": "E01005.message",
        "statusCode": "E01005.statusCode"
    },
    "E01006": {
        "code": "E01006.code",
        "errorId": "E01006.errorId",
        "message": "E01006.message",
        "statusCode": "E01006.statusCode"
    },
    "E01007": {
        "code": "E01007.code",
        "errorId": "E01007.errorId",
        "message": "E01007.message",
        "statusCode": "E01007.statusCode"
    },
    "E01008": {
        "code": "E01008.code",
        "errorId": "E01008.errorId",
        "message": "E01008.message",
        "statusCode": "E01008.statusCode"
    },
    "E01009": {
        "code": "E01009.code",
        "errorId": "E01009.errorId",
        "message": "E01009.message",
        "statusCode": "E01009.statusCode"
    },
    "E01010": {
        "code": "E01010.code",
        "errorId": "E01010.errorId",
        "message": "E01010.message",
        "statusCode": "E01010.statusCode"
    },
    "E02000": {
        "code": "E02000.code",
        "errorId": "E02000.errorId",
        "message": "E02000.message",
        "statusCode": "E02000.statusCode"
    },
    "E02001": {
        "code": "E02001.code",
        "errorId": "E02001.errorId",
        "message": "E02001.message",
        "statusCode": "E02001.statusCode"
    },
    "E02002": {
        "code": "E02002.code",
        "errorId": "E02002.errorId",
        "message": "E02002.message",
        "statusCode": "E02002.statusCode"
    },
    "E02003": {
        "code": "E02003.code",
        "errorId": "E02003.errorId",
        "message": "E02003.message",
        "statusCode": "E02003.statusCode"
    },
    "E02004": {
        "code": "E02004.code",
        "errorId": "E02004.errorId",
        "message": "E02004.message",
        "statusCode": "E02004.statusCode"
    },
    "E02005": {
        "code": "E02005.code",
        "errorId": "E02005.errorId",
        "message": "E02005.message",
        "statusCode": "E02005.statusCode"
    },
    "E02006": {
        "code": "E02006.code",
        "errorId": "E02006.errorId",
        "message": "E02006.message",
        "statusCode": "E02006.statusCode"
    },
    "E02007": {
        "code": "E02007.code",
        "errorId": "E02007.errorId",
        "message": "E02007.message",
        "statusCode": "E02007.statusCode"
    },
    "E02008": {
        "code": "E02008.code",
        "errorId": "E02008.errorId",
        "message": "E02008.message",
        "statusCode": "E02008.statusCode"
    },
    "E02009": {
        "code": "E02009.code",
        "errorId": "E02009.errorId",
        "message": "E02009.message",
        "statusCode": "E02009.statusCode"
    },
    "E02010": {
        "code": "E02010.code",
        "errorId": "E02010.errorId",
        "message": "E02010.message",
        "statusCode": "E02010.statusCode"
    },
    "E02011": {
        "code": "E02011.code",
        "errorId": "E02011.errorId",
        "message": "E02011.message",
        "statusCode": "E02011.statusCode"
    },
    "E02012": {
        "code": "E02012.code",
        "errorId": "E02012.errorId",
        "message": "E02012.message",
        "statusCode": "E02012.statusCode"
    },
    "E02013": {
        "code": "E02013.code",
        "errorId": "E02013.errorId",
        "message": "E02013.message",
        "statusCode": "E02013.statusCode"
    },
    "E02014": {
        "code": "E02014.code",
        "errorId": "E02014.errorId",
        "message": "E02014.message",
        "statusCode": "E02014.statusCode"
    },
    "E02015": {
        "code": "E02015.code",
        "errorId": "E02015.errorId",
        "message": "E02015.message",
        "statusCode": "E02015.statusCode"
    },
    "E02016": {
        "code": "E02016.code",
        "errorId": "E02016.errorId",
        "message": "E02016.message",
        "statusCode": "E02016.statusCode"
    },
    "E02017": {
        "code": "E02017.code",
        "errorId": "E02017.errorId",
        "message": "E02017.message",
        "statusCode": "E02017.statusCode"
    },
    "E02018": {
        "code": "E02018.code",
        "errorId": "E02018.errorId",
        "message": "E02018.message",
        "statusCode": "E02018.statusCode"
    },
    "E02019": {
        "code": "E02019.code",
        "errorId": "E02019.errorId",
        "message": "E02019.message",
        "statusCode": "E02019.statusCode"
    },
    "E02020": {
        "code": "E02020.code",
        "errorId": "E02020.errorId",
        "message": "E02020.message",
        "statusCode": "E02020.statusCode"
    },
    "E02021": {
        "code": "E02021.code",
        "errorId": "E02021.errorId",
        "message": "E02021.message",
        "statusCode": "E02021.statusCode"
    },
    "E02022": {
        "code": "E02022.code",
        "errorId": "E02022.errorId",
        "message": "E02022.message",
        "statusCode": "E02022.statusCode"
    },
    "E03000": {
        "code": "E03000.code",
        "errorId": "E03000.errorId",
        "message": "E03000.message",
        "statusCode": "E03000.statusCode"
    },
    "E03001": {
        "code": "E03001.code",
        "errorId": "E03001.errorId",
        "message": "E03001.message",
        "statusCode": "E03001.statusCode"
    },
    "E03002": {
        "code": "E03002.code",
        "errorId": "E03002.errorId",
        "message": "E03002.message",
        "statusCode": "E03002.statusCode"
    },
    "E03003": {
        "code": "E03003.code",
        "errorId": "E03003.errorId",
        "message": "E03003.message",
        "statusCode": "E03003.statusCode"
    },
    "E03004": {
        "code": "E03004.code",
        "errorId": "E03004.errorId",
        "message": "E03004.message",
        "statusCode": "E03004.statusCode"
    },
    "E03005": {
        "code": "E03005.code",
        "errorId": "E03005.errorId",
        "message": "E03005.message",
        "statusCode": "E03005.statusCode"
    },
    "E03006": {
        "code": "E03006.code",
        "errorId": "E03006.errorId",
        "message": "E03006.message",
        "statusCode": "E03006.statusCode"
    },
    "E03007": {
        "code": "E03007.code",
        "errorId": "E03007.errorId",
        "message": "E03007.message",
        "statusCode": "E03007.statusCode"
    },
    "E03008": {
        "code": "E03008.code",
        "errorId": "E03008.errorId",
        "message": "E03008.message",
        "statusCode": "E03008.statusCode"
    },
    "E03009": {
        "code": "E03009.code",
        "errorId": "E03009.errorId",
        "message": "E03009.message",
        "statusCode": "E03009.statusCode"
    },
    "E03010": {
        "code": "E03010.code",
        "errorId": "E03010.errorId",
        "message": "E03010.message",
        "statusCode": "E03010.statusCode"
    },
    "E03011": {
        "code": "E03011.code",
        "errorId": "E03011.errorId",
        "message": "E03011.message",
        "statusCode": "E03011.statusCode"
    },
    "E03012": {
        "code": "E03012.code",
        "errorId": "E03012.errorId",
        "message": "E03012.message",
        "statusCode": "E03012.statusCode"
    },
    "E03013": {
        "code": "E03013.code",
        "errorId": "E03013.errorId",
        "message": "E03013.message",
        "statusCode": "E03013.statusCode"
    },
    "E03014": {
        "code": "E03014.code",
        "errorId": "E03014.errorId",
        "message": "E03014.message",
        "statusCode": "E03014.statusCode"
    },
    "E03015": {
        "code": "E03015.code",
        "errorId": "E03015.errorId",
        "message": "E03015.message",
        "statusCode": "E03015.statusCode"
    },
    "E03016": {
        "code": "E03016.code",
        "errorId": "E03016.errorId",
        "message": "E03016.message",
        "statusCode": "E03016.statusCode"
    },
    "E04000": {
        "code": "E04000.code",
        "errorId": "E04000.errorId",
        "message": "E04000.message",
        "statusCode": "E04000.statusCode"
    },
    "E04001": {
        "code": "E04001.code",
        "errorId": "E04001.errorId",
        "message": "E04001.message",
        "statusCode": "E04001.statusCode"
    },
    "E04002": {
        "code": "E04002.code",
        "errorId": "E04002.errorId",
        "message": "E04002.message",
        "statusCode": "E04002.statusCode"
    },
    "E04003": {
        "code": "E04003.code",
        "errorId": "E04003.errorId",
        "message": "E04003.message",
        "statusCode": "E04003.statusCode"
    },
    "E04004": {
        "code": "E04004.code",
        "errorId": "E04004.errorId",
        "message": "E04004.message",
        "statusCode": "E04004.statusCode"
    },
    "E04005": {
        "code": "E04005.code",
        "errorId": "E04005.errorId",
        "message": "E04005.message",
        "statusCode": "E04005.statusCode"
    },
    "E04006": {
        "code": "E04006.code",
        "errorId": "E04006.errorId",
        "message": "E04006.message",
        "statusCode": "E04006.statusCode"
    },
    "E04007": {
        "code": "E04007.code",
        "errorId": "E04007.errorId",
        "message": "E04007.message",
        "statusCode": "E04007.statusCode"
    },
    "E04008": {
        "code": "E04008.code",
        "errorId": "E04008.errorId",
        "message": "E04008.message",
        "statusCode": "E04008.statusCode"
    },
    "E04009": {
        "code": "E04009.code",
        "errorId": "E04009.errorId",
        "message": "E04009.message",
        "statusCode": "E04009.statusCode"
    },
    "E04010": {
        "code": "E04010.code",
        "errorId": "E04010.errorId",
        "message": "E04010.message",
        "statusCode": "E04010.statusCode"
    },
    "E04011": {
        "code": "E04011.code",
        "errorId": "E04011.errorId",
        "message": "E04011.message",
        "statusCode": "E04011.statusCode"
    },
    "E04012": {
        "code": "E04012.code",
        "errorId": "E04012.errorId",
        "message": "E04012.message",
        "statusCode": "E04012.statusCode"
    },
    "E04013": {
        "code": "E04013.code",
        "errorId": "E04013.errorId",
        "message": "E04013.message",
        "statusCode": "E04013.statusCode"
    },
    "E04014": {
        "code": "E04014.code",
        "errorId": "E04014.errorId",
        "message": "E04014.message",
        "statusCode": "E04014.statusCode"
    },
    "E04015": {
        "code": "E04015.code",
        "errorId": "E04015.errorId",
        "message": "E04015.message",
        "statusCode": "E04015.statusCode"
    },
    "E04016": {
        "code": "E04016.code",
        "errorId": "E04016.errorId",
        "message": "E04016.message",
        "statusCode": "E04016.statusCode"
    },
    "E04017": {
        "code": "E04017.code",
        "errorId": "E04017.errorId",
        "message": "E04017.message",
        "statusCode": "E04017.statusCode"
    },
    "E04018": {
        "code": "E04018.code",
        "errorId": "E04018.errorId",
        "message": "E04018.message",
        "statusCode": "E04018.statusCode"
    },
    "E04019": {
        "code": "E04019.code",
        "errorId": "E04019.errorId",
        "message": "E04019.message",
        "statusCode": "E04019.statusCode"
    },
    "E04020": {
        "code": "E04020.code",
        "errorId": "E04020.errorId",
        "message": "E04020.message",
        "statusCode": "E04020.statusCode"
    },
    "E04021": {
        "code": "E04021.code",
        "errorId": "E04021.errorId",
        "message": "E04021.message",
        "statusCode": "E04021.statusCode"
    },
    "E04022": {
        "code": "E04022.code",
        "errorId": "E04022.errorId",
        "message": "E04022.message",
        "statusCode": "E04022.statusCode"
    },
    "E04023": {
        "code": "E04023.code",
        "errorId": "E04023.errorId",
        "message": "E04023.message",
        "statusCode": "E04023.statusCode"
    },
    "E04024": {
        "code": "E04024.code",
        "errorId": "E04024.errorId",
        "message": "E04024.message",
        "statusCode": "E04024.statusCode"
    },
    "E04025": {
        "code": "E04025.code",
        "errorId": "E04025.errorId",
        "message": "E04025.message",
        "statusCode": "E04025.statusCode"
    },
    "E04026": {
        "code": "E04026.code",
        "errorId": "E04026.errorId",
        "message": "E04026.message",
        "statusCode": "E04026.statusCode"
    },
    "E04027": {
        "code": "E04027.code",
        "errorId": "E04027.errorId",
        "message": "E04027.message",
        "statusCode": "E04027.statusCode"
    },
    "E04028": {
        "code": "E04028.code",
        "errorId": "E04028.errorId",
        "message": "E04028.message",
        "statusCode": "E04028.statusCode"
    },
    "E04029": {
        "code": "E04029.code",
        "errorId": "E04029.errorId",
        "message": "E04029.message",
        "statusCode": "E04029.statusCode"
    },
    "E04030": {
        "code": "E04030.code",
        "errorId": "E04030.errorId",
        "message": "E04030.message",
        "statusCode": "E04030.statusCode"
    },
    "E04031": {
        "code": "E04031.code",
        "errorId": "E04031.errorId",
        "message": "E04031.message",
        "statusCode": "E04031.statusCode"
    },
    "E04032": {
        "code": "E04032.code",
        "errorId": "E04032.errorId",
        "message": "E04032.message",
        "statusCode": "E04032.statusCode"
    },
    "E04033": {
        "code": "E04033.code",
        "errorId": "E04033.errorId",
        "message": "E04033.message",
        "statusCode": "E04033.statusCode"
    },
    "E04034": {
        "code": "E04034.code",
        "errorId": "E04034.errorId",
        "message": "E04034.message",
        "statusCode": "E04034.statusCode"
    },
    "E05000": {
        "code": "E05000.code",
        "errorId": "E05000.errorId",
        "message": "E05000.message",
        "statusCode": "E05000.statusCode"
    },
    "E05001": {
        "code": "E05001.code",
        "errorId": "E05001.errorId",
        "message": "E05001.message",
        "statusCode": "E05001.statusCode"
    },
    "E05002": {
        "code": "E05002.code",
        "errorId": "E05002.errorId",
        "message": "E05002.message",
        "statusCode": "E05002.statusCode"
    },
    "E05003": {
        "code": "E05003.code",
        "errorId": "E05003.errorId",
        "message": "E05003.message",
        "statusCode": "E05003.statusCode"
    },
    "E05004": {
        "code": "E05004.code",
        "errorId": "E05004.errorId",
        "message": "E05004.message",
        "statusCode": "E05004.statusCode"
    },
    "E05005": {
        "code": "E05005.code",
        "errorId": "E05005.errorId",
        "message": "E05005.message",
        "statusCode": "E05005.statusCode"
    },
    "E05006": {
        "code": "E05006.code",
        "errorId": "E05006.errorId",
        "message": "E05006.message",
        "statusCode": "E05006.statusCode"
    },
    "E05007": {
        "code": "E05007.code",
        "errorId": "E05007.errorId",
        "message": "E05007.message",
        "statusCode": "E05007.statusCode"
    },
    "E05008": {
        "code": "E05008.code",
        "errorId": "E05008.errorId",
        "message": "E05008.message",
        "statusCode": "E05008.statusCode"
    },
    "E05009": {
        "code": "E05009.code",
        "errorId": "E05009.errorId",
        "message": "E05009.message",
        "statusCode": "E05009.statusCode"
    },
    "E05010": {
        "code": "E05010.code",
        "errorId": "E05010.errorId",
        "message": "E05010.message",
        "statusCode": "E05010.statusCode"
    },
    "E05011": {
        "code": "E05011.code",
        "errorId": "E05011.errorId",
        "message": "E05011.message",
        "statusCode": "E05011.statusCode"
    },
    "E05012": {
        "code": "E05012.code",
        "errorId": "E05012.errorId",
        "message": "E05012.message",
        "statusCode": "E05012.statusCode"
    },
    "E05013": {
        "code": "E05013.code",
        "errorId": "E05013.errorId",
        "message": "E05013.message",
        "statusCode": "E05013.statusCode"
    },
    "E05014": {
        "code": "E05014.code",
        "errorId": "E05014.errorId",
        "message": "E05014.message",
        "statusCode": "E05014.statusCode"
    },
    "E05015": {
        "code": "E05015.code",
        "errorId": "E05015.errorId",
        "message": "E05015.message",
        "statusCode": "E05015.statusCode"
    },
    "E06000": {
        "code": "E06000.code",
        "errorId": "E06000.errorId",
        "message": "E06000.message",
        "statusCode": "E06000.statusCode"
    },
    "E06001": {
        "code": "E06001.code",
        "errorId": "E06001.errorId",
        "message": "E06001.message",
        "statusCode": "E06001.statusCode"
    },
    "E06002": {
        "code": "E06002.code",
        "errorId": "E06002.errorId",
        "message": "E06002.message",
        "statusCode": "E06002.statusCode"
    },
    "E06004": {
        "code": "E06004.code",
        "errorId": "E06004.errorId",
        "message": "E06004.message",
        "statusCode": "E06004.statusCode"
    },
    "E06005": {
        "code": "E06005.code",
        "errorId": "E06005.errorId",
        "message": "E06005.message",
        "statusCode": "E06005.statusCode"
    },
    "E06006": {
        "code": "E06006.code",
        "errorId": "E06006.errorId",
        "message": "E06006.message",
        "statusCode": "E06006.statusCode"
    },
    "E06007": {
        "code": "E06007.code",
        "errorId": "E06007.errorId",
        "message": "E06007.message",
        "statusCode": "E06007.statusCode"
    },
    "E06020": {
        "code": "E06020.code",
        "errorId": "E06020.errorId",
        "message": "E06020.message",
        "statusCode": "E06020.statusCode"
    },
    "E06021": {
        "code": "E06021.code",
        "errorId": "E06021.errorId",
        "message": "E06021.message",
        "statusCode": "E06021.statusCode"
    },
    "E06022": {
        "code": "E06022.code",
        "errorId": "E06022.errorId",
        "message": "E06022.message",
        "statusCode": "E06022.statusCode"
    },
    "E06024": {
        "code": "E06024.code",
        "errorId": "E06024.errorId",
        "message": "E06024.message",
        "statusCode": "E06024.statusCode"
    },
    "E06025": {
        "code": "E06025.code",
        "errorId": "E06025.errorId",
        "message": "E06025.message",
        "statusCode": "E06025.statusCode"
    },
    "E06040": {
        "code": "E06040.code",
        "errorId": "E06040.errorId",
        "message": "E06040.message",
        "statusCode": "E06040.statusCode"
    },
    "E06041": {
        "code": "E06041.code",
        "errorId": "E06041.errorId",
        "message": "E06041.message",
        "statusCode": "E06041.statusCode"
    },
    "E06042": {
        "code": "E06042.code",
        "errorId": "E06042.errorId",
        "message": "E06042.message",
        "statusCode": "E06042.statusCode"
    },
    "E06044": {
        "code": "E06044.code",
        "errorId": "E06044.errorId",
        "message": "E06044.message",
        "statusCode": "E06044.statusCode"
    },
    "E06045": {
        "code": "E06045.code",
        "errorId": "E06045.errorId",
        "message": "E06045.message",
        "statusCode": "E06045.statusCode"
    },
    "E06046": {
        "code": "E06046.code",
        "errorId": "E06046.errorId",
        "message": "E06046.message",
        "statusCode": "E06046.statusCode"
    },
    "E06047": {
        "code": "E06047.code",
        "errorId": "E06047.errorId",
        "message": "E06047.message",
        "statusCode": "E06047.statusCode"
    },
    "E06060": {
        "code": "E06060.code",
        "errorId": "E06060.errorId",
        "message": "E06060.message",
        "statusCode": "E06060.statusCode"
    },
    "E06061": {
        "code": "E06061.code",
        "errorId": "E06061.errorId",
        "message": "E06061.message",
        "statusCode": "E06061.statusCode"
    },
    "E06062": {
        "code": "E06062.code",
        "errorId": "E06062.errorId",
        "message": "E06062.message",
        "statusCode": "E06062.statusCode"
    },
    "E06064": {
        "code": "E06064.code",
        "errorId": "E06064.errorId",
        "message": "E06064.message",
        "statusCode": "E06064.statusCode"
    },
    "E06065": {
        "code": "E06065.code",
        "errorId": "E06065.errorId",
        "message": "E06065.message",
        "statusCode": "E06065.statusCode"
    },
    "E06066": {
        "code": "E06066.code",
        "errorId": "E06066.errorId",
        "message": "E06066.message",
        "statusCode": "E06066.statusCode"
    },
    "E06067": {
        "code": "E06067.code",
        "errorId": "E06067.errorId",
        "message": "E06067.message",
        "statusCode": "E06067.statusCode"
    },
    "E06080": {
        "code": "E06080.code",
        "errorId": "E06080.errorId",
        "message": "E06080.message",
        "statusCode": "E06080.statusCode"
    },
    "E06081": {
        "code": "E06081.code",
        "errorId": "E06081.errorId",
        "message": "E06081.message",
        "statusCode": "E06081.statusCode"
    },
    "E06082": {
        "code": "E06082.code",
        "errorId": "E06082.errorId",
        "message": "E06082.message",
        "statusCode": "E06082.statusCode"
    },
    "E06084": {
        "code": "E06084.code",
        "errorId": "E06084.errorId",
        "message": "E06084.message",
        "statusCode": "E06084.statusCode"
    },
    "E06085": {
        "code": "E06085.code",
        "errorId": "E06085.errorId",
        "message": "E06085.message",
        "statusCode": "E06085.statusCode"
    },
    "E06100": {
        "code": "E06100.code",
        "errorId": "E06100.errorId",
        "message": "E06100.message",
        "statusCode": "E06100.statusCode"
    },
    "E06101": {
        "code": "E06101.code",
        "errorId": "E06101.errorId",
        "message": "E06101.message",
        "statusCode": "E06101.statusCode"
    },
    "E06102": {
        "code": "E06102.code",
        "errorId": "E06102.errorId",
        "message": "E06102.message",
        "statusCode": "E06102.statusCode"
    },
    "E06104": {
        "code": "E06104.code",
        "errorId": "E06104.errorId",
        "message": "E06104.message",
        "statusCode": "E06104.statusCode"
    },
    "E06105": {
        "code": "E06105.code",
        "errorId": "E06105.errorId",
        "message": "E06105.message",
        "statusCode": "E06105.statusCode"
    },
    "E06120": {
        "code": "E06120.code",
        "errorId": "E06120.errorId",
        "message": "E06120.message",
        "statusCode": "E06120.statusCode"
    },
    "E06124": {
        "code": "E06124.code",
        "errorId": "E06124.errorId",
        "message": "E06124.message",
        "statusCode": "E06124.statusCode"
    },
    "E06125": {
        "code": "E06125.code",
        "errorId": "E06125.errorId",
        "message": "E06125.message",
        "statusCode": "E06125.statusCode"
    },
    "E06140": {
        "code": "E06140.code",
        "errorId": "E06140.errorId",
        "message": "E06140.message",
        "statusCode": "E06140.statusCode"
    },
    "E06141": {
        "code": "E06141.code",
        "errorId": "E06141.errorId",
        "message": "E06141.message",
        "statusCode": "E06141.statusCode"
    },
    "E06144": {
        "code": "E06144.code",
        "errorId": "E06144.errorId",
        "message": "E06144.message",
        "statusCode": "E06144.statusCode"
    },
    "E06145": {
        "code": "E06145.code",
        "errorId": "E06145.errorId",
        "message": "E06145.message",
        "statusCode": "E06145.statusCode"
    },
    "E06160": {
        "code": "E06160.code",
        "errorId": "E06160.errorId",
        "message": "E06160.message",
        "statusCode": "E06160.statusCode"
    },
    "E06161": {
        "code": "E06161.code",
        "errorId": "E06161.errorId",
        "message": "E06161.message",
        "statusCode": "E06161.statusCode"
    },
    "E06162": {
        "code": "E06162.code",
        "errorId": "E06162.errorId",
        "message": "E06162.message",
        "statusCode": "E06162.statusCode"
    },
    "E06164": {
        "code": "E06164.code",
        "errorId": "E06164.errorId",
        "message": "E06164.message",
        "statusCode": "E06164.statusCode"
    },
    "E06165": {
        "code": "E06165.code",
        "errorId": "E06165.errorId",
        "message": "E06165.message",
        "statusCode": "E06165.statusCode"
    },
    "E06180": {
        "code": "E06180.code",
        "errorId": "E06180.errorId",
        "message": "E06180.message",
        "statusCode": "E06180.statusCode"
    },
    "E06181": {
        "code": "E06181.code",
        "errorId": "E06181.errorId",
        "message": "E06181.message",
        "statusCode": "E06181.statusCode"
    },
    "E06182": {
        "code": "E06182.code",
        "errorId": "E06182.errorId",
        "message": "E06182.message",
        "statusCode": "E06182.statusCode"
    },
    "E06184": {
        "code": "E06184.code",
        "errorId": "E06184.errorId",
        "message": "E06184.message",
        "statusCode": "E06184.statusCode"
    },
    "E06185": {
        "code": "E06185.code",
        "errorId": "E06185.errorId",
        "message": "E06185.message",
        "statusCode": "E06185.statusCode"
    },
    "E06200": {
        "code": "E06200.code",
        "errorId": "E06200.errorId",
        "message": "E06200.message",
        "statusCode": "E06200.statusCode"
    },
    "E06201": {
        "code": "E06201.code",
        "errorId": "E06201.errorId",
        "message": "E06201.message",
        "statusCode": "E06201.statusCode"
    },
    "E06202": {
        "code": "E06202.code",
        "errorId": "E06202.errorId",
        "message": "E06202.message",
        "statusCode": "E06202.statusCode"
    },
    "E06203": {
        "code": "E06203.code",
        "errorId": "E06203.errorId",
        "message": "E06203.message",
        "statusCode": "E06203.statusCode"
    },
    "E06204": {
        "code": "E06204.code",
        "errorId": "E06204.errorId",
        "message": "E06204.message",
        "statusCode": "E06204.statusCode"
    },
    "E06205": {
        "code": "E06205.code",
        "errorId": "E06205.errorId",
        "message": "E06205.message",
        "statusCode": "E06205.statusCode"
    },
    "E06206": {
        "code": "E06206.code",
        "errorId": "E06206.errorId",
        "message": "E06206.message",
        "statusCode": "E06206.statusCode"
    },
    "E06207": {
        "code": "E06207.code",
        "errorId": "E06207.errorId",
        "message": "E06207.message",
        "statusCode": "E06207.statusCode"
    },
    "E06208": {
        "code": "E06208.code",
        "errorId": "E06208.errorId",
        "message": "E06208.message",
        "statusCode": "E06208.statusCode"
    },
    "E06300": {
        "code": "E06300.code",
        "errorId": "E06300.errorId",
        "message": "E06300.message",
        "statusCode": "E06300.statusCode"
    },
    "E06301": {
        "code": "E06301.code",
        "errorId": "E06301.errorId",
        "message": "E06301.message",
        "statusCode": "E06301.statusCode"
    },
    "E06302": {
        "code": "E06302.code",
        "errorId": "E06302.errorId",
        "message": "E06302.message",
        "statusCode": "E06302.statusCode"
    },
    "E07000": {
        "code": "E07000.code",
        "errorId": "E07000.errorId",
        "message": "E07000.message",
        "statusCode": "E07000.statusCode"
    },
    "E07001": {
        "code": "E07001.code",
        "errorId": "E07001.errorId",
        "message": "E07001.message",
        "statusCode": "E07001.statusCode"
    },
    "E07002": {
        "code": "E07002.code",
        "errorId": "E07002.errorId",
        "message": "E07002.message",
        "statusCode": "E07002.statusCode"
    },
    "E07003": {
        "code": "E07003.code",
        "errorId": "E07003.errorId",
        "message": "E07003.message",
        "statusCode": "E07003.statusCode"
    },
    "E07004": {
        "code": "E07004.code",
        "errorId": "E07004.errorId",
        "message": "E07004.message",
        "statusCode": "E07004.statusCode"
    },
    "E07005": {
        "code": "E07005.code",
        "errorId": "E07005.errorId",
        "message": "E07005.message",
        "statusCode": "E07005.statusCode"
    },
    "E07006": {
        "code": "E07006.code",
        "errorId": "E07006.errorId",
        "message": "E07006.message",
        "statusCode": "E07006.statusCode"
    },
    "E07007": {
        "code": "E07007.code",
        "errorId": "E07007.errorId",
        "message": "E07007.message",
        "statusCode": "E07007.statusCode"
    },
    "E07008": {
        "code": "E07008.code",
        "errorId": "E07008.errorId",
        "message": "E07008.message",
        "statusCode": "E07008.statusCode"
    },
    "E07009": {
        "code": "E07009.code",
        "errorId": "E07009.errorId",
        "message": "E07009.message",
        "statusCode": "E07009.statusCode"
    },
    "E07010": {
        "code": "E07010.code",
        "errorId": "E07010.errorId",
        "message": "E07010.message",
        "statusCode": "E07010.statusCode"
    },
    "E07011": {
        "code": "E07011.code",
        "errorId": "E07011.errorId",
        "message": "E07011.message",
        "statusCode": "E07011.statusCode"
    },
    "E07012": {
        "code": "E07012.code",
        "errorId": "E07012.errorId",
        "message": "E07012.message",
        "statusCode": "E07012.statusCode"
    },
    "E07014": {
        "code": "E07014.code",
        "errorId": "E07014.errorId",
        "message": "E07014.message",
        "statusCode": "E07014.statusCode"
    },
    "E07015": {
        "code": "E07015.code",
        "errorId": "E07015.errorId",
        "message": "E07015.message",
        "statusCode": "E07015.statusCode"
    },
    "E07016": {
        "code": "E07016.code",
        "errorId": "E07016.errorId",
        "message": "E07016.message",
        "statusCode": "E07016.statusCode"
    },
    "E07017": {
        "code": "E07017.code",
        "errorId": "E07017.errorId",
        "message": "E07017.message",
        "statusCode": "E07017.statusCode"
    },
    "E07018": {
        "code": "E07018.code",
        "errorId": "E07018.errorId",
        "message": "E07018.message",
        "statusCode": "E07018.statusCode"
    },
    "E07019": {
        "code": "E07019.code",
        "errorId": "E07019.errorId",
        "message": "E07019.message",
        "statusCode": "E07019.statusCode"
    },
    "E07020": {
        "code": "E07020.code",
        "errorId": "E07020.errorId",
        "message": "E07020.message",
        "statusCode": "E07020.statusCode"
    },
    "E07021": {
        "code": "E07021.code",
        "errorId": "E07021.errorId",
        "message": "E07021.message",
        "statusCode": "E07021.statusCode"
    },
    "E07022": {
        "code": "E07022.code",
        "errorId": "E07022.errorId",
        "message": "E07022.message",
        "statusCode": "E07022.statusCode"
    },
    "E07023": {
        "code": "E07023.code",
        "errorId": "E07023.errorId",
        "message": "E07023.message",
        "statusCode": "E07023.statusCode"
    },
    "E08000": {
        "code": "E08000.code",
        "errorId": "E08000.errorId",
        "message": "E08000.message",
        "statusCode": "E08000.statusCode"
    },
    "E08001": {
        "code": "E08001.code",
        "errorId": "E08001.errorId",
        "message": "E08001.message",
        "statusCode": "E08001.statusCode"
    },
    "E08002": {
        "code": "E08002.code",
        "errorId": "E08002.errorId",
        "message": "E08002.message",
        "statusCode": "E08002.statusCode"
    },
    "E08003": {
        "code": "E08003.code",
        "errorId": "E08003.errorId",
        "message": "E08003.message",
        "statusCode": "E08003.statusCode"
    },
    "E08004": {
        "code": "E08004.code",
        "errorId": "E08004.errorId",
        "message": "E08004.message",
        "statusCode": "E08004.statusCode"
    },
    "E08005": {
        "code": "E08005.code",
        "errorId": "E08005.errorId",
        "message": "E08005.message",
        "statusCode": "E08005.statusCode"
    },
    "E08006": {
        "code": "E08006.code",
        "errorId": "E08006.errorId",
        "message": "E08006.message",
        "statusCode": "E08006.statusCode"
    },
    "E08007": {
        "code": "E08007.code",
        "errorId": "E08007.errorId",
        "message": "E08007.message",
        "statusCode": "E08007.statusCode"
    },
    "E08008": {
        "code": "E08008.code",
        "errorId": "E08008.errorId",
        "message": "E08008.message",
        "statusCode": "E08008.statusCode"
    },
    "E08009": {
        "code": "E08009.code",
        "errorId": "E08009.errorId",
        "message": "E08009.message",
        "statusCode": "E08009.statusCode"
    },
    "E08010": {
        "code": "E08010.code",
        "errorId": "E08010.errorId",
        "message": "E08010.message",
        "statusCode": "E08010.statusCode"
    },
    "E08011": {
        "code": "E08011.code",
        "errorId": "E08011.errorId",
        "message": "E08011.message",
        "statusCode": "E08011.statusCode"
    },
    "E08012": {
        "code": "E08012.code",
        "errorId": "E08012.errorId",
        "message": "E08012.message",
        "statusCode": "E08012.statusCode"
    },
    "E08013": {
        "code": "E08013.code",
        "errorId": "E08013.errorId",
        "message": "E08013.message",
        "statusCode": "E08013.statusCode"
    },
    "E08014": {
        "code": "E08014.code",
        "errorId": "E08014.errorId",
        "message": "E08014.message",
        "statusCode": "E08014.statusCode"
    },
    "E08015": {
        "code": "E08015.code",
        "errorId": "E08015.errorId",
        "message": "E08015.message",
        "statusCode": "E08015.statusCode"
    },
    "E08016": {
        "code": "E08016.code",
        "errorId": "E08016.errorId",
        "message": "E08016.message",
        "statusCode": "E08016.statusCode"
    },
    "E08017": {
        "code": "E08017.code",
        "errorId": "E08017.errorId",
        "message": "E08017.message",
        "statusCode": "E08017.statusCode"
    },
    "E08018": {
        "code": "E08018.code",
        "errorId": "E08018.errorId",
        "message": "E08018.message",
        "statusCode": "E08018.statusCode"
    },
    "E08019": {
        "code": "E08019.code",
        "errorId": "E08019.errorId",
        "message": "E08019.message",
        "statusCode": "E08019.statusCode"
    },
    "E08020": {
        "code": "E08020.code",
        "errorId": "E08020.errorId",
        "message": "E08020.message",
        "statusCode": "E08020.statusCode"
    },
    "E08021": {
        "code": "E08021.code",
        "errorId": "E08021.errorId",
        "message": "E08021.message",
        "statusCode": "E08021.statusCode"
    },
    "E08022": {
        "code": "E08022.code",
        "errorId": "E08022.errorId",
        "message": "E08022.message",
        "statusCode": "E08022.statusCode"
    },
    "E08023": {
        "code": "E08023.code",
        "errorId": "E08023.errorId",
        "message": "E08023.message",
        "statusCode": "E08023.statusCode"
    },
    "E08024": {
        "code": "E08024.code",
        "errorId": "E08024.errorId",
        "message": "E08024.message",
        "statusCode": "E08024.statusCode"
    },
    "E08025": {
        "code": "E08025.code",
        "errorId": "E08025.errorId",
        "message": "E08025.message",
        "statusCode": "E08025.statusCode"
    },
    "E08026": {
        "code": "E08026.code",
        "errorId": "E08026.errorId",
        "message": "E08026.message",
        "statusCode": "E08026.statusCode"
    },
    "E08027": {
        "code": "E08027.code",
        "errorId": "E08027.errorId",
        "message": "E08027.message",
        "statusCode": "E08027.statusCode"
    },
    "E08028": {
        "code": "E08028.code",
        "errorId": "E08028.errorId",
        "message": "E08028.message",
        "statusCode": "E08028.statusCode"
    },
    "E08029": {
        "code": "E08029.code",
        "errorId": "E08029.errorId",
        "message": "E08029.message",
        "statusCode": "E08029.statusCode"
    },
    "E08030": {
        "code": "E08030.code",
        "errorId": "E08030.errorId",
        "message": "E08030.message",
        "statusCode": "E08030.statusCode"
    },
    "E08031": {
        "code": "E08031.code",
        "errorId": "E08031.errorId",
        "message": "E08031.message",
        "statusCode": "E08031.statusCode"
    },
    "E08032": {
        "code": "E08032.code",
        "errorId": "E08032.errorId",
        "message": "E08032.message",
        "statusCode": "E08032.statusCode"
    },
    "E08033": {
        "code": "E08033.code",
        "errorId": "E08033.errorId",
        "message": "E08033.message",
        "statusCode": "E08033.statusCode"
    },
    "E08034": {
        "code": "E08034.code",
        "errorId": "E08034.errorId",
        "message": "E08034.message",
        "statusCode": "E08034.statusCode"
    },
    "E08035": {
        "code": "E08035.code",
        "errorId": "E08035.errorId",
        "message": "E08035.message",
        "statusCode": "E08035.statusCode"
    },
    "E08036": {
        "code": "E08036.code",
        "errorId": "E08036.errorId",
        "message": "E08036.message",
        "statusCode": "E08036.statusCode"
    },
    "E08037": {
        "code": "E08037.code",
        "errorId": "E08037.errorId",
        "message": "E08037.message",
        "statusCode": "E08037.statusCode"
    },
    "E08038": {
        "code": "E08038.code",
        "errorId": "E08038.errorId",
        "message": "E08038.message",
        "statusCode": "E08038.statusCode"
    },
    "E08039": {
        "code": "E08039.code",
        "errorId": "E08039.errorId",
        "message": "E08039.message",
        "statusCode": "E08039.statusCode"
    },
    "E08040": {
        "code": "E08040.code",
        "errorId": "E08040.errorId",
        "message": "E08040.message",
        "statusCode": "E08040.statusCode"
    },
    "E08041": {
        "code": "E08041.code",
        "errorId": "E08041.errorId",
        "message": "E08041.message",
        "statusCode": "E08041.statusCode"
    },
    "E08042": {
        "code": "E08042.code",
        "errorId": "E08042.errorId",
        "message": "E08042.message",
        "statusCode": "E08042.statusCode"
    },
    "E08043": {
        "code": "E08043.code",
        "errorId": "E08043.errorId",
        "message": "E08043.message",
        "statusCode": "E08043.statusCode"
    },
    "E08044": {
        "code": "E08044.code",
        "errorId": "E08044.errorId",
        "message": "E08044.message",
        "statusCode": "E08044.statusCode"
    },
    "E08045": {
        "code": "E08045.code",
        "errorId": "E08045.errorId",
        "message": "E08045.message",
        "statusCode": "E08045.statusCode"
    },
    "E08046": {
        "code": "E08046.code",
        "errorId": "E08046.errorId",
        "message": "E08046.message",
        "statusCode": "E08046.statusCode"
    },
    "E08047": {
        "code": "E08047.code",
        "errorId": "E08047.errorId",
        "message": "E08047.message",
        "statusCode": "E08047.statusCode"
    },
    "E08048": {
        "code": "E08048.code",
        "errorId": "E08048.errorId",
        "message": "E08048.message",
        "statusCode": "E08048.statusCode"
    },
    "E08049": {
        "code": "E08049.code",
        "errorId": "E08049.errorId",
        "message": "E08049.message",
        "statusCode": "E08049.statusCode"
    },
    "E08050": {
        "code": "E08050.code",
        "errorId": "E08050.errorId",
        "message": "E08050.message",
        "statusCode": "E08050.statusCode"
    },
    "E08051": {
        "code": "E08051.code",
        "errorId": "E08051.errorId",
        "message": "E08051.message",
        "statusCode": "E08051.statusCode"
    },
    "E08052": {
        "code": "E08052.code",
        "errorId": "E08052.errorId",
        "message": "E08052.message",
        "statusCode": "E08052.statusCode"
    },
    "E08053": {
        "code": "E08053.code",
        "errorId": "E08053.errorId",
        "message": "E08053.message",
        "statusCode": "E08053.statusCode"
    },
    "E08054": {
        "code": "E08054.code",
        "errorId": "E08054.errorId",
        "message": "E08054.message",
        "statusCode": "E08054.statusCode"
    },
    "E08055": {
        "code": "E08055.code",
        "errorId": "E08055.errorId",
        "message": "E08055.message",
        "statusCode": "E08055.statusCode"
    },
    "E08056": {
        "code": "E08056.code",
        "errorId": "E08056.errorId",
        "message": "E08056.message",
        "statusCode": "E08056.statusCode"
    },
    "E08057": {
        "code": "E08057.code",
        "errorId": "E08057.errorId",
        "message": "E08057.message",
        "statusCode": "E08057.statusCode"
    },
    "E08058": {
        "code": "E08058.code",
        "errorId": "E08058.errorId",
        "message": "E08058.message",
        "statusCode": "E08058.statusCode"
    },
    "E08059": {
        "code": "E08059.code",
        "errorId": "E08059.errorId",
        "message": "E08059.message",
        "statusCode": "E08059.statusCode"
    },
    "E08060": {
        "code": "E08060.code",
        "errorId": "E08060.errorId",
        "message": "E08060.message",
        "statusCode": "E08060.statusCode"
    },
    "E08061": {
        "code": "E08061.code",
        "errorId": "E08061.errorId",
        "message": "E08061.message",
        "statusCode": "E08061.statusCode"
    },
    "E08062": {
        "code": "E08062.code",
        "errorId": "E08062.errorId",
        "message": "E08062.message",
        "statusCode": "E08062.statusCode"
    },
    "E08063": {
        "code": "E08063.code",
        "errorId": "E08063.errorId",
        "message": "E08063.message",
        "statusCode": "E08063.statusCode"
    },
    "E08064": {
        "code": "E08064.code",
        "errorId": "E08064.errorId",
        "message": "E08064.message",
        "statusCode": "E08064.statusCode"
    },
    "E08065": {
        "code": "E08065.code",
        "errorId": "E08065.errorId",
        "message": "E08065.message",
        "statusCode": "E08065.statusCode"
    },
    "E08066": {
        "code": "E08066.code",
        "errorId": "E08066.errorId",
        "message": "E08066.message",
        "statusCode": "E08066.statusCode"
    },
    "E08067": {
        "code": "E08067.code",
        "errorId": "E08067.errorId",
        "message": "E08067.message",
        "statusCode": "E08067.statusCode"
    },
    "E08068": {
        "code": "E08068.code",
        "errorId": "E08068.errorId",
        "message": "E08068.message",
        "statusCode": "E08068.statusCode"
    },
    "E08069": {
        "code": "E08069.code",
        "errorId": "E08069.errorId",
        "message": "E08069.message",
        "statusCode": "E08069.statusCode"
    },
    "E08070": {
        "code": "E08070.code",
        "errorId": "E08070.errorId",
        "message": "E08070.message",
        "statusCode": "E08070.statusCode"
    },
    "E08071": {
        "code": "E08071.code",
        "errorId": "E08071.errorId",
        "message": "E08071.message",
        "statusCode": "E08071.statusCode"
    },
    "E08072": {
        "code": "E08072.code",
        "errorId": "E08072.errorId",
        "message": "E08072.message",
        "statusCode": "E08072.statusCode"
    },
    "E08073": {
        "code": "E08073.code",
        "errorId": "E08073.errorId",
        "message": "E08073.message",
        "statusCode": "E08073.statusCode"
    },
    "E08074": {
        "code": "E08074.code",
        "errorId": "E08074.errorId",
        "message": "E08074.message",
        "statusCode": "E08074.statusCode"
    },
    "E08075": {
        "code": "E08075.code",
        "errorId": "E08075.errorId",
        "message": "E08075.message",
        "statusCode": "E08075.statusCode"
    },
    "E08076": {
        "code": "E08076.code",
        "errorId": "E08076.errorId",
        "message": "E08076.message",
        "statusCode": "E08076.statusCode"
    },
    "E08077": {
        "code": "E08077.code",
        "errorId": "E08077.errorId",
        "message": "E08077.message",
        "statusCode": "E08077.statusCode"
    },
    "E08078": {
        "code": "E08078.code",
        "errorId": "E08078.errorId",
        "message": "E08078.message",
        "statusCode": "E08078.statusCode"
    },
    "E08079": {
        "code": "E08079.code",
        "errorId": "E08079.errorId",
        "message": "E08079.message",
        "statusCode": "E08079.statusCode"
    },
    "E08080": {
        "code": "E08080.code",
        "errorId": "E08080.errorId",
        "message": "E08080.message",
        "statusCode": "E08080.statusCode"
    },
    "E08081": {
        "code": "E08081.code",
        "errorId": "E08081.errorId",
        "message": "E08081.message",
        "statusCode": "E08081.statusCode"
    },
    "E08082": {
        "code": "E08082.code",
        "errorId": "E08082.errorId",
        "message": "E08082.message",
        "statusCode": "E08082.statusCode"
    },
    "E08083": {
        "code": "E08083.code",
        "errorId": "E08083.errorId",
        "message": "E08083.message",
        "statusCode": "E08083.statusCode"
    },
    "E08084": {
        "code": "E08084.code",
        "errorId": "E08084.errorId",
        "message": "E08084.message",
        "statusCode": "E08084.statusCode"
    },
    "E08085": {
        "code": "E08085.code",
        "errorId": "E08085.errorId",
        "message": "E08085.message",
        "statusCode": "E08085.statusCode"
    },
    "E08086": {
        "code": "E08086.code",
        "errorId": "E08086.errorId",
        "message": "E08086.message",
        "statusCode": "E08086.statusCode"
    },
    "E08087": {
        "code": "E08087.code",
        "errorId": "E08087.errorId",
        "message": "E08087.message",
        "statusCode": "E08087.statusCode"
    },
    "E08088": {
        "code": "E08088.code",
        "errorId": "E08088.errorId",
        "message": "E08088.message",
        "statusCode": "E08088.statusCode"
    },
    "E08089": {
        "code": "E08089.code",
        "errorId": "E08089.errorId",
        "message": "E08089.message",
        "statusCode": "E08089.statusCode"
    },
    "E08090": {
        "code": "E08090.code",
        "errorId": "E08090.errorId",
        "message": "E08090.message",
        "statusCode": "E08090.statusCode"
    },
    "E08091": {
        "code": "E08091.code",
        "errorId": "E08091.errorId",
        "message": "E08091.message",
        "statusCode": "E08091.statusCode"
    },
    "E08092": {
        "code": "E08092.code",
        "errorId": "E08092.errorId",
        "message": "E08092.message",
        "statusCode": "E08092.statusCode"
    },
    "E08093": {
        "code": "E08093.code",
        "errorId": "E08093.errorId",
        "message": "E08093.message",
        "statusCode": "E08093.statusCode"
    },
    "E08094": {
        "code": "E08094.code",
        "errorId": "E08094.errorId",
        "message": "E08094.message",
        "statusCode": "E08094.statusCode"
    },
    "E08095": {
        "code": "E08095.code",
        "errorId": "E08095.errorId",
        "message": "E08095.message",
        "statusCode": "E08095.statusCode"
    },
    "E08096": {
        "code": "E08096.code",
        "errorId": "E08096.errorId",
        "message": "E08096.message",
        "statusCode": "E08096.statusCode"
    },
    "E08097": {
        "code": "E08097.code",
        "errorId": "E08097.errorId",
        "message": "E08097.message",
        "statusCode": "E08097.statusCode"
    },
    "E08098": {
        "code": "E08098.code",
        "errorId": "E08098.errorId",
        "message": "E08098.message",
        "statusCode": "E08098.statusCode"
    },
    "E08099": {
        "code": "E08099.code",
        "errorId": "E08099.errorId",
        "message": "E08099.message",
        "statusCode": "E08099.statusCode"
    },
    "E08100": {
        "code": "E08100.code",
        "errorId": "E08100.errorId",
        "message": "E08100.message",
        "statusCode": "E08100.statusCode"
    },
    "E08101": {
        "code": "E08101.code",
        "errorId": "E08101.errorId",
        "message": "E08101.message",
        "statusCode": "E08101.statusCode"
    },
    "E08102": {
        "code": "E08102.code",
        "errorId": "E08102.errorId",
        "message": "E08102.message",
        "statusCode": "E08102.statusCode"
    },
    "E08103": {
        "code": "E08103.code",
        "errorId": "E08103.errorId",
        "message": "E08103.message",
        "statusCode": "E08103.statusCode"
    },
    "E08104": {
        "code": "E08104.code",
        "errorId": "E08104.errorId",
        "message": "E08104.message",
        "statusCode": "E08104.statusCode"
    },
    "E08105": {
        "code": "E08105.code",
        "errorId": "E08105.errorId",
        "message": "E08105.message",
        "statusCode": "E08105.statusCode"
    },
    "E08106": {
        "code": "E08106.code",
        "errorId": "E08106.errorId",
        "message": "E08106.message",
        "statusCode": "E08106.statusCode"
    },
    "E08107": {
        "code": "E08107.code",
        "errorId": "E08107.errorId",
        "message": "E08107.message",
        "statusCode": "E08107.statusCode"
    },
    "E08108": {
        "code": "E08108.code",
        "errorId": "E08108.errorId",
        "message": "E08108.message",
        "statusCode": "E08108.statusCode"
    },
    "E08109": {
        "code": "E08109.code",
        "errorId": "E08109.errorId",
        "message": "E08109.message",
        "statusCode": "E08109.statusCode"
    },
    "E08110": {
        "code": "E08110.code",
        "errorId": "E08110.errorId",
        "message": "E08110.message",
        "statusCode": "E08110.statusCode"
    },
    "E08111": {
        "code": "E08111.code",
        "errorId": "E08111.errorId",
        "message": "E08111.message",
        "statusCode": "E08111.statusCode"
    },
    "E08112": {
        "code": "E08112.code",
        "errorId": "E08112.errorId",
        "message": "E08112.message",
        "statusCode": "E08112.statusCode"
    },
    "E08113": {
        "code": "E08113.code",
        "errorId": "E08113.errorId",
        "message": "E08113.message",
        "statusCode": "E08113.statusCode"
    },
    "E08114": {
        "code": "E08114.code",
        "errorId": "E08114.errorId",
        "message": "E08114.message",
        "statusCode": "E08114.statusCode"
    },
    "E08115": {
        "code": "E08115.code",
        "errorId": "E08115.errorId",
        "message": "E08115.message",
        "statusCode": "E08115.statusCode"
    },
    "E08116": {
        "code": "E08116.code",
        "errorId": "E08116.errorId",
        "message": "E08116.message",
        "statusCode": "E08116.statusCode"
    },
    "E08117": {
        "code": "E08117.code",
        "errorId": "E08117.errorId",
        "message": "E08117.message",
        "statusCode": "E08117.statusCode"
    },
    "E08118": {
        "code": "E08118.code",
        "errorId": "E08118.errorId",
        "message": "E08118.message",
        "statusCode": "E08118.statusCode"
    },
    "E08119": {
        "code": "E08119.code",
        "errorId": "E08119.errorId",
        "message": "E08119.message",
        "statusCode": "E08119.statusCode"
    },
    "E08120": {
        "code": "E08120.code",
        "errorId": "E08120.errorId",
        "message": "E08120.message",
        "statusCode": "E08120.statusCode"
    },
    "E08121": {
        "code": "E08121.code",
        "errorId": "E08121.errorId",
        "message": "E08121.message",
        "statusCode": "E08121.statusCode"
    },
    "E08122": {
        "code": "E08122.code",
        "errorId": "E08122.errorId",
        "message": "E08122.message",
        "statusCode": "E08122.statusCode"
    },
    "E08123": {
        "code": "E08123.code",
        "errorId": "E08123.errorId",
        "message": "E08123.message",
        "statusCode": "E08123.statusCode"
    },
    "E08124": {
        "code": "E08124.code",
        "errorId": "E08124.errorId",
        "message": "E08124.message",
        "statusCode": "E08124.statusCode"
    },
    "E08125": {
        "code": "E08125.code",
        "errorId": "E08125.errorId",
        "message": "E08125.message",
        "statusCode": "E08125.statusCode"
    },
    "E08126": {
        "code": "E08126.code",
        "errorId": "E08126.errorId",
        "message": "E08126.message",
        "statusCode": "E08126.statusCode"
    },
    "E08127": {
        "code": "E08127.code",
        "errorId": "E08127.errorId",
        "message": "E08127.message",
        "statusCode": "E08127.statusCode"
    },
    "E08128": {
        "code": "E08128.code",
        "errorId": "E08128.errorId",
        "message": "E08128.message",
        "statusCode": "E08128.statusCode"
    },
    "E08129": {
        "code": "E08129.code",
        "errorId": "E08129.errorId",
        "message": "E08129.message",
        "statusCode": "E08129.statusCode"
    },
    "E08130": {
        "code": "E08130.code",
        "errorId": "E08130.errorId",
        "message": "E08130.message",
        "statusCode": "E08130.statusCode"
    },
    "E08131": {
        "code": "E08131.code",
        "errorId": "E08131.errorId",
        "message": "E08131.message",
        "statusCode": "E08131.statusCode"
    },
    "E08132": {
        "code": "E08132.code",
        "errorId": "E08132.errorId",
        "message": "E08132.message",
        "statusCode": "E08132.statusCode"
    },
    "E08133": {
        "code": "E08133.code",
        "errorId": "E08133.errorId",
        "message": "E08133.message",
        "statusCode": "E08133.statusCode"
    },
    "E08134": {
        "code": "E08134.code",
        "errorId": "E08134.errorId",
        "message": "E08134.message",
        "statusCode": "E08134.statusCode"
    },
    "E08135": {
        "code": "E08135.code",
        "errorId": "E08135.errorId",
        "message": "E08135.message",
        "statusCode": "E08135.statusCode"
    },
    "E08136": {
        "code": "E08136.code",
        "errorId": "E08136.errorId",
        "message": "E08136.message",
        "statusCode": "E08136.statusCode"
    },
    "E08137": {
        "code": "E08137.code",
        "errorId": "E08137.errorId",
        "message": "E08137.message",
        "statusCode": "E08137.statusCode"
    },
    "E08138": {
        "code": "E08138.code",
        "errorId": "E08138.errorId",
        "message": "E08138.message",
        "statusCode": "E08138.statusCode"
    },
    "E08139": {
        "code": "E08139.code",
        "errorId": "E08139.errorId",
        "message": "E08139.message",
        "statusCode": "E08139.statusCode"
    },
    "E08140": {
        "code": "E08140.code",
        "errorId": "E08140.errorId",
        "message": "E08140.message",
        "statusCode": "E08140.statusCode"
    },
    "E08141": {
        "code": "E08141.code",
        "errorId": "E08141.errorId",
        "message": "E08141.message",
        "statusCode": "E08141.statusCode"
    },
    "E08142": {
        "code": "E08142.code",
        "errorId": "E08142.errorId",
        "message": "E08142.message",
        "statusCode": "E08142.statusCode"
    },
    "E08143": {
        "code": "E08143.code",
        "errorId": "E08143.errorId",
        "message": "E08143.message",
        "statusCode": "E08143.statusCode"
    },
    "E08144": {
        "code": "E08144.code",
        "errorId": "E08144.errorId",
        "message": "E08144.message",
        "statusCode": "E08144.statusCode"
    },
    "E08145": {
        "code": "E08145.code",
        "errorId": "E08145.errorId",
        "message": "E08145.message",
        "statusCode": "E08145.statusCode"
    },
    "E08146": {
        "code": "E08146.code",
        "errorId": "E08146.errorId",
        "message": "E08146.message",
        "statusCode": "E08146.statusCode"
    },
    "E08147": {
        "code": "E08147.code",
        "errorId": "E08147.errorId",
        "message": "E08147.message",
        "statusCode": "E08147.statusCode"
    },
    "E08148": {
        "code": "E08148.code",
        "errorId": "E08148.errorId",
        "message": "E08148.message",
        "statusCode": "E08148.statusCode"
    },
    "E08149": {
        "code": "E08149.code",
        "errorId": "E08149.errorId",
        "message": "E08149.message",
        "statusCode": "E08149.statusCode"
    },
    "E08150": {
        "code": "E08150.code",
        "errorId": "E08150.errorId",
        "message": "E08150.message",
        "statusCode": "E08150.statusCode"
    },
    "E08151": {
        "code": "E08151.code",
        "errorId": "E08151.errorId",
        "message": "E08151.message",
        "statusCode": "E08151.statusCode"
    },
    "E08152": {
        "code": "E08152.code",
        "errorId": "E08152.errorId",
        "message": "E08152.message",
        "statusCode": "E08152.statusCode"
    },
    "E08153": {
        "code": "E08153.code",
        "errorId": "E08153.errorId",
        "message": "E08153.message",
        "statusCode": "E08153.statusCode"
    },
    "E08154": {
        "code": "E08154.code",
        "errorId": "E08154.errorId",
        "message": "E08154.message",
        "statusCode": "E08154.statusCode"
    },
    "E08155": {
        "code": "E08155.code",
        "errorId": "E08155.errorId",
        "message": "E08155.message",
        "statusCode": "E08155.statusCode"
    },
    "E08156": {
        "code": "E08156.code",
        "errorId": "E08156.errorId",
        "message": "E08156.message",
        "statusCode": "E08156.statusCode"
    },
    "E08157": {
        "code": "E08157.code",
        "errorId": "E08157.errorId",
        "message": "E08157.message",
        "statusCode": "E08157.statusCode"
    },
    "E08158": {
        "code": "E08158.code",
        "errorId": "E08158.errorId",
        "message": "E08158.message",
        "statusCode": "E08158.statusCode"
    },
    "E08159": {
        "code": "E08159.code",
        "errorId": "E08159.errorId",
        "message": "E08159.message",
        "statusCode": "E08159.statusCode"
    },
    "E08160": {
        "code": "E08160.code",
        "errorId": "E08160.errorId",
        "message": "E08160.message",
        "statusCode": "E08160.statusCode"
    },
    "E08161": {
        "code": "E08161.code",
        "errorId": "E08161.errorId",
        "message": "E08161.message",
        "statusCode": "E08161.statusCode"
    },
    "E08162": {
        "code": "E08162.code",
        "errorId": "E08162.errorId",
        "message": "E08162.message",
        "statusCode": "E08162.statusCode"
    },
    "E08163": {
        "code": "E08163.code",
        "errorId": "E08163.errorId",
        "message": "E08163.message",
        "statusCode": "E08163.statusCode"
    },
    "E08164": {
        "code": "E08164.code",
        "errorId": "E08164.errorId",
        "message": "E08164.message",
        "statusCode": "E08164.statusCode"
    },
    "E08165": {
        "code": "E08165.code",
        "errorId": "E08165.errorId",
        "message": "E08165.message",
        "statusCode": "E08165.statusCode"
    },
    "E08166": {
        "code": "E08166.code",
        "errorId": "E08166.errorId",
        "message": "E08166.message",
        "statusCode": "E08166.statusCode"
    },
    "E08167": {
        "code": "E08167.code",
        "errorId": "E08167.errorId",
        "message": "E08167.message",
        "statusCode": "E08167.statusCode"
    },
    "E08168": {
        "code": "E08168.code",
        "errorId": "E08168.errorId",
        "message": "E08168.message",
        "statusCode": "E08168.statusCode"
    },
    "E08169": {
        "code": "E08169.code",
        "errorId": "E08169.errorId",
        "message": "E08169.message",
        "statusCode": "E08169.statusCode"
    },
    "E08170": {
        "code": "E08170.code",
        "errorId": "E08170.errorId",
        "message": "E08170.message",
        "statusCode": "E08170.statusCode"
    },
    "E08171": {
        "code": "E08171.code",
        "errorId": "E08171.errorId",
        "message": "E08171.message",
        "statusCode": "E08171.statusCode"
    },
    "E08172": {
        "code": "E08172.code",
        "errorId": "E08172.errorId",
        "message": "E08172.message",
        "statusCode": "E08172.statusCode"
    },
    "E08173": {
        "code": "E08173.code",
        "errorId": "E08173.errorId",
        "message": "E08173.message",
        "statusCode": "E08173.statusCode"
    },
    "E08174": {
        "code": "E08174.code",
        "errorId": "E08174.errorId",
        "message": "E08174.message",
        "statusCode": "E08174.statusCode"
    },
    "E08175": {
        "code": "E08175.code",
        "errorId": "E08175.errorId",
        "message": "E08175.message",
        "statusCode": "E08175.statusCode"
    },
    "E08176": {
        "code": "E08176.code",
        "errorId": "E08176.errorId",
        "message": "E08176.message",
        "statusCode": "E08176.statusCode"
    },
    "E08177": {
        "code": "E08177.code",
        "errorId": "E08177.errorId",
        "message": "E08177.message",
        "statusCode": "E08177.statusCode"
    },
    "E08178": {
        "code": "E08178.code",
        "errorId": "E08178.errorId",
        "message": "E08178.message",
        "statusCode": "E08178.statusCode"
    },
    "E08179": {
        "code": "E08179.code",
        "errorId": "E08179.errorId",
        "message": "E08179.message",
        "statusCode": "E08179.statusCode"
    },
    "E08180": {
        "code": "E08180.code",
        "errorId": "E08180.errorId",
        "message": "E08180.message",
        "statusCode": "E08180.statusCode"
    },
    "E08181": {
        "code": "E08181.code",
        "errorId": "E08181.errorId",
        "message": "E08181.message",
        "statusCode": "E08181.statusCode"
    },
    "E08182": {
        "code": "E08182.code",
        "errorId": "E08182.errorId",
        "message": "E08182.message",
        "statusCode": "E08182.statusCode"
    },
    "E08183": {
        "code": "E08183.code",
        "errorId": "E08183.errorId",
        "message": "E08183.message",
        "statusCode": "E08183.statusCode"
    },
    "E08184": {
        "code": "E08184.code",
        "errorId": "E08184.errorId",
        "message": "E08184.message",
        "statusCode": "E08184.statusCode"
    },
    "E08185": {
        "code": "E08185.code",
        "errorId": "E08185.errorId",
        "message": "E08185.message",
        "statusCode": "E08185.statusCode"
    },
    "E08186": {
        "code": "E08186.code",
        "errorId": "E08186.errorId",
        "message": "E08186.message",
        "statusCode": "E08186.statusCode"
    },
    "E08187": {
        "code": "E08187.code",
        "errorId": "E08187.errorId",
        "message": "E08187.message",
        "statusCode": "E08187.statusCode"
    },
    "E08188": {
        "code": "E08188.code",
        "errorId": "E08188.errorId",
        "message": "E08188.message",
        "statusCode": "E08188.statusCode"
    },
    "E08189": {
        "code": "E08189.code",
        "errorId": "E08189.errorId",
        "message": "E08189.message",
        "statusCode": "E08189.statusCode"
    },
    "E08190": {
        "code": "E08190.code",
        "errorId": "E08190.errorId",
        "message": "E08190.message",
        "statusCode": "E08190.statusCode"
    },
    "E08191": {
        "code": "E08191.code",
        "errorId": "E08191.errorId",
        "message": "E08191.message",
        "statusCode": "E08191.statusCode"
    },
    "E08192": {
        "code": "E08192.code",
        "errorId": "E08192.errorId",
        "message": "E08192.message",
        "statusCode": "E08192.statusCode"
    },
    "E08193": {
        "code": "E08193.code",
        "errorId": "E08193.errorId",
        "message": "E08193.message",
        "statusCode": "E08193.statusCode"
    },
    "E08194": {
        "code": "E08194.code",
        "errorId": "E08194.errorId",
        "message": "E08194.message",
        "statusCode": "E08194.statusCode"
    },
    "E08195": {
        "code": "E08195.code",
        "errorId": "E08195.errorId",
        "message": "E08195.message",
        "statusCode": "E08195.statusCode"
    },
    "E08196": {
        "code": "E08196.code",
        "errorId": "E08196.errorId",
        "message": "E08196.message",
        "statusCode": "E08196.statusCode"
    },
    "E08197": {
        "code": "E08197.code",
        "errorId": "E08197.errorId",
        "message": "E08197.message",
        "statusCode": "E08197.statusCode"
    },
    "E08198": {
        "code": "E08198.code",
        "errorId": "E08198.errorId",
        "message": "E08198.message",
        "statusCode": "E08198.statusCode"
    },
    "E08199": {
        "code": "E08199.code",
        "errorId": "E08199.errorId",
        "message": "E08199.message",
        "statusCode": "E08199.statusCode"
    },
    "E08200": {
        "code": "E08200.code",
        "errorId": "E08200.errorId",
        "message": "E08200.message",
        "statusCode": "E08200.statusCode"
    },
    "E08201": {
        "code": "E08201.code",
        "errorId": "E08201.errorId",
        "message": "E08201.message",
        "statusCode": "E08201.statusCode"
    },
    "E08202": {
        "code": "E08202.code",
        "errorId": "E08202.errorId",
        "message": "E08202.message",
        "statusCode": "E08202.statusCode"
    },
    "E08203": {
        "code": "E08203.code",
        "errorId": "E08203.errorId",
        "message": "E08203.message",
        "statusCode": "E08203.statusCode"
    },
    "E08204": {
        "code": "E08204.code",
        "errorId": "E08204.errorId",
        "message": "E08204.message",
        "statusCode": "E08204.statusCode"
    },
    "E08205": {
        "code": "E08205.code",
        "errorId": "E08205.errorId",
        "message": "E08205.message",
        "statusCode": "E08205.statusCode"
    },
    "E08206": {
        "code": "E08206.code",
        "errorId": "E08206.errorId",
        "message": "E08206.message",
        "statusCode": "E08206.statusCode"
    },
    "E08207": {
        "code": "E08207.code",
        "errorId": "E08207.errorId",
        "message": "E08207.message",
        "statusCode": "E08207.statusCode"
    },
    "E08208": {
        "code": "E08208.code",
        "errorId": "E08208.errorId",
        "message": "E08208.message",
        "statusCode": "E08208.statusCode"
    },
    "E08209": {
        "code": "E08209.code",
        "errorId": "E08209.errorId",
        "message": "E08209.message",
        "statusCode": "E08209.statusCode"
    },
    "E08210": {
        "code": "E08210.code",
        "errorId": "E08210.errorId",
        "message": "E08210.message",
        "statusCode": "E08210.statusCode"
    },
    "E08211": {
        "code": "E08211.code",
        "errorId": "E08211.errorId",
        "message": "E08211.message",
        "statusCode": "E08211.statusCode"
    },
    "E08212": {
        "code": "E08212.code",
        "errorId": "E08212.errorId",
        "message": "E08212.message",
        "statusCode": "E08212.statusCode"
    },
    "E08213": {
        "code": "E08213.code",
        "errorId": "E08213.errorId",
        "message": "E08213.message",
        "statusCode": "E08213.statusCode"
    },
    "E08214": {
        "code": "E08214.code",
        "errorId": "E08214.errorId",
        "message": "E08214.message",
        "statusCode": "E08214.statusCode"
    },
    "E08215": {
        "code": "E08215.code",
        "errorId": "E08215.errorId",
        "message": "E08215.message",
        "statusCode": "E08215.statusCode"
    },
    "E08216": {
        "code": "E08216.code",
        "errorId": "E08216.errorId",
        "message": "E08216.message",
        "statusCode": "E08216.statusCode"
    },
    "E08217": {
        "code": "E08217.code",
        "errorId": "E08217.errorId",
        "message": "E08217.message",
        "statusCode": "E08217.statusCode"
    },
    "E08218": {
        "code": "E08218.code",
        "errorId": "E08218.errorId",
        "message": "E08218.message",
        "statusCode": "E08218.statusCode"
    },
    "E08219": {
        "code": "E08219.code",
        "errorId": "E08219.errorId",
        "message": "E08219.message",
        "statusCode": "E08219.statusCode"
    },
    "E08220": {
        "code": "E08220.code",
        "errorId": "E08220.errorId",
        "message": "E08220.message",
        "statusCode": "E08220.statusCode"
    },
    "E08221": {
        "code": "E08221.code",
        "errorId": "E08221.errorId",
        "message": "E08221.message",
        "statusCode": "E08221.statusCode"
    },
    "E08222": {
        "code": "E08222.code",
        "errorId": "E08222.errorId",
        "message": "E08222.message",
        "statusCode": "E08222.statusCode"
    },
    "E08223": {
        "code": "E08223.code",
        "errorId": "E08223.errorId",
        "message": "E08223.message",
        "statusCode": "E08223.statusCode"
    },
    "E08224": {
        "code": "E08224.code",
        "errorId": "E08224.errorId",
        "message": "E08224.message",
        "statusCode": "E08224.statusCode"
    },
    "E08225": {
        "code": "E08225.code",
        "errorId": "E08225.errorId",
        "message": "E08225.message",
        "statusCode": "E08225.statusCode"
    },
    "E08226": {
        "code": "E08226.code",
        "errorId": "E08226.errorId",
        "message": "E08226.message",
        "statusCode": "E08226.statusCode"
    },
    "E08227": {
        "code": "E08227.code",
        "errorId": "E08227.errorId",
        "message": "E08227.message",
        "statusCode": "E08227.statusCode"
    },
    "E08228": {
        "code": "E08228.code",
        "errorId": "E08228.errorId",
        "message": "E08228.message",
        "statusCode": "E08228.statusCode"
    },
    "E08229": {
        "code": "E08229.code",
        "errorId": "E08229.errorId",
        "message": "E08229.message",
        "statusCode": "E08229.statusCode"
    },
    "E08230": {
        "code": "E08230.code",
        "errorId": "E08230.errorId",
        "message": "E08230.message",
        "statusCode": "E08230.statusCode"
    },
    "E08231": {
        "code": "E08231.code",
        "errorId": "E08231.errorId",
        "message": "E08231.message",
        "statusCode": "E08231.statusCode"
    },
    "E08232": {
        "code": "E08232.code",
        "errorId": "E08232.errorId",
        "message": "E08232.message",
        "statusCode": "E08232.statusCode"
    },
    "E08233": {
        "code": "E08233.code",
        "errorId": "E08233.errorId",
        "message": "E08233.message",
        "statusCode": "E08233.statusCode"
    },
    "E08234": {
        "code": "E08234.code",
        "errorId": "E08234.errorId",
        "message": "E08234.message",
        "statusCode": "E08234.statusCode"
    },
    "E08235": {
        "code": "E08235.code",
        "errorId": "E08235.errorId",
        "message": "E08235.message",
        "statusCode": "E08235.statusCode"
    },
    "E10000": {
        "code": "E10000.code",
        "errorId": "E10000.errorId",
        "message": "E10000.message",
        "statusCode": "E10000.statusCode"
    },
    "E10001": {
        "code": "E10001.code",
        "errorId": "E10001.errorId",
        "message": "E10001.message",
        "statusCode": "E10001.statusCode"
    },
    "E10002": {
        "code": "E10002.code",
        "errorId": "E10002.errorId",
        "message": "E10002.message",
        "statusCode": "E10002.statusCode"
    },
    "E10003": {
        "code": "E10003.code",
        "errorId": "E10003.errorId",
        "message": "E10003.message",
        "statusCode": "E10003.statusCode"
    },
    "E10004": {
        "code": "E10004.code",
        "errorId": "E10004.errorId",
        "message": "E10004.message",
        "statusCode": "E10004.statusCode"
    },
    "E10005": {
        "code": "E10005.code",
        "errorId": "E10005.errorId",
        "message": "E10005.message",
        "statusCode": "E10005.statusCode"
    },
    "E10006": {
        "code": "E10006.code",
        "errorId": "E10006.errorId",
        "message": "E10006.message",
        "statusCode": "E10006.statusCode"
    },
    "E10999": {
        "code": "E10999.code",
        "errorId": "E10999.errorId",
        "message": "E10999.message",
        "statusCode": "E10999.statusCode"
    },
    "E95000": {
        "code": "E95000.code",
        "errorId": "E95000.errorId",
        "message": "E95000.message",
        "statusCode": "E95000.statusCode"
    },
    "E95001": {
        "code": "E95001.code",
        "errorId": "E95001.errorId",
        "message": "E95001.message",
        "statusCode": "E95001.statusCode"
    },
    "E95002": {
        "code": "E95002.code",
        "errorId": "E95002.errorId",
        "message": "E95002.message",
        "statusCode": "E95002.statusCode"
    },
    "E95003": {
        "code": "E95003.code",
        "errorId": "E95003.errorId",
        "message": "E95003.message",
        "statusCode": "E95003.statusCode"
    },
    "E95004": {
        "code": "E95004.code",
        "errorId": "E95004.errorId",
        "message": "E95004.message",
        "statusCode": "E95004.statusCode"
    },
    "E95005": {
        "code": "E95005.code",
        "errorId": "E95005.errorId",
        "message": "E95005.message",
        "statusCode": "E95005.statusCode"
    },
    "E95006": {
        "code": "E95006.code",
        "errorId": "E95006.errorId",
        "message": "E95006.message",
        "statusCode": "E95006.statusCode"
    },
    "E96000": {
        "code": "E96000.code",
        "errorId": "E96000.errorId",
        "message": "E96000.message",
        "statusCode": "E96000.statusCode"
    },
    "E96001": {
        "code": "E96001.code",
        "errorId": "E96001.errorId",
        "message": "E96001.message",
        "statusCode": "E96001.statusCode"
    },
    "E96002": {
        "code": "E96002.code",
        "errorId": "E96002.errorId",
        "message": "E96002.message",
        "statusCode": "E96002.statusCode"
    },
    "E96003": {
        "code": "E96003.code",
        "errorId": "E96003.errorId",
        "message": "E96003.message",
        "statusCode": "E96003.statusCode"
    },
    "E96004": {
        "code": "E96004.code",
        "errorId": "E96004.errorId",
        "message": "E96004.message",
        "statusCode": "E96004.statusCode"
    },
    "E96005": {
        "code": "E96005.code",
        "errorId": "E96005.errorId",
        "message": "E96005.message",
        "statusCode": "E96005.statusCode"
    },
    "E96006": {
        "code": "E96006.code",
        "errorId": "E96006.errorId",
        "message": "E96006.message",
        "statusCode": "E96006.statusCode"
    },
    "E96007": {
        "code": "E96007.code",
        "errorId": "E96007.errorId",
        "message": "E96007.message",
        "statusCode": "E96007.statusCode"
    },
    "E97000": {
        "code": "E97000.code",
        "errorId": "E97000.errorId",
        "message": "E97000.message",
        "statusCode": "E97000.statusCode"
    },
    "E97001": {
        "code": "E97001.code",
        "errorId": "E97001.errorId",
        "message": "E97001.message",
        "statusCode": "E97001.statusCode"
    },
    "E97002": {
        "code": "E97002.code",
        "errorId": "E97002.errorId",
        "message": "E97002.message",
        "statusCode": "E97002.statusCode"
    },
    "E97003": {
        "code": "E97003.code",
        "errorId": "E97003.errorId",
        "message": "E97003.message",
        "statusCode": "E97003.statusCode"
    },
    "E97004": {
        "code": "E97004.code",
        "errorId": "E97004.errorId",
        "message": "E97004.message",
        "statusCode": "E97004.statusCode"
    },
    "E98000": {
        "code": "E98000.code",
        "errorId": "E98000.errorId",
        "message": "E98000.message",
        "statusCode": "E98000.statusCode"
    },
    "E98001": {
        "code": "E98001.code",
        "errorId": "E98001.errorId",
        "message": "E98001.message",
        "statusCode": "E98001.statusCode"
    },
    "E98002": {
        "code": "E98002.code",
        "errorId": "E98002.errorId",
        "message": "E98002.message",
        "statusCode": "E98002.statusCode"
    },
    "E99000": {
        "code": "E99000.code",
        "errorId": "E99000.errorId",
        "message": "E99000.message",
        "statusCode": "E99000.statusCode"
    },
    "E99001": {
        "code": "E99001.code",
        "errorId": "E99001.errorId",
        "message": "E99001.message",
        "statusCode": "E99001.statusCode"
    },
    "E99002": {
        "code": "E99002.code",
        "errorId": "E99002.errorId",
        "message": "E99002.message",
        "statusCode": "E99002.statusCode"
    },
    "E99003": {
        "code": "E99003.code",
        "errorId": "E99003.errorId",
        "message": "E99003.message",
        "statusCode": "E99003.statusCode"
    },
    "E99004": {
        "code": "E99004.code",
        "errorId": "E99004.errorId",
        "message": "E99004.message",
        "statusCode": "E99004.statusCode"
    },
    "E99005": {
        "code": "E99005.code",
        "errorId": "E99005.errorId",
        "message": "E99005.message",
        "statusCode": "E99005.statusCode"
    },
    "E99006": {
        "code": "E99006.code",
        "errorId": "E99006.errorId",
        "message": "E99006.message",
        "statusCode": "E99006.statusCode"
    },
    "E99007": {
        "code": "E99007.code",
        "errorId": "E99007.errorId",
        "message": "E99007.message",
        "statusCode": "E99007.statusCode"
    },
    "E99008": {
        "code": "E99008.code",
        "errorId": "E99008.errorId",
        "message": "E99008.message",
        "statusCode": "E99008.statusCode"
    },
    "E99999": {
        "code": "E99999.code",
        "errorId": "E99999.errorId",
        "message": "E99999.message",
        "statusCode": "E99999.statusCode"
    }
}