import * as React from 'react';

import { Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';

import { context } from '@/user/components/mfp/Context';

const Consumer = context.Consumer;

const Component: React.FC = () => (
    <Consumer>
        {(app) =>
            app && (
                <Grid container wrap="nowrap" spacing={16} justify="flex-end">
                    <Grid item style={{ padding: 0 }}>
                        <Fab
                            data-testid="modalOpenSP"
                            aria-label="Add"
                            onClick={() => {
                                app.openMFPListModal();
                                app.getMfps();
                            }}
                        >
                            <Add />
                        </Fab>
                    </Grid>
                </Grid>
            )
        }
    </Consumer>
);

export default Component;
