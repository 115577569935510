import * as React from 'react';

import { Theme, createStyles, WithStyles, withStyles, Modal, AppBar, Toolbar, Typography, IconButton, Grid } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import * as locale from '@/common/utils/locale/locale';
import { context } from '@/user/components/mfp/Context';
import PreserveButton from '@/user/components/mfp/Modal/PreserveButton';
import RegistableMFPList from '@/user/components/mfp/Modal/RegistableMFPList';
import { isLoaded, isLoading } from '@/common/components/hooks/useUI';
import LoadingState from '@/common/components/ui-state/LoadingState';
import environment from '@/common/constants/environment';

const selectColor = (tenant: string) => {
    switch (tenant) {
        case "ricoh":
            return '#E06666';
        default:
            return '#607d8b';
    }
}


const Consumer = context.Consumer;

const styles = (theme: Theme) =>
    createStyles({
        txtBox: {
            marginBottom: theme.spacing.unit * 3,
            '&& p': {
                margin: 0,
            },
            alignItems: 'center',
        },
        title: {
            color: theme.palette.grey[700],
            alignItems: 'center',
        },
        buttonCase: {
            align: 'center',
            textAlign: 'center',
        },
        modalCreate: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignSelf: 'center',
            backgroundColor: '#fff',
            flexGrow: 1,
            height: '100%',
            padding: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            alignItems: 'center',
            overflow: 'auto',
            outline: 'none',
        },
        header: {
            width: '100%',
            backgroundColor: selectColor(environment.tenant),
        },
        heading: {
            margin: '0 auto',
            fontSize: theme.spacing.unit * 2,
            fontWeight: 'bold',
        },
        container: {
            width: '25%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                marginLeft: theme.spacing.unit * 1,
                marginRight: theme.spacing.unit * 1,
            },
        },
        close: {
            position: 'absolute',
            right: 0,
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;

    return (
        <Consumer>
            {(app) =>
                app && (
                    <>
                        {/* ModalOuter.tsxでもModalコンポーネントを開いているので2重にモーダルが開いてしまっている。 */}
                        <Modal open={true}>
                            <div data-testid="modal" className={classes.modalCreate}>
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <AppBar position="static" className={classes.header}>
                                        <Toolbar variant="dense">
                                            <Typography color="inherit" className={classes.heading}>
                                                {locale.t(locale.keys.mfp.addShareDevice)}
                                            </Typography>
                                            <IconButton color="inherit" aria-label="Close" className={classes.close} onClick={() => app.closeMFPListModal()}>
                                                <Close />
                                            </IconButton>
                                        </Toolbar>
                                        {isLoading(app.getStateObject(app.mfpUI)) ? <LoadingState isModal={true} /> : <></>}
                                    </AppBar>
                                    {isLoaded(app.getStateObject(app.mfpUI)) ? (
                                        <div className={classes.container}>
                                            <RegistableMFPList />
                                            <div className={classes.txtBox}>
                                                <p className={classes.title}>{locale.t(locale.keys.modalLabel.mfp.model)}</p>
                                                <p>{app.activePrinter.model}</p>
                                            </div>
                                            <div className={classes.txtBox}>
                                                <p className={classes.title}>{locale.t(locale.keys.modalLabel.mfp.machineNumber)}</p>
                                                <p>{app.activePrinter.number}</p>
                                            </div>
                                            <div className={classes.buttonCase}>
                                                <PreserveButton />
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            </div>
                        </Modal>
                    </>
                )
            }
        </Consumer>
    );
};

export default withStyles(styles)(Component);
