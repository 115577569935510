import React from 'react';
import useTitle from '@/common/components/hooks/useTitle';
// import locale from '@/common/utils/locale';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { EditCloudContainer } from './EditCloudContainer';
import EditCloud from './EditCloud';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: -theme.spacing.unit,
            [theme.breakpoints.down('md')]: {
                marginLeft: theme.spacing.unit * 2,
                marginRight: theme.spacing.unit * 2,
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    onTitle?: () => void;
}

export const Component: React.FC<Props> = (props) => {
    useTitle('クラウド接続編集');
    if (typeof props.onTitle === 'function') {
        props.onTitle();
    }
    return (
        <div className={props.classes.root}>
            <EditCloudContainer.Provider>
                <EditCloud />
            </EditCloudContainer.Provider>
        </div>
    )
}
export default withStyles(styles)(Component);
