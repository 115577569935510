import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import { default as consts } from '@/common/constants';
import * as schema from '@/bundles/schema/typescript/schema';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export interface Props {
    // state
    showPassword: boolean;
    showPasswordConfirm: boolean;
    errors: validator.ValidationOutput | null;
}
export const NewProps = (): Props => {
    return {
        showPassword: false,
        showPasswordConfirm: false,
        errors: NewValidation(),
    };
};

export interface Form {
    password: string;
    passwordConfirm: string;
}
export const NewForm = (): Form => {
    return {
        password: '',
        passwordConfirm: '',
    };
};

export const validations = (): validator.Constraints => {
    return {
        password: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            passwordPolicy: {
                message: locale.t(locale.keys.validation.passwordPolicy),
            },
        },
        passwordConfirm: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            equality: {
                attribute: 'password',
                message: locale.t(locale.keys.validation.notEnough),
            },
        },
    };
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        password: null,
        passwordConfirm: null,
    };
};

// パスワード設定API
export const resetPassword = async (req: schema.V1UserUpdateRequest, auth: string): Promise<schema.V1UserUpdateResponse> => {
    const response = await axiosFactory.patch<schema.V1UserUpdateResponse>(`https://${consts.environment.api.hostname}/v1/user`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
