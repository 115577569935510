import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Typography, Grid, Switch } from '@material-ui/core';
import { EditCloudContainer } from './EditCloudContainer';
import * as locale from '@/common/utils/locale/locale';
import RegistableCloudList from './RegistableCloudList';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        switchContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        switch: {
            marginLeft: 'auto',
            marginRight: theme.spacing.unit * 2,
        },
        folderSelect: {
            marginTop: theme.spacing.unit * 2,
            width: '100%',
        },
        typography: {
            marginBottom: theme.spacing.unit,
            marginTop: theme.spacing.unit * 3,
        },
    });

export interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const editCloudContainer = EditCloudContainer.useContainer();
    return (
        <>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <div>
                        <RegistableCloudList />
                    </div>
                    <div className={classes.switchContainer}>
                        <Typography>{locale.t(locale.keys.createEnpoint.upload)}</Typography>
                        <Switch
                            onChange={editCloudContainer.handleSwitch('isUploadable')}
                            className={classes.switch}
                            disabled={editCloudContainer.activeStorage && editCloudContainer.activeStorage.isUploadable ? false : true}
                            checked={editCloudContainer.upDownFeature.isUploadable}
                        />
                    </div>
                    <div className={classes.switchContainer}>
                        <Typography>{locale.t(locale.keys.createEnpoint.download)}</Typography>
                        <Switch
                            onChange={editCloudContainer.handleSwitch('isDownloadable')}
                            className={classes.switch}
                            disabled={editCloudContainer.activeStorage && editCloudContainer.activeStorage.isDownloadable ? false : true}
                            checked={editCloudContainer.upDownFeature.isDownloadable}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles)(Component);
