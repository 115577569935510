import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Theme, createStyles, WithStyles, withStyles, Toolbar, AppBar, IconButton, Typography, Grid } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import LoadingState from '@/common/components/ui-state/LoadingState';
import { UserAppContainer } from '../../UserAppContainer';

const styles = (theme: Theme) =>
    createStyles({
        header: {
            width: '100%',
        },
        arrowBackButton: {
            position: 'absolute',
            left: 0,
        },
        heading: {
            margin: '0 auto',
            fontSize: '16px',
        },
        desc: {
            margin: '0 auto',
            width: 500,
            [theme.breakpoints.down('xs')]: {
                width: 250,
            },
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    title?: string;
    pageDescription?: string;
}

export const Component: React.FC<Props> = (props: Props) => {
    const appContainer = UserAppContainer.useContainer();
    return (
        <>
            <AppBar position="static" className={props.classes.header}>
                <Toolbar variant="dense">
                    <IconButton className={props.classes.arrowBackButton} color="inherit" aria-label="Close" onClick={() => props.history.goBack()}>
                        <ArrowBack />
                    </IconButton>

                    <Grid container direction="column" justify="center" alignItems="center">
                        <Typography className={props.classes.heading} variant="h4" color="inherit">
                            {props.title}
                        </Typography>
                        {props.pageDescription && (
                            <Typography className={props.classes.desc} variant="caption" color="inherit">
                                {
                                    props.pageDescription /*.split('\n').map((value, index) => {
                                    return <div key={index}>{value}</div>
                                })*/
                                }
                            </Typography>
                        )}
                    </Grid>
                </Toolbar>
            </AppBar>
            {appContainer.loadingState && <LoadingState isModal={true} />}
        </>
    );
};

export default withStyles(styles)(withRouter(Component));
