import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { Variants } from '@/common/components/messages/CommonMessage';
import { default as UI } from '@/common/constants/ui';
import { UserAppContainer } from '../UserAppContainer';
import * as schema from '@/bundles/schema/typescript/schema';
import * as spoolModel from '@/user/models/spool/spool';
import locale from '@/common/utils/locale';
import * as errorHandler from '@/common/utils/errorHandler';

const useDeviceContainer = () => {
    const [ui, setUI] = useState(UI.state.Loading);
    const appContainer = UserAppContainer.useContainer();
    const [tabIndex, setTabIndex] = useState(0);
    const [spoolList, setSpoolList] = useState<schema.V1ObjectsSpoolersIndex[]>([]);
    const [isItemFull, setIsItemFull] = useState(false);

    const [spoolListProcessing, setSpoolListProcessing] = useState<schema.V1ObjectsSpoolersIndex[]>([]);
    const [offsetProcessing, setOffsetProcessing] = useState('0');

    const [spoolListComplete, setSpoolListComplete] = useState<schema.V1ObjectsSpoolersIndex[]>([]);
    const [offsetComplete, setOffsetComplete] = useState('0');

    const [spoolListError, setSpoolListError] = useState<schema.V1ObjectsSpoolersIndex[]>([]);
    const [offsetError, setOffsetError] = useState('0');

    const [spoolListDelete, setSpoolListDelete] = useState<schema.V1ObjectsSpoolersIndex[]>([]);
    const [offsetDelete, setOffsetDelete] = useState('0');

    // TODO: will be updated in the future after reaching agreement on the number of pages on the same site
    const rowsPerPage = 20;
    // only use for tab Completed and Deleted
    const maxItemPerTab = 1000;

    const handleChangeTabIndex = async (event: React.ChangeEvent<{}>, newValue: number) => {
        if (newValue === tabIndex) {
            return;
        }
        setTabIndex(newValue);
        setIsItemFull(false);
        setSpoolList([]);
        switch (newValue) {
            case 1:
                await getSpoolList(1, true);
                break;
            case 2:
                await getSpoolList(2, true);
                break;
            case 3:
                await getSpoolList(3, true);
                break;
            default:
                await getSpoolList(0, true);
        }
    };

    const loadDummyData = (dummySpoolList: schema.V1ObjectsSpoolersIndex[]) => {
        setSpoolList(dummySpoolList);
    };

    const getSpoolList = async (currentTab: number, isInitLoad?: boolean) => {
        try {
            let queryType = schema.QueryType.Processing;
            let offset = isInitLoad ? '0' : offsetProcessing;
            let limit = rowsPerPage;
            switch (currentTab) {
                case 1:
                    queryType = schema.QueryType.Error;
                    offset = isInitLoad ? '0' : offsetError;
                    break;
                case 2:
                    queryType = schema.QueryType.Completed;
                    offset = isInitLoad ? '0' : offsetComplete;
                    if (maxItemPerTab - spoolListComplete.length < limit) {
                        limit = maxItemPerTab - spoolListComplete.length;
                    }
                    break;
                case 3:
                    queryType = schema.QueryType.Deleted;
                    offset = isInitLoad ? '0' : offsetDelete;
                    if (maxItemPerTab - spoolListDelete.length < limit) {
                        limit = maxItemPerTab - spoolListDelete.length;
                    }
                    break;
            }
            setUI(UI.state.Loading);
            const dataResponse = await spoolModel.getSpoolList(appContainer.values.authorizationCode, queryType, offset, limit.toString());
            setUI(UI.state.Loaded);
            if (!dataResponse) {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.error.default),
                    variant: Variants.error,
                });
                return;
            }
            if (dataResponse.data.length === 0) {
                setIsItemFull(true);
                return;
            }
            switch (currentTab) {
                case 1:
                    let listError = dataResponse.data;
                    if (!isInitLoad) {
                        listError = removeDuplicate(spoolListError, dataResponse.data);
                    }
                    setSpoolList(listError);
                    setSpoolListError(listError);
                    setOffsetError(dataResponse.offset ? dataResponse.offset.toString() : '0');
                    break;
                case 2:
                    if (spoolListComplete.length === maxItemPerTab) {
                        setIsItemFull(true);
                        return;
                    }
                    let listComplete = dataResponse.data;
                    if (!isInitLoad) {
                        listComplete = removeDuplicate(spoolListComplete, dataResponse.data);
                    }
                    setSpoolList(listComplete);
                    setSpoolListComplete(listComplete);
                    setOffsetComplete(dataResponse.offset ? dataResponse.offset.toString() : '0');
                    break;
                case 3:
                    if (spoolListDelete.length === maxItemPerTab) {
                        setIsItemFull(true);
                        return;
                    }
                    let listDelete = dataResponse.data;
                    if (!isInitLoad) {
                        listDelete = removeDuplicate(spoolListDelete, dataResponse.data);
                    }
                    setSpoolList(listDelete);
                    setSpoolListDelete(listDelete);
                    setOffsetDelete(dataResponse.offset ? dataResponse.offset.toString() : '0');
                    break;
                default:
                    let listSpoolProcessing = dataResponse.data;
                    if (!isInitLoad) {
                        listSpoolProcessing = removeDuplicate(spoolListProcessing, dataResponse.data);
                    }
                    setSpoolList(listSpoolProcessing);
                    setSpoolListProcessing(listSpoolProcessing);
                    setOffsetProcessing(dataResponse.offset ? dataResponse.offset.toString() : '0');
            }
        } catch (e) {
            setUI(UI.state.Error);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const handleClickRetry = async (index: string) => {
        try {
            const dataResponse = await spoolModel.retrySpoolObject(appContainer.values.authorizationCode, spoolList[Number(index)].id);
            if (!dataResponse) {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.error.default),
                    variant: Variants.error,
                });
                return;
            }
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.updated),
                variant: Variants.success,
            });
            const dataList = [...spoolList];
            const dataListError = [...spoolListError];
            dataList.splice(Number(index), 1);
            dataListError.splice(Number(index), 1);
            setSpoolList(dataList);
            setSpoolListError(dataListError);
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const handleClickRemove = async (id: string) => {
        try {
            const dataResponse = await spoolModel.destroySpoolObject(appContainer.values.authorizationCode, id);
            if (!dataResponse) {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.error.default),
                    variant: Variants.error,
                });
                return;
            }
            if (dataResponse && dataResponse.status === schema.V1ObjectsModifyResponseStatusEnum.Accepted) {
                let dataList = [...spoolList];
                dataList = dataList.filter((item: schema.V1ObjectsSpoolersIndex) => id !== item.id);
                setSpoolList(dataList);
                let dataListError = [...spoolListError];
                dataListError = dataListError.filter((item: schema.V1ObjectsSpoolersIndex) => id !== item.id);
                setSpoolListError(dataListError);
                setOffsetError((Number(offsetError) - 1).toString());
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.deleted),
                    variant: Variants.success,
                });
            }
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const confirmRemove = (isYes: boolean, value?: string) => {
        if (!isYes) {
            return;
        }
        if (typeof value === 'string') {
            handleClickRemove(value);
        }
    };

    const handleClickDownload = async (index: string) => {
        try {
            const dataResponse = await spoolModel.downloadSpoolObjectFailed(appContainer.values.authorizationCode, spoolList[Number(index)].id);
            if (!dataResponse) {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.error.default),
                    variant: Variants.error,
                });
                return;
            }
            // The url (recover link) returns a downloadable file rather than a new page
            window.location.href = dataResponse.recoverLink;
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const removeDuplicate = (array1: schema.V1ObjectsSpoolersIndex[], array2: schema.V1ObjectsSpoolersIndex[]) => {
        const array = [...array1, ...array2];
        const idArr = array.map((item) => item.id);
        const newArr = array.filter((value, index) => {
            return index === idArr.indexOf(value.id);
        });
        return newArr;
    };

    return {
        ui,
        setUI,
        loadDummyData,
        confirmRemove,
        handleClickRetry,
        getSpoolList,
        handleChangeTabIndex,
        tabIndex,
        spoolList,
        isItemFull,
        handleClickDownload,
        setSpoolList,
    };
};
export const SpoolContainer = createContainer(useDeviceContainer);
