import { useState } from 'react';
import logger from '@/common/utils/logger';

import { createContainer } from 'unstated-next';
import * as model from '@/user/models/change-password/changePassword';
import * as validator from '@/common/utils/validator';

const useChangeContainer = () => {
    const [values, setValues] = useState<model.Props>(model.NewProps());
    const [form, setForm] = useState<model.Form>(model.NewForm());

    const [touched, setTouched] = useState<string[]>([]);
    const touchForm = (name: keyof model.Form) => {
        if (!touched.includes(name)) {
            touched.push(name);
            setTouched(touched);
        }
    };

    const handleChange = (name: keyof model.Form) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const changed = { ...form, [name]: event.target.value };
        setForm(changed);
        touchForm(name);

        const valideteOutput = validator.Validate<model.Form>(changed, model.validations(), model.NewValidation);
        const validated = model.NewValidation();
        if (valideteOutput) {
            touched.forEach((key) => {
                validated[key] = valideteOutput[key];
            });
            setValues({ ...values, errors: validated });
        } else {
            setValues({ ...values, errors: null });
        }
    };

    const handleClickShowPassword = (name: keyof model.Props) => {
        setValues({ ...values, [name]: !values[name] });
    };

    const isError = (name: keyof model.Form) => {
        return !!(values.errors && name in values.errors && !!values.errors[name]);
    };

    const getHelperText = (name: keyof model.Form) => {
        if (values.errors && name in values.errors && values.errors[name]) {
            return values.errors[name];
        }
        return '';
    };
    const buttonDisabled = () => {
        return values.errors !== null;
    };

    const savePassword = (workspaceId: string, userEmail: string, auth: string) => {
        logger.debug('savePassword');
        return model.resetPassword(
            {
                workspace: workspaceId,
                email: userEmail,
                password: form.password,
            },
            auth,
        );
    };
    return { values, form, handleChange, handleClickShowPassword, isError, getHelperText, buttonDisabled, savePassword };
};
export const ChangeContainer = createContainer(useChangeContainer);
