import * as React from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import * as locale from '@/common/utils/locale/locale';
import { context } from '@/user/components/mfp/Context';

const styles = (theme: Theme) =>
    createStyles({
        button: {
            width: '70%',
            '&& span': {
                fontSize: '1.25rem',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('lg')]: {},
        },
    });

const Consumer = context.Consumer;

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    return (
        <Consumer>
            {(app) =>
                app && (
                    <Button
                        disabled={!app.activePrinter || app.activePrinter.id === '' ? true : false}
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            app.registerMFP(app.activePrinter);
                            app.closeMFPListModal();
                        }}
                        className={classes.button}
                    >
                        {locale.t(locale.keys.modalLabel.mfp.registerNewDevice)}
                    </Button>
                )
            }
        </Consumer>
    );
};

export default withStyles(styles)(Component);
