import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { NewEndpointContainer } from '../NewEndpointContainer';
import PermissionTable from '../../permission-setting/PermissionTable/PermissionTable';
import EnablePermission from '../../permission-setting/EnablePermission';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
    });

export interface Props extends WithStyles<typeof styles> {
    isShared: boolean;
}

const StepFive: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const newEndpointContainer = NewEndpointContainer.useContainer();

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item style={{ width: '100%' }}>
                <EnablePermission
                    isShared={props.isShared}
                    handleEnablePermisson={newEndpointContainer.endpoints.handleEnablePermisson}
                    enablePermission={newEndpointContainer.endpoints.enablePermission}
                    applicationPermission={newEndpointContainer.endpoints.applicationPermission}
                    handleApplicationPermission={newEndpointContainer.endpoints.handleApplicationPermission}
                />
            </Grid>
            <Grid item style={{ width: '100%', overflow: 'auto' }}>
                {(newEndpointContainer.endpoints.enablePermission.userGroupPermission || newEndpointContainer.endpoints.enablePermission.mfpPermission) && (
                    <PermissionTable
                        isShared={props.isShared}
                        enablePermission={newEndpointContainer.endpoints.enablePermission}
                        wsData={newEndpointContainer.endpoints.wsData}
                        mfpPermission={newEndpointContainer.endpoints.permissionRows.mfpPermission}
                        handleMfpPermission={newEndpointContainer.endpoints.handleMfpPermission}
                        selectedMfpPermission={newEndpointContainer.endpoints.selectedMfpPermission}
                        userPermission={newEndpointContainer.endpoints.permissionRows.userPermission}
                        handleUserPermission={newEndpointContainer.endpoints.handleUserPermission}
                        selectedUserPermission={newEndpointContainer.endpoints.selectedUserPermission}
                        groupPermission={newEndpointContainer.endpoints.permissionRows.groupPermission}
                        handleGroupPermission={newEndpointContainer.endpoints.handleGroupPermission}
                        selectedGroupPermission={newEndpointContainer.endpoints.selectedGroupPermission}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(StepFive);
