import { default as React, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router';
import { Theme, Button, TextField, InputAdornment, IconButton, Typography, createStyles, withStyles, WithStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { UserAppContainer } from '@/user/components/UserAppContainer';
import { ChangeContainer } from './ChangeContainer';

import locale from '@/common/utils/locale';
import logger from '@/common/utils/logger';
import * as errorHandler from '@/common/utils/errorHandler';
import routes from '@/user/constants/routes';
import { Variants } from '@/common/components/messages/CommonMessage';
import Error from '@/common/components/state/Error';
import Loading from '@/common/components/state/Loading';
import Saving from '@/common/components/state/Saving';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import CovasAvatar from '@/common/components/CovasAvatar';

const styles = (theme: Theme) =>
    createStyles({
        heading: {
            margin: `0 auto ${theme.spacing.unit * 3}px`,
            wordWrap: 'break-word',
        },
        workspaceAvatar: {
            margin: `0 auto ${theme.spacing.unit * 3}px`,
        },
        mb20: {
            marginBottom: 20,
        },
        button: {
            '&& span': {
                fontSize: '1.25rem',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('lg')]: {
                width: 'auto',
            },
        },
    });

interface Props extends RouteComponentProps, WithStyles<typeof styles> {
    ui?: UI;
    skipEffect?: boolean;
}

export const Component: React.FC<Props> = (props) => {
    const appContainer = UserAppContainer.useContainer();
    const changeContainer = ChangeContainer.useContainer();
    const ui = useUI(props.ui);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        if (props.skipEffect) {
            return;
        }
        if (ui.current !== UI.Loading) {
            logger.debug(`useEffect skip. UI is not 'Loading'`);
            return;
        }
        ui.update(UI.Loaded);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = () => (event: React.MouseEvent<HTMLInputElement>) => {
        logger.debug(`handleClick`);
        if (changeContainer.values.errors !== null) {
            return false;
        }
        ui.update(UI.Saving);
        (async () => {
            try {
                const result = await changeContainer.savePassword(
                    appContainer.values.signinWorkspaceObject.displayId,
                    appContainer.values.signinWorkspaceUserObject.invitationEmail,
                    appContainer.values.authorizationCode,
                );
                logger.debug(`savePassword success`);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.saved),
                    variant: Variants.success,
                });
                // TODO: get authorizationCode
                appContainer.values.authorizationCode = result.id;
                appContainer.setValues(appContainer.values);
                ui.update(UI.Loaded);
                setLoggedIn(true);
            } catch (e) {
                ui.update(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        return;
    };
    return (
        <>
            {loggedIn ? (
                <Redirect to={routes.dashboard.index} />
            ) : (
                <>
                    {ui.current === UI.Loading && (
                        <div data-testid={UI.Loading}>
                            <Loading />
                        </div>
                    )}

                    {ui.current === UI.Loaded && (
                        <div data-testid={UI.Loaded}>
                            <CovasAvatar
                                alt="Workspace"
                                size={160}
                                seed={appContainer.values.signinWorkspaceObject ? appContainer.values.signinWorkspaceObject.id : ''}
                                src={appContainer.values.signinWorkspaceObject ? appContainer.values.signinWorkspaceObject.logoUrl : ''}
                                className={props.classes.workspaceAvatar}
                            />
                            <Typography className={props.classes.heading} variant="h3" color="inherit">
                                {locale.t(locale.keys.memberSignup.workspace, {
                                    workspaceName: appContainer.values.signinWorkspaceObject ? appContainer.values.signinWorkspaceObject.displayName : '',
                                    workspaceId: appContainer.values.signinWorkspaceObject ? appContainer.values.signinWorkspaceObject.displayId : '',
                                })}
                            </Typography>
                            <TextField
                                className={props.classes.mb20}
                                fullWidth
                                label={locale.t(locale.keys.common.email)}
                                defaultValue={appContainer.values.signinWorkspaceUserObject ? appContainer.values.signinWorkspaceUserObject.invitationEmail : ''}
                                margin="normal"
                                disabled={true}
                                inputProps={{
                                    readOnly: true,
                                    style: { height: '100%' },
                                }}
                            />
                            <TextField
                                className={props.classes.mb20}
                                type={changeContainer.values.showPassword ? 'text' : 'password'}
                                fullWidth
                                variant="filled"
                                label={locale.t(locale.keys.common.password)}
                                value={changeContainer.form.password}
                                onChange={changeContainer.handleChange('password')}
                                error={changeContainer.isError('password')}
                                helperText={changeContainer.getHelperText('password')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="Toggle password visibility" onClick={() => changeContainer.handleClickShowPassword('showPassword')}>
                                                {changeContainer.values.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    inputProps: { style: { height: '100%' } },
                                }}
                            />
                            <TextField
                                className={props.classes.mb20}
                                type={changeContainer.values.showPasswordConfirm ? 'text' : 'password'}
                                fullWidth
                                variant="filled"
                                label={locale.t(locale.keys.common.passwordConfirm)}
                                value={changeContainer.form.passwordConfirm}
                                onChange={changeContainer.handleChange('passwordConfirm')}
                                error={changeContainer.isError('passwordConfirm')}
                                helperText={changeContainer.getHelperText('passwordConfirm')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="Toggle verify visibility" onClick={() => changeContainer.handleClickShowPassword('showPasswordConfirm')}>
                                                {changeContainer.values.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    inputProps: { style: { height: '100%' } },
                                }}
                            />

                            <Button className={props.classes.button} fullWidth variant="contained" disabled={changeContainer.buttonDisabled()} onClick={handleClick()}>
                                {locale.t(locale.keys.action.save)}
                            </Button>
                        </div>
                    )}

                    {ui.current === UI.Saving && (
                        <div data-testid={UI.Saving}>
                            <Saving />
                        </div>
                    )}

                    {ui.current === UI.Error && (
                        <div data-testid={UI.Error}>
                            <Error />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default withRouter(withStyles(styles)(Component));
