import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { EditCloudContainer } from './EditCloudContainer';
import FolderSelect from '@/user/components/common/endpoints/folders/FolderSelect';
import * as schema from '@/bundles/schema/typescript/schema';
import ChooseName from '@/user/components/common/endpoints/choose-name/ChooseName';
import StepMail from '@/user/components/common/endpoints/step-mail/StepMail';

const styles = (theme: Theme) =>
    createStyles({
        folderSelect: {
            width: '100%',
            marginLeft: '3px',
            marginTop: theme.spacing.unit * 2,
            marginBottom: theme.spacing.unit * 2,
        },
    });

export interface Props extends WithStyles<typeof styles> {
    storages?: string;
    theme: Theme;
}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const editCloudContainer = EditCloudContainer.useContainer();

    const getStepThree = (service: schema.V1ObjectsServiceEnum) => {
        if (service && schema.V1ObjectsServiceEnum.Email === service) {
            return <StepMail mailList={editCloudContainer.mailList} />;
        }
        if (service && schema.V1ObjectsServiceEnum.Docard === service) {
            return (
                <div className={classes.folderSelect}>
                    {editCloudContainer.activeStorage && (
                        <ChooseName
                            id={editCloudContainer.activeStorage.xstorageId}
                            endpointType={editCloudContainer.activeStorage.service}
                            cloudAcc={editCloudContainer.activeStorage.authorizationId}
                            pathSelect={editCloudContainer.getPath}
                            path={editCloudContainer.tmpPathName}
                        />
                    )}
                </div>
            );
        }
        return (
            <div className={classes.folderSelect}>
                {editCloudContainer.activeStorage && (
                    <FolderSelect
                        id={editCloudContainer.activeStorage.xstorageId}
                        endpointType={editCloudContainer.activeStorage.service}
                        cloudAcc={editCloudContainer.activeStorage.authorizationId}
                        pathSelect={editCloudContainer.getPath}
                        path={editCloudContainer.tmpPathName}
                        pathId={editCloudContainer.idPath}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={6}>
                    {editCloudContainer.activeStorage && getStepThree(editCloudContainer.activeStorage.service)}
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
