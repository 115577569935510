export default {
    "E00000": "E00000",
    "E00001": "E00001",
    "E00002": "E00002",
    "E00003": "E00003",
    "E01000": "E01000",
    "E01001": "E01001",
    "E01002": "E01002",
    "E01003": "E01003",
    "E01004": "E01004",
    "E01005": "E01005",
    "E01006": "E01006",
    "E01007": "E01007",
    "E01008": "E01008",
    "E01009": "E01009",
    "E01010": "E01010",
    "E02000": "E02000",
    "E02001": "E02001",
    "E02002": "E02002",
    "E02003": "E02003",
    "E02004": "E02004",
    "E02005": "E02005",
    "E02006": "E02006",
    "E02007": "E02007",
    "E02008": "E02008",
    "E02009": "E02009",
    "E02010": "E02010",
    "E02011": "E02011",
    "E02012": "E02012",
    "E02013": "E02013",
    "E02014": "E02014",
    "E02015": "E02015",
    "E02016": "E02016",
    "E02017": "E02017",
    "E02018": "E02018",
    "E02019": "E02019",
    "E02020": "E02020",
    "E02021": "E02021",
    "E02022": "E02022",
    "E03000": "E03000",
    "E03001": "E03001",
    "E03002": "E03002",
    "E03003": "E03003",
    "E03004": "E03004",
    "E03005": "E03005",
    "E03006": "E03006",
    "E03007": "E03007",
    "E03008": "E03008",
    "E03009": "E03009",
    "E03010": "E03010",
    "E03011": "E03011",
    "E03012": "E03012",
    "E03013": "E03013",
    "E03014": "E03014",
    "E03015": "E03015",
    "E03016": "E03016",
    "E04000": "E04000",
    "E04001": "E04001",
    "E04002": "E04002",
    "E04003": "E04003",
    "E04004": "E04004",
    "E04005": "E04005",
    "E04006": "E04006",
    "E04007": "E04007",
    "E04008": "E04008",
    "E04009": "E04009",
    "E04010": "E04010",
    "E04011": "E04011",
    "E04012": "E04012",
    "E04013": "E04013",
    "E04014": "E04014",
    "E04015": "E04015",
    "E04016": "E04016",
    "E04017": "E04017",
    "E04018": "E04018",
    "E04019": "E04019",
    "E04020": "E04020",
    "E04021": "E04021",
    "E04022": "E04022",
    "E04023": "E04023",
    "E04024": "E04024",
    "E04025": "E04025",
    "E04026": "E04026",
    "E04027": "E04027",
    "E04028": "E04028",
    "E04029": "E04029",
    "E04030": "E04030",
    "E04031": "E04031",
    "E04032": "E04032",
    "E04033": "E04033",
    "E04034": "E04034",
    "E05000": "E05000",
    "E05001": "E05001",
    "E05002": "E05002",
    "E05003": "E05003",
    "E05004": "E05004",
    "E05005": "E05005",
    "E05006": "E05006",
    "E05007": "E05007",
    "E05008": "E05008",
    "E05009": "E05009",
    "E05010": "E05010",
    "E05011": "E05011",
    "E05012": "E05012",
    "E05013": "E05013",
    "E05014": "E05014",
    "E05015": "E05015",
    "E06000": "E06000",
    "E06001": "E06001",
    "E06002": "E06002",
    "E06004": "E06004",
    "E06005": "E06005",
    "E06006": "E06006",
    "E06007": "E06007",
    "E06020": "E06020",
    "E06021": "E06021",
    "E06022": "E06022",
    "E06024": "E06024",
    "E06025": "E06025",
    "E06040": "E06040",
    "E06041": "E06041",
    "E06042": "E06042",
    "E06044": "E06044",
    "E06045": "E06045",
    "E06046": "E06046",
    "E06047": "E06047",
    "E06060": "E06060",
    "E06061": "E06061",
    "E06062": "E06062",
    "E06064": "E06064",
    "E06065": "E06065",
    "E06066": "E06066",
    "E06067": "E06067",
    "E06080": "E06080",
    "E06081": "E06081",
    "E06082": "E06082",
    "E06084": "E06084",
    "E06085": "E06085",
    "E06100": "E06100",
    "E06101": "E06101",
    "E06102": "E06102",
    "E06104": "E06104",
    "E06105": "E06105",
    "E06120": "E06120",
    "E06124": "E06124",
    "E06125": "E06125",
    "E06140": "E06140",
    "E06141": "E06141",
    "E06144": "E06144",
    "E06145": "E06145",
    "E06160": "E06160",
    "E06161": "E06161",
    "E06162": "E06162",
    "E06164": "E06164",
    "E06165": "E06165",
    "E06180": "E06180",
    "E06181": "E06181",
    "E06182": "E06182",
    "E06184": "E06184",
    "E06185": "E06185",
    "E06200": "E06200",
    "E06201": "E06201",
    "E06202": "E06202",
    "E06203": "E06203",
    "E06204": "E06204",
    "E06205": "E06205",
    "E06206": "E06206",
    "E06207": "E06207",
    "E06208": "E06208",
    "E06300": "E06300",
    "E06301": "E06301",
    "E06302": "E06302",
    "E07000": "E07000",
    "E07001": "E07001",
    "E07002": "E07002",
    "E07003": "E07003",
    "E07004": "E07004",
    "E07005": "E07005",
    "E07006": "E07006",
    "E07007": "E07007",
    "E07008": "E07008",
    "E07009": "E07009",
    "E07010": "E07010",
    "E07011": "E07011",
    "E07012": "E07012",
    "E07014": "E07014",
    "E07015": "E07015",
    "E07016": "E07016",
    "E07017": "E07017",
    "E07018": "E07018",
    "E07019": "E07019",
    "E07020": "E07020",
    "E07021": "E07021",
    "E07022": "E07022",
    "E07023": "E07023",
    "E08000": "E08000",
    "E08001": "E08001",
    "E08002": "E08002",
    "E08003": "E08003",
    "E08004": "E08004",
    "E08005": "E08005",
    "E08006": "E08006",
    "E08007": "E08007",
    "E08008": "E08008",
    "E08009": "E08009",
    "E08010": "E08010",
    "E08011": "E08011",
    "E08012": "E08012",
    "E08013": "E08013",
    "E08014": "E08014",
    "E08015": "E08015",
    "E08016": "E08016",
    "E08017": "E08017",
    "E08018": "E08018",
    "E08019": "E08019",
    "E08020": "E08020",
    "E08021": "E08021",
    "E08022": "E08022",
    "E08023": "E08023",
    "E08024": "E08024",
    "E08025": "E08025",
    "E08026": "E08026",
    "E08027": "E08027",
    "E08028": "E08028",
    "E08029": "E08029",
    "E08030": "E08030",
    "E08031": "E08031",
    "E08032": "E08032",
    "E08033": "E08033",
    "E08034": "E08034",
    "E08035": "E08035",
    "E08036": "E08036",
    "E08037": "E08037",
    "E08038": "E08038",
    "E08039": "E08039",
    "E08040": "E08040",
    "E08041": "E08041",
    "E08042": "E08042",
    "E08043": "E08043",
    "E08044": "E08044",
    "E08045": "E08045",
    "E08046": "E08046",
    "E08047": "E08047",
    "E08048": "E08048",
    "E08049": "E08049",
    "E08050": "E08050",
    "E08051": "E08051",
    "E08052": "E08052",
    "E08053": "E08053",
    "E08054": "E08054",
    "E08055": "E08055",
    "E08056": "E08056",
    "E08057": "E08057",
    "E08058": "E08058",
    "E08059": "E08059",
    "E08060": "E08060",
    "E08061": "E08061",
    "E08062": "E08062",
    "E08063": "E08063",
    "E08064": "E08064",
    "E08065": "E08065",
    "E08066": "E08066",
    "E08067": "E08067",
    "E08068": "E08068",
    "E08069": "E08069",
    "E08070": "E08070",
    "E08071": "E08071",
    "E08072": "E08072",
    "E08073": "E08073",
    "E08074": "E08074",
    "E08075": "E08075",
    "E08076": "E08076",
    "E08077": "E08077",
    "E08078": "E08078",
    "E08079": "E08079",
    "E08080": "E08080",
    "E08081": "E08081",
    "E08082": "E08082",
    "E08083": "E08083",
    "E08084": "E08084",
    "E08085": "E08085",
    "E08086": "E08086",
    "E08087": "E08087",
    "E08088": "E08088",
    "E08089": "E08089",
    "E08090": "E08090",
    "E08091": "E08091",
    "E08092": "E08092",
    "E08093": "E08093",
    "E08094": "E08094",
    "E08095": "E08095",
    "E08096": "E08096",
    "E08097": "E08097",
    "E08098": "E08098",
    "E08099": "E08099",
    "E08100": "E08100",
    "E08101": "E08101",
    "E08102": "E08102",
    "E08103": "E08103",
    "E08104": "E08104",
    "E08105": "E08105",
    "E08106": "E08106",
    "E08107": "E08107",
    "E08108": "E08108",
    "E08109": "E08109",
    "E08110": "E08110",
    "E08111": "E08111",
    "E08112": "E08112",
    "E08113": "E08113",
    "E08114": "E08114",
    "E08115": "E08115",
    "E08116": "E08116",
    "E08117": "E08117",
    "E08118": "E08118",
    "E08119": "E08119",
    "E08120": "E08120",
    "E08121": "E08121",
    "E08122": "E08122",
    "E08123": "E08123",
    "E08124": "E08124",
    "E08125": "E08125",
    "E08126": "E08126",
    "E08127": "E08127",
    "E08128": "E08128",
    "E08129": "E08129",
    "E08130": "E08130",
    "E08131": "E08131",
    "E08132": "E08132",
    "E08133": "E08133",
    "E08134": "E08134",
    "E08135": "E08135",
    "E08136": "E08136",
    "E08137": "E08137",
    "E08138": "E08138",
    "E08139": "E08139",
    "E08140": "E08140",
    "E08141": "E08141",
    "E08142": "E08142",
    "E08143": "E08143",
    "E08144": "E08144",
    "E08145": "E08145",
    "E08146": "E08146",
    "E08147": "E08147",
    "E08148": "E08148",
    "E08149": "E08149",
    "E08150": "E08150",
    "E08151": "E08151",
    "E08152": "E08152",
    "E08153": "E08153",
    "E08154": "E08154",
    "E08155": "E08155",
    "E08156": "E08156",
    "E08157": "E08157",
    "E08158": "E08158",
    "E08159": "E08159",
    "E08160": "E08160",
    "E08161": "E08161",
    "E08162": "E08162",
    "E08163": "E08163",
    "E08164": "E08164",
    "E08165": "E08165",
    "E08166": "E08166",
    "E08167": "E08167",
    "E08168": "E08168",
    "E08169": "E08169",
    "E08170": "E08170",
    "E08171": "E08171",
    "E08172": "E08172",
    "E08173": "E08173",
    "E08174": "E08174",
    "E08175": "E08175",
    "E08176": "E08176",
    "E08177": "E08177",
    "E08178": "E08178",
    "E08179": "E08179",
    "E08180": "E08180",
    "E08181": "E08181",
    "E08182": "E08182",
    "E08183": "E08183",
    "E08184": "E08184",
    "E08185": "E08185",
    "E08186": "E08186",
    "E08187": "E08187",
    "E08188": "E08188",
    "E08189": "E08189",
    "E08190": "E08190",
    "E08191": "E08191",
    "E08192": "E08192",
    "E08193": "E08193",
    "E08194": "E08194",
    "E08195": "E08195",
    "E08196": "E08196",
    "E08197": "E08197",
    "E08198": "E08198",
    "E08199": "E08199",
    "E08200": "E08200",
    "E08201": "E08201",
    "E08202": "E08202",
    "E08203": "E08203",
    "E08204": "E08204",
    "E08205": "E08205",
    "E08206": "E08206",
    "E08207": "E08207",
    "E08208": "E08208",
    "E08209": "E08209",
    "E08210": "E08210",
    "E08211": "E08211",
    "E08212": "E08212",
    "E08213": "E08213",
    "E08214": "E08214",
    "E08215": "E08215",
    "E08216": "E08216",
    "E08217": "E08217",
    "E08218": "E08218",
    "E08219": "E08219",
    "E08220": "E08220",
    "E08221": "E08221",
    "E08222": "E08222",
    "E08223": "E08223",
    "E08224": "E08224",
    "E08225": "E08225",
    "E08226": "E08226",
    "E08227": "E08227",
    "E08228": "E08228",
    "E08229": "E08229",
    "E08230": "E08230",
    "E08231": "E08231",
    "E08232": "E08232",
    "E08233": "E08233",
    "E08234": "E08234",
    "E08235": "E08235",
    "E10000": "E10000",
    "E10001": "E10001",
    "E10002": "E10002",
    "E10003": "E10003",
    "E10004": "E10004",
    "E10005": "E10005",
    "E10006": "E10006",
    "E10999": "E10999",
    "E95000": "E95000",
    "E95001": "E95001",
    "E95002": "E95002",
    "E95003": "E95003",
    "E95004": "E95004",
    "E95005": "E95005",
    "E95006": "E95006",
    "E96000": "E96000",
    "E96001": "E96001",
    "E96002": "E96002",
    "E96003": "E96003",
    "E96004": "E96004",
    "E96005": "E96005",
    "E96006": "E96006",
    "E96007": "E96007",
    "E97000": "E97000",
    "E97001": "E97001",
    "E97002": "E97002",
    "E97003": "E97003",
    "E97004": "E97004",
    "E98000": "E98000",
    "E98001": "E98001",
    "E98002": "E98002",
    "E99000": "E99000",
    "E99001": "E99001",
    "E99002": "E99002",
    "E99003": "E99003",
    "E99004": "E99004",
    "E99005": "E99005",
    "E99006": "E99006",
    "E99007": "E99007",
    "E99008": "E99008",
    "E99999": "E99999"
}