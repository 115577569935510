import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const updateMfpUser = async (enId: string, mfpNumber: string, pin: string, auth: string): Promise<schema.V1MfpUsersShowResponse> => {
    const updateMfpUser: schema.V1MfpUsersUpdateRequest = {
        mfpNumber,
        pin,
        skipDecryption: true,
    };

    const response = await axiosFactory.patch<schema.V1MfpUsersShowResponse>(`https://${consts.environment.api.hostname}/v1/mfp/users/${encodeURIComponent(enId)}`, updateMfpUser, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const updatePin = async (enId: string, _: string, pin: string, auth: string): Promise<schema.V1MfpUsersShowResponse> => {
    const updatePin: schema.V1MfpUsersUpdateRequest = {
        pin,
        mfpNumber: '',
        skipDecryption: true,
    };

    const responce = await axiosFactory.put<schema.V1MfpUsersShowResponse>(`https://${consts.environment.api.hostname}/v1/mfp/users/${encodeURIComponent(enId)}`, updatePin, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return responce.data;
};
