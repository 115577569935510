import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import '@/common/css/app.css';
import { default as commonConsts } from '@/common/constants';
import routes from '@/user/constants/routes';
import components from '@/user/components';
import CommonMenu from '@/common/components/CommonMenu';
import CommonHeaderComponent from '@/common/components/CommonHeaderComponent';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import { default as CovasDropAndCrop } from '@/common/components/file/CovasDropAndCrop';
import GenericHeader from '@/user/components/common/headers/GenericHeader';
import SubHeader from '@/user/components/common/headers/SubHeader';
import AgentSigninHeader, { AgentContentWrapper } from '@/user/components/common/headers/AgentSigninHeader';
import userRole from '@/common/constants/userRole';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            paddingTop: commonConsts.ui.headerHeight,
        },
        content: {
            flexGrow: 1,
            padding: 0,
            height: '95vh',
            minHeight: '95vh',
            maxHeight: '95vh',
            overflowX: 'auto',
        },
        mainDiv: {
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing.unit * 3,
            },
            [theme.breakpoints.down('md')]: {
                padding: 12,
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    theme: Theme;
}

export const Component: React.SFC<Props> = (props) => {
    const [onMenu, toggleOpen] = React.useState(false);
    const [title, titleExchange] = React.useState('');
    const [pageDescription, setPageDescription] = React.useState<string | undefined>();
    const [back, backExchange] = React.useState('');
    const [backTitle, backTitleExchange] = React.useState('');
    const [fabWidth, setFabWidth] = React.useState<number | undefined>(undefined);
    const appContainer = UserAppContainer.useContainer();

    const onTitle = (title?: string) => {
        titleExchange(title || document.title);
        setPageDescription(undefined);
    };

    const onPageDescriptioin = (desc?: string) => {
        setPageDescription(desc);
    };

    const onBackButton = (path: string, pathTitle?: string) => {
        backExchange(path);
        if (pathTitle) {
            backTitleExchange(pathTitle);
        }
    };

    const onMenuOpen = () => {
        toggleOpen(true);
    };

    const onMenuClose = () => {
        toggleOpen(false);
    };
    const onCropAndDropCallback = () => {
        appContainer.setValues({
            ...appContainer.values,
            cropAndDropProps: {
                ...appContainer.values.cropAndDropProps,
                visibility: 'hidden',
            },
        });
    };

    const onSetFabWidth = (width: number) => {
        setFabWidth(width);
    };

    // Routes after signed in.
    return (
        <div className="App">
            {appContainer.values.cropAndDropProps.visibility && appContainer.values.cropAndDropProps.visibility === 'show' ? (
                <CovasDropAndCrop {...appContainer.values.cropAndDropProps} onClose={onCropAndDropCallback} visibility={'show'} dependAppContainer={UserAppContainer.useContainer} />
            ) : (
                <></>
            )}
            <div style={{ display: appContainer.values.cropAndDropProps.visibility && appContainer.values.cropAndDropProps.visibility === 'show' ? 'none' : '' }}>
                {
                    // urlがdashboardにmatchしたらdashboard用のheaderを出す
                }
                <Switch>
                    <Route
                        path={[
                            routes.sharedEndpoints.filename.index,
                            routes.sharedEndpoints.scan.index,
                            routes.sharedEndpoints.scan.edit,
                            routes.sharedEndpoints.cloud,
                            routes.sharedCloudConnection.auth,
                            routes.sharedCloudConnection.docardAuth,
                            routes.endpoints.filename.index,
                            routes.endpoints.scan.index,
                            routes.endpoints.scan.edit,
                            routes.endpoints.cloud,
                            routes.cloudConnection.auth,
                            routes.cloudConnection.docardAuth,
                        ]}
                    >
                        <SubHeader title={title} pageDescription={pageDescription} />
                    </Route>
                    {
                        // urlがdashboard以外で画面幅が1280px以上だったらdashboard用のheaderを出す
                        // 1280px以下だったらsp用の汎用headerを出す
                    }
                    <>
                        <AgentSigninHeader />
                        <GenericHeader onMenuOpen={onMenuOpen} title={title} pageDescription={pageDescription} />
                    </>
                </Switch>
                <AgentContentWrapper>
                    <div className={props.classes.root}>
                        <Switch>
                            <Route exact path={routes.sharedEndpoints.scan.index}>
                                <components.sharedendpoints.scan.List onTitle={onTitle} onDesc={onPageDescriptioin} />
                            </Route>
                            {appContainer.values.signinWorkspaceUserObject.role < userRole.member && (
                                <Route path={routes.sharedEndpoints.cloud}>
                                    <components.sharedendpoints.editCloud.List onTitle={onTitle} />
                                </Route>
                            )}
                            <Route path={routes.sharedEndpoints.filename.index}>
                                <components.sharedendpoints.filename.List onTitle={onTitle} />
                            </Route>
                            <Route exact path={routes.endpoints.scan.index}>
                                <components.endpoints.scan.List onTitle={onTitle} onDesc={onPageDescriptioin} />
                            </Route>
                            <Route path={routes.endpoints.scan.edit}>
                                <components.endpoints.mfpSetting.List onTitle={onTitle} />
                            </Route>
                            <Route path={routes.endpoints.cloud}>
                                <components.endpoints.editCloud.List onTitle={onTitle} />
                            </Route>
                            <Route path={routes.endpoints.filename.index}>
                                <components.endpoints.filename.List onTitle={onTitle} />
                            </Route>
                            <Route path={routes.sharedCloudConnection.auth}>
                                <components.sharedCloudConnection.Docab.List />
                            </Route>
                            <Route path={routes.sharedCloudConnection.docardAuth}>
                                <components.sharedCloudConnection.Docard.List />
                            </Route>
                            <Route path={routes.cloudConnection.auth}>
                                <components.cloudConnection.Docab.List />
                            </Route>
                            <Route path={routes.cloudConnection.docardAuth}>
                                <components.cloudConnection.Docard.List />
                            </Route>
                            <Route>
                                <CommonMenu isAdminApp={false} isAdminUser={true} commonMenuOpen={onMenu} commonMenuCloseFunc={onMenuClose} />
                                <main id="contentRight" className={props.classes.content}>
                                    <div id="mainDiv" className={props.classes.mainDiv}>
                                        <CommonHeaderComponent
                                            isAdminApp={false}
                                            title={document.title}
                                            pageDescription={pageDescription}
                                            onTitle={onTitle}
                                            isBack={back}
                                            backTitle={backTitle}
                                            fabWidth={fabWidth}
                                        />
                                        <Switch>
                                            <Route path={routes.changePassword.index}>
                                                <components.changePassword.List onTitle={onTitle} />
                                            </Route>
                                            <Route exact path={routes.dashboard.index}>
                                                <components.dashboard.List onTitle={onTitle} />
                                            </Route>
                                            <Route exact path={routes.dashboard.spool}>
                                                <components.spool.List onTitle={onTitle} />
                                            </Route>
                                            <Route path={routes.userProfile.index}>
                                                <components.userProfile.List onTitle={onTitle} onDesc={onPageDescriptioin} />
                                            </Route>
                                            <Route path={routes.mfp.index}>
                                                <components.mfp.List onTitle={onTitle} appContainer={appContainer} />
                                            </Route>
                                            <Route exact path={routes.sharedEndpoints.index}>
                                                <components.sharedendpoints.List onTitle={onTitle} onDesc={onPageDescriptioin} onSetFabWidth={onSetFabWidth} />
                                            </Route>
                                            <Route exact path={routes.sharedEndpoints.edit}>
                                                <components.sharedendpoints.Edit onTitle={onTitle} onBack={onBackButton} />
                                            </Route>
                                            <Route exact path={routes.endpoints.index}>
                                                <components.endpoints.List onTitle={onTitle} onDesc={onPageDescriptioin} onSetFabWidth={onSetFabWidth} />
                                            </Route>
                                            <Route exact path={routes.endpoints.edit}>
                                                <components.endpoints.Edit onTitle={onTitle} onBack={onBackButton} />
                                            </Route>
                                            <Route path={routes.sharedCloudConnection.index}>
                                                <components.sharedCloudConnection.List onTitle={onTitle} onDesc={onPageDescriptioin} />
                                            </Route>
                                            <Route path={routes.cloudConnection.index}>
                                                <components.cloudConnection.List onTitle={onTitle} onDesc={onPageDescriptioin} />
                                            </Route>
                                        </Switch>
                                    </div>
                                </main>
                            </Route>
                        </Switch>
                    </div>
                </AgentContentWrapper>
            </div>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
