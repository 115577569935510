import { default as React, useState } from 'react';
import { Route, Switch } from 'react-router';
import '@/common/css/app.css';
import routes from '@/user/constants/routes';
import Private from '@/user/components/Private';
import PrivateRoute from '@/user/components/PrivateRoute';
import Public from '@/user/components/Public';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import CommonMessage, { Variants } from '@/common/components/messages/CommonMessage';
import Forbidden from '@/common/components/error/Forbidden';
import { useObserverUserContainer } from '@/common/utils/authManagement/agentSignIn';
import components from '@/user/components';
import { Redirect } from 'react-router';
import UnsubscribePage from '@/user/components/unsubscribe/Page';

export const Component: React.FC = () => {
    const inInvalidPath = useObserverUserContainer();
    const defaultMessData = {
        isOpen: false,
        message: '',
        variant: Variants.info,
        resultCode: undefined,
        autoHideDuration: undefined,
    };

    const [message, updateMessage] = useState(defaultMessData);
    const handleClose = () => {
        updateMessage(defaultMessData);
    };

    if (inInvalidPath) {
        return <Redirect to="/" />;
    }

    return (
        <UserAppContainer.Provider>
            <Switch>
                <Route path={routes.auth.index} component={Public} />
                <Route path={routes.forbidden.index}>
                    <Forbidden isAdminApp={false} />
                </Route>
                <Route path={routes.cloudConnection.authRequest} component={components.cloudConnection.auth.List} />
                <Route path={routes.unsubscribe.index} component={UnsubscribePage} />
                <PrivateRoute path={routes.dashboard.index} component={Private} />
            </Switch>
            <CommonMessage onClose={handleClose} isOpen={message.isOpen} variant={message.variant} message={message.message} dependAppContainer={UserAppContainer.useContainer} />
        </UserAppContainer.Provider>
    );
};
export default Component;
