import { default as React, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router';
import { Theme, Button, TextField, InputAdornment, IconButton, Typography, createStyles, withStyles, WithStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import * as model from '@/user/models/auth/recover';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import { RecoverContainer } from './RecoverContainer';

import locale from '@/common/utils/locale';
import logger from '@/common/utils/logger';
import * as errorHandler from '@/common/utils/errorHandler';
import routes from '@/user/constants/routes';
import { Variants } from '@/common/components/messages/CommonMessage';
import Error from '@/common/components/state/Error';
import Loading from '@/common/components/state/Loading';
import Saving from '@/common/components/state/Saving';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import CovasAvatar from '@/common/components/CovasAvatar';

const styles = (theme: Theme) =>
    createStyles({
        heading: {
            margin: `0 auto ${theme.spacing.unit * 3}px`,
            wordWrap: 'break-word',
        },
        workspaceAvatar: {
            margin: `0 auto ${theme.spacing.unit * 3}px`,
        },
        mb20: {
            marginBottom: 20,
        },
        button: {
            '&& span': {
                fontSize: '1.25rem',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('lg')]: {
                width: 'auto',
            },
        },
    });

interface Props extends RouteComponentProps, WithStyles<typeof styles> {
    ui?: UI;
    skipEffect?: boolean;
}

export const Component: React.FC<Props> = (props) => {
    const appContainer = UserAppContainer.useContainer();
    const recoverContainer = RecoverContainer.useContainer();
    const ui = useUI(props.ui);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        const getUrlParams = () => {
            if (!props.location.search) return new URLSearchParams();
            return new URLSearchParams(props.location.search);
        };

        if (props.skipEffect) {
            return;
        }
        if (ui.current !== UI.Loading) {
            logger.debug(`useEffect skip. UI is not 'Loading'`);
            return;
        }

        (async () => {
            try {
                const search = getUrlParams();
                const token = search.get('token') || '';
                const result = await model.passwordResetVerify({ token });

                if (!result.success) {
                    ui.update(UI.Error);
                    return;
                }

                recoverContainer.values.signinWorkspaceObject = result.workspace;
                recoverContainer.values.signinWorkspaceUserObject = result.user;
                recoverContainer.values.token = token;
                ui.update(UI.Loaded);
            } catch (e) {
                ui.update(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = () => (event: React.MouseEvent<HTMLInputElement>) => {
        logger.debug(`handleClick`);
        if (recoverContainer.values.errors !== null) {
            return false;
        }
        const workspace = recoverContainer.values.signinWorkspaceObject;
        if (!workspace) {
            return false;
        }
        const user = recoverContainer.values.signinWorkspaceUserObject;
        if (!user) {
            return false;
        }
        (async () => {
            try {
                const result = await recoverContainer.savePassword();
                logger.debug(`savePassword success`);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.saved),
                    variant: Variants.success,
                });
                appContainer.onSignin(appContainer, result.id, result.workspace, result.user);
                setLoggedIn(true);
            } catch (e) {
                ui.update(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        return;
    };
    return (
        <>
            {loggedIn ? (
                <Redirect to={routes.dashboard.index} />
            ) : (
                <>
                    {ui.current === UI.Loading && (
                        <div data-testid={UI.Loading}>
                            <Loading />
                        </div>
                    )}

                    {ui.current === UI.Loaded && (
                        <div data-testid={UI.Loaded}>
                            <CovasAvatar
                                alt="Workspace"
                                size={160}
                                seed={recoverContainer.values.signinWorkspaceObject ? recoverContainer.values.signinWorkspaceObject.id : ''}
                                src={recoverContainer.values.signinWorkspaceObject ? recoverContainer.values.signinWorkspaceObject.logoUrl : ''}
                                className={props.classes.workspaceAvatar}
                            />
                            {/* ワークスペース “workspace.displayName(workspace.displayId)” にサインイン */}
                            <Typography className={props.classes.heading} variant="h3" color="inherit">
                                {locale.t(locale.keys.passwordRecovery.content, {
                                    workspaceName: recoverContainer.values.signinWorkspaceObject ? recoverContainer.values.signinWorkspaceObject.displayName : '',
                                    workspaceId: recoverContainer.values.signinWorkspaceObject ? recoverContainer.values.signinWorkspaceObject.displayId : '',
                                })}
                            </Typography>
                            <TextField
                                className={props.classes.mb20}
                                fullWidth
                                label={locale.t(locale.keys.common.email)}
                                defaultValue={recoverContainer.values.signinWorkspaceUserObject ? recoverContainer.values.signinWorkspaceUserObject.invitationEmail : ''}
                                margin="normal"
                                inputProps={{
                                    readOnly: true,
                                    style: { height: '100%' },
                                }}
                            />
                            <TextField
                                className={props.classes.mb20}
                                type={recoverContainer.values.showPassword ? 'text' : 'password'}
                                fullWidth
                                variant="filled"
                                label={locale.t(locale.keys.common.password)}
                                value={recoverContainer.form.password}
                                onChange={recoverContainer.handleChange('password')}
                                error={recoverContainer.isError('password')}
                                helperText={recoverContainer.getHelperText('password')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="Toggle password visibility" onClick={() => recoverContainer.handleClickShowPassword('showPassword')}>
                                                {recoverContainer.values.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    inputProps: { style: { height: '100%' } },
                                }}
                            />
                            <TextField
                                className={props.classes.mb20}
                                type={recoverContainer.values.showPasswordConfirm ? 'text' : 'password'}
                                fullWidth
                                variant="filled"
                                label={locale.t(locale.keys.common.passwordConfirm)}
                                value={recoverContainer.form.passwordConfirm}
                                onChange={recoverContainer.handleChange('passwordConfirm')}
                                error={recoverContainer.isError('passwordConfirm')}
                                helperText={recoverContainer.getHelperText('passwordConfirm')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="Toggle verify visibility" onClick={() => recoverContainer.handleClickShowPassword('showPasswordConfirm')}>
                                                {recoverContainer.values.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    inputProps: { style: { height: '100%' } },
                                }}
                            />

                            <Button className={props.classes.button} fullWidth variant="contained" disabled={recoverContainer.buttonDisabled()} onClick={handleClick()}>
                                {locale.t(locale.keys.passwordRecovery.change)}
                            </Button>
                        </div>
                    )}

                    {ui.current === UI.Saving && (
                        <div data-testid={UI.Saving}>
                            <Saving />
                        </div>
                    )}

                    {ui.current === UI.Error && (
                        <div data-testid={UI.Error}>
                            <Error />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default withRouter(withStyles(styles)(Component));
