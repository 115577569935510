import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import querystring from 'querystring';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const getMfps = async (workspaceId: string, auth: string, mfpNumber?: string): Promise<schema.V1WorkspaceMfpsIndexResponse> => {
    let query = '';
    if (mfpNumber) {
        query = querystring.stringify({
            mfpNumber,
        });
    }
    const response = await axiosFactory.get<schema.V1WorkspaceMfpsIndexResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/mfps?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
