import { default as React } from 'react';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import Select from './Select';

export const Component: React.FC = () => {
    useTitle(locale.t(locale.keys.selectWorkspace.index));
    return <Select />;
};

export default Component;
