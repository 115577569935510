import * as React from 'react';
import { createStyles, withStyles, WithStyles, AppBar } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { isAgent, resetAgentCookie } from '@/common/utils/authManagement/agentSignIn';
import locale from '@/common/utils/locale';
import { Route, Switch } from 'react-router';
import routes from '@/user/constants/routes';
import constants from '@/common/constants';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import * as errorHandler from '@/common/utils/errorHandler';

const AGENT_HEADER_HEIGHT = 24;

// ヘッダーのpositionは画面に対して絶対座標なので、それぞれに直接topプロパティでずらしを入れる必要がある
export const agentSigninHeaderHeight = () => {
    if (isAgent()) return AGENT_HEADER_HEIGHT;
    return 0;
};

const wrapperStyles = () =>
    createStyles({
        headerOffset: {
            paddingTop: AGENT_HEADER_HEIGHT,
        },
    });

interface AgentWrapperProps extends WithStyles<typeof wrapperStyles> {
    children: React.ReactNode;
}

// 代理ログイン用UI表示のためにずれたヘッダーの分だけ、メインコンテンツも下にずらす必要がある
export const AgentContentWrapper = withStyles(wrapperStyles, { withTheme: true })((props: AgentWrapperProps) => {
    return isAgent() ? (
        <Switch>
            <Route
                path={[
                    // routes.sharedEndpoints.extensions.index, 拡張機能は停止中
                    routes.sharedEndpoints.filename.index,
                    routes.sharedEndpoints.scan.index,
                    routes.sharedEndpoints.scan.edit,
                    routes.sharedEndpoints.cloud,
                    routes.sharedCloudConnection.auth,
                    routes.sharedCloudConnection.docardAuth,
                    // routes.endpoints.extensions.index, 拡張機能は停止中
                    routes.endpoints.filename.index,
                    routes.endpoints.scan.index,
                    routes.endpoints.scan.edit,
                    routes.endpoints.cloud,
                    routes.cloudConnection.auth,
                    routes.cloudConnection.docardAuth,
                ]}
            >
                <>{props.children}</>
            </Route>
            <div className={props.classes.headerOffset}>{props.children}</div>
        </Switch>
    ) : (
        <>{props.children}</>
    );
});

const styles = () =>
    createStyles({
        root: {
            backgroundColor: '#B22A00',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
            paddingInline: '20px',
            maxWidth: constants.ui.maxWidthScreen,
            width: '100%',
            height: AGENT_HEADER_HEIGHT,
            position: 'fixed',
        },
        icon: {
            marginInline: '4px',
        },
        text: {
            fontSize: 16,
            fontColor: 'white',
            fontWeight: 'normal',
        },
        button: {
            fontSize: 16,
            color: 'white',
            fontWeight: 'bold',
            textDecorationLine: 'underline',
            margin: 0,
            padding: 0,
            border: 'none',
            outline: 'none',
            background: 'transparent',
            cursor: 'pointer',
        },
    });

interface Props extends WithStyles<typeof styles> {}

const AgentSigninHeader: React.FC<Props> = (props) => {
    const appContainer = UserAppContainer.useContainer();
    const returnAgent = () => {
        try {
            resetAgentCookie();
        } catch (e) {
            // Cookieを手動で削除しない限り発生しない
            errorHandler.forceSignout(appContainer);
        }
    };

    return isAgent() ? (
        <AppBar position="static" className={props.classes.root}>
            <Info className={props.classes.icon} />
            <span>{locale.t(locale.keys.agentSignInHeader.info)}</span>
            <button className={props.classes.button} onClick={() => returnAgent()}>
                {locale.t(locale.keys.agentSignInHeader.link)}
            </button>
        </AppBar>
    ) : (
        <></>
    );
};

export default withStyles(styles, { withTheme: true })(AgentSigninHeader);
