import { default as React, useEffect, useState } from 'react';
import { WithStyles, Theme, createStyles, withStyles, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Radio } from '@material-ui/core';

import { KeyboardArrowRight, LastPage, LocalOffer, SupervisorAccount } from '@material-ui/icons';
import locale from '@/common/utils/locale';
import * as xStorages from '@/user/models/x-storages/files/files';
import { default as UI } from '@/common/constants/ui';
import * as schema from '@/bundles/schema/typescript/schema';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import { isLoaded, isLoading, isError } from '@/common/components/hooks/useUI';
import LoadingState from '@/common/components/ui-state/LoadingState';
import * as errorHandler from '@/common/utils/errorHandler';
import * as webappUtil from '@/common/utils/webappUtil';
import { join, sep } from 'path';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        header: {
            width: '100%',
        },
        pathContent: {
            marginTop: theme.spacing.unit * 1,
            marginBottom: theme.spacing.unit * 1,
            display: 'flex',
            overflowX: 'auto',
        },
        path: {
            display: 'flex',
            cursor: 'pointer',
            '&& span': {
                marginRight: theme.spacing.unit,
                color: 'orange',
            },
            '&& svg': {
                marginRight: theme.spacing.unit,
            },
        },
        body: {
            paddingTop: 0,
            width: '100%',
            maxHeight: theme.spacing.unit * 25,
            overflow: 'auto',
        },
        content: {
            paddingLeft: 0,
            '&& div': {
                marginRight: 0,
            },
            width: '100%',
            listStyleType: 'circle',
        },
        folderIcon: {
            width: theme.spacing.unit * 2.625,
            height: theme.spacing.unit * 3.125,
            marginRight: theme.spacing.unit,
            '&& span': {
                fontSize: '0.9375rem',
                color: '#0d47a1',
            },
        },
        radioChecked: {
            '&& span': {
                padding: 0,
                marginRight: theme.spacing.unit,
            },
        },
        cloudName: {
            fontFamily: 'HiraginoSans-W6',
            fontSize: '1rem',
            color: '#333333',
            marginTop: theme.spacing.unit * 3,
            '&& p': {
                fontFamily: 'HiraginoSans-W3',
                fontSize: '0.8125rem',
                color: '#666666',
                marginTop: theme.spacing.unit * 0.15,
            },
        },
        nextPage: {
            marginLeft: 'auto',
            marginTop: theme.spacing.unit * 2,
        },
        load: {
            width: '100%',
            textAlign: 'center',
        },
        imgStyle: {
            width: '30px',
            height: '30px',
            border: 'solid 1px black',
            borderRadius: '50%',
        },
        imgStyleSelected: {
            width: '30px',
            height: '30px',
            backgroundColor: 'orange',
            border: 'solid 1px black',
            borderRadius: '50%',
        },
        folderStyle: {
            float: 'left',
        },
        pathFolder: {
            overflow: 'hidden',
            whiteSpace: 'pre' /**空白は詰めない */,
            textOverflow: 'ellipsis',
            maxWidth: 70,
        },
    });

interface Props extends WithStyles<typeof styles> {
    id: string;
    endpointType?: string;
    cloudAcc?: string;
    path?: string;
    pathSelect: (pth: string, pthId?: string) => void;
}

interface IFurigana {
    id: string;
    name: string;
}

export const Component: React.SFC<Props> = (props: Props) => {
    const { classes } = props;
    const katakana = {
        ア行: ['ア', 'イ', 'ウ', 'エ', 'オ'],
        カ行: ['カ', 'キ', 'ク', 'ケ', 'コ'],
        サ行: ['サ', 'シ', 'ス', 'セ', 'ソ'],
        タ行: ['タ', 'チ', 'ツ', 'テ', 'ト'],
        ナ行: ['ナ', 'ニ', 'ヌ', 'ネ', 'ノ'],
        ハ行: ['ハ', 'ヒ', 'フ', 'ヘ', 'ホ'],
        マ行: ['マ', 'ミ', 'ム', 'メ', 'モ'],
        ヤ行: ['ヤ', 'ユ', 'ヨ'],
        ラ行: ['ラ', 'リ', 'ル', 'レ', 'ロ'],
        ワ行: ['ワ', 'ヲ', 'ン'],
        'A-Z': ['A-Z'],
        other: [locale.t(locale.keys.endpoint.businessCard.other)],
        all: [locale.t(locale.keys.endpoint.businessCard.all)],
    };
    const [isSelected, setIsSelected] = useState('');
    const appContainer = UserAppContainer.useContainer();
    const list = [];
    const listFol = [];
    const [cursorState, setCursorState] = useState();
    const [listFolEmpty, setListFolEmpty] = useState(false);
    const [ui, setUI] = useState(UI.state.Loading);
    const [pathFurigana, setPathFurigana] = useState<IFurigana[]>([{ id: '', name: '/' }]);
    const [katakanaList, setKatakanaList] = useState<schema.V1ObjectsXstorageFile[]>([]);
    const [azOtherAll, setAzOtherAll] = useState(false); // [A-z, other, all]
    const [userIcon, setUserIcon] = useState(false);
    const [isEdit, setIsEdit] = React.useState(false);

    useEffect(() => {
        if (!props.path || props.path === '/' || typeof props.path === 'undefined') {
            getKatakana('');
            setUI(UI.state.Loaded);
            return;
        }
        const dataArr = props.path.split(sep).filter((item) => item);
        if (dataArr[0] !== '/') {
            dataArr.unshift('/');
        }
        const listPathFurigana: IFurigana[] = [];
        for (const i in dataArr) {
            listPathFurigana.push({ id: '', name: dataArr[i] });
        }
        setPathFurigana(listPathFurigana);
        void (async () => {
            await getUserList('');
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        appContainer.updateLoadingState(ui);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        if (!isEdit) {
            return;
        }
        let name = '/';
        if (userIcon && pathFurigana[pathFurigana.length - 1].id) {
            name = join('/', pathFurigana[pathFurigana.length - 1].name, '/');
        }
        if (typeof props.pathSelect === 'function') {
            props.pathSelect(name, pathFurigana[pathFurigana.length - 1].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathFurigana]);

    const getUserList = async (value: string, cursor?: string) => {
        setUI(UI.state.Loading);
        try {
            let filter = value;
            if (value === katakana['A-Z'][0]) {
                filter = 'A-Za-z';
            }
            if (value === katakana.other[0]) {
                filter = '';
            }
            if (value === katakana.all[0]) {
                filter = '';
            }
            let isEmpty = false;
            const dataRes = await xStorages.getListFolder(props.id, '/', appContainer.values.authorizationCode, cursor, filter);
            if (dataRes.xStorageFiles.length === 0) {
                isEmpty = true;
            }
            setUserIcon(true);
            setListFolEmpty(isEmpty);
            setKatakanaList(dataRes.xStorageFiles);
            setCursorState(dataRes.cursor);
            setUI(UI.state.Loaded);
        } catch (e) {
            setUI(UI.state.Error);
            appContainer.setLoadingState(false);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const getKatakana = (key: string) => {
        setUserIcon(false);
        setListFolEmpty(false);
        if (!key) {
            const keyArray = Object.keys(katakana);
            setPathFurigana([{ id: '', name: '/' }]);
            const list: schema.V1ObjectsXstorageFile[] = [];
            for (const i in keyArray) {
                const itemPust: schema.V1ObjectsXstorageFile = {
                    id: '',
                    name: keyArray[i],
                    path: '/',
                    writable: true,
                };
                list.push(itemPust);
            }
            setKatakanaList(list);
            return;
        }
        const valueArr: string[] = webappUtil.getEnumData(key, katakana);
        const list: schema.V1ObjectsXstorageFile[] = [];
        for (const i in valueArr) {
            const itemPust: schema.V1ObjectsXstorageFile = {
                id: '',
                name: valueArr[i],
                path: '/',
                writable: true,
            };
            list.push(itemPust);
        }
        setKatakanaList(list);
    };

    const selectOnClick = async (name: string, i: string, id: string) => {
        setIsEdit(true);
        const arrName = popName();
        let value = name;
        if (['other', 'all'].includes(name)) {
            value = webappUtil.getEnumData(name, katakana)[0];
        }
        arrName.push({ id, name: value });
        const arrFilter = arrName.filter((item) => item);
        setPathFurigana(arrFilter);
        if (userIcon) {
            return setIsSelected(i);
        }

        const isAzOtherAll = checkAzOtherAll(arrFilter);
        if (arrFilter.length === 2 && isAzOtherAll) {
            return await getUserList(value);
        }
        if (arrFilter.length === 3 && !isAzOtherAll) {
            return await getUserList(value);
        }
        if (arrFilter.length <= 2 && !isAzOtherAll) {
            return getKatakana(value);
        }
        setIsSelected(i);
    };

    const handleSelectClick = async (name: string, i: string, id: string) => {
        setIsEdit(true);
        const arrName = popName();
        let value = name;
        if (['other', 'all'].includes(name)) {
            value = webappUtil.getEnumData(name, katakana)[0];
        }
        arrName.push({ id, name: value });
        const arrFilter = arrName.filter((item) => item);
        setPathFurigana(arrFilter);
        if (userIcon) {
            return setIsSelected(i);
        }
        if (arrFilter && arrFilter.length <= 3) {
            return await selectOnClick(value, i, id);
        }
    };

    const linkOnClick = async (index: string) => {
        if (isLoading(ui)) {
            return;
        }
        const pathArr = [...pathFurigana];
        if (Number(index) === pathArr.length - 1) {
            return;
        }
        setIsEdit(true);
        setIsSelected('-1');
        if (index === '0') {
            return getKatakana('');
        }
        const i = Number(index) + 1;
        pathArr.splice(i, pathArr.length - i).filter((item) => item);
        setPathFurigana(pathArr);
        const isAzOtherAll = checkAzOtherAll(pathArr);
        if (index === '1' && !isAzOtherAll) {
            const key = pathArr[1].name;
            return getKatakana(key);
        }
        await getUserList(pathArr[pathArr.length - 1].name);
    };

    const nextOnClick = async () => {
        await getUserList(pathFurigana[pathFurigana.length - 1].name, cursorState);
    };

    const popName = () => {
        const arrName = [...pathFurigana];
        if (arrName.length === 4 || (azOtherAll && arrName.length === 3) || (!azOtherAll && userIcon && arrName.length === 2)) {
            arrName.pop();
        }
        return arrName;
    };

    const checkAzOtherAll = (pathFuri: IFurigana[]) => {
        let isAzOtherAll = false;
        if (pathFuri.length >= 2 && ['A-Z', locale.t(locale.keys.endpoint.businessCard.other), locale.t(locale.keys.endpoint.businessCard.all)].includes(pathFuri[1].name)) {
            isAzOtherAll = true;
        }
        setAzOtherAll(isAzOtherAll);
        return isAzOtherAll;
    };

    const getIcon = (usericon: boolean) => {
        if (usericon) {
            return <SupervisorAccount className={props.classes.folderStyle} />;
        }
        return <LocalOffer className={props.classes.folderStyle} />;
    };

    for (const i in katakanaList) {
        const data = katakanaList[i];
        listFol.push(
            <ListItem key={i} button className={classes.content} onKeyDown={(event) => handleSelectPressKey(event, data.name, i, data.id)}>
                <ListItemIcon className={classes.radioChecked} tabIndex={-1}>
                    <Radio checked={isSelected === i} value={data.name} name={i} tabIndex={-1} onChange={() => handleSelectClick(data.name, i, data.id)} />
                </ListItemIcon>
                <ListItemIcon onClick={() => selectOnClick(data.name, i, data.id)}>{getIcon(userIcon)}</ListItemIcon>
                <ListItemText onClick={() => selectOnClick(data.name, i, data.id)} primary={['other', 'all'].includes(data.name) ? webappUtil.getEnumData(data.name, katakana)[0] : data.name} />
            </ListItem>,
        );
    }

    const handleSelectPressKey = (event: any, name: string, index: string, id: string) => {
        if (event.keyCode === 13 || event.keyCode === 32) {
            handleSelectClick(name, index, id);
            return;
        }
    };

    const handleSelectPress = (event: any, index: string, isName: boolean) => {
        if ((event.keyCode === 13 || event.keyCode === 32) && !isName) {
            linkOnClick(index);
            setIsSelected('-1');
            return;
        }
        if ((event.keyCode === 13 || event.keyCode === 32) && isName) {
            linkOnClick(index);
            return;
        }
    };

    for (const i in pathFurigana) {
        list.push(
            <Link className={classes.path} key={i} onClick={() => linkOnClick(i)}>
                {i === '0' ? (
                    <div tabIndex={0} onKeyDown={(event: any) => handleSelectPress(event, i, false)}>
                        {pathFurigana[i].name}
                    </div>
                ) : (
                    <>
                        <KeyboardArrowRight />
                        <span className={classes.pathFolder} tabIndex={0} onKeyDown={(event: any) => handleSelectPress(event, i, true)}>
                            {pathFurigana[i].name}
                        </span>
                    </>
                )}
            </Link>,
        );
    }

    return (
        <div className={classes.root}>
            <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                <div className={classes.header}>
                    <div className={classes.cloudName}>
                        <p>{locale.t(locale.keys.endpoint.cloudConnection)}</p>
                        {webappUtil.getServiceText(props.endpointType)}
                        <p>{props.endpointType !== schema.V1ObjectsServiceEnum.Nonecloud && props.cloudAcc}</p>
                    </div>
                    <div id="pathId" className={classes.pathContent}>
                        {list}
                    </div>
                </div>
                {isLoading(ui) && <div className={classes.load} data-testid={UI.state.Loading} /> && <LoadingState isModal={true} />}

                {isLoaded(ui) && (
                    <div className={classes.load} data-testid={UI.state.Loaded}>
                        <List className={classes.body} tabIndex={-1}>
                            {listFolEmpty ? (
                                <Grid container justify="center" alignItems="flex-start">
                                    {locale.t(locale.keys.endpoint.empty)}
                                </Grid>
                            ) : (
                                <></>
                            )}
                            {listFol}
                        </List>
                        {cursorState === '' || cursorState === undefined ? (
                            <></>
                        ) : (
                            <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                                <LastPage className={classes.nextPage} onClick={nextOnClick} />
                            </Grid>
                        )}
                    </div>
                )}

                {isError(ui) && <div className={classes.load} data-testid={UI.state.Error} />}
            </Grid>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
