import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import NameRule from './NameRule';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        cloudName: {
            fontSize: '1rem',
            color: '#333333',
            marginTop: theme.spacing.unit * 2,
            '&& p': {
                fontSize: '0.8125rem',
                color: '#666666',
                marginTop: theme.spacing.unit * 0.15,
                marginBottom: '4px',
            },
        },
        folderSelect: {
            marginTop: theme.spacing.unit * 2,
            width: '100%',
        },
    });

export interface Props extends WithStyles<typeof styles> {
    storages?: string;
    theme: Theme;
    isShared: boolean;
}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;

    return (
        <>
            <Grid className={classes.root}>
                <NameRule isShared={props.isShared} />
            </Grid>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
