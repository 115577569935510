import { default as React, useEffect, useState } from 'react';
import { createStyles, Theme, WithStyles, withStyles, AppBar, Toolbar, Button, Typography, IconButton, Stepper, Step, StepContent, StepLabel, Grid, Avatar } from '@material-ui/core';
import { CloseOutlined, Folder, SupervisorAccount } from '@material-ui/icons';
import StepOne from './create-endpoint/StepOne';
import StepTwo from './create-endpoint/StepTwo';
import { NewEndpointContainer } from './create-endpoint/NewEndpointContainer';
import StepThree from './create-endpoint/StepThree';
import StepFour from './create-endpoint/StepFour';
import * as locale from '@/common/utils/locale/locale';
import { default as imgs } from '@/common/img/mfp/index';
import * as schema from '@/bundles/schema/typescript/schema';
import { default as UI } from '@/common/constants/ui';
import LoadingState from '@/common/components/ui-state/LoadingState';
import { isLoaded, isLoading, isError, isSaving } from '@/common/components/hooks/useUI';
import * as webappUtil from '@/common/utils/webappUtil';
import DialogMessage from '@/common/components/messages/DialogMessage';
import StepFive from './create-endpoint/StepFive/StepFive';
import DialogBase, { DialogBaseProps } from '@/common/components/Confirm/DialogBase';
import { MultiLineText } from '@/common/components/messages/MultiLineText';
import ErrorBoundary from '@/common/utils/ErrorBoundary';
import { UserAppContainer } from '../UserAppContainer';
import environment from '@/common/constants/environment';

const selectColor = (tenant: string) => {
    switch (tenant) {
        case "ricoh":
            return '#E06666';
        default:
            return '#607d8b';
    }
}


const styles = (theme: Theme) =>
    createStyles({
        header: {
            width: '100%',
            position: 'absolute',
            zIndex: 100,
            backgroundColor: selectColor(environment.tenant),
        },
        heading: {
            margin: '0 auto',
            fontSize: theme.spacing.unit * 2,
            fontWeight: 'bold',
        },
        root: {
            alignItems: 'center',
            marginTop: '52px',
        },
        button: {
            marginTop: theme.spacing.unit,
            marginRight: theme.spacing.unit,
        },
        actionsContainer: {
            marginBottom: theme.spacing.unit * 2,
        },
        rootStepContainer: {
            margin: theme.spacing.unit * 2,
            padding: 0,
        },
        active: {
            color: `${theme.palette.secondary.main} !important`,
        },
        completed: {
            color: `${theme.palette.secondary.main} !important`,
        },
        endpointContainer: {
            display: 'flex',
            height: theme.spacing.unit * 9,
            marginRight: theme.spacing.unit * 2,
            marginBottom: theme.spacing.unit,
            cursor: 'pointer',
            textAlign: 'left',
        },
        imgBackground: {
            margin: 10,
            color: '#fff',
            borderRadius: 0,
            width: theme.spacing.unit * 7,
            height: theme.spacing.unit * 7,
            border: 'solid 1px #dddddd',
        },
        imgStyle: {
            width: 'inherit',
            height: 'inherit',
            padding: theme.spacing.unit * 1.5,
        },
        labelStyle: {
            alignItems: 'start',
        },
        labelStep3: {
            float: 'left',
            marginRight: theme.spacing.unit,
            marginLeft: theme.spacing.unit * 3,
            marginBottom: '-11px',
        },
        borderStyle: {
            marginLeft: -theme.spacing.unit * 2.5,
            borderLeft: 'solid 1px #bdbdbd',
            marginBottom: '-22px',
        },
        borderStyleContainer: {
            marginLeft: -theme.spacing.unit * 2.5,
            borderLeft: 'solid 1px #bdbdbd',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
            paddingLeft: '14px',
            marginTop: '10px',
            marginBottom: '-12px',
        },
        endpointLabel: {
            fontSize: theme.spacing.unit * 2,
            paddingLeft: theme.spacing.unit * 2,
            color: 'black',
        },
        pStyle: {
            marginLeft: theme.spacing.unit * 3,
        },
        stepStyle: {
            borderStyle: 'none',
        },
        container: {
            overflow: 'auto',
            maxHeight: '100%',
        },
    });

const initialDialogObject: DialogBaseProps = {
    callBack: () => {},
    onClose: () => {},
    isOpen: false,
    title: '',
    renderChildren: () => <></>,
    type: 'alert',
};

interface Props extends WithStyles<typeof styles> {
    skipEffect?: boolean;
    onClose: () => void;
    onCreateEndpoint: () => void;
    isShared: boolean;
}

function getSteps(isShared: boolean) {
    return [
        locale.t(locale.keys.createEnpoint.stepOneLabel),
        locale.t(locale.keys.createEnpoint.stepTwoLabel),
        locale.t(locale.keys.createEnpoint.stepThreeLabel),
        locale.t(locale.keys.createEnpoint.stepFourLabel),
        isShared ? locale.t(locale.keys.createSharedEndpoint.stepFiveLabel) : locale.t(locale.keys.createEnpoint.stepFiveLabel),
    ];
}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const [activeStep, setActiveStep] = useState(0);
    const endpointState = NewEndpointContainer.useContainer();
    const [steps, setStep] = useState(getSteps(props.isShared));
    const name = endpointState.endpointIcon ? endpointState.endpointIcon.split('.')[0] : '';
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [dialogObject, setDialogObject] = useState<DialogBaseProps>(initialDialogObject);
    const appContainer = UserAppContainer.useContainer();

    const handleNext = () => {
        if (activeStep === 2 && endpointState.activeStorage.service === schema.V1ObjectsServiceEnum.Sharepointonline) {
            // サイトとライブラリが選択されていない時はメッセージ表示
            if (endpointState.m365ErrorMessage(activeStep)) {
                setIsOpen(true);
                return;
            }
        }
        if (
            activeStep === 4 ||
            (activeStep === 1 && endpointState && endpointState.activeStorage.service === schema.V1ObjectsServiceEnum.Nonecloud) ||
            (activeStep === 2 && endpointState && endpointState.activeStorage.service === schema.V1ObjectsServiceEnum.Docard)
        ) {
            void (async () => {
                await endpointState.handleCreate();
                props.onClose();
                props.onCreateEndpoint();
            })();
        }
        if (activeStep === 1 && endpointState && schema.V1ObjectsServiceEnum.Nonecloud === endpointState.activeStorage.service) {
            return;
        }
        if (activeStep === 2 && endpointState && schema.V1ObjectsServiceEnum.Docard === endpointState.activeStorage.service) {
            return;
        }
        if (endpointState.tmpPathName !== endpointState.pathName && endpointState.tmpPathName !== '') {
            endpointState.setTmpPathName(endpointState.pathName);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (endpointState.tmpPathName !== endpointState.pathName) {
            endpointState.setTmpPathName(endpointState.pathName);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                endpointState.path = '';
                return <StepOne isShared={props.isShared} />;
            case 1:
                return <StepTwo isShared={props.isShared} />;
            case 2:
                return <StepThree />;
            case 3:
                return <StepFour isShared={props.isShared} />;
            case 4:
                return <StepFive isShared={props.isShared} />;
            default:
                return 'Unknown step';
        }
    };

    const backgroundColor = endpointState.endpointBtnColor ? { backgroundColor: webappUtil.getEnumKey(endpointState.endpointBtnColor, schema.V1ObjectsEndpointsButtonColor) } : { backgroundColor: '' };

    useEffect(() => {
        if (props.skipEffect) {
            endpointState.setUI(UI.state.Loaded);
            return;
        }
        void (async () => {
            await endpointState.loadInitialData();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!endpointState.activeStorage) {
            return;
        }
        if (schema.V1ObjectsServiceEnum.Nonecloud === endpointState.activeStorage.service) {
            const step = steps.splice(0, 2);
            setStep(step);
            return;
        }
        if (schema.V1ObjectsServiceEnum.Docard === endpointState.activeStorage.service) {
            const step = steps.splice(0, 3);
            setStep(step);
            return;
        }
        if (steps.length !== 4) {
            setStep(getSteps(props.isShared));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endpointState.activeStorage]);

    const getIconWithXStorage = (service: schema.V1ObjectsServiceEnum, path: string) => {
        if (schema.V1ObjectsServiceEnum.Docard === service) {
            const name = path.split('/').filter((item) => item);
            return (
                <>
                    <SupervisorAccount className={props.classes.labelStep3} /> <p>{name}</p>
                </>
            );
        }
        return (
            <>
                <Folder className={props.classes.labelStep3} /> <p>{path}</p>
            </>
        );
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const shouldConfirmCreate = () => {
        if (activeStep !== steps.length - 1) return false;
        if (endpointState.endpoints.enablePermission.mfpPermission && endpointState.endpoints.selectedMfpPermission.length === 0) return true;
        if (endpointState.endpoints.enablePermission.applicationPermission && !endpointState.endpoints.applicationPermission.fax && !endpointState.endpoints.applicationPermission.scan) return true;

        return false;
    };

    return (
        <div className={classes.container}>
            <AppBar position="static" className={classes.header}>
                <Toolbar variant="dense">
                    <Typography color="inherit" className={classes.heading}>
                        {props.isShared ? locale.t(locale.keys.createSharedEndpoint.title) : locale.t(locale.keys.createEnpoint.title)}
                    </Typography>
                    <IconButton color="inherit" aria-label="Close" onClick={props.onClose} style={{ position: 'absolute', right: 0 }}>
                        <CloseOutlined />
                    </IconButton>
                </Toolbar>
                {isLoading(endpointState.ui) || isSaving(endpointState.ui) ? <LoadingState isModal={true} /> : <></>}
            </AppBar>
            {activeStep === 2 && <DialogMessage open={isOpen} handleClose={handleClose} message={endpointState.m365ErrorMessage(activeStep)} />}
            {isLoading(endpointState.ui) && <div data-testid={UI.state.Loading} />}
            {isError(endpointState.ui) && <div data-testid={UI.state.Error} />}
            {isLoaded(endpointState.ui) && (
                <ErrorBoundary container={appContainer}>
                    <Grid container className={classes.root}>
                        <Grid item sm={3} />
                        <Grid item xs={12} sm={9}>
                            <Stepper activeStep={activeStep} orientation="vertical" className={classes.rootStepContainer}>
                                {steps.map((label, index) => (
                                    <Step key={label}>
                                        <StepLabel
                                            className={props.classes.labelStyle}
                                            StepIconProps={{
                                                classes: {
                                                    active: classes.active,
                                                    completed: classes.completed,
                                                },
                                            }}
                                        >
                                            {label}
                                            {index === 0 && activeStep > 0 && (
                                                <div className={props.classes.borderStyleContainer}>
                                                    <Avatar className={classes.imgBackground} style={backgroundColor}>
                                                        <img alt="img" src={imgs.iconMfps[name]} className={classes.imgStyle} />
                                                    </Avatar>
                                                    <div className={classes.endpointLabel}>{endpointState.endpointName}</div>
                                                </div>
                                            )}
                                            {index === 1 && activeStep > 1 && (
                                                <div className={props.classes.borderStyle}>
                                                    <div className={props.classes.pStyle}>
                                                        <p>{webappUtil.getServiceText(endpointState.activeStorage.service)}</p>
                                                        <p>
                                                            {![schema.V1ObjectsServiceEnum.Nonecloud, schema.V1ObjectsServiceEnum.Email].includes(endpointState.activeStorage.service) &&
                                                                endpointState.activeStorage.authorizationId}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                            {index === 2 && activeStep > 2 && (
                                                <div className={props.classes.borderStyle}>{getIconWithXStorage(endpointState.activeStorage.service, endpointState.pathName)}</div>
                                            )}
                                            {index === 3 && activeStep > 3 && (
                                                <div className={props.classes.borderStyle}>
                                                    <div className={props.classes.pStyle}>
                                                        <p>{locale.t(locale.keys.fileName.example.index) + endpointState.getFileNameExample()}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </StepLabel>
                                        <StepContent style={{ paddingRight: 0 }}>
                                            <div>{getStepContent(index)}</div>
                                            <div className={classes.actionsContainer}>
                                                <div>
                                                    {activeStep !== 0 && (
                                                        <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                                            {locale.t(locale.keys.action.back)}
                                                        </Button>
                                                    )}
                                                    {shouldConfirmCreate() ? (
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => {
                                                                setDialogObject({
                                                                    callBack: () => {
                                                                        handleNext();
                                                                        setDialogObject(initialDialogObject);
                                                                    },
                                                                    onClose: () => setDialogObject(initialDialogObject),
                                                                    isOpen: true,
                                                                    title: locale.t(locale.keys.endpoint.confirmSave.title),
                                                                    type: 'confirm',
                                                                    renderChildren: () => {
                                                                        let desc = [] as string[];
                                                                        let list = [] as string[];

                                                                        if (
                                                                            endpointState.endpoints.enablePermission.applicationPermission &&
                                                                            !endpointState.endpoints.applicationPermission.fax &&
                                                                            !endpointState.endpoints.applicationPermission.scan
                                                                        ) {
                                                                            desc.push(locale.t(locale.keys.endpoint.confirmSave.endpointPermission.desc.application));
                                                                            list.push(locale.t(locale.keys.endpoint.confirmSave.endpointPermission.applicationPermissionList));
                                                                        }

                                                                        if (endpointState.endpoints.enablePermission.mfpPermission && endpointState.endpoints.selectedMfpPermission.length === 0) {
                                                                            desc.push(locale.t(locale.keys.endpoint.confirmSave.endpointPermission.desc.mfp));
                                                                            list.push(locale.t(locale.keys.endpoint.confirmSave.endpointPermission.mfpPermissionList));
                                                                        }

                                                                        return (
                                                                            <>
                                                                                <MultiLineText
                                                                                    value={locale.t(locale.keys.endpoint.confirmSave.endpointPermission.desc.base, {
                                                                                        endpoint: props.isShared
                                                                                            ? locale.t(locale.keys.common.model.endpoint.shared)
                                                                                            : locale.t(locale.keys.common.model.endpoint.individual),
                                                                                        list: desc.join(locale.t(locale.keys.endpoint.confirmSave.endpointPermission.desc.separator)),
                                                                                    })}
                                                                                />
                                                                                <ul style={{ margin: 0 }}>
                                                                                    {list.map((item, index) => {
                                                                                        return (
                                                                                            <li key={index} style={{ wordBreak: 'break-all' }}>
                                                                                                {item}
                                                                                            </li>
                                                                                        );
                                                                                    })}
                                                                                </ul>
                                                                            </>
                                                                        );
                                                                    },
                                                                });
                                                            }}
                                                            className={classes.button}
                                                            disabled={endpointState.isDisabled(activeStep)}
                                                        >
                                                            {activeStep === steps.length - 1 ||
                                                            (activeStep === 1 && endpointState && endpointState.activeStorage.service === schema.V1ObjectsServiceEnum.Nonecloud)
                                                                ? props.isShared
                                                                    ? locale.t(locale.keys.createSharedEndpoint.btnCreate)
                                                                    : locale.t(locale.keys.createEnpoint.btnCreate)
                                                                : locale.t(locale.keys.action.next)}
                                                        </Button>
                                                    ) : (
                                                        <Button variant="contained" onClick={handleNext} className={classes.button} disabled={endpointState.isDisabled(activeStep)}>
                                                            {activeStep === steps.length - 1 ||
                                                            (activeStep === 1 && endpointState && endpointState.activeStorage.service === schema.V1ObjectsServiceEnum.Nonecloud)
                                                                ? props.isShared
                                                                    ? locale.t(locale.keys.createSharedEndpoint.btnCreate)
                                                                    : locale.t(locale.keys.createEnpoint.btnCreate)
                                                                : locale.t(locale.keys.action.next)}
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                    </Grid>
                </ErrorBoundary>
            )}
            <DialogBase
                callBack={dialogObject.callBack}
                onClose={dialogObject.onClose}
                isOpen={dialogObject.isOpen}
                title={dialogObject.title}
                type={dialogObject.type}
                renderChildren={dialogObject.renderChildren}
            />
        </div>
    );
};

export default withStyles(styles)(Component);
