import React, { useEffect, useState } from 'react';
import { isError, isLoaded, isLoading } from '@/common/components/hooks/useUI';
import { default as UI } from '@/common/constants/ui';
import { Button, createStyles, Grid, Step, StepContent, StepLabel, Stepper, Theme, withStyles, WithStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserAppContainer } from '../../../UserAppContainer';
import { EditCloudContainer } from './EditCloudContainer';
import * as errorHandler from '@/common/utils/errorHandler';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import { Variants } from '@/common/components/messages/CommonMessage';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import * as webappUtil from '@/common/utils/webappUtil';
import { Folder, SupervisorAccount } from '@material-ui/icons';
import DialogMessage from '@/common/components/messages/DialogMessage';
import shareFormat from '@/common/constants/shareFormat';

const styles = (theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
        },
        root: {
            alignItems: 'start',
            marginTop: '52px',
        },
        heading: {
            float: 'left',
            fontSize: '14px',
            color: '#666666',
        },
        rootStepContainer: {
            textAlign: 'start',
            margin: theme.spacing.unit * 2,
            padding: 0,
        },
        borderStyle: {
            marginLeft: -theme.spacing.unit * 2.5,
            borderLeft: 'solid 1px #bdbdbd',
            marginBottom: '-22px',
        },
        pStyle: {
            marginLeft: theme.spacing.unit * 3,
        },
        actionsContainer: {
            marginBottom: theme.spacing.unit * 2,
        },
        button: {
            marginTop: theme.spacing.unit,
            marginRight: theme.spacing.unit,
        },
        labelStyle: {
            alignItems: 'start',
        },
        active: {
            color: `${theme.palette.secondary.main} !important`,
        },
        completed: {
            color: `${theme.palette.secondary.main} !important`,
        },
        labelStepFolder: {
            float: 'left',
            marginRight: theme.spacing.unit,
            marginLeft: theme.spacing.unit * 3,
            marginBottom: '-11px',
        },
    });

type TParams = {
    id: string;
    num: string;
};

interface Props extends WithStyles<typeof styles>, RouteComponentProps<TParams> {
    skipEffect?: boolean;
}
const getSteps = () => {
    // クラウド接続とフォルダ階層の選択
    return [locale.t(locale.keys.createEnpoint.stepTwoLabel), locale.t(locale.keys.createEnpoint.stepThreeLabel)];
};
const getShareFormat = (f: string) => {
    if (f === '0' || f === '1') {
        return Number(f);
    } else {
        return shareFormat.personal;
    }
};
const EditCloudComponet: React.FC<Props> = (props) => {
    const { classes } = props;
    // get id from url
    const id = props.match.params.id;
    const share = getShareFormat(props.match.params.num);
    const editCloudContainer = EditCloudContainer.useContainer();
    const appContainer = UserAppContainer.useContainer();
    const [activeStep, setActiveStep] = useState<number>(0);
    const [steps, setStep] = useState<string[]>(getSteps());
    const [isOpen, setIsOpen] = useState<boolean>(false);
    useEffect(() => {
        appContainer.updateLoadingState(editCloudContainer.ui);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editCloudContainer.ui]);

    useEffect(() => {
        if (props.skipEffect) {
            editCloudContainer.setUI(UI.state.Loaded);
            return;
        }
        void (async () => {
            try {
                editCloudContainer.setUI(UI.state.Loading);
                // エンドポイント取得
                await editCloudContainer.getEndpoint(id);
                // クラウド接続リスト取得
                await editCloudContainer.getStoragesList(share);
                editCloudContainer.setUI(UI.state.Loaded);
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!editCloudContainer.activeStorage) {
            return;
        }
        if (schema.V1ObjectsServiceEnum.Nonecloud === editCloudContainer.activeStorage.service) {
            const step = steps.splice(0, 2);
            setStep(step);
            return;
        }
        setStep(getSteps());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editCloudContainer.activeStorage]);
    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                editCloudContainer.path = '';
                return <StepTwo />;
            case 1:
                return <StepThree />;
            default:
                return 'Unknown step';
        }
    };
    const getIconWithXStorage = (service: schema.V1ObjectsServiceEnum, path: string) => {
        if (schema.V1ObjectsServiceEnum.Docard === service) {
            const name = path.split('/').filter((item) => item);
            return (
                <>
                    <SupervisorAccount className={props.classes.labelStepFolder} /> <p>{name}</p>
                </>
            );
        }
        return (
            <>
                <Folder className={props.classes.labelStepFolder} /> <p>{path}</p>
            </>
        );
    };
    const handleNext = () => {
        if (activeStep === 1 && editCloudContainer.activeStorage && editCloudContainer.activeStorage.service === schema.V1ObjectsServiceEnum.Sharepointonline) {
            // サイトとライブラリが選択されていない時はメッセージ表示
            if (editCloudContainer.m365ErrorMessage(activeStep)) {
                setIsOpen(true);
                return;
            }
        }
        if ((activeStep === 0 && editCloudContainer.activeStorage && editCloudContainer.activeStorage.service === schema.V1ObjectsServiceEnum.Nonecloud) || activeStep === 1) {
            void (async () => {
                try {
                    editCloudContainer.setUI(UI.state.Loading);
                    // クラウド接続保存
                    const result = await editCloudContainer.updateCloudSetting(id, share);

                    if (result) {
                        props.history.goBack();
                    } else {
                        appContainer.updateMessage({
                            autoHideDuration: 3000,
                            isOpen: true,
                            message: locale.t(locale.keys.error.updateCloudSetting),
                            variant: Variants.error,
                        });
                    }
                } catch (err) {
                    appContainer.updateMessage({
                        autoHideDuration: 3000,
                        isOpen: true,
                        message: locale.t(locale.keys.error.updateCloudSetting),
                        variant: Variants.error,
                    });
                } finally {
                    editCloudContainer.setUI(UI.state.Loaded);
                }
            })();
        }
        if (activeStep === 0 && editCloudContainer.activeStorage && schema.V1ObjectsServiceEnum.Nonecloud === editCloudContainer.activeStorage.service) {
            return;
        }
        if (activeStep === 1 && editCloudContainer.activeStorage && schema.V1ObjectsServiceEnum.Docard === editCloudContainer.activeStorage.service) {
            return;
        }
        if (editCloudContainer.tmpPathName !== editCloudContainer.pathName && editCloudContainer.tmpPathName !== '') {
            editCloudContainer.setTmpPathName(editCloudContainer.pathName);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        if (editCloudContainer.tmpPathName !== editCloudContainer.pathName) {
            editCloudContainer.setTmpPathName(editCloudContainer.pathName);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    return (
        <div className={classes.container}>
            {isLoading(editCloudContainer.ui) && <div data-testid={UI.state.Loading} />}
            {isError(editCloudContainer.ui) && <div data-testid={UI.state.Error} />}
            {isLoaded(editCloudContainer.ui) && (
                <Grid container className={classes.root}>
                    <Grid item sm={3} />
                    <Grid item xs={12} sm={9}>
                        <Stepper activeStep={activeStep} orientation="vertical" className={classes.rootStepContainer}>
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel
                                        className={props.classes.labelStyle}
                                        StepIconProps={{
                                            classes: {
                                                active: classes.active,
                                                completed: classes.completed,
                                            },
                                        }}
                                    >
                                        {label}
                                        {index === 0 && activeStep > 0 && (
                                            <div className={props.classes.borderStyle}>
                                                <div className={props.classes.pStyle}>
                                                    <p>{editCloudContainer.activeStorage && webappUtil.getServiceText(editCloudContainer.activeStorage.service)}</p>
                                                    <p>
                                                        {editCloudContainer.activeStorage &&
                                                            ![schema.V1ObjectsServiceEnum.Nonecloud, schema.V1ObjectsServiceEnum.Email].includes(editCloudContainer.activeStorage.service) &&
                                                            editCloudContainer.activeStorage.authorizationId}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        {index === 1 && activeStep > 1 && editCloudContainer.activeStorage && (
                                            <div className={props.classes.borderStyle}>{getIconWithXStorage(editCloudContainer.activeStorage.service, editCloudContainer.pathName)}</div>
                                        )}
                                    </StepLabel>
                                    <StepContent style={{ paddingRight: 0 }}>
                                        <div>{getStepContent(index)}</div>
                                        <div className={classes.actionsContainer}>
                                            <div>
                                                {activeStep !== 0 && (
                                                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                                        {locale.t(locale.keys.action.back)}
                                                    </Button>
                                                )}
                                                <Button variant="contained" onClick={handleNext} className={classes.button} disabled={editCloudContainer.isDisabled(activeStep)}>
                                                    {activeStep === steps.length - 1 ||
                                                    (activeStep === 0 && editCloudContainer.activeStorage && editCloudContainer.activeStorage.service === schema.V1ObjectsServiceEnum.Nonecloud)
                                                        ? locale.t(locale.keys.endpoint.button.save)
                                                        : locale.t(locale.keys.action.next)}
                                                </Button>
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                    {activeStep === 1 && <DialogMessage open={isOpen} handleClose={handleClose} message={editCloudContainer.m365ErrorMessage(activeStep)} />}
                </Grid>
            )}
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(withRouter(EditCloudComponet));
