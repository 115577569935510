import * as React from 'react';

import { Theme, createStyles, WithStyles, withStyles, Popover, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import * as locale from '@/common/utils/locale/locale';
import * as schema from '@/bundles/schema/typescript/schema';

const styles = (theme: Theme) =>
    createStyles({
        error: {
            display: 'flex',
            '&& svg': {
                width: theme.spacing.unit * 2.5,
                height: theme.spacing.unit * 2.5,
                color: 'red',
            },
        },
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            maxWidth: '50%',
            padding: theme.spacing.unit,
            [theme.breakpoints.down('md')]: {
                maxWidth: '80%',
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    listScanExFail?: string[];
    listPrintExFail?: string[];
    xStorageAccFail?: string;
    isShared?: boolean;
}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const openPopover = Boolean(anchorEl);
    let dataList: string[] = [];
    const [accFailState, setAccFailState] = React.useState<string | undefined>('');
    const lang = window.localStorage.getItem('lang');

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    if (props.xStorageAccFail && accFailState === '') {
        setAccFailState(props.xStorageAccFail);
    }

    if (props.listScanExFail && props.listScanExFail.length !== 0) {
        dataList = [...props.listScanExFail];
    }

    if (props.listPrintExFail && props.listPrintExFail.length !== 0) {
        dataList = [...dataList, ...props.listPrintExFail];
    }

    const exFailList = dataList.filter((v, i) => dataList.indexOf(v) === i);

    return (
        <>
            <div className={classes.error} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                <Error />
            </div>
            <Popover
                id="mouse-over-popover"
                open={openPopover}
                anchorEl={anchorEl}
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <>
                    {lang === schema.Language.Ja ? (
                        <Typography>
                            {accFailState && `${accFailState}: `}
                            {locale.t(locale.keys.popoverWarning.xstoragesAccountFail, {
                                endpointType: props.isShared ? locale.t(locale.keys.common.model.endpoint.shared) : locale.t(locale.keys.common.model.endpoint.individual),
                            })}
                        </Typography>
                    ) : (
                        <Typography>
                            {accFailState && `${accFailState}: `}
                            {locale.t(locale.keys.popoverWarning.xstoragesAccountFail, {
                                endpointType: props.isShared ? locale.t(locale.keys.common.model.endpoint.shared) : locale.t(locale.keys.common.model.endpoint.individual),
                            })}
                        </Typography>
                    )}
                    {exFailList.length !== 0 && (
                        <Typography>
                            {exFailList}
                            {locale.t(locale.keys.popoverWarning.hasBeenRemoved)}
                        </Typography>
                    )}
                </>
            </Popover>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
