import React, { FC, useEffect } from 'react';
import { Theme, Grid, WithStyles, createStyles, withStyles, Tabs, Tab } from '@material-ui/core';
import { styles as baseTabStyles } from '@/common/components/Tab/style';
import * as locale from '@/common/utils/locale/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import MfpPermissionTable from './MfpPermissionTable';
import UserPermissionTable from './UserPermissionTable';
import GroupPermissionTable from './GroupPermissionTable';
import { MfpPermissionRow, UserPermissionRow, GroupPermissionRow } from '@/common/models/endpoints/useEndpoints';

const styles = (theme: Theme) =>
    createStyles({
        ...baseTabStyles(theme),
        root: {
            width: '100%',
            gap: '16px',
        },
    });

interface Props extends WithStyles<typeof styles> {
    isShared: boolean;
    enablePermission: {
        applicationPermission: boolean;
        userGroupPermission: boolean;
        mfpPermission: boolean;
    };
    wsData: {
        wsUserList: schema.V1ObjectsWorkspaceuserLarge[];
        wsGroupList: schema.V1WorkspaceGroupsShowResponse[];
        wsMfpList: schema.V1ObjectsWorkspacemfp[];
    };
    mfpPermission: MfpPermissionRow[];
    handleMfpPermission: (mfpPermission: string[]) => void;
    selectedMfpPermission: string[];
    userPermission: UserPermissionRow[];
    handleUserPermission: (userPermission: string[]) => void;
    selectedUserPermission: string[];
    groupPermission: GroupPermissionRow[];
    handleGroupPermission: (groupPermission: string[]) => void;
    selectedGroupPermission: string[];
}

const initialSelectedTab = (userGroupPermission: boolean, mfpPermission: boolean, isShared: boolean): 'user' | 'group' | 'mfp' | null => {
    if (isShared) {
        if (userGroupPermission) {
            return 'user';
        } else if (mfpPermission) {
            return 'mfp';
        } else {
            return null;
        }
    } else {
        if (mfpPermission) {
            return 'mfp';
        } else if (userGroupPermission) {
            // 通常業務/転送先画面において、ユーザーグループ利用制限が有効のことはないので、複合機の利用制限にタブを切り替える。
            return 'mfp';
        } else {
            return null;
        }
    }
};

const PermissionTable: FC<Props> = (props: Props) => {
    const { classes } = props;

    const [tabIndex, setTabIndex] = React.useState<'user' | 'group' | 'mfp' | null>(
        initialSelectedTab(props.enablePermission.userGroupPermission, props.enablePermission.mfpPermission, props.isShared),
    );

    // 各利用制限のスイッチをON/OFFにした場合に、有効なタブに切り替える処理
    useEffect(() => {
        if (!props.enablePermission.mfpPermission && props.enablePermission.userGroupPermission && tabIndex !== 'group') {
            setTabIndex('user');
        }
        if (props.enablePermission.mfpPermission && !props.enablePermission.userGroupPermission) {
            setTabIndex('mfp');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.enablePermission.mfpPermission, props.enablePermission.userGroupPermission]);

    const handleTabChange = (_: React.ChangeEvent<{}>, newValue: 'user' | 'group' | 'mfp' | null) => {
        setTabIndex(newValue);
    };

    return (
        <Grid container direction="column" style={{ gap: '4px' }}>
            <Grid item className={classes.tabs} style={{ marginInlineStart: '4px' }}>
                <Tabs value={tabIndex} onChange={handleTabChange} classes={{ root: classes.tabsContainer, indicator: classes.indicator }}>
                    {props.enablePermission.userGroupPermission && props.isShared && (
                        <Tab label={locale.t(locale.keys.endpointsPermission.tab.userPermission)} className={classes.tabTags} classes={{ selected: classes.tabSelected }} value={'user'} />
                    )}
                    {props.enablePermission.userGroupPermission && props.isShared && (
                        <Tab label={locale.t(locale.keys.endpointsPermission.tab.groupPermission)} className={classes.tabTags} classes={{ selected: classes.tabSelected }} value={'group'} />
                    )}
                    {props.enablePermission.mfpPermission && (
                        <Tab label={locale.t(locale.keys.endpointsPermission.tab.mfpPermission)} className={classes.tabTags} classes={{ selected: classes.tabSelected }} value={'mfp'} />
                    )}
                </Tabs>
            </Grid>
            <Grid item>
                {tabIndex === 'user' && (
                    <UserPermissionTable
                        userPermission={props.userPermission}
                        selectedUserPermission={props.selectedUserPermission}
                        handleUserPermission={props.handleUserPermission}
                        groupPermission={props.groupPermission}
                        selectedGroupPermission={props.selectedGroupPermission}
                    />
                )}
                {tabIndex === 'group' && (
                    <GroupPermissionTable
                        userPermission={props.userPermission}
                        selectedUserPermission={props.selectedUserPermission}
                        groupPermission={props.groupPermission}
                        selectedGroupPermission={props.selectedGroupPermission}
                        handleGroupPermission={props.handleGroupPermission}
                    />
                )}
                {tabIndex === 'mfp' && <MfpPermissionTable mfpPermission={props.mfpPermission} selectedMfpPermission={props.selectedMfpPermission} handleMfpPermission={props.handleMfpPermission} />}
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(PermissionTable);
