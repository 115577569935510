import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import locale from '@/common/utils/locale';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: '24px 0',
            margin: '0 24px',
        },
        action: {
            marginRight: theme.spacing.unit * 2,
            // borderTop: '1px solid #ccc',
            '&& button': {
                textTransform: 'unset',
            },
        },
        yesBtn: {
            color: '#ff7300',
            border: 'none',
        },
    });

interface Props extends WithStyles<typeof styles> {
    value?: string;
    callBack: (isYes: boolean, value?: string) => void;
    children?: React.ReactNode;
    skipEffect?: boolean;
    message?: string;
    name?: string;
    isDisable?: boolean;
}

const CopyConfirm: React.SFC<Props> = (props: Props) => {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (props.skipEffect) {
            setOpen(true);
        }
    }, [props.skipEffect]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        if (!props.isDisable) {
            setOpen(true);
        }
    };

    const handleConfirm = () => {
        props.callBack(true, props.value);
        setOpen(false);
    };

    const createMessage = () => {
        switch (locale.current()) {
            case locale.Language.English:
                return <span>{locale.t(locale.keys.confirmCopy.areYouSaveAndCopy)}</span>;
            case locale.Language.Japanese:
                return <span>{locale.t(locale.keys.confirmCopy.areYouSaveAndCopy)}</span>;
        }
    };
    return (
        <>
            <div onClick={handleOpen}>{props.children}</div>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent className={props.classes.content}>
                    <DialogContentText>{createMessage()}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className={props.classes.action}>
                        <Button size="small" color="primary" onClick={handleClose}>
                            {locale.t(locale.keys.confirmCopy.button.no)}
                        </Button>
                        <Button className={props.classes.yesBtn} onClick={handleConfirm} variant="outlined">
                            {locale.t(locale.keys.confirmCopy.button.yes)}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withStyles(styles)(CopyConfirm);
