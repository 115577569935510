// @ts-nocheck
import * as schema from '@/bundles/schema/typescript/schema';

export const dummyNameRule: schema.V1EndpointsRulesUpdateResponse = {
    dateFormat: 'y|M|d',
    endpoint: '303030303030303030303031',
    fileOptional: false,
    fixed: [],
    itemList: 'date|file',
    separator: '-',
    wordList: ['abc', 'xyz'],
    sequenceType: 0,
    lockEdit: false,
};

export default dummyNameRule;
