import List from './List';
import Modal from './Modal';
import Docard from './Modal/Docard';
import Docab from './Modal/docab';
import auth from './auth';

export default {
    List,
    Modal,
    Docard,
    Docab,
    auth,
};
