import { default as fig } from './fig@2x.png';

interface Img {
    [key: string]: string;
}

const eraseBorder: Img = { fig };
export default {
    eraseBorder,
};
