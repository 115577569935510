import { default as React, useEffect } from 'react';
import { default as dashboardImg } from '@/common/img/dashboard-img/index';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import useTitle from '@/common/components/hooks/useTitle';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { UserAppContainer } from '@/user/components/UserAppContainer';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
        },
    });

interface Props extends WithStyles<typeof styles> {
    theme: Theme;
    onTitle: (title?: string) => void;
}

export const Component: React.FC<Props> = (props) => {
    const matchesLg = useMediaQuery(props.theme.breakpoints.up('lg'), {
        defaultMatches: false,
    });
    const ui = useUI();
    const appContainer = UserAppContainer.useContainer();
    useTitle(locale.t(locale.keys.pageTitle.dashboard.userIndex));
    if (typeof props.onTitle === 'function') {
        props.onTitle(locale.t(locale.keys.pageTitle.dashboard.userIndex));
    }

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        ui.update(UI.Loaded);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const render = () => {
        if (matchesLg) {
            return (
                <>
                    <img src={dashboardImg.dashboardImg.siosDashboard2x} alt="img" />
                </>
            );
        }
        return (
            <>
                <img src={dashboardImg.dashboardImg.siosDashboard} alt="img" />
            </>
        );
    };
    return <>{render()}</>;
};

export default withStyles(styles, { withTheme: true })(Component);
