import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import querystring from 'querystring';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const getMfpList = async (sub: string, auth: string, offset?: number, limit?: number): Promise<schema.V1UserMfpsShowResponse> => {
    const query = querystring.stringify({
        offset,
        limit,
    });

    const response = await axiosFactory.get<schema.V1UserMfpsShowResponse>(`https://${consts.environment.api.hostname}/v1/user/mfps/${encodeURIComponent(sub)}?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const update = async (sub: string, auth: string): Promise<schema.V1MfpUsersStatusShowResponse> => {
    const responce = await axiosFactory.put<schema.V1MfpUsersStatusShowResponse>(
        `https://${consts.environment.api.hostname}/v1/user/mfps/${encodeURIComponent(sub)}`,
        {},
        {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        },
    );
    return responce.data;
};

export const deleteMfp = async (id: string, auth: string): Promise<schema.V1MfpUsersStatusShowResponse> => {
    const response = await axiosFactory.delete<schema.V1MfpUsersStatusShowResponse>(`https://${consts.environment.api.hostname}/v1/user/mfps/${id}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
