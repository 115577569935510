import locale from '@/common/utils/locale';
import { isNotGarble } from '@/common/utils/webappUtil';
import { FOLDER_SIZE, forbiddenWords } from '@/user/constants/endpoint-setting';

const removeUnnecessarySpace = (chip: string) => {
    return chip.replace(/  +/g, ' ');
};

const isWordInvalid = (item: string) => {
    for (let index = 0; index < forbiddenWords.length; index += 1) {
        const char = forbiddenWords[index];
        if (item.toLowerCase().indexOf(char) === 0) return true;
    }
    return false;
};

const regexFolder = new RegExp(`^${locale.t(locale.keys.fileName.folderLabel.index)}([1-9]|1[0-9]|20)$`);
const regexSpecialCharacter = new RegExp(/^(?!.*\.{2})[^"#%&*,/:;<>?\\{-~]*$/);
const regexDesktopIni = new RegExp(/^(?!desktop\.ini).*$/);
const regexVti = new RegExp(/^(?!.*_vti_).*$/);

export const validateNameRuleChip = (chip: string, itemList: string[]): { itemList: string[]; errorMessege: string } => {
    const trimmedChip = chip.trim();
    let errorMessege = '';
    let fixedCount = 0;
    // ファイル名に指定できない記号文字をチェック \ ¥ : , ; * ? " < > | /
    if (!regexSpecialCharacter.test(removeUnnecessarySpace(trimmedChip))) return { itemList, errorMessege };

    // 先頭に「desktop.ini」が含まれていないかどうか
    if (!regexDesktopIni.test(trimmedChip)) return { itemList, errorMessege };

    // 「_vti_」が含まれていないかどうか
    if (!regexVti.test(trimmedChip)) return { itemList, errorMessege };

    // 固定文字ですが、名付けルールの先頭にある場合、以下から開始している場合はエラー※システムファイル名となるため。
    if (itemList.length === 0 && isWordInvalid(removeUnnecessarySpace(chip))) return { itemList, errorMessege };

    // SJISで表示できない文字をチェック
    if (!isNotGarble(chip)) {
        return { itemList, errorMessege };
    }
    if (trimmedChip === locale.t(locale.keys.fileName.anyCharater.index) || trimmedChip === locale.t(locale.keys.fileName.user.index) || trimmedChip === locale.t(locale.keys.fileName.date.index)) {
        return { itemList, errorMessege };
    }
    if (regexFolder.test(trimmedChip) || trimmedChip === locale.t(locale.keys.fileName.folderLabel.index)) {
        return { itemList, errorMessege };
    }

    const itemChipList = [...itemList];
    if (itemChipList.includes(removeUnnecessarySpace(trimmedChip))) {
        return { itemList, errorMessege };
    }
    itemChipList.push(removeUnnecessarySpace(trimmedChip));

    // 固定文字の場合カウントする
    for (let i = 0; i < itemChipList.length; i++) {
        if (
            !regexFolder.test(itemChipList[i]) &&
            itemChipList[i] !== locale.t(locale.keys.fileName.anyCharater.index) &&
            itemChipList[i] !== locale.t(locale.keys.fileName.user.index) &&
            itemChipList[i] !== locale.t(locale.keys.fileName.time.index) &&
            itemChipList[i] !== locale.t(locale.keys.fileName.date.index)
        ) {
            fixedCount++;
        }
    }
    // 個数を20個に制限
    if (fixedCount > FOLDER_SIZE) {
        errorMessege = locale.t(locale.keys.validation.itemlistLimit);
        return { itemList, errorMessege };
    }

    return { itemList: itemChipList, errorMessege };
};

export const validateFileName = (folderName: string): boolean => {
    // 半角スペースから始まる場合
    if (folderName.match(/^\s.*$/)) return false;

    // 「.」から始まる場合
    if (folderName.match(/^\..*$/)) return false;

    // ファイル名に指定できない記号文字をチェック \ ¥ : , ; * ? " < > | /
    if (!regexSpecialCharacter.test(folderName)) return false;

    // 先頭に「desktop.ini」が含まれていないかどうか
    if (!regexDesktopIni.test(folderName)) return false;

    // 「_vti_」が含まれていないかどうか
    if (!regexVti.test(folderName)) return false;

    // 名付けルールの先頭にある場合、以下から開始している場合はエラー※システムファイル名となるため。
    if (isWordInvalid(removeUnnecessarySpace(folderName))) return false;

    return true;
};
