import * as React from 'react';

export const useModal = () => {
    const [isOpen, setOpen] = React.useState(false);

    const open = () => setOpen(true);
    const close = () => setOpen(false);

    return { isOpen, open, close };
};
