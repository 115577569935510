import { default as React, useEffect, useState } from 'react';
import { getQuery, getCookie, createAuthLink, setAuthCookie, Status, StatusObj, handleClosePopUp } from './model';
import { StylesResult, StylesLoading, StylesForbidden, StylesCloudConnection } from './Page';
import locale from '@/common/utils/locale';
import * as errLocale from '@/common/utils/locale/error-locale';
import { isHttpStatusErrorResponse } from '@/common/utils/errorHandler';

export const StatusPage = (props: { status: StatusObj; onClickRetryAuth: () => void }) => {
    // 返却されるステータスコードによって表示される画面
    switch (props.status.status) {
        case Status.ERROR:
            // ステータスコード付きのエラーの場合
            switch (props.status.statusCode) {
                // 認可URLの有効期限切れの場合
                case 401:
                case 403:
                    return <StylesResult title={locale.t(locale.keys.cloudConnectionAuth.title.error)} message={locale.t(locale.keys.cloudConnectionAuth.message.error.expired)} />;
            }

            // エラーID（E000000 ~ E990000）付きのエラーの場合
            switch (props.status.errorCode) {
                case errLocale.keys.E07003:
                    return <StylesResult title={locale.t(locale.keys.cloudConnectionAuth.title.error)} message={locale.t(locale.keys.cloudConnectionAuth.message.error.invalidDomain)} />;
                case errLocale.keys.E07004:
                    return <StylesResult title={locale.t(locale.keys.cloudConnectionAuth.title.error)} message={locale.t(locale.keys.cloudConnectionAuth.message.error.invalidCloudStorage)} />;
                // メンバーサイトのクラウドストレージ画面に挙動を合わせるため、認可済みの場合は認可成功とみなす
                // このとき、認可期限は更新される
                case errLocale.keys.E07007:
                    return <StylesResult title={locale.t(locale.keys.cloudConnectionAuth.title.success)} message={locale.t(locale.keys.cloudConnectionAuth.message.success)} />;
            }

            // 上記に当てはまらない場合
            return <StylesResult title={locale.t(locale.keys.cloudConnectionAuth.title.error)} message={locale.t(locale.keys.cloudConnectionAuth.message.error.unknown)} />;
        case Status.SUCCESS:
            return <StylesResult title={locale.t(locale.keys.cloudConnectionAuth.title.success)} message={locale.t(locale.keys.cloudConnectionAuth.message.success)} />;

        case Status.LOADING:
            return <StylesLoading />;
        case Status.CLOUD_CONNECTION:
            return <StylesCloudConnection onClickRetryAuth={props.onClickRetryAuth} />;
        default:
            return <StylesForbidden />;
    }
};

// RootComponent
const Root = () => {
    const [pageStatus, setStatus] = useState<StatusObj>({
        // 初期状態はローディング状態
        // この画面を開くときは必ず前処理として何らかの処理が発生するため
        status: Status.LOADING,
        statusCode: null,
        errorCode: null,
    });
    // 新規クラウド接続画面の為のステート
    const [redirectUrl, setRedirectUrl] = useState('');

    const query = getQuery();
    const code = query.code as string | null | undefined;
    const orderId = query.id as string | null | undefined;
    const scope = query.scope as string | null | undefined;

    // 認可ボタンを押下したときにポップアップを表示する用の関数
    const onClickRetryAuth = () => {
        // redirectUrlに空文字は入らない
        // createAuthLinkでUrlを受け取れなければエラー画面が表示されて認可ボタンを押下できなくなるから
        handleClosePopUp(redirectUrl, setStatus);
    };

    useEffect(() => {
        (async () => {
            // 認可メールからリダイレクトした場合に認可URLを取得
            if (code && orderId) {
                try {
                    let status = {
                        status: Status.LOADING as Status,
                        statusCode: null,
                        errorCode: null,
                    };
                    setStatus({ ...status });
                    const authLink = await createAuthLink(orderId, code);

                    // popupを定期監視し、閉じたら認可情報をAPIに送信
                    handleClosePopUp(authLink.authorizationLink, setStatus);

                    // popupを表示した後も画面はそのまま「CLOUD_CONNECTION」
                    setRedirectUrl(authLink.authorizationLink);
                    status = {
                        status: Status.CLOUD_CONNECTION as Status,
                        statusCode: null,
                        errorCode: null,
                    };
                    setStatus({ ...status });
                } catch (e) {
                    // ここではcreateAuthLinkによる認可期限エラーか不明なエラーしか発生しない
                    let status: StatusObj = {
                        status: Status.ERROR as Status,
                        statusCode: 500,
                        errorCode: null,
                    };
                    if (isHttpStatusErrorResponse(e)) {
                        status.statusCode = e.statusCode;
                    }
                    setStatus({ ...status });
                }
                return;
            }

            // 認可後にcodeをcookieに一時保存してpopupを閉じる
            // popupでのみ実行される
            const cookie = getCookie();
            if (code && scope && !orderId && cookie.connectionService && cookie.token) {
                setAuthCookie(code, scope);
                window.close();
                return;
            }

            // それ以外の場合
            const status = {
                status: Status.UNKNOWN as Status,
                statusCode: null,
                errorCode: null,
            };
            setStatus({ ...status });
            return;
        })();
    }, [code, orderId]);

    return <StatusPage status={pageStatus} onClickRetryAuth={onClickRetryAuth} />;
};

export default Root;
