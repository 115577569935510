import * as schema from '@/bundles/schema/typescript/schema';
import * as validator from '@/common/utils/validator';
import userRole from '@/common/constants/userRole';
import * as Login from '@/common/api/auth/login';

// メンバSPA権限チェック。true:ok false:ng
export const roleCheck = (workspace: schema.V1ObjectsWorkspace, user: schema.V1ObjectsWorkspaceuserLarge) => {
    switch (user.role) {
        case userRole.systemAdmin: // システム管理者は無条件でok
            return true;
        case userRole.admin: // 管理者は無条件でok
            return true;
        case userRole.externalAdmin: // 外部管理者は無条件でng
            return false;
        case userRole.member: // メンバーは無条件でok
            return true;
        default:
            return false; // 想定外のrole
    }
};

export const getWorkspaceObject = async (displayId: string, auth: string): Promise<schema.V1ObjectsWorkspace> => {
    return Login.getWorkspaceObject(displayId, auth);
};

export const memberSignin = async (workspaceId: string, email: string, pass: string): Promise<schema.V1UserCreateResponse | null> => {
    return Login.memberSignin(workspaceId, email, pass);
};

export const myPasswordReset = async (req: schema.V1WorkspaceuserPasswordResetCreateRequest): Promise<schema.V1WorkspaceuserPasswordResetCreateResponse> => {
    return Login.myPasswordReset(req);
};

export interface Form {
    email: string;
    password: string;
    validateInit: {
        email: boolean;
        password: boolean;
    };
    errors: validator.ValidationOutput;
}

export const New = () => Login.New();
export const validations = Login.validations;
export const passwordResetValidations = Login.passwordResetValidations;
export const NewValidation: () => validator.ValidationOutput = Login.NewValidation;
