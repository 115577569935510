import { default as React, useEffect, useState } from 'react';
import { default as UI } from '@/common/constants/ui';
import useTitle from '@/common/components/hooks/useTitle';
import * as endpoints from '@/user/models/endpoints/endpoints';
import * as locale from '@/common/utils/locale/locale';
import { withStyles, Theme, createStyles, WithStyles, Button, Fab, Modal, Grid } from '@material-ui/core';
import * as schema from '@/bundles/schema/typescript/schema';
import { Add } from '@material-ui/icons';
import { Route, Switch, generatePath } from 'react-router';
import New from './New';
import routes from '@/user/constants/routes';
import { Variants } from '@/common/components/messages/CommonMessage';
import { NewEndpointContainer } from './create-endpoint/NewEndpointContainer';
import { UserAppContainer } from '../UserAppContainer';
import { isLoaded, isLoading, isError, isSaving } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';
import { PAGINATION_LIMIT_ENDPOINT } from '@/common/constants/pagination';
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc';
import { arrayMove } from '@/common/utils/array-helper/arrayHelper';
import Endpoint, { EndpointMenuObject } from '@/user/components/common/endpoints/card/Endpoint';
import * as orders from '@/user/models/endpoints/orders/orders';
import shareFormat from '@/common/constants/shareFormat';
import ui from '@/common/constants/ui';
import { getSplitNewLineString } from '@/common/utils/webappUtil';

const styles = (theme: Theme) =>
    createStyles({
        button: {
            marginLeft: 'auto',
            borderRadius: 50,
            top: -theme.spacing.unit * 2,
            right: theme.spacing.unit * 3,
            width: theme.spacing.unit * 27.75,
            '&& span': {
                fontSize: '0.875rem',
            },
            [theme.breakpoints.down(ui.breakPoints_1380)]: {
                display: 'none',
            },
        },
        addButtonContainer: {
            display: 'flex',
            marginTop: -theme.spacing.unit,
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing.unit * 3,
            right: theme.spacing.unit * 3,
            [theme.breakpoints.up(ui.breakPoints_1380)]: {
                display: 'none',
            },
        },
        commonModal: {
            backgroundColor: '#fff',
            flexGrow: 1,
            height: '100%',
            padding: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            outline: 'none',
        },
        root: {
            paddingTop: '12px',
        },
    });

interface Props extends WithStyles<typeof styles> {
    onTitle: () => void;
    onDesc: (desc?: string) => void;
    onSetFabWidth: (width: number) => void;
}

export const Component: React.SFC<Props> = (props) => {
    const { classes } = props;
    // Start from loading state, then update according to the progress.
    const [ui, setUI] = useState(UI.state.Loading);
    const appContainer = UserAppContainer.useContainer();
    useEffect(() => {
        appContainer.updateLoadingState(ui);
        if (isError(ui)) {
            appContainer.setOnBackError(true);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.error),
                variant: Variants.error,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    const [endpointList, setEndpointList] = useState<schema.V1ObjectsEndpointInfo[]>([]);
    const [pagination, setPagination] = useState<schema.V1ObjectsPagination>();
    const [open, setOpen] = React.useState(false);
    const [isItemFull, setIsItemFull] = useState(false);
    const [isDragHandle, setIsDragHandle] = useState(true);

    useTitle(locale.t(locale.keys.endpointUserSetting.title));
    if (typeof props.onTitle === 'function') {
        props.onTitle();
    }
    if (typeof props.onDesc === 'function') {
        const subtitle = getSplitNewLineString(locale.t(locale.keys.endpointUserSetting.description)).join('\n');
        props.onDesc(subtitle);
    }

    props.onSetFabWidth(232);

    useEffect(() => {
        void (async () => {
            await getEndpoints();
        })();
        return () => {
            appContainer.setOnBackError(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let dragHandle = false;
        if (appContainer.loadingState) {
            dragHandle = true;
        }
        setIsDragHandle(dragHandle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appContainer.loadingState]);

    const handleNewModel = () => {
        appContainer.setLoadingState(false);
        setOpen(false);
    };

    const handleReloadPage = async () => {
        await getEndpoints();
        appContainer.updateMessage({
            autoHideDuration: 3000,
            isOpen: true,
            message: locale.t(locale.keys.action.created),
            variant: Variants.success,
        });
    };

    const createNewEndpoint = () => {
        setOpen(true);
    };

    const fetchMoreListItems = () => {
        if (isItemFull) {
            return;
        }
        if (!endpointList || endpointList.length === 0 || appContainer.loadingState) {
            setIsFetching(false);
            return;
        }

        setTimeout(async () => {
            try {
                appContainer.setLoadingState(true);
                let offset = endpointList.length;
                if (pagination !== undefined) {
                    offset = Number(pagination.offset);
                }
                const data = await endpoints.getEndpoints(true, true, appContainer.values.authorizationCode, shareFormat.personal, offset, PAGINATION_LIMIT_ENDPOINT);
                if (!data || data.endpoints.length === 0) {
                    setIsFetching(false);
                    setIsItemFull(true);
                    appContainer.setLoadingState(false);
                    return;
                }
                if (data.pagination !== undefined && data.pagination.totalCount === data.pagination.offset) {
                    setIsItemFull(true);
                }
                setPagination(data.pagination);
                setEndpointList([...endpointList, ...data.endpoints]);
                appContainer.setLoadingState(false);
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
                appContainer.setLoadingState(false);
            }
            setIsFetching(false);
        }, 2000);
    };
    const { isFetching, setIsFetching } = appContainer.useInfiniteScroll(isItemFull, fetchMoreListItems);

    const getEndpoints = async () => {
        try {
            setUI(UI.state.Loading);
            const data = await endpoints.getEndpoints(true, true, appContainer.values.authorizationCode, shareFormat.personal, 0, PAGINATION_LIMIT_ENDPOINT);
            if (!data) {
                setUI(UI.state.Error);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.error.notFound),
                    variant: Variants.error,
                });
                return;
            }
            let isFull = false;
            if ((data.pagination !== undefined && data.pagination.totalCount === data.pagination.offset) || data.endpoints.length === 0) {
                isFull = true;
            }
            setIsFetching(false);
            setIsItemFull(isFull);
            setPagination(data.pagination);
            setEndpointList(data.endpoints);
            setUI(UI.state.Loaded);
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
            setUI(UI.state.Error);
            appContainer.setLoadingState(false);
        }
    };

    const onSortEnd = async ({ oldIndex, newIndex }: SortEnd) => {
        if (typeof oldIndex !== 'number' || typeof newIndex !== 'number' || oldIndex === newIndex) {
            return;
        }
        const items = arrayMove(endpointList, oldIndex, newIndex);
        setEndpointList(items);
        const dataUpdate: schema.V1ObjectsEndpointOrder[] = items.map((item, index) => {
            return {
                id: item.id,
                order: index,
            };
        });
        await update({ endpoints: dataUpdate });
    };

    const copyEndpoint = async (endpointState: schema.V1ObjectsEndpointInfo) => {
        // リクエスト処理
        try {
            const option = endpoints.copyEndpointObject(endpointState, appContainer.values.signinWorkspaceUserObject);
            setUI(UI.state.Saving);
            const dataRes = await endpoints.copyEndpoint(endpointState.id, option, appContainer.values.authorizationCode);
            if (!dataRes) {
                // 失敗
                setUI(UI.state.Error);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.error.default),
                    variant: Variants.error,
                });
            } else {
                // 成功
                setUI(UI.state.Loaded);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.copied),
                    variant: Variants.success,
                });
            }
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
            appContainer.setLoadingState(false);
        }

        // 一覧の更新
        getEndpoints();
    };

    const SortableItem = React.memo(
        SortableElement(({ value, endpointMenuObjects }: { value: schema.V1ObjectsEndpointInfo; endpointMenuObjects: EndpointMenuObject[] }) => {
            const editPath = generatePath(routes.endpoints.edit, { id: value.id });
            return (
                <Grid item xs={12} sm={4} lg={3} container direction="row" justify="space-between" alignItems="center">
                    <Endpoint editPath={editPath} value={value} endpointMenuObjects={endpointMenuObjects} isShared={false} />
                </Grid>
            );
        }),
    );

    const SortableList = SortableContainer(({ items }: { items: schema.V1ObjectsEndpointInfo[] }) => (
        <Grid container direction="row" className={classes.root}>
            {items.map((value, index) => (
                <SortableItem
                    key={`item-${value.id}`}
                    index={index}
                    value={value}
                    endpointMenuObjects={[
                        {
                            name: locale.t(locale.keys.endpoint.button.settingcopy),
                            action: () => copyEndpoint({ ...value, share: 0 }),
                            disabled: endpoints.disableCopy(value),
                        },
                        {
                            name: locale.t(locale.keys.endpoint.button.sharecopy),
                            action: () => copyEndpoint({ ...value, share: 1 }),
                            disabled: endpoints.disableShareCopy(value, appContainer.values.signinWorkspaceUserObject),
                        },
                    ]}
                />
            ))}
        </Grid>
    ));

    const update = async (body: schema.V1EndpointsOrdersPatchRequest) => {
        try {
            appContainer.setLoadingState(true);
            const response = await orders.update(body, appContainer.values.authorizationCode);
            appContainer.setLoadingState(false);
            if (!response) {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.error.default),
                    variant: Variants.error,
                });
                return setUI(UI.state.Error);
            }
            if (response.status === schema.V1ObjectsModifyResponseStatusEnum.Denied) {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.failedToSave),
                    variant: Variants.error,
                });
                return;
            }
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.updated),
                variant: Variants.success,
            });
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
            appContainer.setLoadingState(false);
        }
    };

    return (
        <>
            {isLoading(ui) && <div data-testid={UI.state.Loading} />}

            {isSaving(ui) && <div data-testid={UI.state.Saving} />}

            <Switch>
                <Route exact path={routes.endpoints.index}>
                    {isLoaded(ui) && (
                        <>
                            <div className={classes.addButtonContainer}>
                                <Button variant="contained" className={classes.button} onClick={createNewEndpoint}>
                                    <Add />
                                    {locale.t(locale.keys.endpointUserSetting.addButton)}
                                </Button>
                            </div>
                            <div>
                                <Fab className={classes.fab} aria-label="Add" onClick={createNewEndpoint}>
                                    <Add />
                                </Fab>
                            </div>
                        </>
                    )}
                    {!endpointList || endpointList.length === 0 ? <></> : <SortableList items={endpointList} pressDelay={200} axis="xy" onSortEnd={onSortEnd} useDragHandle={isDragHandle} />}
                </Route>
            </Switch>
            <div>
                <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
                    <div className={classes.commonModal}>
                        <NewEndpointContainer.Provider>
                            <New onClose={handleNewModel} onCreateEndpoint={handleReloadPage} isShared={false} />
                        </NewEndpointContainer.Provider>
                    </div>
                </Modal>
            </div>
            {isFetching && !isItemFull && locale.t(locale.keys.spool.loading.index)}

            {isError(ui) && <div data-testid={UI.state.Error} />}
        </>
    );
};

export default withStyles(styles, { withTheme: true })(React.memo(Component));
