import { default as icon001 } from './icon001.png';
import { default as icon002 } from './icon002.png';
import { default as icon003 } from './icon003.png';
import { default as icon004 } from './icon004.png';
import { default as icon005 } from './icon005.png';
import { default as icon006 } from './icon006.png';
import { default as icon007 } from './icon007.png';
import { default as icon008 } from './icon008.png';
import { default as icon009 } from './icon009.png';
import { default as icon010 } from './icon010.png';
import { default as icon011 } from './icon011.png';
import { default as icon012 } from './icon012.png';
import { default as icon013 } from './icon013.png';
import { default as icon014 } from './icon014.png';
import { default as icon015 } from './icon015.png';
import { default as icon016 } from './icon016.png';
import { default as icon017 } from './icon017.png';
import { default as icon018 } from './icon018.png';
import { default as icon019 } from './icon019.png';
import { default as icon020 } from './icon020.png';
import { default as icon021 } from './icon021.png';
import { default as icon022 } from './icon022.png';
import { default as icon023 } from './icon023.png';
import { default as icon024 } from './icon024.png';
import { default as icon025 } from './icon025.png';
import { default as icon026 } from './icon026.png';
import { default as icon027 } from './icon027.png';
import { default as icon028 } from './icon028.png';
import { default as icon029 } from './icon029.png';
import { default as icon030 } from './icon030.png';
import { default as icon031 } from './icon031.png';
import { default as icon032 } from './icon032.png';
import { default as icon033 } from './icon033.png';
import { default as icon034 } from './icon034.png';
import { default as icon035 } from './icon035.png';
import { default as icon036 } from './icon036.png';
import { default as icon037 } from './icon037.png';
import { default as icon038 } from './icon038.png';
import { default as icon039 } from './icon039.png';
import { default as icon040 } from './icon040.png';
import { default as icon041 } from './icon041.png';
import { default as icon042 } from './icon042.png';
import { default as icon043 } from './icon043.png';
import { default as icon044 } from './icon044.png';
import { default as icon045 } from './icon045.png';
import { default as icon046 } from './icon046.png';
import { default as icon047 } from './icon047.png';
import { default as icon048 } from './icon048.png';
import { default as icon049 } from './icon049.png';
import { default as icon050 } from './icon050.png';

interface Img {
    [key: string]: string;
}

const iconMfps: Img = {
    icon001,
    icon002,
    icon003,
    icon004,
    icon005,
    icon006,
    icon007,
    icon008,
    icon009,
    icon010,
    icon011,
    icon012,
    icon013,
    icon014,
    icon015,
    icon016,
    icon017,
    icon018,
    icon019,
    icon020,
    icon021,
    icon022,
    icon023,
    icon024,
    icon025,
    icon026,
    icon027,
    icon028,
    icon029,
    icon030,
    icon031,
    icon032,
    icon033,
    icon034,
    icon035,
    icon036,
    icon037,
    icon038,
    icon039,
    icon040,
    icon041,
    icon042,
    icon043,
    icon044,
    icon045,
    icon046,
    icon047,
    icon048,
    icon049,
    icon050,
};
export default {
    iconMfps,
};
