import * as queryString from 'query-string';
import { default as consts } from '@/common/constants';
import jsonwebtoken from 'jsonwebtoken';
import { keys } from '@/common/utils/authManagement/cookieConstants';
import * as cookies from '@/common/utils/browser/cookies';

/**
 * Returns Azure B2C URL for login.
 * The result URL is chaotically long, therefore dive in to Azure Portal
 * and check the destination URL if something goes wrong.
 * https://portal.azure.com/#blade/Microsoft_AAD_B2CAdmin/TenantManagementMenuBlade/userJourneys
 */
export const loginUrl = (): string => {
    const path = [`https://${consts.environment.azure.b2c.directoryName}.b2clogin.com`, `${consts.environment.azure.b2c.directoryName}.onmicrosoft.com`, 'oauth2/v2.0/authorize'].join('/');

    const queries = queryString.stringify({
        p: consts.environment.azure.b2c.policy.signin,
        client_id: consts.environment.azure.b2c.appId,
        redirect_uri: consts.environment.azure.b2c.redirectUri,
        nonce: 'defaultNonce',
        response_mode: 'fragment',
        response_type: 'code',
        scope: [
            `https://${consts.environment.azure.b2c.directoryName}.onmicrosoft.com`,
            consts.environment.azure.b2c.appId,
            // Not sure blank space is a qualified URI, however tested and works on modern browsers.
            'user_impersonation openid',
        ].join('/'),
    });

    return `${path}?${queries}`;
};

/**
 * Given a URL hash containing Azure B2C token, parse and return token.
 * This method does not take care about the validity of the token,
 * because malicious token will be rejected on API service anyway.
 */
export const parseAzureB2CToken = (given: string): string => {
    const got = queryString.parse(given);

    // Azure B2C redirect URI returns token in #code=12345 format.
    // Parent context is responsible for handling exception.
    if (!got || !got.code || typeof got.code !== 'string') {
        throw new Error('given token is invalid');
    }

    return got.code as string;
};

export const isValidToken = (tokenFull: string | null): boolean => {
    if (!tokenFull) {
        return false;
    }
    return true;
};

export const identifyUser = (token?: string): { email: string; mfpNumber: string; sub: string } => {
    try {
        const localToken = token || cookies.get<string>(keys.authorizationCode) || '';
        const decodedToken = JSON.parse(JSON.stringify(jsonwebtoken.decode(localToken)));
        if (!decodedToken.sub) {
            return {
                email: '',
                mfpNumber: '',
                sub: '',
            };
        }
        const { sub, email, mfpNumber } = decodedToken;
        return {
            email,
            mfpNumber,
            sub,
        };
    } catch (err) {
        return {
            email: '',
            mfpNumber: '',
            sub: '',
        };
    }
};
