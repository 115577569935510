import * as React from 'react';
import { WithStyles, Theme, createStyles, withStyles, Avatar, Button } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import * as schema from '@/bundles/schema/typescript/schema';
import locale from '@/common/utils/locale';
import { listBrightBtnColor } from '@/user/constants/endpoint-setting';
import * as webappUtil from '@/common/utils/webappUtil';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing.unit * 3,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignSelf: 'center',
            [theme.breakpoints.down('md')]: {
                marginLeft: theme.spacing.unit * 1,
                marginRight: theme.spacing.unit * 1,
                width: '100%',
                overflow: 'auto',
            },
        },
        iconButton: {
            margin: theme.spacing.unit * 1.5,
            width: theme.spacing.unit * 7,
            height: theme.spacing.unit * 7,
            borderRadius: '50%',
            border: 'solid 1px #dddddd',
            [theme.breakpoints.down('md')]: {
                width: theme.spacing.unit * 5,
                height: theme.spacing.unit * 5,
                margin: theme.spacing.unit * 1,
            },
            cursor: 'pointer',
        },
        iconButtonFirefox: {
            margin: theme.spacing.unit * 1.5,
            width: theme.spacing.unit * 7,
            height: theme.spacing.unit * 7,
            borderRadius: '50%',
            border: 'solid 1px #dddddd',
            [theme.breakpoints.down('md')]: {
                width: theme.spacing.unit * 5,
                height: theme.spacing.unit * 5,
                margin: theme.spacing.unit * 1,
            },
            cursor: 'pointer',
            '&:focus-within': {
                border: 'solid 1px black',
            },
        },
        divBtn: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        btnClose: {
            color: '#ffffff',
            backgroundColor: '#ff7300',
            borderRadius: theme.spacing.unit * 3,
            width: theme.spacing.unit * 27,
            [theme.breakpoints.down('md')]: {
                color: '#ffffff',
                backgroundColor: '#ff7300',
                borderRadius: theme.spacing.unit * 3,
                width: theme.spacing.unit * 27,
            },
        },
        divList: {
            alignSelf: 'center',
            width: '50%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    closeModal: (btnColorName: string) => void;
    defaultValue?: string;
}

export const Component: React.SFC<Props> = (props: Props) => {
    const { classes } = props;

    const list = [];
    const [isSelectedColor, setIsSelectedColor] = React.useState();
    const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;

    const handlePressKeyButton = (event: any, key: string) => {
        if (event.keyCode === 32 || event.keyCode === 13) {
            setIsSelectedColor(key);
        }
    };

    if (props.defaultValue && !isSelectedColor) {
        const defaultEnum = webappUtil.getEnumKey(props.defaultValue, schema.V1ObjectsEndpointsButtonColor);
        setIsSelectedColor(defaultEnum);
    }
    const btnColorList = JSON.parse(JSON.stringify(schema.V1ObjectsEndpointsButtonColor));
    for (const item in btnColorList) {
        let styleBtn = { backgroundColor: item, color: 'transparent' };
        if (isSelectedColor && isSelectedColor === item && !listBrightBtnColor.includes(item)) {
            styleBtn = { backgroundColor: item, color: 'white' };
        }
        if (isSelectedColor && isSelectedColor === item && listBrightBtnColor.includes(item)) {
            styleBtn = { backgroundColor: item, color: 'black' };
        }
        list.push(
            <Avatar
                key={item}
                id={`avatar${item}`}
                className={isFirefox ? `${classes.iconButtonFirefox}` : `${classes.iconButton}`}
                style={styleBtn}
                onClick={() => setIsSelectedColor(item)}
                onKeyDown={(event) => handlePressKeyButton(event, item)}
                tabIndex={0}
            >
                <Check />
            </Avatar>,
        );
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.divList}>{list}</div>
            </div>
            <div className={classes.divBtn}>
                <Button disabled={isSelectedColor ? false : true} onClick={() => props.closeModal(isSelectedColor)} variant="contained" className={classes.btnClose}>
                    {locale.t(locale.keys.endpoint.saveChangeColor)}
                </Button>
            </div>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
