import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const getAuthLink = async (orderId: string, auth: string): Promise<schema.V1XStoragesOrderLinkShowResponse> => {
    const response = await axiosFactory.get<schema.V1XStoragesOrderLinkShowResponse>(`https://${consts.environment.api.hostname}/v1/x-storages/order/link/${orderId}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
