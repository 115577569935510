import * as validatejs from 'validate.js';
import * as moment from 'moment';
import locale from '@/common/utils/locale';
import { csvFormat4Endpoint } from '@/common/constants/csvFormat';
import { getStringByteCount } from '@/common/utils/webappUtil';

export interface ValidationOutput {
    [key: string]: string[] | null;
}

interface Rule {
    message: string;
    [key: string]: any;
}

interface Rules {
    datetime?: Rule;
    email?: Rule;
    cc?: Rule;
    equality?: Rule;
    exclusion?: Rule;
    format?: Rule;
    inclusion?: Rule;
    length?: Rule;
    numericality?: Rule;
    presence?: Rule;
    type?: Rule;
    url?: Rule;
    // custom validation
    workspaceId?: Rule;
    passwordPolicy?: Rule;
    csvEnumFormat?: Rule;
    userEmail?: Rule;
    domain?: Rule;
    pinPolicy?: Rule;
    mfpNumberFormat?: Rule;
    inputLimit?: Rule;
    phoneNumber?: Rule;
    csvBooFormat?: Rule;
    idFormat?: Rule;
    nameFormatWord?: Rule;
    regularExpression?: Rule;
    sameItem?: Rule;
    presenceSpaceOk?: Rule;
    strByteLength?: Rule;
}

// https://validatejs.org/#constraints
// ex)
//    displayId: {
//        presence: {
//            allowEmpty: false,
//            message: locale.t(locale.keys.validation.required),
//        },
//    },
export interface Constraints {
    [key: string]: Rules;
}

/**
 * 文字列の検証
 * @param attributes
 * @param constratints
 * @param newValidationOutput
 * @param parseFunction
 * @param formatFunction
 * @return 抽象オブジェクト(任意の型を指定できる)
 */
const validate = <T>(
    attributes: T,
    constratints: Constraints,
    newValidationOutput: () => ValidationOutput,
    parseFunction: (value: string, options: any) => moment.Moment,
    formatFunction: (value: string, options: any) => string,
): ValidationOutput | null => {
    validatejs.extend(validatejs.validators.datetime, {
        parse: parseFunction,
        format: formatFunction,
    });

    // azure ad b2cに登録可能なメールアドレスかどうか判定する
    validatejs.validators.userEmail = (value: any, options: Rule, key: any, attributes: any) => {
        // 未定義
        if (!value) {
            if (options.allowEmpty) {
                // 任意入力の項目の場合、空欄を許可する
                return '';
            }
            return options && options.message ? options.message : locale.t(locale.keys.validation.required);
        }

        if (validateEmailFormat(value)) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.email);
        }

        return '';
    };

    // クラウドストレージ認可時に、Covas側で許可するドメインのフォーマット
    validatejs.validators.domain = (value: any, options: Rule, key: any, attributes: any) => {
        // 未定義
        if (!value) {
            if (options.allowEmpty) {
                // 任意入力の項目の場合、空欄を許可する
                return '';
            }
            return options && options.message ? options.message : locale.t(locale.keys.validation.required);
        }
        /* azure ad b2cに登録可能なアドレスのドメイン部と同じ正規表現。
            日本語ドメインを登録する需要が今のところないため*/
        if (!/^[a-zA-Z0-9]+[a-zA-Z0-9.-]*\.[a-zA-Z0-9]+$/.test(value)) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.incorrectFormat);
        }
        // 連続した2個以上のドット(..)がある場合
        if (/\.\./.test(value)) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.incorrectFormat);
        }
        return '';
    };

    // CovasのメールCC入力ルールは下記の通り
    // 単一フォームにカンマ区切りで2つのアドレスが入力可能
    // 検証前にスペースをトリミングすること
    // OK: sios@sios.com,aaa@sios.com
    // NG: sios@sios.com, aaa@sios.com
    validatejs.validators.cc = (value: any, options: Rule, key: any, attributes: any) => {
        // 未定義
        if (!value) {
            if (options.allowEmpty) {
                // 任意入力の項目の場合、空欄を許可する
                return '';
            }
            return options && options.message ? options.message : locale.t(locale.keys.validation.required);
        }
        // ccに複数メールアドレスが指定されている場合
        if (value.includes(',')) {
            const mailList = value.split(',');
            // v1.8.0ではccの上限は2つ
            if (mailList.length > 2) {
                return options && options.message ? options.message : locale.t(locale.keys.validation.cc.tooMany);
            } else {
                // 複数アドレス指定なら、各アドレスを検証
                for (let i in mailList) {
                    if (validateEmailFormat(mailList[i])) {
                        return options && options.message ? options.message : locale.t(locale.keys.validation.email);
                    }
                }
                // 重複検証
                if (mailList[0] === mailList[1]) {
                    return options && options.message ? options.message : locale.t(locale.keys.validation.cc.invalidDuplication);
                }
            }
            // カンマなしで単一アドレスの場合
        } else {
            if (validateEmailFormat(value)) {
                return options && options.message ? options.message : locale.t(locale.keys.validation.email);
            }
        }
        return '';
    };

    // custom validation
    validatejs.validators.workspaceId = (value: any, options: Rule, key: any, attributes: any) => {
        // 必須
        if (!value) {
            return locale.t(locale.keys.validation.required);
        }
        // 21文字以下
        if (value.length > 21) {
            return locale.t(locale.keys.validation.tooLong, { num: 21 });
        }
        // 英字(小文字)、数字、ハイフンのみ使用可能
        if (!/^[a-z0-9-]+$/.test(value)) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.workspaceIdFormat);
        }
        // 先頭のハイフン禁止
        const start = value.substring(0, 1);
        if (!/[a-z0-9]/.test(start)) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.workspaceIdFormat);
        }
        // 終端のハイフン禁止
        const end = value.slice(-1);
        if (!/[a-z0-9]/.test(end)) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.workspaceIdFormat);
        }
        // 連続するハイフン禁止
        if (value.indexOf('--') !== -1) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.workspaceIdFormat);
        }
        return '';
    };

    validatejs.validators.passwordPolicy = (value: any, options: Rule, key: any, attributes: any) => {
        // パスワードは8～64文字
        if (!value || value.length < 8 || value.length > 64) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.passwordPolicy);
        }
        // 英字(小文字)、数字、ハイフンのみ使用可能
        if (!/^[a-zA-Z0-9!-/:-@¥[-`{-~]+$/.test(value)) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.passwordPolicy);
        }
        // 小文字、大文字、数字、記号の4種類のうち3種が必要
        const hasLowercase = (str: string) => {
            return !str ? false : /[a-z]/.test(str);
        };
        const hasUppercase = (str: string) => {
            return !str ? false : /[A-Z]/.test(str);
        };
        const hasNumber = (str: string) => {
            return !str ? false : /[0-9]/.test(str);
        };
        const hasSymbol = (str: string) => {
            return !str ? false : /[!-/:-@¥[-`{-~]/.test(str);
        };
        if ([hasLowercase(value), hasUppercase(value), hasNumber(value), hasSymbol(value)].filter((val) => val).length < 3) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.passwordPolicy);
        }
        return '';
    };

    /**
     * CSV読み込みチェック
     * 言語と権限のチェックに使用可能
     * 通常業務：アイコン・ラベル色・接続方法
     * @param value
     * @param options
     * @param key
     * @param attributes
     */
    validatejs.validators.csvEnumFormat = (value: any, options: Rule, key: any, attributes: any) => {
        const allows = options.allow as string[];
        for (let i = 0; i < allows.length; i += 1) {
            if (allows[i] === value) {
                return '';
            }
        }
        return options.message;
    };

    /**
     * 存在チェック：スペースを許容する
     * @param value
     * @param options
     * @param key
     * @param attributes
     */
    validatejs.validators.presenceSpaceOk = (value: any, options: Rule, key: any, attributes: any) => {
        if (value) return '';
        return options.message;
    };

    /**
     * pinチェック
     */
    validatejs.validators.pinPolicy = (value: any, options: Rule, key: any, attributes: any) => {
        // 未入力
        if (!value) {
            if ('allow' in options && typeof options.allow === 'boolean' && !options.allow) {
                return locale.t(locale.keys.validation.required);
            }
            return '';
        }
        // return options && options.message ? options.message : locale.t(locale.keys.validation.required);
        // 数字のみ使用可能
        if (!/^[0-9]+$/.test(value)) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.numeric);
        }
        // 6文字
        if (value.length !== 6) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.just, { num: 6 });
        }
        return '';
    };

    /**
     * 機械番号のチェック
     * @param value
     * @param options
     * @param key
     * @param attributes
     */
    validatejs.validators.mfpNumberFormat = (value: any, options: Rule, key: any, attributes: any) => {
        // 未入力
        if (!value) {
            if ('allow' in options && typeof options.allow === 'boolean' && !options.allow) {
                return locale.t(locale.keys.validation.mfpNumber);
            }
            return '';
        }
        // 英数字のみ使用可能
        if (!/(^[A-Za-z0-9]{4}-[0-9]{6}$)/g.test(value)) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.mfpNumber);
        }
        return '';
    };

    /**
     * 環境依存文字のチェック
     * @param value
     * @param options
     * @param key
     * @param attributes
     */
    validatejs.validators.inputLimit = (value: any, options: Rule, key: any, attributes: any) => {
        // 文字列チェック
        if (inputLimit(value)) {
            return options && options.message ? options.message : '入力できない文字が含まれています。';
        }
        return '';
    };

    /**
     * 電話番号のチェック
     * @param value
     * @param options
     * @param key
     * @param attributes
     */
    validatejs.validators.phoneNumber = (value: any, options: Rule, key: any, attributes: any) => {
        // 未入力
        // 英数字のみ使用可能
        if (!/^[0-9\-+]+$/.test(value)) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.incorrectFormat);
        }
        return '';
    };

    /**
     * 認可IDのチェック
     * @param value
     * @param options
     * @param key
     * @param attributes
     */
    validatejs.validators.idFormat = (value: any, options: Rule, key: any, attributes: any) => {
        // 英数字のみ使用可能
        if (!options.allow.test(value)) {
            return options && options.message ? options.message : locale.t(locale.keys.validation.inputLimit);
        }
        return '';
    };

    /**
     * booleanであることをチェック
     * @param value
     * @param options
     * @param key
     * @param attributes
     */
    validatejs.validators.csvBooFormat = (value: any, options: Rule, key: any, attributes: any) => {
        if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
            return '';
        }
        return options.message;
    };

    /**
     * 正規表現によるvalueのチェック
     * @param value
     * @param options
     * @param key
     * @param attributes
     */
    validatejs.validators.regularExpression = (value: any, options: Rule, key: any, attributes: any) => {
        if (options.allowSpace) {
            value = value.split(' ').join('');
        }
        if (options.allow.test(value)) return '';
        return options.message;
    };

    /**
     * ファイル名形式のチェック
     * @param value
     * @param options
     * @param key
     * @param attributes
     */
    validatejs.validators.nameFormatWord = (value: any, options: Rule, key: any, attributes: any) => {
        // %で分割・配列の先頭が空文字であること・配列の[1]以降がallowのどれか（正規表現・%なし）と一致すること
        const wordlist = value.split(csvFormat4Endpoint.NAMEFORMAT_SPLIT);
        if (wordlist[0]) return options.message;
        for (let index = 1; index < wordlist.length; index++) {
            if (!options.allow.test(wordlist[index])) return options.message;
        }
        return '';
    };

    /**
     * 重複の禁止
     * @param value
     * @param options
     * @param key
     * @param attributes
     */
    validatejs.validators.sameItem = (value: any, options: Rule, key: any, attributes: any) => {
        const valuelist = value.split(options.separator);
        const valueitem = valuelist.filter(Boolean);
        const valueset = new Set(valueitem);
        if (valueset.size !== valueitem.length) return options.message;
        return '';
    };

    /**
     * 文字列のバイト数確認
     * Shift_JIS想定でカウントする
     */
    validatejs.validators.strByteLength = (value: any, options: Rule, key: any, attributes: any) => {
        if (getStringByteCount(value) > options.maximum) {
            return options.message;
        }
        return '';
    };
    // ここで検証したい文字列と検証項目を渡してる
    const res = validatejs.validate(attributes, constratints, {
        // This will prevent English attribute name prepended into error message.
        fullMessages: false,
    });

    if (!res) {
        return null;
    }

    const out = newValidationOutput();

    // Cast validation errors `string[]` to output `string`.
    Object.keys(res).forEach((key) => (out[key] = res[key][0]));

    return out;
};

export const Validate = <T>(attributes: T, constraints: Constraints, newValidationOutput: () => ValidationOutput): ValidationOutput | null => {
    return validate(
        attributes,
        constraints,
        newValidationOutput,
        // The value is guaranteed not to be null or undefined but otherwise it
        // could be anything.
        (value: string, options: any) => {
            // parseFunction
            return moment.utc(value);
        },
        // Input is a unix timestamp
        (value: string, options: any) => {
            // formatFunction
            const format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
            return moment.utc(value).format(format);
        },
    );
};

export const ValidateDateExplicitly = <T>(
    attributes: T,
    constraints: Constraints,
    newValidationOutput: () => ValidationOutput,
    parseFunction: (value: string, options: any) => moment.Moment,
    formatFunction: (value: string, options: any) => string,
): ValidationOutput | null => {
    return validate(attributes, constraints, newValidationOutput, parseFunction, formatFunction);
};

// 環境依存文字の入力制限
export const inputLimit = (str: string) => {
    // バリデーション結果
    let result = false;
    if (!str) {
        return result;
    }

    // 入力禁止のunicodeを指定
    const ranges = [
        // 絵文字指定されている文字コード
        // 参考：https://ichi.pro/javascript-no-emoji-271114725718523
        '[\u2700-\u27bf]',
        '(?:\ud83c[\udde6-\uddff]){2}',
        '[\ud800-\udbff][\udc00-\udfff]',
        '[\u0023-\u0039]\ufe0f?\u20e3',
        '\u3299',
        '\u3297',
        '\u303d',
        '\u3030',
        '\u24c2',
        '\ud83c[\udd70-\udd71]',
        '\ud83c[\udd7e-\udd7f]',
        '\ud83c\udd8e',
        '\ud83c[\udd91-\udd9a]',
        '\ud83c[\udde6-\uddff]',
        '\ud83c\ude1a',
        '\ud83c\ude2f',
        '\u203c',
        '\u2049',
        '[\u25aa-\u25ab]',
        '\u25b6',
        '\u25c0',
        '[\u25fb-\u25fe]',
        '\u00a9',
        '\u00ae',
        '\u2122',
        '\u2139',
        '\ud83c\udc04',
        '[\u2600-\u26FF]',
        '\u2b05',
        '\u2b06',
        '\u2b07',
        '\u2b1b',
        '\u2b1c',
        '\u2b50',
        '\u2b55',
        '\u231a',
        '\u231b',
        '\u2328',
        '\u23cf',
        '[\u23e9-\u23f3]',
        '[\u23f8-\u23fa]',
        '\ud83c\udccf',
        '\u2934',
        '\u2935',
        '[\u2190-\u21ff]',
        // 代表的な環境依存文字コード
        // 参考：http://charset.7jp.net/tokusyumoji.html
        '[\u2105-\u2179]',
        '[\u2194-\u21b9]',
        '\u21e7',
        '\u2206',
        '\u220f',
        '\u2211',
        '\u2215',
        '\u2219',
        '\u221f',
        '\u2223',
        '\u2225',
        '\u222e',
        '[\u2236-\u223c]',
        '\u2248',
        '\u224c',
        '\u2264',
        '\u2265',
        '\u226e',
        '\u226f',
        '\u2295',
        '\u2299',
        '\u22bf',
        '\u2310',
        '[\u2320-\u24ea]',
        '[\u2504-\u250b]',
        '\u250d',
        '\u250e',
        '\u2511',
        '\u2512',
        '\u2515',
        '\u2516',
        '\u2519',
        '\u251a',
        '\u251e',
        '\u251f',
        '\u2521',
        '\u2522',
        '\u2526',
        '\u2527',
        '\u2529',
        '\u252a',
        '\u252d',
        '\u252e',
        '\u2531',
        '\u2532',
        '\u2535',
        '\u2536',
        '\u2539',
        '\u253a',
        '\u253d',
        '\u253e',
        '\u2540',
        '\u2541',
        '[\u2543-\u254a]',
        '[\u2550-\u2595]',
        '[\u25a3-\u25ac]',
        '[\u25b6-\u25ba]',
        '[\u25c0-\u25c4]',
        '\u25c8',
        '\u25ca',
        '[\u25d0-\u25e6]',
        '[\u2609-\u263c]',
        '[\u2660-\u266c]',
        '[\u3280-\u33fe]',
    ];

    const reg = RegExp(ranges.join('|'), 'g');

    // 入力禁止の文字コードと入力された文字のコードが一致でtrue
    if (str.match(reg)) {
        result = true;
    }
    return result;
};

// Covasで使用可能なメールアドレスの検証
// 入力不可ならtrueフラグを返す
export const validateEmailFormat = (value: string) => {
    // azure ad b2cに登録可能なメールアドレスかどうか判定する
    if (!/^([a-zA-Z0-9]+[a-zA-Z0-9.'\-_!#^~]*[a-zA-Z0-9'\-_!#^~]+|[a-zA-Z0-9]+)@[a-zA-Z0-9]+[a-zA-Z0-9.-]*\.[a-zA-Z0-9]+$/.test(value)) {
        return true;
    }
    // 連続した2個以上のドット(..)がある場合
    if (/\.\./.test(value)) {
        return true;
    }
    return false;
};

export const timeCombinationFalse = (year: string, month: string, day: string, time: string) => {
    if (year.toLowerCase() === 'false' && month.toLowerCase() === 'false' && day.toLowerCase() === 'false' && time.toLowerCase() === 'false') {
        return true;
    }
    return false;
};
