import List from './List';
import Edit from './Edit';
import filename from './filename';
import scan from './scan';
import editCloud from '../common/endpoints/edit-cloud';

export default {
    List,
    Edit,
    filename,
    scan,
    editCloud,
};
