import * as React from 'react';
import { Button, createStyles, Theme, WithStyles, withStyles, TextField, Grid, InputAdornment, IconButton } from '@material-ui/core';
import * as models from '@/user/models/cloud-connection/cloud-connection';
import locale from '@/common/utils/locale';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import * as errorHandler from '@/common/utils/errorHandler';
import { __RouterContext } from 'react-router';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import routes from '@/user/constants/routes';
import queryString from 'query-string';

// Eメールからの依頼と/cloud-connectionでの認可を分岐させる。
// 分岐によってリダイレクト先が異なる
const isOrderAuth = () => {
    const query = queryString.parse(document.location.search);
    const order = query.order;
    if (order === 'true') {
        return true;
    }
    return false;
};

const styles = (theme: Theme) =>
    createStyles({
        text: {
            margin: '11px',
            maxWidth: '30%',
            minWidth: '328px',
        },
        button: {
            color: 'white',
            margin: theme.spacing.unit,
            maxWidth: '20%',
            minWidth: '240px',
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const [user, setUser] = React.useState('');
    const [password, setPassword] = React.useState('');
    const appContainer = UserAppContainer.useContainer();
    const routerContext = React.useContext(__RouterContext);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleChangeUser = (e: any) => {
        setUser(e.target.value);
    };

    const handleChangePass = (e: any) => {
        setPassword(e.target.value);
    };

    const isDisable = () => {
        if (!user || !password) {
            return true;
        }
        return false;
    };

    const handleClick = async () => {
        try {
            const response = await models.generatorAccessKey({ user, password }, appContainer.values.authorizationCode);
            if (!response) {
                throw new Error();
            }
            const query = encodeURIComponent(`${user}:${response.accessKey}`);
            if (isOrderAuth()) {
                routerContext.history.push(`${routes.cloudConnection.authRequest}?code=${query}`);
            } else {
                routerContext.history.push(`${routes.cloudConnection.index}?code=${query}`);
            }
        } catch (e) {
            appContainer.setLoadingState(false);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    return (
        <>
            <Grid container spacing={24}>
                <Grid item xs={12} sm={12}>
                    <h3>{locale.t(locale.keys.cloudConnection.modal.docabTitle)}</h3>
                    <div>
                        <TextField
                            className={classes.text}
                            id="email"
                            label={locale.t(locale.keys.common.email)}
                            placeholder=""
                            margin="normal"
                            variant="filled"
                            value={user}
                            onChange={handleChangeUser}
                            // error={handleValidateEmail() !== ''}
                            // helperText={handleValidateEmail()}
                        />
                    </div>
                    <div>
                        <TextField
                            className={classes.text}
                            type={showPassword ? 'text' : 'password'}
                            label={locale.t(locale.keys.common.password)}
                            variant="filled"
                            value={password}
                            onChange={handleChangePass}
                            // error={handleValidatePass() !== ''}
                            // helperText={handleValidatePass()}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="Toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    handleClick();
                                    ev.preventDefault();
                                }
                            }}
                        />
                    </div>
                    <div>
                        <Button disabled={isDisable()} variant="contained" fullWidth onClick={handleClick} className={classes.button}>
                            {locale.t(locale.keys.cloudConnection.modal.button.authorize)}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles)(Component);
