// 操作対象選択モーダルコンポーネント
import { default as React, useEffect } from 'react';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles, Button, CardActions, CardContent } from '@material-ui/core';
import FolderSelect from '@/user/components/common/endpoints/folders/FolderSelect';
import * as schema from '@/bundles/schema/typescript/schema';
import ChooseName from '@/user/components/common/endpoints/choose-name/ChooseName';
import { UserAppContainer } from '@/user/components/UserAppContainer';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing.unit * 5,
            display: 'flex',
            flexDirection: 'column',
        },
        content: {
            width: '25%',
            margin: '0 auto',
            [theme.breakpoints.down('lg')]: {
                width: '30%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '50%',
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        button: {
            minWidth: 196,
            maxWidth: 246,
            height: 44,
            margin: `0 auto`,
        },
    });

interface Props extends WithStyles<typeof styles> {
    ui?: UI;
    skipEffect?: boolean;
    closeModal: () => void;
    id: string;
    endpointType: schema.V1ObjectsServiceEnum;
    cloudAcc?: string;
    path?: string;
    pathSelect?: (pth: string, pathId?: string) => void;
    pathId?: string;
}

interface IPath {
    path: string;
    pathId?: string;
}

export const Component: React.SFC<Props> = (props: Props) => {
    const appContainer = UserAppContainer.useContainer();
    const defaultData: IPath = {
        path: '',
    };
    const [pathState, setPathState] = React.useState(defaultData);
    const getPath = (pth: string, id?: string) => {
        setPathState({ path: pth, pathId: id });
    };
    const ui = useUI(props.ui);
    const pathOnClick = () => {
        if (typeof props.pathSelect === 'function') {
            props.pathSelect(pathState.path, pathState.pathId);
            props.closeModal();
        }
    };

    useEffect(() => {
        if (props.skipEffect) {
            return;
        }
        ui.update(UI.Loaded);
    }, [props.skipEffect, ui]);

    const getOperationObject = (service: schema.V1ObjectsServiceEnum) => {
        if (schema.V1ObjectsServiceEnum.Docard === service) {
            return <ChooseName id={props.id} cloudAcc={props.cloudAcc} endpointType={props.endpointType} path={props.path} pathSelect={getPath} />;
        }
        return <FolderSelect id={props.id} cloudAcc={props.cloudAcc} endpointType={props.endpointType} path={props.path} pathSelect={getPath} pathId={props.pathId} />;
    };

    const disabledButtonSelect = () => {
        if (!pathState.path) {
            return true;
        }
        let pathArray = pathState.path.split('/');
        pathArray = pathArray.filter((item) => item !== '');
        if ((!pathArray || pathArray.length < 2) && props.endpointType === schema.V1ObjectsServiceEnum.Sharepointonline) {
            return true;
        }
        if (appContainer.loadingState) {
            return true;
        }
        return false;
    };

    return (
        <>
            {ui.current === UI.Loading && <div data-testid={UI.Loading}>Loading</div>}

            {ui.current === UI.Loaded && (
                <div className={props.classes.root} data-testid={UI.Loaded}>
                    <CardContent className={props.classes.content}>{getOperationObject(props.endpointType)}</CardContent>
                    <CardActions>
                        <Button className={props.classes.button} fullWidth variant="contained" disabled={disabledButtonSelect()} onClick={pathOnClick}>
                            {locale.t(locale.keys.endpoint.button.selectFolder)}
                        </Button>
                    </CardActions>
                </div>
            )}

            {ui.current === UI.Error && <div data-testid={UI.Error}>Error</div>}
        </>
    );
};

export default withStyles(styles)(Component);
