import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const update = async (body: schema.V1EndpointsOrdersPatchRequest, auth: string): Promise<schema.V1EndpointsOrdersPatchResponse> => {
    const response = await axiosFactory.patch<schema.V1EndpointsOrdersPatchResponse>(`https://${consts.environment.api.hostname}/v1/endpoints/orders`, body, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
