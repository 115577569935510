import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import querystring from 'querystring';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

/**
 * クラウド接続の取得
 */
export const indexXStorage = async (skipDecryption: boolean, auth: string, share: number, userSub?: string, workspaceId?: string): Promise<schema.V1XStoragesIndexResponse> => {
    if (!userSub && !workspaceId) {
        throw new Error('Invalid params');
    }
    let query = '';
    query = querystring.stringify({
        skipDecryption,
        userSub,
        workspaceId,
        share,
        status: schema.V1ObjectsXStorageStatus.Success,
    });

    const response = await axiosFactory.get<schema.V1XStoragesIndexResponse>(`https://${consts.environment.api.hostname}/v1/x-storages?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
