import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Grid, FormControl, Switch, FormControlLabel } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import ApplicationCheckList from './ApplicationCheckList';
import BaseRole from '@/common/constants/userRole';

const styles = (theme: Theme) => createStyles({});

export interface Props extends WithStyles<typeof styles> {
    isShared: boolean;
    handleEnablePermisson: (enablePermission: { userGroupPermission: boolean; applicationPermission: boolean; mfpPermission: boolean }) => void;
    enablePermission: { userGroupPermission: boolean; applicationPermission: boolean; mfpPermission: boolean };
    applicationPermission: { scan: boolean; fax: boolean };
    handleApplicationPermission: (applicationPermission: { scan: boolean; fax: boolean }) => void;
    userRole?: number;
}

const EnablePermission: React.FC<Props> = (props: Props) => {
    return (
        <Grid container direction="column">
            {props.isShared ? (
                <Grid item>
                    <FormControl>
                        <FormControlLabel
                            onChange={(_, checked) => {
                                props.handleEnablePermisson({ ...props.enablePermission, userGroupPermission: checked });
                            }}
                            control={<Switch checked={props.enablePermission.userGroupPermission} />}
                            label={locale.t(locale.keys.endpointsPermission.userGroupPermission.permissionSwitch)}
                            disabled={props.userRole ? props.userRole > BaseRole.externalAdmin : false}
                        />
                    </FormControl>
                </Grid>
            ) : (
                <Grid item>
                    <FormControl>
                        <FormControlLabel
                            onChange={(_, checked) => {
                                props.handleEnablePermisson({ ...props.enablePermission, applicationPermission: checked });
                            }}
                            control={<Switch checked={props.enablePermission.applicationPermission} />}
                            label={locale.t(locale.keys.endpointsPermission.applicationPermission.permissionSwitch)}
                            disabled={props.userRole ? props.userRole > BaseRole.externalAdmin : false}
                        />
                    </FormControl>
                    <Grid item>
                        {props.enablePermission.applicationPermission && (
                            <ApplicationCheckList applicationPermission={props.applicationPermission} handleApplicationPermission={props.handleApplicationPermission} />
                        )}
                    </Grid>
                </Grid>
            )}
            <Grid item>
                <FormControl>
                    <FormControlLabel
                        onChange={(_, checked) => {
                            props.handleEnablePermisson({ ...props.enablePermission, mfpPermission: checked });
                        }}
                        control={<Switch checked={props.enablePermission.mfpPermission} />}
                        label={locale.t(locale.keys.endpointsPermission.mfpPermission.permissionSwitch)}
                        disabled={props.userRole ? props.userRole > BaseRole.externalAdmin : false}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(EnablePermission);
