import { default as React } from 'react';
import { Theme, WithStyles, withStyles, Avatar, createStyles, Grid, Card, Tooltip, IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { default as imgs } from '@/common/img/mfp/index';
import { Link } from 'react-router-dom';
import * as schema from '@/bundles/schema/typescript/schema';
import * as webappUtil from '@/common/utils/webappUtil';
import { listBrightBtnColor } from '@/user/constants/endpoint-setting';
import WarningPopover from '@/user/components/common/WarningPopover';

const styles = (theme: Theme) =>
    createStyles({
        endpointContainer: {
            width: 'calc(100% - 48px)',
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
        },
        imgBackground: {
            marginLeft: 8,
            color: '#fff',
            borderRadius: 0,
            width: theme.spacing.unit * 7,
            height: theme.spacing.unit * 7,
            border: 'solid 1px #dddddd',
        },
        endpointLabel: {
            fontSize: '16px',
            color: '#333333',
            fontWeight: 'bold',
            textAlign: 'left',
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'pre' /**空白は詰めない */,
            paddingLeft: 14,
        },
        imgStyle: {
            width: 'inherit',
            height: 'inherit',
            padding: theme.spacing.unit * 1.5,
        },
        popper: {
            opacity: 1,
        },
        lightTooltip: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.bodyText.main,
            boxShadow: theme.shadows[1],
            fontSize: 12,
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap' /**空白は詰めない(改行は行う) */,
        },
        root: {
            width: '98%',
            height: '80px',
            marginBlock: `${theme.spacing.unit}px`,
            display: 'flex',
            alignItems: 'center',
        },
        itemRoot: {
            height: '100%',
        },
        iconContainer: {
            width: theme.spacing.unit * 8,
        },
        nameContainer: {
            width: 'auto',
            overflow: 'hidden',
            flexGrow: 1,
        },
        warningIconContainer: {
            width: 18,
        },
        moreIconContainer: {
            width: 48,
        },
    });

export type EndpointMenuObject = {
    name: string;
    action: () => void;
    disabled: boolean;
};

interface Props extends WithStyles<typeof styles> {
    editPath: string;
    value: schema.V1ObjectsEndpointInfo;
    endpointMenuObjects?: EndpointMenuObject[];
    isShared?: boolean;
}

const Endpoint: React.FC<Props> = (props) => {
    const { classes, editPath, value, endpointMenuObjects } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        event.preventDefault();
    };

    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(null);
        event.preventDefault();
    };

    const handleCloseAndAction = (event: React.MouseEvent<HTMLButtonElement>, action: () => void) => {
        action();
        setAnchorEl(null);
        event.preventDefault();
    };

    const name = value.iconName.split('.')[0];
    const color = webappUtil.getEnumKey(value.buttonColor, schema.V1ObjectsEndpointsButtonColor);
    const backgroundColor = { backgroundColor: color };
    let imgStyle = {};
    if (!listBrightBtnColor.includes(color)) {
        imgStyle = { filter: 'invert(1)' };
    }

    let iconWarning: JSX.Element | null = null;

    if (value.isDisable || !value.isStorageAvailable) {
        iconWarning = (
            <WarningPopover
                xStorageAccFail={value.cloudAccount ? (value.cloudAccount.length > 30 ? value.cloudAccount.slice(0, 30) + '...' : value.cloudAccount) : value.cloudAccount}
                listScanExFail={value.extensionScanFails}
                listPrintExFail={value.extensionPrintFails}
                isShared={props.isShared}
            />
        );
    }
    return (
        <Card className={classes.root}>
            <Grid item container wrap="nowrap" className={classes.itemRoot}>
                <Link className={classes.endpointContainer} to={editPath}>
                    <Grid item container wrap="nowrap">
                        <Grid item className={classes.iconContainer}>
                            <Avatar className={classes.imgBackground} style={backgroundColor}>
                                <img alt="img" src={imgs.iconMfps[name]} className={classes.imgStyle} style={imgStyle} />
                            </Avatar>
                        </Grid>
                        <Grid item container className={classes.nameContainer} alignItems="center">
                            <Tooltip title={value.name} classes={{ tooltip: props.classes.lightTooltip, popper: props.classes.popper }}>
                                <span className={classes.endpointLabel}>{value.name}</span>
                            </Tooltip>
                        </Grid>
                        {iconWarning && (
                            <Grid item container className={classes.warningIconContainer} alignItems="center" justify="flex-end">
                                {iconWarning}
                            </Grid>
                        )}
                    </Grid>
                </Link>
                {endpointMenuObjects && (
                    <Grid item container className={classes.moreIconContainer} alignItems="center" justify="center">
                        <IconButton onClick={handleClick}>
                            <MoreVert />
                        </IconButton>
                        <Menu anchorEl={anchorEl} disableAutoFocusItem open={Boolean(anchorEl)} onClose={handleClose}>
                            {endpointMenuObjects.map((endpointMenuObject, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleCloseAndAction(event, endpointMenuObject.action)}
                                        disabled={endpointMenuObject.disabled}
                                    >
                                        {endpointMenuObject.name}
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    </Grid>
                )}
            </Grid>
        </Card>
    );
};

export default withStyles(styles)(React.memo(Endpoint));
