import React, { useState, useEffect } from 'react';
import { Grid, Typography, createStyles, withStyles, WithStyles, Theme, Button } from '@material-ui/core';
import { ColorLinearProgress } from '@/common/components/ui-state/LoadingState';
import locale from '@/common/utils/locale';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { getSplitNewLineString } from '@/common/utils/webappUtil';
import { unsubscribeEndpointNotification } from '@/common/api/endpoints/unsubscribe';
import axios, { AxiosError } from 'axios';
import * as schema from '@/bundles/schema/typescript/schema';
import useTitle from '@/common/components/hooks/useTitle';
import environment from '@/common/constants/environment';
import { keys } from '@/common/utils/authManagement/localStorageConstants';
import * as errorLocale from '@/common/utils/locale/error-locale';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            gap: '8px',
            height: '100vh',
        },
        body: {
            display: 'flex',
            gap: '8px',
            width: '90%',
            height: '90vh',
            marginRight: '5%',
            marginLeft: '5%',
        },
        logo: {
            width: '100%',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
        },
        title: {
            fontSize: 24,
            fontWeight: 'bold',
        },
        bodyText: {
            fontSize: 16,
            fontWeight: 'normal',
        },
        footer: {
            display: 'flex',
            justifyContent: 'right',
            flexGrow: 1,
            paddingBottom: '5vh',
            alignItems: 'end',
            gap: '8px',
        },
    });

interface Props extends WithStyles<typeof styles> {}

const UnsubscribePage: React.FC<Props> = (props) => {
    const [message, setMessage] = useState({
        success: null as null | schema.V1EndpointsUnsubscribeCreateResponse,
        error: null as null | schema.V1ErrorsErrorResponse,
    });
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const lang = window.localStorage.getItem(keys.lang);
    useTitle(locale.t(locale.keys.unsubscribe.index));

    useEffect(() => {
        (async () => {
            try {
                const parsedQuery = parse(location.search);
                if (parsedQuery.code && typeof parsedQuery.code === 'string') {
                    const res = await unsubscribeEndpointNotification(parsedQuery.code);
                    setMessage({
                        success: res,
                        error: null,
                    });
                } else {
                    throw new Error('Invalid query');
                }
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    const axiosError = e as AxiosError<schema.V1ErrorsErrorResponse>;
                    if (axiosError.response) {
                        setMessage({
                            success: null,
                            error: {
                                ...axiosError.response.data,
                                error: {
                                    ...axiosError.response.data.error,
                                    message: errorLocale.translate(errorLocale.keys.E08233, undefined, lang ? lang : schema.Language.Ja).message,
                                },
                            },
                        });
                    } else {
                        // APIから処理できないエラーは不明なエラーとして表示
                        setMessage({
                            success: null,
                            error: {
                                error_summary: '',
                                error: {
                                    ...errorLocale.translate(errorLocale.keys.E08233, undefined, lang ? lang : schema.Language.Ja),
                                    requestId: '',
                                    resource: '',
                                },
                            },
                        });
                    }
                } else {
                    // APIから処理できないエラーは不明なエラーとして表示
                    setMessage({
                        success: null,
                        error: {
                            error_summary: '',
                            error: {
                                ...errorLocale.translate(errorLocale.keys.E08233, undefined, lang ? lang : schema.Language.Ja),
                                requestId: '',
                                resource: '',
                            },
                        },
                    });
                }
            }
            setIsLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setMultiLineText = (str: string): JSX.Element[] => {
        const splite = getSplitNewLineString(str);
        const components: JSX.Element[] = [];
        for (let text of splite) {
            components.push(<Typography className={props.classes.bodyText}>{text}</Typography>);
        }
        return components;
    };

    return (
        <Grid className={props.classes.root}>
            <Grid item className={props.classes.logo}>
                <img alt="header-logo" width="100%" height="100%" src="/assets/images/logo/brand.svg" />
            </Grid>
            {isLoading ? (
                <Grid data-testid={'loading'}>
                    <ColorLinearProgress />
                </Grid>
            ) : message.success !== null ? (
                // メール停止成功時の画面
                <Grid container direction="column" className={props.classes.body}>
                    <Grid item>
                        <Typography className={props.classes.title}>{locale.t(locale.keys.unsubscribe.success.subTitle)}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={props.classes.bodyText}>{locale.t(locale.keys.unsubscribe.success.description)}</Typography>
                        <table>
                            <tr className={props.classes.bodyText}>
                                <td style={{ minWidth: 150, verticalAlign: 'top' }}>
                                    {'・'}
                                    {message.success.isShared ? locale.t(locale.keys.common.model.endpoint.shared) : locale.t(locale.keys.common.model.endpoint.individual)}
                                </td>
                                <td style={{ verticalAlign: 'top' }}>:</td>
                                <td style={{ verticalAlign: 'top' }}>
                                    <a href={`https://${environment.webapp.user.hostname}/endpoints/${message.success.endpointId}/edit`}>{message.success.endpointName}</a>
                                </td>
                            </tr>
                            <tr className={props.classes.bodyText}>
                                <td style={{ minWidth: 150, verticalAlign: 'top' }}>
                                    {'・'}
                                    {locale.t(locale.keys.unsubscribe.success.type.index)}
                                </td>
                                <td style={{ verticalAlign: 'top' }}>:</td>
                                <td style={{ verticalAlign: 'top' }}>
                                    {message.success.endpointMailType === schema.EndpointMailType.ServiceComplete
                                        ? locale.t(locale.keys.unsubscribe.success.type.complete)
                                        : locale.t(locale.keys.unsubscribe.success.type.error)}
                                </td>
                            </tr>
                        </table>
                        {setMultiLineText(
                            locale.t(locale.keys.unsubscribe.success.description2, {
                                endpointType: message.success.isShared ? locale.t(locale.keys.common.model.endpoint.shared) : locale.t(locale.keys.common.model.endpoint.individual),
                            }),
                        )}
                        <a
                            className={props.classes.bodyText}
                            href={
                                message.success.isShared
                                    ? `${locale.t(locale.keys.pageTitle.manual.app.url)}topic/sharedendpointdetail`
                                    : `${locale.t(locale.keys.pageTitle.manual.app.url)}topic/endpointdetail`
                            }
                        >
                            {message.success.isShared
                                ? `${locale.t(locale.keys.pageTitle.manual.app.url)}topic/sharedendpointdetail`
                                : `${locale.t(locale.keys.pageTitle.manual.app.url)}topic/endpointdetail`}
                        </a>
                    </Grid>
                    <Grid item className={props.classes.footer}>
                        <Button
                            variant="outlined"
                            style={{
                                color: 'gray',
                                borderColor: 'gray',
                            }}
                            onClick={() => {
                                window.close();
                            }}
                        >
                            {locale.t(locale.keys.action.close)}
                        </Button>
                    </Grid>
                </Grid>
            ) : message.error !== null ? (
                // メール停止失敗時の画面
                <Grid container direction="column" className={props.classes.body}>
                    <Grid item>
                        <Typography className={props.classes.title}>{locale.t(locale.keys.unsubscribe.error.subTitle)}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={props.classes.bodyText}>{message.error.error.message}</Typography>
                        <Typography className={props.classes.bodyText}>{locale.t(locale.keys.unsubscribe.error.description)}</Typography>
                        <table>
                            <tr className={props.classes.bodyText}>
                                <td style={{ minWidth: 200, verticalAlign: 'top' }}>
                                    {'・'}
                                    {locale.t(locale.keys.unsubscribe.error.manualLink, { endpointType: locale.t(locale.keys.common.model.endpoint.individual) })}
                                </td>
                                <td style={{ verticalAlign: 'top' }}>:</td>
                                <td style={{ verticalAlign: 'top' }}>
                                    <a href={`${locale.t(locale.keys.pageTitle.manual.app.url)}topic/endpointdetail`}>{`${locale.t(locale.keys.pageTitle.manual.app.url)}topic/endpointdetail`}</a>
                                </td>
                            </tr>
                            <tr className={props.classes.bodyText}>
                                <td style={{ minWidth: 200, verticalAlign: 'top' }}>
                                    {'・'}
                                    {locale.t(locale.keys.unsubscribe.error.manualLink, { endpointType: locale.t(locale.keys.common.model.endpoint.shared) })}
                                </td>
                                <td style={{ verticalAlign: 'top' }}>:</td>
                                <td style={{ verticalAlign: 'top' }}>
                                    <a href={`${locale.t(locale.keys.pageTitle.manual.app.url)}topic/sharedendpointdetail`}>{`${locale.t(
                                        locale.keys.pageTitle.manual.app.url,
                                    )}topic/sharedendpointdetail`}</a>
                                </td>
                            </tr>
                        </table>
                    </Grid>
                    <Grid item className={props.classes.footer}>
                        <Button
                            variant="outlined"
                            style={{
                                color: 'gray',
                                borderColor: 'gray',
                            }}
                            onClick={() => {
                                window.close();
                            }}
                        >
                            {locale.t(locale.keys.action.close)}
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                // 不明な理由でメール停止失敗時の画面
                <Grid container direction="column" className={props.classes.body}>
                    <Grid item>
                        <Typography className={props.classes.title}>{locale.t(locale.keys.unsubscribe.error.subTitle)}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={props.classes.bodyText}>{locale.t(locale.keys.unsubscribe.error.cause.unknown)}</Typography>
                        <Typography className={props.classes.bodyText}>{locale.t(locale.keys.unsubscribe.error.description)}</Typography>
                        <ul>
                            <li className={props.classes.bodyText}>
                                {locale.t(locale.keys.unsubscribe.error.manualLink, { endpointType: locale.t(locale.keys.common.model.endpoint.individual) })}
                                {'('}
                                <a href={`${locale.t(locale.keys.pageTitle.manual.app.url)}topic/endpointdetail`}>{`${locale.t(locale.keys.pageTitle.manual.app.url)}topic/endpointdetail`}</a>
                                {')'}
                            </li>
                            <li className={props.classes.bodyText}>
                                {locale.t(locale.keys.unsubscribe.error.manualLink, { endpointType: locale.t(locale.keys.common.model.endpoint.shared) })}
                                {'('}
                                <a href={`${locale.t(locale.keys.pageTitle.manual.app.url)}topic/sharedendpointdetail`}>{`${locale.t(
                                    locale.keys.pageTitle.manual.app.url,
                                )}topic/sharedendpointdetail`}</a>
                                {')'}
                            </li>
                        </ul>
                    </Grid>
                    <Grid item className={props.classes.footer}>
                        <Button
                            variant="outlined"
                            style={{
                                color: 'gray',
                                borderColor: 'gray',
                            }}
                            onClick={() => {
                                window.close();
                            }}
                        >
                            {locale.t(locale.keys.action.close)}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default withStyles(styles)(UnsubscribePage);
