import * as React from 'react';
import { Grid, Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { ChangeContainer } from './ChangeContainer';
import Change from './Change';

const styles = (theme: Theme) =>
    createStyles({
        link: {
            color: '#0d47a1',
        },
        button: {
            '&& span': {
                fontSize: '1.25rem',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('lg')]: {
                width: 'auto',
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    onTitle: () => void;
}

export const Component: React.FC<Props> = (props) => {
    return (
        <>
            <ChangeContainer.Provider>
                <Grid container>
                    <Grid item lg={3} />
                    <Grid item xs={12} lg={6}>
                        <Change />
                    </Grid>
                    <Grid item lg={3} />
                </Grid>
            </ChangeContainer.Provider>
        </>
    );
};

export default withStyles(styles)(Component);
