import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { default as UI } from '@/common/constants/ui';
import * as schema from '@/bundles/schema/typescript/schema';
import locale from '@/common/utils/locale';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import { indexXStorage } from '@/user/models/x-storages/x-storages';
import * as auth from '@/user/models/auth';
import * as endpoints from '@/user/models/endpoints/endpoints';

const storagesDefault = {
    xstorages: [
        {
            xstorageId: '',
            authorizationId: '',
            authorizationDeadline: '',
            authorizationLink: '',
            imageLink: '',
            service: schema.V1ObjectsServiceEnum.Box,
            status: schema.V1ObjectsXStorageStatus.Success,
        },
    ],
};

const useEditCloudContainer = () => {
    const [ui, setUI] = useState(UI.state.Loading);
    const appContainer = UserAppContainer.useContainer();
    const [endpoint, setEndpoint] = useState<schema.V1ObjectsEndpointInfo>();
    const [docName, setDocName] = useState<schema.V1ObjectsEndpointsDefaultSettingDocNameFormat>();
    const [wordbook, setWordBook] = useState<string[]>();
    const [wordbook02, setWordBook02] = useState<string[]>();
    const [path, setPath] = useState<string>();
    const [pathName, setPathName] = useState<string>('');
    const [activeStorage, setActiveStorage] = useState<schema.V1ObjectsXStorage>();
    const [emails, setEmails] = useState<string[]>([]);
    const [tmpPathName, setTmpPathName] = useState<string>('');
    const [bodyMail, setBodyMail] = useState(locale.t(locale.keys.endpoint.emailXStorage.defaultTemplate));
    const [storageList, setStorageList] = useState<schema.V1XStoragesIndexResponse>(storagesDefault);
    const [upDownFeature, setFeature] = useState({
        isUploadable: false,
        isDownloadable: false,
    });
    const sub = auth.identifyUser()!.sub;
    const [, setSubject] = useState(locale.t(locale.keys.endpoint.emailXStorage.defaultSubject));
    const [, setFileMaxSize] = useState(10);
    const getPath = (path: string, idPath?: string) => {
        setPathName(path);
        setPath(path);
        setIdPath(idPath || '');
    };
    const [idPath, setIdPath] = useState('');
    /**
     * エンドポイント取得
     * @param id
     */
    const getEndpoint = async (id: string) => {
        try {
            const data = await endpoints.showEndpointById(id, appContainer.values.authorizationCode);
            if (data.endpoint) setEndpoint(data.endpoint);
            if (data.docNameFormat) setDocName(data.docNameFormat);
            if (data.wordListData) setWordBook(data.wordListData);
            if (data.wordListData02) setWordBook02(data.wordListData02);
        } catch (err) {
            throw err;
        }
    };
    /**
     * クラウドストレージリスト取得
     * @returns
     */
    const getStoragesList = async (share: number) => {
        try {
            const data = await indexXStorage(true, appContainer.values.authorizationCode, share, sub, appContainer.values.signinWorkspaceObject.id);
            if (!data) {
                throw new Error('not found xStorage');
            }
            const noneCloudExists = data.xstorages.find((item) => item.service === schema.V1ObjectsServiceEnum.Nonecloud);
            if (!noneCloudExists) {
                const item: schema.V1ObjectsXStorage = {
                    xstorageId: schema.V1ObjectsServiceEnum.Nonecloud,
                    authorizationDeadline: '',
                    authorizationLink: '',
                    service: schema.V1ObjectsServiceEnum.Nonecloud,
                    status: schema.V1ObjectsXStorageStatus.Success,
                    isDownloadable: false,
                    isUploadable: true,
                    is2L: false,
                };
                data.xstorages.push(item);
            }
            setStorageList(JSON.parse(JSON.stringify(data)));
            setActiveStorage(data.xstorages[0]);
            setFeature({
                isUploadable: data.xstorages[0].isUploadable!,
                isDownloadable: data.xstorages[0].isDownloadable!,
            });
        } catch (err) {
            throw err;
        }
    };
    const updateCloudSetting = async (id: string, share: number) => {
        try {
            if (endpoint && activeStorage && docName) {
                let folderPath = path || '/';
                let workData = wordbook || [];
                let workData02 = wordbook02 || [];
                if (activeStorage && schema.V1ObjectsServiceEnum.Nonecloud === activeStorage.service) {
                    folderPath = '/';
                    workData = [];
                    workData02 = [];
                    docName.fixed = [];
                    docName.itemList = '';
                    docName.separator = '';
                    docName.fileOptional = false;
                    docName.sequenceType = 0;
                }
                if (activeStorage && schema.V1ObjectsServiceEnum.Docard === activeStorage.service) {
                    workData = [];
                    workData02 = [];
                    docName.fixed = [];
                    docName.itemList = '';
                    docName.separator = '';
                    docName.fileOptional = false;
                }
                const request: schema.V1EndpointsCloudSettingUpdateRequest = {
                    docNameFormat: docName,
                    endpoint: {
                        share,
                        xStorage: activeStorage.xstorageId,
                        uploadable: upDownFeature.isUploadable,
                        downloadable: upDownFeature.isDownloadable,
                        path: folderPath,
                        pathId: idPath,
                    },
                    wordListData: workData,
                    wordListData02: workData02,
                };

                return await endpoints.changeCloudSetting(id, request, appContainer.values.authorizationCode);
            }
            return false;
        } catch (err) {
            throw err;
        }
    };
    /**
     * M365のサイトとライブラリが選択されていない時はメッセージ表示
     * @param step
     * @returns
     */
    const m365ErrorMessage = (step: number) => {
        if (activeStorage && activeStorage.service === schema.V1ObjectsServiceEnum.Sharepointonline && step === 1) {
            if (!path) return locale.t(locale.keys.createEnpoint.m365SiteRequiredDialog);
            let pathArray = path.split('/');
            pathArray = pathArray.filter((item: string) => item !== '');
            if (pathArray.length < 1) {
                return locale.t(locale.keys.createEnpoint.m365SiteRequiredDialog);
            }
            if (pathArray.length < 2) {
                return locale.t(locale.keys.createEnpoint.m365LibRequiredDialog);
            }
        }
        return '';
    };

    /**
     * 保存ボタンの非活性判定
     * @param step
     * @returns
     */
    const isDisabled = (step: number) => {
        switch (step) {
            case 0: {
                if (activeStorage) return false;
                return true;
            }
            case 1: {
                if (path && activeStorage && activeStorage.service === schema.V1ObjectsServiceEnum.Docard) return false;
                if (appContainer.loadingState) return true;
                // let pathArray = path.split('/');
                // pathArray = pathArray.filter((item: string) => item !== '');
                // if ((!pathArray || pathArray.length < 2) && activeStorage.service === schema.V1ObjectsServiceEnum.Sharepointonline) {
                //     return true;
                // }
                if (activeStorage && activeStorage.service === schema.V1ObjectsServiceEnum.Email && emails.length !== 0 && bodyMail) {
                    return false;
                }
                if (path && activeStorage && activeStorage.service !== schema.V1ObjectsServiceEnum.Email) {
                    return false;
                }
                if (path) return false;
                return true;
            }
            default:
                return true;
        }
    };
    const handleSwitch = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeature({ ...upDownFeature, [name]: event.target.checked });
    };
    const mailList = (mailSetting: schema.V1ObjectsEndpointsMailSetting) => {
        if (mailSetting.address) {
            setEmails(mailSetting.address);
        }
        if (mailSetting.template) {
            setBodyMail(mailSetting.template);
        }
        if (mailSetting.subject) {
            setSubject(mailSetting.subject);
        }
        if (mailSetting.fileMaxSize) {
            setFileMaxSize(mailSetting.fileMaxSize);
        }
    };
    return {
        ui,
        setUI,
        path,
        setPath,
        m365ErrorMessage,
        activeStorage,
        pathName,
        setPathName,
        isDisabled,
        tmpPathName,
        setTmpPathName,
        getStoragesList,
        storageList,
        setActiveStorage,
        setFeature,
        upDownFeature,
        handleSwitch,
        mailList,
        getPath,
        getEndpoint,
        endpoint,
        setEndpoint,
        updateCloudSetting,
        idPath,
    };
};

export const EditCloudContainer = createContainer(useEditCloudContainer);
