import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const showDefaultSetting = async (id: string, auth: string): Promise<schema.V1EndpointsDefaultSettingsShowResponse> => {
    const response = await axiosFactory.get<schema.V1EndpointsDefaultSettingsShowResponse>(`https://${consts.environment.api.hostname}/v1/endpoint/default-settings/${id}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const updateDefaultSetting = async (id: string, req: schema.V1EndpointsDefaultSettingsUpdateRequest, auth: string): Promise<schema.V1EndpointsStatusShowResponse> => {
    const responce = await axiosFactory.put<schema.V1EndpointsStatusShowResponse>(`https://${consts.environment.api.hostname}/v1/endpoint/default-settings/${id}`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return responce.data;
};

export const deleteDefaultSetting = async (id: string, auth: string): Promise<schema.V1EndpointsDestroyResponse> => {
    const response = await axiosFactory.delete<schema.V1EndpointsDestroyResponse>(`https://${consts.environment.api.hostname}/v1/endpoint/default-settings/${id}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
