import { useState } from 'react';
import { createContainer } from 'unstated-next';
import * as schema from '@/bundles/schema/typescript/schema';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as defaultSettingsModel from '@/user/models/endpoints/default-settings/default-settings';
import * as endpointsModel from '@/user/models/endpoints/endpoints';
import locale from '@/common/utils/locale';
import moment from 'moment';
import { UserAppContainer } from '../../UserAppContainer';
import { default as UI } from '@/common/constants/ui';
import * as errorHandler from '@/common/utils/errorHandler';
import { DateFormat } from '@/common/constants/dateFormat';

const responseConvert = (key: string, data: {}) => {
    let response = '';
    const isDynamic = locale.isDynamic(key);
    if (isDynamic) {
        response += `${locale.t(key, data)}`;
    }
    if (!isDynamic) {
        response += `${locale.t(key)}`;
    }
    response += ', ';
    return response;
};

export const isDefined = (value: any) => {
    return value !== undefined && value !== null;
};

export const dynamicLocale = (key: any, data: any, keyObj: any) => {
    if (!isDefined(data[key])) {
        return '';
    }
    if (typeof keyObj[key] === 'string') {
        return responseConvert(keyObj[key], { [key]: data[key] });
    }
    if (typeof keyObj[key] === 'object') {
        for (const t in keyObj[key]) {
            let value = data[key];
            if (typeof data[key] === 'number') {
                value = `type${data[key]}`;
            }
            if (value.toString() !== t) {
                continue;
            }
            return responseConvert(keyObj[key][t], { [t]: value });
        }
    }
    return '';
};

export const scanAttributeList = (scanAttribute: schema.V1ObjectsEndpointsDefaultSettingScanAttributeData) => {
    const scanKeys = JSON.parse(JSON.stringify(locale.keys.scanAttributeData));
    let orderedScanAttribute = '';
    if (isDefined(scanAttribute.aggregation)) orderedScanAttribute += dynamicLocale('aggregation', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.autoDensity)) orderedScanAttribute += dynamicLocale('autoDensity', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.batch)) orderedScanAttribute += dynamicLocale('batch', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.colorType)) orderedScanAttribute += dynamicLocale('colorType', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.density)) orderedScanAttribute += dynamicLocale('density', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.eraseBorder)) orderedScanAttribute += dynamicLocale('eraseBorder', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.eraseBorderWidthBottom)) orderedScanAttribute += dynamicLocale('eraseBorderWidthBottom', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.eraseBorderWidthLeft)) orderedScanAttribute += dynamicLocale('eraseBorderWidthLeft', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.eraseBorderWidthRight)) orderedScanAttribute += dynamicLocale('eraseBorderWidthRight', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.eraseBorderWidthTop)) orderedScanAttribute += dynamicLocale('eraseBorderWidthTop', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.eraseCenterWidth)) orderedScanAttribute += dynamicLocale('eraseCenterWidth', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.eraseBorderWidthSame)) orderedScanAttribute += dynamicLocale('eraseBorderWidthSame', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.eraseCenter)) orderedScanAttribute += dynamicLocale('eraseCenter', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.eraseSettingSame)) orderedScanAttribute += dynamicLocale('eraseSettingSame', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.mixedSize)) orderedScanAttribute += dynamicLocale('mixedSize', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.outputType)) orderedScanAttribute += dynamicLocale('outputType', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.preset1Horizontal)) orderedScanAttribute += dynamicLocale('preset1Horizontal', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.preset1Vertical)) orderedScanAttribute += dynamicLocale('preset1Vertical', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.preset2Horizontal)) orderedScanAttribute += dynamicLocale('preset2Horizontal', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.preset2Vertical)) orderedScanAttribute += dynamicLocale('preset2Vertical', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.preset3Horizontal)) orderedScanAttribute += dynamicLocale('preset3Horizontal', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.preset3Vertical)) orderedScanAttribute += dynamicLocale('preset3Vertical', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.resolution)) orderedScanAttribute += dynamicLocale('resolution', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.rotate)) orderedScanAttribute += dynamicLocale('rotate', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.sadf)) orderedScanAttribute += dynamicLocale('sadf', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.scanPreview)) orderedScanAttribute += dynamicLocale('scanPreview', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.size)) orderedScanAttribute += dynamicLocale('size', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.scanBothSide)) orderedScanAttribute += dynamicLocale('scanBothSide', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.clippedVertical)) orderedScanAttribute += dynamicLocale('clippedVertical', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.resolutionLock)) orderedScanAttribute += dynamicLocale('resolutionLock', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.orgResolutionLock)) orderedScanAttribute += dynamicLocale('orgResolutionLock', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.colorTypeLock)) orderedScanAttribute += dynamicLocale('colorTypeLock', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.orgColorTypeLock)) orderedScanAttribute += dynamicLocale('orgColorTypeLock', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.omitBlankPage)) orderedScanAttribute += dynamicLocale('omitBlankPage', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.documentThickness)) orderedScanAttribute += dynamicLocale('documentThickness', scanAttribute, scanKeys);
    if (isDefined(scanAttribute.mixedSizeMode) && isDefined(scanAttribute.mixedSize) && scanAttribute.mixedSize === true) {
        // 原稿サイズ自動（不定形）の場合はサイズ混載モード（違う幅）として表示する
        // QSで原稿サイズ自動（不定形）の場合はサイズ混載モードの設定を無視して、サイズ混載モード（違う幅）を使用するため
        if (scanAttribute.size === 15) {
            orderedScanAttribute += dynamicLocale('mixedSizeMode', { mixedSizeMode: 1 }, scanKeys);
        } else {
            orderedScanAttribute += dynamicLocale('mixedSizeMode', scanAttribute, scanKeys);
        }
    }
    if (isDefined(scanAttribute.doCutImage)) orderedScanAttribute += dynamicLocale('doCutImage', scanAttribute, scanKeys);

    return orderedScanAttribute;
};

export const pramDataAttributeList = (pramDataAttribute: schema.V1ObjectsEndpointsDefaultSettingParamData) => {
    const paramKeys = JSON.parse(JSON.stringify(locale.keys.paramData));
    let orderedParamData = '';
    if (isDefined(pramDataAttribute.overwrite)) orderedParamData += dynamicLocale('overwrite', pramDataAttribute, paramKeys);
    if (isDefined(pramDataAttribute.scanOverwriteAlert)) orderedParamData += dynamicLocale('scanOverwriteAlert', pramDataAttribute, paramKeys);
    if (isDefined(pramDataAttribute.folderDepth)) orderedParamData += dynamicLocale('folderDepth', pramDataAttribute, paramKeys);
    if (isDefined(pramDataAttribute.autoSelect)) orderedParamData += dynamicLocale('autoSelect', pramDataAttribute, paramKeys);
    if (isDefined(pramDataAttribute.iconVisible)) orderedParamData += dynamicLocale('iconVisible', pramDataAttribute, paramKeys);
    if (isDefined(pramDataAttribute.folderTitle1)) orderedParamData += dynamicLocale('folderTitle1', pramDataAttribute, paramKeys);
    if (isDefined(pramDataAttribute.folderTitle2)) orderedParamData += dynamicLocale('folderTitle2', pramDataAttribute, paramKeys);
    if (isDefined(pramDataAttribute.folderTitle3)) orderedParamData += dynamicLocale('folderTitle3', pramDataAttribute, paramKeys);
    if (isDefined(pramDataAttribute.folderTitle4)) orderedParamData += dynamicLocale('folderTitle4', pramDataAttribute, paramKeys);
    if (isDefined(pramDataAttribute.folderFormat)) orderedParamData += dynamicLocale('folderFormat', pramDataAttribute, paramKeys);
    if (isDefined(pramDataAttribute.numberOfListDisplayColumns)) orderedParamData += dynamicLocale('numberOfListDisplayColumns', pramDataAttribute, paramKeys);

    return orderedParamData;
};

const useScanContainer = () => {
    const [defaultSettingList, changeDefaultSettingList] = useState();
    const appContainer = UserAppContainer.useContainer();
    const [ui, setUI] = useState(UI.state.Loading);
    const [idSelected, setIdSelected] = useState('');
    const [goToDefaultSetting, setGoToDefaultSetting] = useState(false);

    const handleClickGoToDefaultSetting = (id: string) => {
        setIdSelected(id);
        setGoToDefaultSetting(true);
    };

    const getListDefaultSetting = async (id: string) => {
        if (defaultSettingList) {
            return;
        }
        try {
            const response = await endpointsModel.getAllDefaultSetting(id, appContainer.values.authorizationCode);
            if (!response) {
                setUI(UI.state.Loaded);
                appContainer.setLoadingState(false);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.error.notFound),
                    variant: Variants.error,
                });
                return;
            }
            changeDefaultSettingList(response);
        } catch (e) {
            setUI(UI.state.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const loadDummyData = (dummyList: schema.V1EndpointsUpdateResponse) => {
        changeDefaultSettingList(dummyList);
    };

    const confirmRemove = (isYes: boolean, value?: string) => {
        if (!isYes) {
            return;
        }
        if (typeof value === 'string') {
            onDelete(value);
        }
    };

    const onDelete = (id: string) => {
        void (async () => {
            try {
                const currentId = id;
                const response = await defaultSettingsModel.deleteDefaultSetting(currentId, appContainer.values.authorizationCode);
                if (!response.id) {
                    throw new Error('force to fail');
                }
                const copyObj = Object.assign({}, defaultSettingList);
                for (const i in copyObj.defaultSettings) {
                    if (copyObj.defaultSettings[i].id === currentId) {
                        delete copyObj.defaultSettings[i];
                    }
                }
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.deleted),
                    variant: Variants.success,
                });
                changeDefaultSettingList(copyObj);
            } catch (e) {
                setUI(UI.state.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };
    const dataDefaultSetting = (data: schema.V1ObjectsEndpointDefaultSetting, type: string) => {
        let response = '';
        const printKeys = JSON.parse(JSON.stringify(locale.keys.printAttributeData));
        const pdfKeys = JSON.parse(JSON.stringify(locale.keys.pdfAttributeData));
        const copyObj = JSON.parse(JSON.stringify(data));
        // scan attributes
        response += scanAttributeList(data.scanAttributeData);
        // print attributes
        response += mappingResponse(data.printAttributeData, printKeys, copyObj.printAttributeData);
        // pdf attributes
        response += mappingResponse(data.pdfAttributeData, pdfKeys, copyObj.pdfAttributeData);
        // param attributes
        if (data.paramData) {
            response += pramDataAttributeList(data.paramData);
        }
        // remove last comma in string
        return response.slice(0, -2);
    };

    const mappingResponse = (data: { [key: string]: any }, keys: { [key: string]: any }, copyObj: { [key: string]: any }) => {
        let response = '';
        for (const i in data) {
            // check if translate have dynamic value
            if (typeof keys[i] === 'string') {
                response += responseConvert(keys[i], { [i]: copyObj[i] });
            }
            if (typeof keys[i] === 'object') {
                for (const t in keys[i]) {
                    // if value === locale key
                    let value = copyObj[i];
                    if (typeof copyObj[i] === 'number') {
                        value = `type${copyObj[i]}`;
                    }
                    if (value.toString() !== t) {
                        continue;
                    }
                    response += responseConvert(keys[i][t], { [t]: value });
                }
            }
        }
        return response;
    };
    const responseConvert = (key: string, data: {}) => {
        let response = '';
        const isDynamic = locale.isDynamic(key);
        if (isDynamic) {
            response += `${locale.t(key, data)}`;
        }
        if (!isDynamic) {
            response += `${locale.t(key)}`;
        }
        response += ', ';
        return response;
    };

    const dateConvert = (date: string) => {
        const lang = window.localStorage.getItem('lang');
        let dateFormat = DateFormat.fullDateWithSlash;
        if (lang === 'ja') {
            dateFormat = DateFormat.fullJpDate;
        }
        if (moment(date).isValid()) {
            return moment(date).format(dateFormat);
        }
        return null;
    };
    return {
        ui,
        setUI,
        handleClickGoToDefaultSetting,
        idSelected,
        goToDefaultSetting,
        setGoToDefaultSetting,
        defaultSettingList,
        getListDefaultSetting,
        confirmRemove,
        dataDefaultSetting,
        loadDummyData,
        dateConvert,
    };
};
export const ScanContainer = createContainer(useScanContainer);
